import AbstractWidget from './AbstractWidget';

export default class TextBreakWidget extends AbstractWidget
{
    static getDefaults = () =>
    {
        return {
            ...AbstractWidget.getDefaults(),
            bottomBorderColor: '#cccccc',
            bottomBorderStyle: 'solid', 
            bottomBorderWidth: '1px',
            showHorizontalRule: false
        };
    }

    render = (data) =>
    {
        // In the normal React-flow this is unlikely to be called since ReportWidgetPanel handles this (because it is so simple) but the code
        // is repeated/translated here in case we ever use this in a non-React app
        if ((this.container !== undefined) && (this.container !== null) && (this.design !== undefined) && (this.design !== null))
        {
            const settings = {
                ...TextBreakWidget.getDefaults(),
                ...this.design
            },
                nodeName = ((settings['showHorizontalRule'] === true) || (settings['showHorizontalRule'].toString().toLowerCase() === 'true') ? 'hr' : 'div'),
                elm = this.container.ownerDocument.createElement(nodeName);
            let nodeStyle = `height: auto; min-height: 5px; font-size: 1px;`;
            if (settings.bottomBorderStyle !== undefined) nodeStyle += `border-bottom-style: ${settings.bottomBorderStyle}`;
            if (settings.bottomBorderColor !== undefined) nodeStyle += `border-bottom-color: ${settings.bottomBorderColor}`;
            if (settings.bottomBorderWidth !== undefined) nodeStyle += `border-bottom-width: ${settings.bottomBorderWidth}`;
            if (settings.cssStyle !== undefined) nodeStyle += settings.cssStyle;
            elm.setAttribute('style', nodeStyle);
            if (settings.cssClass !== undefined) elm.setAttribute(settings.cssClass);
            if (nodeName === 'div') elm.appendChild(this.container.ownerDocument.createTextNode(' '));
            this.container.appendChild(elm);
        }
    }
}