import { createRoot } from 'react-dom/client';
import { appendAlternateViewButtons, buildHtmlFromData } from './widgetDataUtils';
import { addClass, hasClass, removeClass } from '../utils/dom';

const APPROVED_EVENTS = [
    'click',
    'mouseover',
    'mouseleave',
    'mousemove',
    'mouseout',
    'hover',
    'widgetdataerror',
    'widgetrender'
]; // Hover/mousemove is generated by charts, others use mouse events natively

export default class AbstractWidget {
    _design = {};
    _container = null;
    _eventSource = null;

    constructor(containerElement, design) {
        this._container = containerElement;
        this._design = design;
    }

    get container() {
        return this._container;
    }

    get design() {
        return this._design;
    }

    get indicatorAliases() {
        const { indicatorAliases } = this._design;
        if (indicatorAliases === undefined || indicatorAliases === null) return undefined;
        return parseIndicatorAliases(indicatorAliases);
    }

    render = (data, options = {}) => {
        if (this.container !== undefined && this.container !== null) {
            let txt = this.container.ownerDocument.createTextNode(
                    `⚠️ Override this to render content for the widget [${this.design.scriptClass}] ⚠️`
                ),
                span = this.container.ownerDocument.createElement('span');
            span.setAttribute('class', 'ia-generated');
            span.appendChild(txt);
            this.container.appendChild(span);
            if (data !== null && data !== undefined) {
                txt = this.container.ownerDocument.createTextNode(
                    `⚠️ Widget data: ${JSON.stringify(data, null, 2)} ⚠️`
                );
                span = this.container.ownerDocument.createElement('code');
                span.setAttribute('class', 'ia-generated');
                span.setAttribute('style', 'display: block; font-size: 9px;');
                span.appendChild(txt);
                this.container.appendChild(span);
                //containerElement.appendChild(<code style={{ fontSize: '9px' }}>⚠️ Widget data: {JSON.stringify(data, null, 2)} ⚠️</code>);
            }
        }
    };

    renderError = (errorOrData, widgetId, widgetClassType, widgetIndicators) => {
        const writeWarning = typeof console.warn !== 'undefined' ? console.warn : console.log,
            preHandled =
                errorOrData !== undefined &&
                errorOrData.message !== undefined &&
                errorOrData.override !== undefined &&
                errorOrData.override === true;
        if (!preHandled) {
            if (typeof console.group !== 'undefined')
                console.group(`⚠️ Warning, widget error: ${widgetId}, ${widgetClassType}`);
            else writeWarning(`⚠️ Warning, widget error: ${widgetId}, ${widgetClassType}`); // DEBUG
            writeWarning(errorOrData); // DEBUG
            if (typeof errorOrData.rootCause !== 'undefined') {
                writeWarning('---------------- root cause ----------------');
                writeWarning(errorOrData.rootCause);
            }
            writeWarning(widgetIndicators); // DEBUG
            this.fireEvent('widgetDataError', errorOrData);
            if (typeof console.groupEnd !== 'undefined') console.groupEnd();
        }
        if (this.container !== undefined && this.container !== null) {
            const identityCancel =
                    errorOrData.name !== undefined && errorOrData.name === 'identity-manager:user-aborted',
                fetchBadFail =
                    errorOrData.message !== undefined && errorOrData.message.toLowerCase() === 'failed to fetch',
                knownError = identityCancel || fetchBadFail,
                messageText =
                    knownError && identityCancel
                        ? `⚠️ This widget requires authentication to view its data. Please refresh the page to try again.`
                        : knownError && fetchBadFail
                        ? `⚠️ Sorry - this widget could not connect to its data source. Please refresh the page to try again.`
                        : `⚠️ Sorry - this widget is not working. Please refresh the page to try again.`;
            let txt = this.container.ownerDocument.createTextNode(messageText),
                span = this.container.ownerDocument.createElement('span'),
                existing = this.container.querySelector('.ia-generated.ia-error-simple');
            const widgetBlock = this.container.closest('.ia-report-widget');
            if (existing !== undefined && existing !== null) existing.remove();
            span.setAttribute('class', 'ia-generated ia-error-simple');
            span.appendChild(txt);
            if (this.container.firstChild !== null) this.container.insertBefore(span, this.container.firstChild);
            else this.container.appendChild(span);
            if (errorOrData !== null && errorOrData !== undefined) {
                if (
                    errorOrData.code !== undefined &&
                    (errorOrData.code === 403 || errorOrData.code === 401 || errorOrData.code === 499)
                ) {
                    txt = this.container.ownerDocument.createTextNode(
                        `🚫 Sorry - you don't seem to have permission to access this widget's data. Please refresh the page and/or sign in to try again.`
                    );
                    span.firstChild.remove();
                    span.appendChild(txt);
                } else if (preHandled) {
                    txt = this.container.ownerDocument.createTextNode(errorOrData.message);
                    span.firstChild.remove();
                    span.appendChild(txt);
                } else if (!knownError) {
                    txt = this.container.ownerDocument.createTextNode(
                        `⚠️ An unexpected error occurred when fetching data or rendering the widget.\n Detail: ${
                            errorOrData.message || errorOrData
                        } ⚠️`
                    );
                    span = this.container.ownerDocument.createElement('code');
                    span.setAttribute('class', 'ia-generated ia-error-detail');
                    span.setAttribute('style', 'display: block; font-size: 9px;');
                    span.appendChild(txt);
                    this.container.appendChild(span);
                }
                if (widgetBlock !== undefined && widgetBlock !== null && hasClass(widgetBlock, 'placeholder')) {
                    removeClass(widgetBlock, 'placeholder');
                    addClass(widgetBlock, 'errored');
                }
                //containerElement.appendChild(<code style={{ fontSize: '9px' }}>⚠️ Widget data: {JSON.stringify(data, null, 2)} ⚠️</code>);
            }
        }
    };

    set eventSource(targetElement) {
        this._eventSource = targetElement;
    }

    get eventSource() {
        return this._eventSource;
    }

    // Wrap around the container to simplify events
    addEventListener = (eventListener, options) => {
        if (this.eventSource !== undefined && this.eventSource !== null) {
            for (let eventType of APPROVED_EVENTS) {
                this.eventSource.addEventListener(`rb.${eventType}`, eventListener, options);
            }
        }
    };

    // Wrap around the container to simplify events
    removeEventListener = (eventListener, options) => {
        if (this.eventSource !== undefined && this.eventSource !== null) {
            for (let eventType of APPROVED_EVENTS) {
                this.eventSource.removeEventListener(`rb.${eventType}`, eventListener, options);
            }
        }
    };

    // Use the container to simplify event listeners but allow a custom event to bubble out.
    // Done here to streamline events
    fireEvent = (eventType = 'unknown', eventData = {}) => {
        if (this.eventTimeoutId !== undefined) window.clearTimeout(this.eventTimeoutId);
        const fire = () => {
            if (this.container !== undefined && this.container !== null) {
                const lowerType = eventType.toLowerCase(),
                    event = new CustomEvent(`rb.${lowerType}`, {
                        bubbles: true,
                        detail: eventData
                    });
                if (APPROVED_EVENTS.indexOf(lowerType) < 0)
                    throw new Error(`Event type ${lowerType} is not supported as a widget event`);
                return this.container.dispatchEvent(event);
            }
        };
        this.eventTimeoutId = window.setTimeout(fire, 20);
        return false;
    };

    destroy = (removeElements = true) => {
        if (removeElements && this.container !== null) this.container.remove();
    };

    appendAlternateViewActions = async (settings = {}, data = []) => {
        if (this.container !== null) {
            const links = this.container.querySelectorAll('.ia-chart-data-table-link');
            appendAlternateViewButtons(this.container, links, settings, data);
            if (
                settings.includeAccessibleTable === undefined ||
                settings.includeAccessibleTable === null ||
                settings.includeAccessibleTable !== false
            ) {
                const canvasElm = this.container.querySelector('canvas');
                if (canvasElm !== undefined && canvasElm !== null) {
                    try {
                        const dataTableHtml = await buildHtmlFromData(
                                data.map((d) => (d.data !== undefined ? d.data : d)),
                                settings.numberFormat,
                                settings.locale,
                                false,
                                false
                            ),
                            canvasChild = canvasElm.ownerDocument.createElement('div');
                        canvasElm.appendChild(canvasChild);
                        const canvasRoot = createRoot(canvasChild);
                        canvasRoot.render(dataTableHtml.body);
                    } catch (dataTableErr) {
                        console.log(dataTableErr); // No echo to screen, is a nice-to-have
                    }
                }
            }
        }
    };

    static applySortAndRename = (columns, aliases, indicators, rows = []) => {
        let als, i;
        // Set the value indices on the columns, to pick data up later...
        for (i = 0; i < columns.length; i++) columns[i].index = i;
        for (let c of columns) {
            als = aliases.find((ca) => ca.id === c.iid);
            i = indicators.find((ii) => ii.id === c.iid);
            // 2nd chance - match by offset
            if (als === undefined && c.offset !== undefined)
                als = aliases.find((ca) => ca.id.toString() === c.offset.toString());
            if (i === undefined && c.offset !== undefined)
                i = indicators.find((ii) => ii.offset !== undefined && ii.offset.toString() === c.offset.toString());
            if (als === undefined) c.overIndex = c.iid === 'name' ? -1 : 999999;
            else {
                c.overIndex = als.index * 1000 + c.index; // To allow for time series within the set
                if (i !== undefined) {
                    i.fullName = i.fullName || i.name.toString(); // Just in case there are some silly duplicates!
                    i.name = als.label;
                    i.shortName = als.label;
                    i.overIndex = als.index * 1000;
                }
            }
        }
        columns.sort((a, b) => a.overIndex - b.overIndex);
        indicators.sort((a, b) => a.overIndex - b.overIndex);
        if (rows.length > 0) {
            // Re-apply the over index to make sure that they line up...
            for (i = 0; i < columns.length; i++) columns[i].overIndex = i;
            for (let r = 0; r < rows.length; r++) {
                const clone = new Array(rows[r].length);
                for (let c of columns) {
                    clone[c.overIndex] = rows[r][c.index];
                }
                rows[r] = [...clone];
            }
        }
    };

    // Presuming a table is arranged as feature rows, filter those rows (in place)...
    static filterTable = (dataTable, featureRowFilter = null, comparisonRowFilter = null) => {
        const nof = featureRowFilter === null,
            // Strip relationships from list - should be pre-filtered in that case!
            cfltr =
                comparisonRowFilter !== null
                    ? comparisonRowFilter.filter(
                          (c) => c !== undefined && c !== null && c.indexOf('relationship:') !== 0
                      )
                    : null,
            noc = comparisonRowFilter === null || (comparisonRowFilter.length > 0 && cfltr.length === 0),
            nf = dataTable.features.length,
            nr = dataTable.rows.length,
            ni = dataTable.rowIds.length,
            discards = [];
        if (nf !== nr || nf !== ni || nr !== ni) throw new Error(`Table is corrupt - features !== rows !== row IDs`);
        for (let i = nf - 1; i >= 0; i--) {
            const notF =
                    !nof && !dataTable.features[i].comparison && featureRowFilter.indexOf(dataTable.features[i].id) < 0,
                notC =
                    !noc &&
                    dataTable.features[i].comparison &&
                    cfltr.indexOf(dataTable.features[i].id) < 0 &&
                    cfltr.indexOf(`#${dataTable.features[i].id}`) < 0;
            if (notF || notC) {
                dataTable.features.splice(i, 1);
                dataTable.rowIds.splice(i, 1);
                const removed = dataTable.rows.splice(i, 1)[0];
                if (notF) discards.push(removed);
            }
        }
        return discards;
    };

    static cleanAggregateNames = (dataTable, aggregatedAreasAlias, aggregatedFeatureKey = '___iaAggregatedFeature') => {
        const aggs = dataTable.features.filter((f) => f.id === aggregatedFeatureKey),
            aggRows = dataTable.rows.filter((r, i) => dataTable.rowIds[i] === aggregatedFeatureKey);
        for (let f of aggs) {
            aggs.name = aggregatedAreasAlias;
        }
        for (let r of aggRows) {
            r[0] = aggregatedAreasAlias;
        }
    };

    static getDefaults = () => {
        return {
            aggregatedAreasAlias: 'Selected Areas',
            allAreasRequired: false,
            allowTableView: true,
            comparisonFeatureIds: '',
            cssClass: '',
            cssStyle: '',
            eventsGenerate: 'hover', // click, hover, none, comma-or-space-delimited
            eventsListen: 'hover', // click, hover, none, comma-or-space-delimited, react to events generated/proxied by the containing <section> or not?
            extendedToolTips: true,
            float: 'left',
            height: 'auto',
            includeAllAreas: false,
            includeNumeratorsAndDenominators: false,
            indicatorAliases: '',
            labelStyle: 'FullName',
            locale: 'en',
            numberFormat: '#,###.#',
            numberFormatAssociates: '#,###.#',
            noDataText: 'N/A',
            requiredAggregationMethod: 'baseOnly',
            requiredDataAggregation: 'disaggregated',
            showComparisons: false,
            showWhenEmpty: true,
            sortFeaturesByName: true,
            width: 'auto'
        };
    };
}

export const parseIndicatorAliases = (indicatorAliases) => {
    // "I28846=Persons age 65+|I28840=Males age 65+|I28834=Females age 65+"
    const tokens = indicatorAliases.split('|'),
        aliases = [];
    let parts;
    for (let i = 0; i < tokens.length; i++) {
        parts = tokens[i].split('=');
        if (parts.length > 1) {
            aliases.push({
                index: i,
                id: parts[0],
                label: parts[1]
            });
        }
    }
    return aliases;
};

export const lengthWithUnit = (
    cssLength = '',
    unit = 'px',
    convertNonPixelLengths = true,
    minLength = 1,
    autoFill = false
) => {
    const tokens = cssLength.toString().split(/\s/);
    while (tokens.length < minLength) tokens.push(autoFill ? tokens[0] : 'auto');
    return tokens
        .map((t) =>
            /^-?[0-9.]+$/.test(t)
                ? `${t}${unit}`
                : unit === 'px' && convertNonPixelLengths
                ? convertLengthToPixels(t, true, false, '0px')
                : t
        )
        .join(' ');
};

export const convertLengthToPixels = (
    cssLength = '',
    wholeNumbers = true,
    asNumber = false,
    defValue,
    containerLength
) => {
    const tokens = cssLength.toString().match(/^(-?[0-9.]+)(mm|px|in|cm|pt|%)$/);
    let v = 0;
    if (tokens === null || tokens.length < 3) {
        if (cssLength.toString().match(/^(-?[0-9.]+)$/) !== null) {
            v = parseFloat(cssLength.toString());
        }
        if (!isNaN(v) && asNumber) return v;
        else return defValue !== undefined ? defValue : cssLength; // Fail but pass something valid(?) back
    } else v = parseFloat(tokens[1]);
    if (tokens !== null && tokens.length > 2) {
        if (tokens[2] === 'mm') v = 96 * (v / 25.4); // 96 dpi, 25.4mm = 1inch
        else if (tokens[2] === 'cm') v = 96 * (v / 2.54); // 96 dpi, 2.54cm = 1inch
        else if (tokens[2] === 'in') v = 96 * v; // 96 dpi
        else if (tokens[2] === 'pt') v = 1.33333 * v; // 1pt = 1.3333px
        else if (tokens[2] === '%' && containerLength !== undefined && containerLength > 0)
            v = v * 0.01 * containerLength; // v / 100 * container
    }
    if (wholeNumbers) v = Math.round(v);
    return asNumber ? v : `${v}px`;
};

export const WIDGET_CONTAINER_CSS_CLASS = 'ia-child-widget-container';

export const parseRules = (rulesCommaDelimited = '') => {
    if (rulesCommaDelimited === undefined || rulesCommaDelimited === null || rulesCommaDelimited === '')
        return undefined;
    const WHITESPACE_STRIP_PATTERN = /(?:\s+)|((?:"(?:.+?)")|(?:'(?:.+?)'))/g,
        NEW_RULES_PATTERN =
            /((?<test>equals|doesnotequal|notequal|lessthan|greaterthan|lessthanorequalto|lessthanorequal|greaterthanorequalto|greaterthanorequal|=|==|!=|<>|<|>|<=|>=|contains|doesnotcontain|in|notin|isnull|isnotnull)\((?<value>(#IVALUE{|#CVALUE{|#FVALUE{|#VALUE{)?([0-9a-zA-Z:_\-#;|.]+)}?),(?<match>[0-9a-zA-Z:_\-#;]+)(,(?<negate>[0-9a-zA-Z:_\-#;]*))?(,(?<key>[0-9a-zA-Z:_\-#]+))?\))/gim;

    const rulesPatternMatch = rulesCommaDelimited.replace(WHITESPACE_STRIP_PATTERN, '').matchAll(NEW_RULES_PATTERN),
        rules = [],
        map = {
            eq: '==',
            neq: '!=',
            gt: '>',
            gte: '>=',
            lt: '<',
            lte: '<='
        };
    // May be a suitable match and not old syntax...
    if (rulesPatternMatch !== null && rulesCommaDelimited.indexOf('|') < 0) {
        for (let m of rulesPatternMatch) {
            const { test, value, match, negate, key } = m.groups;
            if (test !== undefined && value !== undefined) {
                rules.push({
                    test: map[test] !== undefined ? map[test] : test,
                    value,
                    match,
                    negate,
                    key
                });
            }
        }
    } else {
        const tokens = rulesCommaDelimited.split(',');
        let test, value, text, valueKey;
        for (let t of tokens) {
            [value, test, text, valueKey] = t.split('|');
            if (map[test] !== undefined) test = map[test];
            if (test !== undefined && value !== undefined) {
                rules.push({
                    test: test,
                    value: value,
                    match: text,
                    key: valueKey
                });
            }
        }
    }
    return rules;
};

/**
 *
 * @param {*} ruleset The set of rules to iterate over for the given value.
 * @param {*} testValue The value to test against each rule.
 * @param {*} testKey If defined and non-null a key to further filter the list of rules that can be tested against. If a
 * rule has no "key" then it  is assumed it can be tested against.
 * @returns
 */
export const applyRuleSet = (ruleset = [], testValue, testKey) => {
    const keyedRuleset =
        testKey !== undefined && testKey !== null && testKey !== ''
            ? ruleset.filter((r) => r.key === undefined || r.key === null || r.key === testKey)
            : ruleset;
    let outputValue = null;
    for (let rule of keyedRuleset) {
        outputValue = applyRule(rule, testValue);
        if (outputValue !== null) break;
    }
    return outputValue;
};

export const applyRule = (rule, testValue) => {
    let outputValue = null;
    const tvs = rule.value.toString().split(/[;|]/),
        hasValue = testValue !== undefined && testValue !== null,
        isEmpty = hasValue && testValue.toString().trim() === '',
        matchValue = rule.match || rule.text; // .text is a legacy version that needs to be parsed out;
    switch (rule.test.toLowerCase()) {
        case '=':
        case '==':
        case 'equals':
            outputValue =
                hasValue && testValue.toString().trim() === rule.value.toString().trim()
                    ? matchValue
                    : rule.negate !== undefined && rule.negate !== ''
                    ? rule.negate
                    : null;
            break;
        case '!=':
        case '<>':
        case 'notequal':
        case 'doesnotequal': // legacy
            outputValue =
                !hasValue || testValue.toString().trim() !== rule.value.toString().trim()
                    ? matchValue
                    : rule.negate !== undefined && rule.negate !== ''
                    ? rule.negate
                    : null;
            break;
        case '===':
        case 'numericequals':
            outputValue =
                hasValue && parseFloat(testValue) === parseFloat(rule.value)
                    ? matchValue
                    : rule.negate !== undefined && rule.negate !== ''
                    ? rule.negate
                    : null;
            break;
        case '!==':
        case 'numericnotequal':
        case 'numericdoesnotequal': // legacy
            outputValue =
                !hasValue || parseFloat(testValue) !== parseFloat(rule.value)
                    ? matchValue
                    : rule.negate !== undefined && rule.negate !== ''
                    ? rule.negate
                    : null;
            break;
        case '>':
        case 'greaterthan':
        case 'morethan':
        case 'isgreaterthan': // legacy
            outputValue =
                hasValue && parseFloat(testValue) > parseFloat(rule.value)
                    ? matchValue
                    : rule.negate !== undefined && rule.negate !== ''
                    ? rule.negate
                    : null;
            break;
        case '>=':
        case 'greaterthanorequal':
        case 'morethanorequal':
        case 'isgreaterthanorequalto': // legacy
            outputValue =
                hasValue && parseFloat(testValue) >= parseFloat(rule.value)
                    ? matchValue
                    : rule.negate !== undefined && rule.negate !== ''
                    ? rule.negate
                    : null;
            break;
        case '<':
        case 'lessthan':
        case 'islessthan': // legacy
            outputValue =
                hasValue && parseFloat(testValue) < parseFloat(rule.value)
                    ? matchValue
                    : rule.negate !== undefined && rule.negate !== ''
                    ? rule.negate
                    : null;
            break;
        case '<=':
        case 'lessthanorequal':
        case 'islessthanorequalto': // legacy
            outputValue =
                hasValue && parseFloat(testValue) <= parseFloat(rule.value)
                    ? matchValue
                    : rule.negate !== undefined && rule.negate !== ''
                    ? rule.negate
                    : null;
            break;
        case 'contains':
            let contains = hasValue && !isEmpty && testValue.toString().indexOf(rule.value.toString()) >= 0;
            for (let tv of tvs) {
                contains = contains || (hasValue && testValue.toString() === tv);
            }
            outputValue = contains ? matchValue : rule.negate !== undefined && rule.negate !== '' ? rule.negate : null;
            break;
        case '!contains':
        case 'doesnotcontain':
            let doesNotContain = hasValue && testValue.toString().indexOf(rule.value.toString()) < 0;
            for (let tv of tvs) {
                doesNotContain = doesNotContain && hasValue && testValue.toString() !== tv;
            }
            outputValue = doesNotContain
                ? matchValue
                : rule.negate !== undefined && rule.negate !== ''
                ? rule.negate
                : null;
            break;
        case 'in':
        case 'iscontainedby': // legacy
            let isIn = hasValue && !isEmpty && rule.value.toString().indexOf(testValue.toString().trim()) >= 0;
            if (tvs.length > 1) {
                isIn = false;
                for (let tv of tvs) {
                    isIn = isIn || (hasValue && tv === testValue.toString().trim());
                }
            }
            outputValue = isIn ? matchValue : rule.negate !== undefined && rule.negate !== '' ? rule.negate : null;
            break;
        case 'notin':
        case 'isnotcontainedby': // legacy
            let isNotIn =
                !hasValue || isEmpty || (hasValue && rule.value.toString().indexOf(testValue.toString().trim()) < 0);
            if (tvs.length > 1 && hasValue) {
                isNotIn = true;
                for (let tv of tvs) {
                    isNotIn = isNotIn && tv !== testValue.toString().trim();
                }
            }
            outputValue = isNotIn ? matchValue : rule.negate !== undefined && rule.negate !== '' ? rule.negate : null;
            break;
        case '?':
        case 'isnull':
            outputValue =
                testValue === null || testValue === '<NULL>'
                    ? matchValue
                    : rule.negate !== undefined && rule.negate !== ''
                    ? rule.negate
                    : null;
            break;
        case '!?':
        case 'isnotnull':
            outputValue =
                testValue !== null && testValue !== '<NULL>'
                    ? matchValue
                    : rule.negate !== undefined && rule.negate !== ''
                    ? rule.negate
                    : null;
            break;
        case 'nullcomparator':
            outputValue = matchValue;
            break;
        default:
            outputValue = null;
    }
    //console.debug(`(ℹ️) ${testValue} ${rule.test} ${rule.value} ? ${(outputValue !== null)}`); // DEBUG
    return outputValue;
};
