import React from 'react';
import { useHistory } from 'react-router-dom';
import { isNullOrUndefined, isNullOrWhitespace, lowerKeyParams } from './object';
import { addClass } from './dom';
import { get } from './json';
import { generateGuid } from './report-migrate-plus';
import ReportSelectorDropDowns from '../components/ReportSelectorDropDowns';

const IA_CUSTOMER_SITE_CONFIG_HOST = 'https://iao.blob.core.windows.net/data-store';
const IA_CUSTOMER_FILENAME_STEM = 'IAO-customer';

export interface IPortalConfig {
    url: string;
    type: 'online' | 'portal';
    home: string;
}

export interface IAppConfig {
    container?: string;
    appId?: string;
    appAuthId?: string; // Authentication ID for edit page etc. - from ArcGIS Online (or Portal)
    viewOnlyAuthId?: string; // Authentication ID for viewer page - from ArcGIS Online (or Portal)
    appHost?: string;
    appHelp?: string;
    staticHost?: string;
    publisherUrl?: string;
    token?: string | null;
    tokenExpiry?: number;
    portal?: IPortalConfig;
    appLinks?: { [id: string]: any };
    fontFamily?: string;
    fontSize?: number;
    onReady?: (containerNode: Element | null, config: IAppConfig, e: any) => void;
    onError?: (err: any) => void;
    user?: any;
    locale?: string;
    messages?: any;
    routerMode?: 'path' | 'browser' | 'hash' | 'static';
    host?: string;
    basename?: string;
    className?: string;
    embedded?: boolean;
    error?: any;
    location?: any;
    configPathPattern?: string; // Overriding config based on domain - can be static file or micro-service URL with query
    overrides?: { [id: string]: any }; // Non-defined set of key=value pairs that can be passed down through the React render stack and "read" by components
    featureSwitches?: { [id: string]: string }; // Any key=value pairs that can switch feature on and off
}

export const RB_APP_VIEW_ONLY_AUTH_ID = '0gn21qJjhxLwv4IN';

export interface IAppStartupConfig extends IAppConfig {
    container: string;
    appId: string;
    appHost: string;
    portal: IPortalConfig;
    locale: string;
}

export interface IColorScheme {
    id?: string;
    pageBackground: string;
    standard: string;
    heading: string;
    light: string;
    border: string;
    boxBackground: string;
    boxForeground: string;
    boxContentBackground: string;
}

export const defaultReportColors: IColorScheme = {
    pageBackground: '#ffffff',
    standard: '#111111',
    heading: '#111111',
    light: '#777777',
    border: '#999999',
    boxBackground: '#cccccc',
    boxForeground: '#111111',
    boxContentBackground: '#ffffff'
};

export const standardColorSchemes: IColorScheme[] = [
    {
        ...defaultReportColors,
        id: 'default'
    },
    {
        ...defaultReportColors,
        boxBackground: '#9e9e9e',
        boxForeground: '#111111',
        id: 'grey'
    },
    {
        ...defaultReportColors,
        id: 'light',
        border: 'rgba(255,255,255,0)',
        boxBackground: 'rgba(255,255,255,0)'
    },
    {
        ...defaultReportColors,
        id: 'blue',
        heading: '#2c5793',
        border: '#2c5793',
        boxBackground: '#2c5793',
        boxForeground: '#ffffff'
    },
    {
        ...defaultReportColors,
        id: 'olive',
        heading: '#4e5e19',
        border: '#4e5e19',
        boxBackground: '#4e5e19',
        boxForeground: '#ffffff'
    },
    {
        ...defaultReportColors,
        id: 'orange',
        heading: '#a03410',
        border: '#a03410',
        boxBackground: '#a03410',
        boxForeground: '#ffffff'
    },
    {
        ...defaultReportColors,
        id: 'pale-blue',
        heading: '#4672af',
        border: '#4672af',
        boxBackground: '#C3D1E6',
        boxForeground: '#333333'
    }
];

export const checkForEmbeddedLaunch = (
    launchFunction,
    beforeInjectionFunction?: (appHostUrl: string | null) => void
) => {
    const injectedScript = Array.from(
            document.querySelectorAll('script.ia-injected-script')
        ).filter((s) => s.className.indexOf('ia-locked') < 0),
        injectionRequired = injectedScript.length > 0,
        injectedPath = injectionRequired
            ? (injectedScript[0].getAttribute('src') || '').split('?')[0]
            : '',
        injectedQS = injectionRequired
            ? (
                  injectedScript[0].getAttribute('data-query-string') ||
                  injectedScript[0].getAttribute('src') ||
                  ''
              ).split('?')[1]
            : '';
    let injected = false;
    if (injectionRequired) {
        const appHost = !isNullOrWhitespace(injectedScript[0].getAttribute('data-app-host'))
                ? injectedScript[0].getAttribute('data-app-host')
                : injectedPath.substring(0, injectedPath.lastIndexOf('/static/js/')),
            injectedQuery = new URLSearchParams(injectedQS),
            pageQuery = new URLSearchParams(window.location.search || ''),
            // Make it data-sensitive
            dataMode = (
                injectedQuery.has('data') && injectedQuery.get('data') !== null
                    ? (injectedQuery.get('data') || '').toString()
                    : 'live'
            ).toLowerCase(),
            rerouteAnchorLinks = () => {
                document.body.addEventListener('click', (e: any) => {
                    if (
                        !isNullOrUndefined(e.target) &&
                        (e.target.nodeName === 'A' || e.target.closest('a') !== null)
                    ) {
                        const linkElement =
                                e.target.nodeName === 'A' ? e.target : e.target.closest('a'),
                            href = linkElement.getAttribute('href');
                        // Some content that must be in a page... and if not we will swallow it anyway, because we can't allow # in case of hash-router
                        if (
                            !isNullOrUndefined(href) &&
                            href.substring(0, 1) === '#' &&
                            href.indexOf('/view-report/') < 0
                        ) {
                            e.preventDefault();
                            const targetElement = document.getElementById(href.substring(1));
                            if (targetElement !== undefined && targetElement !== null) {
                                targetElement.scrollIntoView();
                            }
                        }
                    }
                });
            },
            launchArgs: IAppConfig = {
                host: appHost !== null ? appHost : undefined,
                routerMode: 'hash',
                container: '.ia-report-viewer',
                className: `esriuk-app-embedded ${
                    injectedQuery.get('page-controls') !== null &&
                    injectedQuery.get('page-controls') === 'true'
                        ? ''
                        : 'no-controls'
                }`,
                embedded: true,
                onReady: () => {
                    rerouteAnchorLinks();
                }
            };
        if (injectedQuery.get('container') !== null && injectedQuery.get('container') !== '')
            launchArgs.container = (injectedQuery.get('container') || '').toString();
        if (injectedQuery.get('token') !== null && injectedQuery.get('token') !== '')
            launchArgs.token = (injectedQuery.get('token') || '').toString();
        if (injectedQuery.get('auto') !== null && injectedQuery.get('auto') !== 'script-only') {
            // Transfer any params from page URL to local URL...
            for (let key in pageQuery.keys()) injectedQuery.set(key, pageQuery.get(key) || '');
        }
        // Anything required by calling class/code?
        if (beforeInjectionFunction !== undefined)
            beforeInjectionFunction(`${appHost}/`.replace(/^(.*)\/$/, '$1'));
        // Callback?
        let callbackFnc = (a: any, b: any) => {
            rerouteAnchorLinks();
        };
        if (injectedQuery.get('callback') !== null && injectedQuery.get('callback') !== '') {
            const cbRef = (injectedQuery.get('callback') || '').toString(),
                [cbFuncOrObjKey, cbFuncKey] = cbRef.split('.');
            if (cbFuncOrObjKey !== undefined && typeof window[cbFuncOrObjKey] === 'function')
                callbackFnc = (rn, stgs) => {
                    rerouteAnchorLinks();
                    window[cbFuncOrObjKey](rn, stgs);
                };
            if (
                cbFuncOrObjKey !== undefined &&
                typeof window[cbFuncOrObjKey] === 'object' &&
                cbFuncKey !== undefined
            ) {
                const nspaceOrObj = window[cbFuncOrObjKey];
                if (typeof nspaceOrObj[cbFuncKey] === 'function')
                    callbackFnc = (rn, stgs) => {
                        rerouteAnchorLinks();
                        nspaceOrObj[cbFuncKey](rn, stgs);
                    };
            }
        }
        // Direct link to a report (with or without feature)
        if (injectedQuery.get('report') !== null && injectedQuery.get('auto') !== null) {
            // Set the hash, which will not cause a reload by the browser but allows the inline launch to respond properly
            const featureId = !isNullOrWhitespace(injectedQuery.get('feature')?.toString())
                ? injectedQuery.get('feature')
                : !isNullOrWhitespace(pageQuery.get('feature')?.toString())
                ? pageQuery.get('feature')
                : null;
            window.location.replace(
                !isNullOrWhitespace(featureId)
                    ? `${window.location.href.split('#')[0]}#/view-${
                          dataMode === 'static' ? 'publication' : 'report'
                      }/${injectedQuery.get('report')}/${featureId}`
                    : `${window.location.href.split('#')[0]}#/view-${
                          dataMode === 'static' ? 'publication' : 'report'
                      }/${injectedQuery.get('report')}`
            );
            launchFunction(launchArgs);
            injected = true;
        }
        // Lookup - WP embed for our standard IA customers...
        else if (injectedQuery.get('auto') !== null && injectedQuery.get('auto') !== '') {
            // Multiple reports - specified in one way or another...
            const populateReportControls = (reportsArgs, isMultiChoice) => {
                const {
                    reports = [],
                    label,
                    defaultGeography,
                    bodyClass,
                    geos,
                    catalog,
                    nest,
                    multi
                } = reportsArgs;
                let featureId = !isNullOrWhitespace(injectedQuery.get('feature')?.toString())
                    ? injectedQuery.get('feature')
                    : !isNullOrWhitespace(pageQuery.get('feature')?.toString())
                    ? pageQuery.get('feature')
                    : '___iaFirstFeature';
                const wardRep = reports.find(
                        (r) =>
                            r.geo !== undefined &&
                            r.geo !== null &&
                            (r.geo.toLowerCase().substring(0, 4) === 'ward' ||
                                r.geo.toLowerCase().substring(0, 4) === 'g7')
                    ),
                    targetReport = !isNullOrWhitespace(injectedQuery.get('report')?.toString())
                        ? injectedQuery.get('report')
                        : !isNullOrWhitespace(pageQuery.get('report')?.toString())
                        ? pageQuery.get('report')
                        : defaultGeography,
                    forceMultiChoice =
                        isMultiChoice ||
                        (nest !== undefined && 'false,0,no'.indexOf(nest.toString()) >= 0) ||
                        (multi !== undefined && 'true,1,yes'.indexOf(multi) >= 0);
                let selectedReport =
                        forceMultiChoice &&
                        !isNullOrUndefined(targetReport) &&
                        !isNullOrUndefined(reports.find((r) => r.id === targetReport))
                            ? reports.find((r) => r.id === targetReport)
                            : forceMultiChoice && wardRep !== undefined
                            ? wardRep
                            : reports[0],
                    reportId = selectedReport.id,
                    reportGeoId = selectedReport.geo,
                    navRouterMode = !isNullOrWhitespace(reportsArgs.routing)
                        ? reportsArgs.routing
                        : 'hash',
                    internalLinks = !isNullOrWhitespace(injectedQuery.get('links')?.toString())
                        ? (injectedQuery.get('links') || '') === 'hash-only'
                        : false,
                    navigationPrefix =
                        navRouterMode === 'path'
                            ? window.location.pathname.replace(/^(.*)\/$/, '$1')
                            : navRouterMode === 'hash'
                            ? '#'
                            : `${window.location.pathname}#`; // Both hashes, but one builds more SEO-friendly links
                reports.find((r) => r.id === reportId).isDefault = true;
                // Page tweakery
                launchArgs.routerMode = navRouterMode;
                launchArgs.onReady = (reactRootNode: Element | undefined | null, appSettings) => {
                    rerouteAnchorLinks();
                    const b =
                        reactRootNode !== undefined && reactRootNode !== null
                            ? reactRootNode.ownerDocument.querySelector('body')
                            : null;
                    if (!isNullOrUndefined(b) && !isNullOrWhitespace(bodyClass))
                        addClass(b, bodyClass);
                    if (
                        !isNullOrWhitespace(label) &&
                        reactRootNode !== undefined &&
                        reactRootNode !== null
                    )
                        reactRootNode.setAttribute('data-aria-label', label);
                    callbackFnc(reactRootNode, appSettings);
                };
                if (catalog !== undefined && catalog !== null) {
                    launchArgs.overrides = {
                        catalog: `${
                            typeof catalog.url !== 'undefined'
                                ? catalog.url
                                : typeof catalog.id !== 'undefined'
                                ? catalog.id
                                : catalog.toString()
                        }`
                    };
                    if (reportsArgs.chartPalette !== undefined)
                        launchArgs.overrides.chartPalette = reportsArgs.chartPalette;
                    if (reportsArgs.chartComparisonsPalette !== undefined)
                        launchArgs.overrides.chartComparisonsPalette =
                            reportsArgs.chartComparisonsPalette;
                }
                // TODO? - deal with features? Index will do this but do we want drop-down option?
                const hashMatch =
                        /[#]\/(?:report-builder\/)?view-(publication|report)\/([a-zA-Z0-9]+)\/([a-zA-Z0-9_-]+)(\/[a-zA-Z0-9_-]+)?/.exec(
                            window.location.hash || ''
                        ),
                    pathMatch =
                        /(.*)\/(?:report-builder\/)?view-(publication|report)\/([a-zA-Z0-9]+)\/([a-zA-Z0-9_-]+)(\/[a-zA-Z0-9_-]+)?/.exec(
                            window.location.pathname || ''
                        ),
                    preHashed = hashMatch !== null,
                    preRouted = pathMatch !== null;
                if (hashMatch !== null) {
                    // Adjust the defaults...
                    // ["#/view-report/20ef5c9e96634f1890c5a3f91f21800a/E05011462", "report", "20ef5c9e96634f1890c5a3f91f21800a", "E05011462"]
                    reportId = hashMatch[2];
                    featureId = hashMatch[3];
                    if (
                        hashMatch[4] !== undefined &&
                        hashMatch[4] !== null &&
                        hashMatch[4].trim() !== '/' &&
                        hashMatch[4].trim() !== ''
                    ) {
                        reportGeoId = hashMatch[4].trim().replace('/', '');
                        selectedReport =
                            forceMultiChoice &&
                            !isNullOrUndefined(
                                reports.find((r) => r.id === reportId && r.geo === reportGeoId)
                            )
                                ? reports.find((r) => r.id === reportId && r.geo === reportGeoId)
                                : selectedReport;
                        reportId = selectedReport.id;
                        reportGeoId = selectedReport.geo;
                        for (let r of reports) {
                            r.isDefault = r.id === reportId && r.geo === reportGeoId; // reset before passing to next stage
                        }
                    }
                }
                if (pathMatch !== null) {
                    // Adjust the defaults...
                    // ["<something-or-nothing>/view-report/20ef5c9e96634f1890c5a3f91f21800a/E05011462", "<something-or-nothing>", "report", "20ef5c9e96634f1890c5a3f91f21800a", "E05011462"]
                    reportId = pathMatch[3];
                    featureId = pathMatch[4];
                    if (
                        pathMatch[4] !== undefined &&
                        pathMatch[4] !== null &&
                        pathMatch[4].trim() !== '/' &&
                        pathMatch[4].trim() !== ''
                    ) {
                        reportGeoId = pathMatch[4].trim().replace('/', '');
                        selectedReport =
                            forceMultiChoice &&
                            !isNullOrUndefined(
                                reports.find((r) => r.id === reportId && r.geo === reportGeoId)
                            )
                                ? reports.find((r) => r.id === reportId && r.geo === reportGeoId)
                                : selectedReport;
                        reportId = selectedReport.id;
                        reportGeoId = selectedReport.geo;
                        for (let r of reports) {
                            r.isDefault = r.id === reportId && r.geo === reportGeoId; // reset before passing to next stage
                        }
                    }
                    launchArgs.routerMode = 'path';
                    navigationPrefix = pathMatch[1];
                }
                if (forceMultiChoice) {
                    if (
                        !isNullOrWhitespace(injectedQuery.get('index')?.toString()) &&
                        injectedQuery.get('index') === 'tab'
                    ) {
                        const handleLinkTabClick = (e) => {
                                const linkId = (e.target || e.currentTarget)
                                        .closest('li')
                                        .getAttribute('data-report-id'),
                                    tabs = (e.target || e.currentTarget)
                                        .closest('.nav-tabs')
                                        .querySelectorAll('li');
                                tabs.forEach((t) => {
                                    t.setAttribute(
                                        'class',
                                        t.getAttribute('data-report-id') === linkId
                                            ? 'active'
                                            : 'inactive'
                                    );
                                });
                            },
                            linkTabs = reports.map((r) => {
                                const lbl =
                                        geos !== undefined &&
                                        geos.find((g) => g.id === r.geo) !== undefined
                                            ? geos.find((g) => g.id === r.geo).label
                                            : r.name,
                                    urlSfx =
                                        catalog !== undefined && catalog !== null
                                            ? `/${r.geo}`
                                            : ''; // Append a core layer ID if provided
                                return (
                                    <li
                                        key={r.id}
                                        data-report-id={r.id}
                                        className={r.id === reportId ? 'active' : 'inactive'}
                                    >
                                        <RouterLink
                                            url={`/view-${
                                                dataMode === 'static' ? 'publication' : 'report'
                                            }/${r.id}${urlSfx}`}
                                            urlPrefix={navigationPrefix}
                                            afterClick={handleLinkTabClick}
                                            label={lbl}
                                        />
                                    </li>
                                );
                            });
                        if (!preHashed && !preRouted) {
                            const urlSfx =
                                catalog !== undefined && catalog !== null ? `/${reportGeoId}` : ''; // Append a core layer ID if provided
                            window.location.replace(
                                `${window.location.href.split('#')[0]}#/view-${
                                    dataMode === 'static' ? 'publication' : 'report'
                                }/${reportId}${isMultiChoice ? '' : `/${featureId}${urlSfx}`}`
                            );
                        }
                        launchFunction(launchArgs, <ul className="nav nav-tabs">{linkTabs}</ul>);
                    } else {
                        const labelledReports = reports.map((r) => {
                            const lbl =
                                geos !== undefined && geos.find((g) => g.id === r.geo) !== undefined
                                    ? geos.find((g) => g.id === r.geo).label
                                    : r.name;
                            return {
                                ...r,
                                label: lbl,
                                isDefault: r.id === reportId && r.geo === reportGeoId
                            };
                        });
                        if (!preHashed && !preRouted) {
                            const urlSfx =
                                catalog !== undefined && catalog !== null ? `/${reportGeoId}` : ''; // Append a core layer ID if provided
                            window.location.replace(
                                `${window.location.href.split('#')[0]}#/view-${
                                    dataMode === 'static' ? 'publication' : 'report'
                                }/${reportId}${
                                    isMultiChoice && false ? '' : `/${featureId}`
                                }${urlSfx}`
                            );
                        }
                        // This helps to build crawlable URLs but lets controls still act...but is optional
                        const linkPattern = internalLinks
                            ? ''
                            : `${window.location.pathname}?report={report}&feature={feature}`;
                        launchFunction(
                            launchArgs,
                            <div className="ia-controls">
                                <ReportSelectorDropDowns
                                    reports={labelledReports}
                                    feature={
                                        featureId !== '___iaFirstFeature'
                                            ? featureId?.toString()
                                            : undefined
                                    }
                                    pattern={linkPattern}
                                    onChange={(path) => {
                                        //console.log(`Navigation to ${path}`); // DEBUG
                                    }}
                                    includeGeoIdInPath={catalog !== undefined && catalog !== null}
                                    catalogUrl={
                                        catalog !== undefined && catalog !== null
                                            ? `${
                                                  typeof catalog.url !== 'undefined'
                                                      ? catalog.url
                                                      : typeof catalog.id !== 'undefined'
                                                      ? catalog.id
                                                      : catalog.toString()
                                              }`
                                            : undefined
                                    }
                                ></ReportSelectorDropDowns>
                            </div>
                        );
                    }
                } else {
                    if (!preHashed && !preRouted) {
                        const featureId = !isNullOrWhitespace(
                            injectedQuery.get('feature')?.toString()
                        )
                            ? injectedQuery.get('feature')
                            : !isNullOrWhitespace(pageQuery.get('feature')?.toString())
                            ? pageQuery.get('feature')
                            : null;
                        const urlSfx =
                            catalog !== undefined && catalog !== null ? `/${reportGeoId}` : ''; // Append a core layer ID if provided
                        window.location.replace(
                            !isNullOrWhitespace(featureId)
                                ? `${window.location.href.split('#')[0]}#/view-${
                                      dataMode === 'static' ? 'publication' : 'report'
                                  }/${reportId}/${featureId}${urlSfx}`
                                : `${window.location.href.split('#')[0]}#/view-${
                                      dataMode === 'static' ? 'publication' : 'report'
                                  }/${reportId}${
                                      isMultiChoice ? '' : `/___iaFirstFeature${urlSfx}`
                                  }`
                        );
                    }
                    launchFunction(launchArgs);
                }
                injected = true;
            };

            // Standalone items within one page - no navigation
            if (
                injectedQuery.get('auto') !== null &&
                injectedQuery.get('auto')?.toString().toLowerCase() === 'true'
            ) {
                const embeds = document.querySelectorAll(launchArgs.container || 'never');
                if (embeds.length > 1) launchArgs.routerMode = 'static';
                embeds.forEach((e, idx) => {
                    // Self-configured...
                    const uuid = `ia-embed-${generateGuid()}`,
                        cfg = e.getAttribute('data-config') || '';
                    if (!isNullOrWhitespace(cfg) && cfg !== '') {
                        const reportConfig =
                                cfg.indexOf('{') >= 0
                                    ? JSON.parse(cfg)
                                    : lowerKeyParams(new URLSearchParams(cfg)),
                            localReportId =
                                reportConfig.reports !== undefined
                                    ? reportConfig.reports[0].id
                                    : reportConfig.report,
                            localFeatureId =
                                reportConfig.feature !== undefined
                                    ? reportConfig.feature
                                    : injectedQuery.get('feature') ||
                                      pageQuery.get('feature') ||
                                      '___iaFirstFeature',
                            localLaunchArgs = {
                                ...launchArgs,
                                container: `.${uuid}`,
                                location: `/embed-${
                                    dataMode === 'static' ? 'publication' : 'report'
                                }/${localReportId}/${localFeatureId}`,
                                routerMode: 'static',
                                onReady: callbackFnc
                            };
                        // Multi-choice report or want a drop down - default to other code...
                        if (
                            reportConfig.reports !== undefined &&
                            (reportConfig.reports.length > 1 ||
                                reportConfig.dropDown === undefined ||
                                reportConfig.dropDown !== false)
                        ) {
                            populateReportControls(reportConfig, true);
                            addClass(e as HTMLElement, uuid);
                        } else {
                            addClass(e as HTMLElement, uuid);
                            launchFunction(localLaunchArgs);
                        }
                    } else console.log('Cannot launch reports - no configuration!');
                });
            }
            // Automatic - get embedded content from the master file...
            else {
                const site =
                        injectedQuery.get('site') !== null && injectedQuery.get('site') !== ''
                            ? (injectedQuery.get('site') || '').toString()
                            : window.location.hostname,
                    matches = (
                        injectedQuery.get('url') !== null && injectedQuery.get('url') !== ''
                            ? (injectedQuery.get('url') || '').toString()
                            : window.location.href
                    ).match(/^(http:\/\/|https:\/\/)([.\w-]+)\/(.*)$/),
                    /*
                     * 0 = https://customer.wpengine.com/children-and-young-people/reports/
                     * 1 = https://
                     * 2 = customer.wpengine.com
                     * 3 = children-and-young-people/reports/
                     */
                    path = (
                        injectedQuery.get('path') !== null && injectedQuery.get('path') !== ''
                            ? (injectedQuery.get('path') || '').toString()
                            : matches !== null && matches.length > 3
                            ? matches[3] || ''
                            : ''
                    )
                        .toLowerCase()
                        .split('/')[0];
                get(
                    `${IA_CUSTOMER_SITE_CONFIG_HOST.replace(
                        /^(.*)\/$/,
                        '$1'
                    )}/${IA_CUSTOMER_FILENAME_STEM}-list.json`
                )
                    .then((customerList: any = {}) => {
                        if (!isNullOrUndefined(customerList[site])) {
                            get(
                                `${IA_CUSTOMER_SITE_CONFIG_HOST.replace(
                                    /^(.*)\/$/,
                                    '$1'
                                )}/${IA_CUSTOMER_FILENAME_STEM}-package.${
                                    customerList[site].id
                                }.json`
                            ).then((customerInfo = {}) => {
                                const reportsSubPath = (
                                        customerInfo.reportsPath !== undefined
                                            ? customerInfo.reportsPath
                                            : 'reports'
                                    ).toLowerCase(),
                                    mapExplorerSubPath = (
                                        customerInfo.mapExplorerPath !== undefined
                                            ? customerInfo.mapExplorerPath
                                            : 'map'
                                    ).toLowerCase(),
                                    trueMatches =
                                        matches !== null &&
                                        matches.length > 3 &&
                                        matches[3] !== undefined
                                            ? matches[3].split('#')[0].split('?')[0].split('/')
                                            : [],
                                    lastMatch =
                                        trueMatches.length > 0
                                            ? trueMatches.filter((t) => t.length > 0).pop()
                                            : null,
                                    isMultiChoice =
                                        lastMatch !== undefined &&
                                        lastMatch !== null &&
                                        lastMatch.trim() === reportsSubPath,
                                    isMapExplorer =
                                        lastMatch !== undefined &&
                                        lastMatch !== null &&
                                        lastMatch.trim() === mapExplorerSubPath;
                                if (
                                    !isNullOrUndefined(customerInfo.themes) &&
                                    !isNullOrUndefined(customerInfo.themes[path])
                                ) {
                                    // Map Explorer? In which case we are no longer in this app, so just inject then bail...
                                    // This needs refactoring if/when we redevelop the map explorers...
                                    if (isMapExplorer) {
                                        const appHostKey = (appHost || '')
                                                .replace('https://', '')
                                                .replace('http://', '')
                                                .replace('//', '')
                                                .split('/')[0],
                                            mapHostLookup = {
                                                'www.reports.esriuk.com': 'hub.instantatlas.com',
                                                'reports.instantatlas.com': 'hub.instantatlas.com',
                                                'test.reports.esriuk.com':
                                                    'ia-data-catalog-hub-test.azurewebsites.net',
                                                'development.reports.esriuk.com':
                                                    'esriuk-apps-dev-builds.azurewebsites.net'
                                            },
                                            mapHost =
                                                mapHostLookup[appHostKey] !== undefined
                                                    ? mapHostLookup[appHostKey]
                                                    : 'hub.instantatlas.com',
                                            mapContainer = document.querySelector(
                                                launchArgs.container || 'never'
                                            ),
                                            mapContentId =
                                                `map${customerList[site].id}${path}`.replace(
                                                    /[^a-zA-Z0-9]/g,
                                                    '_'
                                                ),
                                            mapContentPanel = document.createElement('div'),
                                            mapScriptUrl = `https://${mapHost}/map-explorer/static/js/explorer.js`,
                                            mapStyleUrl = `https://${mapHost}/map-explorer/static/css/explorer.css`,
                                            mapScriptTag = document.createElement('script'),
                                            mapLaunchTag = document.createElement('script'),
                                            mapStyleTag = document.createElement('link'),
                                            loadingMsg = document.createElement('div'),
                                            mapLaunchScript =
                                                `(function() { var launchId = 0; var launchAttempts = 0; launchId = window.setInterval(function() { launchAttempts++; if (window.mapExplorer !== undefined) { window.mapExplorer.launch({ container: '${mapContentId}', appid: '${customerInfo.themes[path].explorer.appid}' });` +
                                                `setTimeout(function() { var mc = document.getElementById('${mapContentId}'); mc.querySelector('.initial').remove(); }, 500); clearInterval(launchId); } if (launchAttempts > 200) { clearInterval(launchId); } }, 250); })();`;
                                        mapContentPanel.setAttribute('id', mapContentId);
                                        mapContentPanel.setAttribute(
                                            'class',
                                            'content-holder ia-explorer-container ia-map-panel'
                                        );
                                        //mapContentPanel.setAttribute('style', 'width: 100%; height: 600px;');
                                        loadingMsg.setAttribute('class', 'initial');
                                        loadingMsg.innerHTML = `<i class="fas fa-cog fa-spin"></i> Loading explorer. Please wait...</div>`;
                                        mapContentPanel.appendChild(loadingMsg);
                                        mapContainer?.appendChild(mapContentPanel);
                                        mapStyleTag.setAttribute('href', mapStyleUrl);
                                        mapStyleTag.setAttribute('rel', 'stylesheet');
                                        mapContainer?.appendChild(mapStyleTag);
                                        mapScriptTag.setAttribute('src', `${mapScriptUrl}`);
                                        mapContainer?.appendChild(mapScriptTag);
                                        mapContainer?.appendChild(mapLaunchTag);
                                        mapLaunchTag.appendChild(
                                            document.createTextNode(mapLaunchScript)
                                        );
                                    } else {
                                        populateReportControls(
                                            {
                                                ...customerInfo.themes[path],
                                                geos: customerInfo.geos,
                                                catalog: customerInfo.catalog,
                                                bodyClass: customerInfo.bodyClass,
                                                chartPalette: customerInfo.chartPalette, // probably undefined
                                                chartComparisonsPalette:
                                                    customerInfo.chartComparisonsPalette, // probably undefined
                                                routing:
                                                    injectedQuery.get('routing') !== null &&
                                                    injectedQuery.get('routing') !== ''
                                                        ? injectedQuery.get('routing')
                                                        : undefined
                                            },
                                            isMultiChoice
                                        );
                                    }
                                } else
                                    throw new Error(
                                        `Cannot find ${path} in themes for site ${site}`
                                    );
                            });
                        } else throw new Error(`Cannot find ${site} in customer list`);
                    })
                    .catch((ajaxErr) => {
                        console.log(
                            `Cannot start Report Builder for ArcGIS reports in this page - error shown below.`
                        );
                        console.log(ajaxErr);
                    });
            }
        } else
            console.log(
                `Cannot start Report Builder for ArcGIS reports in this page - required arguments missing from query '${injectedQS}'`
            );
    }
    return injectionRequired || injected;
};

const RouterLink = ({ url, urlPrefix = '#', label, className = 'btn-link', afterClick }) => {
    const history = useHistory();

    const handleClick = (e) => {
        history.push(url);
        if (afterClick !== undefined) afterClick(e, url);
        e.preventDefault();
    };

    return (
        <a href={`${urlPrefix}${url}`} className={className} onClick={handleClick}>
            {label}
        </a>
    );
};
