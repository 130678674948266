import AbstractWidget, { convertLengthToPixels, lengthWithUnit } from './AbstractWidget';
import AbstractChartWidget, { getBestTextForWidth, createSwatchImage } from './AbstractChartWidget';
import { TextWidget } from './TextWidget';
import { isNullOrWhitespace, hyphenate } from '../utils/object';
import { getNumberFormatter } from '../utils/localization';
import { DataManipulator } from 'data-catalog-js-api';
import { nullifyEmptyValues } from './widgetDataUtils';
import ChartJS from '../lib/ChartJS';

export class PieChartWidget extends AbstractChartWidget {
    _layoutType = 'pie'; // or 'polar' - which hard-wires some other options

    constructor(containerHtmlElement, design, specialType = 'pie') {
        super(containerHtmlElement, design);
        this._layoutType = specialType;
    }

    // Render here is slightly special, because it can _detect_ if the calling (React) class has laid out some of the HTML before calling this method.
    render = (data, options = {}) => {
        if (
            this.container !== undefined &&
            this.container !== null &&
            this.design !== undefined &&
            this.design !== null
        ) {
            // Basics - this will give us the box or the chart content area... using super class
            const settings = {
                    ...PieChartWidget.getDefaults(),
                    ...this.design
                },
                chartOpts = {
                    ...this.getChartOptions(settings)
                },
                //chartContainerElement
                chartContentElement = this.renderBox(data, {
                    element: 'figure',
                    boxClass: 'ia-chart-box',
                    showTitle: hyphenate(settings.titlePosition) !== 'inside-chart',
                    messagePadType: 'padding',
                    allowBackgroundFill: false,
                    settings: {
                        ...settings,
                        messageBackground: settings.backgroundColor // Transfer chart background to message as well
                    }
                }),
                //chartContentElement = this.container.ownerDocument.createElement('figure'),
                chartLegendElement = this.container.ownerDocument.createElement('div'),
                topWidget = this.container.closest('.ia-report-widget'),
                { activeFeature } = options,
                dataTable = DataManipulator.mergeTables(data, !settings.includeAllAreas), // Discard non-common features  unless they are comparisons
                dataAvailable =
                    dataTable !== undefined &&
                    dataTable !== null &&
                    dataTable.colIds !== undefined &&
                    dataTable.colIds.length > 0 &&
                    dataTable.rows !== undefined &&
                    dataTable.rows !== null,
                iAliases = this.indicatorAliases,
                aliasesProvided = iAliases !== undefined && iAliases !== null && iAliases.length > 0,
                palette =
                    settings.palette !== undefined && settings.palette !== null && settings.palette !== ''
                        ? AbstractChartWidget.getPaletteColors(settings.palette)
                        : [],
                keyedPalette = AbstractChartWidget.getKeyedPaletteColors(settings.palette),
                nfmt = getNumberFormatter(settings.locale, settings.numberFormat),
                allowLegendInChart = true; //this._layoutType === 'pie' && settings.legendInChart;
            if (dataAvailable) {
                if (settings.showLegend) {
                    const legPos = (settings.legendPosition || 'right').toLowerCase(),
                        hzFlex = legPos === 'left' || legPos === 'right',
                        leg1st = legPos === 'left' || legPos === 'top',
                        legWeight = settings.legendWeight !== undefined ? settings.legendWeight : 20;
                    if (allowLegendInChart) {
                        if (hzFlex) {
                            const lmw = legWeight * 0.01 * parseFloat(lengthWithUnit(settings.width).replace('px', ''));
                            chartOpts.plugins.legend.maxWidth = lmw;
                        } else {
                            const lmh =
                                legWeight * 0.01 * parseFloat(lengthWithUnit(settings.height).replace('px', ''));
                            chartOpts.plugins.legend.maxHeight = lmh;
                        }
                    }
                    if (!allowLegendInChart) {
                        const chartContainerElement = document.createElement('div');
                        // TODO - review the interaction code here: https://dev.to/giselamd/creating-a-custom-chart-js-legend-style-50i5
                        chartContainerElement.setAttribute(
                            'style',
                            `display: flex; flex-direction: ${hzFlex ? 'row' : 'column'}; align-items: stretch;`
                        );
                        // Now the items
                        chartContentElement.setAttribute('class', 'ia-chart-flex-box');
                        chartContentElement.setAttribute(
                            'style',
                            `flex: 1 1 ${100 - legWeight}%; max-${hzFlex ? 'height' : 'width'}: 100%; order: 1;`
                        );
                        chartLegendElement.setAttribute(
                            'class',
                            `ia-chart-legend ia-chart-legend-${legPos} ${
                                settings.legendReverseOrder ? 'reversed' : 'normal'
                            }`
                        );
                        chartLegendElement.setAttribute(
                            'style',
                            `flex: 1 1 ${legWeight}%; max-${hzFlex ? 'height' : 'width'}: 100%; order: ${
                                leg1st ? 0 : 2
                            }; background-color: ${settings.backgroundColor || 'transparent'}; color: ${
                                settings.legendFontColor || 'inherit'
                            };`
                        );
                        chartContainerElement.appendChild(chartLegendElement);
                        if (settings.legendFontFamily !== undefined)
                            chartLegendElement.style.fontFamily = settings.legendFontFamily;
                        if (settings.legendFontSize !== undefined)
                            chartLegendElement.style.fontSize = lengthWithUnit(settings.legendFontSize);
                    }
                }
                //chartContainerElement.appendChild(chartContentElement);
                const canvas = this.createCanvas(settings, data, chartContentElement);
                //allDataTable = DataManipulator.mergeTables(data.filter(d => (d.indicators !== undefined) && (d.indicators.length > 0)));
                //console.log(settings); // DEBUG
                // Split data up - by indicator to start with... unless we are counting?
                // Pre-test for unique values...
                const valueSet =
                    settings.useRestrictedDistinctValues !== undefined &&
                    settings.useRestrictedDistinctValues !== null &&
                    settings.useRestrictedDistinctValues !== ''
                        ? settings.useRestrictedDistinctValues.split(',')
                        : keyedPalette !== undefined && keyedPalette !== null
                        ? Array.from(keyedPalette.keys())
                        : [];
                let chartData = data
                    .filter((d) => d.indicators !== undefined && d.indicators.length > 0)
                    .map((d, di) => {
                        AbstractWidget.filterTable(
                            d,
                            settings.hideMainFeature === true ? [] : null,
                            settings.showComparisons === false
                                ? []
                                : !isNullOrWhitespace(settings.comparisonFeatureIds)
                                ? settings.comparisonFeatureIds.split(',').map((f) => f.split('|')[1])
                                : null
                        );
                        // But if required, re-sort
                        if (aliasesProvided) {
                            AbstractWidget.applySortAndRename(d.colIds, iAliases, d.indicators, d.rows);
                            d.headers = d.colIds.map((c) => c.label);
                        }
                        let mappedToValues = false;
                        if (valueSet.length > 0) {
                            const keyedCounts = new Map(
                                valueSet.map((k) => {
                                    return [k, 0];
                                })
                            );
                            for (let r of d.rows) {
                                const v = r[1] !== undefined && r[1] !== null ? r[1].toString() : '';
                                if (keyedCounts.has(v)) keyedCounts.set(v, keyedCounts.get(v) + 1);
                            }
                            // Only apply an update if there are non-zero values because otherwise the chart is pointless
                            mappedToValues =
                                Array.from(keyedCounts.values()).filter((v) => v > 0).length > 0.25 * keyedCounts.size;
                        }
                        const twisted =
                            settings.includeAllAreas || //&&
                            //hyphenate(settings.seriesBindingStyle) === 'features-as-series') ||
                            (valueSet.length > 0 && mappedToValues) // &&
                                ? //hyphenate(settings.seriesBindingStyle) === 'indicators-as-series')
                                  d
                                : DataManipulator.transposeTable(d);
                        return {
                            name: twisted.headers[1],
                            data: twisted,
                            colors: palette.slice(di)
                        };
                    });
                for (let cd of chartData) {
                    let aind,
                        offset = 0;
                    for (let r of cd.data.rows) {
                        aind =
                            aliasesProvided && iAliases.find((ia) => ia.id === r[0].id) !== undefined
                                ? iAliases.find((ia) => ia.id === r[0].id).label
                                : r[0].name;
                        r[0] = settings.seriesLabelFormat
                            .replace(/#NAME/g, aind !== undefined ? aind : r[0]) // Depends on includeAllAreas or specific twisting of data to match incoming headers
                            .replace(/#FNAME/g, r[0])
                            .replace(/#INAME/g, aind)
                            .replace(/#IDATE|#DATE/g, cd.data.rowIds[offset].label || '') // Presuming 1st header is the date, twisted!
                            .replace(/#POINTINDEXSYM|#PIDXSYM/g, '①②③④⑤⑥⑦⑧⑨⑩⑪⑫⑬⑭⑮⑯⑰⑱⑲⑳⓪'.charAt(offset))
                            .replace(
                                /#INDEXALPHA|#INDEXA|#IDXA|#DATASETALPHA|#DATASETA/g,
                                'abcdefghijklmnopqrstuvwxyz0123456789'.charAt(offset)
                            )
                            .replace(/#INDEX|#IDX|#DATASET|#POINTINDEX|#PIDX/g, (offset + 1).toFixed(0));
                        offset++;
                    }
                }
                // Specialist sub-type of chart - take the values and make them into a set of counts
                if (
                    settings.useCountsPerDistinctValue ||
                    valueSet.length > 0 //&&
                    //hyphenate(settings.seriesBindingStyle) === 'indicators-as-series'
                ) {
                    // TODO - deal with looking up the distinct values
                    // Re-sort and re-count the data and adjust in-place to provide correct input to chart
                    for (let cd of chartData) {
                        const keyedCounts = new Map(
                            valueSet.map((k) => {
                                return [k, 0];
                            })
                        );
                        for (let r of cd.data.rows) {
                            const v = r[1] !== undefined && r[1] !== null ? r[1].toString() : '';
                            if (keyedCounts.has(v)) keyedCounts.set(v, keyedCounts.get(v) + 1);
                        }
                        // Only apply an update if there are non-zero values because otherwise the chart is pointless
                        if (Array.from(keyedCounts.values()).filter((v) => v > 0).length > 0.25 * keyedCounts.size) {
                            cd.data.rowIds = Array.from(keyedCounts.keys());
                            cd.data.rows = Array.from(keyedCounts);
                            cd.colors = palette;
                        }
                    }
                }
                // Data adjustment? See https://stackoverflow.com/questions/61212912/how-to-group-smaller-pie-chart-slices-together-to-improve-readability-in-chartjs
                if (chartData.length === 1 && settings.thresholdPercent !== undefined) {
                    const valueSum = chartData[0].data.rows.reduce((a, b) => a + b[1], 0),
                        thresholdPercent = settings.thresholdPercent,
                        slicedRows = chartData[0].data.rows.reduce((accumulator, currentRow) => {
                            const percent = (100 * currentRow[1]) / valueSum;
                            if (percent < thresholdPercent) {
                                const others = accumulator.find((o) => o[0] === '___others');
                                if (others === undefined)
                                    return accumulator.concat([['___others', ...currentRow.slice(1)]]);
                                for (let j = 1; j < currentRow.length; j++) {
                                    others[j] += currentRow[j];
                                }
                            } else accumulator.push(currentRow);
                            return accumulator;
                        }, []);
                    if (slicedRows.length < chartData[0].data.rows.length) {
                        const rowKeys = slicedRows.map((r) => r[0]),
                            others = slicedRows.find((o) => o[0] === '___others');
                        for (let i = chartData[0].data.rows.length - 1; i >= 0; i--) {
                            if (rowKeys.indexOf(chartData[0].data.rows[i][0]) < 0) {
                                chartData[0].data.rows.splice(i, 1);
                                chartData[0].data.rowIds.splice(i, 1);
                            }
                        }
                        if (others !== undefined) {
                            others[0] = settings.seriesLabelFormat
                                .replace(/#NAME/g, settings.thresholdOthersLabel || 'Others')
                                .replace(/#FNAME/g, settings.thresholdOthersLabel || 'Others')
                                .replace(/#INAME/g, settings.thresholdOthersLabel || 'Others')
                                .replace(/#IDATE|#DATE/g, '') // Presuming 1st header is the date, twisted!
                                .replace(
                                    /#INDEXALPHA|#INDEXA|#IDXA|#DATASETALPHA|#DATASETA/g,
                                    'abcdefghijklmnopqrstuvwxyz0123456789'.charAt(chartData[0].data.rows.length)
                                )
                                .replace(
                                    /#INDEX|#IDX|#DATASET|#POINTINDEX|#PIDX/g,
                                    (chartData[0].data.rows.length + 1).toFixed(0)
                                );
                            chartData[0].data.rows.push(others);
                            chartData[0].data.rowIds.push('___others');
                        }
                    }
                }
                //console.log(chartData); // DEBUG
                if (settings.animated)
                    topWidget.setAttribute('class', `${topWidget.getAttribute('class')} ia-animated`);
                // chart.js 3 needs empty values not undefined - in-place
                nullifyEmptyValues(chartData, false);
                const chartEngine = new ChartJS(canvas.getContext('2d')),
                    chartTitleText = TextWidget.insertValuesIntoText(
                        settings.titleText,
                        data,
                        settings.numberFormat,
                        settings.locale,
                        '',
                        false
                    ),
                    cutout = parseFloat(settings.doughnutHoleSize),
                    circumference = parseFloat(settings.arcDegrees),
                    startOffset = parseFloat(settings.segmentStartOffset),
                    rotateStart = isNaN(startOffset) ? 270 : startOffset; // circumference !== 360
                // Disable the default legend...?
                if (!allowLegendInChart) chartOpts.displayLegend = false;
                // But if we need one...
                if (settings.showLegend && !allowLegendInChart) {
                    chartOpts.legendCallback = (chart) => {
                        const odoc = this.container.ownerDocument,
                            ul = this.container.ownerDocument.createElement('ul'),
                            bgColors = chart.data.datasets.length > 0 ? chart.data.datasets[0].backgroundColor : [];
                        chart.data.labels.forEach((label, index) => {
                            const limg = createSwatchImage(odoc, bgColors[index]);
                            ul.innerHTML += `
                                <li class="legend-item">
                                    <span class="swatch" style="background-color: ${bgColors[index]}"><img class="swatch-image" src="${limg.src}" alt="swatch image" /></span>
                                    <span class="legend-label">${label}</span>
                                </li>
                            `; // ^ ES6 Template String
                        });
                        return ul.outerHTML;
                    };
                }
                const dataPointLabels =
                        settings.showSegmentLabels !== undefined &&
                        settings.showSegmentLabels !== null &&
                        settings.showSegmentLabels.toLowerCase() !== 'none' &&
                        settings.showSegmentLabels.toLowerCase() !== 'disabled',
                    chartPlugins = {
                        datalabels: {
                            enabled: dataPointLabels,
                            font: {
                                family: settings.axisFontFamily,
                                size: settings.axisFontSize
                            },
                            formatter: function (value, context) {
                                //console.log(value); // DEBUG
                                return typeof value === 'number' ? nfmt.format(value) : '' + value;
                            }
                        }
                    };
                const { pieBorderWidth, pieBorderColor } = settings;
                if (
                    pieBorderWidth !== undefined &&
                    pieBorderWidth !== null &&
                    pieBorderColor !== undefined &&
                    pieBorderColor !== null
                ) {
                    for (let cd of chartData) {
                        cd.borderWidth = convertLengthToPixels(pieBorderWidth, true, true);
                        cd.borderColor = pieBorderColor;
                    }
                }
                this.chart = chartEngine.render({
                    name: !isNaN(cutout) && cutout > 0 ? 'doughnut' : 'pie',
                    datasets: chartData,
                    config: {
                        fillTo100: settings.fillTo100,
                        fillTo100Color: settings.fillTo100Color,
                        type: !isNaN(cutout) && cutout > 0 ? 'doughnut' : 'pie',
                        fontFamily: settings.axisFontFamily,
                        numberFormatter: nfmt,
                        displayLegend: settings.showLegend && allowLegendInChart,
                        titles:
                            hyphenate(settings.titlePosition) === 'inside-chart' &&
                            chartTitleText !== null &&
                            chartTitleText !== ''
                                ? getBestTextForWidth(
                                      canvas.getContext('2d'),
                                      chartTitleText,
                                      convertLengthToPixels(settings.width, true, true) - 40,
                                      settings.axisFontFamily,
                                      settings.titleFontSize
                                  ).lines
                                : [],
                        titleFontSize: parseInt(settings.titleFontSize),
                        titleFontWeight: settings.titleIsBold === true ? 'bold' : 'normal',
                        titleFontColor:
                            !isNullOrWhitespace(settings.titleColor) && hyphenate(settings.titleColor) !== 'not-set'
                                ? settings.titleColor
                                : undefined,
                        options: {
                            ...chartOpts,
                            cutout: !isNaN(cutout) ? `${cutout}%` : 0,
                            rotation: !isNaN(rotateStart) ? rotateStart : 0, // Pie/Doughnut
                            scales: { r: { startAngle: !isNaN(rotateStart) ? rotateStart : 0 } }, // Polar
                            circumference
                        },
                        plugins: chartPlugins,
                        maintainAspectRatio: false,
                        animationResizeDuration: 300
                    },
                    preRender: (chartConfig) => {
                        if (this._layoutType !== 'pie') chartConfig.type = this._layoutType; // TODO - axes?
                        if (
                            settings.thresholdOthersColor !== undefined &&
                            chartConfig.data.keys !== undefined &&
                            chartConfig.data.datasets !== undefined
                        ) {
                            const othersIndex = chartConfig.data.keys.findIndex((k) => k.toString() === '___others');
                            if (othersIndex > 0) {
                                for (let ds of chartConfig.data.datasets) {
                                    if (
                                        ds.backgroundColor !== undefined &&
                                        Array.isArray(ds.backgroundColor) &&
                                        ds.backgroundColor.length > othersIndex
                                    ) {
                                        ds.backgroundColor[othersIndex] = settings.thresholdOthersColor;
                                    }
                                }
                            }
                        }
                        if (settings.pieBorderColorComparisons !== undefined) {
                            const activeIds = chartData[0].data.colIds
                                .filter((c) => c.comparison === true)
                                .map((c) => c.id);
                            for (let ds of chartConfig.data.datasets) {
                                if (ds.id !== undefined && activeIds.indexOf(ds.id) >= 0) {
                                    ds.borderColor = settings.pieBorderColorComparisons;
                                }
                            }
                        }
                        if (keyedPalette !== null && chartConfig.data.datasets.length > 0) {
                            const labels = chartConfig.data.labels,
                                colorPalette = new Array(labels.length);
                            for (let i = 0; i < labels.length; i++) {
                                colorPalette[i] = keyedPalette.has(labels[i])
                                    ? keyedPalette.get(labels[i])
                                    : keyedPalette.has(i.toString())
                                    ? keyedPalette.get(i.toString())
                                    : settings.thresholdOthersColor !== undefined
                                    ? settings.thresholdOthersColor
                                    : palette[i];
                                //labels[i] = settings.xAxisLabelFormat.replace(/#NAME/g, labels[i]);
                            }
                            const specifiedColors = colorPalette.filter((c) => c !== undefined && c !== null);
                            for (let ds of chartConfig.data.datasets) {
                                ds.backgroundColor = specifiedColors.length > 1 ? specifiedColors : specifiedColors[0];
                            }
                        }
                        if (dataPointLabels) {
                            this.applyDataPointLabels(
                                {
                                    ...settings,
                                    labelDataPointsFormat: settings.segmentLabelFormat,
                                    labelDataPointsAnchor:
                                        settings.showSegmentLabels.toLowerCase() === 'inside' ? 'center' : 'end',
                                    labelDataPointsAlign:
                                        settings.showSegmentLabels.toLowerCase() === 'inside' ? 'center' : 'end'
                                },
                                chartConfig
                            );
                        }
                        if (
                            settings.includeAllAreas &&
                            settings.highlightSelectedFeature &&
                            settings.highlightColor !== undefined &&
                            settings.highlightColor !== null &&
                            activeFeature !== undefined &&
                            activeFeature !== null &&
                            chartData.length > 0 &&
                            chartConfig.data.keys !== undefined &&
                            chartConfig.data.keys !== null
                        ) {
                            const activeIds = Array.isArray(activeFeature)
                                    ? activeFeature.map((f) => f.id)
                                    : [activeFeature.id],
                                activeIndices = chartConfig.data.keys
                                    .map((r, i) => (activeIds.indexOf(r) >= 0 ? i : -1))
                                    .filter((i) => i >= 0);
                            //chartConfig.data.labelKeys = activeData.rowIds.slice(0);
                            if (activeIndices.length >= 0) {
                                for (let ds of chartConfig.data.datasets) {
                                    const colors = new Array(ds.data.length),
                                        widths = new Array(ds.data.length),
                                        arrayAlready = Array.isArray(ds.backgroundColor);
                                    for (let i = 0; i < colors.length; i++) {
                                        colors[i] = arrayAlready ? ds.backgroundColor[i] : ds.backgroundColor;
                                        widths[i] = 0;
                                    }
                                    for (let i of activeIndices) {
                                        colors[i] = settings.highlightColor;
                                        widths[i] = 1;
                                    }
                                    if (
                                        settings.highlightStyle !== undefined &&
                                        settings.highlightStyle.toLowerCase() === 'border'
                                    ) {
                                        ds.borderColor = colors;
                                        ds.borderWidth = widths;
                                    } else ds.backgroundColor = colors;
                                }
                            }
                        }
                        this.applyEvents(settings, chartConfig, options);
                    },
                    onComplete: (ca) => {
                        const populated = chartLegendElement.getAttribute('data-populated') || '';
                        if (populated === '' && settings.showLegend && !allowLegendInChart) {
                            chartLegendElement.innerHTML = `<div>${chartOpts.legendCallback(ca.chart)}</div>`; //ca.chart.generateLegend();
                            const chart = ca.chart,
                                legendItemSelector = '.legend-item',
                                legendItems = [...chartLegendElement.querySelectorAll(legendItemSelector)],
                                updateDataset = (currentEl, index) => {
                                    const meta = chart.getDatasetMeta(0),
                                        labelEl = currentEl.querySelector('.legend-label'),
                                        result = meta.data[index].hidden === true ? false : true;
                                    if (result === true) {
                                        meta.data[index].hidden = true;
                                        labelEl.style.textDecoration = 'line-through';
                                    } else {
                                        labelEl.style.textDecoration = 'none';
                                        meta.data[index].hidden = false;
                                    }
                                    chart.update();
                                };
                            legendItems.forEach((item, i) => {
                                item.addEventListener('click', (e) => {
                                    updateDataset(e.target.parentNode, i);
                                });
                            });
                            chartLegendElement.setAttribute('data-populated', 'yes');
                        }
                        //console.log(ca); // DEBUG
                        //if (!animBound && settings.animated)
                        //{
                        //    const widgetId = topWidget.getAttribute('id');
                        //    //window[`widgetChart${widgetId}`] = chart;
                        //    //AbstractChartWidget.bindAnimationUpdate(topWidget);
                        //    //topWidget.addEventListener('click', (e) =>
                        //    //{
                        //    //    setTimeout(() =>
                        //    //    {
                        //    //        console.log('click chart'); // DEBUG
                        //    //        console.log(chart); // DEBUG
                        //    //        chart.data.datasets = chart.data.datasets.slice();
                        //    //        chart.options. = chart.options.rotation + 1;
                        //    //        chart.update({
                        //    //            duration: 700
                        //    //        });
                        //    //    }, 250);
                        //    //});
                        //}
                    }
                });
                this.applyChartActions(settings, chartData);
                topWidget.setAttribute('class', topWidget.getAttribute('class').replace(/\s(placeholder)/g, ''));
            }
        }
    };

    static getDefaults = () => {
        return {
            ...AbstractChartWidget.getDefaults(),
            arcDegrees: 360,
            axisFontFamily: 'Lato',
            seriesLabelFormat: '#NAME',
            doughnutHoleSize: 0,
            legendWeight: 20,
            legendInChart: true,
            showSegmentLabels: 'none', // inside | outside | none | disabled (legacy value), was 'pieLabels'
            segmentLabelFormat: '#PERCENT',
            labelDataPointsOffset: '-3px',
            labelDataPointsColor: '#111111',
            segmentStartOffset: 270,
            fillTo100: false,
            fillTo100Color: '#f7f7f7',
            //seriesBindingStyle: 'features-as-series',
            thresholdPercent: 3,
            thresholdOthersLabel: 'Others',
            thresholdOthersColor: '#cccccc',
            pieBorderColor: '#cccccc',
            pieBorderColorComparisons: '#ccccff'
        };
    };
}

export class PolarChartWidget extends PieChartWidget {
    constructor(container, design) {
        super(container, design, 'polarArea');
    }

    static getDefaults = () => {
        return {
            ...PieChartWidget.getDefaults(),
            legendInChart: false
        };
    };
}
