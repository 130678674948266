import React, { useContext, useEffect } from 'react';
import { isNullOrUndefined } from '../utils/object';
import MenuContext from './MenuContext';

const MenuToggleButton = ({
    id,
    element,
    elementClassName = '',
    className = '',
    text,
    tooltip = '',
    children,
    ...others
}) => {
    const [menuOpen, setMenuOpen] = useContext(MenuContext),
        containerElementName = !isNullOrUndefined(element) ? element : 'div',
        btnClassName = className !== undefined && className !== '' ? className : 'btn btn-default',
        button = React.useRef(null),
        htmlElement = React.useRef(null);

    const clearCurrentMenu = (e) => {
        if (e !== undefined && e.target !== undefined && e.target.closest('.ia-menu-container') === null)
            setMenuOpen('');
    };

    useEffect(() => {
        const handleKeyboard = (e) => {
            if (e.keyCode === 27) setMenuOpen('');
        };
        if (menuOpen === id) {
            document.addEventListener('mouseup', clearCurrentMenu);
            document.addEventListener('keydown', handleKeyboard);
        }
        return () => {
            if (menuOpen === id) {
                document.removeEventListener('mouseup', clearCurrentMenu);
                document.removeEventListener('keydown', handleKeyboard);
            }
        };
    });

    return React.createElement(
        containerElementName,
        {
            className: `${menuOpen === id ? 'open' : ''} ${
                elementClassName !== undefined && elementClassName !== '' ? elementClassName : ''
            } ia-menu-container`.trim(),
            onMouseUp:
                menuOpen === id
                    ? (e) => {
                          if (e.target.closest('.toggle-btn-def') === null) setMenuOpen('');
                      }
                    : undefined,
            ref: htmlElement
        },
        <React.Fragment>
            <button
                ref={button}
                className={`${btnClassName} toggle-btn-def`}
                type="button"
                onClick={() => setMenuOpen(menuOpen === id ? '' : id)}
                data-tooltip={tooltip !== null ? tooltip : ''}
                {...others}
            >
                {text}
            </button>
            <>{children}</>
        </React.Fragment>
    );
};

export default MenuToggleButton;
