import React from 'react';
import { useOnlineStatus } from './onlineStatus';

const OnlineAwarePanel = ({
    children,
    matchOnlineStatus = 'online'
}: {
    children?: any;
    matchOnlineStatus?: 'offline' | 'online';
}) => {
    const isOnline = useOnlineStatus();
    return (isOnline && matchOnlineStatus === 'online') ||
        (!isOnline && matchOnlineStatus === 'offline') ? (
        <React.Fragment>{children}</React.Fragment>
    ) : null;
};

export const OfflinePanel = ({ children }) => {
    return <OnlineAwarePanel matchOnlineStatus={'offline'}>{children}</OnlineAwarePanel>;
};

export const OnlinePanel = ({ children }) => {
    return <OnlineAwarePanel matchOnlineStatus={'online'}>{children}</OnlineAwarePanel>;
};
