import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { FormattedMessage } from 'react-intl';
import ReportWidgetPanel from './ReportWidgetPanel';
import { lengthWithUnit } from '../widgets/AbstractWidget';
import { isNullOrUndefined } from '../utils/object';
import { parseCssStyles } from '../utils/dom';
import { getNamedSize } from '../utils/report-migrate-plus';
import ListToggleButton from './ListToggleButton';
import { IDialogController } from './editor/EditorInterfacesAndTypes';
import '../assets/styles/components/ReportSection.scss';

interface IReportSectionArgs {
    globals: any;
    design: any;
    feature: any;
    className?: string;
    id?: string;
    index?: number;
    label?: string;
    editable: boolean;
    onWidgetClick?: (e: any) => void;
    onWidgetDoubleClick?: (e: any) => void;
    dialogController?: IDialogController;
    children?: any;
    layout?: 'relative' | 'absolute';
    size?: any;
    scale?: number;
    margin?: any;
    titleTag?: string;
    onRender: (e: any, i: any) => void;
    onVisibleChange: (i: number, visible: boolean, id?: string) => void;
    load: 'when-in-view' | 'always' | 'strict-when-in-view'; // when-in-view = load data 1st time scrolled or paged to, always = oad data independent of visibility, strict-when-in-view = reload data whenever viewport changes for this section
    mode: 'normal' | 'preview';
    widgetEvents: 'none' | 'hover' | 'click' | 'hover-and-click';
    widgetSelectionMode: 'none' | 'single' | 'multiple';
}

const ReportSectionPanel = ({
    globals,
    design = {},
    feature,
    className = '',
    id = '',
    index = -1,
    label = 'Page',
    editable = false,
    onWidgetClick,
    onWidgetDoubleClick,
    dialogController,
    children,
    layout = 'relative',
    size = null,
    scale = 1.0,
    margin,
    titleTag = 'h2',
    onRender = (e, i) => {},
    onVisibleChange = (i, v, id) => {},
    load = 'when-in-view',
    mode = 'normal',
    widgetEvents = 'none',
    widgetSelectionMode = 'none',
    ...others
}: IReportSectionArgs) => {
    const { title, style = {}, widgets, cssStyle } = design,
        //{ borderStyle = '', borderWidth = '', borderColor = '', borderRadius, backgroundColor, backgroundImage, backgroundRepeat, backgroundPosition } = style,
        locale =
            !isNullOrUndefined(globals.locale) && globals.locale.toString().length > 1
                ? globals.locale.toString()
                : 'en',
        pageClassName = `ia-section ${editable ? 'ia-section-editing' : ''} ${
            !isNullOrUndefined(design) && !isNullOrUndefined(design.cssClass) ? design.cssClass : ''
        }`.trim(),
        pageLayoutClassName = !isNullOrUndefined(layout)
            ? `page-layout-${layout.toLowerCase()}`
            : 'page-layout-relative',
        fid = !isNullOrUndefined(feature)
            ? Array.isArray(feature)
                ? feature.map((f) => f.id).join('_')
                : feature.id
            : 'XXXXX',
        sectionId =
            id !== ''
                ? id
                : `s${new Date().getTime().toFixed(0)}${(Math.random() * 1000).toFixed(0)}`;

    const [firstViewportRef, firstInView] = useInView({
            /* Optional options */
            threshold: 0,
            triggerOnce: true
        }),
        [scrollingViewportRef, scrollInView] = useInView({
            /* Optional options */
            threshold: 0.1,
            delay: 500
        }),
        [visited, setVisited] = useState(load === 'always');

    // First viewport intersect => start loading if not done so already
    useEffect(() => {
        setVisited((v) => firstInView || v);
    }, [firstInView]);

    // Any viewport intersect => start loading but also trigger the event handler (this uses a delay to stop too many intersections)
    useEffect(() => {
        setVisited((v) => scrollInView || v);
        onVisibleChange(index, scrollInView, sectionId);
    }, [index, scrollInView, onVisibleChange, sectionId]);

    useEffect(() => {
        const activeDiv = document.querySelector(`#${sectionId} > .ia-report-section`);
        if (activeDiv !== undefined && activeDiv !== null) {
            activeDiv.removeAttribute('data-initial-style');
            if (editable) {
                const tabbable = activeDiv.querySelectorAll(
                    '.ia-report-widget a, .ia-report-widget button, .ia-report-widget select'
                );
                tabbable.forEach((b) => {
                    b.setAttribute('tabindex', '-1');
                });
            }
            if (!isNullOrUndefined(onRender)) {
                onRender(activeDiv, sectionId);
            }
        }
    }, [sectionId, editable, onRender]);

    let styles = {
            ...style
        },
        wrapperStyles = {};
    // Custom, or legacy
    if (!isNullOrUndefined(cssStyle)) styles = { ...styles, ...parseCssStyles(cssStyle) };
    // View scale - done here to avoid Chrome(+?) issues with a position: fixed; inside a transformed container...
    if (!isNullOrUndefined(scale) && scale !== 1.0) styles.transform = `scale(${scale.toFixed(4)})`;
    if (!isNullOrUndefined(size)) {
        if (typeof size === 'string') styles = { ...styles, ...getNamedSize(size) };
        else styles = { ...styles, ...size };
    }
    if (!isNullOrUndefined(margin)) wrapperStyles = { ...wrapperStyles, margin: margin };
    // Background image cleanup... maybe refactor this out to utils?
    if (!isNullOrUndefined(styles.backgroundImage) && styles.backgroundImage !== '') {
        if (/^(https|http|\/|[.]\/)(.*)$/.test(styles.backgroundImage))
            styles.backgroundImage = `url('${styles.backgroundImage}')`;
    }

    const fids = (Array.isArray(feature) ? feature : [feature]).map((f) => f.id).join(','),
        editingAllowed = editable && isNullOrUndefined(design.source),
        isRemote = !isNullOrUndefined(design.source) && design.source !== '',
        styleOver =
            !isNullOrUndefined(design.cssStyle) && typeof design.cssStyle === 'object'
                ? { ...design.cssStyle }
                : {};

    return (
        <>
            <section
                id={sectionId}
                ref={firstViewportRef}
                className={`ia-report-page ${pageLayoutClassName} ${className} ${pageClassName} lang-${locale
                    .substring(0, 2)
                    .toLowerCase()} ${isRemote ? 'remote-source locked' : ''}`.trim()}
                data-feature-id={fids}
                data-page-index={index.toFixed(0)}
                lang={locale}
                style={styleOver}
            >
                <div
                    ref={scrollingViewportRef}
                    className="ia-report-section scaler"
                    style={styles}
                    data-initial-style=""
                >
                    <div className="ia-report-section-wrapper" style={wrapperStyles}>
                        {title !== undefined && title !== null && title.trim() !== ''
                            ? React.createElement(
                                  titleTag,
                                  { className: 'ia-report-section-title' },
                                  title
                              )
                            : null}
                        {!isNullOrUndefined(widgets)
                            ? widgets.map((w: any, i: number) => {
                                  return (
                                      <ReportWidgetPanel
                                          key={`${sectionId}${w.id}${fid}`}
                                          className={`ia-report-widget-${(i + 1).toFixed(0)}`}
                                          design={{
                                              ...w,
                                              eventsGenerate: widgetEvents.replace(
                                                  'hover-and-click',
                                                  'hover,click'
                                              ),
                                              eventsListen: widgetEvents.replace(
                                                  'hover-and-click',
                                                  'hover,click'
                                              ),
                                              eventsSelectionMode: widgetSelectionMode
                                          }}
                                          feature={feature}
                                          globals={{ ...globals }}
                                          locale={locale}
                                          editable={editingAllowed}
                                          onClick={onWidgetClick}
                                          onDoubleClick={onWidgetDoubleClick}
                                          eventTarget={editable ? null : `#${sectionId}`}
                                          pageSize={size}
                                          inViewport={
                                              firstInView ||
                                              (visited && load !== 'strict-when-in-view')
                                          }
                                          mode={mode}
                                          {...others}
                                      />
                                  );
                              })
                            : null}
                        {children}
                    </div>
                </div>
            </section>
            {editable && isRemote && (
                <ListToggleButton
                    elementClassName="remote-detail"
                    text={<i className="fas fa-lock"></i>}
                >
                    <>
                        <div className="remote-explain">
                            <FormattedMessage
                                id="edit.section.remote.label"
                                defaultMessage="This page is linked to report {link}. You cannot edit it here - you need to {editLink} then refresh this page."
                                values={{
                                    link: (
                                        <a
                                            href={`https://www.arcgis.com/home/item.html?id=${design.source}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {design.title || design.source}{' '}
                                            <i className="fas fa-external-link-alt"></i>
                                        </a>
                                    ),
                                    editLink: (
                                        <a
                                            href={`./${design.source}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FormattedMessage
                                                id="edit.section.remote.label"
                                                defaultMessage="edit that report"
                                                values={{ name: design.title || design.source }}
                                            />
                                            <i className="fas fa-external-link-alt"></i>
                                        </a>
                                    )
                                }}
                            />
                        </div>
                    </>
                </ListToggleButton>
            )}
        </>
    );
};

export default ReportSectionPanel;

//WidgetListItem.propTypes = {
//    connectDragSource: PropTypes.func,
//    connectDragPreview: PropTypes.func,
//    isDragging: PropTypes.bool,
//};

//export default DragSource(
//    DragItemTypes.WIDGET_TEMPLATE,
//    {
//        beginDrag: (props) => ({
//            id: props.id,
//            name: props.label,
//            itemType: 'Widget'
//        }),
//        endDrag(props, monitor, component)
//        {
//            if ((props.onDrop !== undefined) && (monitor.getDropResult() !== undefined))
//            {
//                props.onDrop(monitor.getDropResult(), monitor.getItem());
//            }
//        }
//    },
//    (connect, monitor) => ({
//        connectDragSource: connect.dragSource(),
//        connectDragPreview: connect.dragPreview(),
//        isDragging: monitor.isDragging(),
//    }),
//)(WidgetListItem);
