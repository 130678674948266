import React from 'react';
import dateFormat from 'dateformat';
import messages_en from '../translations/en.json';
import messages_es from '../translations/es.json';
import messages_de from '../translations/de.json';

import '@esri/calcite-components/dist/components/calcite-icon';
import '@esri/calcite-components/dist/components/calcite-link';
import { CalciteIcon, CalciteLink } from '@esri/calcite-components-react';

export const MESSAGE_BUNDLE = {
    en: messages_en,
    es: messages_es,
    de: messages_de
};

export const getMessage = (messageKey: string, locale: string, fallbackText?: string) => {
    const bundle =
            MESSAGE_BUNDLE[locale.toLowerCase()] !== undefined
                ? MESSAGE_BUNDLE[locale.toLowerCase()]
                : locale.length > 2 &&
                  MESSAGE_BUNDLE[locale.substring(0, 2).toLowerCase()] !== undefined
                ? MESSAGE_BUNDLE[locale.substring(0, 2).toLowerCase()]
                : MESSAGE_BUNDLE.en,
        txt = bundle[messageKey];
    return txt !== undefined ? txt : fallbackText;
};

export const getReactIntlHtmlFuncs = (enableCalciteComponents = true) => {
    //const ks = `k${(new Date()).getTime().toFixed(0)}${Math.round(Math.random() * 1000.0).toString(16)}`;
    return {
        h1: (children) => (
            <React.Fragment key={`h${Math.round(Math.random() * 1000).toFixed(0)}`}>
                <h1>{children}</h1>
            </React.Fragment>
        ),
        h2: (children) => (
            <React.Fragment key={`h${Math.round(Math.random() * 1000).toFixed(0)}`}>
                <h2>{children}</h2>
            </React.Fragment>
        ),
        h3: (children) => (
            <React.Fragment key={`h${Math.round(Math.random() * 1000).toFixed(0)}`}>
                <h3>{children}</h3>
            </React.Fragment>
        ),
        h4: (children) => (
            <React.Fragment key={`h${Math.round(Math.random() * 1000).toFixed(0)}`}>
                <h4>{children}</h4>
            </React.Fragment>
        ),
        h5: (children) => (
            <React.Fragment key={`h${Math.round(Math.random() * 1000).toFixed(0)}`}>
                <h5>{children}</h5>
            </React.Fragment>
        ),
        p: (children) => (
            <React.Fragment key={`p${Math.round(Math.random() * 1000).toFixed(0)}`}>
                <p>{children}</p>
            </React.Fragment>
        ),
        div: (children) => (
            <React.Fragment key={`p${Math.round(Math.random() * 1000).toFixed(0)}`}>
                <div>{children}</div>
            </React.Fragment>
        ),
        a: (children) => {
            const tokens = children.toString().split(';'),
                txt = tokens.pop(),
                href = tokens.shift(),
                tgt = tokens.length > 0 ? tokens.shift() : '_self';
            return (
                <React.Fragment key={`a${Math.round(Math.random() * 1000).toFixed(0)}`}>
                    {enableCalciteComponents ? (
                        <CalciteLink href={href} target={tgt}>
                            {txt}
                        </CalciteLink>
                    ) : (
                        <a href={href} target={tgt}>
                            {txt}
                        </a>
                    )}
                </React.Fragment>
            );
        },
        b: (children) => (
            <React.Fragment key={`b${Math.round(Math.random() * 1000).toFixed(0)}`}>
                <strong>{children}</strong>
            </React.Fragment>
        ),
        i: (children) => (
            <React.Fragment key={`i${Math.round(Math.random() * 1000).toFixed(0)}`}>
                <em>{children}</em>
            </React.Fragment>
        ),
        strong: (children) => (
            <React.Fragment key={`st${Math.round(Math.random() * 1000).toFixed(0)}`}>
                <strong>{children}</strong>
            </React.Fragment>
        ),
        em: (children) => (
            <React.Fragment key={`em${Math.round(Math.random() * 1000).toFixed(0)}`}>
                <em>{children}</em>
            </React.Fragment>
        ),
        span: (children) => (
            <React.Fragment key={`s${Math.round(Math.random() * 1000).toFixed(0)}`}>
                <span>{children}</span>
            </React.Fragment>
        ),
        ul: (children) => (
            <React.Fragment key={`ul${Math.round(Math.random() * 1000).toFixed(0)}`}>
                <ul>{children}</ul>
            </React.Fragment>
        ),
        li: (children) => (
            <React.Fragment key={`li${Math.round(Math.random() * 1000).toFixed(0)}`}>
                <li>{children}</li>
            </React.Fragment>
        ),
        err: (children) => (
            <React.Fragment key={`info${Math.round(Math.random() * 1000).toFixed(0)}`}>
                <span
                    className="error-message"
                    key={`err${Math.round(Math.random() * 1000).toFixed(0)}`}
                >
                    {children}
                </span>
            </React.Fragment>
        ),
        warn: (children) => (
            <React.Fragment key={`info${Math.round(Math.random() * 1000).toFixed(0)}`}>
                <span className="warning-message">{children}</span>
            </React.Fragment>
        ),
        info: (children) => (
            <React.Fragment key={`info${Math.round(Math.random() * 1000).toFixed(0)}`}>
                <span className="info-message">{children}</span>
            </React.Fragment>
        ),
        icon: (children) => (
            <React.Fragment key={`info${Math.round(Math.random() * 1000).toFixed(0)}`}>
                {enableCalciteComponents ? (
                    <CalciteIcon icon={children.toString()}></CalciteIcon>
                ) : (
                    <i className={`${children}`}></i>
                )}
            </React.Fragment>
        )
    };
};

export const isNumeric = (n: any): boolean => {
    return !isNaN(parseFloat(n)) && isFinite(n);
};

class DivisibleNumberFormatter {
    _numberFormat: Intl.NumberFormat = new Intl.NumberFormat('en');
    _pattern: string = '#.#';
    _divisor: number = 1;
    _nullValueText: string | undefined;
    _group: string | undefined = ',';
    _decimal: string | undefined = '.';

    constructor(
        formatter: Intl.NumberFormat,
        pattern: string,
        divisor: number = 1,
        nullValueText?: string
    ) {
        this._numberFormat = formatter;
        this._pattern = pattern;
        this._divisor = divisor;
        this._nullValueText = nullValueText;
        const parts = this._numberFormat.formatToParts(12345.67);
        this._group = parts.find((d) => d.type === 'group')?.value;
        this._decimal = parts.find((d) => d.type === 'decimal')?.value;
    }

    get pattern(): string {
        return this._pattern;
    }

    format(number: any, options: ExtendedNumberFormatOptions = {}): string {
        if (number === null && this._nullValueText !== undefined) return this._nullValueText;
        const realNumber =
                typeof number === 'number'
                    ? number
                    : !isNaN(parseFloat(number))
                    ? parseFloat(number)
                    : undefined,
            pureNumber =
                realNumber !== undefined &&
                /^(-?(\d+|\d{1,3}(,\d{3})*)(\.\d+)?|\.\d+)(e-\d+)?$/.test(number.toString()); // Added (e-\d+)? to deal with "scientific" numbers
        if (
            typeof number !== 'object' &&
            (!pureNumber || (realNumber === undefined && !options.strict))
        ) {
            return number; // Allow non-numbers back out unless we are strictly enforcing formatting
        }
        let numString =
            realNumber !== undefined ? this._numberFormat.format(realNumber / this._divisor) : '';
        if (this._pattern !== undefined && this._pattern !== null && this._pattern !== '') {
            numString = this._pattern.replace(
                /^([^0#,.]*)([0#,.])+([^0#,.]*)$/,
                `$1${numString}$3`
            );
        }
        return numString;
    }

    round(value: number): number {
        const mult = Math.pow(10, this._numberFormat.resolvedOptions().maximumFractionDigits);
        return Math.round(mult * value) / mult;
    }
}

export const wellKnownNumberFormats = ['N', 'G', 'C', 'P'];

interface ExtendedNumberFormatOptions extends Intl.NumberFormatOptions {
    strict?: boolean | undefined;
    divisor?: number | undefined;
}

export const getNumberFormatter = (
    locale: string = 'en',
    format: string = 'G',
    currencyUnit: string = 'USD',
    nullValueText?: string
) => {
    // Uses https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
    // and parses the format string to match the JS implementation with the C# one at
    // https://docs.microsoft.com/en-us/dotnet/standard/base-types/standard-numeric-format-strings and
    // https://docs.microsoft.com/en-us/dotnet/standard/base-types/custom-numeric-format-strings
    let options: ExtendedNumberFormatOptions = {
            useGrouping: false
        },
        pattern = '';
    switch (format) {
        case 'G':
            break;
        case 'N':
            options = {
                useGrouping: true
            };
            break;
        case 'C':
            options = {
                style: 'currency',
                currency: currencyUnit,
                useGrouping: true
            };
            break;
        case 'P':
            options = {
                ...buildFormatOptions('#,##0.##%'),
                divisor: 0.01 // = * 100.0, inverted...
            };
            pattern = '#,##0.##%';
            break;
        default:
            options = buildFormatOptions(format);
            pattern = format.toString();
            break;
    }
    const formatter = new DivisibleNumberFormatter(
        new Intl.NumberFormat(locale, options),
        pattern,
        options.divisor,
        nullValueText
    );
    return formatter;
};

export const formatNumber = (
    number: any,
    locale: string = 'en',
    format: string = 'G',
    currencyUnit: string = 'USD'
): string => {
    if (number === undefined || number === null) return number;
    const realNumber =
        typeof number === 'number'
            ? number
            : !isNaN(parseFloat(number))
            ? parseFloat(number)
            : undefined;
    if (realNumber === undefined) return number;
    const formatter = getNumberFormatter(locale, format, currencyUnit);
    return formatter.format(realNumber);
};

const buildFormatOptions = (formatPattern: string): ExtendedNumberFormatOptions => {
    let minFractions = 0,
        maxFractions = 0,
        minUnits = 1,
        patterns = formatPattern.split('.'), // #,##0.##
        useGroups = patterns[0].indexOf(',') >= 0 || patterns[0].length < 4, // #,##0
        stripped = patterns[0].replace(/[^0#,.]/g, ''),
        original = stripped.toString(),
        useDivisors = stripped.lastIndexOf(',') === stripped.length - 1, // #,##0,,
        divisor = 1;
    if (useDivisors) {
        while (stripped.substring(stripped.length - 1) === ',') {
            stripped = stripped.substring(0, stripped.length - 1);
            divisor *= 1000;
        }
        patterns[0] = patterns[0].replace(original, stripped);
    }
    minFractions = patterns.length > 1 ? patterns[1].replace(/[^0]/g, '').length : 0;
    maxFractions =
        patterns.length > 1 ? patterns[1].replace(/#/g, '0').replace(/[^0]/g, '').length : 0;
    minUnits = patterns[0].replace(/[^0#,.]/g, '').replace(/[#,]/g, '').length;
    const options: ExtendedNumberFormatOptions = {
        minimumIntegerDigits: Math.max(1, minUnits),
        minimumFractionDigits: minFractions,
        maximumFractionDigits: maxFractions,
        divisor: divisor,
        useGrouping: useGroups
    };
    return options;
};

export const formatDate = (
    dateTimeObject: Date,
    format: 'd' | 'date' | 'D' | 'f' | 'g' | 'G' | 't' | 'time' | 'T' = 'D',
    locale: string
): string => {
    return format.toUpperCase() === 'ISO'
        ? dateTimeObject.toISOString()
        : dateTimeObject.toLocaleString(locale, getDateTimeOptions(format));
};

export const formatDateCustom = (dateTimeObject: Date, format: string): string => {
    return dateFormat(dateTimeObject, format);
};

export const getDateTimeOptions = (
    cSharpDateFormat: 'd' | 'date' | 'D' | 'f' | 'g' | 'G' | 't' | 'time' | 'T' = 'D'
): Intl.DateTimeFormatOptions => {
    let fmt: Intl.DateTimeFormatOptions = {
        dateStyle: 'full',
        timeStyle: 'full'
    };
    switch (cSharpDateFormat) {
        case 'd':
            fmt.dateStyle = 'short';
            fmt.timeStyle = undefined;
            break;
        case 'date':
        case 'D':
            fmt.dateStyle = 'full';
            fmt.timeStyle = undefined;
            break;
        case 'f':
            fmt.dateStyle = 'long';
            fmt.timeStyle = 'short';
            break;
        case 'g':
            fmt.dateStyle = 'short';
            fmt.timeStyle = 'short';
            break;
        case 'G':
            fmt.dateStyle = 'short';
            fmt.timeStyle = 'long';
            break;
        case 't':
            fmt.dateStyle = undefined;
            fmt.timeStyle = 'short';
            break;
        case 'time':
        case 'T':
            fmt.dateStyle = undefined;
            fmt.timeStyle = 'long';
            break;
        default:
            break;
    }
    // TODO - others
    return fmt;
};
