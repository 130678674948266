import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector, RootState } from '../redux/builderStoreHooks';
import MenuBar from './MenuBar';
import UserOptions from './UserOptions';
import { FormattedMessage, useIntl } from 'react-intl';
import ProductLogo from './ProductLogo';
import '../assets/styles/components/AppNavigationBar.scss';

import '@esri/calcite-components/dist/components/calcite-action';
import '@esri/calcite-components/dist/components/calcite-action-bar';
import '@esri/calcite-components/dist/components/calcite-button';
import '@esri/calcite-components/dist/components/calcite-tooltip';
import {
    CalciteAction,
    CalciteActionBar,
    CalciteButton,
    CalciteTooltip
} from '@esri/calcite-components-react';

const AppNavigationBar = ({
    homeUrl = '',
    activePath,
    helpPath,
    helpStyle = 'text+icon',
    controller,
    children,
    userOptions,
    extras
}: {
    homeUrl?: string;
    activePath: string;
    helpPath?: string;
    helpStyle?: 'text' | 'icon' | 'text+icon';
    controller?: any;
    children?: React.ReactNode;
    extras?: React.ReactNode;
    userOptions?: React.ReactNode;
}) => {
    const intl = useIntl(),
        history = useHistory(),
        pageName = activePath.split('/')[1] || 'home',
        user: any = useAppSelector((state: RootState) => state.appSettings.user) || {},
        routeHelpPath: string | null =
            helpPath !== undefined
                ? (user.username === undefined || user.username === null) && pageName === 'home'
                    ? helpPath
                    : `${helpPath}${pageName}-page`
                : null;

    return (
        <MenuBar>
            <nav>
                <div className="container-fluid">
                    <ul className="nav navbar-nav navbar-left">
                        <li className="navbar-brand-containe rb-flex">
                            <CalciteActionBar
                                layout="horizontal"
                                expandDisabled={true}
                                expanded={true}
                            >
                                <CalciteAction
                                    appearance="transparent"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        history.push('/');
                                    }}
                                    id={'app-home-button'}
                                    text=""
                                >
                                    <div className="rb-flex rb-items-center rb-space-x-2">
                                        <ProductLogo size="1.4em" style={{ marginRight: '10px' }} />
                                        <FormattedMessage
                                            id="app.name"
                                            defaultMessage="Report Builder for ArcGIS"
                                        />
                                    </div>
                                </CalciteAction>
                            </CalciteActionBar>
                            <CalciteTooltip
                                referenceElement={'app-home-button'}
                                label="Index"
                                placement="trailing"
                                overlayPositioning="fixed"
                            >
                                {intl.formatMessage({
                                    id: 'app.label.hub',
                                    defaultMessage: 'Report Builder for ArcGIS | My Reports'
                                })}
                            </CalciteTooltip>
                        </li>
                    </ul>
                    {children}
                    <div className="nav navbar-nav navbar-right">
                        <CalciteActionBar
                            layout="horizontal"
                            expandDisabled={true}
                            expanded={helpStyle.indexOf('text') >= 0 ? true : undefined}
                        >
                            {routeHelpPath !== null &&
                            activePath !== undefined &&
                            activePath !== null &&
                            activePath.substring(0, 5) !== '/help' ? (
                                <CalciteAction
                                    id="rbHelpButton_NavBar"
                                    onClick={() => {
                                        window.open(routeHelpPath, 'rbHelpWindow');
                                    }}
                                    className="btn help-link"
                                    appearance="transparent"
                                    icon={helpStyle.indexOf('icon') >= 0 ? 'question' : undefined}
                                    text={intl.formatMessage({
                                        id: 'app.link.help',
                                        defaultMessage: 'Help & Support'
                                    })}
                                ></CalciteAction>
                            ) : null}
                            {extras !== undefined && <>{extras}</>}
                        </CalciteActionBar>
                        {helpStyle.indexOf('text') < 0 && (
                            <CalciteTooltip
                                referenceElement="rbHelpButton_NavBar"
                                overlayPositioning="fixed"
                                placement="leading"
                                label={intl.formatMessage({
                                    id: 'app.link.help',
                                    defaultMessage: 'Help & Support'
                                })}
                                closeOnClick={true}
                            >
                                {intl.formatMessage({
                                    id: 'app.link.help',
                                    defaultMessage: 'Help & Support'
                                })}
                            </CalciteTooltip>
                        )}
                    </div>
                    <div className="rb-hidden nav navbar-nav navbar-right">
                        <UserOptions
                            signOutAction={controller.signOutOfApp}
                            showOptions={false}
                            tipLocation="inline"
                            alignment="right"
                        >
                            {userOptions}
                        </UserOptions>
                    </div>
                </div>
            </nav>
        </MenuBar>
    );
};

export default AppNavigationBar;
