type KeyedFunctionTask = {
    id: string;
    func: () => void;
};

export default class TaskScheduler {
    _delay: number;
    _tasks: (KeyedFunctionTask | (() => void))[];
    _offset: number;
    _taskIntervalId: number;
    _timeoutContainer: any;
    _progressListeners: ((at: number, maxNumberOfTasks: number) => void)[];
    _settings: { [id: string]: any } = {};

    constructor(queueCheckDelay?: number | undefined, timeoutContainer = window) {
        this._delay = queueCheckDelay !== undefined ? queueCheckDelay : 5; // Time delay in mlliseconds between checks on the queue
        this._tasks = [];
        this._offset = 0;
        this._taskIntervalId = 0;
        this._timeoutContainer = timeoutContainer;
        this._progressListeners = [];
    }

    enqueue = (asyncFunctionOrFunctionWrapper: KeyedFunctionTask | (() => void)): void => {
        this._tasks.push(asyncFunctionOrFunctionWrapper);
        if (this._taskIntervalId <= 0) {
            this._taskIntervalId = this._timeoutContainer.setTimeout(this.processNextTask, this._delay);
        }
        if (this._progressListeners.length > 0) {
            for (let pf of this._progressListeners) {
                pf(this._offset, this._tasks.length);
            }
        }
    };

    dequeue = (asyncTaskId: string): boolean => {
        const kfi: number = this._tasks.findIndex(
            (t): t is KeyedFunctionTask => (t as KeyedFunctionTask).id === asyncTaskId
        );
        if (kfi >= 0) {
            this._tasks.splice(kfi, 1);
            if (this._progressListeners.length > 0) {
                for (let pf of this._progressListeners) {
                    pf(this._offset, this._tasks.length);
                }
            }
            return true;
        }
        return false;
    };

    processNextTask = async () => {
        this._timeoutContainer.clearTimeout(this._taskIntervalId);
        if (this._tasks.length > 0 && this._offset < this._tasks.length) {
            const t: any = this._tasks[this._offset],
                f: any = typeof t === 'object' ? t.func : t;
            if (f !== undefined && typeof f === 'function') {
                //console.log(`processing task. ${this._tasks.length} still to go...`); // DEBUG
                try {
                    await f();
                    if (this._progressListeners.length > 0) {
                        for (let pf of this._progressListeners) {
                            pf(this._offset + 1, this._tasks.length);
                        }
                    }
                } catch (ex) {
                    console.error(ex);
                }
            }
            this._offset = this._offset + 1;
            this._taskIntervalId = this._timeoutContainer.setTimeout(this.processNextTask, this._delay);
        }
    };

    get length() {
        return this._tasks.length;
    }

    addProgressListener = (listener: (at: number, maxNumberOfTasks: number) => void): void => {
        this._progressListeners.push(listener);
    };

    setProperty = (name: string, value: any) => {
        this._settings[name] = value;
    };

    getProperty = (name: string) => {
        return this._settings[name];
    };
}
