import { ArcGISPortal } from 'data-catalog-js-api';
import { migrateDesign } from './report-migrate-plus';

export interface IArcItemInfo {
    id: string;
    title?: string;
    [key: string]: any;
}

export const getReportDesign = async (
    reportItem: IArcItemInfo,
    reportData: any,
    token: string | null = '',
    portalUrl: string | null = '',
    qsArgs = '',
    fetchArgs?: any
): Promise<any> => {
    const reportPartPromises: string[] = [],
        reportDetails =
            reportData.values.reportbuilder !== undefined
                ? reportData.values.reportbuilder
                : reportData.values.instantatlas !== undefined
                ? reportData.values.instantatlas
                : reportData.values.iaoReportDetails, // Current or legacy
        nParts = reportDetails.numberOfParts,
        //fileExt = reportDetails.fileExt, // Unused - only deals with JSON
        filePath = reportDetails.filePath !== undefined ? reportDetails.filePath : 'info',
        fileName = reportDetails.fileName !== undefined ? reportDetails.fileName : 'instantatlas/ia-report-design.json', // Legacy - modern versions (2.6+) should have a file name
        isLegacy = reportDetails.itemType === undefined || reportDetails.itemType === null,
        host = (
            portalUrl !== undefined && portalUrl !== null && portalUrl !== ''
                ? portalUrl
                : ArcGISPortal.arcgisOnlineHost
        ).replace(/\/sharing\/rest(\/)?$/, '');
    let uri;
    if (filePath === 'info') {
        for (let i = 1; i <= nParts; i++) {
            uri = isLegacy
                ? `${host}/sharing/rest/content/items/${reportItem.id}/info/iao-report-part-${i}.txt?f=text&token=${token}${qsArgs}`
                : `${host}/sharing/rest/content/items/${reportItem.id}/info/instantatlas/ia-report-part-${i}.txt?f=text&token=${token}${qsArgs}`;
            reportPartPromises.push(await fetch(uri, fetchArgs).then((r) => r.text()));
        }
    } else {
        uri = `${host}/sharing/rest/content/items/${reportItem.id}/${filePath}/${fileName}?f=json&token=${token}${qsArgs}`;
        reportPartPromises.push(await fetch(uri, fetchArgs).then((r) => r.text()));
    }
    let jsonTxt = reportPartPromises.join('');
    // Some direct replacements that we _know_ are now redundant in the new world...
    jsonTxt = jsonTxt.replace(/font-family: FontAwesome;/g, `font-family: 'Font Awesome 5 Free';font-weight: bold;`);
    jsonTxt = jsonTxt.replace(/RelatedItemsTableWidget/g, 'RelatedRecordsTableWidget');
    try {
        const report = JSON.parse(jsonTxt, function (k, v) {
            // Rename some specials on the way through by directly assigning to _this_, then returning undefined
            if (k.toLowerCase().indexOf('colour') >= 0) {
                this[k.replace(/Colour/g, 'Color').replace(/colour/g, 'color')] = v;
                return undefined;
            }
            const preserveEmpty = ['widgets', 'sections'];
            // Remove some redundant features - which are mostly C# hangovers...
            return v === null ||
                v === 'Undefined' ||
                k === '$type' ||
                (Array.isArray(v) && v.length < 1 && preserveEmpty.indexOf(k) < 0)
                ? undefined
                : v === 'False'
                ? false
                : v === 'True'
                ? true
                : v;
        });
        if (report.error !== undefined) throw new Error(report.error);
        // Allow import of other reports into this one
        if (report.design !== undefined && report.design.sections !== undefined) {
            // Pull the design across into this section
            for (let i = 0; i < report.design.sections.length; i++) {
                if (
                    report.design.sections[i].source !== undefined &&
                    report.design.sections[i].source !== null &&
                    report.design.sections[i].source !== ''
                ) {
                    try {
                        const src = await getReport(report.design.sections[i].source, token, portalUrl, fetchArgs),
                            srcDesign = src.report !== undefined ? src.report.design : undefined;
                        report.design.sections[i].widgets = [];
                        if (srcDesign !== undefined && srcDesign.sections !== undefined) {
                            for (let srcSec of srcDesign.sections) {
                                for (let srcWidg of srcSec.widgets) {
                                    report.design.sections[i].widgets.push(srcWidg);
                                }
                            }
                            if (report.design.sections[i].title === undefined)
                                report.design.sections[i].title = src.info.title;
                            // Some styles?
                            if (srcDesign.font !== undefined) {
                                report.design.sections[i].style = {
                                    fontFamily: srcDesign.font.family,
                                    fontSize: srcDesign.font.size
                                };
                            }
                            // Should we look at data? Or just trust them??
                        }
                    } catch (recursiveErr) {
                        console.error(recursiveErr); // DEBUG
                        throw recursiveErr;
                    }
                }
            }
        }
        return report;
    } catch (jsonParseEx: any) {
        console.error(jsonParseEx); // DEBUG
        if (jsonParseEx.name !== undefined && jsonParseEx.name === 'WrappedArcError') throw jsonParseEx;
        throw new Error(
            'Auto-migration error - cannot parse design as JSON. Older designs use XML - open and re-save in Report Builder 1.x, then try again.'
        );
        /*throw new Error({
            code: 500,
            message: 'Auto-migration error - cannot parse design as JSON. Older designs use XML - open and re-save in Report Builder 1.x, then try again.',
            detail: jsonParseEx,
            data: jsonTxt
        });*/
    }
};

export const getReport = (
    reportId: string,
    token: string | null = '',
    portalUrl: string | null = null,
    fetchArgs?: any
): any => {
    const rp = token === null && token !== '' ? { f: 'json' } : { f: 'json', token: token };
    return new Promise((resolve, reject) => {
        Promise.all([
            ArcGISPortal.getItemDescription(reportId, rp, portalUrl, fetchArgs),
            ArcGISPortal.getItemData(reportId, rp, portalUrl, fetchArgs)
        ])
            .then(([itemDesc, itemData]) => {
                getReportDesign(itemDesc, itemData, token, portalUrl, undefined, fetchArgs).then((reportDesign) => {
                    reportDesign.id = itemDesc.id;
                    const updatedDesign = migrateDesign(reportDesign);
                    // Web map?
                    if (
                        itemData.values !== undefined &&
                        itemData.values !== null &&
                        itemData.values.webmap !== undefined &&
                        itemData.values.webmap !== null
                    ) {
                        ArcGISPortal.getItemData(itemData.values.webmap, rp, portalUrl, fetchArgs).then(
                            (mapItemData) => {
                                resolve({
                                    info: itemDesc,
                                    data: itemData,
                                    report: updatedDesign.report,
                                    webmap: mapItemData
                                });
                            }
                        );
                    } else {
                        resolve({
                            info: itemDesc,
                            data: itemData,
                            report: updatedDesign.report
                        });
                    }
                });
            })
            .catch((internalPromiseErr) => reject(internalPromiseErr));
    });
};

export const saveReportDesign = (
    reportItem: { [id: string]: any },
    reportData: { [id: string]: any },
    reportConfig: { [id: string]: any },
    thumbnailBlob: Blob | null = null,
    userId: string,
    folderId: string | null = null,
    token: string | null = null,
    portalUrl: string | null = null,
    targetPath: 'info' | 'resources' = 'resources'
) => {
    return new Promise((resolve, reject) => {
        const designJson = new Blob([JSON.stringify(reportConfig)], { type: 'application/json' }),
            nParts = targetPath === 'info' ? Math.ceil(designJson.size / (1024 * 99.5)) : 1, // 100kb limit in ArcGIS Online/Portal, with wriggle room
            sliceSize = designJson.size / nParts,
            reportPartPromises: Promise<any>[] = [],
            host = (portalUrl ? portalUrl : ArcGISPortal.arcgisOnlineHost).replace(/\/sharing\/rest(\/)?$/, '');
        let uri, fname, fragment;
        delete reportData.values.iaoReportDetails;
        if (targetPath === 'info') {
            for (let i = 0; i < nParts; i++) {
                fragment = designJson.slice(i * sliceSize, i * sliceSize + sliceSize, 'text/plain');
                // https://www.arcgis.com/sharing/rest/content/users/jsmith/items/ef78d18087c4456eab5478b0485f9911/updateInfo
                uri = `${host}/sharing/rest/content/users/${userId}/items/${reportItem.id}/updateInfo`;
                fname = `ia-report-part-${(i + 1).toFixed(0)}.txt`;
                const uploadData = new FormData();
                uploadData.append('file', fragment, fname);
                uploadData.append('folderName', 'instantatlas');
                uploadData.append('token', token || '');
                uploadData.append('f', 'json');
                reportPartPromises.push(
                    fetch(uri, {
                        method: 'POST',
                        body: uploadData
                    })
                );
            }
        } else {
            // Resources - simpler - one file
            // https://www.arcgis.com/sharing/rest/content/users/jsmith/items/ef78d18087c4456eab5478b0485f9911/add|remove|updateResources
            const uploadData = new FormData(),
                killData = new FormData();
            uploadData.append('file', designJson, `report-design.json`); // Change at 2.6
            uploadData.append('resourcesPrefix', 'reportbuilder'); // Change at 2.6
            uploadData.append('token', token || '');
            uploadData.append('f', 'json');
            //killData.append('resource', `instantatlas/${fname}`);
            killData.append('token', token || '');
            killData.append('f', 'json');
            reportPartPromises.push(
                fetch(`${host}/sharing/rest/content/users/${userId}/items/${reportItem.id}/resources`, {
                    method: 'POST',
                    body: killData
                }).then((listRsp) => {
                    return listRsp.json().then((list) => {
                        const match =
                                list.resources !== undefined
                                    ? list.resources.find(
                                          (r) => r.resource === `reportbuilder/report-design.json` // || r.resource === `instantatlas/ia-report-design.json`
                                      )
                                    : undefined,
                            action = match !== undefined ? 'updateResources' : 'addResources';
                        return fetch(`${host}/sharing/rest/content/users/${userId}/items/${reportItem.id}/${action}`, {
                            method: 'POST',
                            body: uploadData
                        });
                    });
                })
            );
        }
        const coreSrc =
                reportConfig.data.sources.find((ds) => ds.primary) !== undefined
                    ? reportConfig.data.sources.find((ds) => ds.primary)
                    : reportConfig.data.sources[0],
            idf =
                reportConfig.design !== undefined && reportConfig.design.idField !== undefined
                    ? reportConfig.design.idField
                    : coreSrc.idField !== undefined
                    ? coreSrc.idField
                    : coreSrc.params !== undefined && coreSrc.params.idField !== undefined
                    ? coreSrc.params.idField
                    : '',
            nmf =
                reportConfig.design !== undefined && reportConfig.design.nameField !== undefined
                    ? reportConfig.design.nameField
                    : coreSrc.nameField !== undefined
                    ? coreSrc.nameField
                    : coreSrc.params !== undefined && coreSrc.params.nameField !== undefined
                    ? coreSrc.params.nameField
                    : '';
        if (reportData.values.instantatlas !== undefined) delete reportData.values.instantatlas; // Change at 2.6
        reportData.values.reportbuilder = {
            itemType: 'report',
            numberOfParts: nParts,
            fileExt: 'json',
            filePath: targetPath,
            fileName: 'reportbuilder/report-design.json', // New at 2.6
            coreLayer: `${coreSrc.url};${idf};${nmf}`
        };
        const updateTextData = new FormData(),
            updateOthersData = new FormData();
        updateTextData.append('text', JSON.stringify(reportData));
        for (let simpleKey of ['url', 'title', 'description', 'snippet', 'tags', 'typeKeywords']) {
            if (reportItem[simpleKey] !== undefined) {
                updateOthersData.append(
                    simpleKey,
                    Array.isArray(reportItem[simpleKey])
                        ? reportItem[simpleKey].join(',')
                        : reportItem[simpleKey] !== null
                        ? reportItem[simpleKey]
                        : ''
                );
            }
        }
        updateTextData.append('token', token || '');
        updateTextData.append('f', 'json');
        const userSpecificThumbnail =
            reportItem.thumbnail !== undefined &&
            reportItem.thumbnail !== null &&
            reportItem.thumbnail !== 'thumbnail/ia-thumbnail.png' &&
            reportItem.thumbnail !== 'thumbnail/rb-thumbnail.png';
        if (!userSpecificThumbnail && thumbnailBlob !== undefined && thumbnailBlob !== null) {
            uri = `${host}/sharing/rest/content/users/${userId}/items/${reportItem.id}/updateInfo`;
            fname = `rb-thumbnail.png`;
            //const uploadData = new FormData();
            //uploadData.append('file', thumbnailBlob, fname);
            //uploadData.append('folderName', 'thumbnail');
            //uploadData.append('token', token);
            //uploadData.append('f', 'json');
            //reportPartPromises.push(
            //    fetch(uri, {
            //        method: 'POST',
            //        body: uploadData
            //    })
            //);
            //updateOthersData.append('thumbnail', `info/thumbnail/ia-thumbnail.png`);
            updateOthersData.append('thumbnail', thumbnailBlob, fname);
        }
        uri = `${host}/sharing/rest/content/users/${userId}/items/${reportItem.id}/update`;
        reportPartPromises.push(
            fetch(uri, {
                method: 'POST',
                body: updateTextData
            })
        );
        if (Array.from(updateOthersData.keys()).length > 0) {
            uri = `${host}/sharing/rest/content/users/${userId}/items/${reportItem.id}/update`;
            updateOthersData.append('clearEmptyFields', 'true');
            updateOthersData.append('token', token || '');
            updateOthersData.append('f', 'json');
            reportPartPromises.push(
                fetch(uri, {
                    method: 'POST',
                    body: updateOthersData
                })
            );
        }
        // Move?
        if (folderId !== null && folderId !== reportItem.ownerFolder) {
            uri = `${host}/sharing/rest/content/users/${userId}/items/${reportItem.id}/move`;
            const moveData = new FormData();
            moveData.append('folder', folderId || '');
            moveData.append('token', token || '');
            moveData.append('f', 'json');
            reportPartPromises.push(
                fetch(uri, {
                    method: 'POST',
                    body: moveData
                })
            );
        }
        reportPartPromises
            .reduce((promiseChain, currentTask) => {
                return promiseChain.then((chainResults) =>
                    currentTask.then((currentResult) => {
                        return currentResult.json().then((rsp) => {
                            return [...chainResults, rsp];
                        });
                    })
                );
            }, Promise.resolve([]))
            .then((arrayOfResults) => {
                resolve(targetPath === 'info' ? arrayOfResults : arrayOfResults.slice(1)); // Save to /resources/ includes a call to delete which should be allowed to fail
            });
    });
};

export const saveImageFileItem = async (
    image: File,
    userId: string,
    targetItemId: string | null = null,
    token: string,
    portalUrl: string | null = null,
    asResource: boolean = true,
    targetFolderId?: string
): Promise<{ [id: string]: any }> => {
    const host = (portalUrl ? portalUrl : ArcGISPortal.arcgisOnlineHost).replace(/\/sharing\/rest(\/)?$/, '');
    // https://[root]/content/users/[userName]/addItem
    const uploadData = new FormData();
    uploadData.append('title', image.name);
    uploadData.append('type', 'Image');
    uploadData.append('file', image, image.name);
    uploadData.append('token', token || '');
    uploadData.append('f', 'json');
    if (asResource) {
        uploadData.append('resourcesPrefix', `images`);
        const existing = await fetch(
                `${host}/sharing/rest/content/users/${userId}/items/${targetItemId}/resources?f=json&token=${token}`
            ).then((rsp) => rsp.json()),
            command =
                existing.resources !== undefined &&
                existing.resources.find((r) => r.resource === `images/${image.name}`) !== undefined
                    ? 'updateResources'
                    : 'addResources',
            summary = await fetch(`${host}/sharing/rest/content/users/${userId}/items/${targetItemId}/${command}`, {
                method: 'POST',
                body: uploadData
            }).then((addRsp) => {
                return addRsp.json().then((added) => {
                    return {
                        ...added,
                        url: `${host}/sharing/rest/content/items/${targetItemId}/resources/images/${image.name}`
                    };
                });
            });
        return summary;
    } else {
        if (targetItemId !== undefined && targetItemId !== null) {
            uploadData.append('originItemId', targetItemId);
            uploadData.append('relationshipTypes', 'Item2Attachment');
        }
        const itemAdded = await fetch(`${host}/sharing/rest/content/users/${userId}/addItem`, {
            method: 'POST',
            body: uploadData
        }).then((addRsp) => {
            return addRsp.json().then((added) => {
                // Move?
                if (added.success) {
                    if (targetFolderId !== undefined) {
                        const uri = `${host}/sharing/rest/content/users/${userId}/items/${added.id}/move`,
                            moveData = new FormData();
                        moveData.append('folder', targetItemId || '');
                        moveData.append('token', token || '');
                        moveData.append('f', 'json');
                        return fetch(uri, {
                            method: 'POST',
                            body: moveData
                        })
                            .then((r) => {
                                return r.json();
                            })
                            .then((mi) => {
                                return {
                                    ...mi,
                                    ...added
                                };
                            });
                    }
                }
                return added;
            });
        });
        return itemAdded;
    }
};

export const isSameDomain = (url: string, portalUrl: string, allowSubDomains = true): boolean => {
    const URL_HOST_PATTERN = /(\w+:)?(?:\/\/)([\w.-]+)?(?::(\d+))?\/?/,
        urlMatch = URL_HOST_PATTERN.exec(url) || [],
        urlHost = urlMatch[2].split('.'),
        portalMatch = URL_HOST_PATTERN.exec(portalUrl) || [],
        portalHost = portalMatch[2].split('.');
    return allowSubDomains && urlHost.length > 2 && portalHost.length > 2
        ? urlHost.slice(-2).join('.') === portalHost.slice(-2).join('.')
        : urlHost.join('.') === portalHost.join('.');
};

export const findCoreLayer = async (
    catalogUrlOrId: string,
    coreLayerId: string,
    portalUrl?: string,
    token?: string
): Promise<string[]> => {
    const overCatPromise = /^http|https:\/\/.*$/.test(catalogUrlOrId)
        ? Promise.resolve(catalogUrlOrId)
        : ArcGISPortal.getItemDescription(catalogUrlOrId, { f: 'json', token: token }, portalUrl).then((tableItem) => {
              return /^(.*)\/FeatureServer\/[0-9]+$/.test(tableItem.url) ? tableItem.url : `${tableItem.url}/0`;
          });
    return overCatPromise.then((tableUrl: string) => {
        return ArcGISPortal.getInfo(tableUrl, { f: 'json', token: token }, false, portalUrl).then((catalogInfo) => {
            const idf = catalogInfo.fields.find((f) => f.name.toLowerCase() === 'id').name,
                suf = catalogInfo.fields.find((f) => f.name.toLowerCase() === 'service_url').name,
                itf = catalogInfo.fields.find((f) => f.name.toLowerCase() === 'item_type').name;
            return ArcGISPortal.queryFeatures(tableUrl, `${itf}='Geo' AND ${idf}='${coreLayerId}'`, 1, { token }).then(
                (geoSet) => {
                    const g = geoSet[0][suf];
                    return [g, tableUrl];
                }
            );
        });
    });
};

interface StandardFieldsReponse {
    info: any;
    idField: string | null;
    nameField: string | null;
}

export const findStandardFields = async (
    coreFeatureUrl: string,
    idField: string,
    nameField: string,
    idFieldSearchAlias = 'code',
    nameFieldSearchAlias = 'name',
    portalUrl?: string,
    token?: string
): Promise<StandardFieldsReponse> => {
    const fsDetail: any = await ArcGISPortal.getInfo(coreFeatureUrl, {
        f: 'json',
        token:
            token !== undefined && portalUrl !== undefined && isSameDomain(coreFeatureUrl, portalUrl || '')
                ? token
                : undefined
    });
    let idf = idField,
        nmf = nameField;
    if (fsDetail.fields !== undefined) {
        // Search by alias first
        let tmpf = fsDetail.fields.find(
            (ff: any) => ff.alias !== undefined && ff.alias.toLowerCase() === idFieldSearchAlias.toLowerCase()
        );
        if (tmpf === undefined) {
            // Not found by alias, fall back to what the report has asked for...
            tmpf = fsDetail.fields.find(
                (ff: any) => ff.name !== undefined && ff.name.toLowerCase() === idField.toLowerCase()
            );
        }
        if (tmpf !== undefined) {
            idf = tmpf.name;
        }
        // Repeat process for name field
        tmpf = fsDetail.fields.find(
            (ff: any) => ff.alias !== undefined && ff.alias.toLowerCase() === nameFieldSearchAlias.toLowerCase()
        );
        if (tmpf === undefined) {
            tmpf = fsDetail.fields.find(
                (ff: any) => ff.name !== undefined && ff.name.toLowerCase() === nmf.toLowerCase()
            );
        }
        if (tmpf !== undefined) {
            nmf = tmpf.name;
        }
        return {
            info: fsDetail,
            idField: idf,
            nameField: nmf
        };
    }
    return {
        info: null,
        idField,
        nameField
    };
};
