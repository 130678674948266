import React, { useState, useEffect, useRef, useCallback, useLayoutEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { setPageState, setPageReport } from '../redux/builderStore';
import { useAppDispatch, useAppSelector, RootState } from '../redux/builderStoreHooks';
import { Link } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { isNullOrUndefined, lowerKeyParams, lowerKeys } from '../utils/object';
import { get } from '../utils/json';
//import WelcomeDialog from 'components/WelcomeDialog';
import { RB_APP_VIEW_ONLY_AUTH_ID } from '../utils/appUtilsAndConstants';
import { addClass, hasClass, removeClass } from '../utils/dom';
import { getTokenFor, redirectToSignIn } from '../utils/arcgis';
import {
    getReportDesign,
    isSameDomain,
    findCoreLayer,
    findStandardFields
} from '../utils/arcgis-network';
import {
    migrateDesign,
    injectStyles,
    findIndicatorRefs,
    getNamedSize,
    logMessagesToConsole
} from '../utils/report-migrate-plus';
import '../assets/styles/pages/View.scss';
import '../assets/styles/pages/View-responsive.scss';
import '../assets/styles/pages/View-embedded.scss';
import '../assets/styles/pages/View-print-layout.scss';
import ProgressMessage from '../components/ProgressMessage';
import ModalDialog from '../components/ModalDialog';
import MetadataDialog from '../components/MetadataDialog';
import { OfflinePanel } from '../components/OnlineAwarePanel';
import ReportSectionPanel from '../components/ReportSectionPanel';
import { ArcGISPortal, ReportDataSource, JsonFileDataSource } from 'data-catalog-js-api';
import { getReactIntlHtmlFuncs } from '../utils/localization';
import { buildHtmlFromData } from '../widgets/widgetDataUtils';
import { NamedItem, RenderEvent } from './PropTypes';
//import reportDataWebWorker from "../api/ReportDataWebWorker.js";
//import WrappedWebWorker from "../api/WrappedWebWorker";
import {
    deleteReportFromBrowser,
    downloadReportToBrowser,
    fetchReportFromBrowser,
    fetchReportDataFromBrowser
} from '../utils/storage';
import { convertLengthToPixels } from '../widgets/AbstractWidget';
import TaskScheduler from '../utils/TaskScheduler';
import { Chart } from 'chart.js';

import '@esri/calcite-components/dist/components/calcite-button';
import '@esri/calcite-components/dist/components/calcite-icon';
import '@esri/calcite-components/dist/components/calcite-link';
import '@esri/calcite-components/dist/components/calcite-notice';
import '@esri/calcite-components/dist/components/calcite-pagination';
import '@esri/calcite-components/dist/components/calcite-tooltip';
import {
    CalciteButton,
    CalciteIcon,
    CalciteLink,
    CalciteNotice,
    CalcitePagination,
    CalciteTooltip
} from '@esri/calcite-components-react';

type ReportViewPageArgs = {
    mode?: string | undefined;
    dataMode?: string | undefined;
    staticUrl?: string | undefined;
    windowMode?: 'standalone' | 'embedded' | undefined;
    lockNavigation?: boolean;
    onRender?: (e: RenderEvent) => {};
    appAuthenticationId?: string;
    overrides?: { [id: string]: any } | undefined | null;
};

const ReportViewPage = ({
    mode = 'single',
    dataMode = 'live',
    staticUrl = '',
    windowMode = 'standalone',
    lockNavigation = false,
    onRender,
    appAuthenticationId = RB_APP_VIEW_ONLY_AUTH_ID,
    overrides
}: ReportViewPageArgs) => {
    // Redux mapping for app level variables
    const portalUrl: string = useAppSelector((state: RootState) => state.appSettings.portalUrl),
        //portalHome: string = useAppSelector((state: RootState) => state.appSettings.portalHome),
        portalType: string = useAppSelector((state: RootState) => state.appSettings.portalType),
        //appAuthId: string = useAppSelector((state: RootState) => state.appSettings.appAuthId),
        appHome: string = useAppSelector((state: RootState) => state.appSettings.appHome),
        user: any = useAppSelector((state: RootState) => state.appSettings.user),
        //title: any = useAppSelector((state: RootState) => state.pageTitle),
        reportItem: any = useAppSelector((state: RootState) => state.report),
        token: string = useAppSelector((state: RootState) => state.appSettings.token),
        { tokenManager } = useAppSelector((state: RootState) => state.applicationState);

    const windowHash: string | undefined = window.location.hash,
        hasPageArg: boolean = /^#page[0-9]+$/.test(windowHash),
        hasAnchorArg: string[] | null = /^#([0-9a-zA-Z_]+)$/.exec(windowHash),
        anchorTarget: string | null = hasAnchorArg ? hasAnchorArg[1] : null,
        startPage = hasPageArg ? parseInt(windowHash.replace(/[^0-9]/g, '')) - 1 : 0,
        qs: URLSearchParams = new URLSearchParams(window.location.search),
        autoPrint: boolean =
            qs.has('action') && (qs.get('action') === 'print' || qs.get('action') === 'pdf'),
        autoPrintClassName: string = autoPrint ? ` ia-${qs.get('action')}-mode` : '',
        [loading, setLoading] = useState<boolean>(false),
        [activeFeature, setActiveFeature] = useState<NamedItem[]>([]),
        [page, setPage] = useState<number>(startPage),
        [maxPage, setMaxPage] = useState<number>(0),
        [currentPageIndex, setCurrentPageIndex] = useState<number>(startPage),
        [backgroundProcessing, setBackgroundProcessing] = useState<boolean>(false),
        [metadataServiceUrl, setMetadataServiceUrl] = useState<string | null>(null),
        [error, setError] = useState<any>(null),
        [modal, setModal] = useState<any>({}),
        [inPrintMode, setInPrintMode] = useState<boolean>(false),
        abortController = useRef<AbortController>(),
        {
            id: reportId,
            feature: featureUrlId,
            layer: targetLayerId
        } = useParams<{ [id: string]: string }>(),
        featureIds =
            mode === 'aggregate' || featureUrlId === 'multiple'
                ? (qs.has('features') ? qs.get('features') || '' : '').toString()
                : featureUrlId,
        history = useHistory(),
        location = useLocation(),
        dispatch = useAppDispatch(),
        dataProvider = useRef<any>(),
        intl = useIntl(),
        isInErrorState = !isNullOrUndefined(error),
        viewerElementRef = useRef<HTMLDivElement>(null),
        renderEventTimeoutId = useRef<number>(-1),
        pagesInViewport = useRef<boolean[]>([]),
        mapTaskScheduler = useRef<TaskScheduler>();

    useEffect(() => {
        const autoHandleWindowResize = (e: any) => {
            window.clearTimeout(window['rbResizeTimeoutId']);
            window['rbResizeTimeoutId'] = window.setTimeout(handleWindowResize, 250);
        };
        const handleWindowBeforePrint = (e: any) => {
            const reportElement: HTMLElement | null = document.querySelector(
                '.esriuk-app .viewer .ia-report-holder'
            );
            addClass(reportElement, 'pages-force-all');
            for (let id in Chart.instances) {
                const cc: any = Chart.instances[id],
                    cw = cc.canvas.closest('.ia-report-widget'),
                    ccw = Math.min(
                        cc.canvas.width > 0 ? cc.canvas.width : 9999,
                        convertLengthToPixels(
                            cw !== null && cw.style !== undefined && cw.style !== null
                                ? cw.style.width
                                : undefined,
                            true,
                            true,
                            9999
                        ),
                        cw !== null ? cw.offsetWidth : 0
                    ),
                    cch = Math.min(
                        cc.canvas.height > 0 ? cc.canvas.height : 9999,
                        convertLengthToPixels(
                            cw !== null && cw.style !== undefined && cw.style !== null
                                ? cw.style.height
                                : undefined,
                            true,
                            true,
                            9999
                        ),
                        cw !== null ? cw.offsetHeight : 0
                    );
                if (ccw > 0 && cch > 0) {
                    cc.canvas.parentNode.style.width = `${ccw}px`;
                    cc.canvas.parentNode.style.height = `${cch}px`;
                    //console.log(`w=${ccw}px, h=${cch}px`); // DEBUG
                }
                cc.resize(true);
                cc.update({ duration: 0 }); // Try to force a chart render without animating which may take too long
            }
        };
        const handleWindowAfterPrint = (e: any) => {
            const reportElement: HTMLElement | null = document.querySelector(
                '.esriuk-app .viewer .ia-report-holder'
            );
            removeClass(reportElement, 'pages-force-all');
        };
        setLoading(true);
        // Clear...and establish a controller that can kill all the pending requests
        abortController.current = new AbortController();
        setError(null);
        if (portalUrl !== undefined && portalUrl !== null && portalUrl !== '') {
            onPageStart({
                reportId,
                token: getTokenFor(portalUrl, tokenManager, ''),
                portalUrl,
                portalType,
                dispatch,
                onError: (err) => {
                    setLoading(false);
                    setError(err);
                },
                lockNavigation,
                appAuthenticationId,
                dataMode,
                windowMode
            });
        }
        dispatch(setPageState(`View | Item | Report Builder for ArcGIS`, undefined, null, null));
        if (windowMode === 'standalone') window.scrollTo(0, 0); // Reset on initial mount
        window.addEventListener('resize', autoHandleWindowResize);
        window.addEventListener('beforeprint', handleWindowBeforePrint);
        window.addEventListener('afterprint', handleWindowAfterPrint);
        document.body.addEventListener('rb.widgetdataerror', handleDataError);
        addClass(document.body, windowMode === 'embedded' ? 'rb-view-embedded' : 'rb-view-page');
        return () => {
            window.removeEventListener('resize', autoHandleWindowResize);
            window.removeEventListener('beforeprint', handleWindowBeforePrint);
            window.removeEventListener('afterprint', handleWindowAfterPrint);
            document.body.removeEventListener('rb.widgetdataerror', handleDataError);
            removeClass(document.body, 'rb-view-page');
            removeClass(document.body, 'rb-view-embedded');
            if (abortController.current !== undefined) abortController.current.abort();
        };
    }, [
        reportId,
        windowMode,
        dataMode,
        dispatch,
        portalType,
        portalUrl,
        lockNavigation,
        appAuthenticationId,
        tokenManager
    ]);

    useEffect(() => {
        const handleWindowBeforePrint = (e: any) => {
            if (backgroundProcessing) {
                alert(
                    '⚠️ One or more maps in this report are still downloading data. Your print output will be incomplete. Please cancel the print dialog and wait until all the maps have loaded.'
                );
            }
            const shadowHost = document.querySelector('calcite-shell') as HTMLElement;
            if (
                shadowHost !== undefined &&
                shadowHost !== null &&
                !hasClass(shadowHost, 'rb-block-adjusted')
            ) {
                const blockStyle = document.createElement('style');
                blockStyle.innerHTML =
                    '@media print { .main, .content { block-size: auto !important; } }';
                shadowHost.shadowRoot?.appendChild(blockStyle);
                addClass(shadowHost, 'rb-block-adjusted');
            }
        };
        window.addEventListener('beforeprint', handleWindowBeforePrint);
        return () => {
            window.removeEventListener('beforeprint', handleWindowBeforePrint);
        };
    }, [backgroundProcessing]);

    const oldReportItem: any = usePrevious(reportItem),
        oldId: string =
            oldReportItem !== undefined && oldReportItem.info !== undefined
                ? oldReportItem.info.id
                : '',
        reportExists: boolean =
            reportItem !== undefined &&
            reportItem !== null &&
            reportItem.info !== undefined &&
            reportItem.info.id === reportId,
        reportHasChanged: boolean =
            reportExists && (oldReportItem === undefined || oldId !== reportItem.info.id),
        oldFeatureIds = usePrevious(featureIds),
        featuresHaveChanged = oldFeatureIds !== featureIds;

    useEffect(() => {
        if (!isInErrorState && reportExists) {
            //&& (reportHasChanged || featuresHaveChanged)) {
            // CSS - this is legacy model, might change?
            injectStyles(
                reportItem.report.design,
                windowMode === 'embedded' ? { url: `${appHome}/view-report/` } : null,
                '/view-report/',
                undefined,
                windowMode === 'embedded'
            );
            const { report, info, webmap } = reportItem,
                stamp = new Date().getTime().toFixed(0);
            // Got the report, now see if there are data sources to populate the on-screen items...
            (async () => {
                try {
                    let catalogSrc,
                        f: any,
                        adjustedByQueryString = false;
                    const uqs: { [id: string]: any } = {
                        ...lowerKeyParams(new URLSearchParams(window.location.search)),
                        ...lowerKeys(overrides !== undefined && overrides !== null ? overrides : {})
                    };
                    if (dataMode === 'local') {
                        dataProvider.current = await fetchReportDataFromBrowser(
                            info.id,
                            featureIds,
                            token
                        );
                        f = [
                            {
                                id: featureIds,
                                name: featureIds
                            }
                        ];
                        if (
                            !isNullOrUndefined(report.data) &&
                            !isNullOrUndefined(report.data.sources)
                        )
                            catalogSrc = report.data.sources.find((s) => s.type === 'DataCatalog');
                    } else if (dataMode === 'static') {
                        const coreFeatureUrl = `${staticUrl.replace(/\/$/, '')}/${info.id}/${(
                            info.pattern || '#FID.json'
                        ).replace('#FID', featureIds)}`;
                        dataProvider.current = new JsonFileDataSource(coreFeatureUrl, token, 0);
                        f = await dataProvider.current.init().then(() => {
                            return [
                                {
                                    id: featureIds,
                                    name: featureIds
                                }
                            ];
                        });
                        if (
                            !isNullOrUndefined(report.data) &&
                            !isNullOrUndefined(report.data.sources)
                        )
                            catalogSrc = report.data.sources.find((s) => s.type === 'DataCatalog');
                    } else if (
                        !isNullOrUndefined(report.data) &&
                        !isNullOrUndefined(report.data.sources)
                    ) {
                        let dataSourcesPromise = Promise.resolve(report.data.sources);
                        const overLayerId =
                                uqs['layer'] !== undefined
                                    ? uqs['layer']
                                    : targetLayerId !== undefined && targetLayerId !== null
                                    ? targetLayerId
                                    : '',
                            overCatalogId = uqs['catalog'] !== undefined ? uqs['catalog'] : '';
                        // Override core data source?
                        if (
                            report.data.allowCoreOverride !== undefined &&
                            report.data.allowCoreOverride !== null &&
                            report.data.allowCoreOverride === true &&
                            overLayerId !== null &&
                            overLayerId !== ''
                        ) {
                            const ovDataSources = [...report.data.sources];
                            // GUID => an item inside ArcGIS Online
                            if (
                                /^[0-9a-fA-F]{8}[0-9a-fA-F]{4}[0-9a-fA-F]{4}[0-9a-fA-F]{4}[0-9a-fA-F]{12}$/.test(
                                    overLayerId
                                )
                            ) {
                                dataSourcesPromise = ArcGISPortal.getItemDescription(
                                    overLayerId,
                                    { f: 'json', token: token },
                                    portalUrl
                                ).then((layerItem) => {
                                    ovDataSources[0].url = layerItem.url;
                                    return ovDataSources;
                                });
                                adjustedByQueryString = true;
                            }
                            // https: => a raw URL
                            else if (/^http|https:\/\/.*$/.test(overLayerId)) {
                                dataSourcesPromise = new Promise((resolve, reject) => {
                                    ovDataSources[0].url = overLayerId;
                                    resolve(ovDataSources);
                                });
                                adjustedByQueryString = true;
                            }
                            // Otherwise must be an ID inside a catalog... which imposes more requests...
                            else if (overCatalogId !== null && overCatalogId !== '') {
                                dataSourcesPromise = findCoreLayer(
                                    overCatalogId,
                                    overLayerId,
                                    portalUrl,
                                    token
                                ).then((urls: string[]) => {
                                    const [geoUrl, tableUrl] = urls;
                                    if (geoUrl !== undefined && geoUrl !== null) {
                                        ovDataSources[0].url = geoUrl;
                                        const tempCatalogSrc = ovDataSources.find(
                                            (s) => s.type === 'DataCatalog'
                                        );
                                        if (tempCatalogSrc !== undefined)
                                            tempCatalogSrc.url = tableUrl;
                                    }
                                    return ovDataSources;
                                });
                                adjustedByQueryString = true;
                            }
                        }
                        //dataSourcesPromise.then((reportDataSources: any) => {
                        const activeDataSources = await dataSourcesPromise,
                            coreSrc = activeDataSources[0],
                            requiredIndicators = findIndicatorRefs(report.design),
                            // Finer-grained control over the fetch implementation...
                            fetchInitArgs = {
                                signal:
                                    abortController.current !== undefined &&
                                    abortController.current !== null
                                        ? abortController.current.signal
                                        : new AbortController().signal,
                                cache: 'default'
                            };
                        let idf = report.design.idField,
                            nmf = report.design.nameField,
                            outf = [idf, nmf];
                        if (
                            !isNullOrUndefined(report.design.customSettings) &&
                            !isNullOrUndefined(report.design.customSettings['dataCachingPolicy']) &&
                            report.design.customSettings['dataCachingPolicy'].toLowerCase() ===
                                'disabled'
                        )
                            fetchInitArgs.cache = 'no-cache'; // *default, no-cache, reload, force-cache, only-if-cached
                        const coreFeatureUrl = coreSrc.url;
                        catalogSrc = activeDataSources.find((s) => s.type === 'DataCatalog');
                        if (adjustedByQueryString && catalogSrc !== undefined) {
                            // Secondary check - are the ID and name fields valid?
                            const {
                                info: fsDetail,
                                idField: foundIdf,
                                nameField: foundNmf
                            } = await findStandardFields(
                                coreFeatureUrl,
                                idf,
                                nmf,
                                undefined,
                                undefined,
                                portalUrl,
                                getTokenFor(coreFeatureUrl, tokenManager, token)
                            );
                            idf = foundIdf;
                            nmf = foundNmf;
                            outf = [idf, nmf];
                            report.design.idField = foundIdf; // In-memory but may be used elsewhere
                            report.design.nameField = foundNmf;
                            if (
                                fsDetail !== undefined &&
                                fsDetail !== null &&
                                fsDetail.relationships !== undefined &&
                                fsDetail.relationships !== null
                            ) {
                                report.data.coreRelationships = [...fsDetail.relationships]; // Attached to the report to make it easy to percolate through the stack...
                                report.data.coreName = fsDetail.name;
                            }
                            report.adjustedInMemory = true;
                        }
                        // Keep the data provider out of state to avoid issues with state serialization
                        dataProvider.current = new ReportDataSource(
                            activeDataSources,
                            idf,
                            nmf,
                            token,
                            portalUrl,
                            requiredIndicators,
                            webmap,
                            tokenManager
                        );
                        // TODO - look at using web workers here, to split the data fetching requests up...
                        //this.dataWorker = new WrappedWebWorker(reportDataWebWorker);
                        //this.dataWorker.onmessage = (e) =>
                        //{
                        //    console.log('worker event' + e); // DEBUG
                        //};
                        //this.dataWorker.postMessage({
                        //    action: 'init',
                        //    sources: activeDataSources,
                        //    idField: idf,
                        //    token: token,
                        //    indicators: requiredIndicators
                        //});
                        const fetchFeatureUrlPromise =
                                mode === 'aggregate'
                                    ? ArcGISPortal.getInfo(coreFeatureUrl, {
                                          f: 'json',
                                          token: getTokenFor(coreFeatureUrl, tokenManager, token)
                                      })
                                    : Promise.resolve(null),
                            urlInfo = await fetchFeatureUrlPromise,
                            idField =
                                urlInfo !== undefined &&
                                urlInfo !== null &&
                                urlInfo.fields !== undefined
                                    ? urlInfo.fields.find(
                                          (uf) => uf.name.toLowerCase() === idf.toLowerCase()
                                      )
                                    : undefined,
                            isNumericIdf =
                                idField !== undefined &&
                                idField.type !== undefined &&
                                [
                                    'esriFieldTypeInteger',
                                    'esriFieldTypeBigInteger',
                                    'esriFieldTypeSmallInteger',
                                    'esriFieldTypeDouble',
                                    'esriFieldTypeSingle',
                                    'esriFieldTypeOID'
                                ].indexOf(idField.type) >= 0,
                            whereClause =
                                mode === 'aggregate'
                                    ? isNumericIdf &&
                                      featureIds.split(',').filter((fid) => /^[0-9]+$/.test(fid))
                                          .length === featureIds.split(',').length
                                        ? featureIds
                                              .split(',')
                                              .map((fid) => `${idf}=${fid}`)
                                              .join(' OR ')
                                        : `${idf} IN ('${featureIds.split(',').join(`','`)}')`
                                    : featureIds === '___iaFirstFeature'
                                    ? '1=1'
                                    : /^[^0a-zA-Z_][0-9]+$/.test(featureIds) && isNumericIdf
                                    ? `${idf}=${featureIds}` //` OR ${idf}='${featureIds}'`
                                    : `${idf}='${featureIds}'`,
                            featuresRsp = await ArcGISPortal.queryFeatures(
                                coreFeatureUrl,
                                whereClause,
                                mode === 'aggregate' ? 100 : 1,
                                {
                                    f: 'json',
                                    token: isSameDomain(coreFeatureUrl, portalUrl)
                                        ? token
                                        : undefined,
                                    outFields: outf,
                                    orderByFields: nmf
                                },
                                true,
                                fetchInitArgs
                            ),
                            featureArray = featuresRsp.features;
                        if (
                            featureArray === undefined ||
                            featureArray === null ||
                            featureArray.length < 1
                        )
                            throw new Error(
                                `No features found in core layer matching ID(s) "${featureIds}"`
                            );
                        const fidf = featuresRsp.fields.find(
                                (ff) => ff.name.toLowerCase() === idf.toLowerCase()
                            ).name,
                            fnmf = featuresRsp.fields.find(
                                (ff) => ff.name.toLowerCase() === nmf.toLowerCase()
                            ).name;
                        f = featureArray.map((ff) => {
                            return {
                                id: ff.attributes[fidf].toString(),
                                name: ff.attributes[fnmf]
                            };
                        });
                    } else {
                        console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!'); // DEBUG
                        console.log(reportItem); // DEBUG
                        throw new Error('Cannot access data sources');
                    }
                    // Got the feature?
                    if (f !== undefined && Array.isArray(f) && f.length > 0) {
                        const t = intl.formatMessage(
                            {
                                id: 'view.titleFormat',
                                defaultMessage:
                                    '{reportTitle} | {featureName} | Report Builder for ArcGIS'
                            },
                            {
                                reportTitle: info.title,
                                featureName: f.map((ff) => ff.name).join(', ')
                            }
                        );
                        document.title = t;
                        if (!isNullOrUndefined(window.parent)) {
                            window.parent.postMessage(
                                JSON.stringify({
                                    title: info.title,
                                    featureName: f.map((ff) => ff.name).join(', '),
                                    styles: {
                                        ...(report.design.pageStyles || {})
                                    }
                                }),
                                window.location.href.split('?')[0]
                            );
                        }
                        let scripted = false,
                            scriptLibs: any[] = [],
                            isSw: boolean;
                        for (let s of report.design.sections) {
                            for (let w of s.widgets || []) {
                                isSw =
                                    w.scriptClass === 'EmbeddedWebScriptWidget' ||
                                    w.scriptClass === 'ScriptWidget';
                                scripted = scripted || isSw;
                                if (
                                    isSw &&
                                    w.libraries !== undefined &&
                                    w.libraries !== 'none' &&
                                    scriptLibs.indexOf(w.libraries) < 0
                                )
                                    scriptLibs.push(w.libraries);
                                if (w.timestamp === undefined) w.timestamp = stamp;
                                //if (scripted) break;
                                if (w.childWidgets !== undefined) {
                                    for (let c of w.childWidgets) {
                                        if (c.timestamp === undefined) c.timestamp = stamp;
                                        isSw =
                                            c.scriptClass === 'EmbeddedWebScriptWidget' ||
                                            c.scriptClass === 'ScriptWidget';
                                        scripted = scripted || isSw;
                                        if (
                                            isSw &&
                                            c.libraries !== undefined &&
                                            c.libraries !== 'none' &&
                                            scriptLibs.indexOf(c.libraries) < 0
                                        )
                                            scriptLibs.push(c.libraries);
                                        //if (scripted) break;
                                    }
                                }
                            }
                        }
                        // If there is a script widget, add jQuery for utility functions...
                        if (scripted && scriptLibs.indexOf('jquery') >= 0) {
                            const jqSrc = `${window.location.pathname.substring(
                                0,
                                window.location.pathname.indexOf('/view-report/')
                            )}/static/lib/jquery-3.4.1.min.js`; // jquery-3.4.1.slim.min.js
                            if (document.querySelector(`head > script[src="${jqSrc}"]`) === null) {
                                const jqElement = document.createElement('script');
                                jqElement.setAttribute('class', 'ia-generated');
                                jqElement.setAttribute('async', 'true');
                                jqElement.setAttribute('src', jqSrc);
                                document.head.appendChild(jqElement);
                            }
                        }
                        //setLoading(f.length < 1);
                        setPage(startPage);
                        setMaxPage(report.design.sections.length - 1);
                        pagesInViewport.current = [];
                        for (let i = 0; i < report.design.sections.length; i++) {
                            pagesInViewport.current.push(i === startPage);
                        }
                        //setActiveFeature(f);
                        setMetadataServiceUrl(catalogSrc !== undefined ? catalogSrc.url : null);
                        setError(null);
                        // Finer-grained control over the fetch implementation...
                        const fetchInitArgs = {
                            signal:
                                abortController.current !== undefined &&
                                abortController.current !== null
                                    ? abortController.current.signal
                                    : new AbortController().signal,
                            cache: 'default'
                        };
                        if (
                            !isNullOrUndefined(report.design.customSettings) &&
                            !isNullOrUndefined(report.design.customSettings['dataCachingPolicy']) &&
                            report.design.customSettings['dataCachingPolicy'].toLowerCase() ===
                                'disabled'
                        ) {
                            fetchInitArgs.cache = 'no-cache'; // *default, no-cache, reload, force-cache, only-if-cached
                            // Disable caching in-memory for calls to catalog services
                            ArcGISPortal.ALLOW_MEMORY_CACHE = false;
                        }
                        dataProvider.current
                            .init(fetchInitArgs)
                            .then((ds) => {
                                // 2nd chance for the feature, because this can be lazy-loaded with the data tables for a static JSON file
                                if (ds.feature !== undefined) {
                                    f = Array.isArray(ds.feature) ? ds.feature : [ds.feature];
                                    document.title = intl.formatMessage(
                                        {
                                            id: 'view.titleFormat',
                                            defaultMessage:
                                                '{reportTitle} | {featureName} | Report Builder for ArcGIS'
                                        },
                                        {
                                            reportTitle: info.title,
                                            featureName: f.map((ff) => ff.name).join(', ')
                                        }
                                    );
                                }
                                setLoading(false);
                                setActiveFeature(f);
                                // Kick to apply refresh of screen...
                                handleWindowResize();
                            })
                            .catch((err) => {
                                console.log(err); // DEBUG
                                setLoading(false);
                                setActiveFeature([]);
                                setError(err);
                            });
                    } else
                        throw new Error(
                            featureIds === '___iaFirstFeature'
                                ? `Cannot find features in core layer`
                                : `Cannot find feature(s) matching IDs ${featureIds}`
                        );
                } catch (featureErr: any) {
                    console.log(featureErr); // DEBUG
                    setActiveFeature([]);
                    setLoading(false);
                    if (featureErr.code === undefined || featureErr.code !== 20)
                        setError(featureErr);
                }
            })();
        } else {
            //const activePage = document.getElementById(`page${info.id}P${(page + 1)}`);
            //if (!isNullOrUndefined(activePage)) addClass(activePage, 'active');
            // TODO - some other global handlers to deal with intra-report page navigation when the report is "paged"
        }
    }, [
        reportItem,
        reportExists,
        reportHasChanged,
        featureIds,
        featuresHaveChanged,
        startPage, // Primary drivers of change
        isInErrorState,
        portalUrl,
        dataMode,
        mode,
        intl,
        token,
        staticUrl, // ↓↓↓ Unlikely to change ↓↓↓, but has an impact if they do...
        overrides,
        targetLayerId,
        tokenManager,
        appHome,
        windowMode
    ]);

    const downloadToBrowser = () => {
        setBackgroundProcessing(true);
        if (activeFeature !== null) {
            const { report } = reportItem,
                timestamp: string | undefined =
                    report.data !== undefined &&
                    report.data.allowLocalDownload !== undefined &&
                    report.data.allowLocalDownload === true &&
                    report.data.localDownloadMode !== undefined &&
                    report.data.localDownloadMode === 'time-stamped'
                        ? new Date()
                              .toISOString()
                              .substring(0, 'yyyy-MM-ddTHH:mm:ss'.length)
                              .replace(/[^0-9a-zA-Z]/g, '')
                        : undefined;
            downloadReportToBrowser(reportItem, activeFeature, token, portalUrl, timestamp).then(
                (downloadResult: any) => {
                    // TODO - inform them and/or change the URL QS to reflect this...
                    setBackgroundProcessing(false);
                    setModal({
                        id: 'messageDialog',
                        args: {
                            title: (
                                <FormattedMessage
                                    id="view.offlineDialog.title"
                                    defaultMessage="Report Downloaded"
                                />
                            ),
                            body: (
                                <FormattedMessage
                                    id="view.offlineDialog.title"
                                    defaultMessage="<p><strong>{name}</strong> is now available offline on this device and browser. To view the offline version use the URL below or follow the link on the homepage.</p><p>{link}</p>"
                                    values={{
                                        ...getReactIntlHtmlFuncs(),
                                        name: itemInfo.title,
                                        link: <a href={downloadResult.url}>{downloadResult.url}</a>
                                    }}
                                />
                            )
                        }
                    });
                }
            );
        }
    };

    const deleteFromBrowser = () => {
        setBackgroundProcessing(true);
        const af: NamedItem | null = Array.isArray(activeFeature)
            ? activeFeature[0]
            : activeFeature;
        if (af !== null) {
            deleteReportFromBrowser(reportItem, af).then((deleted: boolean) => {
                if (deleted) {
                    window.location.href = `${window.location.pathname.substring(
                        0,
                        window.location.pathname.indexOf('/view')
                    )}/view-report/${reportId}/${af.id}`; // Bail back to non-saved version
                }
            });
        }
    };

    // Next 5 functions and the navbar actions are <outside> React to avoid reloads of the widgets themselves...
    const resetPages = () => {
        const activePage: NodeListOf<HTMLElement> = document.querySelectorAll(
            '.ia-report-page.active, nav.page-navigation .active'
        );
        activePage.forEach((p) => {
            removeClass(p, 'active');
        });
    };

    const nextPage = (e: any) => {
        e.preventDefault();
        setPage((pi) => {
            const pageIndex = pi + 1;
            return Math.min(maxPage, pageIndex);
        });
        if (e.currentTarget !== undefined && e.currentTarget.blur !== undefined)
            e.currentTarget.blur();
    };

    const prevPage = (e: any) => {
        e.preventDefault();
        setPage((pi) => {
            const pageIndex = pi - 1;
            return Math.max(0, pageIndex);
        });
        if (e.currentTarget !== undefined && e.currentTarget.blur !== undefined)
            e.currentTarget.blur();
    };

    const baseHref =
        appHome.indexOf('http') === 0
            ? `${appHome}${location.pathname}`
            : `${window.location.protocol}//${window.location.hostname}${
                  window.location.port && window.location.port !== ''
                      ? `:${window.location.port}`
                      : ''
              }${appHome.replace(/(.*)\/$/, '$1')}${location.pathname}`;

    const handleInlinePageClick = (e: any) => {
        // Deal with page links embedded in the page...
        if (
            !isNullOrUndefined(e.target) &&
            (e.target.nodeName === 'A' || e.target.closest('a') !== null)
        ) {
            const linkElement = e.target.nodeName === 'A' ? e.target : e.target.closest('a'),
                href = linkElement.getAttribute('href');
            // Standard #pageX or #page<report-id>P<page>
            if (
                !isNullOrUndefined(href) &&
                (href.substring(0, 5) === '#page' || href.substring(0, 6) === '?#page')
            ) {
                const pageId = href.indexOf('?#') === 0 ? href.substring(6) : href.substring(5),
                    pageIndex =
                        parseInt(
                            pageId.indexOf(`${reportId}P`) === 0
                                ? pageId.substring(pageId.lastIndexOf('P') + 1)
                                : pageId
                        ) - 1;
                if (!isNaN(pageIndex) && pageIndex <= maxPage && pageIndex !== page) {
                    e.preventDefault();
                    setPage(Math.max(0, pageIndex));
                } else {
                    const p = document.querySelector(`.ia-page-${page + 1}`);
                    if (p !== undefined && p !== null && p.scrollIntoView !== undefined) {
                        e.preventDefault();
                        p.scrollIntoView();
                    }
                }
            }
            // Intra-report links, but handled without full reload #path:<path> or ./<path>
            else if (
                !isNullOrUndefined(href) &&
                (href.substring(0, 5) === '#path' || href.substring(0, 2) === './')
            ) {
                const pageLocation =
                    href.substring(0, 2) === './' ? href.substring(2) : href.split(':')[1];
                if (!isNullOrUndefined(pageLocation)) {
                    e.preventDefault();
                    const srcPathParts = location.pathname.split('/'),
                        tgtPathParts = pageLocation.split('/'),
                        path = srcPathParts
                            .slice(0, srcPathParts.length - tgtPathParts.length)
                            .concat(tgtPathParts)
                            .join('/');
                    history.push(path);
                }
            }
            // Metadata links, but handled without full reload #metadata:<ID>
            else if (
                !isNullOrUndefined(href) &&
                (href.substring(0, 9) === '#metadata' || href.substring(0, 10) === '?#metadata')
            ) {
                const indId = href.split(':')[1];
                if (
                    !isNullOrUndefined(indId) &&
                    (metadataServiceUrl !== null ||
                        dataProvider.current.catalogSource !== undefined)
                ) {
                    e.preventDefault();
                    addClass(linkElement, 'ia-last-clicked-4modal');
                    setModal({
                        id: 'metadataDialog',
                        args: {
                            indicator: {
                                id: indId
                            }
                        }
                    });
                }
            }
            // Blob links, but handled without full reload #blob:http<URL>
            else if (
                !isNullOrUndefined(href) &&
                (href.substring(0, 10) === '#blob:http' || href.substring(0, 11) === '?#blob:http')
            ) {
                const dataBlobUrl =
                        href.indexOf('?#') === 0 ? href.substring(2) : href.substring(1),
                    dnf = linkElement.getAttribute('data-number-format'),
                    dnl = linkElement.getAttribute('data-number-locale');
                e.preventDefault();
                fetch(dataBlobUrl).then((d) => {
                    d.blob().then((dataBlob) => {
                        dataBlob.text().then((dataJson) => {
                            buildHtmlFromData(JSON.parse(dataJson), dnf, dnl).then((dataHtml) => {
                                addClass(linkElement, 'ia-last-clicked-4modal');
                                setModal({
                                    id: 'dataTableDialog',
                                    args: dataHtml
                                });
                            });
                        });
                    });
                });
            }
            // Some content that must be in a page... and if not we will swallow it anyway, because we can't allow # in case of hash-router
            else if (!isNullOrUndefined(href) && href.substring(0, 1) === '#') {
                e.preventDefault();
                scrollToTarget(href.substring(1), maxPage, setPage);
            }
            // Relative links
            else if (!isNullOrUndefined(href) && href.substring(0, 1) === '.') {
                try {
                    const url = new URL(href, baseHref)
                            .toString()
                            .replace('/view-report/view-report/', '/view-report/'), // Common mistake so may as well deal with it...,
                        tgt = linkElement.getAttribute('target');
                    linkElement.setAttribute('href', url);
                    e.preventDefault();
                    if (tgt !== null && tgt !== '_self') window.open(url, tgt);
                    else window.location.href = url;
                } catch (urlEx) {
                    console.debug(urlEx);
                }
            }
        }
    };

    const clearActiveDialog = () => {
        setModal({});
        // Move the focus to the last clicked object?
        const b: HTMLElement | null = document.querySelector('.ia-last-clicked-4modal');
        if (b !== undefined && b !== null && typeof b.focus !== 'undefined') {
            b.focus();
            removeClass(b, 'ia-last-clicked-4modal');
        }
    };

    const handleWidgetClick = (e, element) => {
        console.log('handleWidgetClick');
        console.log(e);
    };

    const scrollTimeoutId = useRef<number>();
    const handleSectionScrollIntoViewport = (pageIndex: number, isVisible: boolean) => {
        pagesInViewport.current[pageIndex] = isVisible;
        for (let i = 0; i < pagesInViewport.current.length; i++) {
            if (pagesInViewport.current[i]) {
                // Now need to go back into the DOM because the layout stuff needs us to detect which page is closest to the top
                if (scrollTimeoutId.current !== undefined)
                    window.clearTimeout(scrollTimeoutId.current);
                const ti = Math.min(i, maxPage);
                if (ti !== currentPageIndex) {
                    scrollTimeoutId.current = window.setTimeout(() => {
                        setCurrentPageIndex(ti);
                    }, 250);
                }
                break;
            }
        }
    };

    const afterSectionRender = useCallback(
        (sectionElm: HTMLElement, sectionId?: string) => {
            if (anchorTarget !== null) {
                const te: HTMLElement | null = sectionElm.querySelector(`#${anchorTarget}`);
                // Is a child, so scroll to it (with a brief delay for rendering catchup)
                if (te !== undefined && te !== null) {
                    setTimeout(() => {
                        scrollToTarget(anchorTarget, maxPage, setPage);
                    }, 200);
                    return;
                }
            }
            if (startPage !== 0 && sectionElm !== undefined && sectionElm !== null) {
                const s = sectionElm.closest('section');
                if (s !== null && s.hasAttribute('data-page-index')) {
                    const pi = parseInt(s.getAttribute('data-page-index') || '-1');
                    if (pi === startPage) s.scrollIntoView();
                }
            }
            // Kick to apply refresh of screen...
            handleWindowResize();
            // Deal with links in the section
            if (sectionElm !== undefined && sectionElm !== null) {
                window.setTimeout(() => {
                    const links = sectionElm.querySelectorAll('a[href^="."]');
                    links.forEach((a) => {
                        const href = a.getAttribute('href');
                        if (href !== null) {
                            try {
                                const url = new URL(href, baseHref)
                                    .toString()
                                    .replace('/view-report/view-report/', '/view-report/'); // Common mistake so may as well deal with it...
                                a.setAttribute('href', url);
                            } catch (urlEx) {}
                        }
                    });
                }, 500);
            }
        },
        [anchorTarget, maxPage, startPage, baseHref]
    );

    useEffect(() => {
        if (renderEventTimeoutId.current !== undefined && renderEventTimeoutId.current > 0)
            window.clearTimeout(renderEventTimeoutId.current);
        if (onRender !== undefined && onRender !== null) {
            renderEventTimeoutId.current = window.setTimeout(() => {
                onRender({
                    type: 'render',
                    target:
                        viewerElementRef.current !== null ? viewerElementRef.current : undefined,
                    report:
                        reportItem !== undefined && reportItem.info !== undefined
                            ? reportItem.info.id
                            : undefined,
                    feature:
                        activeFeature !== undefined && activeFeature !== null
                            ? activeFeature.map((f) => f.id).join(',')
                            : undefined
                });
            }, 200);
        }
    });

    useLayoutEffect(() => {
        if (inPrintMode) {
            if (page < maxPage) {
                setTimeout(() => {
                    setPage(page + 1);
                }, 900);
            } else {
                setTimeout(() => {
                    window.print();
                    setInPrintMode(false);
                }, 900);
            }
        }
    }, [inPrintMode, page, maxPage]);

    const handleNavClick = (target: string) => {
        history.push(target);
    };

    const { id: activeDialog, args: activeDialogArgs } = modal,
        { info: itemInfo, report, data: itemData, downloaded = '?' } = reportItem,
        anon = isNullOrUndefined(token) || token.length < 10,
        //hosted = !isNullOrUndefined(portalType) && (portalType.toLowerCase() !== 'online'),
        hasActiveDesign =
            !isNullOrUndefined(itemInfo) &&
            !isNullOrUndefined(activeFeature) &&
            !isNullOrUndefined(report) &&
            !isNullOrUndefined(report.design),
        designIsMigrated =
            hasActiveDesign && !isNullOrUndefined(report.migrated) && report.migrated === 'auto',
        designMigrationStamp =
            designIsMigrated &&
            !isNullOrUndefined(user) &&
            !isNullOrUndefined(itemInfo) &&
            itemInfo.owner === user.username,
        pageDisplay =
            hasActiveDesign && !isNullOrUndefined(report.design.pageStyles.display)
                ? report.design.pageStyles.display.toLowerCase()
                : 'single',
        pageBorder =
            hasActiveDesign && !isNullOrUndefined(report.design.pageStyles.border)
                ? report.design.pageStyles.border.toLowerCase()
                : 'standard',
        pageNav =
            hasActiveDesign && !isNullOrUndefined(report.design.pageStyles.navigation)
                ? report.design.pageStyles.navigation.toLowerCase()
                : 'bar-and-arrows',
        pageDataLoad =
            inPrintMode || autoPrint
                ? 'always'
                : hasActiveDesign && !isNullOrUndefined(report.design.pageStyles.load)
                ? report.design.pageStyles.load.toLowerCase()
                : 'when-in-view',
        pageMapsLoad =
            inPrintMode || autoPrint
                ? 'in-sequence'
                : hasActiveDesign && !isNullOrUndefined(report.design.pageStyles.loadMaps)
                ? report.design.pageStyles.loadMaps.toLowerCase()
                : 'immediately',
        pageWidgetEvents =
            inPrintMode || autoPrint
                ? 'none'
                : hasActiveDesign && !isNullOrUndefined(report.design.pageStyles.widgetEvents)
                ? report.design.pageStyles.widgetEvents.toLowerCase()
                : 'none',
        pageWidgetEventSelectionMode =
            inPrintMode || autoPrint
                ? 'none'
                : hasActiveDesign &&
                  !isNullOrUndefined(report.design.pageStyles.widgetEventSelectionMode)
                ? report.design.pageStyles.widgetEventSelectionMode.toLowerCase()
                : 'none',
        navigationRestricted =
            lockNavigation ||
            (qs.has('restrict') &&
                qs.get('restrict') !== null &&
                'true,1,yes'.indexOf((qs.get('restrict') || '').toString()) >= 0),
        pagedClass = `paged ${
            navigationRestricted ? `pages-flow` : `pages-${pageDisplay}`
        } page-border-${pageBorder}`,
        loadOnDemand =
            hasActiveDesign &&
            (pageDisplay === 'single' || pageDisplay === 'paged') &&
            !isNullOrUndefined(report.design.pageStyles.render) &&
            report.design.pageStyles.render.toLowerCase() === 'ondemand',
        isPageChange = page !== usePrevious(page);

    useEffect(() => {
        const p = document.querySelector(`.ia-page-${page + 1}`);
        if (
            isPageChange &&
            p !== undefined &&
            p !== null &&
            p.scrollIntoView !== undefined &&
            windowMode === 'standalone'
        )
            p.scrollIntoView();
        setCurrentPageIndex(page);
    }, [page, isPageChange, windowMode]);

    useEffect(() => {
        if (
            pageMapsLoad === 'in-sequence' &&
            pageDisplay !== 'single' &&
            pageDisplay !== 'paged' &&
            (mapTaskScheduler.current === undefined || mapTaskScheduler.current === null)
        )
            mapTaskScheduler.current = new TaskScheduler();
        else mapTaskScheduler.current = undefined;
    }, [pageMapsLoad, pageDisplay]);

    const pages = hasActiveDesign
            ? (loadOnDemand
                  ? report.design.sections.slice(page, page + 1)
                  : report.design.sections
              ).map((s: any, i: number) => {
                  return (
                      <ReportSectionPanel
                          key={i}
                          {...report.design.pageStyles}
                          index={i}
                          id={`page${itemInfo.id}P${(loadOnDemand ? page : i) + 1}`}
                          design={s}
                          feature={mode === 'aggregate' ? activeFeature : activeFeature.slice(0, 1)}
                          dataProvider={dataProvider.current}
                          dataAvailable={
                              !isNullOrUndefined(dataProvider.current) &&
                              dataProvider.current.available
                          }
                          globals={{
                              ...itemInfo,
                              idField: report.design.idField,
                              nameField: report.design.nameField,
                              locale: report.design.locale,
                              webMap: itemData.values.webmap,
                              downloaded: downloaded,
                              coreRelationships: report.data.coreRelationships, // In most cases these will be undefined
                              coreName: report.data.coreName,
                              reportId: report.id,
                              baseHref,
                              ...(overrides || {})
                          }}
                          className={`ia-page-${(loadOnDemand ? page : i) + 1} ${
                              page === i || (pageDisplay !== 'single' && pageDisplay !== 'paged')
                                  ? 'active'
                                  : ''
                          }`.trim()}
                          onWidgetClick={handleWidgetClick}
                          onRender={afterSectionRender}
                          onVisibleChange={handleSectionScrollIntoViewport}
                          load={pageDataLoad}
                          widgetEvents={pageWidgetEvents}
                          widgetSelectionMode={pageWidgetEventSelectionMode}
                          scheduler={mapTaskScheduler.current}
                      />
                  );
              })
            : null,
        pageList =
            hasActiveDesign && !isNullOrUndefined(report.design.sections) && maxPage > 0 ? (
                <CalcitePagination
                    startItem={currentPageIndex + 1}
                    pageSize={1}
                    totalItems={maxPage + 1}
                    onCalcitePaginationChange={(pd: any) => {
                        const start: number =
                            pd.target !== undefined && pd.target.startItem !== undefined
                                ? pd.target.startItem
                                : pd.startItem;
                        if (start !== undefined && !isNaN(start)) {
                            setCurrentPageIndex(start - 1);
                            setPage(start - 1);
                        }
                    }}
                ></CalcitePagination>
            ) : null,
        mediaResponsive =
            hasActiveDesign &&
            (isNullOrUndefined(report.design.pageStyles.responsive) ||
                report.design.pageStyles.responsive.enabled !== 'none')
                ? 'media-responsive'
                : '',
        fullScreenResponsive =
            hasActiveDesign &&
            report.design.pageStyles.responsive !== undefined &&
            report.design.pageStyles.responsive.expandToFill === true
                ? 'expand-responsive'
                : '',
        pageSize = !hasActiveDesign
            ? { width: '1px', height: '1px' }
            : typeof report.design.pageStyles.size === 'string'
            ? getNamedSize(report.design.pageStyles.size)
            : { ...report.design.pageStyles.size },
        pageWidth = !hasActiveDesign ? '-1' : pageSize.width,
        pageHeight = !hasActiveDesign ? '-1' : pageSize.height,
        pageOrientation =
            pageHeight !== 'auto' &&
            convertLengthToPixels(pageWidth, false, true) /
                convertLengthToPixels(pageHeight, false, true) >
                1.2
                ? 'landscape'
                : 'portrait',
        breakpointWidth =
            hasActiveDesign &&
            !isNullOrUndefined(report.design.pageStyles.responsive) &&
            !isNullOrUndefined(report.design.pageStyles.responsive.width)
                ? parseInt(report.design.pageStyles.responsive.width.toString().replace('px', ''))
                : -1,
        pxWidth =
            typeof pageWidth === 'string'
                ? convertLengthToPixels(pageWidth, true, true)
                : pageWidth,
        pxHeight =
            typeof pageHeight === 'string' && pageHeight !== 'auto'
                ? convertLengthToPixels(pageHeight, true, true)
                : pageHeight,
        pageStyleText = `@page { size: ${pageOrientation}; }`,
        isLocalView =
            hasActiveDesign &&
            report.data !== undefined &&
            report.data.allowLocalDownload !== undefined &&
            report.data.allowLocalDownload !== false &&
            dataMode === 'local',
        warningMessage = isLocalView ? (
            <div className="report-warnings">
                <div>
                    <CalciteIcon icon="exclamation-mark-triangle" scale="s"></CalciteIcon>
                </div>
                <div>
                    <FormattedMessage
                        id="view.localCopy.warning"
                        defaultMessage={`Local Copy`}
                    ></FormattedMessage>
                </div>
                <div>
                    <a href={`../../view-report/${reportId}/${featureIds}`}>
                        <FormattedMessage
                            id="view.localCopy.switchToLive"
                            defaultMessage={`View Latest`}
                        ></FormattedMessage>
                    </a>
                </div>
            </div>
        ) : null;
    //"iao:WebMapId=2b772107be2543b49b7fb680588a7b85;iao:ReportId=;DateFormat=yyyy-MM-dd @ HH:mm:ss;SelectionMode=;DataPolicy=Enabled;iao:schemaOrgReportType=http://schema.org/Report;iao:schemaOrgAreaType=http://schema.org/AdministrativeArea"

    if (mapTaskScheduler.current !== undefined && mapTaskScheduler.current !== null) {
        mapTaskScheduler.current.addProgressListener((i: number, m: number) => {
            //console.debug(`Processed map ${i} of ${m}`); // DEBUG
            setBackgroundProcessing(i < m);
        });
    }

    return loading ? (
        <div className="viewer">
            <div className="progress-big">
                <ProgressMessage
                    message={intl.formatMessage({
                        id: 'view.loadingMessage',
                        defaultMessage: 'Loading report. Please wait...'
                    })}
                />
            </div>
        </div>
    ) : (
        <>
            <div
                ref={viewerElementRef}
                className={`viewer ${dataMode}-view load-${pageDataLoad} ${autoPrintClassName}`.trim()}
            >
                {designMigrationStamp ? (
                    <div className="bg-warning ia-report-fail-message">
                        <p>
                            <FormattedMessage
                                id="view.migrationWarning"
                                defaultMessage="{icon} Report {name} has been automatically migrated from a design made using Report Builder version 1.x. As the author/owner of this report you should open it in edit mode and check the migration before re-saving it. For more information on this please consult the release notes at {link}."
                                values={{
                                    icon: (
                                        <i
                                            aria-hidden="true"
                                            className="fas fa-exclamation-triangle fa-3x fa-pull-left"
                                        ></i>
                                    ),
                                    name: <strong>{itemInfo.title}</strong>,
                                    link: (
                                        <a
                                            href="https://help.reports.esriuk.com/category/report-builder/"
                                            target="rbHelpWindow"
                                        >
                                            https://help.reports.esriuk.com/category/report-builder/
                                            <i
                                                aria-hidden="true"
                                                className="fas fa-external-link-alt"
                                            ></i>
                                        </a>
                                    )
                                }}
                            />
                        </p>
                    </div>
                ) : null}
                {!isNullOrUndefined(error) ? (
                    !isNullOrUndefined(error.code) && error.code === 403 ? (
                        <div className="fatal-error">
                            <CalciteNotice
                                icon="circle-disallowed"
                                scale="l"
                                open={true}
                                kind="danger"
                            >
                                <div slot="title">
                                    <FormattedMessage
                                        id="view.reportForbidden.title"
                                        defaultMessage="Permission denied"
                                    />
                                </div>
                                <div slot="message">
                                    <FormattedMessage
                                        id="view.reportForbidden.message"
                                        defaultMessage="You{user} do not have permission to view report {name}. If you think you should have access please try opening {arclink} on ArcGIS Online to check and/or {authlink}."
                                        values={{
                                            name: (
                                                <strong>
                                                    {!isNullOrUndefined(itemInfo)
                                                        ? itemInfo.title
                                                        : reportId}
                                                </strong>
                                            ),
                                            user: !isNullOrUndefined(user) ? (
                                                <span title={user.username}>
                                                    {' '}
                                                    ({user.fullName})
                                                </span>
                                            ) : (
                                                <></>
                                            ),
                                            arclink: (
                                                <CalciteLink
                                                    href={`https://www.arcgis.com/home/item.html?=${reportId}`}
                                                >
                                                    {!isNullOrUndefined(itemInfo)
                                                        ? itemInfo.title
                                                        : reportId}
                                                </CalciteLink>
                                            ),
                                            authlink: (
                                                <CalciteLink
                                                    href={`${(portalType.toLowerCase() !== 'online'
                                                        ? portalUrl
                                                        : 'https://www.arcgis.com/sharing/rest'
                                                    ).replace(
                                                        /^(.*)\/$/,
                                                        '$1'
                                                    )}/oauth2/authorize?client_id=${RB_APP_VIEW_ONLY_AUTH_ID}&response_type=token&redirect_uri=${encodeURIComponent(
                                                        window.location.href
                                                    )}`}
                                                >
                                                    <FormattedMessage
                                                        id="view.reportForbidden.signInLink"
                                                        defaultMessage="sign in as a different user"
                                                    />
                                                </CalciteLink>
                                            ),
                                            error: (
                                                <span>
                                                    <br />
                                                    <br />
                                                    <code>
                                                        {error.code} {error.message}
                                                    </code>
                                                </span>
                                            )
                                        }}
                                    />
                                </div>
                            </CalciteNotice>
                        </div>
                    ) : (
                        <div className="bg-warning ia-report-fail-message">
                            <p>
                                <FormattedMessage
                                    id="view.reportFail"
                                    defaultMessage="{icon} Report Builder for ArcGIS encountered an unexpected error when opening report {name}. The error details are shown below - please review and try again. If this problem is persistent please contact the owner{owner} of this report. {error}"
                                    values={{
                                        icon: (
                                            <i
                                                aria-hidden="true"
                                                className="fas fa-times-circle fa-3x fa-pull-left"
                                            ></i>
                                        ),
                                        name: (
                                            <strong>
                                                {!isNullOrUndefined(itemInfo)
                                                    ? itemInfo.title
                                                    : reportId}
                                            </strong>
                                        ),
                                        owner:
                                            !isNullOrUndefined(itemInfo) &&
                                            !isNullOrUndefined(itemInfo.owner) ? (
                                                <span>
                                                    &nbsp;
                                                    <a
                                                        href={`https://www.arcgis.com/home/user.html?user=${itemInfo.owner}`}
                                                        target="iaArcWindow"
                                                    >
                                                        ({itemInfo.owner}
                                                        <i
                                                            aria-hidden="true"
                                                            className="fas fa-external-link-alt"
                                                        ></i>
                                                        )
                                                    </a>
                                                </span>
                                            ) : (
                                                ''
                                            ),
                                        error: (
                                            <span>
                                                <br />
                                                <br />
                                                <code>
                                                    {error.code} {error.message}
                                                </code>
                                            </span>
                                        )
                                    }}
                                />
                            </p>
                        </div>
                    )
                ) : null}
                {hasActiveDesign && pageDisplay !== 'quiet' && (
                    <>
                        <nav
                            aria-label="Page navigation"
                            className="page-navigation"
                            style={{
                                display:
                                    pageNav === undefined ||
                                    (pageNav !== undefined && pageNav.indexOf('bar') >= 0)
                                        ? 'inherit'
                                        : 'none'
                            }}
                        >
                            <div className="pagination pagination-sm">
                                {pageList}
                                {!navigationRestricted && (
                                    <div className="index">
                                        <CalciteButton
                                            id={'view-index-button'}
                                            href={`/view-${
                                                dataMode === 'static' ? 'publication' : 'report'
                                            }/${reportId}`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleNavClick(
                                                    `/view-${
                                                        dataMode === 'static'
                                                            ? 'publication'
                                                            : 'report'
                                                    }/${reportId}`
                                                );
                                            }}
                                            className="ia-page-nav-btn"
                                            label="Index"
                                            kind="neutral"
                                            appearance="transparent"
                                            iconStart="globe"
                                        ></CalciteButton>
                                        <CalciteTooltip
                                            referenceElement={'view-index-button'}
                                            label="Index"
                                            placement="auto"
                                            overlayPositioning="absolute"
                                        >
                                            {intl.formatMessage(
                                                {
                                                    id: 'view.navigation.index.tooltip',
                                                    defaultMessage: '{report} | Index'
                                                },
                                                {
                                                    report: itemInfo.title
                                                }
                                            )}
                                        </CalciteTooltip>
                                    </div>
                                )}
                                <OfflinePanel>
                                    <div className="index">
                                        <Link
                                            to={`/`}
                                            className="ia-page-nav-btn"
                                            aria-label="Home"
                                            data-tooltip={intl.formatMessage(
                                                {
                                                    id: 'view.navigation.home.tooltip',
                                                    defaultMessage: 'Home'
                                                },
                                                {
                                                    report: itemInfo.title
                                                }
                                            )}
                                        >
                                            <CalciteIcon icon="home" scale="s"></CalciteIcon>
                                        </Link>
                                    </div>
                                </OfflinePanel>
                                {isNullOrUndefined(report.design.allowPrint) ||
                                report.design.allowPrint !== false ? (
                                    <div className="index">
                                        <CalciteButton
                                            id={'view-print-button'}
                                            onClick={(e) => {
                                                if (pageDataLoad === 'always') window.print();
                                                else {
                                                    setPage(0);
                                                    setInPrintMode(true);
                                                }
                                                e.preventDefault();
                                                return false;
                                            }}
                                            className="ia-page-nav-btn"
                                            label="Print"
                                            kind="neutral"
                                            appearance="transparent"
                                            iconStart="print"
                                        ></CalciteButton>
                                        <CalciteTooltip
                                            referenceElement={'view-print-button'}
                                            label="Index"
                                            placement="auto"
                                            overlayPositioning="absolute"
                                        >
                                            {intl.formatMessage(
                                                {
                                                    id: 'view.navigation.print.tooltip',
                                                    defaultMessage: 'Print {report}...'
                                                },
                                                {
                                                    report: itemInfo.title
                                                }
                                            )}
                                        </CalciteTooltip>
                                    </div>
                                ) : null}
                                {dataMode === 'live' &&
                                !lockNavigation &&
                                !isNullOrUndefined(report.data.allowLocalDownload) &&
                                report.data.allowLocalDownload !== false ? (
                                    <>
                                        {backgroundProcessing ? (
                                            <span className="ia-page-nav-btn">
                                                <i
                                                    aria-hidden="true"
                                                    className="fas fa-download icon-clip-grow-top"
                                                ></i>
                                            </span>
                                        ) : (
                                            <>
                                                <CalciteButton
                                                    id={'view-offline-button'}
                                                    href="#offline"
                                                    onClick={downloadToBrowser}
                                                    className="ia-page-nav-btn"
                                                    aria-label="Download"
                                                    kind="neutral"
                                                    appearance="transparent"
                                                    iconStart="download"
                                                ></CalciteButton>
                                                <CalciteTooltip
                                                    referenceElement={'view-offline-button'}
                                                    label="Index"
                                                    placement="auto"
                                                    overlayPositioning="absolute"
                                                >
                                                    {intl.formatMessage(
                                                        {
                                                            id: 'view.navigation.download.tooltip',
                                                            defaultMessage:
                                                                'Make {report} available offline...'
                                                        },
                                                        {
                                                            report: itemInfo.title
                                                        }
                                                    )}
                                                </CalciteTooltip>
                                            </>
                                        )}
                                    </>
                                ) : null}
                                {!isNullOrUndefined(report.data.allowLocalDownload) &&
                                report.data.allowLocalDownload !== false &&
                                dataMode === 'local' ? (
                                    <>
                                        {backgroundProcessing ? (
                                            <span className="ia-page-nav-btn">
                                                <i
                                                    aria-hidden="true"
                                                    className="fas fa-download icon-clip-grow-top"
                                                ></i>
                                            </span>
                                        ) : (
                                            <>
                                                <CalciteButton
                                                    id={'view-delete-offline-button'}
                                                    href="#offline"
                                                    onClick={deleteFromBrowser}
                                                    className="ia-page-nav-btn"
                                                    aria-label="Delete local copy"
                                                    kind="neutral"
                                                    appearance="transparent"
                                                    iconStart="trash"
                                                ></CalciteButton>
                                                <CalciteTooltip
                                                    referenceElement={'view-delete-offline-button'}
                                                    label="Index"
                                                    placement="auto"
                                                    overlayPositioning="absolute"
                                                >
                                                    {intl.formatMessage(
                                                        {
                                                            id: 'view.navigation.delete.tooltip',
                                                            defaultMessage:
                                                                'Delete {report} from offline storage...'
                                                        },
                                                        {
                                                            report: itemInfo.title
                                                        }
                                                    )}
                                                </CalciteTooltip>
                                            </>
                                        )}
                                    </>
                                ) : null}
                            </div>
                        </nav>
                    </>
                )}
                <div className={`ia-report-holder ${pagedClass}`}>
                    {warningMessage}
                    {hasActiveDesign ? (
                        <article
                            id={`design_${report.id}`}
                            lang={report.design.locale}
                            className={`ia-report ${mediaResponsive} ${fullScreenResponsive} best-shape-${pageOrientation} ${
                                anon ? 'anon' : 'auth'
                            } ${designIsMigrated ? 'deign-migrated' : ''}`.trim()}
                            style={{
                                ...report.design.style
                            }}
                            onClick={handleInlinePageClick}
                            data-report-uuid={report.id}
                            data-report-size={`${pageWidth};${pxWidth};${breakpointWidth};${pageHeight};${pxHeight}`}
                            data-feature-id={activeFeature.map((f) => f.id).join(' ')}
                        >
                            {pageHeight !== 'auto' && false && <style>{pageStyleText}</style>}
                            <h1 className="silent">
                                ${itemInfo.title} | ${activeFeature.map((f) => f.name).join(', ')}
                            </h1>
                            {!navigationRestricted &&
                                maxPage > 0 &&
                                pageNav.indexOf('arrows') >= 0 && (
                                    <>
                                        <div
                                            className={`page-turner backward ${
                                                page === 0 ? 'disabled' : ''
                                            }`}
                                        >
                                            <button
                                                onClick={prevPage}
                                                disabled={page === 0}
                                                className="btn-link ia-page-nav-btn"
                                                aria-label="Back"
                                            >
                                                <CalciteIcon
                                                    icon="chevron-left"
                                                    scale="l"
                                                ></CalciteIcon>
                                            </button>
                                        </div>
                                        <div
                                            className={`page-turner forward ${
                                                page === maxPage ? 'disabled' : ''
                                            }`}
                                        >
                                            <button
                                                onClick={nextPage}
                                                disabled={page === maxPage}
                                                className="btn-link ia-page-nav-btn"
                                                aria-label="Next"
                                            >
                                                <CalciteIcon
                                                    icon="chevron-right"
                                                    scale="l"
                                                ></CalciteIcon>
                                            </button>
                                        </div>
                                    </>
                                )}
                            {pages}
                        </article>
                    ) : null}
                </div>
                {hasActiveDesign && inPrintMode && (
                    <div className="progress-big screen-only page-level-mask">
                        <ProgressMessage
                            message={
                                <div style={{ fontSize: '16px' }}>
                                    <FormattedMessage
                                        id="view.printProcessing"
                                        defaultMessage="Preparing print view for page {at} of {max}. Please wait..."
                                        values={{
                                            at: page,
                                            max: maxPage
                                        }}
                                    />
                                </div>
                            }
                        />
                    </div>
                )}
            </div>
            {!isNullOrUndefined(activeDialog) &&
            activeDialog === 'metadataDialog' &&
            !isNullOrUndefined(dataProvider.current) &&
            (!isNullOrUndefined(dataProvider.current.catalogSource) ||
                !isNullOrUndefined(metadataServiceUrl)) ? (
                <MetadataDialog
                    catalog={
                        !isNullOrUndefined(dataProvider.current.catalogSource)
                            ? dataProvider.current.catalogSource.connector
                            : null
                    }
                    item={activeDialogArgs}
                    token={token}
                    service={metadataServiceUrl}
                    show={true}
                    onClose={clearActiveDialog}
                />
            ) : null}
            {!isNullOrUndefined(activeDialog) &&
            activeDialog === 'dataTableDialog' &&
            !isNullOrUndefined(activeDialogArgs) ? (
                <ModalDialog
                    show={true}
                    large={true}
                    scroll={true}
                    title={<FormattedMessage id="view.dataDialog.title" defaultMessage="Data" />}
                    buttons={
                        <>
                            {activeDialogArgs.button}
                            <CalciteButton
                                kind="brand"
                                appearance="outline"
                                slot="secondary"
                                onClick={clearActiveDialog}
                                iconStart="x"
                            >
                                <FormattedMessage
                                    id="view.dataDialog.button.close"
                                    defaultMessage="Close"
                                />
                            </CalciteButton>
                        </>
                    }
                    onClose={clearActiveDialog}
                >
                    <div onClick={handleInlinePageClick}>{activeDialogArgs.body}</div>
                </ModalDialog>
            ) : null}
            {!isNullOrUndefined(activeDialog) &&
            activeDialog === 'messageDialog' &&
            !isNullOrUndefined(activeDialogArgs) ? (
                <ModalDialog
                    show={true}
                    large={true}
                    scroll={true}
                    title={
                        activeDialogArgs.title !== undefined ? (
                            activeDialogArgs.title
                        ) : (
                            <FormattedMessage
                                id="view.messageDialog.title"
                                defaultMessage="Message"
                            />
                        )
                    }
                    buttons={
                        <CalciteButton
                            kind="brand"
                            appearance="outline"
                            slot="primary"
                            onClick={clearActiveDialog}
                        >
                            <FormattedMessage
                                id="view.messageDialog.button.close"
                                defaultMessage="OK"
                            />
                        </CalciteButton>
                    }
                    onClose={clearActiveDialog}
                >
                    <div onClick={handleInlinePageClick}>{activeDialogArgs.body}</div>
                </ModalDialog>
            ) : null}
        </>
    );
};

// Taken function out of hook/class for clarity and to reduce need for useCallback and all that stuff
const onPageStart = async ({
    reportId = '',
    token = '',
    forceUpdate = false,
    portalType = 'online',
    portalUrl = 'https://www.arcgis.com/sharing/rest',
    dataMode = 'live',
    staticUrl = '',
    dispatch = (args: any) => {},
    onError = (err: any) => {},
    lockNavigation = false,
    appAuthenticationId = RB_APP_VIEW_ONLY_AUTH_ID,
    windowMode = 'standalone'
}) => {
    const anon = token === undefined || token === null || token.length < 10,
        //hosted = !isNullOrUndefined(portalType) && (portalType.toLowerCase() !== 'online'),
        qs = new URLSearchParams(
            !isNullOrUndefined(window.location.search) ? window.location.search : ''
        ),
        rp: any = anon ? { f: 'json' } : { f: 'json', token: token };
    if (
        qs.has('cache') !== undefined &&
        qs.get('cache') !== null &&
        (qs.get('cache') || '').toString().toLowerCase() !== 'true'
    )
        rp._t = new Date().getTime().toFixed(0); // Cache buster for GETs
    if (forceUpdate || reportId !== '') {
        if (dataMode === 'local') {
            const targetTime: string | null | undefined =
                qs.has('t') && qs.get('t') !== null && qs.get('t') !== '' ? qs.get('t') : undefined;
            fetchReportFromBrowser(reportId, targetTime)
                .then((reportObj: any) => {
                    const {
                        info: reportInfo,
                        report,
                        data: reportData,
                        downloaded = '?'
                    } = reportObj;
                    if (qs.has('pages')) {
                        const pageIndices = (qs.get('pages') || '')
                            .toString()
                            .split(',')
                            .map((p) => parseInt(p)); // zero-based
                        report.sections = report.sections.filter(
                            (s: any, i: number) => pageIndices.indexOf(i) >= 0
                        );
                    }
                    dispatch(
                        setPageReport({
                            info: reportInfo,
                            data: {
                                values: {
                                    webmap: reportData.webmap
                                }
                            },
                            report: {
                                ...report
                            },
                            downloaded: downloaded
                        })
                    ); // Should then trigger another update...
                })
                .catch((reportError) => {
                    onError(reportError);
                });
            //    });
            //});
        } else if (dataMode === 'static') {
            const designUrl = `${staticUrl.replace(/\/$/, '')}/${reportId}/__index.json`;
            get(designUrl).then((reportInfo) => {
                /*document.title = intl.formatMessage({
                        id: 'view.titleFormat', defaultMessage: '{reportTitle} | View | Report Builder for ArcGIS'
                    }, {
                            reportTitle: reportInfo.title
                    });*/
                if (qs.has('pages')) {
                    const pageIndices = (qs.get('pages') || '')
                        .toString()
                        .split(',')
                        .map((p) => parseInt(p)); // zero-based
                    reportInfo.report.sections = reportInfo.report.sections.filter(
                        (s: any, i: number) => pageIndices.indexOf(i) >= 0
                    );
                }
                dispatch(
                    setPageReport({
                        info: reportInfo,
                        data: {
                            values: {
                                webmap: reportInfo.webmap
                            }
                        },
                        report: {
                            ...reportInfo.report
                        }
                    })
                ); // Should then trigger another update...
            });
        } else {
            try {
                const [itemDesc, itemData] = await Promise.all([
                    ArcGISPortal.getItemDescription(reportId, rp, portalUrl),
                    ArcGISPortal.getItemData(reportId, rp, portalUrl)
                ]);
                /*document.title = intl.formatMessage({
                    id: 'view.titleFormat', defaultMessage: '{reportTitle} | View | Report Builder for ArcGIS'
                }, {
                    reportTitle: itemDesc.title
                });*/
                const savedReportDesign = await getReportDesign(
                    itemDesc,
                    itemData,
                    token,
                    portalUrl,
                    !isNullOrUndefined(rp._t) ? `&_t=${rp._t}` : ''
                );
                const updatedDesign = migrateDesign(savedReportDesign),
                    reportDesign = updatedDesign.report;
                if (reportDesign.migrated)
                    logMessagesToConsole(updatedDesign.changes, 'Legacy Design - Report Changes');
                reportDesign.id = itemDesc.id;
                // Act on some query string parameters...
                if (qs.has('pages') && !isNullOrUndefined(reportDesign.design.sections)) {
                    const pageIndices = (qs.get('pages') || '')
                        .toString()
                        .split(',')
                        .map((p) => parseInt(p)); // zero-based
                    reportDesign.design.sections = reportDesign.design.sections.filter(
                        (s: any, i: number) => pageIndices.indexOf(i) >= 0
                    );
                }
                if (
                    qs.has('preview') &&
                    qs.get('preview') !== null &&
                    (qs.get('preview') || '').toString().toLowerCase() === 'true'
                ) {
                    if (qs.has('responsive') && qs.get('responsive') !== null) {
                        const [rw, rh] = (qs.get('responsive') || '').toString().split(',');
                        if (isNullOrUndefined(reportDesign.design.pageStyles.responsive))
                            reportDesign.design.pageStyles.responsive = {
                                enabled: true
                            };
                        reportDesign.design.pageStyles.responsive.width = rw || '';
                        reportDesign.design.pageStyles.responsive.height = rh || '';
                    }
                }
                // Web map?
                if (
                    !isNullOrUndefined(itemData.values) &&
                    !isNullOrUndefined(itemData.values.webmap)
                ) {
                    const mapItemData: any = await ArcGISPortal.getItemData(
                        itemData.values.webmap,
                        rp,
                        portalUrl
                    );
                    dispatch(
                        setPageReport({
                            info: itemDesc,
                            data: itemData,
                            report: reportDesign,
                            webmap: mapItemData
                        })
                    ); // Should then trigger another update...
                } else {
                    dispatch(
                        setPageReport({
                            info: itemDesc,
                            data: itemData,
                            report: reportDesign
                        })
                    ); // Should then trigger another update...
                }
            } catch (itemErr: any) {
                const coreErr =
                    !isNullOrUndefined(itemErr) && !isNullOrUndefined(itemErr.error)
                        ? itemErr.error
                        : itemErr;
                if (
                    !isNullOrUndefined(coreErr) &&
                    !isNullOrUndefined(coreErr.code) &&
                    (coreErr.code === 403 || coreErr.code === 498 || coreErr.code === 499)
                ) {
                    // Signed in already? Just echo the error...
                    if (lockNavigation || (!isNullOrUndefined(token) && token.length > 0)) {
                        onError(coreErr);
                    } else {
                        redirectToSignIn({
                            appAuthId: appAuthenticationId, // Hard-wired - view-only sign in...
                            redirectUri: window.location.href,
                            portalUrl
                        });
                    }
                } else {
                    onError(coreErr);
                }
            }
        }
    }
    //else setPageReport(reportItem); // Should then trigger another update...
};

const handleWindowResize = () => {
    const reportElm: HTMLElement | null = document.querySelector('.ia-report.media-responsive');
    if (
        reportElm !== null &&
        reportElm.getAttribute('data-report-size') !== undefined &&
        reportElm.getAttribute('data-report-size') !== null &&
        reportElm.getAttribute('data-report-size') !== ''
    ) {
        const rs = (reportElm.getAttribute('data-report-size') || '').split(';'),
            ww = Math.min(
                window.innerWidth,
                document.documentElement.clientWidth,
                reportElm.clientWidth
            ),
            pxWidth = parseFloat(rs[1]),
            breakpointWidth = parseFloat(rs[2]),
            pxHeight = rs.length > 4 && rs[3] !== 'auto' ? parseFloat(rs[4]) : -1;
        removeClass(reportElm, 'less-than-25');
        removeClass(reportElm, 'less-than-33');
        removeClass(reportElm, 'less-than-50');
        removeClass(reportElm, 'less-than-66');
        removeClass(reportElm, 'less-than-75');
        removeClass(reportElm, 'less-than-80');
        removeClass(reportElm, 'less-than-100');
        removeClass(reportElm, 'less-than-custom');
        if (ww < pxWidth * 0.250001) addClass(reportElm, 'less-than-25');
        if (ww < pxWidth * 0.330001) addClass(reportElm, 'less-than-33');
        if (ww < pxWidth * 0.50001) addClass(reportElm, 'less-than-50');
        if (ww < pxWidth * 0.660001) addClass(reportElm, 'less-than-66');
        if (ww < pxWidth * 0.750001) addClass(reportElm, 'less-than-75');
        if (ww < pxWidth * 0.800001) addClass(reportElm, 'less-than-80');
        if (ww < pxWidth * 0.990001) addClass(reportElm, 'less-than-100');
        if (ww < breakpointWidth) addClass(reportElm, 'less-than-custom');
        reportElm.setAttribute('data-page-window-width-ratio', (ww / pxWidth).toFixed(5));
        if (hasClass(reportElm, 'expand-responsive')) {
            // Rejig widgets based on their % of the original
            let nw = pxWidth,
                nh = pxHeight;
            if (nh < 0 && !reportElm.hasAttribute('data-natural-size')) {
                nw = reportElm.offsetWidth;
                nh = reportElm.offsetHeight;
                reportElm.setAttribute('data-natural-size', `${nw};${nh}`);
            } else if (nh < 0) {
                nh =
                    parseFloat(reportElm.getAttribute('data-natural-size')?.split(';')[1] || '0') ||
                    reportElm.offsetHeight;
            }
            const scale = (ww * 1.0) / nw,
                widgets = reportElm.querySelectorAll<HTMLElement>(
                    '.ia-report-section-wrapper > .ia-report-widget'
                );
            widgets.forEach((w) => {
                let ww: any = w.style.width,
                    wh: any = w.style.height;
                if (!w.hasAttribute('data-natural-size'))
                    w.setAttribute(
                        'data-natural-size',
                        `${ww};${wh !== 'auto' ? wh : w.offsetHeight}`
                    );
                if (scale > 1.0) {
                    ww = convertLengthToPixels(
                        w.getAttribute('data-natural-size')?.split(';')[0],
                        false,
                        true,
                        -1
                    );
                    wh = convertLengthToPixels(
                        w.getAttribute('data-natural-size')?.split(';')[1],
                        false,
                        true,
                        -1
                    );
                    w.style.width = `${Math.floor(ww * scale)}px`;
                    w.style.height = `${Math.floor(wh * scale)}px`;
                } else {
                    w.style.width = ww;
                    w.style.height = wh;
                }
            });
        }
    }
};

const scrollToTarget = (htmlTargetId: string, maxPage: number, setPage: any) => {
    const targetElement = document.getElementById(htmlTargetId);
    if (targetElement !== undefined && targetElement !== null) {
        let inPage = targetElement.closest('section.ia-section');
        if (inPage !== null) {
            const pageHref = inPage.getAttribute('id'),
                pageIndexAtt = inPage.getAttribute('data-page-index');
            if (
                (pageHref !== null && pageHref.substring(0, 4) === 'page') ||
                !isNullOrUndefined(pageIndexAtt)
            ) {
                const pageIndex =
                    pageIndexAtt !== undefined && pageIndexAtt !== null
                        ? parseInt(pageIndexAtt)
                        : pageHref !== undefined && pageHref !== null
                        ? parseInt(pageHref.substring(4)) - 1
                        : Number.NaN;
                if (!isNaN(pageIndex) && pageIndex <= maxPage) {
                    setPage(Math.max(0, pageIndex));
                    setTimeout(() => {
                        targetElement.scrollIntoView();
                    }, 200);
                } else inPage = null;
            } else inPage = null;
        }
        if (inPage === null) targetElement.scrollIntoView();
    }
};

const handleDataError = (e: any) => {
    const qs = new URLSearchParams(window.location.search);
    if (e.detail && e.detail.code && e.detail.code === 400 && e.detail.url) {
        const err = `${e.detail.code}\t${e.detail.message}\t${e.detail.url}\t${qs.get(
            'outFields'
        )}\t${e.detail.url}?${e.detail.query}\t${e.detail.details}\n`;
        if (window.location.search.indexOf('debug=true') > 0) console.debug(err);
    }
};

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export default ReportViewPage;
