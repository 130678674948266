/* This must be shareable somehow between projects? JC? */
export const get = (
    url: string,
    params?: object | undefined | null,
    fetchArgs?: RequestInit | undefined
): Promise<any> => {
    const queryString = getQueryString(params);
    let outUrl = url;
    if (queryString !== '') outUrl += '?' + queryString;

    if (outUrl.length > 1000) return post(url, params, fetchArgs);
    else return fetch(outUrl, fetchArgs).then((response) => response.json());
};

export const post = (
    url: string,
    params?: object | undefined | null,
    fetchArgs?: RequestInit | undefined
): Promise<any> => {
    return fetch(url, {
        ...(fetchArgs || {}),
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        body: getQueryString(params)
    }).then((response) => response.json());
};

const getQueryString = (params?: object | undefined | null): string => {
    let arrQuery: string[] = [];
    if (params !== undefined && params !== null) {
        arrQuery = Object.keys(params).reduce((filtered: string[], key: string) => {
            if (params[key] !== undefined) filtered.push(key + '=' + params[key]);
            return filtered;
        }, []);
    }
    return arrQuery.join('&');
};
