import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import MenuBar from './MenuBar';
import MenuToggleButton from './MenuToggleButton';
import { ProgressIcon } from './ProgressMessage';
import { getReport, findCoreLayer, findStandardFields } from '../utils/arcgis-network'; // This is overly expensive, should be able to get the core layer itself
import '../assets/styles/components/ReportSelectorDropDowns.scss';

const ReportSelectorDropDowns = ({
    reports = [],
    token = '',
    feature = '',
    onChange = () => {},
    pattern = '',
    includeGeoIdInPath = false,
    catalogUrl
}: {
    reports: ReportGeoItem[];
    token?: string | undefined;
    feature?: string | undefined;
    onChange?: (u: string, r: string | undefined, f: string | undefined) => void;
    pattern?: string | undefined | null;
    includeGeoIdInPath?: boolean | undefined;
    catalogUrl?: string | undefined;
}) => {
    const defReport = reports.find((r) => r.isDefault),
        defReportId =
            defReport !== undefined ? defReport.id : reports.length > 0 ? reports[0].id : '',
        defGeoId =
            defReport !== undefined ? defReport.geo : reports.length > 0 ? reports[0].geo : '',
        [report, setReport] = useState<string>(defReportId),
        [coreLayer, setCoreLayer] = useState<string>(defGeoId),
        [features, setFeatures] = useState<GeoFeature[]>([]),
        [activeFeature, setActiveFeature] = useState<GeoFeature | null>(null),
        [working, setWorking] = useState<'no' | 'reports' | 'features' | 'yes'>('no'),
        activeReport =
            (coreLayer !== ''
                ? reports.find((r) => r.id === report && r.geo === coreLayer)
                : reports.find((r) => r.id === report)) || reports[0],
        { dataMode = 'report' } = activeReport,
        timerId = useRef(-1),
        history = useHistory();

    useEffect(() => {
        const handleChange = async () => {
            setWorking('features');
            setFeatures([]);
            setActiveFeature(null);
            const reportDetails: any = await getReport(report, token),
                { report: reportObj } = reportDetails,
                coreSrc = reportObj.data.sources[0],
                coreUrlPromise =
                    catalogUrl !== undefined
                        ? findCoreLayer(catalogUrl, coreLayer, undefined, token).then(
                              (urls: string[]) => {
                                  return urls[0];
                              }
                          )
                        : Promise.resolve(coreSrc.url),
                coreFeatureUrl = await coreUrlPromise;
            let idf = reportObj.design.idField,
                nmf = reportObj.design.nameField;
            const { idField: foundIdf, nameField: foundNmf } = await findStandardFields(
                coreFeatureUrl,
                idf,
                nmf,
                undefined,
                undefined,
                undefined,
                token
            );
            if (idf !== foundIdf) idf = foundIdf;
            if (nmf !== foundNmf) nmf = foundNmf;
            const outf = [idf, nmf];
            fetch(
                `${coreFeatureUrl}/query?f=json&outFields=${encodeURIComponent(
                    outf.join(',')
                )}&returnGeometry=false&where=${encodeURIComponent('1=1')}`
            )
                .then((featureRsp: any) => {
                    return featureRsp.json();
                })
                .then((featuresJson: any) => {
                    const featureSet: GeoFeature[] = featuresJson.features.map(
                        (f: any, i: number) => {
                            return {
                                id: f.attributes[idf],
                                name: f.attributes[nmf]
                            };
                        }
                    );
                    featureSet.sort((a, b) => {
                        return a.name.localeCompare(b.name);
                    });
                    setFeatures([...featureSet]);
                    if (feature !== undefined && feature !== null && feature !== '') {
                        const f = featureSet.find((ff) => ff.id === feature);
                        if (f !== undefined) setActiveFeature(f);
                    }
                })
                .finally(() => {
                    setWorking('no');
                });
        };
        handleChange();
    }, [report, token, feature, coreLayer, catalogUrl]);

    const activeId =
        activeFeature !== undefined && activeFeature !== null ? activeFeature.id : feature;
    useEffect(() => {
        const ff: GeoFeature | undefined =
                activeId !== undefined && activeId !== null && activeId !== ''
                    ? features.find((f) => f.id === activeId)
                    : undefined,
            firstLink: HTMLAnchorElement | null =
                ff !== undefined && ff !== null
                    ? document.querySelector(
                          `.ia-report-selector-controls .ia-features-menu > li[data-feature-id="${ff.id}"] > a`
                      )
                    : document.querySelector(
                          '.ia-report-selector-controls .ia-features-menu > li:first-child > a'
                      );
        //if ((activeFeature === undefined || activeFeature === null) && (firstLink !== null)) firstLink.click();
        if (firstLink !== null) {
            if (timerId.current > 0) window.clearTimeout(timerId.current);
            timerId.current = window.setTimeout(() => {
                firstLink.click();
            }, 50);
        }
    }, [features, activeId]);

    const currFeatureId = activeFeature?.id,
        currReportId = activeReport.id,
        currGeoId = activeReport.geo;
    useEffect(() => {
        if (currFeatureId !== undefined && currFeatureId !== null) {
            const activeUrl = includeGeoIdInPath
                ? `/view-${
                      dataMode === 'static' ? 'publication' : 'report'
                  }/${currReportId}/${currFeatureId}/${currGeoId}`
                : `/view-${
                      dataMode === 'static' ? 'publication' : 'report'
                  }/${currReportId}/${currFeatureId}`;
            if (timerId.current > 0) window.clearTimeout(timerId.current);
            timerId.current = window.setTimeout(() => {
                onChange(activeUrl, currReportId, currFeatureId);
            }, 150);
        }
    }, [currFeatureId, currReportId, currGeoId, dataMode, onChange, includeGeoIdInPath]);

    const handleClick = (
        e: React.MouseEvent<HTMLAnchorElement>,
        path: string,
        url: string,
        f?: GeoFeature
    ) => {
        e.preventDefault();
        history.push(path);
        //if (onChange !== undefined) onChange(url, reports.find(r => r.id === report), f); // Handled by useEffect above...
    };

    return (
        <div className="ia-report-selector-controls">
            <MenuBar>
                <MenuToggleButton
                    id="reportsMenu"
                    element="div"
                    elementClassName={`ia-report-selector ${
                        reports.length === 1 ? 'ia-single-report' : 'ia-multi-report'
                    }`}
                    text={
                        <>
                            <i aria-hidden="true" className="fas fa-fw fa-layer-group"></i>
                            <span className="ia-report-name">{activeReport.label}</span>
                            <i aria-hidden="true" className="fas fa-fw fa-caret-down"></i>
                        </>
                    }
                    className={`btn btn-default`}
                    aria-label="Reports Menu"
                >
                    <ul className="dropdown-menu">
                        {reports.map((r, i) => {
                            const url =
                                pattern !== undefined && pattern !== null
                                    ? pattern
                                          .replace(/{report}/gi, activeReport.id)
                                          .replace(/{feature}/gi, '___iaFirstFeature')
                                          .replace(/{geo}/gi, activeReport.geo)
                                    : '';
                            return (
                                <li key={i} data-report-id={r.id}>
                                    {url !== '' ? (
                                        <a
                                            href={url}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setReport(r.id);
                                                setCoreLayer(r.geo);
                                            }}
                                            className={`ia-report-selector-item`}
                                        >
                                            {r.label}
                                        </a>
                                    ) : (
                                        <button
                                            className="ia-report-selector-item"
                                            onClick={() => {
                                                setReport(r.id);
                                                setCoreLayer(r.geo);
                                            }}
                                        >
                                            {r.label}
                                        </button>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </MenuToggleButton>
                <MenuToggleButton
                    id="featuresMenu"
                    element="div"
                    elementClassName="ia-feature-selector"
                    text={
                        <>
                            <i aria-hidden="true" className="fas fa-fw fa-map-marker-alt"></i>
                            {working === 'yes' || working === 'features' ? (
                                <span className="inline-progress-holder">
                                    <ProgressIcon />
                                </span>
                            ) : (
                                <span className="ia-feature-name">
                                    {activeFeature !== null ? activeFeature.name : ''}
                                </span>
                            )}
                            <i aria-hidden="true" className="fas fa-fw fa-caret-down"></i>
                        </>
                    }
                    className={`btn btn-default`}
                    aria-label="Features Menu"
                >
                    <ul className="dropdown-menu ia-features-menu">
                        {features.map((f: GeoFeature, i: number) => {
                            const path = includeGeoIdInPath
                                    ? `/view-${dataMode === 'static' ? 'publication' : 'report'}/${
                                          activeReport.id
                                      }/${f.id}/${activeReport.geo}`
                                    : `/view-${dataMode === 'static' ? 'publication' : 'report'}/${
                                          activeReport.id
                                      }/${f.id}`,
                                url =
                                    pattern !== undefined && pattern !== null
                                        ? pattern
                                              .replace(/{report}/gi, activeReport.id)
                                              .replace(/{feature}/gi, f.id)
                                              .replace(/{geo}/gi, activeReport.geo)
                                        : '';
                            return (
                                <li
                                    key={i}
                                    data-feature-id={f.id}
                                    onClick={() => setActiveFeature({ ...f })}
                                >
                                    {url !== '' ? (
                                        <a
                                            href={url}
                                            onClick={(e) => {
                                                handleClick(e, path, url, f);
                                            }}
                                            className={`geo-feature-link ${
                                                activeId === f.id ? 'geo-feature-active' : ''
                                            }`.trim()}
                                        >
                                            {f.name}
                                        </a>
                                    ) : (
                                        <Link
                                            to={path}
                                            className={`geo-feature-link ${
                                                activeId === f.id ? 'geo-feature-active' : ''
                                            }`.trim()}
                                        >
                                            {f.name}
                                        </Link>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </MenuToggleButton>
            </MenuBar>
        </div>
    );
};

export type ReportGeoItem = {
    id: string;
    geo: string;
    name: string | undefined;
    label: string | undefined;
    dataMode: string;
    isDefault: boolean;
};

type GeoFeature = {
    id: string;
    name: string;
};

export default ReportSelectorDropDowns;
