import { useState, useEffect } from 'react';

const getOnlineStatus = () => {
    return typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
        ? navigator.onLine
        : true;
};

export const useOnlineStatus = () => {
    const [onlineStatus, setOnlineStatus] = useState<boolean>(getOnlineStatus()),
        setOnline = () => setOnlineStatus(true),
        setOffline = () => setOnlineStatus(false);

    useEffect(() => {
        window.addEventListener('online', setOnline);
        window.addEventListener('offline', setOffline);
        return () => {
            window.removeEventListener('online', setOnline);
            window.removeEventListener('offline', setOffline);
        };
    }, []);

    return onlineStatus;
};
