import React from 'react';
import { FormattedMessage } from 'react-intl';
import '../assets/styles/components/Progress.scss';

import '@esri/calcite-components/dist/components/calcite-loader';
import { CalciteLoader } from '@esri/calcite-components-react';

const ProgressMessage = ({
    message,
    subMessage,
    onCancel,
    type = 'standard'
}: {
    message: any;
    subMessage?: any;
    onCancel?: VoidFunction;
    type?: string;
}) => {
    const msg: any =
        message !== undefined ? (
            message
        ) : (
            <FormattedMessage
                id="app.label.progress"
                defaultMessage="Finding data catalog information. Please wait..."
            />
        );
    return (
        <div className="row-fluid anonContent startup">
            <div className="col-md-10 col-md-offset-1">
                <div className="spaced">
                    {type === 'standard' ? (
                        <CalciteLoader
                            scale="l"
                            type="indeterminate"
                            label={
                                message !== undefined &&
                                message !== null &&
                                typeof message === 'string'
                                    ? message
                                    : 'Processing. Please wait...'
                            }
                        ></CalciteLoader>
                    ) : (
                        <>
                            <div className="progress-message progress-message-large">
                                {type !== undefined &&
                                type !== null &&
                                type.toLowerCase() === 'cog' ? (
                                    <i aria-hidden="true" className="fas fa-cog fa-spin"></i>
                                ) : (
                                    <ProgressIcon type={type} />
                                )}
                                {typeof msg === 'string' && msg !== '' ? (
                                    <span style={{ marginLeft: '0.5em' }}>{msg}</span>
                                ) : (
                                    msg
                                )}
                                {onCancel !== undefined ? (
                                    <button
                                        type="button"
                                        onClick={onCancel}
                                        className="btn btn-default btn-secondary"
                                        style={{ marginLeft: '0.5em' }}
                                    >
                                        <FormattedMessage
                                            id="progress.cancelButton.text"
                                            defaultMessage="{icon} Cancel"
                                            values={{
                                                icon: (
                                                    <i
                                                        aria-hidden="true"
                                                        className="fas fa-times"
                                                    ></i>
                                                )
                                            }}
                                        />
                                    </button>
                                ) : null}
                            </div>
                            <p className="startup progress-message" style={{ marginLeft: '36px' }}>
                                {subMessage}
                            </p>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export const ProgressIcon = ({ type, className = '' }: { type?: string; className?: string }) => {
    const mediaQuery: MediaQueryList = window.matchMedia('(prefers-reduced-motion: reduce)');
    // Low motion = simple clock with no animation
    if (mediaQuery.matches) {
        return (
            <span className="progress-message fa-stack native-stack">
                <i className="far fa-clock fa-stack-2x"></i>
            </span>
        );
    }
    let icon = (
        <span className="progress-message fa-stack native-stack">
            <i className="far fa-circle fa-stack-2x"></i>
            <i className="fas fa-circle fa-stack-1x fa-inverse roll-around"></i>
            <i className="fas fa-circle fa-stack-1x fa-inverse roll-around"></i>
            <i className="fas fa-circle fa-stack-1x fa-inverse roll-around"></i>
            <i className="fas fa-circle fa-stack-1x fa-inverse roll-around"></i>
        </span>
    );
    /*icon = (
        <div className="cube-animate">
            <ProductLogo size="1em" />
        </div>
    );*/
    if (type !== undefined && type !== null && type.toLowerCase() === 'spin-globes') {
        icon = (
            <span className="progress-message fa-stack native-stack">
                <i className="far fa-circle fa-stack-2x"></i>
                <i className="fas fa-globe-africa fa-stack-1x fa-inverse roll-around"></i>
                <i className="fas fa-globe-asia fa-stack-1x fa-inverse roll-around"></i>
                <i className="fas fa-globe-europe fa-stack-1x fa-inverse roll-around"></i>
                <i className="fas fa-globe-americas fa-stack-1x fa-inverse roll-around"></i>
            </span>
        );
    } else if (type !== undefined && type !== null && type.toLowerCase() === 'spinner') {
        icon = (
            <span className={`progress-message fa-stack ${className}`.trim()}>
                <i aria-hidden="true" className="far fa-circle fa-stack-1x"></i>
                <i aria-hidden="true" className="far fa-circle fa-stack-1x fa-spin"></i>
                {type !== undefined && type !== null && type.toLowerCase() === 'circle-stacked' ? (
                    <i aria-hidden="true" className="far fa-circle fa-stack-1x central"></i>
                ) : null}
            </span>
        );
    } else if (type !== undefined && type !== null && type.toLowerCase() !== '') {
        icon = (
            <span className="progress-message fa-stack native-stack">
                <i className="far fa-circle fa-stack-2x"></i>
                <i className={`fas fa-${type} fa-stack-1x fa-inverse roll-around`}></i>
                <i className={`fas fa-${type} fa-stack-1x fa-inverse roll-around`}></i>
                <i className={`fas fa-${type} fa-stack-1x fa-inverse roll-around`}></i>
                <i className={`fas fa-${type} fa-stack-1x fa-inverse roll-around`}></i>
            </span>
        );
    }
    return icon;
};

export default ProgressMessage;
