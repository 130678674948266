import { lengthWithUnit } from './AbstractWidget';
import AbstractIconWidget from './AbstractIconWidget';
import { addClass, removeClass, hasClass } from '../utils/dom';
import { isNullOrWhitespace, isNullOrEmpty, hyphenate } from '../utils/object';
import { getNumberFormatter } from '../utils/localization';
import { DataManipulator } from 'data-catalog-js-api';
import AbstractChartWidget from './AbstractChartWidget';

export default class IconRepeaterWidget extends AbstractIconWidget {
    // Render here is slightly special, because it can _detect_ if the calling (React) class has laid out some of the HTML before calling this method.
    render = (data, options = {}) => {
        if (
            this.container !== undefined &&
            this.container !== null &&
            this.design !== undefined &&
            this.design !== null
        ) {
            // Basics - this will give us the box or the icon content area... using super class
            const settings = {
                    ...IconRepeaterWidget.getDefaults(),
                    ...this.design
                },
                iconContentElement = this.renderBox(data, {
                    ...settings,
                    element: 'figure',
                    boxClass: 'ia-chart-box',
                    messageBackground: settings.backgroundColor, // Transfer chart background to message as well
                    showTitle: true
                }),
                doc = this.container.ownerDocument,
                topWidget = this.container.closest('.ia-report-widget'),
                { activeFeature, comparisonFeatures } = options,
                {
                    iconType,
                    icons,
                    iconImage: iconImages,
                    palette,
                    locale,
                    numberFormat,
                    indicatorIconsLayout,
                    highlightSelectedFeature,
                    highlightColor,
                    iconsFillTo,
                    iconsFillToColor,
                    indicatorBarsLabelFormat,
                    indicatorBarsValueLabelFormat,
                    iconAlignment,
                    includeAllAreas = true,
                    alwaysUseFormattedNumbers = true
                } = settings, // Deconstruct/spread the most used...
                activeFeatureIds = Array.isArray(activeFeature)
                    ? activeFeature.map((f) => f.id.toString())
                    : [activeFeature.id.toString()],
                dataTable = DataManipulator.mergeTables(data, !includeAllAreas), // Discard non-common features? unless they are comparisons
                dataAvailable =
                    dataTable !== undefined &&
                    dataTable !== null &&
                    dataTable.colIds !== undefined &&
                    dataTable.colIds.length > 0 &&
                    dataTable.rows !== undefined &&
                    dataTable.rows !== null,
                scaleBetween = this.getScaleValues(dataTable),
                iAliases = this.indicatorAliases,
                aliasesProvided = iAliases !== undefined && iAliases !== null && iAliases.length > 0,
                findAliasById = (indId) => {
                    return iAliases.find((ia) => ia.id === indId).label;
                },
                findIndexById = (indId) => {
                    return iAliases.find((ia) => ia.id === indId).index;
                },
                idStem = `w${settings.id.replace(/[^0-9a-zA-Z]/g, '')}`,
                availableIcons = iconType === 'Image' ? iconImages.split(/[,|\s]/) : icons.split(/[,|\s]/),
                availableIconColors =
                    palette.indexOf('=') >= 0
                        ? AbstractChartWidget.getKeyedPaletteColors(palette)
                        : AbstractChartWidget.getPaletteColors(palette),
                nfmt = getNumberFormatter(locale, numberFormat),
                usingBars =
                    hyphenate(indicatorIconsLayout) === 'bar-chart' ||
                    hyphenate(indicatorIconsLayout) === 'bar-chart-filled',
                maxFill =
                    iconsFillTo !== undefined && iconsFillTo !== null && iconsFillTo !== '' && iconsFillTo !== '[auto]'
                        ? parseFloat(iconsFillTo)
                        : scaleBetween[1],
                showIndicatorLabels = !isNullOrEmpty(indicatorBarsLabelFormat),
                showIndicatorValueLabels = !isNullOrEmpty(indicatorBarsValueLabelFormat);
            let diff = scaleBetween[1] !== Number.MIN_SAFE_INTEGER ? scaleBetween[1] - scaleBetween[0] : 0;
            // Basic setup...
            iconContentElement.setAttribute('id', `${idStem}icons`);
            let style = 'z-index: 10;'; // overflow: hidden;???
            style += `text-align: ${
                iconAlignment.toLowerCase() !== '' && iconAlignment.toLowerCase() !== 'auto'
                    ? iconAlignment.toLowerCase()
                    : 'center'
            };`;
            if (!isNullOrWhitespace(settings.messagePadding))
                style += `padding: ${lengthWithUnit(settings.messagePadding, 'px', true, 4)};`;
            if (style.length > 0) iconContentElement.setAttribute('style', style);
            // Only one column is tested if the diff is zero...
            if (diff === 0 && dataAvailable) {
                diff = 0;
                for (let r of dataTable.rows) {
                    if (typeof r[1] === 'number') diff = Math.max(r[1], diff);
                }
            }
            let valuePerIcon = settings.iconSizeDivisor,
                maxDisplayValue = Math.max(diff, scaleBetween[1]),
                elmnt;
            const hardLimit = settings.numberOfIcons > 0 ? settings.numberOfIcons : 1000;
            // Hard limit - more than 1000 potential icons then scale it accordingly
            if (maxDisplayValue > hardLimit || valuePerIcon < 0)
                valuePerIcon = Math.max(valuePerIcon, Math.pow(10, Math.floor(Math.log10(maxDisplayValue)) - 2));
            // Default style for icons - could maybe be factored out into <head> or <style>
            let iconStyle = '',
                iconSetStyle = '',
                iconKeyFrames = '',
                iconAnimDelay = 0,
                iconAnimTime = 0;
            if (settings.iconBorders)
                iconStyle += `border: ${lengthWithUnit(settings.iconBorderWidth)} ${
                    settings.iconBorderStyle || 'solid'
                } ${settings.iconBorderColor};`;
            if (!isNullOrWhitespace(settings.iconPadding))
                iconStyle += `padding: ${lengthWithUnit(settings.iconPadding, 'px', true, 4)};`;
            // if (!isNullOrWhitespace(settings.iconMargin))
            //     iconStyle += `margin: ${lengthWithUnit(settings.iconMargin, 'px', true, 4)};`;
            //iconStyle += `color: ${settings.iconColor};`;
            // Animation?
            if (
                settings.animationDuration !== undefined &&
                settings.animationDuration !== null &&
                !isNaN((iconAnimTime = parseFloat(settings.animationDuration)))
            ) {
                iconKeyFrames = `@keyframes animIcon${idStem} { 0% { clip-path: inset(0 100% 0 0); } 99% { clip-path: inset(0 0 0 0); } 100% { clip-path: none; } }`;
                iconSetStyle = `clip-path: inset(0 100% 0 0); animation-duration: ${iconAnimTime}s; animation-name: animIcon${idStem}; animation-fill-mode: forwards;`;
                iconAnimDelay = parseFloat(settings.animationDelay);
                addClass(topWidget, 'ia-animated');
            }
            elmnt = doc.createElement('style');
            elmnt.setAttribute('class', 'ia-generated');
            elmnt.appendChild(
                doc.createTextNode(
                    `#${idStem}icons .ia-icon { ${iconStyle} } .ia-animated.ia-showing #${idStem}icons .ia-icon-set { ${iconSetStyle} } ${iconKeyFrames}`
                )
            );
            iconContentElement.parentNode.insertBefore(elmnt, iconContentElement);
            // Got data?
            if (data !== undefined && data !== null && scaleBetween[1] !== Number.MIN_SAFE_INTEGER) {
                let sortable = [],
                    val,
                    valInt,
                    iconClass,
                    iconAt,
                    iconColorAt,
                    iat = 0,
                    iconBoxContainer,
                    iconBox,
                    iconBoxDisplay = 'inline',
                    icon,
                    iconLabel,
                    f,
                    txt,
                    legendBox = settings.showLegend ? doc.createElement('div') : null,
                    i = 0,
                    maxTotal = 0;
                // Set the value indices on the columns, to pick data up later...
                for (i = 0; i < dataTable.colIds.length; i++) dataTable.colIds[i].index = i; //dataTable.colIds[i].index || i;
                const valueCols = dataTable.colIds.filter(
                    (c, i) => i === 0 || c.type === undefined || (c.type !== 'numerator' && c.type !== 'denominator')
                );
                // If we have a layout where each icon is background-filled rather than foreground, we need to do something different...
                if (hyphenate(indicatorIconsLayout) === 'run-in-with-background-fill') {
                    for (i = 1; i < valueCols.length; i++) {
                        const ci = valueCols[i].index;
                        for (let j = 0; j < dataTable.rows.length; j++) {
                            f = findFeature(dataTable.features, dataTable.rowIds[j]);
                            if (f !== undefined && typeof dataTable.rows[j][ci] === 'number')
                                maxTotal += dataTable.rows[j][ci];
                        }
                    }
                    //if (settings.numberOfIcons > 0) valuePerIcon = Math.abs(maxTotal) / parseFloat(settings.numberOfIcons);
                    //writer.Write(string.Format("<div id=\"{1}_icon_fullSet\" class=\"iao-icon-set iao-icon-value-set\" style=\"font-size: {0}px; line-height: {0}px;\">",
                    //    this.IconSize,
                    //    this.ClientID));
                }
                // Indicators, columns...
                for (i = 1; i < valueCols.length; i++) {
                    sortable = [];
                    const ind = findIndicator(dataTable.indicators, valueCols[i].iid),
                        ci = valueCols[i].index,
                        iName = aliasesProvided
                            ? findAliasById(ind.id)
                            : settings.labelStyle === 'ShortName'
                            ? ind.shortName || ind.name
                            : ind.name,
                        iIndex = aliasesProvided ? findIndexById(ind.id) : i;
                    for (let j = 0; j < dataTable.rowIds.length; j++) {
                        val = dataTable.rows[j][ci] / valuePerIcon;
                        if (alwaysUseFormattedNumbers) {
                            val = nfmt.round(dataTable.rows[j][ci]) / valuePerIcon;
                        }
                        if (
                            !isNaN(val) &&
                            (((f = findFeature(dataTable.features, dataTable.rowIds[j])) !== undefined &&
                                (!f.comparison || settings.showComparisons)) ||
                                (dataTable.features.length < 1 &&
                                    (dataTable.rowIds[j].substring(0, 1) !== '#' || settings.showComparisons)))
                        ) {
                            sortable.push({
                                id: dataTable.rowIds[j],
                                value: val,
                                label: dataTable.rows[j][0],
                                raw: dataTable.rows[j][ci],
                                comparison: f !== undefined ? f.comparison : dataTable.rowIds[j].substring(0, 1) === '#'
                            });
                        }
                    }
                    // Got values for this column, deal with them...
                    if (hyphenate(indicatorIconsLayout) !== 'run-in-with-background-fill') {
                    }
                    // Basics - choosing and styling the icon...
                    iconAt = iat < availableIcons.length ? iat : iat % availableIcons.length;
                    iconColorAt =
                        !Array.isArray(availableIconColors) && typeof availableIconColors.get !== 'undefined'
                            ? availableIconColors.get(iName) || availableIconColors.get(ind.id)
                            : availableIconColors[
                                  iat < availableIconColors.length ? iat : iat % availableIconColors.length
                              ];
                    iconClass = `${
                        availableIcons[iconAt].indexOf(' ') > 0
                            ? availableIcons[iconAt]
                            : `${settings.iconClassPrefix} fa-${availableIcons[iconAt]}`
                    }`; // ${settings.fixedIconWidth ? 'fa-fw' : ''}
                    if (settings.iconSortByValue.toLowerCase() === 'ascending') sortable.sort(sortIconsByValue);
                    else if (settings.iconSortByValue.toLowerCase() === 'descending')
                        sortable.sort(sortIconsByValue).reverse();
                    // All of them?
                    if (!settings.includeAllAreas)
                        sortable = sortable.filter(
                            (vp) => vp.comparison || activeFeatureIds.indexOf(vp.id.toString()) >= 0
                        );
                    // Still got something to show?
                    if (sortable.length > 0) {
                        for (let vp of sortable) {
                            if (settings.includeAllAreas || vp.comparison) {
                                iconAt = iat < availableIcons.length ? iat : iat % availableIcons.length;
                                iconColorAt = iat < availableIconColors.length ? iat : iat % availableIconColors.length;
                                iconClass = `${
                                    availableIcons[iconAt].indexOf(' ') > 0
                                        ? availableIcons[iconAt]
                                        : `${settings.iconClassPrefix} fa-${availableIcons[iconAt]}`
                                }`; // ${settings.fixedIconWidth ? 'fa-fw' : ''}
                                if (
                                    activeFeature !== undefined &&
                                    activeFeature !== null &&
                                    activeFeatureIds.indexOf(vp.id.toString()) >= 0 &&
                                    highlightSelectedFeature
                                )
                                    iconColorAt = highlightColor;
                                else iconColorAt = availableIconColors[iconColorAt];
                            }
                            valInt = Math.floor(vp.value);
                            val = vp.value - valInt;
                            iconBoxContainer = null;
                            if (usingBars) {
                                iconBoxContainer = doc.createElement('div');
                                iconBoxContainer.setAttribute('class', 'row icon-bar-row');
                                iconBoxContainer.setAttribute(
                                    'style',
                                    `margin: 0; padding-bottom: ${lengthWithUnit(
                                        settings.indicatorBarsRowSeparation
                                    )};` +
                                        (settings.indicatorBarsItemAlignment !== undefined &&
                                        settings.indicatorBarsItemAlignment !== 'auto'
                                            ? `align-items: ${settings.indicatorBarsItemAlignment};`
                                            : '')
                                );
                                iconBoxContainer.setAttribute('data-ind-name', iName);
                                iconBoxContainer.setAttribute('data-ind-index', iIndex.toFixed(0));
                                if (showIndicatorLabels) {
                                    iconBox = doc.createElement('div');
                                    iconBox.setAttribute(
                                        'class',
                                        `col label-col col-w-${settings.indicatorBarsLabelWidth}`
                                    );
                                    iconBox.setAttribute(
                                        'style',
                                        `text-align: ${settings.indicatorBarsLabelAlignment.toLowerCase()}; width: ${
                                            settings.indicatorBarsLabelWidth
                                        }%; max-width: ${settings.indicatorBarsLabelWidth}%; flex: 0 0 ${
                                            settings.indicatorBarsLabelWidth
                                        }%;`
                                    );
                                    elmnt = doc.createElement('div');
                                    iconBox.setAttribute('class', `col value-col`);
                                    elmnt.setAttribute(
                                        'style',
                                        `display: flex; justify-content: center; flex-direction: column; height: 100%; min-height: ${lengthWithUnit(
                                            settings.iconSize
                                        )};`
                                    );
                                    iconLabel = doc.createElement('div');
                                    iconLabel.setAttribute('class', 'col label-col indicator-label');
                                    iconLabel.setAttribute('style', 'flex: 0 0 auto; line-height: normal;');
                                    txt = indicatorBarsLabelFormat
                                        .replace(/#FNAME/g, vp.label)
                                        .replace(/#INAME/g, iName)
                                        .replace(/(#IVALUE|#VALUE)/g, nfmt.format(vp.raw))
                                        .replace(/#IDATE/g, valueCols[i].label);
                                    if (txt.indexOf('</') > 0)
                                        iconLabel.innerHTML = txt.replace('<>', '').replace('</>', '');
                                    // Allow empty enclosers, React-style
                                    else iconLabel.appendChild(doc.createTextNode(txt));
                                    elmnt.appendChild(iconLabel);
                                    iconBox.appendChild(elmnt);
                                    iconBoxContainer.appendChild(iconBox);
                                }
                                iconContentElement.appendChild(iconBoxContainer);
                                iconBox = doc.createElement('div');
                                const offset = showIndicatorValueLabels ? 1 : 0;
                                iconBox.setAttribute(
                                    'class',
                                    `col-w-${
                                        100 -
                                        (showIndicatorLabels ? parseInt(settings.indicatorBarsLabelWidth) : 0) -
                                        offset
                                    }`
                                );
                                iconBox.setAttribute('style', 'padding: 0; flex: 1 1 auto;');
                                iconBoxContainer.appendChild(iconBox);
                                if (showIndicatorValueLabels) {
                                    elmnt = doc.createElement('div');
                                    elmnt.setAttribute('class', `col-w-1 ia-row-value`);
                                    elmnt.setAttribute(
                                        'style',
                                        `display: flex; justify-content: center; flex-direction: column; height: 100%; min-height: ${lengthWithUnit(
                                            settings.iconSize
                                        )};`
                                    );
                                    iconLabel = doc.createElement('div');
                                    iconLabel.setAttribute(
                                        'style',
                                        'flex: 0 0 auto; vertical-align: middle; line-height: normal;'
                                    );
                                    txt = indicatorBarsValueLabelFormat.replace(
                                        /(#IVALUE|#VALUE|#VAL)/g,
                                        nfmt.format(vp.raw)
                                    );
                                    if (txt.indexOf('</') > 0)
                                        iconLabel.innerHTML = txt.replace('<>', '').replace('</>', '');
                                    // Allow empty enclosers, React-style
                                    else iconLabel.appendChild(doc.createTextNode(txt));
                                    elmnt.appendChild(iconLabel);
                                    iconBoxContainer.appendChild(elmnt);
                                }
                                iconContentElement.appendChild(iconBoxContainer);
                                iconBoxContainer = iconBox;
                            }
                            iconBox = doc.createElement('div');
                            iconBox.setAttribute('class', 'ia-icon-set pure-tip pure-tip-top');
                            if (settings.tooltipFormat !== undefined && settings.tooltipFormat !== null) {
                                iconBox.setAttribute(
                                    'data-tooltip',
                                    settings.tooltipFormat
                                        .replace(/#COLOR/g, '') // TODO
                                        .replace(/#FNAME/g, vp.label)
                                        .replace(/#FID/g, vp.id)
                                        .replace(/#INAME/g, iName)
                                        .replace(/#IDATE/g, valueCols[i].label)
                                        .replace(/(#IVALUE|#VALUE)/g, nfmt.format(vp.raw, settings.numberFormat))
                                );
                            }
                            //iconBox.setAttribute('data-aria-label', vp.label);
                            iconBox.setAttribute('data-value', alwaysUseFormattedNumbers ? nfmt.round(vp.raw) : vp.raw);
                            iconStyle =
                                iconType === 'image'
                                    ? `min-height: ${lengthWithUnit(
                                          settings.iconSize
                                      )}; width: auto; display: ${iconBoxDisplay};`
                                    : `font-size: ${lengthWithUnit(settings.iconSize)}; display: ${iconBoxDisplay};`;
                            if (iconAnimTime > 0)
                                iconStyle += ` animation-delay: ${(
                                    iconAnimDelay +
                                    (usingBars ? 0 : iat) * iconAnimTime
                                ).toFixed(3)}s;`;
                            if (hyphenate(indicatorIconsLayout) === 'run-in-with-background-fill')
                                iconStyle += `background-color: ${iconColorAt}; color: ${settings.iconColor};`;
                            else iconStyle += `color: ${iconColorAt};`;
                            if (usingBars && hyphenate(indicatorIconsLayout) === 'bar-chart-filled')
                                iconStyle += `position: absolute; left: 0; top: 0;`;
                            iconBox.setAttribute('style', iconStyle);
                            // Bars? Fill to?
                            if (usingBars && hyphenate(indicatorIconsLayout) === 'bar-chart-filled') {
                                elmnt = doc.createElement('div');
                                elmnt.setAttribute('class', 'ia-icon-set-filler');
                                elmnt.setAttribute(
                                    'style',
                                    `font-size: ${lengthWithUnit(
                                        settings.iconSize
                                    )}; color: ${iconsFillToColor}; display: ${iconBoxDisplay};`
                                );
                                for (
                                    let k = 0;
                                    k <
                                    Math.round(
                                        (iconsFillTo !== undefined ? parseFloat(iconsFillTo) : maxDisplayValue) /
                                            valuePerIcon
                                    );
                                    k++
                                ) {
                                    if (iconType === 'Image') {
                                        icon = doc.createElement('img');
                                        icon.setAttribute('src', availableIcons[iconAt]);
                                        icon.setAttribute(
                                            'style',
                                            `height: ${lengthWithUnit(settings.iconSize)}; width: auto;`
                                        );
                                        elmnt.appendChild(icon);
                                    } else {
                                        icon = doc.createElement('i');
                                        icon.setAttribute('class', `ia-icon ${iconClass}`);
                                        elmnt.appendChild(icon);
                                    }
                                }
                                iconBoxContainer.appendChild(elmnt);
                            }
                            for (let k = 0; k < valInt; k++) {
                                if (iconType === 'Image') {
                                    icon = doc.createElement('img');
                                    icon.setAttribute('src', availableIcons[iconAt]);
                                    icon.setAttribute(
                                        'style',
                                        `height: ${lengthWithUnit(settings.iconSize)}; width: auto;`
                                    );
                                    iconBox.appendChild(icon);
                                } else {
                                    icon = doc.createElement('i');
                                    icon.setAttribute('class', `ia-icon ${iconClass}`);
                                    iconBox.appendChild(icon);
                                }
                            }
                            if (val > 0) {
                                elmnt = doc.createElement('div');
                                elmnt.setAttribute(
                                    'class',
                                    hyphenate(indicatorIconsLayout) === 'run-in' ? 'ia-partial-icon' : 'ia-partial-icon'
                                ); // ia-clipped-icon
                                elmnt.setAttribute('data-partial', val.toFixed(3));
                                elmnt.setAttribute(
                                    'style',
                                    `display: inline-block; width: auto; clip-path: inset(0 ${((1 - val) * 100).toFixed(
                                        3
                                    )}% 0 0); overflow: hidden; vertical-align: bottom;`
                                );
                                if (iconType === 'Image') {
                                    icon = doc.createElement('img');
                                    icon.setAttribute('src', availableIcons[iconAt]);
                                    icon.setAttribute(
                                        'style',
                                        `height: ${lengthWithUnit(settings.iconSize)}; width: auto;`
                                    );
                                } else {
                                    icon = doc.createElement('i');
                                    icon.setAttribute('class', `ia-icon ${iconClass}`);
                                }
                                elmnt.appendChild(icon);
                                iconBox.appendChild(elmnt);
                            }
                            if (iconBoxContainer !== null) iconBoxContainer.appendChild(iconBox);
                            else iconContentElement.appendChild(iconBox);
                            if (settings.showLegend && !usingBars) {
                                elmnt = doc.createElement('div');
                                elmnt.setAttribute('class', 'icon-key col-md-2');
                                if (iconType === 'Image') {
                                    icon = doc.createElement('img');
                                    icon.setAttribute('src', availableIcons[iconAt]);
                                    icon.setAttribute(
                                        'style',
                                        `height: ${lengthWithUnit(settings.iconSize)}; width: auto;`
                                    );
                                } else {
                                    icon = doc.createElement('i');
                                    icon.setAttribute('class', `ia-icon ${iconClass}`);
                                }
                                if (hyphenate(indicatorIconsLayout) === 'run-in-with-background-fill')
                                    icon.setAttribute(
                                        'style',
                                        `background-color: ${iconColorAt}; color: ${settings.iconColor};`
                                    );
                                else icon.setAttribute('style', `color: ${iconColorAt};`);
                                elmnt.appendChild(icon);
                                const legLabel = (
                                    settings.legendLabelFormat ||
                                    (settings.includeAllAreas || settings.showComparisons ? ' #FNAME' : ' #INAME')
                                )
                                    .replace(/#COLOR/g, '') // TODO
                                    .replace(/#FNAME/g, vp.label)
                                    .replace(/#FID/g, vp.id)
                                    .replace(
                                        /#INAME/g,
                                        aliasesProvided
                                            ? findAliasById(ind.id)
                                            : settings.labelStyle === 'ShortName'
                                            ? ind.shortName || ind.name
                                            : ind.name
                                    )
                                    .replace(/#IDATE/g, valueCols[i].label)
                                    .replace(/(#IVALUE|#VALUE)/g, nfmt.format(vp.raw, settings.numberFormat));
                                elmnt.appendChild(doc.createTextNode(legLabel));
                                legendBox.appendChild(elmnt);
                            }
                            iat++;
                        }
                    }
                    //iat++;
                }
                if (settings.showLegend && settings.showValuePerIcon) {
                    elmnt = doc.createElement('div');
                    elmnt.setAttribute('class', 'icon-key col-md-2');
                    if (iconType === 'Image') {
                        icon = doc.createElement('img');
                        icon.setAttribute('src', availableIcons[iconAt]);
                        icon.setAttribute('style', `height: ${lengthWithUnit(settings.iconSize)}; width: auto;`);
                        icon.setAttribute('class', `ia-icon`);
                    } else {
                        icon = doc.createElement('i');
                        icon.setAttribute('class', `ia-icon ${iconClass}`);
                    }
                    elmnt.appendChild(icon);
                    elmnt.appendChild(doc.createTextNode(` = ${nfmt.format(valuePerIcon)}`));
                    legendBox.appendChild(elmnt);
                }
                if (legendBox !== null && legendBox.childElementCount > 0) {
                    // && !usingBars)
                    legendBox.setAttribute('class', 'ia-icons-legend row');
                    iconContentElement.appendChild(legendBox);
                }
                this.appendAlternateViewActions(settings, data);
            }
            if (topWidget !== null) {
                // Peg back the partial icons only when the widget becomes visible
                if (
                    (hasClass(topWidget, 'ia-animated') ||
                        topWidget.querySelectorAll('div.ia-partial-icon').length > 0) &&
                    typeof IntersectionObserver !== 'undefined'
                ) {
                    const iconObserver = new IntersectionObserver(
                        (entries, observer) => {
                            entries.forEach((entry) => {
                                const isAnimated = hasClass(entry.target, 'ia-animated');
                                if (entry.intersectionRatio > 0 || hasClass(entry.target, 'ia-fixed-view')) {
                                    if (isAnimated) addClass(entry.target, 'ia-showing');
                                    const partials = Array.from(
                                        entry.target.querySelectorAll('div.ia-partial-icon:not([data-adjusted="true"])')
                                    );
                                    while (partials.length > 0) {
                                        const p = partials.pop(),
                                            marked = p.getAttribute('data-adjusted'),
                                            cut = parseFloat(p.getAttribute('data-partial'));
                                        if (marked !== 'true') {
                                            p.style.clipPath = '';
                                            let w = p.offsetWidth; // After the clip-path has been reset or it impacts on the width
                                            let offsetLeft = 0;
                                            const s =
                                                p.firstElementChild !== undefined && p.firstElementChild !== null
                                                    ? getComputedStyle(p.firstElementChild)
                                                    : null;
                                            if (s !== null) {
                                                offsetLeft =
                                                    parseFloat(
                                                        (s.getPropertyValue('padding-left') || '0').replace('px', '')
                                                    ) +
                                                    parseFloat(
                                                        (s.getPropertyValue('margin-left') || '0').replace('px', '')
                                                    );
                                                w =
                                                    w -
                                                    offsetLeft -
                                                    parseFloat(
                                                        (s.getPropertyValue('padding-right') || '0').replace('px', '')
                                                    ) -
                                                    parseFloat(
                                                        (s.getPropertyValue('margin-right') || '0').replace('px', '')
                                                    );
                                            }
                                            p.style.width = `${(offsetLeft + cut * w).toFixed(3)}px`;
                                            p.setAttribute('data-adjusted', 'true');
                                        }
                                    }
                                    // Gone through once, disconnect (unless we still need the animation handler)
                                    if (!isAnimated) observer.disconnect();
                                } else if (isAnimated && !hasClass(entry.target, 'ia-fixed-view'))
                                    removeClass(entry.target, 'ia-showing');
                            });
                        },
                        {
                            root: document.documentElement,
                            threshold: 0.75
                        }
                    );
                    iconObserver.observe(topWidget);
                }
                topWidget.setAttribute('class', topWidget.getAttribute('class').replace(/\s(placeholder)/g, ''));
            }
        }
    };

    static getDefaults = () => {
        return {
            ...AbstractIconWidget.getDefaults(),
            icons: 'square',
            palette: '#fbb3ad,#b2cce2,#ccebc5,#decbe4,#fed9a5,#ffffcc,#e4d7bc,#fddaec',
            iconSize: '32px',
            iconsFillTo: '1',
            iconsFillToColor: '#cdcdcd',
            indicatorIconsLayout: 'run-in',
            legendEntriesInRows: false,
            numberOfIcons: '100',
            showLegend: false,
            showMissingValuesLabels: false,
            showValuePerIcon: false,
            includeAllAreas: true,
            alwaysUseFormattedNumbers: true
        };
    };
}

/** Specialist subclass of IconRepeaterWidget for bar chart style layout - simplifies settings a LOT */
export class IconBarChartWidget extends IconRepeaterWidget {
    static getDefaults = () => {
        return {
            ...IconRepeaterWidget.getDefaults(),
            icons: 'square',
            palette: '#fbb3ad,#b2cce2,#ccebc5,#decbe4,#fed9a5,#ffffcc,#e4d7bc,#fddaec',
            iconsFillTo: '1',
            iconsFillToColor: '#cdcdcd',
            indicatorBarsLabelAlignment: 'Auto',
            indicatorBarsLabelFormat: '#INAME<br/><b>#IVALUE</b>',
            indicatorBarsLabelWidth: '2',
            indicatorBarsRowSeparation: '20',
            indicatorBarsValueLabelFormat: '#IVALUE',
            indicatorIconsLayout: 'bar-chart',
            includeAllAreas: false,
            alwaysUseFormattedNumbers: false
        };
    };
}

const findFeature = (featureList, id) => {
    return featureList.find((f) => f.id === id);
};

const findIndicator = (indicatorList, id) => {
    return indicatorList.find((f) => f.id === id);
};

const sortIconsByValue = (a, b) => {
    return a.value - b.value;
};
