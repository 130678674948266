import AbstractWidget, { lengthWithUnit } from './AbstractWidget';
import { removeClass } from '../utils/dom';
import { ArcGISPortal } from 'data-catalog-js-api';
import { isNumericField } from './widgetDataUtils';

export default class LayerAttachmentsWidget extends AbstractWidget {
    render = (data, options = {}) => {
        if (
            this.container !== undefined &&
            this.container !== null &&
            this.design !== undefined &&
            this.design !== null
        ) {
            const doc = this.container.ownerDocument,
                topWidget = this.container.closest('.ia-report-widget'),
                dataAvailable = data !== undefined && data !== null && Array.isArray(data) && data.length > 0,
                { activeFeature, comparisonFeatures, idField, nameField, token } = options,
                settings = {
                    ...LayerAttachmentsWidget.getDefaults(),
                    ...this.design
                },
                { showWhenEmpty = true, imageSize = '128px,auto', itemPadding = '5px 5px 5px 5px' } = settings,
                imgSize = `width: ${imageSize.split(',')[0]}; height: ${
                    imageSize.split(',')[1] || imageSize.split(',')[0]
                };`,
                primaryAvailable =
                    activeFeature !== undefined &&
                    activeFeature !== null &&
                    idField !== undefined &&
                    idField !== null &&
                    data[0].indicators !== undefined &&
                    data[0].indicators !== null &&
                    data[0].indicators.length > 0;
            // Primary data source - get the source layer URL from that...
            if (showWhenEmpty || (dataAvailable && primaryAvailable)) {
                const primaryLayer = data.find((d) => d.primary === true),
                    primaryUrl =
                        primaryLayer !== undefined
                            ? primaryLayer.source !== undefined
                                ? primaryLayer.source.url
                                : primaryLayer.indicators[0].src
                            : '';
                if (primaryUrl === '') throw new Error(`Cannot access layer data source in dataset `);
                ArcGISPortal.getInfo(primaryUrl, { token: token })
                    .then((coreLayerInfo) => {
                        const oidf =
                                coreLayerInfo.objectIdField !== undefined ? coreLayerInfo.objectIdField : 'OBJECTID',
                            idf = coreLayerInfo.fields.find((f) => f.name.toLowerCase() === idField.toLowerCase()),
                            nmf = coreLayerInfo.fields.find((f) => f.name.toLowerCase() === nameField.toLowerCase()),
                            fParams = { token: token, outFields: `${oidf},${idf.name},${nmf.name}` };
                        ArcGISPortal.queryFeatures(
                            primaryUrl,
                            !isNumericField(idf)
                                ? `${idf.name} IN ('${(Array.isArray(activeFeature) ? activeFeature : [activeFeature])
                                      .map((f) => f.id)
                                      .join(`','`)}')`
                                : `${idf.name} IN (${(Array.isArray(activeFeature) ? activeFeature : [activeFeature])
                                      .map((f) => f.id)
                                      .join(`,`)})`,
                            1,
                            fParams
                        ).then((featureSet) => {
                            // TODO - deal with the use of multiple features
                            const objectIds = featureSet.map((f) => f[oidf]);
                            ArcGISPortal.getFeatures(
                                `${primaryUrl}/queryAttachments`,
                                {
                                    objectIds: objectIds,
                                    f: 'json',
                                    token: token
                                },
                                true
                            ).then((attachmentData) => {
                                if (attachmentData.attachmentGroups !== undefined) {
                                    let link, child, path, cell, grp;
                                    for (let srcFeature of featureSet) {
                                        const oid = srcFeature[oidf];
                                        grp = attachmentData.attachmentGroups.find((g) => g.parentObjectId === oid);
                                        if (grp !== undefined) {
                                            cell = doc.createElement('div');
                                            cell.setAttribute('class', 'ia-generated');
                                            if (itemPadding !== undefined)
                                                cell.setAttribute(
                                                    'style',
                                                    `padding: ${lengthWithUnit(itemPadding, 'px', true, 4)};`
                                                );
                                            for (let info of grp.attachmentInfos) {
                                                path = `${primaryUrl}/${grp.parentObjectId}/attachments/${info.id}`;
                                                if (settings.useTokensInUrls && token !== undefined)
                                                    path = `${path}?token=${token}`;
                                                link = cell.ownerDocument.createElement('a');
                                                link.setAttribute('class', 'att-link');
                                                link.setAttribute('target', '_blank');
                                                link.setAttribute('href', path);
                                                if (info.contentType.indexOf('image/') === 0) {
                                                    child = cell.ownerDocument.createElement('img');
                                                    child.setAttribute('src', path);
                                                    child.setAttribute('alt', info.name);
                                                    child.setAttribute('style', `${imgSize}`.trim());
                                                    if (info.keywords !== undefined && info.keywords !== '')
                                                        child.setAttribute('title', info.keywords);
                                                    if (settings.hyperlinkImages) link.appendChild(child);
                                                    else link = child;
                                                } else link.appendChild(cell.ownerDocument.createTextNode(info.name));
                                                cell.appendChild(link);
                                            }
                                            this.container.appendChild(cell);
                                        }
                                    }
                                }
                            });
                        });
                    })
                    .catch((err) => {
                        this.renderError(err, settings.id, settings.scriptClass);
                    });
            }
            removeClass(topWidget, 'placeholder');
        }
    };

    static getDefaults = () => {
        return {
            ...AbstractWidget.getDefaults(),
            hyperlinkImages: true,
            imageSize: '128px,auto',
            itemPadding: '5px 5px 5px 5px',
            noRecordsFoundLabel: 'No attachments found',
            useTokensInUrls: true
        };
    };
}
