import AbstractWidget from './AbstractWidget';
import { TextWidget } from './TextWidget';
import { buildDisplayText } from './widgetHelpers';
import { addClass, removeClass, hasClass } from '../utils/dom';

export default class ToggleContentWidget extends AbstractWidget {
    static getDefaults = () => {
        return {
            ...AbstractWidget.getDefaults(),
            text: '<h2>Toggle</h2>',
            elementName: 'div',
            iconOpen: 'toggle-on',
            iconClosed: 'toggle-off',
            iconPosition: 'end', // start | end | none | off
            closedAtStartup: 'expanded'
        };
    };

    render = (data, dataOptions, editable = false) => {
        if (
            this.container !== undefined &&
            this.container !== null &&
            this.design !== undefined &&
            this.design !== null
        ) {
            const {
                    id,
                    text = '<h2>Toggle</h2>',
                    elementName = 'div',
                    iconOpen = 'toggle-on',
                    iconClosed = 'toggle-off',
                    iconPosition = 'end',
                    iconPrefix = 'fa-',
                    iconClass = 'fas fa-fw',
                    closedAtStartup = 'expanded',
                    cssClass = '',
                    cssStyle,
                    locale,
                    numberFormat,
                    noDataText
                } = {
                    ...ToggleContentWidget.getDefaults(),
                    ...this.design
                },
                doc = this.container.ownerDocument,
                btn = doc.createElement('button'), // accessibility...
                span = doc.createElement('span'),
                icon = doc.createElement('i'),
                styleTag = doc.createElement('style'),
                forceCollapseSelector = `ia-collapse--${id}`,
                forceCollapseCss =
                    `.${forceCollapseSelector},` + // standard
                    `.ia-report-viewer .ia-report-widget.ia-text-box-widget.ia-widget-container.${forceCollapseSelector},` + // reports, migrated
                    `.ia-report-viewer .ia-report-widget .ia-widget-container.${forceCollapseSelector}` + // reports, migrated
                    `{ height: 0px !important; margin-top: 0px !important; margin-bottom: 0px !important; }`,
                topWidget = this.container.closest('.ia-report-widget'),
                features = data !== undefined && data !== null && data.length > 0 ? data[0].features : undefined;
            let html = buildDisplayText(text, features, {}, locale);
            if (html.placeholder)
                html = TextWidget.insertValuesIntoText(text, data, numberFormat, locale, noDataText, false);
            else html = html.text;
            let elm = doc.createElement(elementName); // Fallback
            if (html.trim().indexOf('<') === 0) {
                const tagName = html.match(/^<(h1|h2|h3|h4|h5|div|p|span)>.*$/)[1];
                if (tagName !== undefined && tagName !== null) {
                    html = html.substring(html.indexOf('>') + 1, html.lastIndexOf('</'));
                    elm = doc.createElement(tagName);
                }
            }
            styleTag.appendChild(doc.createTextNode(forceCollapseCss));
            styleTag.setAttribute('class', 'ia-generated');
            this.container.appendChild(styleTag);
            let nodeStyle = ``;
            if (cssStyle !== undefined) nodeStyle += cssStyle;
            elm.setAttribute('style', nodeStyle);
            elm.setAttribute('class', `ia-generated ia-expandable ${cssClass}`.trim());
            // Undecorated... and iniitial state
            btn.setAttribute(
                'style',
                'width: 100%; display: flex; flex-direction: row; align-items: center; justify-content: space-between; border: none; background-color: transparent; font-size: 1em; text-align: inherit; color: inherit; font-weight: inherit; font-family: inherit;'
            );
            btn.setAttribute('aria-expanded', 'true');
            // Layout
            span.setAttribute('style', 'flex: 1 1 auto');
            if (html.trim().indexOf('<') === 0) span.innerHTML = html;
            else span.appendChild(doc.createTextNode(html));
            icon.setAttribute('class', `${iconClass} ${iconPrefix}${iconOpen}`);
            icon.setAttribute('style', 'flex: 0 0 auto; margin: 0 5px;');
            if (iconPosition === 'start') btn.appendChild(icon);
            btn.appendChild(span);
            if (iconPosition === 'end') btn.appendChild(icon);
            elm.appendChild(btn);
            addClass(elm, 'ia-open');
            this.container.appendChild(elm);
            btn.addEventListener('click', (evt) => {
                handleExpansionEvent(
                    evt,
                    `${iconPrefix}${iconOpen}`,
                    `${iconPrefix}${iconClosed}`,
                    forceCollapseSelector,
                    `i.${iconClass.split(' ')[0]}`
                );
            });
            if (!editable && closedAtStartup === 'collapsed') btn.click();
            if (topWidget !== null)
                topWidget.setAttribute('class', topWidget.getAttribute('class').replace(/\s(placeholder)/g, ''));
        }
    };
}

const handleExpansionEvent = (evt, icnOpen, icnClosed, collapsedClassName, iconQuerySelector = 'i.fas') => {
    const b = evt.currentTarget,
        hdg = b.parentElement,
        w = hdg.closest('.ia-report-widget');
    if (w !== null) {
        const s = w.parentElement,
            wid = w.getAttribute('data-uuid'),
            controlling = b.getAttribute('aria-controls'),
            expanded = b.getAttribute('aria-expanded') === 'true',
            icn = b.querySelector(iconQuerySelector);
        let siblings = Array.from(s.querySelectorAll('.ia-report-widget'));
        const start = siblings.findIndex((ww) => ww.getAttribute('data-uuid') === wid) + 1,
            cut = siblings.findIndex((ww, i) => i > start && hasClass(ww, 'ia-toggle-content-widget'));
        siblings = siblings.slice(start);
        if (cut > 0) siblings = siblings.slice(0, cut - start);
        if (siblings.length > 0) {
            // Accessibility again...
            if (controlling === undefined || controlling === null || controlling === '') {
                const ids = [];
                siblings.forEach((e, j) => {
                    let id = e.getAttribute('id');
                    if (id === undefined || id === null) {
                        id = `iacontrolled${wid.replace(/[^0-9a-zA-Z]/g, '')}_${j}`;
                        e.setAttribute('id', id);
                    }
                    ids.push(id);
                    e.style.transition = 'height .25s, margin-top .25s, margin-bottom .25s';
                    e.style.overflow = 'hidden';
                    e.style.boxSizing = 'border-box'; // explicit with overflow
                });
                b.setAttribute('aria-controls', ids.join(' '));
            }
            if (expanded) {
                addClass(siblings, collapsedClassName);
                removeClass(hdg, 'ia-open');
                removeClass(hdg, 'expanded');
                addClass(hdg, 'ia-closed');
                b.setAttribute('aria-expanded', 'false');
                if (icn !== null) {
                    removeClass(icn, icnOpen);
                    addClass(icn, icnClosed);
                }
                for (let s of siblings) {
                    const links = s.querySelectorAll('a');
                    links.forEach((a) => {
                        a.setAttribute('tabindex', '-1');
                    });
                }
            } else {
                removeClass(siblings, collapsedClassName);
                addClass(hdg, 'ia-open');
                addClass(hdg, 'expanded');
                removeClass(hdg, 'ia-closed');
                b.setAttribute('aria-expanded', 'true');
                if (icn !== null) {
                    addClass(icn, icnOpen);
                    removeClass(icn, icnClosed);
                }
                for (let s of siblings) {
                    const links = s.querySelectorAll('a');
                    links.forEach((a) => {
                        a.removeAttribute('tabindex');
                    });
                }
            }
        }
    }
};
