import AbstractWidget, { lengthWithUnit } from './AbstractWidget';
import AbstractTableWidget, { findNumeratorValue, applyCellRules } from './AbstractTableWidget';
import { getNumberFormatter, formatDate } from '../utils/localization';
import { DataManipulator } from 'data-catalog-js-api';
import { TextWidget } from './TextWidget';
import { hyphenate, isNullOrWhitespace } from '../utils/object';
import { addClass, hasClass, removeClass } from '../utils/dom';

export default class TableWidget extends AbstractTableWidget {
    render = (data, options = {}) => {
        if (
            this.container !== undefined &&
            this.container !== null &&
            this.design !== undefined &&
            this.design !== null
        ) {
            const doc = this.container.ownerDocument,
                topWidget = this.container.closest('.ia-report-widget'),
                tableElement = doc.createElement('table'),
                dataAvailable = data !== undefined && data !== null && Array.isArray(data) && data.length > 0,
                { activeFeature } = options,
                settings = {
                    ...TableWidget.getDefaults(),
                    ...this.design
                },
                { numberFormat, locale, noDataText, cellDataFormat, columnDirectionality = 'LeftToRight' } = settings,
                nfmt = getNumberFormatter(locale, numberFormat),
                tableId = `table-${settings.id}`,
                fids = (
                    activeFeature !== undefined && activeFeature !== null
                        ? Array.isArray(activeFeature)
                            ? activeFeature
                            : [activeFeature]
                        : []
                ).map((f) => f.id);

            if (dataAvailable || settings.showWhenEmpty !== false) {
                if (this.container.querySelector(`.${tableId}`) !== null)
                    this.container.querySelector(`.${tableId}`).remove();
                tableElement.setAttribute('id', tableId);
                if (columnDirectionality !== undefined && columnDirectionality.toLowerCase() === 'righttoleft')
                    tableElement.setAttribute('dir', 'RTL');
                let style = '';
                if (settings.tableBorderStyle !== undefined && settings.tableBorderStyle !== 'NotSet') {
                    style += `border: ${settings.tableBorderStyle.toLowerCase()} ${lengthWithUnit(
                        settings.tableBorderWidth
                    )} ${settings.tableBorderColor !== undefined ? settings.tableBorderColor : '#000'};`;
                }
                if (settings.collapseBorders !== undefined && settings.collapseBorders === true)
                    style += `border-collapse: collapse;`;
                tableElement.setAttribute('class', `ia-table ${settings.tableCssClass} ia-generated ${tableId}`);
                if (settings.tableAutoWidth !== undefined && settings.tableAutoWidth !== false) style += 'width: 100%;';
                if (settings.autoAdjustColumnWidths !== undefined && settings.autoAdjustColumnWidths !== false)
                    style += 'table-layout: fixed;';
                if (settings.tableCssStyle !== undefined && settings.tableCssStyle !== null)
                    style += settings.tableCssStyle;
                if (style !== '') tableElement.setAttribute('style', style);
                // Child element styles
                style = '';
                if (settings.headerCellBackgroundColor !== undefined && settings.headerCellBackgroundColor !== null)
                    style += `#${tableId} th { background-color: ${settings.headerCellBackgroundColor}; } `;
                if (settings.headerCellTextColor !== undefined && settings.headerCellTextColor !== null)
                    style += `#${tableId} th { color: ${settings.headerCellTextColor}; } `;
                if (settings.cellBackgroundColor !== undefined && settings.cellBackgroundColor !== null)
                    style += `#${tableId} tbody td { background-color: ${settings.cellBackgroundColor}; } `;
                if (settings.cellTextColor !== undefined && settings.cellTextColor !== null)
                    style += `#${tableId} tbody td { color: ${settings.cellTextColor}; } `;
                if (
                    settings.cellBorderStyle !== undefined &&
                    settings.cellBorderStyle !== null &&
                    hyphenate(settings.cellBorderStyle) !== 'not-set'
                ) {
                    style += `#${tableId} tbody td, #${tableId} th { border: ${
                        lengthWithUnit(settings.cellBorderWidth) || '1px'
                    } ${settings.cellBorderStyle} ${settings.cellBorderColor || 'inherit'}; }`;
                }
                if (style.length > 0) {
                    const inlineStyleId = `rbStyleFor${tableId}`,
                        existing = doc.getElementById(inlineStyleId),
                        inlineStyleTag = doc.createElement('style');
                    if (existing !== undefined && existing !== null) existing.remove();
                    inlineStyleTag.setAttribute('id', inlineStyleId);
                    inlineStyleTag.appendChild(doc.createTextNode(style));
                    doc.head?.appendChild(inlineStyleTag);
                }
                // Build up a set of columns...
                if (dataAvailable) {
                    // But if required, re-sort
                    const iAliases = this.indicatorAliases,
                        aliasesProvided = iAliases !== undefined && iAliases !== null && iAliases.length > 0,
                        allTable = DataManipulator.mergeTables(data); // Merge beforehand, because this will impact sorting...
                    if (aliasesProvided) {
                        //for (let ds of data.filter(d => d.colIds !== undefined)) AbstractWidget.applySortAndRename(ds.colIds, iAliases, ds.indicators, ds.rows);
                        AbstractWidget.applySortAndRename(
                            allTable.colIds,
                            iAliases,
                            allTable.indicators,
                            allTable.rows
                        );
                    }
                    // Merge any tables of data into one big table, tweaking the column layout as necessary
                    let coreRows = AbstractWidget.filterTable(
                        allTable,
                        settings.hideMainFeature === true ? [] : null,
                        settings.showComparisons === false
                            ? []
                            : !isNullOrWhitespace(settings.comparisonFeatureIds)
                            ? settings.comparisonFeatureIds.split(',').map((f) => f.split('|')[1])
                            : null
                    );
                    if (settings.aggregatedAreasAlias !== undefined && settings.aggregatedAreasAlias !== null)
                        AbstractWidget.cleanAggregateNames(allTable, settings.aggregatedAreasAlias);
                    if (!isNullOrWhitespace(settings.tableSummary)) {
                        const captionText = TextWidget.insertValuesIntoText(
                                settings.tableSummary,
                                data,
                                numberFormat,
                                locale,
                                noDataText
                            ),
                            tableCaption = doc.createElement('caption');
                        tableCaption.setAttribute('class', 'rb-table-caption');
                        tableCaption.appendChild(doc.createTextNode(captionText));
                        tableElement.appendChild(tableCaption);
                    }
                    const ofc = (hyphenate(settings.orientation) || 'features-are-rows') === 'features-are-columns',
                        dataTable = ofc ? DataManipulator.transposeTable(allTable) : allTable,
                        hasRules =
                            settings.cellColorRules !== undefined &&
                            settings.cellColorRules !== null &&
                            settings.cellColorRules !== '';
                    if (true) {
                        let { colIds, rowIds, rows, indicators } = dataTable,
                            subElement = doc.createElement('thead'),
                            rowElement = doc.createElement('tr'),
                            cell,
                            label,
                            txt,
                            ii,
                            v,
                            vn,
                            isEmptyValue,
                            headerStyle = '';
                        if (settings.headerCellBackgroundColor !== undefined)
                            headerStyle += `background-color: ${settings.headerCellBackgroundColor};`;
                        // Set the value indices on the columns, to pick data up later...
                        for (let i = 0; i < colIds.length; i++) {
                            colIds[i].index = i;
                            colIds[i].hasDateValues =
                                colIds[i].dataType !== undefined && colIds[i].dataType === 'categoric:date';
                        }
                        const valueCols = colIds.filter(
                            (c, i) =>
                                i === 0 || c.type === undefined || (c.type !== 'numerator' && c.type !== 'denominator')
                        );
                        if (settings.rowHeaderWidth !== undefined && settings.rowHeaderWidth !== '')
                            colIds[0].width = `${settings.rowHeaderWidth}%`.replace('%%', '%');
                        const useTh =
                            settings.useRowHeadersInBody !== undefined && settings.useRowHeadersInBody === true;
                        // Generic column width - 1st column...
                        if (colIds[0].width !== undefined) {
                            const cg = doc.createElement('colgroup'),
                                cgc = doc.createElement('col');
                            cgc.setAttribute('style', `width: ${colIds[0].width};`);
                            cg.appendChild(cgc);
                            tableElement.appendChild(cgc);
                        }
                        // Choice of layout - more complex, nested columns...
                        if (
                            ofc &&
                            settings.valueColumnsGroupSize !== undefined &&
                            parseInt(settings.valueColumnsGroupSize) > 1
                        ) {
                            const groupSize = parseInt(settings.valueColumnsGroupSize),
                                compValueIndices = (
                                    settings.valueColumnsForComparators !== undefined
                                        ? settings.valueColumnsForComparators.split(',')
                                        : []
                                ).map((ci) => parseInt(ci) - 1),
                                compGroupSize = compValueIndices.length,
                                nFeatures = colIds.filter((c) => c.iid !== 'name' && c.comparison !== true).length,
                                nComps = colIds.filter((c) => c.iid !== 'name' && c.comparison === true).length;
                            //maxCols = nFeatures * groupSize + nComps * compGroupSize;
                            tableElement.setAttribute(
                                'class',
                                `${tableElement.getAttribute('class') || ''} ia-table-grouped-values`.trim()
                            );
                            // Top left...
                            isEmptyValue =
                                settings.tableTopLeftLabel === undefined ||
                                settings.tableTopLeftLabel === null ||
                                settings.tableTopLeftLabel.trim() === '';
                            cell = doc.createElement(isEmptyValue ? 'td' : 'th');
                            cell.setAttribute('class', `ihc ${settings.headerCellCssClass || ''}`.trim());
                            if (!isEmptyValue) cell.setAttribute('scope', 'col');
                            cell.setAttribute('rowspan', '2');
                            if (headerStyle !== '') cell.setAttribute('style', headerStyle);
                            style = '';
                            if (settings.columnHeaderHeight !== undefined && settings.columnHeaderHeight !== '')
                                style += `height: ${lengthWithUnit(settings.columnHeaderHeight)};`;
                            if (colIds[0].width !== undefined) style += `width: ${colIds[0].width};`;
                            if (style !== '') cell.setAttribute('style', style);
                            label = doc.createElement('span');
                            label.setAttribute('class', 'iname');
                            if (isEmptyValue) label.setAttribute('class', 'iname sr-only');
                            txt =
                                settings.tableTopLeftLabel !== undefined &&
                                settings.tableTopLeftLabel !== null &&
                                settings.tableTopLeftLabel !== ''
                                    ? settings.tableTopLeftLabel
                                    : colIds[0].label;
                            if (txt.indexOf('</') > 0) label.innerHTML = txt;
                            else label.appendChild(doc.createTextNode(txt));
                            cell.appendChild(label);
                            rowElement.appendChild(cell);
                            // Feature headers...
                            let rowElement2 = doc.createElement('tr');
                            for (let i = 1; i < nFeatures + nComps; i++) {
                                cell = doc.createElement('th');
                                cell.setAttribute(
                                    'class',
                                    `ihc ${colIds[i].comparison ? 'cfhc' : 'fhc'} ${
                                        settings.headerCellCssClass || ''
                                    }`.trim()
                                );
                                cell.setAttribute('scope', 'col');
                                cell.setAttribute(
                                    'colspan',
                                    (colIds[i].comparison ? compGroupSize : groupSize).toFixed(0)
                                );
                                cell.setAttribute('data-feature-id', colIds[i].id || colIds[i].iid);
                                if (headerStyle !== '') cell.setAttribute('style', headerStyle);
                                label = doc.createElement('span');
                                label.setAttribute('class', 'iname');
                                txt = settings.valueColumnsGroupHeader
                                    .replace(/#INAME|#NAME|#FNAME/g, colIds[i].label)
                                    .replace(/#DATE|#IDATE/g, '');
                                if (txt.indexOf('</') > 0) label.innerHTML = txt;
                                else label.appendChild(doc.createTextNode(txt));
                                cell.appendChild(label);
                                rowElement.appendChild(cell);
                                // 2nd row...
                                for (let j = 0; j < (colIds[i].comparison ? compGroupSize : groupSize); j++) {
                                    cell = doc.createElement('th');
                                    cell.setAttribute(
                                        'class',
                                        `ihc ${colIds[i].comparison ? 'cfhc' : 'fhc'} ${
                                            settings.headerCellCssClass || ''
                                        }`.trim()
                                    );
                                    cell.setAttribute('scope', 'col');
                                    if (headerStyle !== '') cell.setAttribute('style', headerStyle);
                                    label = doc.createElement('span');
                                    label.setAttribute('class', 'iname');
                                    const iOffset = colIds[i].comparison ? compValueIndices[j] : j;
                                    ii =
                                        rowIds[iOffset] !== undefined && rowIds[iOffset].iid !== undefined
                                            ? indicators.find((ind) => ind.id === rowIds[iOffset].iid)
                                            : undefined;
                                    txt = (
                                        iOffset === 0
                                            ? settings.valueColumnHeader
                                            : settings.valueColumnHeaderNonPrimary
                                    )
                                        .replace(/#INAME|#NAME/g, ii !== undefined ? ii.name : colIds[i].label)
                                        .replace(/#INAME|#NAME|#FNAME/g, colIds[i].label)
                                        .replace(
                                            /#DATE|#IDATE/g,
                                            rowIds[iOffset] !== undefined ? rowIds[iOffset].label : ''
                                        );
                                    if (txt.indexOf('</') > 0) label.innerHTML = txt;
                                    else label.appendChild(doc.createTextNode(txt));
                                    cell.appendChild(label);
                                    rowElement2.appendChild(cell);
                                }
                            }
                            if (settings.showFeatureHeaderRow) subElement.appendChild(rowElement);
                            if (
                                settings.tableTitle !== undefined &&
                                settings.tableTitle !== null &&
                                settings.tableTitle !== ''
                            ) {
                                rowElement = doc.createElement('tr');
                                cell = doc.createElement('th');
                                cell.setAttribute(
                                    'class',
                                    `table-title-cell ${settings.headerCellCssClass || ''}`.trim()
                                );
                                cell.setAttribute('colspan', (nFeatures + nComps + 1).toFixed(0));
                                if (headerStyle !== '') cell.setAttribute('style', headerStyle);
                                txt = TextWidget.insertValuesIntoText(
                                    settings.tableTitle,
                                    data,
                                    numberFormat,
                                    locale,
                                    noDataText
                                );
                                if (txt.indexOf('</') > 0) cell.innerHTML = txt;
                                else cell.appendChild(doc.createTextNode(txt));
                                rowElement.appendChild(cell);
                                subElement.insertBefore(rowElement, subElement.firstChild);
                            }
                            if (rowElement2 !== null) subElement.appendChild(rowElement2);
                            tableElement.appendChild(subElement);
                            subElement = doc.createElement('tbody');
                            let rc = 0;
                            // Rows should have an indicator header now...
                            const activeRows = rows.filter((rr) => rr[0] !== undefined && rr[0].id !== undefined);
                            for (let r = 0; r < activeRows.length; r += groupSize) {
                                rowElement = doc.createElement('tr');
                                rowElement.setAttribute(
                                    'class',
                                    rc % 2 === 0 ? settings.rowCssClass : settings.alternateRowCssClass
                                );
                                // 1st column - name
                                v = activeRows[r][colIds[0].index];
                                if (v === null || v === undefined) v = settings.noDataText;
                                vn = typeof v === 'number';
                                cell = doc.createElement(useTh ? 'th' : 'td');
                                if (useTh && headerStyle !== '') cell.setAttribute('style', headerStyle);
                                cell.setAttribute(
                                    'class',
                                    `${colIds[0].comparison ? 'cfdc' : 'fdc'} ${
                                        vn && settings.rightAlignNumbers !== false ? 'num-right' : ''
                                    }`.trim()
                                );
                                // More complex? A self-labelling item? Normally only the <name> of an indicator...
                                if (v !== null && v !== undefined && v.id !== undefined && v.name !== undefined) {
                                    txt = settings.indicatorLabelText
                                        .replace(
                                            /#INAME|#NAME/g,
                                            settings.labelStyle === 'ShortName' ? v.shortName || v.name : v.name
                                        )
                                        .replace(/#DATE/g, v.date || v.label || '');
                                    rowElement.setAttribute('data-row-id', v.id);
                                } else txt = cellDataFormat.replace(/#VAL/g, vn ? nfmt.format(v) : v);
                                if (txt.indexOf('</') > 0) cell.innerHTML = txt;
                                else cell.appendChild(doc.createTextNode(txt));
                                rowElement.appendChild(cell);
                                // Now more complex - step through the columns...
                                for (let i = 1; i < colIds.length; i++) {
                                    let iOffset = colIds[i].comparison && compGroupSize > 0 ? compValueIndices[0] : 0;
                                    v =
                                        r + iOffset < activeRows.length
                                            ? activeRows[r + iOffset][colIds[i].index]
                                            : undefined;
                                    if (v === null || v === undefined) v = settings.noDataText;
                                    else if (colIds[i].hasDateValues)
                                        v = formatDate(v, settings.dateTimeFormat, settings.locale);
                                    vn = typeof v === 'number';
                                    cell = doc.createElement('td');
                                    cell.setAttribute(
                                        'class',
                                        `${colIds[i].comparison ? 'cfdc' : 'fdc'} ${
                                            vn && settings.rightAlignNumbers !== false ? 'num-right' : ''
                                        }`.trim()
                                    );
                                    txt = cellDataFormat.replace(/#VAL/g, vn ? nfmt.format(v) : v);
                                    if (txt.indexOf('#NUM') >= 0) {
                                        vn = findNumeratorValue(ofc, dataTable, i, r);
                                        txt = txt.replace(/#NUM/g, nfmt.format(vn));
                                    }
                                    if (txt.indexOf('</') > 0) cell.innerHTML = txt;
                                    else cell.appendChild(doc.createTextNode(txt));
                                    rowElement.appendChild(cell);
                                    for (let j = 1; j < (colIds[i].comparison ? compGroupSize : groupSize); j++) {
                                        iOffset = colIds[i].comparison && compGroupSize > 0 ? compValueIndices[j] : j;
                                        v =
                                            r + iOffset < activeRows.length
                                                ? activeRows[r + iOffset][colIds[i].index]
                                                : undefined;
                                        if (v === null || v === undefined) v = settings.noDataText;
                                        else if (colIds[i].hasDateValues)
                                            v = formatDate(v, settings.dateTimeFormat, settings.locale);
                                        vn = typeof v === 'number';
                                        cell = doc.createElement('td');
                                        cell.setAttribute(
                                            'class',
                                            `${colIds[i].comparison ? 'cfdc' : 'fdc'} ${
                                                vn && settings.rightAlignNumbers !== false ? 'num-right' : ''
                                            }`.trim()
                                        );
                                        txt = cellDataFormat.replace(/#VAL/g, vn ? nfmt.format(v) : v);
                                        if (txt.indexOf('#NUM') >= 0) {
                                            vn = findNumeratorValue(ofc, dataTable, colIds[i].index, r + iOffset);
                                            txt = txt.replace(/#NUM/g, nfmt.format(vn));
                                        }
                                        if (txt.indexOf('</') > 0) cell.innerHTML = txt;
                                        else cell.appendChild(doc.createTextNode(txt));
                                        if (hasRules && cell.nodeName.toLowerCase() !== 'th') {
                                            const iIdx = r + iOffset,
                                                indicatorVals = colIds.map((cc) => {
                                                    return { id: cc.id, value: activeRows[iIdx][cc.index] };
                                                });
                                            if (activeFeature !== undefined && activeFeature !== null) {
                                                const afv = indicatorVals.find((iv) => iv.id === fids[0]);
                                                if (afv !== undefined)
                                                    indicatorVals.push({ id: '#ACTIVEFEATURE', value: afv.value });
                                            }
                                            applyCellRules(
                                                cell,
                                                v,
                                                settings.cellColorRules,
                                                settings.cellRuleBehaviour,
                                                undefined,
                                                activeRows[r][colIds[0].index],
                                                indicatorVals
                                            );
                                        }
                                        rowElement.appendChild(cell);
                                    }
                                }
                                subElement.appendChild(rowElement);
                                rc++;
                            }
                            tableElement.appendChild(subElement);
                        }
                        // Otherwise default to a simpler table layout
                        else {
                            for (let h of valueCols) {
                                cell = doc.createElement('th');
                                cell.setAttribute('class', `ihc ${settings.headerCellCssClass || ''}`.trim());
                                cell.setAttribute('scope', 'col');
                                style = '';
                                if (settings.columnHeaderHeight !== undefined && settings.columnHeaderHeight !== '')
                                    style += `height: ${lengthWithUnit(settings.columnHeaderHeight)};`;
                                if (headerStyle !== '') style += headerStyle;
                                if (h.width !== undefined) style += `width: ${h.width};`;
                                if (style !== '') cell.setAttribute('style', style);
                                label = doc.createElement('span');
                                label.setAttribute('class', 'iname');
                                ii = undefined;
                                if (h.iid === 'name' || h.id === 'name') {
                                    const isEmptyValue =
                                        settings.tableTopLeftLabel === undefined ||
                                        settings.tableTopLeftLabel === null ||
                                        settings.tableTopLeftLabel.trim() === '';
                                    if (isEmptyValue) label.setAttribute('class', 'iname sr-only');
                                    txt = !isEmptyValue ? settings.tableTopLeftLabel : h.label;
                                } else if (
                                    indicators !== undefined &&
                                    (ii = indicators.find((ind) => ind.id === h.iid)) !== undefined
                                ) {
                                    txt = settings.indicatorLabelText
                                        .replace(
                                            /#INAME|#NAME/g,
                                            settings.labelStyle === 'ShortName' ? ii.shortName || ii.name : ii.name
                                        )
                                        .replace(/#DATE/g, h.label !== ii.name ? h.label : '');
                                    cell.setAttribute('data-ind-uuid', ii.id);
                                } else {
                                    txt = ofc
                                        ? h.label
                                        : settings.indicatorLabelText
                                              .replace(/#INAME|#NAME/g, h.label)
                                              .replace(
                                                  /#DATE/g,
                                                  settings.indicatorLabelText.indexOf('#INAME') >= 0 ||
                                                      settings.indicatorLabelText.indexOf('#NAME') >= 0
                                                      ? ''
                                                      : h.label
                                              );
                                    cell.setAttribute(`data-${ofc ? 'feature' : 'ind'}-id`, h.iid || h.id);
                                }
                                if (txt.indexOf('</') > 0) label.innerHTML = txt;
                                else label.appendChild(doc.createTextNode(txt));
                                cell.appendChild(label);
                                if (ii !== undefined) this.addMetadataLink(cell, ii, settings);
                                rowElement.appendChild(cell);
                            }
                            if (!ofc || settings.showFeatureHeaderRow) subElement.appendChild(rowElement);
                            if (
                                settings.tableTitle !== undefined &&
                                settings.tableTitle !== null &&
                                settings.tableTitle !== ''
                            ) {
                                rowElement = doc.createElement('tr');
                                cell = doc.createElement('th');
                                cell.setAttribute(
                                    'class',
                                    `table-title-cell ${settings.headerCellCssClass || ''}`.trim()
                                );
                                cell.setAttribute('colspan', valueCols.length.toFixed(0));
                                if (headerStyle !== '') cell.setAttribute('style', headerStyle);
                                txt = TextWidget.insertValuesIntoText(
                                    settings.tableTitle,
                                    data,
                                    numberFormat,
                                    locale,
                                    noDataText
                                );
                                if (txt.indexOf('</') > 0) cell.innerHTML = txt;
                                else cell.appendChild(doc.createTextNode(txt));
                                rowElement.appendChild(cell);
                                subElement.insertBefore(rowElement, subElement.firstChild);
                            }
                            tableElement.appendChild(subElement);
                            subElement = doc.createElement('tbody');
                            let dateRow;
                            const activeRows = ofc ? rows.filter((r) => r[0].id !== undefined) : rows, // Only indicator rows (not numerator/denominator)
                                activeCols = ofc ? colIds : colIds.filter((c) => c.index === 0 || c.type === 'value'); // Only instance columns (not numerator/denominator)
                            if (coreRows.length < 1)
                                coreRows = ofc ? [] : rows.filter((r, i) => fids.indexOf(rowIds[i]) >= 0);
                            for (let r = 0; r < activeRows.length; r++) {
                                rowElement = doc.createElement('tr');
                                rowElement.setAttribute(
                                    'class',
                                    `fr${typeof rowIds[r].iid !== 'undefined' ? rowIds[r].iid : rowIds[r]} ${
                                        r % 2 === 0 ? settings.rowCssClass : settings.alternateRowCssClass
                                    }`
                                );
                                if (typeof rowIds[r].iid === 'undefined')
                                    rowElement.setAttribute('data-feature-id', rowIds[r]);
                                if (
                                    settings.highlightSelectedFeature &&
                                    settings.highlightColor !== undefined &&
                                    activeFeature !== undefined &&
                                    fids.indexOf(rowIds[r]) >= 0
                                ) {
                                    rowElement.setAttribute('style', `background-color: ${settings.highlightColor}`);
                                }
                                dateRow = false;
                                for (let c of activeCols) {
                                    v = activeRows[r][c.index];
                                    const coreVals = ofc ? [] : coreRows.map((r) => r[c.index]),
                                        indicatorVals = ofc
                                            ? activeCols.map((cc) => {
                                                  return { id: cc.id, value: activeRows[r][cc.index] };
                                              })
                                            : activeRows.map((rc, ri) => {
                                                  return { id: rowIds[ri], value: activeRows[ri][c.index] };
                                              });
                                    if (v === null || v === undefined) v = settings.noDataText;
                                    else if (c.hasDateValues || dateRow)
                                        v = formatDate(v, settings.dateTimeFormat, settings.locale);
                                    vn = typeof v === 'number';
                                    cell = doc.createElement(useTh && c.index === 0 ? 'th' : 'td');
                                    if (useTh && c.index === 0 && headerStyle !== '')
                                        cell.setAttribute('style', headerStyle);
                                    if (vn && settings.rightAlignNumbers !== false)
                                        cell.setAttribute('class', 'num-right');
                                    // More complex? A self-labelling item? Normally only the <name> of an indicator...
                                    if (v !== null && v !== undefined && v.id !== undefined && v.name !== undefined) {
                                        txt = settings.indicatorLabelText
                                            .replace(
                                                /#INAME|#NAME/g,
                                                settings.labelStyle === 'ShortName' ? v.shortName || v.name : v.name
                                            )
                                            .replace(/#DATE/g, v.date || v.label || '');
                                        rowElement.setAttribute('data-row-id', v.id);
                                        dateRow =
                                            c.index === 0 &&
                                            v.dataType !== undefined &&
                                            v.dataType === 'categoric:date';
                                    } else if (c.index === 0 || c.iid === 'name') txt = v.toString();
                                    else txt = cellDataFormat.replace(/#VAL/g, vn ? nfmt.format(v) : v);
                                    if (txt.indexOf('#NUM') >= 0) {
                                        vn = findNumeratorValue(ofc, dataTable, c.index, r);
                                        txt = txt.replace(/#NUM/g, nfmt.format(vn));
                                    }
                                    if (txt.indexOf('</') > 0) cell.innerHTML = txt;
                                    else cell.appendChild(doc.createTextNode(txt));
                                    if (v !== null && v !== undefined && v.id !== undefined && v.name !== undefined)
                                        this.addMetadataLink(cell, v, settings);
                                    if (hasRules && cell.nodeName.toLowerCase() !== 'th') {
                                        if (activeFeature !== undefined && activeFeature !== null) {
                                            const afv = indicatorVals.find((iv) => iv.id === fids[0]);
                                            if (afv !== undefined)
                                                indicatorVals.push({ id: '#ACTIVEFEATURE', value: afv.value });
                                        }
                                        applyCellRules(
                                            cell,
                                            v,
                                            settings.cellColorRules,
                                            settings.cellRuleBehaviour,
                                            coreVals,
                                            ofc ? activeRows[r][0] : c, // features = columns => 1st row cell is indicator, otherwise column is indicator
                                            indicatorVals
                                        );
                                    }
                                    rowElement.appendChild(cell);
                                }
                                subElement.appendChild(rowElement);
                            }
                            tableElement.appendChild(subElement);
                        }
                    }
                }
                this.container.appendChild(tableElement);
                /*if ((settings.description !== undefined) && (settings.description !== null))
                {
                    const descTxt = TextWidget.insertValuesIntoText(settings.description, data, numberFormat, settings.locale, settings.noDataText, false);
                    if ((descTxt !== undefined) && (descTxt !== null) && (descTxt !== ''))
                    {
                        const de = this.container.ownerDocument.createElement('div');
                        de.setAttribute('class', 'ia-widget-description table-description ia-generated');
                        de.appendChild(this.container.ownerDocument.createTextNode(descTxt));
                        this.container.appendChild(de);
                    }
                }*/
                this.applyTableActions(settings);
                if (settings.eventsGenerate !== undefined && settings.eventsGenerate !== null)
                    this.bindRowEvents(settings.eventsGenerate);
                if (
                    settings.eventsListen !== undefined &&
                    settings.eventsListen !== null &&
                    settings.eventsListen.toString().toLowerCase() !== 'none'
                ) {
                    const types = settings.eventsListen
                            .toString()
                            .toLowerCase()
                            .replace('hover', 'mousemove,mouseleave,mouseenter,mouseout,hover')
                            .split(/[\s,]/),
                        widgetsContainer = this.container.closest('.ia-report-widget').parentNode;
                    if (widgetsContainer !== undefined && widgetsContainer !== null) {
                        for (let et of types) {
                            widgetsContainer.addEventListener(`rb.${et}`, this.onWidgetEvent);
                        }
                    }
                }
            }
            if (topWidget !== null)
                topWidget.setAttribute('class', topWidget.getAttribute('class').replace(/\s(placeholder)/g, ''));
            this.fireEvent('widgetrender', {
                id: this.design.id,
                type: 'TableWidget'
            }); // Can be important because if set to auto-height can cause layout change
        }
    };

    onWidgetEvent = (e) => {
        if (e.detail !== undefined && e.detail !== null && e.detail.src !== undefined && e.detail.src !== null) {
            const isSelfClick = e.detail.src.id === this.design.id;
            if (
                // !isSelfClick &&
                e.detail.feature !== undefined &&
                e.detail.feature.id !== undefined
            ) {
                const { selectionMode = 'none' } = {
                    ...TableWidget.getDefaults(),
                    ...this.design
                };
                if (
                    e.type === 'rb.mousemove' ||
                    e.type === 'rb.hover' ||
                    e.type === 'rb.mouseout' ||
                    e.type === 'rb.mouseleave' ||
                    selectionMode === 'none' ||
                    selectionMode === 'single'
                ) {
                    removeClass(
                        Array.from(this.container.querySelectorAll('tr.rb-active, td.rb-active, th.rb-active')),
                        'rb-active'
                    );
                }
                const hs = this.container.querySelector(`th[data-feature-id="${e.detail.feature.id}"]`),
                    rs = this.container.querySelector(`tr[data-feature-id="${e.detail.feature.id}"]`);
                if (hs !== undefined && hs !== null) {
                    const hi = Array.from(hs.parentNode.querySelectorAll('th,td')).findIndex(
                        (h) => h.getAttribute('data-feature-id') === e.detail.feature.id
                    );
                    if (hi >= 0) {
                        const rows = this.container.querySelectorAll('table tr');
                        rows.forEach((r) => {
                            const cells = r.querySelectorAll('td,th'),
                                tc = cells.item(hi);
                            if (hasClass(tc, 'rb-active') || e.type === 'rb.mouseout') removeClass(tc, 'rb-active');
                            else addClass(tc, 'rb-active');
                        });
                    }
                }
                if (rs !== undefined && rs !== null) {
                    if (hasClass(rs, 'rb-active') || e.type === 'rb.mouseout') removeClass(rs, 'rb-active');
                    else {
                        addClass(rs, 'rb-active');
                        if (this._design.disableHoverScroll === undefined && !isSelfClick) rs.scrollIntoView(false);
                    }
                }
            }
        }
    };

    static getDefaults = () => {
        return {
            ...AbstractTableWidget.getDefaults(),
            orientation: 'features-are-rows'
        };
        // Examples:
        // indicatorAliases = "I23047=Economic activity rate - 16 plus|I22416=Economic activity rate ♀ - 16 plus|I22225=Economic activity rate ♂ - 16 plus|I1963=Economic activity rate - aged 16-64|I1980=Economic activity rate females - aged 16-64|I1970=Economic activity rate males - aged 16-64"
        // @deprecated from RB 1.X:
        // useDisplayDates: true - is now always true
        // includeDates: "ShowAllPerIndicator" - is not available, defaults back to "ShowAll"
    };
}
