import { JsonFileDataSource, ReportDataProcessor } from 'data-catalog-js-api';
import pako from 'pako'; // These two libraries would be nicer to load dynamically but that doesn't quite work with offline mode, so...
import {
    createStore as createIdbStore,
    get as idbGet,
    set as idbSet,
    del as idbDelete,
    keys as idbKeys
} from 'idb-keyval';

type NamedItem = {
    id: string;
    name: string | undefined;
};

type DownloadResult = {
    url: string;
    timestamp: string;
    label: string;
};

const idbDatabaseId: string = 'ia-local-data',
    idbStoreId: string = 'ia-local-reports';

export const downloadReportToBrowser = async (
    reportItem: any,
    activeFeature: any,
    token: string,
    portalUrl: string,
    specificTimestamp?: string | null | undefined
): Promise<DownloadResult> => {
    //import('idb-keyval').then(({ Store: IdbStore, set: idbSet, get: idbGet }) =>
    //{
    //    import('pako').then(pako =>
    //    {
    const customIdbStore = createIdbStore(idbDatabaseId, idbStoreId),
        { info: itemInfo, report, data: itemData } = reportItem,
        processor = new ReportDataProcessor(report, token, portalUrl);
    if (activeFeature === null) throw new Error('Cannot export with null feature');
    await processor.init();
    const af: NamedItem = Array.isArray(activeFeature) ? activeFeature[0] : activeFeature,
        featureDataTables: any = await processor.getData(af),
        isTimeStamped = specificTimestamp !== undefined && specificTimestamp !== null && specificTimestamp !== '',
        reportKey = isTimeStamped
            ? `rb4arcgisDesign$R${itemInfo.id}$T${specificTimestamp}`
            : `rb4arcgisDesign$R${itemInfo.id}`,
        featuresKey = isTimeStamped ? `iaFeatures$R${itemInfo.id}$T${specificTimestamp}` : `iaFeatures$R${itemInfo.id}`,
        dataKey = isTimeStamped
            ? `iaData$R${itemInfo.id}$F${af.id}$T${specificTimestamp}`
            : `iaData$R${itemInfo.id}$F${af.id}`,
        storageObject: any = {
            info: {
                id: itemInfo.id,
                title: itemInfo.title,
                description: itemInfo.description,
                tags: [...itemInfo.tags]
            },
            data: {
                values: {
                    ...itemData.values
                }
            },
            report: {
                ...report
            }
        };
    if (isTimeStamped) storageObject.downloaded = specificTimestamp;
    // Minimal design summary - to be re-used
    let jsonString = JSON.stringify(storageObject);
    await idbSet(reportKey, pako.gzip(jsonString, { to: 'string' }), customIdbStore);
    // Data summary for the active feature...
    jsonString = JSON.stringify({
        feature: activeFeature,
        tables: featureDataTables
    });
    await idbSet(dataKey, pako.gzip(jsonString, { to: 'string' }), customIdbStore);
    const flistBlob: any = await idbGet(featuresKey, customIdbStore);
    let listPromise = Promise.resolve();
    const localList =
        flistBlob !== undefined
            ? JSON.parse(pako.inflate(flistBlob, { to: 'string' }))
            : {
                  id: itemInfo.id,
                  title: itemInfo.title,
                  description: itemInfo.description,
                  tags: [...itemInfo.tags], // Basic summary so that any list generator can make something useful...
                  features: []
              };
    if (localList.features.find((f) => f.id === af.id) === undefined) {
        localList.features.push({
            id: af.id,
            name: af.name
        });
        listPromise = idbSet(featuresKey, pako.gzip(JSON.stringify(localList), { to: 'string' }), customIdbStore);
    }
    await listPromise;
    const now: string = new Date()
        .toISOString()
        .substring(0, 'yyyy-MM-ddTHH:mm:ss'.length)
        .replace(/[^0-9a-zA-Z]/g, '');
    return {
        url: isTimeStamped
            ? `/view-local/${itemInfo.id}/${af.id}?t=${specificTimestamp}`
            : `/view-local/${itemInfo.id}/${af.id}`,
        timestamp: isTimeStamped ? specificTimestamp : now,
        label: itemInfo.title
    };
};

type DesignStorageResult = {
    key: string;
    timestamp: string;
    stored?: number;
};

export const downloadReportDesignToBrowser = async (
    reportId: string,
    report: any,
    specificTimestamp?: string | null | undefined
): Promise<DesignStorageResult> => {
    const customIdbStore = createIdbStore(idbDatabaseId, idbStoreId);
    const isTimeStamped = specificTimestamp !== undefined && specificTimestamp !== null && specificTimestamp !== '',
        reportKey = isTimeStamped
            ? `rb4arcgisDesignOnly$R${reportId}$T${specificTimestamp}`
            : `rb4arcgisDesignOnly$R${reportId}`,
        storageObject: any = {
            report: {
                ...report
            }
        },
        now = new Date();
    if (isTimeStamped) storageObject.downloaded = specificTimestamp;
    storageObject.stored = now.getTime();
    // Minimal design summary - to be re-used
    let jsonString = JSON.stringify(storageObject);
    await idbSet(reportKey, pako.gzip(jsonString, { to: 'string' }), customIdbStore);
    const nowText: string = now
        .toISOString()
        .substring(0, 'yyyy-MM-ddTHH:mm:ss'.length)
        .replace(/[^0-9a-zA-Z]/g, '');
    return {
        key: reportKey,
        timestamp: isTimeStamped ? specificTimestamp : nowText,
        stored: now.getTime()
    };
};

export const deleteReportFromBrowser = async (
    reportItem: any,
    activeFeature: any,
    specificTimestamp?: string | null | undefined
): Promise<boolean> => {
    const customIdbStore = createIdbStore(idbDatabaseId, idbStoreId),
        { info: itemInfo } = reportItem,
        reportKey =
            specificTimestamp !== undefined && specificTimestamp !== null && specificTimestamp !== ''
                ? `rb4arcgisDesign$R${itemInfo.id}$T${specificTimestamp}`
                : `rb4arcgisDesign$R${itemInfo.id}`,
        dataStemKey = `iaData$R${itemInfo.id}$F`,
        featuresKey =
            specificTimestamp !== undefined && specificTimestamp !== null && specificTimestamp !== ''
                ? `iaFeatures$R${itemInfo.id}$T${specificTimestamp}`
                : `iaFeatures$R${itemInfo.id}`,
        af: NamedItem | null = Array.isArray(activeFeature) ? activeFeature[0] : activeFeature;
    if (af === null) throw new Error('Cannot find local data without feature ID');
    const dataKey =
        specificTimestamp !== undefined && specificTimestamp !== null && specificTimestamp !== ''
            ? `iaData$R${itemInfo.id}$F${af.id}$T${specificTimestamp}`
            : `iaData$R${itemInfo.id}$F${af.id}`;
    await idbDelete(dataKey, customIdbStore);
    const localKeySet: any[] = await idbKeys(customIdbStore),
        otherFeatures = localKeySet.filter((k) => k.toString().indexOf(dataStemKey) === 0);
    if (otherFeatures.length < 1) {
        await idbDelete(reportKey, customIdbStore);
        await idbDelete(featuresKey, customIdbStore);
        return true;
    } else {
        const flistBlob: any = await idbGet(featuresKey, customIdbStore);
        let listPromise = Promise.resolve(),
            listFeatureIndex = -1;
        const localList =
            flistBlob !== undefined
                ? JSON.parse(pako.inflate(flistBlob, { to: 'string' }))
                : {
                      id: itemInfo.id,
                      title: itemInfo.title,
                      description: itemInfo.description,
                      tags: [...itemInfo.tags], // Basic summary so that any list generator can make something useful...
                      features: []
                  };
        if ((listFeatureIndex = localList.features.findIndex((f) => f.id === af.id)) >= 0) {
            localList.features.splice(listFeatureIndex, 1);
            if (localList.features.length < 1) listPromise = idbDelete(featuresKey, customIdbStore);
            else
                listPromise = idbSet(
                    featuresKey,
                    pako.gzip(JSON.stringify(localList), { to: 'string' }),
                    customIdbStore
                );
        }
        await listPromise;
        return true;
    }
};

export const deleteReportDesignFromBrowser = async (
    reportId: string,
    specificTimestamp?: string | null | undefined
): Promise<boolean> => {
    const customIdbStore = createIdbStore(idbDatabaseId, idbStoreId),
        reportKey =
            specificTimestamp !== undefined && specificTimestamp !== null && specificTimestamp !== ''
                ? `rb4arcgisDesignOnly$R${reportId}$T${specificTimestamp}`
                : `rb4arcgisDesignOnly$R${reportId}`;
    await idbDelete(reportKey, customIdbStore);
    return true;
};

export const fetchReportFromBrowser = async (
    reportId: string,
    specificTimestamp?: string | null | undefined
): Promise<any> => {
    const customIdbStore = createIdbStore(idbDatabaseId, idbStoreId),
        designKey =
            specificTimestamp !== undefined && specificTimestamp !== null && specificTimestamp !== ''
                ? `rb4arcgisDesign$R${reportId}$T${specificTimestamp}`
                : `rb4arcgisDesign$R${reportId}`,
        reportJson = await idbGet(designKey, customIdbStore);
    return JSON.parse(pako.inflate(reportJson, { to: 'string' }));
};

export const fetchReportDesignFromBrowser = async (
    reportId: string,
    specificTimestamp?: string | null | undefined
): Promise<any> => {
    const customIdbStore = createIdbStore(idbDatabaseId, idbStoreId),
        designKey =
            specificTimestamp !== undefined && specificTimestamp !== null && specificTimestamp !== ''
                ? `rb4arcgisDesignOnly$R${reportId}$T${specificTimestamp}`
                : `rb4arcgisDesignOnly$R${reportId}`,
        reportJson = await idbGet(designKey, customIdbStore);
    return JSON.parse(pako.inflate(reportJson, { to: 'string' }));
};

export const isReportDesignAvailableInBrowser = async (
    reportId: string,
    specificTimestamp?: string | null | undefined
): Promise<any> => {
    const customIdbStore = createIdbStore(idbDatabaseId, idbStoreId),
        designKey =
            specificTimestamp !== undefined && specificTimestamp !== null && specificTimestamp !== ''
                ? `rb4arcgisDesignOnly$R${reportId}$T${specificTimestamp}`
                : `rb4arcgisDesignOnly$R${reportId}`,
        reportKeys = await idbKeys(customIdbStore);
    return reportKeys.indexOf(designKey) >= 0;
};

export const fetchReportDataFromBrowser = async (
    reportId: string,
    featureIds: string,
    token?: string | null | undefined,
    specificTimestamp?: string | null | undefined
): Promise<JsonFileDataSource> => {
    const customIdbStore = createIdbStore(idbDatabaseId, idbStoreId),
        coreFeatureUrl =
            specificTimestamp !== undefined && specificTimestamp !== null && specificTimestamp !== ''
                ? `iaData$R${reportId}$F${featureIds}$T${specificTimestamp}`
                : `iaData$R${reportId}$F${featureIds}`,
        dataBlob = await idbGet(coreFeatureUrl, customIdbStore);
    return new JsonFileDataSource(pako.inflate(dataBlob, { to: 'string' }), token, 0);
};
