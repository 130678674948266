import AbstractWidget, { lengthWithUnit, parseRules, applyRuleSet } from './AbstractWidget';
import { getMessage } from '../utils/localization';

export default class AbstractTableWidget extends AbstractWidget {
    addMetadataLink = (cell, indicator, settings) => {
        if (
            settings.metadataLink !== undefined &&
            settings.metadataLink !== null &&
            settings.metadataLink.toLowerCase() !== 'none' &&
            settings.metadataLinkUrl !== undefined &&
            settings.metadataLinkUrl !== null &&
            indicator !== undefined &&
            indicator !== null
        ) {
            const hyperlink = settings.metadataLinkUrl
                    .replace(/#CODE/g, indicator.id)
                    .replace(/#NAME/g, indicator.name)
                    .replace(/#ID/g, indicator.id)
                    .replace(/#DATE/g, indicator.date),
                a = cell.ownerDocument.createElement('a'),
                txt = (settings.metadataLinkText || '')
                    .replace(/#CODE/g, indicator.id)
                    .replace(/#NAME/g, indicator.name)
                    .replace(/#ID/g, indicator.id)
                    .replace(/#DATE/g, indicator.date);
            a.setAttribute('href', hyperlink);
            if (settings.metadataLinkToolTip !== undefined && settings.metadataLinkToolTip !== null) {
                const ttTxt = settings.metadataLinkToolTip
                    .replace(/#CODE/g, indicator.id)
                    .replace(/#NAME/g, indicator.name)
                    .replace(/#ID/g, indicator.id)
                    .replace(/#DATE/g, indicator.date);
                a.setAttribute('data-tooltip', ttTxt);
                a.setAttribute('aria-label', txt);
                a.setAttribute('class', 'pure-tip pure-tip-bottom');
            }
            let img = null;
            if (settings.metadataLink.toLowerCase().substring(0, 5) === 'image') {
                if (settings.metadataImageUrl.indexOf('<') === 0) {
                    img = cell.ownerDocument.createElement('span');
                    img.innerHTML = settings.metadataImageUrl;
                } else {
                    img = cell.ownerDocument.createElement('img');
                    img.setAttribute(
                        'src',
                        settings.metadataImageUrl
                            .replace(/#CODE/g, indicator.id)
                            .replace(/#NAME/g, indicator.name)
                            .replace(/#ID/g, indicator.id)
                            .replace(/#DATE/g, indicator.date)
                    );
                    img.setAttribute(
                        'alt',
                        settings.metadataLink.toLowerCase().indexOf('text') >= 0 ? '' : `${txt} - link image`
                    );
                }
                img.setAttribute(
                    'style',
                    `width: ${lengthWithUnit(settings.metadataImageWidth)}; height: ${lengthWithUnit(
                        settings.metadataImageHeight
                    )};`
                );
            }
            if (img !== null && settings.metadataImageLinkPosition.toLowerCase() === 'before') a.appendChild(img);
            if (
                settings.metadataLink.toLowerCase().indexOf('text') >= 0 &&
                settings.metadataLinkText !== undefined &&
                settings.metadataLinkText !== null
            ) {
                const span = cell.ownerDocument.createElement('span');
                if (txt.indexOf('</') > 0) span.innerHTML = txt;
                else span.appendChild(cell.ownerDocument.createTextNode(txt));
                a.appendChild(span);
            }
            if (img !== null && settings.metadataImageLinkPosition.toLowerCase() === 'after') a.appendChild(img);
            cell.appendChild(a);
        }
    };

    applyTableActions = (settings = {}) => {
        if (this.container !== null) {
            const links = this.container.querySelectorAll('.ia-table-export-link');
            for (let el of links) {
                el.remove();
            }
            const doc = this.container.ownerDocument,
                table = this.container.querySelector('table');
            if (settings.exportable && table !== undefined && table !== null) {
                const dataBlob = exportTableTo(table, settings.exportFormat, settings.locale),
                    dataBlobUrl = URL.createObjectURL(dataBlob),
                    da = doc.createElement('a'),
                    di = doc.createElement('i'),
                    ds = doc.createElement('span'),
                    labelText = getMessage(
                        'widget.table.button.export',
                        settings.locale || 'en',
                        `Export this table to {format}`
                    ).replace('{format}', settings.exportFormat || 'CSV');
                da.setAttribute('href', `${dataBlobUrl}`);
                da.setAttribute('class', 'ia-table-export-link pure-tip pure-tip-top-left pure-tip-table-download');
                //da.setAttribute('style', 'position: absolute; z-index: 101; bottom: 5px; right: calc(15px + 1.4em);');
                da.setAttribute('download', 'table-data.csv');
                da.setAttribute('data-tooltip', labelText);
                di.setAttribute('class', 'fas fa-fw fa-cloud-download-alt');
                di.setAttribute('aria-hidden', 'true');
                da.appendChild(di);
                ds.setAttribute('class', 'sr-only');
                ds.appendChild(doc.createTextNode(labelText));
                da.appendChild(ds);
                this.container.appendChild(da);
            }
        }
    };

    bindRowEvents = (eventTypes = '') => {
        if (eventTypes !== '' && this.container !== undefined && this.container !== null) {
            const eventNames = eventTypes.toLowerCase().split(/[\s,]/);
            for (let en of eventNames) {
                this.container.addEventListener(en, this.proxyRowEvent);
            }
        }
    };

    proxyRowEvent = (e) => {
        if (e.target !== undefined && e.target !== null) {
            const td = e.target.closest('td');
            if (td !== undefined && td !== null) {
                const tdh = findHeadersForCell(td);
                this.fireEvent(e.type, {
                    target: e.target,
                    src: {
                        type: 'Table',
                        id: this.design.id
                    },
                    feature: tdh.feature,
                    indicator: tdh.indicator,
                    value: td.innerText
                });
            }
        }
    };

    static getDefaults = () => {
        return {
            ...AbstractWidget.getDefaults(),
            alternateRowCssClass: 'odd',
            autoAdjustColumnWidths: true,
            cellBorderStyle: 'NotSet',
            cellBorderWidth: '0',
            cellColorRules: '',
            cellComparisonDataFormat: '#VAL',
            cellDataFormat: '#VAL',
            cellRuleBehaviour: 'Background',
            collapseBorders: true,
            columnDirectionality: 'LeftToRight',
            columnHeaderHeight: '',
            dateTimeFormat: 'D', // C# options = d|D|t|T|f|g|G or more human options = full|date|time|iso
            exportable: true,
            //featureOrdering: "ComparisonsLast",
            highlightColor: '#ffd700',
            highlightSelectedFeature: false,
            includeNumeratorsAndDenominators: false,
            indicatorLabelText: '#NAME',
            metadataImageHeight: '16px',
            metadataImageLinkPosition: 'After',
            metadataImageUrl: '<i class="fa fa-info-circle"></i>',
            metadataImageWidth: '16px',
            metadataLink: 'None',
            metadataLinkPopup: false,
            metadataLinkText: 'Metadata',
            metadataLinkToolTip: 'Show metadata for #NAME',
            metadataLinkUrl: '?#metadata:#CODE',
            metadataLinksAreAbsolute: false,
            rightAlignNumbers: true,
            rowCssClass: 'even',
            rowHeaderWidth: '25%',
            tableAutoWidth: true,
            tableBorderStyle: 'NotSet',
            tableBorderWidth: '0',
            tableCssClass: 'standard',
            tableSummary: '',
            tableTitle: '',
            useRowHeadersInBody: true
        };
    };
}

const rebuildColorRules = (cellColorRules = [], currentIndicatorValuesLookup = []) => {
    for (let i = 0; i < cellColorRules.length; i++) {
        const v = cellColorRules[i].value;
        if (v.indexOf('#') === 0) {
            let sub = /^#(IVALUE|VALUE|CVALUE){(.*)}$/.exec(v);
            if (sub !== null && sub.length > 2) {
                const fid = sub[2].replace('C:', ''),
                    fv = currentIndicatorValuesLookup.find((f) => f.id === fid);
                if (fv !== undefined) cellColorRules[i].value = fv.value;
            } else if (v === '#IVALUE' || v === '#FVALUE') {
                const fid = '#ACTIVEFEATURE',
                    fv = currentIndicatorValuesLookup.find((f) => f.id === fid);
                if (fv !== undefined) cellColorRules[i].value = fv.value;
            }
        }
    }
    return cellColorRules;
};

const getCellStyle = (
    value,
    cellColorRules,
    cellRuleBehaviour = 'background',
    currentIndicator,
    currentIndicatorValuesLookup
) => {
    // "Fatal|eq|#ff3333;bold;normal;20px"
    if (cellColorRules !== undefined && cellColorRules !== null && cellColorRules !== '') {
        const ruleset = rebuildColorRules(parseRules(cellColorRules), currentIndicatorValuesLookup),
            match = applyRuleSet(
                ruleset,
                value,
                currentIndicator !== undefined && currentIndicator !== null && currentIndicator.id !== undefined
                    ? currentIndicator.id
                    : currentIndicator !== undefined && currentIndicator !== null && currentIndicator.iid !== undefined
                    ? currentIndicator.iid
                    : undefined
            );
        if (match !== undefined && match !== null) {
            const [color, fontWeight, fontStyle, fontSize] = match.split(';');
            let style = '';
            if (color !== undefined && color.substring(0, 6) === 'class:') return color;
            else if (color !== undefined && cellRuleBehaviour.toLowerCase() === 'background')
                style += `background-color: ${color};`;
            else if (color !== undefined) style += `color: ${color};`;
            if (fontWeight !== undefined) style += `font-weight: ${fontWeight};`;
            if (fontStyle !== undefined) style += `font-style: ${fontStyle};`;
            if (fontSize !== undefined) style += `font-size: ${fontSize};`;
            return style;
        }
    }
    return undefined;
};

export const applyCellRules = (
    cell,
    value,
    cellColorRules,
    cellRuleBehaviour = 'background',
    coreFeatureValues = [],
    currentIndicator,
    currentIndicatorValuesLookup
) => {
    const styleOrClass =
        coreFeatureValues.length > 0
            ? getCellStyle(
                  value,
                  cellColorRules.replace(/#IVALUE{1}|#IVALUE|#FVALUE{1}|#FVALUE/gi, coreFeatureValues.join(',')),
                  cellRuleBehaviour,
                  currentIndicator,
                  currentIndicatorValuesLookup
              )
            : getCellStyle(value, cellColorRules, cellRuleBehaviour, currentIndicator, currentIndicatorValuesLookup);
    if (styleOrClass !== undefined) {
        if (styleOrClass.substring(0, 6) === 'class:')
            cell.setAttribute('class', `${cell.getAttribute('class') || ''} ${styleOrClass.substring(6)}`.trim());
        else cell.setAttribute('style', styleOrClass);
    }
};

export const exportTableTo = (htmlTableElement, format = 'CSV', locale = 'en') => {
    const lang = locale.toLowerCase().split('-')[0],
        excelLikelySemi = lang === 'de' || lang === 'es' || lang === 'fr' || lang === 'it',
        valueDelimiter = excelLikelySemi ? ';' : ',',
        headerRows = htmlTableElement.querySelectorAll('thead > tr'),
        dataRows = htmlTableElement.querySelectorAll('tbody > tr'),
        justNums = /^[0-9.,\s]+$/,
        commaStrip = /[,\s]/g,
        whiteStrip = /[\s]/g,
        BOM = '\uFEFF';
    let dataBlob;
    if (format === 'CSV') {
        const csvRows = [];
        for (let i = 0; i < headerRows.length; i++) {
            csvRows.push([]);
        }
        let cs,
            rs,
            v,
            childCells,
            flatRow,
            i = 0,
            j = 0;
        for (let row of headerRows) {
            childCells = row.querySelectorAll('th, td');
            j = csvRows[i].filter((cc) => cc !== undefined && cc !== null).length;
            for (let cell of childCells) {
                cs = cell.hasAttribute('colspan') ? parseInt(cell.getAttribute('colspan')) : 1;
                rs = cell.hasAttribute('rowspan') ? parseInt(cell.getAttribute('rowspan')) : 1;
                v = cell.querySelector('.iname') ? cell.querySelector('.iname').innerText : cell.innerText;
                for (let r = 0; r < rs; r++) {
                    for (let s = 0; s < cs; s++) csvRows[i + r][j + s] = v;
                }
                j += cs;
            }
            i++;
        }
        for (let i = 0; i < csvRows.length; i++) csvRows[i] = `"${csvRows[i].join(`"${valueDelimiter}"`)}"`;
        for (let row of dataRows) {
            childCells = row.querySelectorAll('th, td');
            flatRow = [];
            for (let cell of childCells) {
                cs = cell.hasAttribute('colspan') ? parseInt(cell.getAttribute('colspan')) : 1;
                v = cell.innerText;
                if (justNums.test(v)) v = excelLikelySemi ? v.replace(whiteStrip, '') : v.replace(commaStrip, '');
                else v = `"${v}"`;
                for (let i = 0; i < cs; i++) flatRow.push(v);
            }
            csvRows.push(`${flatRow.join(valueDelimiter)}`);
        }
        dataBlob = new Blob([`${BOM}${csvRows.join('\n')}`], { type: 'text/csv;charset=utf-8' });
    }
    return dataBlob;
};

const findHeadersForCell = (td) => {
    const headers = {
            feature: null,
            indicator: null
        },
        row = td.closest('tr'),
        body = td.closest('tbody'),
        table = td.closest('table');
    if (row !== null && body !== null) {
        let head = table.querySelector('thead'),
            headerRows = head.querySelectorAll('tr'),
            rowId = row.hasAttribute('data-row-id')
                ? row.getAttribute('data-row-id')
                : row.hasAttribute('data-ind-id')
                ? row.getAttribute('data-ind-id')
                : row.getAttribute('data-feature-id'),
            rowHeader = row.getAttribute('data-row-label') || row.querySelector('th, td').innerText,
            colOffset = 0,
            preceding = td.previousSibling,
            thOffset,
            hardOffset = 0,
            colspan;
        while (preceding !== null) {
            colOffset += preceding.hasAttribute('colspan') ? parseInt(preceding.getAttribute('colspan')) : 1;
            preceding = preceding.previousSibling;
        }
        for (let h of headerRows) {
            thOffset = hardOffset;
            for (let th of h.querySelectorAll('td, th')) {
                if (thOffset === colOffset) {
                    if (th.hasAttribute('data-feature-id')) {
                        headers.feature = {
                            id: th.getAttribute('data-feature-id'),
                            name: th.innerText
                        };
                    } else if (th.hasAttribute('data-ind-id') || th.hasAttribute('data-ind-uuid')) {
                        headers.indicator = {
                            id: th.getAttribute('data-ind-id') || th.getAttribute('data-ind-uuid'),
                            name: th.hasAttribute('data-ind-label') ? th.getAttribute('data-ind-label') : th.innerText
                        };
                    }
                }
                colspan = th.hasAttribute('colspan') ? parseInt(th.getAttribute('colspan')) : 1;
                if (thOffset === 0 && th.hasAttribute('rowspan')) hardOffset = colspan;
                thOffset += colspan;
            }
        }
        if (headers.feature === null) {
            headers.feature = {
                id: rowId,
                name: rowHeader
            };
        } else if (headers.indicator === null) {
            headers.indicator = {
                id: rowId,
                name: rowHeader
            };
        }
    }
    return headers;
};

export const findNumeratorValue = (featuresAreColumns = true, dataTable, col = 0, row = 0) => {
    return findNumeratorOrDemoninatorValue('numerator', featuresAreColumns, dataTable, col, row);
};

export const findDenominatorValue = (featuresAreColumns = true, dataTable, col = 0, row = 0) => {
    return findNumeratorOrDemoninatorValue('denominator', featuresAreColumns, dataTable, col, row);
};

const findNumeratorOrDemoninatorValue = (key = 'numerator', featuresAreColumns = true, dataTable, col = 0, row = 0) => {
    let ndv = null;
    if (featuresAreColumns) {
        const iid = dataTable.rowIds[row].iid, // Indicator ID
            ndId = `${iid}___${key.substring(0, 3).toUpperCase()}`,
            idx = dataTable.rowIds.findIndex((r) => r.iid === ndId && r.type === key);
        if (idx >= 0) ndv = dataTable.rows[idx][col];
    } else {
        const iid = dataTable.colIds[col].iid, // Indicator ID
            ndId = `${iid}___${key.substring(0, 3).toUpperCase()}`,
            idx = dataTable.colIds.findIndex((r) => r.iid === ndId && r.type === key);
        if (idx >= 0) ndv = dataTable.rows[row][idx];
    }
    return ndv;
};
