import AbstractWidget, { WIDGET_CONTAINER_CSS_CLASS, lengthWithUnit } from './AbstractWidget';
import { TextWidget } from './TextWidget';
import { DataManipulator } from 'data-catalog-js-api';
import { addClass, removeClass, hasClass } from '../utils/dom';
import { isNullOrWhitespace } from '../utils/object';

export default class NavigatorWidget extends AbstractWidget {
    // Render here is slightly special, because it can _detect_ if the calling (React) class has laid out some of the HTML before calling this method.
    render = (data, options = {}) => {
        if (
            this.container !== undefined &&
            this.container !== null &&
            this.design !== undefined &&
            this.design !== null
        ) {
            const doc = this.container.ownerDocument,
                topWidget = this.container.closest('.ia-report-widget'),
                settings = {
                    ...NavigatorWidget.getDefaults(),
                    ...this.design
                },
                dataset = DataManipulator.mergeTables(data, !settings.includeAllAreas), // Discard non-common features  unless they are comparisons
                featureSet = [...dataset.features],
                { activeFeature } = options,
                activeIds =
                    activeFeature !== undefined
                        ? Array.isArray(activeFeature)
                            ? activeFeature.map((f) => f.id)
                            : [activeFeature.id]
                        : [],
                activeNames =
                    activeFeature !== undefined
                        ? Array.isArray(activeFeature)
                            ? activeFeature.map((f) => f.name)
                            : [activeFeature.name]
                        : [];
            featureSet.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
            let nav = doc.createElement('nav'),
                style = '',
                div,
                label;
            nav.setAttribute('id', `navigator${settings.id}`);
            nav.setAttribute(
                'class',
                `ia-generated ia-text-box ${settings.cssClass} ${
                    settings.navigationStyle !== 'List' && settings.navigationStyle !== 'ListRunIn'
                        ? 'ia-nav-drop-down'
                        : 'ia-nav-list'
                }`.trim()
            );
            if (!isNullOrWhitespace(settings.width)) style += `width: ${settings.width};`;
            if (!isNullOrWhitespace(settings.height)) style += `height: ${settings.height};`;
            if (!isNullOrWhitespace(settings.borderWidth))
                style += `border-width: ${lengthWithUnit(settings.borderWidth)};`;
            if (!isNullOrWhitespace(settings.borderRadius))
                style += `border-radius: ${lengthWithUnit(settings.borderRadius)};`;
            if (!isNullOrWhitespace(settings.borderStyle))
                style += `border-style: ${settings.borderStyle.toLowerCase()};`;
            if (!isNullOrWhitespace(settings.borderColor)) style += `border-color: ${settings.borderColor};`;
            if (!isNullOrWhitespace(settings.float)) style += `float: ${settings.float.toLowerCase()};`;
            if (!isNullOrWhitespace(settings.controlBackground)) style += `background: ${settings.controlBackground};`;
            // Hard-wire some of it - borders and clipping...
            style += `box-sizing: border-box; overflow: ${settings.navigationStyle === 'Menu' ? 'visible' : 'hidden'};`;
            nav.setAttribute('style', style);
            if (!isNullOrWhitespace(settings.titleText)) {
                div = doc.createElement('div');
                div.setAttribute('class', `ia-text-box-title ${settings.boxTitleCssClass}`.trim());
                style = '';
                if (!isNullOrWhitespace(settings.titleColor)) style += `color: ${settings.TitleColor};`;
                if (!isNullOrWhitespace(settings.titleBackground)) style += `background: ${settings.titleBackground};`;
                if (settings.titleIsBold) style += 'font-weight: bold;';
                if (settings.titleAlignment !== 'auto' && settings.titleAlignment !== '')
                    style += `text-align: ${settings.titleAlignment.toLowerCase()};`;
                if (style.length > 0) div.setAttribute('style', style);
                label = doc.createElement('div');
                label.setAttribute('id', `navigator${settings.id}_titleContent`);
                style = '';
                if (!isNullOrWhitespace(settings.titlePadding))
                    style += `padding: ${lengthWithUnit(settings.titlePadding, 'px', true, 4)};`;
                if (style.length > 0) label.setAttribute('style', style);
                let htmlTitleFragment = TextWidget.insertValuesIntoText(
                    settings.titleText,
                    dataset,
                    settings.numberFormat,
                    settings.locale,
                    settings.noDataText
                );
                label.appendChild(doc.createTextNode(htmlTitleFragment != null ? htmlTitleFragment : ''));
                div.appendChild(label);
                nav.appendChild(div);
            }
            div = doc.createElement('div');
            let navContentId = `navigator${settings.id}_navContent`,
                oflow =
                    settings.navigationStyle === 'Menu'
                        ? 'visible'
                        : settings.navigationStyle === 'List' || settings.navigationStyle === 'ListRunIn'
                        ? 'auto'
                        : 'hidden',
                maxh =
                    (settings.navigationStyle === 'List' || settings.navigationStyle === 'ListRunIn') &&
                    !isNullOrWhitespace(settings.titleText)
                        ? '95%'
                        : '100%';
            div.setAttribute('id', navContentId);
            div.setAttribute('class', `nav-widget-content ${WIDGET_CONTAINER_CSS_CLASS}`);
            style = `overflow: ${oflow}; z-index: 10; max-height: ${maxh};`;
            if (!isNullOrWhitespace(settings.controlAlignment) && settings.controlAlignment !== 'auto')
                style += `text-align: ${settings.controlAlignment.toLowerCase()};`;
            if (!isNullOrWhitespace(settings.controlPadding))
                style += `padding: ${lengthWithUnit(settings.controlPadding, 'px', true, 4)};`;
            if (
                !isNullOrWhitespace(settings.itemLabelFontSize) &&
                settings.itemLabelFontSize !== '' &&
                settings.itemLabelFontSize.indexOf('-1') !== 0
            )
                style += `font-size: ${lengthWithUnit(settings.itemLabelFontSize)};`;
            if (style.length > 0) div.setAttribute('style', style);
            // Control(s)...
            if (settings.navigationStyle.toLowerCase() === 'dropdown') {
                let p = doc.createElement('p'),
                    s = doc.createElement('select'),
                    opt;
                for (let f of featureSet) {
                    if (!f.comparison) {
                        opt = doc.createElement('option');
                        opt.setAttribute(
                            'value',
                            settings.itemLinkUrlFormat
                                .replace(/(#FID|#ID)/gi, f.id)
                                .replace(/(#FNAME|#NAME)/gi, f.name)
                                .replace(/(#\{0\})/gi, `#path:${f.id}`)
                        );
                        if (settings.itemLabelFormat.indexOf('</') > 0)
                            opt.innerHTML = TextWidget.insertValuesIntoText(
                                settings.itemLabelFormat
                                    .replace(/(#FID|#ID)/gi, f.id)
                                    .replace(/(#FNAME|#NAME)/gi, f.name)
                                    .replace('<>', '')
                                    .replace('</>', ''),
                                dataset,
                                settings.numberFormat,
                                settings.locale,
                                settings.noDataText
                            ); // Allow empty enclosers, React-style
                        else
                            opt.appendChild(
                                doc.createTextNode(
                                    settings.itemLabelFormat
                                        .replace(/(#FID|#ID)/gi, f.id)
                                        .replace(/(#FNAME|#NAME)/gi, f.name)
                                )
                            );
                        if (activeIds.indexOf(f.id) >= 0) opt.setAttribute('selected', 'selected');
                        s.appendChild(opt);
                    }
                }
                s.setAttribute('id', `${navContentId}_selector`);
                //htmlFragment.AppendFormat(this.TopOptionText, "", "&nbsp;");
                p.appendChild(s);
                div.appendChild(p);
                nav.appendChild(div);
                s.addEventListener('change', (e) => {
                    const sl = e.target,
                        sv = sl.value !== undefined ? sl.value : '',
                        stem = window.location.href;
                    if (sv.indexOf('.') === 0 || sv.indexOf('/') < 0)
                        window.location.href = `${stem.substring(0, stem.lastIndexOf('/') + 1)}${sv}`;
                    else window.location.href = `${stem.substring(0, stem.indexOf('/', 10))}${sv}`;
                });
            } else {
                let g = doc.createElement('div'),
                    b = doc.createElement('button'),
                    ul = doc.createElement('ul'),
                    li,
                    link;
                if (settings.navigationStyle.toLowerCase() === 'menu') {
                    g.setAttribute('class', 'btn-group');
                    b.setAttribute('class', `btn ${settings.buttonCssClass} dropdown-toggle`);
                    // Override some styles because of special circumstances...
                    g.setAttribute('style', 'float: none; display: block; width: 100%;');
                    b.setAttribute('style', 'float: none; display: block; width: 100%;');
                    b.setAttribute('data-toggle', 'dropdown');
                    b.setAttribute('data-target', `#${navContentId}_selector_list`);
                    b.appendChild(
                        doc.createTextNode(
                            TextWidget.insertValuesIntoText(
                                settings.buttonTextFormat.replace('#FNAME', activeNames.join(', ')),
                                dataset,
                                settings.numberFormat,
                                settings.locale,
                                settings.noDataText
                            )
                        )
                    );
                    if (settings.buttonTextFormat.indexOf('</') > 0)
                        b.innerHTML = settings.buttonTextFormat.replace('<>', '').replace('</>', ''); // Allow empty enclosers, React-style
                    li = doc.createElement('i');
                    li.setAttribute('class', 'fas fa-fw fa-caret-down');
                    b.appendChild(li);
                    g.appendChild(b);
                    b.addEventListener('click', (e) => {
                        let bb = e.target,
                            bc = bb.closest('.btn-group'),
                            nw = bb.closest('.ia-navigator-widget');
                        if (bc !== null) {
                            if (hasClass(bc, 'open')) {
                                removeClass(bc, 'open');
                                nw.style.overflow = '';
                            } else {
                                addClass(bc, 'open');
                                nw.style.overflow = 'visible';
                            }
                            if (bb.tagName !== 'A') e.preventDefault();
                        }
                    });
                    ul.setAttribute('id', `${navContentId}_selector_list`);
                    ul.setAttribute('class', 'dropdown-menu');
                    ul.setAttribute('style', 'z-index: 1000; max-height: 25em; overflow-y: scroll; width: 100%;');
                } else {
                    ul.setAttribute('id', `${navContentId}_selector_list`);
                    ul.setAttribute(
                        'style',
                        `list-style: ${
                            !isNullOrWhitespace(settings.itemListStyle) ? settings.itemListStyle.toLowerCase() : 'none'
                        };`
                    );
                }
                for (let f of featureSet) {
                    //"<li role=\"presentation\"" + (this.NavigationStyle == NavigationControlStyle.ListRunIn ? " style=\"display: inline-block; margin-right: 0.5em;\"" : string.Empty) + ">
                    // <a href=\"" + ItemLinkUrlFormat.Replace("#FID", "{0}").Replace("#FNAME", "{1}") + "\" data-feature=\"{0}\" class=\"feature-link\">" + ItemLabelFormat.Replace("#FID", "{0}").Replace("#FNAME", "{1}") + "</a></li>");
                    if (!f.comparison) {
                        li = doc.createElement('li');
                        li.setAttribute('role', 'presentation');
                        if (settings.navigationStyle === 'ListRunIn')
                            li.setAttribute('style', 'display: inline-block; margin-right: 0.5em;');
                        link = doc.createElement('a');
                        const href = settings.itemLinkUrlFormat
                            .replace(/(#FID|#ID)/gi, f.id)
                            .replace(/(#FNAME|#NAME)/gi, f.name)
                            .replace(/(#\{0\})/gi, `#path:${f.id}`);
                        link.setAttribute(
                            'href',
                            `${href}${(window.location.search || '').replace('?', href.indexOf('?') > 0 ? '&' : '?')}${
                                window.location.hash || ''
                            }`
                        );
                        link.setAttribute('class', 'feature-link');
                        link.setAttribute('data-feature', f.id);
                        if (settings.itemLabelFormat.indexOf('</') > 0)
                            link.innerHTML = TextWidget.insertValuesIntoText(
                                settings.itemLabelFormat
                                    .replace(/(#FID|#ID)/gi, f.id)
                                    .replace(/(#FNAME|#NAME)/gi, f.name)
                                    .replace('<>', '')
                                    .replace('</>', ''),
                                dataset,
                                settings.numberFormat,
                                settings.locale,
                                settings.noDataText
                            ); // Allow empty enclosers, React-style
                        else
                            link.appendChild(
                                doc.createTextNode(
                                    TextWidget.insertValuesIntoText(
                                        settings.itemLabelFormat
                                            .replace(/(#FID|#ID)/gi, f.id)
                                            .replace(/(#FNAME|#NAME)/gi, f.name),
                                        dataset,
                                        settings.numberFormat,
                                        settings.locale,
                                        settings.noDataText
                                    )
                                )
                            );
                        li.appendChild(link);
                        ul.appendChild(li);
                    }
                }
                g.appendChild(ul);
                ul.addEventListener('clickxxx', (e) => {
                    let bb = e.target,
                        bc = bb.closest('.btn-group'),
                        nw = bb.closest('.ia-navigator-widget');
                    if (bc !== null) {
                        if (hasClass(bc, 'open')) {
                            removeClass(bc, 'open');
                            nw.style.overflow = '';
                        } else {
                            addClass(bc, 'open');
                            nw.style.overflow = 'visible';
                        }
                        if (bb.tagName !== 'A') e.preventDefault();
                    }
                });
                div.appendChild(g);
                nav.appendChild(div);
            }
            if (settings.navigationStyle === 'Menu') {
            }

            //if (this.NavigationStyle == NavigationControlStyle.DropDown)
            //{
            //    htmlFragment.Append("</select></p>");
            //    if (this.TagVersion == HtmlTagMode.Html4)
            //        htmlFragment.Append("<script type=\"text/javascript\">if (jQuery) { ");
            //    else
            //        htmlFragment.Append("<script>if (jQuery) { ");
            //    htmlFragment.AppendFormat("jQuery('#{0}_selector').off('change').on('change', function(e) ", navContentId);
            //    htmlFragment.Append("{ var fid = jQuery(this).val(); var href = jQuery(this).children('option:selected').data('navigation-url'); if (fid || href) { ");
            //}
            //else
            //{
            //    htmlFragment.Append("</ul></div>");
            //    if (this.TagVersion == HtmlTagMode.Html4)
            //        htmlFragment.Append("<script type=\"text/javascript\">if (jQuery) { ");
            //    else
            //        htmlFragment.Append("<script>if (jQuery) { ");
            //    htmlFragment.AppendFormat("jQuery('#{0}_selector_list a.feature-link').off('click').on('click', function(e) ", navContentId);
            //    htmlFragment.Append("{ var fid = jQuery(this).data('feature'); var href = jQuery(this).attr('href'); if (fid || href) { ");
            //}
            //htmlFragment.Append("var urlStem = window.location.href.split('?')[0]; urlStem = urlStem.substring(0, urlStem.lastIndexOf('/'));");
            //htmlFragment.Append("var urlTarget = (href && (href != fid) && (href != '#' + fid) ? href : urlStem + '/' + fid) + (window.location.search ? window.location.search : ''); IAO.showPageMask(); window.location.href = urlTarget; e.preventDefault();");
            //htmlFragment.Append(" } }); }</script>");
            //writer.Write(htmlFragment);
            //writer.WriteEndTag("div");
            //if ((this.TagName != null) && (this.TagName != string.Empty))
            //    writer.WriteEndTag(this.TagName);
            this.container.appendChild(nav);
            if (topWidget !== null)
                topWidget.setAttribute('class', topWidget.getAttribute('class').replace(/\s(placeholder)/g, ''));
        }
    };

    static getDefaults = () => {
        return {
            ...AbstractWidget.getDefaults(),
            titleText: '',
            titleBackground: '',
            titleAlignment: '', // '' = 'auto'
            titleColor: '',
            titlePadding: '5',
            controlAlignment: '', // '' = 'auto'
            controlBackground: '',
            controlPadding: '0',
            boxTitleCssClass: null,
            borderRadius: '',
            borderColor: '',
            borderWidth: '0',
            borderStyle: 'none',
            navigationStyle: 'DropDown', // or List, Menu, ListRunIn
            itemLabelFormat: '#FNAME',
            itemLinkUrlFormat: './#FID',
            itemLabelFontSize: null,
            itemListStyle: 'none', // or disc, circle, square
            buttonTextFormat: '#FNAME',
            buttonCssClass: '',
            topOptionText: null,
            includeAllAreas: true
        };
    };
}
