import Color from 'color';
//import { Chart, registerables, LineController } from 'chart.js';
import Chart from 'chart.js/auto';

import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
    BarWithErrorBarsController,
    BarWithErrorBar,
    LineWithErrorBarsController,
    PolarAreaWithErrorBarsController,
    ScatterWithErrorBarsController,
    ArcWithErrorBar,
    PointWithErrorBar
} from 'chartjs-chart-error-bars';
//import 'chartjs-chart-error-bars';
import ChartjsPluginStacked100 from 'chartjs-plugin-stacked100';
import annotationPlugin from 'chartjs-plugin-annotation';

// Data labels available, but not by default
Chart.unregister(ChartDataLabels);
Chart.register(
    BarWithErrorBarsController,
    BarWithErrorBar,
    LineWithErrorBarsController,
    PolarAreaWithErrorBarsController,
    ScatterWithErrorBarsController,
    PointWithErrorBar,
    ArcWithErrorBar
);
Chart.register(ChartjsPluginStacked100);
Chart.register(annotationPlugin);

// Background is white by default
//Chart.register(
/*{
    id:'derivedChart',
    beforeDraw: (chartInstance) =>
    {
        const ctx = chartInstance.chart.ctx,
            canvas = chartInstance.chart.canvas;
        ctx.fillStyle = (canvas.hasAttribute('data-bg-color') ? canvas.getAttribute('data-bg-color') : '#ffffff');
        ctx.fillRect(0, 0, chartInstance.chart.width, chartInstance.chart.height);
    }}*/
//...registerables
//);
/*
https://www.chartjs.org/docs/latest/getting-started/v3-migration.html
This has been renamed "Chart.plugins" according to the documentation but
that doesnt exist in the API and I dont know what the difference is between this
and "Chart.register" (formally "Chart.plugins.register")
*/
/*Chart.plugins.register({
    beforeDraw: function (chart, easing) {
        if (chart.config.options.chartArea && chart.config.options.chartArea.backgroundColor) 
        {
            const ctx = chart.chart.ctx, 
                chartArea = chart.chartArea;
            ctx.save();
            ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
            ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
            ctx.restore();
        }
    }
});*/

const QUERY = '(prefers-reduced-motion: reduce)';
const prefersReducedMotion = window.matchMedia(QUERY).matches;

export default class ChartJS {
    constructor(ctx) {
        this.ctx = ctx;
        this.chart = undefined;
    }
    clear() {
        if (this.chart !== undefined) this.chart.destroy();
    }
    renderConfig(config) {
        //import('chart.js').then(chartjs => {
        this.clear();
        //config.type = config.type === 'line' ? 'bar' :config.type
        this.chart = new Chart(this.ctx, config);
        return this.chart;
        //});
    }
    render({
        name = 'timeseries',
        datasets = [],
        config,
        onComplete = () => {}, // After the chart has drawn/animated
        preRender = (cfg) => {}, // Last chance before render to adjust the configuration - allows complex overrides without bloating this function...
        afterRender = () => {},
        onError = (ex, cfg) => {
            console.group('Error rendering chart');
            console.error(ex);
            console.error(cfg);
            console.groupEnd();
        }
    }) {
        config.onComplete = onComplete;
        let c;
        if (datasets.length === 0) c = chartConfig(config);
        else {
            switch (name) {
                case 'timeseries':
                    c = timeSeriesConfig(datasets, config);
                    break;
                case 'timeseriesmultiaxis':
                    c = multiAxisTimeSeriesConfig(datasets[0], datasets[1], config);
                    break;
                case 'rankedfeatures':
                    c = rankedFeaturesConfig(datasets, config);
                    break;
                case 'pie':
                case 'doughnut':
                    c = pieConfig(datasets, config);
                    break;
                case 'scatter':
                    c = scatterConfig(datasets, config);
                    break;
                default:
                    c = chartConfig(config);
            }
        }
        preRender(c);
        try {
            const chart = this.renderConfig(c);
            afterRender(chart);
            return chart;
        } catch (chartEx) {
            if (this.ctx !== undefined && this.ctx !== null) this.ctx.restore(); // Will probably do nothing, but might alow recovery
            if (onError !== undefined && onError !== null) onError(chartEx, c);
        }
        return null;
    }
}

/****** All subsequent functions return config json for chartjs ******/

const chartConfig = ({
    multiAxis = false,
    labels = [],
    keys = [],
    datasets = [],
    titles = [],
    titleFontSize = 12,
    titleFontWeight = 'bold',
    titleFontColor,
    type = 'line', // bar | stackedBar | line | stackedArea | combo
    hover = {}, // options to override defaults
    tooltip = {}, // options to override defaults
    legend = {}, // options to override defaults
    legendPosition = 'top', // top | right | bottom | left,
    legendFontSize = 12,
    xAxisLabel = '',
    yAxisLabel = '',
    yAxisLabelLeft = '', // Multi axis chart.
    yAxisLabelRight = '', // Multi axis chart.
    displayLegend = true,
    displayXAxes = true,
    displayYAxes = true,
    displayXGridLines = true,
    displayYGridLines = true,
    displayXBorder = true,
    displayYBorder = true,
    xAxisFontSize = 12,
    yAxisFontSize = 12,
    xAxisFontColor = '#333333',
    yAxisFontColor = '#333333',
    fontFamily = 'Roboto',
    responsive = true,
    maintainAspectRatio = true,
    animationResizeDuration = 0,
    orientation = 'vertical', // vertical | horizontal
    beginAtZero = false, // true | false
    pointStyle, // circle |  triangle | rect | rectRounded | rectRot | cross | crossRot | star | line | dash
    pointRadius,
    borderWidth,
    borderColor,
    borderDash, // [5, 5]
    lineTension, // 0 for straight lines
    stepped, // true |  false | 'before' | 'after' | 'middle'
    spanGaps = true,
    onComplete = () => {},
    numberFormatter = new Intl.NumberFormat(),
    plugins = {
        datalabels: {
            enabled: false
        }
    },
    options = {},
    datasetOptions = {}
} = {}) => {
    const backgroundColorPlugin = {
        id: 'custom_canvas_background_color',
        beforeDraw: (chart) => {
            if (options.chartArea && options.chartArea.backgroundColor) {
                const {
                    ctx,
                    chartArea: { left, top, right, bottom }
                } = chart;
                //const ctx = chart.canvas.getContext('2d');
                ctx.save();
                ctx.globalCompositeOperation = 'destination-over';
                ctx.fillStyle = options.chartArea.backgroundColor;
                //ctx.fillRect(0, 0, chart.width, chart.height);
                ctx.fillRect(left, top, right - left, bottom - top);
                ctx.restore();
            }
        }
    };

    const pluginsSet = plugins.datalabels.enabled ? [ChartDataLabels, backgroundColorPlugin] : [backgroundColorPlugin];

    // Derive chart type.
    let chartType, stacked;
    const indexAxis = orientation === 'vertical' ? 'x' : 'y';
    switch (type) {
        case 'bar':
            chartType = 'bar';
            stacked = false;
            break;
        case 'stackedBar':
            chartType = 'bar';
            stacked = true;
            break;
        case 'stackedArea':
            chartType = 'line';
            stacked = true;
            break;
        case 'combo':
            stacked = false;
            break;
        case 'doughnut':
        case 'pie':
        case 'polar':
        case 'radar':
        case 'scatter':
        case 'bubble':
            chartType = type;
            stacked = false;
            break;
        default:
            chartType = 'line';
            stacked = false;
    }

    const cfg = {
        type: chartType,
        plugins: pluginsSet,
        data: {
            labels,
            keys,
            datasets: datasets.map((dataset) => {
                return {
                    /*borderDash,*/
                    borderWidth,
                    lineTension,
                    stepped,
                    pointRadius,
                    spanGaps,
                    fill: type !== 'line' && type !== 'combo',
                    ...dataset,
                    ...datasetOptions
                };
            })
        },
        options: {
            ...options,
            plugins: {
                legend: {
                    display: displayLegend,
                    position: legendPosition,
                    labels: { font: { size: legendFontSize, family: fontFamily } },
                    ...legend
                },
                tooltip: {
                    mode: 'point',
                    intersect: false,
                    titleFont: { family: fontFamily },
                    bodyFont: { family: fontFamily },
                    footerFont: { family: fontFamily },
                    ...tooltip
                },
                title: {
                    display: !multiAxis && titles.length > 0,
                    text: titles,
                    font: { family: fontFamily, size: titleFontSize, weight: titleFontWeight },
                    color: titleFontColor
                },
                ...(options.plugins || {})
            },
            indexAxis,
            animation: {
                resize: { duration: animationResizeDuration },
                onComplete: function (a) {
                    onComplete(a);
                },
                ...(options.animation || {})
            },
            responsive,
            maintainAspectRatio,
            scales: {
                x: {
                    stacked,
                    display: displayXAxes,
                    beginAtZero,
                    ticks: {
                        font: { family: fontFamily, size: xAxisFontSize },
                        color: xAxisFontColor
                    },
                    grid: {
                        drawOnChartArea: displayXGridLines,
                        drawBorder: displayXBorder
                    },
                    title: {
                        display: xAxisLabel !== '' ? true : false,
                        text: xAxisLabel,
                        font: { family: fontFamily, size: xAxisFontSize },
                        color: xAxisFontColor,
                        padding: {
                            top: 10,
                            bottom: 5
                        }
                    }
                },
                y: {
                    stacked,
                    display: !multiAxis && displayYAxes,
                    beginAtZero,
                    ticks: {
                        callback: (value, index, values) => {
                            return numberFormatter.format(value);
                        },
                        font: { family: fontFamily, size: yAxisFontSize },
                        color: yAxisFontColor
                    },
                    grid: { drawOnChartArea: displayYGridLines, drawBorder: displayYBorder },
                    title: {
                        display: yAxisLabel !== '' ? true : false,
                        text: yAxisLabel,
                        font: { family: fontFamily, size: yAxisFontSize },
                        color: yAxisFontColor
                    }
                },
                left: {
                    stacked,
                    beginAtZero,
                    ticks: {
                        callback: (value, index, values) => {
                            return numberFormatter.format(value);
                        },
                        font: { family: fontFamily, size: yAxisFontSize }
                    },
                    display: multiAxis && displayYAxes,
                    grid: { drawOnChartArea: displayYGridLines, drawBorder: displayYBorder },
                    title: {
                        display: yAxisLabelLeft !== '' ? true : false,
                        text: yAxisLabelLeft,
                        font: { family: fontFamily, size: yAxisFontSize },
                        padding: {
                            top: 10,
                            bottom: 5
                        }
                    }
                },
                right: {
                    stacked,
                    beginAtZero,
                    ticks: {
                        callback: (value, index, values) => {
                            return numberFormatter.format(value);
                        },
                        font: { family: fontFamily, size: yAxisFontSize }
                    },
                    display: multiAxis && displayYAxes,
                    position: 'right',
                    grid: { drawOnChartArea: displayYGridLines, drawBorder: displayYBorder },
                    title: {
                        display: yAxisLabelRight !== '' ? true : false,
                        text: yAxisLabelRight,
                        font: { family: fontFamily, size: yAxisFontSize }
                    }
                },
                ...(options.scales || {})
            },
            elements: {
                point: {
                    pointStyle
                },
                ...(options.elements || {})
            },
            hover: {
                mode: 'point',
                intersect: false,
                ...(options.hover || {}),
                ...hover
            }
        }
    };

    //console.log(cfg);

    // Disable all animations if prefers-reduced-motion has been set to reduce in windows environment.
    if (prefersReducedMotion === true) cfg.options.animation = false;

    return cfg;
};

// dataset = {name:'', data:{colIds:[], rowIds:[], headers:[], rows:[]}, colors:[]}
const timeSeriesConfig = (arrDatasets = [], config) => {
    const dataset = arrDatasets[0],
        colors = getChartColors(dataset.data.rows.length).map((c, i) => {
            return dataset.colors !== undefined && dataset.colors.length > i ? dataset.colors[i] : c;
        }),
        { autoDash = true, preserveAllLabels = false } = config, // custom parameters
        fullUniqueLabelSet = preserveAllLabels
            ? [...dataset.data.headers.slice(1)]
            : [
                  ...new Set(
                      arrDatasets
                          .map((d) => [...d.data.headers].slice(1).join('\t'))
                          .join('\t')
                          .split('\t')
                  )
              ];
    let offset = 0;
    const datasets = arrDatasets.reduce((acc, dataset, j) => {
        acc = [
            ...acc,
            ...dataset.data.rows.map((row, i) => {
                const data = [...row],
                    label = data.shift(),
                    xLabels = [...dataset.data.headers.slice(1)],
                    rowId = dataset.data.rowIds[i],
                    feature =
                        dataset.data.features !== undefined && dataset.data.features !== null
                            ? dataset.data.features.find((f) => f.id === rowId)
                            : undefined;
                // Splice in empty data values if the headers do not match, this keeps things lined up!
                for (let j = 0; j < fullUniqueLabelSet.length; j++) {
                    if (xLabels.indexOf(fullUniqueLabelSet[j]) < 0) data.splice(j, 0, undefined);
                }
                return {
                    key: rowId,
                    feature,
                    indicator: dataset.name,
                    label: dataset.label !== undefined ? dataset.label : label,
                    data,
                    labels: xLabels,
                    backgroundColor:
                        dataset.colors !== undefined && dataset.colors.length > i
                            ? dataset.colors[i]
                            : colors[offset + i],
                    borderColor:
                        config.borderColor !== undefined
                            ? config.borderColor
                            : dataset.colors !== undefined && dataset.colors.length > i
                            ? dataset.colors[i]
                            : colors[offset + i],
                    borderDash:
                        config.borderDash !== undefined
                            ? config.borderDash
                            : dataset.borderDash !== undefined
                            ? dataset.borderDash[i]
                            : autoDash && j > 0
                            ? [j * 5, 2]
                            : undefined,
                    borderWidth:
                        config.borderWidth !== undefined
                            ? config.borderWidth
                            : dataset.borderWidth !== undefined && Array.isArray(dataset.borderWidth)
                            ? dataset.borderWidth[i]
                            : dataset.borderWidth || undefined,
                    order: dataset.order
                };
            })
        ];
        offset += dataset.data.rows.length;
        return acc;
    }, []);

    let c = {
        datasets: datasets
            .filter((d) => d.data !== undefined && Array.isArray(d.data) && d.data.length > 0)
            // This sort forces comparison features to be rendered at the end (right) of the chart.
            .sort((a, b) => {
                if (
                    a.feature !== undefined &&
                    a.feature.comparison !== undefined &&
                    b.feature !== undefined &&
                    b.feature.comparison !== undefined
                )
                    return a.feature.comparison - b.feature.comparison;
                return 0;
            }),
        labels: fullUniqueLabelSet,
        //titles: arrDatasets.map(dataset => dataset.name),
        titles: arrDatasets.length > 1 ? '' : dataset.name,
        ...config
    };

    if (arrDatasets.length > 0) {
        c = {
            legend: {
                ...config.legend,
                onClick: function (e, legendItem) {
                    const datasets = this.chart.data.datasets;
                    for (const [index, dataset] of datasets.entries()) {
                        if (dataset.label === legendItem.text) {
                            const meta = this.chart.getDatasetMeta(index);
                            meta.hidden = meta.hidden === null ? !datasets[index].hidden : null;
                        }
                    }
                    this.chart.update();
                },
                labels: {
                    filter: function (legendItem, data) {
                        return legendItem.datasetIndex < data.datasets.length / arrDatasets.length;
                    },
                    font: { family: config.fontFamily }
                }
            },
            tooltip: {
                callbacks: {
                    beforeLabel: function (tooltipItem) {
                        if (arrDatasets.length <= 1) return '';
                        const indicatorName = tooltipItem.dataset.indicator;
                        return `${indicatorName}`;
                    }
                }
            },
            ...c
        };
    }

    return chartConfig(c);
};

// dataset = {name:'', data:{colIds:[], rowIds:[], headers:[], rows:[]}, colors:[]}
const multiAxisTimeSeriesConfig = (dataset1, dataset2, config) => {
    let colors = getChartColors(dataset1.data.rows.length).map((c, i) => {
        return dataset1.colors !== undefined && dataset1.colors.length > i ? dataset1.colors[i] : c;
    });

    const datasetsLine = dataset1.data.rows.map((row, i) => {
        const data = [...row];
        const label = data.shift();
        return {
            label,
            data,
            backgroundColor: colors[i],
            borderColor: colors[i],
            yAxisID: 'left',
            type: 'line'
        };
    });

    colors = colors.map((c, i) => {
        return Color(dataset2.colors !== undefined && dataset2.colors.length > i ? dataset2.colors[i] : c)
            .lighten(0.1)
            .hex();
    });

    const datasetsBar = dataset2.data.rows.map((row, i) => {
        const data = [...row];
        const label = data.shift();
        return {
            label,
            data,
            backgroundColor: colors[i],
            borderColor: colors[i],
            yAxisID: 'right',
            type: 'bar'
        };
    });

    return chartConfig({
        multiAxis: true,
        datasets: [...datasetsLine, ...datasetsBar],
        labels: [...dataset1.data.headers].slice(1),
        titles: [dataset1.name, dataset2.name],
        yAxisLabelLeft: dataset1.name,
        yAxisLabelRight: dataset2.name,
        type: 'combo',
        displayYGridLines: false,
        legend: {
            ...config.legend,
            onClick: function (e, legendItem) {
                const datasets = this.chart.data.datasets;
                const index1 = legendItem.datasetIndex;
                const index2 = datasets.length / 2 + index1;
                for (let index of [index1, index2]) {
                    const meta = this.chart.getDatasetMeta(index);
                    meta.hidden = meta.hidden === null ? !datasets[index].hidden : null;
                }
                this.chart.update();
            },
            labels: {
                filter: function (legendItem, data) {
                    return legendItem.datasetIndex < data.datasets.length / 2;
                },
                font: { family: config.fontFamily }
            }
        },
        tooltip: {
            callbacks: {
                label: function (tooltipItem) {
                    const indicatorName = tooltipItem.dataset.name;
                    const featureName = tooltipItem.dataset.label || '';
                    return `${indicatorName}: ${featureName}: ${tooltipItem.formattedValue}`;
                }
            }
        },
        ...config
    });
};

// dataset = {name:'', data:{colIds:[], rowIds:[], headers:[], rows:[]}, colors:[]}
// Each dataset contains two columns - the name and the date being charted.
const rankedFeaturesConfig = (arrDatasets, config) => {
    const colors = getChartColors(arrDatasets.length).map((c, i) => {
        return arrDatasets[i].colors !== undefined && arrDatasets[i].colors.length > 0 ? arrDatasets[i].colors[0] : c;
    });

    const { rowIds, rows, headers, features } = arrDatasets[0].data;

    // Merge datasets, sum and sort.
    const sortKey = config.sort.toString().toLowerCase(),
        sortDir =
            typeof config.sort === 'number'
                ? config.sort
                : sortKey.substring(0, 4) === 'desc'
                ? -1
                : sortKey.substring(0, 3) === 'asc'
                ? 1
                : 0;
    let sortedData = rowIds
        .map((rowId, i) => {
            const featureName = rows[i][0];
            const comparison = i < features.length && features[i].comparison === true ? true : false;
            const values = [];
            let sum = 0;
            for (let dataset of arrDatasets) {
                const index = dataset.data.rowIds.indexOf(rowId),
                    value = index !== -1 ? dataset.data.rows[index][1] : null,
                    isNum = typeof value === 'number',
                    nv = isNum
                        ? value
                        : value !== undefined && value !== null
                        ? value.y !== undefined
                            ? value.y
                            : !isNaN(parseFloat(value.toString()))
                            ? parseFloat(value.toString())
                            : NaN
                        : NaN;
                values.push(isNum && !isNaN(nv) ? nv : value);
                if (value != null) sum += !isNaN(nv) ? nv : 0;
            }
            return { rowId, featureName, values, sum, comparison };
        })
        .sort((a, b) => {
            if (config.sort === false) return 0; // Do nothing...
            if (isNaN(a.sum) && !isNaN(b.sum)) return -1 * sortDir;
            if (!isNaN(a.sum) && isNaN(b.sum)) return -1 * sortDir;
            if (a.sum < b.sum) return -1 * sortDir;
            if (a.sum > b.sum) return 1 * sortDir;
            return 0;
        });
    // This sort forces comparison features to be rendered at the end (right) of the chart.
    if (config.sortComparisons === false) sortedData = sortedData.sort((a, b) => a.comparison - b.comparison);

    // Build chart datasets.
    const datasets = arrDatasets.map((dataset, i) => {
        return {
            label: dataset.name,
            data: sortedData.map((d) => d.values[i]),
            backgroundColor: colors[i],
            borderWidth:
                config.borderWidth !== undefined
                    ? config.borderWidth
                    : dataset.borderWidth !== undefined
                    ? dataset.borderWidth
                    : 0,
            borderColor:
                config.borderColor !== undefined
                    ? config.borderColor
                    : dataset.borderColor !== undefined
                    ? dataset.borderColor
                    : '#ffffff'
        };
    });

    // Configure axis lines and labels.
    let displayYAxes, displayYGridLines, displayXAxes, displayXGridLines;
    if (config.orientation === 'horizontal') {
        if (rows.length > 20 && config.displayYAxes !== true) displayYAxes = false; // Allow override
        displayYGridLines = false;
    } else {
        if (rows.length > 20 && config.displayXAxes !== true) displayXAxes = false;
        displayXGridLines = false;
    }

    return chartConfig({
        datasets,
        labels: sortedData.map((d) => d.featureName),
        keys: sortedData.map((d) => d.rowId),
        titles: [headers[1]],
        type: 'stackedBar',
        displayXAxes,
        displayYAxes,
        displayXGridLines,
        displayYGridLines,
        ...config
    });
};

// dataset = {name:'', data:{colIds:[], rowIds:[], headers:[], rows:[]}, colors:[]}
// Single datasets - contains two (or more) columns - the name and the date being charted.
const pieConfig = (arrDatasets, config) => {
    let labels = arrDatasets[0].data.rows.map((r) => r[0]),
        keys = arrDatasets[0].data.rowIds.map((r) => r.id || r);
    const colors = getChartColors(labels.length).map((c, i) => {
        return arrDatasets[0].colors !== undefined && arrDatasets[0].colors.length > i ? arrDatasets[0].colors[i] : c;
    });

    const fillTo100 = config.fillTo100;
    const fillTo100Color = config.fillTo100Color;

    // Build chart datasets - presumes that indicators are the rows, features the columns...
    const datasets = [];
    for (let srcDataset of arrDatasets) {
        for (let i = 1; i < srcDataset.data.headers.length; i++) {
            let data = srcDataset.data.rows.map((r) => r[i]);
            let backgroundColor = [...colors];

            if (fillTo100 === true) {
                const arrSum = data.reduce((a, b) => a + b, 0);
                data = [...data, 100 - arrSum];
                backgroundColor = [...colors, fillTo100Color];
            }

            datasets.push({
                id: srcDataset.data.colIds[i].id,
                label: srcDataset.data.headers[i] || srcDataset.name,
                data,
                backgroundColor,
                borderWidth:
                    config.borderWidth !== undefined
                        ? config.borderWidth
                        : srcDataset.borderWidth !== undefined
                        ? srcDataset.borderWidth
                        : 1,
                borderColor:
                    config.borderColor !== undefined
                        ? config.borderColor
                        : srcDataset.borderColor !== undefined
                        ? srcDataset.borderColor
                        : '#ffffff'
            });
        }
    }

    const pcc = chartConfig({
        datasets,
        labels,
        keys,
        type: config.type || 'pie',
        ...config
    });

    pcc.options.plugins.tooltip.filter = (tooltipItem) => {
        return tooltipItem.label !== '';
    };

    pcc.options.scales = undefined; // Exclude non-pie-chart properties
    if (!prefersReducedMotion) {
        pcc.options.animation = {
            ...pcc.options.animation,
            animateRotate: true,
            animateScale: true
        };
    }

    return pcc;
};

// dataset = {name:'', data:{colIds:[], rowIds:[], headers:[], rows:[]}, colors:[]}
// Single datasets - contains two columns - the name and the date being charted.
const scatterConfig = (arrDatasets, config) => {
    const labels = arrDatasets[0].data.rows.map((r) => r[0]);
    const colors = getChartColors(labels.length).map((c, i) => {
        return arrDatasets[0].colors !== undefined && arrDatasets[0].colors.length > i ? arrDatasets[0].colors[i] : c;
    });

    // Build chart datasets - depending on how they have asked for it...

    const datasets =
        config.splitRows === true && arrDatasets.length === 1
            ? arrDatasets[0].data.rows.map((row, i) => {
                  return {
                      id: arrDatasets[0].data.rowIds[i],
                      label: row[0],
                      data: [row[1]],
                      backgroundColor: colors[i],
                      borderWidth:
                          config.borderWidth !== undefined
                              ? config.borderWidth
                              : arrDatasets[0].borderWidth !== undefined
                              ? arrDatasets[0].borderWidth
                              : 1,
                      borderColor:
                          config.borderColor !== undefined
                              ? config.borderColor
                              : arrDatasets[0].borderColor !== undefined
                              ? arrDatasets[0].borderColor
                              : '#ffffff'
                  };
              })
            : arrDatasets.map((dataset, i) => {
                  return {
                      label: dataset.name,
                      data: dataset.data.rows.map((r) => r[1]),
                      labels: [...dataset.data.labels],
                      backgroundColor: colors,
                      borderWidth:
                          config.borderWidth !== undefined
                              ? config.borderWidth
                              : dataset.borderWidth !== undefined
                              ? dataset.borderWidth
                              : 1,
                      borderColor:
                          config.borderColor !== undefined
                              ? config.borderColor
                              : dataset.borderColor !== undefined
                              ? dataset.borderColor
                              : '#ffffff'
                  };
              });

    const scc = chartConfig({
        datasets,
        labels,
        type: config.type || 'scatter',
        ...config
    });
    scc.options.plugins.tooltip = {
        callbacks: {
            label: (tooltipItem) => {
                let label = '';
                const ds = tooltipItem.dataset;
                if (ds !== undefined && ds.labels !== undefined) {
                    label = ds.labels[tooltipItem.dataIndex] || '';
                    if (label) label += ': ';
                    label += `${tooltipItem.label}, ${tooltipItem.formattedValue}`;
                }
                return label;
            }
        },
        ...scc.options.plugins.tooltip
    };
    return scc;
};

// Chart colors.
export const getChartColors = (n) => {
    const chartColors = [
        '#a6cee3',
        '#b2df8a',
        '#fb9a99',
        '#fdbf6f',
        '#cab2d6',
        '#ffff99',
        '#1f78b4',
        '#33a02c',
        '#e31a1c',
        '#ff7f00',
        '#6a3d9a',
        '#b15928'
    ];
    if (n < chartColors.length) return chartColors.slice(0, n);
    return [
        ...chartColors,
        ...Array.from(Array(n - chartColors.length).keys()).map(() =>
            Color({
                r: Math.round(Math.random() * 255),
                g: Math.round(Math.random() * 255),
                b: Math.round(Math.random() * 255)
            }).hex()
        )
    ];
};
