import AbstractWidget, { lengthWithUnit } from './AbstractWidget';
import AbstractTableWidget, { applyCellRules } from './AbstractTableWidget';
import { TextWidget } from './TextWidget';
import { getNumberFormatter, formatDate } from '../utils/localization';
import { DataManipulator } from 'data-catalog-js-api';
import { isNullOrWhitespace, hyphenate } from '../utils/object';

export default class TimeSeriesTableWidget extends AbstractTableWidget {
    render = (data, options) => {
        if (
            this.container !== undefined &&
            this.container !== null &&
            this.design !== undefined &&
            this.design !== null
        ) {
            const doc = this.container.ownerDocument,
                topWidget = this.container.closest('.ia-report-widget'),
                tableElement = doc.createElement('table'),
                dataAvailable = data !== undefined && data !== null && Array.isArray(data) && data.length > 0,
                { activeFeature } = options,
                settings = {
                    ...TimeSeriesTableWidget.getDefaults(),
                    ...this.design
                },
                nfmt = getNumberFormatter(settings.locale, settings.numberFormat),
                cellDataFormat = settings.cellDataFormat,
                tableId = `table-${settings.id}`;
            if (dataAvailable || settings.showWhenEmpty !== false) {
                const {
                    columnDirectionality,
                    tableBorderStyle = 'NotSet',
                    tableBorderWidth = '1px',
                    tableBorderColor,
                    tableAutoWidth,
                    autoAdjustColumnWidths,
                    tableCssStyle,
                    tableCssClass = '',
                    columnLayout = 'most-recent-last',
                    includeDates = 'show-common'
                } = settings;
                if (this.container.querySelector(`.${tableId}`) !== null)
                    this.container.querySelector(`.${tableId}`).remove();
                tableElement.setAttribute('id', tableId);
                if (columnDirectionality !== undefined && columnDirectionality.toLowerCase() === 'righttoleft')
                    tableElement.setAttribute('dir', 'RTL');
                let style = '';
                if (tableBorderStyle !== undefined && tableBorderStyle !== 'NotSet') {
                    style += `border: ${tableBorderStyle.toLowerCase()} ${lengthWithUnit(tableBorderWidth)} ${
                        tableBorderColor !== undefined ? tableBorderColor : '#000'
                    };`;
                }
                if (settings.collapseBorders !== undefined && settings.collapseBorders === true)
                    style += `border-collapse: collapse;`;
                if (tableAutoWidth !== undefined && tableAutoWidth !== false) style += 'width: 100%;';
                if (autoAdjustColumnWidths !== undefined && autoAdjustColumnWidths !== false)
                    style += 'table-layout: fixed;';
                if (tableCssStyle !== undefined && tableCssStyle !== null) style += tableCssStyle;
                // TODO (TOFINISH) - apply properties to this table from the design
                tableElement.setAttribute('class', `ia-table ${tableCssClass} ia-generated ${tableId}`);
                if (style !== '') tableElement.setAttribute('style', style);
                // Child element styles
                style = '';
                if (settings.headerCellBackgroundColor !== undefined && settings.headerCellBackgroundColor !== null)
                    style += `#${tableId} th { background-color: ${settings.headerCellBackgroundColor}; } `;
                if (settings.headerCellTextColor !== undefined && settings.headerCellTextColor !== null)
                    style += `#${tableId} th { color: ${settings.headerCellTextColor}; } `;
                if (settings.cellBackgroundColor !== undefined && settings.cellBackgroundColor !== null)
                    style += `#${tableId} tbody td { background-color: ${settings.cellBackgroundColor}; } `;
                if (settings.cellTextColor !== undefined && settings.cellTextColor !== null)
                    style += `#${tableId} tbody td { color: ${settings.cellTextColor}; } `;
                if (
                    settings.cellBorderStyle !== undefined &&
                    settings.cellBorderStyle !== null &&
                    hyphenate(settings.cellBorderStyle) !== 'not-set'
                ) {
                    style += `#${tableId} tbody td { border: ${lengthWithUnit(settings.cellBorderWidth) || '1px'} ${
                        settings.cellBorderStyle
                    } ${settings.cellBorderColor || 'inherit'}; }`;
                }
                if (style.length > 0) {
                    const inlineStyleId = `rbStyleFor${tableId}`,
                        existing = doc.getElementById(inlineStyleId),
                        inlineStyleTag = doc.createElement('style');
                    if (existing !== undefined && existing !== null) existing.remove();
                    inlineStyleTag.setAttribute('id', inlineStyleId);
                    inlineStyleTag.appendChild(doc.createTextNode(style));
                    doc.head?.appendChild(inlineStyleTag);
                }
                // Build up a set of columns...
                if (dataAvailable) {
                    // Merge any tables of data into one big table
                    const dataTable = DataManipulator.mergeTables(data),
                        iAliases = this.indicatorAliases,
                        aliasesProvided = iAliases !== undefined && iAliases !== null && iAliases.length > 0,
                        findAlias = (iid) => {
                            return iAliases.find((ia) => ia.id === iid);
                        },
                        hasRules =
                            settings.cellColorRules !== undefined &&
                            settings.cellColorRules !== null &&
                            settings.cellColorRules !== '';
                    let coreRows = AbstractWidget.filterTable(
                        dataTable,
                        settings.hideMainFeature === true ? [] : null,
                        settings.showComparisons === false
                            ? []
                            : !isNullOrWhitespace(settings.comparisonFeatureIds)
                            ? settings.comparisonFeatureIds.split(',').map((f) => f.split('|')[1])
                            : null
                    );
                    if (settings.aggregatedAreasAlias !== undefined && settings.aggregatedAreasAlias !== null)
                        AbstractWidget.cleanAggregateNames(dataTable, settings.aggregatedAreasAlias);
                    if (!isNullOrWhitespace(settings.tableSummary)) {
                        const captionText = TextWidget.insertValuesIntoText(
                                settings.tableSummary,
                                data,
                                settings.numberFormat,
                                settings.locale,
                                settings.noDataText
                            ),
                            tableCaption = doc.createElement('caption');
                        tableCaption.setAttribute('class', 'rb-table-caption');
                        tableCaption.appendChild(doc.createTextNode(captionText));
                        tableElement.appendChild(tableCaption);
                    }
                    let { colIds, rowIds, rows, indicators, features } = dataTable,
                        headerGroups = TimeSeriesTableWidget.getColumnGroups(
                            colIds,
                            hyphenate(columnLayout) === 'most-recent-first',
                            hyphenate(includeDates) === 'show-common',
                            iAliases
                        ),
                        // Take the first header group - this will need updating to deal with common/all dates etc.
                        headers = [
                            {
                                label: settings.tableTopLeftLabel !== undefined ? settings.tableTopLeftLabel : '',
                                name: 'Name',
                                width: `${settings.rowHeaderWidth}%`.replace('%%', '%')
                            },
                            ...headerGroups[0]
                        ],
                        subElement = doc.createElement('thead'),
                        rowElement = doc.createElement('tr'),
                        cell,
                        label,
                        txt,
                        first = true,
                        ii,
                        v,
                        vn,
                        headerStyle = '',
                        isEmptyValue = false;
                    if (settings.headerCellBackgroundColor !== undefined)
                        headerStyle += `background-color: ${settings.headerCellBackgroundColor};`;
                    // Generic column width - 1st column...
                    if (headers[0].width !== undefined) {
                        const cg = doc.createElement('colgroup'),
                            cgc = doc.createElement('col');
                        cgc.setAttribute('style', `width: ${headers[0].width};`);
                        cg.appendChild(cgc);
                        tableElement.appendChild(cgc);
                    }
                    for (let h of headers) {
                        cell = doc.createElement('th');
                        cell.setAttribute('scope', 'col');
                        cell.setAttribute('class', `ihc ${settings.headerCellCssClass || ''}`.trim());
                        style = `${headerStyle}`;
                        if (settings.columnHeaderHeight !== undefined && settings.columnHeaderHeight !== '')
                            style += `height: ${lengthWithUnit(settings.columnHeaderHeight)};`;
                        if (h.width !== undefined) style += `width: ${h.width};`;
                        if (style !== '') cell.setAttribute('style', style);
                        cell.setAttribute('data-uuid', h.iid);
                        cell.setAttribute('data-datestamp', h.label);
                        label = doc.createElement('span');
                        txt = settings.dateLabelText.replace(/#DATE/g, h.label);
                        isEmptyValue = txt === undefined || txt === null || txt.trim() === '';
                        label.setAttribute('class', isEmptyValue ? 'iname sr-only' : 'iname');
                        if (txt.indexOf('</') > 0) label.innerHTML = txt;
                        else label.appendChild(doc.createTextNode(isEmptyValue ? h.label || h.name : txt));
                        cell.appendChild(label);
                        rowElement.appendChild(cell);
                    }
                    subElement.appendChild(rowElement);
                    if (
                        settings.tableTitle !== undefined &&
                        settings.tableTitle !== null &&
                        settings.tableTitle !== ''
                    ) {
                        rowElement = doc.createElement('tr');
                        cell = doc.createElement('th');
                        cell.setAttribute('class', `table-title-cell ${settings.headerCellCssClass || ''}`.trim());
                        cell.setAttribute('colspan', headers.length.toFixed(0));
                        if (headerStyle !== '') cell.setAttribute('style', headerStyle);
                        txt = TextWidget.insertValuesIntoText(
                            settings.tableTitle,
                            data,
                            settings.numberFormat,
                            settings.locale,
                            settings.noDataText
                        );
                        if (txt.indexOf('</') > 0) cell.innerHTML = txt;
                        else cell.appendChild(doc.createTextNode(txt));
                        rowElement.appendChild(cell);
                        subElement.insertBefore(rowElement, subElement.firstChild);
                    }
                    tableElement.appendChild(subElement);
                    subElement = doc.createElement('tbody');
                    let rowCount = 0;
                    const useTh = settings.useRowHeadersInBody !== undefined && settings.useRowHeadersInBody === true,
                        fids = (
                            activeFeature !== undefined
                                ? Array.isArray(activeFeature)
                                    ? activeFeature
                                    : [activeFeature]
                                : []
                        ).map((f) => f.id);
                    // Group by feature - fairly simple...?
                    if (hyphenate(settings.rowGroups) === 'group-rows-by-feature') {
                        if (coreRows.length < 1) coreRows = rows.filter((r, i) => fids.indexOf(rowIds[i]) >= 0);
                        for (let f of features) {
                            const frowElement = doc.createElement('tr'),
                                featureRows = rows.filter((r, i) => rowIds[i] === f.id);
                            frowElement.setAttribute('data-feature-id', f.id);
                            rowCount++;
                            cell = doc.createElement(settings.useRowHeadersInBody === true ? 'th' : 'td');
                            cell.setAttribute('colspan', headers.length.toString());
                            cell.appendChild(doc.createTextNode(f.name));
                            frowElement.appendChild(cell);
                            if (settings.showFeatureRowLabel) subElement.appendChild(frowElement);
                            for (let g of headerGroups) {
                                first = true;
                                rowElement = doc.createElement('tr');
                                rowCount++;
                                rowElement.setAttribute(
                                    'class',
                                    rowCount % 2 === 0 ? settings.rowCssClass : settings.alternateRowCssClass
                                );
                                cell = doc.createElement(settings.useRowHeadersInBody === true ? 'th' : 'td');
                                cell.setAttribute('rowspan', featureRows.length.toString());
                                ii = indicators.find((ind) => ind.id === g[0].iid);
                                label = doc.createElement('span');
                                label.setAttribute('class', 'iname');
                                if (ii === undefined) {
                                    txt = `⚠️ Indicator missing: ${g[0].iid} ${g[0].label}`; // DEBUG
                                } else if (aliasesProvided && findAlias(ii.id) !== undefined) {
                                    txt = settings.indicatorLabelText.replace(/#INAME|#NAME/g, findAlias(ii.id).label);
                                } else
                                    txt = settings.indicatorLabelText.replace(
                                        /#INAME|#NAME/g,
                                        hyphenate(settings.labelStyle) === 'short-name'
                                            ? ii.shortName || ii.name
                                            : ii.name
                                    );
                                if (txt.indexOf('</') > 0) label.innerHTML = txt;
                                else label.appendChild(doc.createTextNode(txt));
                                cell.appendChild(label);
                                this.addMetadataLink(cell, ii, settings);
                                rowElement.appendChild(cell);
                                for (let r = 0; r < featureRows.length; r++) {
                                    if (!first) {
                                        rowElement = doc.createElement('tr');
                                        rowCount++;
                                        rowElement.setAttribute(
                                            'class',
                                            rowCount % 2 === 0 ? settings.rowCssClass : settings.alternateRowCssClass
                                        );
                                    }
                                    for (let c of g) {
                                        v = featureRows[r][c.index];
                                        //const coreVals = coreRows.map(cr => cr[c.index]);
                                        if (v === null || v === undefined) v = settings.noDataText;
                                        else if (c.hasDateValues)
                                            v = formatDate(v, settings.dateTimeFormat, settings.locale);
                                        vn = typeof v === 'number';
                                        cell = doc.createElement(useTh && c.index === 0 ? 'th' : 'td');
                                        if (useTh && c.index === 0) cell.setAttribute('scope', 'row');
                                        if (vn && settings.rightAlignNumbers !== false)
                                            cell.setAttribute('class', 'num-right');
                                        txt = cellDataFormat.replace(/#VAL/g, vn ? nfmt.format(v) : v);
                                        if (txt.indexOf('</') > 0) cell.innerHTML = txt;
                                        else cell.appendChild(doc.createTextNode(txt));
                                        if (hasRules && cell.nodeName.toLowerCase() !== 'th') {
                                            const indicatorVals = rows.map((fr, fi) => {
                                                return { id: rowIds[fi], value: fr[c.index] };
                                            });
                                            if (activeFeature !== undefined && activeFeature !== null) {
                                                const afv = indicatorVals.find((iv) => iv.id === fids[0]);
                                                if (afv !== undefined)
                                                    indicatorVals.push({ id: '#ACTIVEFEATURE', value: afv.value });
                                            }
                                            applyCellRules(
                                                cell,
                                                v,
                                                settings.cellColorRules,
                                                settings.cellRuleBehaviour,
                                                undefined,
                                                c,
                                                indicatorVals
                                            );
                                        }
                                        rowElement.appendChild(cell);
                                    }
                                    subElement.appendChild(rowElement);
                                    first = false;
                                }
                            }
                        }
                        tableElement.appendChild(subElement);
                    }
                    // Group rows by indicator - more complex?
                    else if (hyphenate(settings.rowGroups) === 'group-rows-by-indicator') {
                        for (let g of headerGroups) {
                            first = true;
                            rowElement = doc.createElement('tr');
                            rowCount++;
                            rowElement.setAttribute(
                                'class',
                                rowCount % 2 === 0 ? settings.rowCssClass : settings.alternateRowCssClass
                            );
                            cell = doc.createElement(settings.useRowHeadersInBody === true ? 'th' : 'td');
                            if (settings.spacersSpanRows) cell.setAttribute('colspan', (g.length + 1).toString()); // Name column adds 1
                            ii = indicators.find((ind) => ind.id === g[0].iid);
                            label = doc.createElement('span');
                            label.setAttribute('class', 'iname');
                            if (ii === undefined) {
                                txt = `⚠️ Indicator missing: ${g[0].iid} ${g[0].label}`; // DEBUG
                                console.log(indicators); // DEBUG
                                console.log(g); // DEBUG
                            } else if (aliasesProvided && findAlias(ii.id) !== undefined) {
                                txt = settings.indicatorLabelText.replace(/#INAME|#NAME/g, findAlias(ii.id).label);
                            } else
                                txt = settings.indicatorLabelText.replace(
                                    /#INAME|#NAME/g,
                                    hyphenate(settings.labelStyle) === 'short-name' ? ii.shortName || ii.name : ii.name
                                );
                            if (txt.indexOf('</') > 0) label.innerHTML = txt;
                            else label.appendChild(doc.createTextNode(txt));
                            cell.appendChild(label);
                            this.addMetadataLink(cell, ii, settings);
                            rowElement.appendChild(cell);
                            subElement.appendChild(rowElement);
                            for (let r = 0; r < rows.length; r++) {
                                rowElement = doc.createElement('tr');
                                rowElement.setAttribute('data-feature-id', rowIds[r]);
                                rowCount++;
                                rowElement.setAttribute(
                                    'class',
                                    rowCount % 2 === 0 ? settings.rowCssClass : settings.alternateRowCssClass
                                );
                                cell = doc.createElement(settings.useRowHeadersInBody === true ? 'th' : 'td');
                                label = doc.createElement('span');
                                label.setAttribute('class', 'iname');
                                txt = rows[r][0];
                                if (txt.indexOf('</') > 0) label.innerHTML = txt;
                                else label.appendChild(doc.createTextNode(txt));
                                cell.appendChild(label);
                                rowElement.appendChild(cell);
                                for (let c of g) {
                                    v = rows[r][c.index];
                                    if (v === null || v === undefined) v = settings.noDataText;
                                    else if (c.hasDateValues)
                                        v = formatDate(v, settings.dateTimeFormat, settings.locale);
                                    vn = typeof v === 'number';
                                    cell = doc.createElement(useTh && c.index === 0 ? 'th' : 'td');
                                    if (useTh && c.index === 0) cell.setAttribute('scope', 'row');
                                    if (vn && settings.rightAlignNumbers !== false)
                                        cell.setAttribute('class', 'num-right');
                                    txt = cellDataFormat.replace(/#VAL/g, vn ? nfmt.format(v) : v);
                                    if (txt.indexOf('</') > 0) cell.innerHTML = txt;
                                    else cell.appendChild(doc.createTextNode(txt));
                                    if (hasRules && cell.nodeName.toLowerCase() !== 'th') {
                                        const indicatorVals = rows.map((fr, fi) => {
                                            return { id: rowIds[fi], value: fr[c.index] };
                                        });
                                        if (activeFeature !== undefined && activeFeature !== null) {
                                            const afv = indicatorVals.find((iv) => iv.id === fids[0]);
                                            if (afv !== undefined)
                                                indicatorVals.push({ id: '#ACTIVEFEATURE', value: afv.value });
                                        }
                                        applyCellRules(
                                            cell,
                                            v,
                                            settings.cellColorRules,
                                            settings.cellRuleBehaviour,
                                            undefined,
                                            c,
                                            indicatorVals
                                        );
                                    }
                                    rowElement.appendChild(cell);
                                }
                                subElement.appendChild(rowElement);
                                first = false;
                            }
                        }
                        tableElement.appendChild(subElement);
                    }
                }
                this.container.appendChild(tableElement);
                this.applyTableActions(settings);
                this.fireEvent('widgetrender', {
                    id: this.design.id,
                    type: 'TimeSeriesTableWidget'
                }); // Can be important because if set to auto-height can cause layout change
            }
            if (topWidget !== null)
                topWidget.setAttribute('class', topWidget.getAttribute('class').replace(/\s(placeholder)/g, ''));
        }
    };

    static getColumnGroups = (columnSet, reverseDirection, commonDatesOnly, indicatorAliasLookup) => {
        const aliasesProvided =
                indicatorAliasLookup !== undefined && indicatorAliasLookup !== null && indicatorAliasLookup.length > 0,
            uniqueIndColumns = columnSet.map((c) => c.iid).filter((c, i, a) => c !== 'name' && a.indexOf(c) === i),
            uniqueInds =
                aliasesProvided && indicatorAliasLookup.filter((ia) => uniqueIndColumns.indexOf(ia.id) >= 0).length > 0
                    ? indicatorAliasLookup.filter((ia) => uniqueIndColumns.indexOf(ia.id) >= 0).map((ia) => ia.id)
                    : uniqueIndColumns,
            uniqueDates = columnSet
                .slice(1)
                .map((c) => c.label)
                .filter((c, i, a) => a.indexOf(c) === i),
            groups = [],
            filteredDates = [];
        for (let i = 0; i < columnSet.length; i++) {
            columnSet[i].index = i;
            columnSet[i].hasDateValues =
                columnSet[i].dataType !== undefined && columnSet[i].dataType === 'categoric:date';
        }
        for (let d of uniqueDates) {
            let approved = true;
            for (let id of uniqueInds) {
                approved = approved && columnSet.find((c) => c.iid === id && c.label === d) !== undefined;
            }
            if (approved) filteredDates.push(d);
        }
        for (let id of uniqueInds) {
            // Common dates
            if (commonDatesOnly)
                groups.push(columnSet.filter((c) => c.iid === id && filteredDates.indexOf(c.label) >= 0));
            // All dates - need to build a composite...
            else {
                const dateCols = [];
                let matcher;
                for (let d of uniqueDates) {
                    matcher = columnSet.find((c) => c.iid === id && c.label === d);
                    if (matcher !== undefined) dateCols.push(matcher);
                    else {
                        dateCols.push({
                            iid: id,
                            label: d,
                            index: -1
                        });
                        // Aproximate a date from non-common ones to give better ordering
                        matcher = columnSet.find((c) => c.label === d);
                        if (matcher !== undefined) {
                            if (matcher.date !== undefined) dateCols[dateCols.length - 1].date = matcher.date;
                        }
                    }
                }
                groups.push(dateCols);
            }
            groups[groups.length - 1].sort(sortByRealDateOrIndex);
            if (reverseDirection) groups[groups.length - 1].reverse();
        }
        if (groups.filter((g) => g.length > 0).length < 1)
            throw new Error(
                `No common dates found for indicators [${uniqueInds.join(', ')}] - available dates: [${uniqueDates.join(
                    ', '
                )}]`
            );
        return groups;
    };

    static getDefaults = () => {
        return {
            ...AbstractTableWidget.getDefaults(),
            columnLayout: 'MostRecentLast',
            dateLabelFormat: 'g',
            dateLabelText: '#DATE',
            includeNumeratorsAndDenominators: false,
            rowGroups: 'GroupRowsByFeature',
            spacersSpanRows: true
        };
    };
}

const sortByRealDateOrIndex = (a, b) => {
    if (a.date !== undefined && b.date !== undefined) return a.date - b.date;
    else if (a.index !== undefined && b.index !== undefined) return a.index - b.index;
    return 0;
};
