import AbstractTableWidget, { applyCellRules } from './AbstractTableWidget';
import { TextWidget } from './TextWidget';
import { getNumberFormatter, getDateTimeOptions } from '../utils/localization';
import { removeClass, addClass } from '../utils/dom';
import { hyphenate } from '../utils/object';
import { getTokenFor } from '../utils/arcgis';
import { ArcGISPortal, AttributeDomainsLookup } from 'data-catalog-js-api';
import { isNumericField } from './widgetDataUtils';

export default class RelatedRecordsTableWidget extends AbstractTableWidget {
    render = (data, options = {}) => {
        if (
            this.container !== undefined &&
            this.container !== null &&
            this.design !== undefined &&
            this.design !== null
        ) {
            const doc = this.container.ownerDocument,
                topWidget = this.container.closest('.ia-report-widget'),
                dataAvailable = data !== undefined && data !== null && Array.isArray(data) && data.length > 0,
                { activeFeature, idField, nameField, token, tokenManager } = options,
                settings = {
                    ...RelatedRecordsTableWidget.getDefaults(),
                    ...this.design
                },
                {
                    numberFormat,
                    locale,
                    noDataText,
                    cellDataFormat,
                    mode = 'relationship',
                    showWhenEmpty = true,
                    tableMode = 'Table',
                    queryFields = '*',
                    visibleFields = '*',
                    orderByFields,
                    relatedLayersGeoRelation = 'intersects',
                    relatedLayersGeoRelationBuffer = 0,
                    subQuery = '1=1',
                    distinctRecordsOnly = false,
                    tableCssClass = 'standard',
                    hideEmptyRecordSets = false,
                    mergeRelationshipTables = false,
                    customQueryParameters = ''
                } = settings,
                nfmt = getNumberFormatter(locale, numberFormat),
                tfmt = getDateTimeOptions(settings.dateTimeFormat),
                tableId = `relatedRecordsTable${settings.id}`,
                primaryAvailable =
                    activeFeature !== undefined &&
                    activeFeature !== null &&
                    idField !== undefined &&
                    idField !== null &&
                    data[0].indicators !== undefined &&
                    data[0].indicators !== null &&
                    data[0].indicators.length > 0;
            //console.log(this.design); // DEBUG
            // Primary data source - get the source layer URL from that...
            if (showWhenEmpty || (dataAvailable && primaryAvailable)) {
                // How are we rendering?
                let tableContainer = null;
                if (tableMode.toLowerCase() === 'custom' || tableMode.toLowerCase() === 'grid') {
                    const { openingTag = '<div>', closingTag = '</div>', gridColumns = 3 } = settings;
                    tableContainer = this.container.ownerDocument.createElement('div');
                    addClass(tableContainer, 'ia-generated');
                    tableContainer.innerHTML = `${openingTag}${closingTag}`;
                    this.container.appendChild(tableContainer);
                    tableContainer = tableContainer.firstChild;
                    if (tableMode.toLowerCase() === 'grid') {
                        tableContainer.style.display = 'grid';
                        tableContainer.style.gridTemplateColumns = new Array(gridColumns).map((i) => '1fr').join(' ');
                        tableContainer.style.gridTemplateRows = 'auto';
                    }
                } else {
                    tableContainer = this.container;
                }
                const primarySrc =
                        data.find((d) => d.primary === true) !== undefined
                            ? data.find((d) => d.primary === true).source.url
                            : data[0].indicators[0].src,
                    lookups = [],
                    primaryToken = tokenManager !== undefined ? getTokenFor(primarySrc, tokenManager, token) : token;
                ArcGISPortal.getInfo(primarySrc, { token: primaryToken })
                    .then((coreLayerInfo) => {
                        const oidf =
                                coreLayerInfo.objectIdField !== undefined ? coreLayerInfo.objectIdField : 'OBJECTID',
                            idf = coreLayerInfo.fields.find((f) => f.name.toLowerCase() === idField.toLowerCase()),
                            nmf = coreLayerInfo.fields.find((f) => f.name.toLowerCase() === nameField.toLowerCase()),
                            maxGeneralize = settings.maxAllowableOffset || 10,
                            fParams =
                                mode.toLowerCase() === 'geographic'
                                    ? {
                                          token: primaryToken,
                                          outFields: `${oidf},${idf.name},${nmf.name}`,
                                          returnGeometry: true,
                                          maxAllowableOffset: maxGeneralize
                                      }
                                    : { token: primaryToken, outFields: `${oidf},${idf.name},${nmf.name}` };
                        ArcGISPortal.queryFeatures(
                            primarySrc,
                            !isNumericField(idf)
                                ? `${idf.name} IN ('${(Array.isArray(activeFeature) ? activeFeature : [activeFeature])
                                      .map((f) => f.id)
                                      .join(`','`)}')`
                                : `${idf.name} IN (${(Array.isArray(activeFeature) ? activeFeature : [activeFeature])
                                      .map((f) => f.id)
                                      .join(`,`)})`,
                            1,
                            fParams,
                            true
                        ).then((featureSet) => {
                            let dataPromiseChain = [];
                            const coreQueryFeatures = featureSet.features;
                            if (coreQueryFeatures !== undefined && coreQueryFeatures.length > 0) {
                                // TODO - deal with the use of multiple features
                                //activeFeature.objectId = featureSet.features[0].attributes[oidf];
                                //const filterGeometry = featureSet.features[0].geometry;
                                // Update the subQuery to deal with information from the core features...
                                const activeSubQuery = subQuery
                                    .replace(
                                        /#FNAMES\{([^}{]+)\}|\{(active)\}/gi,
                                        coreQueryFeatures.map((f) => f.attributes[nmf.name]).join('$1')
                                    )
                                    .replace(
                                        /#FNAME\{[0-9]+\}|\{active\}/gi,
                                        coreQueryFeatures[!isNaN(parseInt('$1')) ? parseInt('$1') - 1 : 0].attributes[
                                            nmf.name
                                        ]
                                    )
                                    .replace(/#FNAME/gi, coreQueryFeatures[0].attributes[nmf.name])
                                    .replace(
                                        /#FIDS\{([^}{]+)\}|\{(active)\}/gi,
                                        coreQueryFeatures.map((f) => f.attributes[idf.name]).join('$1')
                                    )
                                    .replace(
                                        /#FID\{[0-9]+\}|\{active\}/gi,
                                        coreQueryFeatures[!isNaN(parseInt('$1')) ? parseInt('$1') - 1 : 0].attributes[
                                            idf.name
                                        ]
                                    )
                                    .replace(/#FID/gi, coreQueryFeatures[0].attributes[idf.name])
                                    .replace(`$feature.${idf}`, coreQueryFeatures[0].attributes[idf.name])
                                    .replace(`$feature.${nmf}`, coreQueryFeatures[0].attributes[nmf.name]);
                                if (mode.toLowerCase() === 'geographic') {
                                    for (let url of settings.relatedLayers.split(','))
                                        lookups.push(url.replace('http://', 'https://'));
                                } else if (
                                    settings.relationshipIds !== undefined &&
                                    settings.relationshipIds !== null &&
                                    settings.relationshipIds !== '' &&
                                    settings.relationshipIds !== '*' &&
                                    settings.relationshipIds !== '-1'
                                ) {
                                    for (let t of settings.relationshipIds.split(',')) {
                                        if (/^[0-9]+$/.test(t)) lookups.push(parseInt(t));
                                        else {
                                            const it = coreLayerInfo.relationships.find(
                                                (r) => r.name.toLowerCase() === t.toLowerCase()
                                            );
                                            if (it !== undefined && it.id !== undefined) lookups.push(it.id);
                                        }
                                    }
                                } else {
                                    for (let r of coreLayerInfo.relationships) lookups.push(r.id);
                                }
                                // 2 different data fetching styles dependent on _how_ we are doing this...
                                if (mode.toLowerCase() === 'geographic') {
                                    for (let targetUrl of lookups) {
                                        for (let f of coreQueryFeatures) {
                                            const overParams =
                                                    customQueryParameters !== undefined &&
                                                    customQueryParameters !== null &&
                                                    customQueryParameters !== ''
                                                        ? dumbDownJson(JSON.parse(customQueryParameters))
                                                        : {},
                                                targetParams = {
                                                    geometryType: featureSet.geometryType,
                                                    geometry: JSON.stringify(f.geometry),
                                                    spatialRel: `esriSpatialRel${relatedLayersGeoRelation
                                                        .substring(0, 1)
                                                        .toUpperCase()}${relatedLayersGeoRelation.substring(1)}`,
                                                    inSR: JSON.stringify(featureSet.spatialReference),
                                                    outFields: queryFields, // Always get the full set when we need the object ID
                                                    returnGeometry: false,
                                                    returnDistinctValues: distinctRecordsOnly,
                                                    token:
                                                        tokenManager !== undefined
                                                            ? getTokenFor(targetUrl, tokenManager, primaryToken)
                                                            : primaryToken,
                                                    ...overParams
                                                };
                                            if (relatedLayersGeoRelationBuffer !== 0) {
                                                targetParams.distance = relatedLayersGeoRelationBuffer;
                                                targetParams.units = 'esriSRUnit_Meter';
                                            }
                                            if (
                                                orderByFields !== undefined &&
                                                orderByFields !== null &&
                                                orderByFields !== '' &&
                                                orderByFields !== '*'
                                            )
                                                targetParams.orderByFields = orderByFields;
                                            else if (
                                                queryFields !== undefined &&
                                                queryFields !== null &&
                                                queryFields !== '' &&
                                                queryFields !== '*'
                                            )
                                                targetParams.orderByFields = queryFields;
                                            dataPromiseChain.push(
                                                ArcGISPortal.queryFeatures(
                                                    targetUrl,
                                                    activeSubQuery,
                                                    targetParams['resultRecordCount'] !== undefined ||
                                                        targetParams['outStatistics'] !== undefined
                                                        ? -1
                                                        : 1000,
                                                    targetParams,
                                                    true
                                                ).then((res) => {
                                                    res.sourceUrl = targetUrl;
                                                    res.sourceFeature = {
                                                        ...f
                                                    };
                                                    return res;
                                                })
                                            );
                                        }
                                    }
                                } else {
                                    for (let rid of lookups) {
                                        const targetParams = {
                                            outFields: settings.showRelatedFeatureAttachments
                                                ? '*'
                                                : settings.queryFields, // Always get the full set when we need the object ID
                                            token: primaryToken
                                        };
                                        if (activeSubQuery !== '1=1')
                                            targetParams.definitionExpression = activeSubQuery;
                                        if (
                                            orderByFields !== undefined &&
                                            orderByFields !== null &&
                                            orderByFields !== '' &&
                                            orderByFields !== '*'
                                        )
                                            targetParams.orderByFields = orderByFields;
                                        else if (
                                            queryFields !== undefined &&
                                            queryFields !== null &&
                                            queryFields !== '' &&
                                            queryFields !== '*'
                                        )
                                            targetParams.orderByFields = queryFields;
                                        dataPromiseChain.push(
                                            ArcGISPortal.queryRelatedFeatures(
                                                primarySrc,
                                                rid,
                                                coreQueryFeatures.map((f) => f.attributes[oidf]),
                                                targetParams['resultRecordCount'] !== undefined ||
                                                    targetParams['outStatistics'] !== undefined
                                                    ? -1
                                                    : 1000,
                                                targetParams,
                                                true
                                            ).then((res) => {
                                                res.relationshipId = rid;
                                                return res;
                                            })
                                        );
                                    }
                                }
                            }
                            Promise.all(dataPromiseChain)
                                .then((resultsArray) => {
                                    const resultSet = resultsArray.flat().filter((r) => r !== null),
                                        qfilter =
                                            queryFields === undefined ||
                                            queryFields === null ||
                                            queryFields === '' ||
                                            queryFields === '*',
                                        vfilter =
                                            visibleFields === undefined ||
                                            visibleFields === null ||
                                            visibleFields === '' ||
                                            visibleFields === '*',
                                        allVisible = qfilter && vfilter,
                                        hasRelName =
                                            settings.relatedFeaturesNameField !== undefined &&
                                            settings.relatedFeaturesNameField !== null &&
                                            settings.relatedFeaturesNameField !== '',
                                        visibles = allVisible
                                            ? []
                                            : !vfilter
                                            ? visibleFields.toLowerCase().split(',')
                                            : queryFields.toLowerCase().split(',');
                                    let cell,
                                        v,
                                        vn,
                                        txt,
                                        results,
                                        resultFields,
                                        recordCount = 0;
                                    // Standard table layout...
                                    if (settings.tableMode.toLowerCase() !== 'custom') {
                                        let tableElement,
                                            subElement,
                                            rowElement,
                                            fullSpan = hasRelName ? 1 : 0,
                                            resultsOidf,
                                            isFirst = true;
                                        for (let i = 0; i < resultSet.length; i++) {
                                            results = resultSet[i];
                                            if (results.fields !== undefined) {
                                                resultsOidf = results.fields.find((f) => f.type === 'esriFieldTypeOID');
                                                const relId = results.relationshipId,
                                                    domainLookup = new AttributeDomainsLookup(results.fields),
                                                    domainsReq = domainLookup.domainFields.length > 0;
                                                if (
                                                    isFirst ||
                                                    !mergeRelationshipTables ||
                                                    (hyphenate(settings.orientation) || 'features-are-rows') ===
                                                        'features-are-columns'
                                                ) {
                                                    tableElement = doc.createElement('table');
                                                    tableElement.setAttribute('id', `${tableId}${lookups[i]}`);
                                                    tableElement.setAttribute(
                                                        'class',
                                                        `${tableCssClass} ia-related-records-table ia-generated`
                                                    );
                                                    if (
                                                        settings.columnDirectionality !== undefined &&
                                                        settings.columnDirectionality.toLowerCase() === 'righttoleft'
                                                    )
                                                        tableElement.setAttribute('dir', 'RTL');
                                                    let style = '';
                                                    if (
                                                        settings.tableBorderStyle !== undefined &&
                                                        settings.tableBorderStyle !== 'NotSet'
                                                    ) {
                                                        style += `border: ${settings.tableBorderStyle.toLowerCase()} ${
                                                            settings.tableBorderWidth
                                                        } ${
                                                            settings.tableBorderColor !== undefined
                                                                ? settings.tableBorderColor
                                                                : '#000'
                                                        };`;
                                                    }
                                                    if (
                                                        settings.tableAutoWidth !== undefined &&
                                                        settings.tableAutoWidth !== false
                                                    )
                                                        style += 'width: 100%;';
                                                    if (
                                                        settings.autoAdjustColumnWidths !== undefined &&
                                                        settings.autoAdjustColumnWidths !== false
                                                    )
                                                        style += 'table-layout: fixed;';
                                                    if (
                                                        settings.tableCssStyle !== undefined &&
                                                        settings.tableCssStyle !== null
                                                    )
                                                        style += settings.tableCssStyle;
                                                    tableElement.setAttribute('style', style);
                                                    if (
                                                        (hyphenate(settings.orientation) || 'features-are-rows') ===
                                                        'features-are-columns'
                                                    ) {
                                                        resultFields = allVisible
                                                            ? results.fields
                                                            : results.fields.filter(
                                                                  (f) => visibles.indexOf(f.name.toLowerCase()) >= 0
                                                              );
                                                        sortFieldsBy(resultFields, visibles);
                                                        fullSpan = 1;
                                                        for (let grp of results.relatedRecordGroups) {
                                                            fullSpan +=
                                                                grp.relatedRecords !== undefined
                                                                    ? grp.relatedRecords.length
                                                                    : 0;
                                                        }
                                                    } else {
                                                        resultFields = allVisible
                                                            ? results.fields
                                                            : results.fields.filter(
                                                                  (f) => visibles.indexOf(f.name.toLowerCase()) >= 0
                                                              );
                                                        sortFieldsBy(resultFields, visibles);
                                                        fullSpan = resultFields.length;
                                                        if (settings.showRelationshipFeatures) fullSpan++;
                                                        if (settings.showRelatedFeatureAttachments) fullSpan++;
                                                    }
                                                    subElement = doc.createElement('thead');
                                                    if (
                                                        settings.tableTitle !== undefined &&
                                                        settings.tableTitle !== null &&
                                                        settings.tableTitle !== ''
                                                    ) {
                                                        rowElement = doc.createElement('tr');
                                                        cell = doc.createElement('th');
                                                        cell.setAttribute('colspan', fullSpan.toFixed(0));
                                                        //console.log(settings.tableTitle); // DEBUG
                                                        txt = TextWidget.insertValuesIntoText(
                                                            settings.tableTitle,
                                                            data,
                                                            numberFormat,
                                                            locale,
                                                            ''
                                                        );
                                                        if (txt.indexOf('</') > 0) cell.innerHTML = txt;
                                                        else cell.appendChild(doc.createTextNode(txt));
                                                        rowElement.appendChild(cell);
                                                        subElement.appendChild(rowElement);
                                                    }
                                                    if (settings.showRelationshipName) {
                                                        rowElement = doc.createElement('tr');
                                                        cell = doc.createElement('th');
                                                        cell.setAttribute('colspan', fullSpan.toFixed(0));
                                                        if (mode.toLowerCase() === 'geographic') {
                                                            txt = {
                                                                id: results.sourceFeature.attributes[idf.name],
                                                                name: results.sourceFeature.attributes[nmf.name]
                                                            };
                                                        } else {
                                                            txt = coreLayerInfo.relationships.find(
                                                                (r) => r.id === relId
                                                            );
                                                        }
                                                        if (
                                                            txt !== undefined &&
                                                            (txt.name === undefined || txt.name === '')
                                                        ) {
                                                            const rTitleId = `w${settings.id}Title${results.relationshipId}`,
                                                                path = `${primarySrc.substring(
                                                                    0,
                                                                    primarySrc.lastIndexOf('/')
                                                                )}/${txt.relatedTableId}`;
                                                            cell.setAttribute('id', rTitleId);
                                                            window.setTimeout(() => {
                                                                ArcGISPortal.getInfo(path, {
                                                                    token: primaryToken
                                                                }).then((relatedInfo) => {
                                                                    if (document.getElementById(rTitleId) !== null)
                                                                        document.getElementById(
                                                                            rTitleId
                                                                        ).innerHTML = `<span>${relatedInfo.name}</span>`;
                                                                });
                                                            }, 500);
                                                        } else
                                                            cell.appendChild(
                                                                doc.createTextNode(txt !== undefined ? txt.name : '')
                                                            );
                                                        rowElement.appendChild(cell);
                                                        subElement.appendChild(rowElement);
                                                    }
                                                    // TODO - relationship name...
                                                    if (
                                                        (hyphenate(settings.orientation) || 'features-are-rows') ===
                                                        'features-are-columns'
                                                    ) {
                                                        if (settings.showRelationshipFeatures) {
                                                            rowElement = doc.createElement('tr');
                                                            cell = doc.createElement('td');
                                                            cell.setAttribute('class', 'header-cell');
                                                            //cell.setAttribute('scope', 'col');
                                                            cell.appendChild(doc.createTextNode(''));
                                                            rowElement.appendChild(cell);
                                                            for (let grp of results.relatedRecordGroups) {
                                                                fullSpan =
                                                                    grp.relatedRecords !== undefined
                                                                        ? grp.relatedRecords.length
                                                                        : 0;
                                                                if (fullSpan > 0) {
                                                                    cell = doc.createElement('th');
                                                                    cell.setAttribute('scope', 'col');
                                                                    cell.setAttribute('colspan', fullSpan.toFixed(0));
                                                                    txt = coreQueryFeatures.find(
                                                                        (f) => f.attributes[oidf] === grp.objectId
                                                                    ).attributes[nmf.name];
                                                                    cell.appendChild(doc.createTextNode(txt));
                                                                    rowElement.appendChild(cell);
                                                                }
                                                            }
                                                            subElement.appendChild(rowElement);
                                                            recordCount++;
                                                        }
                                                    } else {
                                                        rowElement = doc.createElement('tr');
                                                        if (settings.showRelationshipFeatures) {
                                                            cell = doc.createElement('td');
                                                            cell.setAttribute('class', 'header-cell');
                                                            //cell.setAttribute('scope', 'col');
                                                            cell.appendChild(doc.createTextNode(''));
                                                            rowElement.appendChild(cell);
                                                        }
                                                        if (hasRelName) {
                                                            cell = doc.createElement('th');
                                                            cell.setAttribute('scope', 'col');
                                                            txt = TextWidget.insertValuesIntoText(
                                                                settings.tableTitle,
                                                                data,
                                                                numberFormat,
                                                                locale,
                                                                ''
                                                            );
                                                            if (txt.indexOf('</') > 0) cell.innerHTML = txt;
                                                            else cell.appendChild(doc.createTextNode(txt));
                                                            rowElement.appendChild(cell);
                                                        }
                                                        for (let f of resultFields) {
                                                            cell = doc.createElement('th');
                                                            cell.setAttribute('scope', 'col');
                                                            cell.appendChild(doc.createTextNode(f.alias || f.name));
                                                            rowElement.appendChild(cell);
                                                        }
                                                        if (settings.showRelatedFeatureAttachments) {
                                                            cell = doc.createElement('th');
                                                            cell.setAttribute('scope', 'col');
                                                            txt = settings.relatedFeatureAttachmentsLabel;
                                                            if (txt.indexOf('</') > 0) cell.innerHTML = txt;
                                                            else cell.appendChild(doc.createTextNode(txt));
                                                            rowElement.appendChild(cell);
                                                        }
                                                        subElement.appendChild(rowElement);
                                                        recordCount++;
                                                    }
                                                    tableElement.appendChild(subElement);
                                                    // Data rows...
                                                    const attOids = [],
                                                        attStem = `w${settings.id}AttR${results.relationshipId}`;
                                                    let rowCount = 0;
                                                    if (results.relatedRecordGroups !== undefined) {
                                                        if (
                                                            (hyphenate(settings.orientation) || 'features-are-rows') ===
                                                            'features-are-columns'
                                                        ) {
                                                            subElement = doc.createElement('tbody');
                                                            let at = 1;
                                                            for (let f of resultFields) {
                                                                rowElement = doc.createElement('tr');
                                                                rowElement.setAttribute(
                                                                    'class',
                                                                    at % 2 === 0
                                                                        ? settings.rowCssClass
                                                                        : settings.alternateRowCssClass
                                                                );
                                                                at++;
                                                                cell = doc.createElement('th');
                                                                cell.setAttribute('scope', 'row');
                                                                cell.appendChild(doc.createTextNode(f.alias || f.name));
                                                                rowElement.appendChild(cell);
                                                                for (let grp of results.relatedRecordGroups) {
                                                                    fullSpan =
                                                                        grp.relatedRecords !== undefined
                                                                            ? grp.relatedRecords.length
                                                                            : 0;
                                                                    if (fullSpan > 0) {
                                                                        for (
                                                                            let j = 0;
                                                                            j < grp.relatedRecords.length;
                                                                            j++
                                                                        ) {
                                                                            cell = doc.createElement('td');
                                                                            v =
                                                                                grp.relatedRecords[j].attributes[
                                                                                    f.name
                                                                                ] || settings.noDataText;
                                                                            if (
                                                                                domainsReq &&
                                                                                domainLookup.hasDomain(f.name)
                                                                            )
                                                                                v = domainLookup
                                                                                    .getDomain(f.name)
                                                                                    .getValue(v);
                                                                            vn = typeof v === 'number';
                                                                            if (
                                                                                vn &&
                                                                                settings.rightAlignNumbers !== false
                                                                            )
                                                                                cell.setAttribute('class', 'num-right');
                                                                            if (f.type === 'esriFieldTypeDate')
                                                                                txt = new Date(v).toLocaleDateString(
                                                                                    locale,
                                                                                    tfmt
                                                                                );
                                                                            else
                                                                                txt = cellDataFormat.replace(
                                                                                    /#VAL/g,
                                                                                    vn ? nfmt.format(v) : v
                                                                                );
                                                                            if (txt.indexOf('</') > 0)
                                                                                cell.innerHTML = txt;
                                                                            else
                                                                                cell.appendChild(
                                                                                    doc.createTextNode(txt)
                                                                                );
                                                                            applyCellRules(
                                                                                cell,
                                                                                v,
                                                                                settings.cellColorRules,
                                                                                settings.cellRuleBehaviour,
                                                                                undefined,
                                                                                { id: f.name },
                                                                                []
                                                                            );
                                                                            rowElement.appendChild(cell);
                                                                        }
                                                                    }
                                                                }
                                                                subElement.appendChild(rowElement);
                                                                recordCount++;
                                                            }
                                                            rowCount = subElement.childNodes.length;
                                                            tableElement.appendChild(subElement);
                                                        } else {
                                                            for (let grp of results.relatedRecordGroups) {
                                                                fullSpan =
                                                                    grp.relatedRecords !== undefined
                                                                        ? grp.relatedRecords.length
                                                                        : 0;
                                                                if (fullSpan > 0) {
                                                                    subElement = doc.createElement('tbody');
                                                                    if (grp.relatedRecords !== undefined) {
                                                                        let at = 1;
                                                                        for (
                                                                            let j = 0;
                                                                            j < grp.relatedRecords.length;
                                                                            j++
                                                                        ) {
                                                                            rowElement = doc.createElement('tr');
                                                                            rowElement.setAttribute(
                                                                                'data-source-oid',
                                                                                grp.objectId.toString()
                                                                            );
                                                                            rowElement.setAttribute(
                                                                                'class',
                                                                                at % 2 === 0
                                                                                    ? settings.rowCssClass
                                                                                    : settings.alternateRowCssClass
                                                                            );
                                                                            at++;
                                                                            if (resultsOidf !== undefined)
                                                                                rowElement.setAttribute(
                                                                                    'data-target-oid',
                                                                                    grp.relatedRecords[j].attributes[
                                                                                        resultsOidf.name
                                                                                    ]
                                                                                );
                                                                            if (
                                                                                settings.showRelationshipFeatures &&
                                                                                j === 0
                                                                            ) {
                                                                                cell = doc.createElement('th');
                                                                                cell.setAttribute('scope', 'row');
                                                                                cell.setAttribute(
                                                                                    'rowspan',
                                                                                    fullSpan.toFixed(0)
                                                                                );
                                                                                txt = coreQueryFeatures.find(
                                                                                    (f) =>
                                                                                        f.attributes[oidf] ===
                                                                                        grp.objectId
                                                                                ).attributes[nmf.name];
                                                                                cell.appendChild(
                                                                                    doc.createTextNode(txt)
                                                                                );
                                                                                rowElement.appendChild(cell);
                                                                            }
                                                                            if (hasRelName) {
                                                                                cell = doc.createElement('td');
                                                                                txt =
                                                                                    grp.relatedRecords[j].attributes[
                                                                                        settings
                                                                                            .relatedFeaturesNameField
                                                                                    ];
                                                                                if (txt.indexOf('</') > 0)
                                                                                    cell.innerHTML = txt;
                                                                                else
                                                                                    cell.appendChild(
                                                                                        doc.createTextNode(txt)
                                                                                    );
                                                                                rowElement.appendChild(cell);
                                                                            }
                                                                            for (let f of resultFields) {
                                                                                cell = doc.createElement('td');
                                                                                v =
                                                                                    grp.relatedRecords[j].attributes[
                                                                                        f.name
                                                                                    ] || settings.noDataText;
                                                                                if (
                                                                                    domainsReq &&
                                                                                    domainLookup.hasDomain(f.name)
                                                                                )
                                                                                    v = domainLookup
                                                                                        .getDomain(f.name)
                                                                                        .getValue(v);
                                                                                vn = typeof v === 'number';
                                                                                if (
                                                                                    vn &&
                                                                                    settings.rightAlignNumbers !== false
                                                                                )
                                                                                    cell.setAttribute(
                                                                                        'class',
                                                                                        'num-right'
                                                                                    );
                                                                                if (f.type === 'esriFieldTypeDate')
                                                                                    txt = new Date(
                                                                                        v
                                                                                    ).toLocaleDateString(locale, tfmt);
                                                                                else
                                                                                    txt = cellDataFormat.replace(
                                                                                        /#VAL/g,
                                                                                        vn ? nfmt.format(v) : v
                                                                                    );
                                                                                if (txt.indexOf('</') > 0)
                                                                                    cell.innerHTML = txt;
                                                                                else
                                                                                    cell.appendChild(
                                                                                        doc.createTextNode(txt)
                                                                                    );
                                                                                applyCellRules(
                                                                                    cell,
                                                                                    v,
                                                                                    settings.cellColorRules,
                                                                                    settings.cellRuleBehaviour,
                                                                                    undefined,
                                                                                    { id: f.name },
                                                                                    []
                                                                                );
                                                                                rowElement.appendChild(cell);
                                                                            }
                                                                            if (
                                                                                settings.showRelatedFeatureAttachments
                                                                            ) {
                                                                                cell = doc.createElement('td');
                                                                                if (
                                                                                    resultsOidf !== undefined &&
                                                                                    (v =
                                                                                        grp.relatedRecords[j]
                                                                                            .attributes[
                                                                                            resultsOidf.name
                                                                                        ]) !== undefined &&
                                                                                    (txt =
                                                                                        coreLayerInfo.relationships.find(
                                                                                            (r) => r.id === relId
                                                                                        )) !== undefined
                                                                                ) {
                                                                                    const cellId = `${attStem}S${grp.objectId}T${v}`;
                                                                                    attOids.push([grp.objectId, v]);
                                                                                    cell.setAttribute('id', cellId);
                                                                                } else
                                                                                    cell.appendChild(
                                                                                        doc.createTextNode('⚠️')
                                                                                    );
                                                                                rowElement.appendChild(cell);
                                                                            }
                                                                            subElement.appendChild(rowElement);
                                                                            recordCount++;
                                                                        }
                                                                    }
                                                                    rowCount = subElement.childNodes.length;
                                                                    tableElement.appendChild(subElement);
                                                                }
                                                            }
                                                        }
                                                        if (attOids.length > 0) {
                                                            txt = coreLayerInfo.relationships.find(
                                                                (r) => r.id === relId
                                                            );
                                                            if (txt !== undefined) {
                                                                const attPath = `${primarySrc.substring(
                                                                        0,
                                                                        primarySrc.lastIndexOf('/')
                                                                    )}/${txt.relatedTableId}`,
                                                                    cellParams = {
                                                                        objectIds: attOids.map(([gid, oid]) => oid),
                                                                        f: 'json',
                                                                        token: primaryToken
                                                                    };
                                                                createAttachmentsPromise(
                                                                    doc,
                                                                    attStem,
                                                                    attPath,
                                                                    cellParams,
                                                                    settings.relatedFeatureAttachmentsImageCssClass,
                                                                    attOids
                                                                );
                                                            }
                                                        }
                                                    } else if (results.features !== undefined) {
                                                        subElement = doc.createElement('tbody');
                                                        let first = true;
                                                        let at = 1;
                                                        for (let feat of results.features) {
                                                            rowElement = doc.createElement('tr');
                                                            rowElement.setAttribute(
                                                                'class',
                                                                at % 2 === 0
                                                                    ? settings.rowCssClass
                                                                    : settings.alternateRowCssClass
                                                            );
                                                            if (settings.showRelationshipFeatures && first) {
                                                                cell = doc.createElement('th');
                                                                cell.setAttribute('scope', 'row');
                                                                cell.setAttribute(
                                                                    'rowspan',
                                                                    results.features.length.toFixed(0)
                                                                );
                                                                txt = results.sourceFeature.attributes[nmf.name];
                                                                cell.appendChild(doc.createTextNode(txt));
                                                                rowElement.appendChild(cell);
                                                                first = false;
                                                            }
                                                            for (let f of resultFields) {
                                                                cell = doc.createElement('td');
                                                                v = feat.attributes[f.name] || settings.noDataText;
                                                                if (domainsReq && domainLookup.hasDomain(f.name))
                                                                    v = domainLookup.getDomain(f.name).getValue(v);
                                                                vn = typeof v === 'number';
                                                                if (f.type === 'esriFieldTypeDate')
                                                                    txt = new Date(v).toLocaleDateString(locale, tfmt);
                                                                else
                                                                    txt = cellDataFormat.replace(
                                                                        /#VAL/g,
                                                                        vn ? nfmt.format(v) : v
                                                                    );
                                                                if (txt.indexOf('</') > 0) cell.innerHTML = txt;
                                                                else cell.appendChild(doc.createTextNode(txt));
                                                                applyCellRules(
                                                                    cell,
                                                                    v,
                                                                    settings.cellColorRules,
                                                                    settings.cellRuleBehaviour,
                                                                    undefined,
                                                                    { id: f.name },
                                                                    []
                                                                );
                                                                rowElement.appendChild(cell);
                                                            }
                                                            subElement.appendChild(rowElement);
                                                            recordCount++;
                                                            at++;
                                                        }
                                                        rowCount = subElement.childNodes.length;
                                                        tableElement.appendChild(subElement);
                                                    }
                                                    if (!hideEmptyRecordSets || rowCount > 0)
                                                        tableContainer.appendChild(tableElement);
                                                }
                                                isFirst = false;
                                            }
                                        }
                                    }
                                    // Heavily customised layout - leave to the designer, just fill in the blanks using ArcGIS (older or Arcade) syntax...
                                    else {
                                        const { cellDataFormat = '', tableMode = 'custom' } = settings,
                                            allValues =
                                                (cellDataFormat === '*' || cellDataFormat === '#VAL') &&
                                                tableMode.toLowerCase() === 'grid',
                                            tableBody = tableContainer.querySelector('tbody');
                                        for (let i = 0; i < resultSet.length; i++) {
                                            results = resultSet[i];
                                            // Data rows...will not work without fields
                                            if (results.fields !== undefined) {
                                                resultFields = allVisible
                                                    ? results.fields
                                                    : results.fields.filter(
                                                          (f) => visibles.indexOf(f.name.toLowerCase()) >= 0
                                                      );
                                                sortFieldsBy(resultFields, visibles);
                                                const domainLookup = new AttributeDomainsLookup(results.fields),
                                                    domainsReq = domainLookup.domainFields.length > 0;
                                                if (results.relatedRecordGroups !== undefined) {
                                                    for (let grp of results.relatedRecordGroups) {
                                                        if (grp.relatedRecords !== undefined) {
                                                            for (let j = 0; j < grp.relatedRecords.length; j++) {
                                                                txt = allValues ? '' : `${cellDataFormat}`;
                                                                for (let f of resultFields) {
                                                                    v =
                                                                        grp.relatedRecords[j].attributes[f.name] ||
                                                                        noDataText;
                                                                    if (domainsReq && domainLookup.hasDomain(f.name))
                                                                        v = domainLookup.getDomain(f.name).getValue(v);
                                                                    vn = typeof v === 'number';
                                                                    if (f.type === 'esriFieldTypeDate')
                                                                        v = new Date(v).toLocaleDateString(
                                                                            locale,
                                                                            tfmt
                                                                        );
                                                                    else v = vn ? nfmt.format(v) : v;
                                                                    txt = allValues
                                                                        ? txt +
                                                                          `<span class="cell-value" data-field="${f.name}">${v}</span>`
                                                                        : txt
                                                                              .replace(
                                                                                  new RegExp(
                                                                                      `$feature.${f.name}`,
                                                                                      'gi'
                                                                                  ),
                                                                                  v
                                                                              )
                                                                              .replace(
                                                                                  new RegExp(`[$]?{${f.name}}`, 'gi'),
                                                                                  v
                                                                              );
                                                                }
                                                                // Wrap in a <span> to add, then pull into parent... or do it directly for a table
                                                                const isTr =
                                                                    txt.indexOf('<tr') === 0 &&
                                                                    tableBody !== undefined &&
                                                                    tableBody !== null;
                                                                cell = doc.createElement(isTr ? 'tbody' : 'span');
                                                                tableContainer.appendChild(cell);
                                                                if (txt.indexOf('</') > 0) {
                                                                    cell.innerHTML = txt;
                                                                    (isTr ? tableBody : tableContainer).appendChild(
                                                                        cell.firstChild
                                                                    );
                                                                    cell.remove();
                                                                } else cell.appendChild(doc.createTextNode(txt));
                                                                recordCount++;
                                                            }
                                                        }
                                                    }
                                                } else if (results.features !== undefined) {
                                                    for (let j = 0; j < results.features.length; j++) {
                                                        txt = allValues ? '' : `${cellDataFormat}`;
                                                        for (let f of resultFields) {
                                                            v = results.features[j].attributes[f.name] || noDataText;
                                                            if (domainsReq && domainLookup.hasDomain(f.name))
                                                                v = domainLookup.getDomain(f.name).getValue(v);
                                                            vn = typeof v === 'number';
                                                            if (f.type === 'esriFieldTypeDate')
                                                                v = new Date(v).toLocaleDateString(locale, tfmt);
                                                            else v = vn ? nfmt.format(v) : v;
                                                            txt = allValues
                                                                ? txt +
                                                                  `<span class="cell-value" data-field="${f.name}">${v}</span>`
                                                                : txt
                                                                      .replace(
                                                                          new RegExp(`[$]feature[.]${f.name}`, 'gi'),
                                                                          v
                                                                      )
                                                                      .replace(new RegExp(`[$]?{${f.name}}`, 'gi'), v);
                                                        }
                                                        // Wrap in a <span> to add, then pull into parent... or do it directly for a table
                                                        const isTr =
                                                            txt.indexOf('<tr') === 0 &&
                                                            tableBody !== undefined &&
                                                            tableBody !== null;
                                                        cell = doc.createElement(isTr ? 'tbody' : 'span');
                                                        tableContainer.appendChild(cell);
                                                        if (txt.indexOf('</') > 0) {
                                                            cell.innerHTML = txt;
                                                            (isTr ? tableBody : tableContainer).appendChild(
                                                                cell.firstChild
                                                            );
                                                            cell.remove();
                                                        } else cell.appendChild(doc.createTextNode(txt));
                                                        recordCount++;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    if (recordCount < 1) {
                                        const label = doc.createElement('div');
                                        label.setAttribute('class', 'ia-generated ia-empty-records-label');
                                        label.appendChild(
                                            doc.createTextNode(
                                                TextWidget.insertValuesIntoText(
                                                    settings.noRecordsFoundLabel,
                                                    data,
                                                    numberFormat,
                                                    locale,
                                                    ''
                                                )
                                            )
                                        );
                                        this.container.appendChild(label);
                                    }
                                    this.applyTableActions(settings);
                                    if (settings.eventsGenerate !== undefined && settings.eventsGenerate !== null)
                                        this.bindRowEvents(settings.eventsGenerate);
                                    if (settings.eventsListen !== undefined && settings.eventsListen === true)
                                        this.addEventListener(this.onWidgetEvent);
                                })
                                .catch((dataPromiseErr) => {
                                    this.renderError(dataPromiseErr, settings.id, settings.scriptClass);
                                });
                        });
                    })
                    .catch((err) => {
                        this.renderError(err, settings.id, settings.scriptClass);
                    });
            }
            this.fireEvent('widgetrender', {
                id: this.design.id,
                type: 'RelatedRecordsTableWidget'
            }); // Can be important because if set to auto-height can cause layout change
            removeClass(topWidget, 'placeholder');
        }
    };

    static getDefaults = () => {
        return {
            ...AbstractTableWidget.getDefaults(),
            queryFields: '*',
            visibleFields: '*',
            mergeRelationshipTables: false,
            showRelationshipFeatures: false,
            showRelationshipName: true,
            relatedFeaturesNameField: '',
            noRecordsFoundLabel: 'No records found',
            hideEmptyRecordSets: false,
            showRelatedFeatureAttachments: false,
            relatedFeatureAttachmentsLabel: '',
            relatedFeatureAttachmentsImageCssClass: 'rb-table-thumbnail',
            mode: 'relationship', // or 'geographic',
            orientation: 'features-are-rows', // or 'features-are-columns'
            relationshipIds: '',
            relatedLayers: '',
            relatedLayersGeoRelation: 'intersects',
            relatedLayersGeoRelationBuffer: 0,
            dateTimeFormat: 'D',
            tableMode: 'Table' // or 'Custom'
        };
    };
}

const createAttachmentsPromise = (
    doc,
    cellIdStem,
    attachmentsLayerUrl,
    attachmentsParams,
    imgCssClass = '',
    featureOidsList = []
) => {
    window.setTimeout(() => {
        ArcGISPortal.getFeatures(`${attachmentsLayerUrl}/queryAttachments`, attachmentsParams, true).then(
            (attachmentData) => {
                if (attachmentData.attachmentGroups !== undefined) {
                    let link, child, path;
                    for (let grp of attachmentData.attachmentGroups) {
                        const srcId = featureOidsList.find(([fid, oid]) => oid === grp.parentObjectId)[0];
                        const cell = doc.getElementById(`${cellIdStem}S${srcId}T${grp.parentObjectId}`);
                        if (cell !== null) {
                            for (let info of grp.attachmentInfos) {
                                path = `${attachmentsLayerUrl}/${grp.parentObjectId}/attachments/${info.id}`;
                                if (attachmentsParams.token !== undefined)
                                    path = `${path}?token=${attachmentsParams.token}`;
                                link = cell.ownerDocument.createElement('a');
                                link.setAttribute('class', 'att-link');
                                link.setAttribute('target', '_blank');
                                link.setAttribute('href', path);
                                if (info.contentType.indexOf('image/') === 0) {
                                    child = cell.ownerDocument.createElement('img');
                                    child.setAttribute('src', path);
                                    child.setAttribute('alt', info.name);
                                    child.setAttribute('class', `att-image ${imgCssClass}`.trim());
                                    if (info.keywords !== undefined && info.keywords !== '')
                                        child.setAttribute('title', info.keywords);
                                    link.appendChild(child);
                                } else link.appendChild(cell.ownerDocument.createTextNode(info.name));
                                cell.appendChild(link);
                            }
                        }
                    }
                }
            }
        );
    }, 500);
};

const sortFieldsBy = (fields = [], orderedNames = []) => {
    if (orderedNames.length > 0 && fields.length > 0) {
        const orderLookup = {};
        for (let i = 0; i < orderedNames.length; i++) {
            orderLookup[orderedNames[i].toLowerCase()] = i;
        }
        const sorter = (a, b) => {
            const an = a.name.toLowerCase(),
                bn = b.name.toLowerCase(),
                ai = orderLookup[an] || 0,
                bi = orderLookup[bn] || 0;
            return ai - bi;
        };
        fields.sort(sorter);
    }
};

const dumbDownJson = (jsonObject = {}) => {
    const keySet = Object.keys(jsonObject),
        flatObject = { ...jsonObject };
    for (let k of keySet) {
        if (typeof jsonObject[k] === 'object') {
            flatObject[k] = JSON.stringify(jsonObject[k]);
        }
    }
    return flatObject;
};
