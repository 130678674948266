import AbstractWidget from './AbstractWidget';
import AbstractChartWidget from './AbstractChartWidget';
import BarChartWidget from './BarChartWidget';
import { hyphenate } from '../utils/object';
import { DataManipulator } from 'data-catalog-js-api';

export default class PyramidChartWidget extends AbstractChartWidget {
    // Render here is slightly special, because it can _detect_ if the calling (React) class has laid out some of the HTML before calling this method.
    render = (data, options = {}) => {
        if (
            this.container !== undefined &&
            this.container !== null &&
            this.design !== undefined &&
            this.design !== null
        ) {
            // Basics - this will give us the box or the chart content area... using super class
            const settings = {
                    ...PyramidChartWidget.getDefaults(),
                    ...this.design
                },
                chartContentElement = this.renderBox(data, {
                    element: 'figure',
                    boxClass: 'ia-chart-box',
                    showTitle: true, // Always true, because inside chart doesn't work here (settings.titlePosition !== 'InsideChart')
                    titleStyle:
                        settings.titlePosition === 'InsideChart' ? `font-family: ${settings.axisFontFamily};` : '',
                    messagePadType: 'padding',
                    settings: {
                        ...settings,
                        messageBackground: settings.backgroundColor // Transfer chart background to message as well
                    }
                }),
                doc = this.container.ownerDocument,
                topWidget = this.container.closest('.ia-report-widget'),
                titleBar = topWidget.querySelector('.ia-text-box-title'),
                dataTable = DataManipulator.mergeTables(
                    data.filter((d) => d.indicators !== undefined),
                    true
                ), // Discard non-common features  unless they are comparisons
                iAliases = this.indicatorAliases,
                aliasesProvided = iAliases !== undefined && iAliases !== null && iAliases.length > 0,
                {
                    palette,
                    plotMargins,
                    yAxisReversed = false,
                    axisAlignment = 'outside',
                    barOrientation = 'horizontal',
                    axisCentreWidth = '40px'
                } = settings,
                hzOrVert = barOrientation.toLowerCase(),
                activePlotMargins = (
                    plotMargins !== undefined &&
                    plotMargins !== null &&
                    plotMargins.toLowerCase() !== '' &&
                    plotMargins.toLowerCase() !== 'auto'
                        ? plotMargins
                        : '5px 25px 5px 25px'
                )
                    .split(' ')
                    .map((m) => parseFloat(m.replace('px', '')));
            let w = settings.width,
                h = settings.height,
                hw = w,
                hh = h;
            if (/^[0-9]+px$/.test(w) && /^[0-9]+px$/.test(h)) {
                w = parseFloat(settings.width.replace('px', ''));
                if (this.container.offsetWidth) w = Math.min(w, this.container.offsetWidth);
                if (chartContentElement.offsetWidth) w = Math.min(w, chartContentElement.offsetWidth);
                h = parseFloat(settings.height.replace('px', ''));
                if (this.container.offsetHeight) h = Math.min(h, this.container.offsetHeight);
                if (chartContentElement.offsetHeight) h = Math.min(h, chartContentElement.offsetHeight);
                else if (titleBar !== undefined && titleBar !== null && titleBar.offsetHeight)
                    hh = hh - titleBar.offsetHeight;
                hw = hzOrVert === 'horizontal' ? (w - activePlotMargins[1] - activePlotMargins[3]) / 2.0 : w;
                hh = hzOrVert === 'horizontal' ? h : h / 2.0;
            }
            // Split the data and the canvas into 2, then use Bar Charts to do the rendering...
            const activePalette = AbstractChartWidget.getPaletteColors(palette),
                centreWidth = axisAlignment === 'center' ? parseFloat(axisCentreWidth.replace('px', '')) : 0,
                wClip = hzOrVert === 'horizontal' ? centreWidth * 0.5 : 0,
                hClip = hzOrVert === 'vertical' ? centreWidth * 0.5 : 0,
                leftConfig = {
                    ...settings,
                    id: `${settings.id}Left`,
                    width: `${hw + activePlotMargins[3] + wClip - 5}px`, // 5 is a fudge, should look at why we need it
                    height: `${hh + hClip}px`,
                    palette: activePalette.join(' '),
                    titlePosition: 'titleBar',
                    yAxesOffset: hzOrVert === 'vertical' ? undefined : false,
                    xAxesCallback: (axes, key) => {
                        if (wClip !== 0) axes.maxWidth = centreWidth;
                        else if (hClip !== 0) axes.maxHeight = centreWidth;
                    },
                    tooltipFormat: '',
                    labelDataPointsAnchor:
                        settings.labelDataPointsAnchor === 'start'
                            ? 'end'
                            : settings.labelDataPointsAnchor === 'end'
                            ? 'start'
                            : settings.labelDataPointsAnchor,
                    preserveAllLabels: true
                },
                rightConfig = {
                    ...settings,
                    id: `${settings.id}Right`,
                    width: `${hw - wClip + activePlotMargins[1] + 5}px`,
                    height: `${hh - hClip}px`,
                    palette: (activePalette.length > 1 ? activePalette.slice(1) : activePalette).join(' '),
                    titlePosition: 'titleBar',
                    yAxesOffset: hzOrVert === 'vertical' ? undefined : false,
                    tooltipFormat: '',
                    preserveAllLabels: true
                },
                leftChart = new BarChartWidget(this.container, leftConfig),
                rightChart = new BarChartWidget(this.container, rightConfig);
            if (hzOrVert === 'horizontal') {
                leftConfig.yAxisReversed = !yAxisReversed; // Left/right - default is to reverse the left one but you can do a different style...
                leftConfig.yAxisAnchor = axisAlignment === 'outside' ? 'left' : 'right';
                leftConfig.plotMargins = [activePlotMargins[0], `0`, activePlotMargins[2], activePlotMargins[3]].join(
                    ' '
                );
                rightConfig.yAxisReversed = yAxisReversed;
                rightConfig.yAxisAnchor = axisAlignment === 'outside' ? 'right' : 'left';
                if (axisAlignment === 'center') rightConfig.xAxisLabelFormat = '[tick]'; //'[blank]';
                rightConfig.plotMargins = [activePlotMargins[0], activePlotMargins[1], activePlotMargins[2], '0'].join(
                    ' '
                );
                if (leftConfig.legendPosition === 'right') rightConfig.legendPosition = 'left';
                if (rightConfig.legendPosition === 'left') rightConfig.legendPosition = 'right';
            } else {
                rightConfig.yAxisReversed = true; // Top(left)/Bottom(right)
                if (axisAlignment === 'center') rightConfig.xAxisLabelFormat = '[tick]'; //'[blank]';
                if (leftConfig.legendPosition === 'bottom') rightConfig.legendPosition = 'top';
                if (rightConfig.legendPosition === 'top') rightConfig.legendPosition = 'bottom';
            }
            // Data constraints?
            if (
                settings.yAxisRange === undefined ||
                settings.yAxisRange === null ||
                settings.yAxisRange === '' ||
                settings.yAxisRange === 'auto'
            ) {
                let min = 0,
                    max = Number.MAX_SAFE_INTEGER * -1;
                for (let r of dataTable.rows) {
                    for (let i = 1; i < r.length; i++) {
                        if (r[i] < min) min = r[i];
                        if (r[i] > max) max = r[i];
                    }
                }
                if (max !== Number.MAX_SAFE_INTEGER) {
                    leftConfig.yAxisRange = `${min.toFixed(0)},${Math.ceil(max).toFixed(0)}`;
                    rightConfig.yAxisRange = `${min.toFixed(0)},${Math.ceil(max).toFixed(0)}`;
                }
            }
            //console.log(settings); // DEBUG
            let singleTimeSlice = dataTable.colIds.length - 1 === dataTable.indicators.length;
            //if (settings.sortFeaturesByName) DataManipulator.sortTable(dataTable);
            // Set the value indices on the columns, to pick data up later...
            for (let i = 0; i < dataTable.colIds.length; i++) dataTable.colIds[i].index = i;
            // But if required, re-sort
            if (aliasesProvided)
                AbstractWidget.applySortAndRename(dataTable.colIds, iAliases, dataTable.indicators, dataTable.rows);
            // One time slice? Then the headers need adjustment...
            if (singleTimeSlice && hyphenate(settings.seriesBindingStyle) !== 'indicators-as-series') {
                let aind;
                for (let i = dataTable.colIds.length - 1; i >= 1; i--) {
                    if (dataTable.colIds[i].type === 'value') {
                        aind =
                            dataTable.indicators.find((ii) => ii.id === dataTable.colIds[i].iid) ||
                            dataTable.indicators[i - 1];
                        aind =
                            aliasesProvided && iAliases.find((ia) => ia.id === dataTable.colIds[i].iid) !== undefined
                                ? iAliases.find((ia) => ia.id === dataTable.colIds[i].iid).label
                                : aind !== undefined
                                ? hyphenate(settings.labelStyle || 'full-name') === 'short-name'
                                    ? aind.shortName
                                    : aind.name
                                : settings.noDataText;
                        dataTable.headers[i] = settings.xAxisLabelFormat
                            .replace(/^\[blank\]$/, '#NAME') // [blank] is special, but is handled elsewhere
                            .replace(/#IDATE/g, dataTable.headers[i])
                            .replace(/#DATE/g, dataTable.headers[i] || aind)
                            .replace(/#NAME/g, aind)
                            .replace(/#FNAME/g, dataTable.rows[0][0])
                            .replace(/#INAME/g, aind);
                    } else dataTable.colIds.splice(i, 1);
                }
                /*for (let i = 1; i < dataTable.colIds.length; i++) {
                    aind =
                        aliasesProvided && iAliases.find((ia) => ia.id === dataTable.colIds[i].iid) !== undefined
                            ? iAliases.find((ia) => ia.id === dataTable.colIds[i].iid).label
                            : (dataTable.indicators.find((ii) => ii.id === dataTable.colIds[i].iid) || dataTable.indicators[i - 1]).name;
                    dataTable.headers[i] = settings.xAxisLabelFormat
                        .replace(/#IDATE/g, dataTable.headers[i])
                        .replace(/#DATE/g, dataTable.headers[i])
                        .replace(/#NAME/g, aind)
                        .replace(/#FNAME/g, dataTable.rows[0][0])
                        .replace(/#INAME/g, aind);
                }*/
            }
            // Split data up... very roughly!
            let leftData, rightData;
            const { leftLabel, rightLabel } = settings,
                hasLeftLabel = leftLabel !== undefined && leftLabel !== null && leftLabel !== '',
                hasRightLabel = rightLabel !== undefined && rightLabel !== null && rightLabel !== '';
            if (settings.dataSplit === 'center') {
                const center = Math.ceil((dataTable.colIds.length - 1) / 2.0) + 1,
                    leftCols = dataTable.colIds.slice(0, center),
                    leftIds = leftCols.map((c) => c.iid),
                    rightCols = dataTable.colIds.slice(0, 1).concat(dataTable.colIds.slice(center)),
                    rightIds = rightCols.map((c) => c.iid);
                leftData = [
                    {
                        colIds: leftCols,
                        headers: dataTable.headers.slice(0, center),
                        rowIds: dataTable.rowIds.slice(0),
                        rows: dataTable.rows.map((r) => r.slice(0, center)),
                        features: dataTable.features.slice(0),
                        indicators: dataTable.indicators.filter((ii) => leftIds.indexOf(ii.id) >= 0)
                    }
                ];
                rightData = [
                    {
                        colIds: rightCols,
                        headers: dataTable.headers.slice(0, 1).concat(dataTable.headers.slice(center)),
                        rowIds: dataTable.rowIds.slice(0),
                        rows: dataTable.rows.map((r) => r.slice(0, 1).concat(r.slice(center))),
                        features: dataTable.features.slice(0),
                        indicators: dataTable.indicators.filter((ii) => rightIds.indexOf(ii.id) >= 0)
                    }
                ];
            } else if (settings.dataSplit === 'alternate') {
                leftData = {
                    colIds: dataTable.colIds.slice(0, 1),
                    headers: dataTable.headers.slice(0, 1),
                    rowIds: dataTable.rowIds.slice(0),
                    rows: dataTable.rows.map((r) => r.slice(0, 1)),
                    features: dataTable.features.slice(0),
                    indicators: []
                };
                rightData = {
                    colIds: dataTable.colIds.slice(0, 1),
                    headers: dataTable.headers.slice(0, 1),
                    rowIds: dataTable.rowIds.slice(0),
                    rows: dataTable.rows.map((r) => r.slice(0, 1)),
                    features: dataTable.features.slice(0),
                    indicators: []
                };
                const ilookup = (iid) => {
                    return dataTable.indicators.find((i) => i.id === iid);
                };
                for (let i = 1; i < dataTable.colIds.length; i += 2) {
                    leftData.colIds.push(dataTable.colIds[i]);
                    rightData.colIds.push(dataTable.colIds[i + 1]);
                    leftData.headers.push(dataTable.headers[i]);
                    rightData.headers.push(dataTable.headers[i + 1]);
                    leftData.indicators.push(ilookup(dataTable.colIds[i].iid));
                    rightData.indicators.push(ilookup(dataTable.colIds[i + 1].iid));
                    for (let j = 0; j < dataTable.rows.length; j++) {
                        leftData.rows[j].push(dataTable.rows[j][i]);
                        rightData.rows[j].push(dataTable.rows[j][i + 1]);
                    }
                }
                leftData = [leftData];
                rightData = [rightData];
            }
            if (leftData[0].rowIds.length === 1 && hasLeftLabel) leftData[0].rows[0][0] = leftLabel;
            else if (hasLeftLabel) {
                for (let i = 0; i < leftData[0].rows.length; i++) {
                    leftData[0].rows[i][0] =
                        leftLabel.indexOf('#') >= 0
                            ? leftLabel.replace(/#FNAME|#NAME/g, leftData[0].rows[i][0])
                            : `${leftLabel} (${leftData[0].rows[i][0]})`;
                }
            }
            if (rightData[0].rowIds.length === 1 && hasRightLabel) rightData[0].rows[0][0] = rightLabel;
            else if (hasRightLabel) {
                for (let i = 0; i < rightData[0].rows.length; i++) {
                    rightData[0].rows[i][0] =
                        rightLabel.indexOf('#') >= 0
                            ? rightLabel.replace(/#FNAME|#NAME/g, rightData[0].rows[i][0])
                            : `${rightLabel} (${rightData[0].rows[i][0]})`;
                }
            }
            // Null values are dangerous in a pyramid chart because they can exclude X or Y labels, so...
            for (let ds of leftData) {
                for (let r of ds.rows) {
                    for (let i = 0; i < r.length; i++) {
                        if (r[i] === undefined || r[i] === null) r[i] = 0;
                    }
                }
            }
            for (let ds of rightData) {
                for (let r of ds.rows) {
                    for (let i = 0; i < r.length; i++) {
                        if (r[i] === undefined || r[i] === null) r[i] = 0;
                    }
                }
            }
            leftConfig.dataIndexOffset = 0;
            rightConfig.dataIndexOffset = leftData.length;
            if (leftConfig.requiredDataAggregation === 'aggregated' && hasLeftLabel)
                leftConfig.aggregatedAreasAlias = leftLabel;
            if (rightConfig.requiredDataAggregation === 'aggregated' && hasRightLabel)
                rightConfig.aggregatedAreasAlias = rightLabel;
            const leftPanel = doc.createElement('div'),
                rightPanel = doc.createElement('div');
            leftPanel.setAttribute(
                'style',
                `width: ${hw + wClip + activePlotMargins[3] - 5}px; max-height: ${hh}px; flex: 0 1 auto;`
            ); // 5 is a fudge, should look at why we need it
            rightPanel.setAttribute(
                'style',
                `width: ${hw - wClip + activePlotMargins[1] + 5}px; max-height: ${hh}px; flex: 0 1 auto;`
            );
            chartContentElement.style.display = 'flex';
            chartContentElement.style.flexDirection = barOrientation === 'horizontal' ? 'row' : 'column';
            chartContentElement.appendChild(leftPanel);
            chartContentElement.appendChild(rightPanel);
            const leftCanvas = this.createCanvas(leftConfig, leftData, leftPanel),
                rightCanvas = this.createCanvas(rightConfig, rightData, rightPanel);
            this.chart = [
                leftChart.render(leftData, {
                    ...options,
                    canvas: leftCanvas,
                    exportable: false,
                    allowTableView: false,
                    returnData: true
                }),
                rightChart.render(rightData, {
                    ...options,
                    canvas: rightCanvas,
                    exportable: false,
                    allowTableView: false,
                    returnData: true
                })
            ];
            topWidget.setAttribute('class', topWidget.getAttribute('class').replace(/\s(placeholder)/g, ''));
            // Make a data table especially for export...
            const mergedData = [
                {
                    colIds: [...leftData[0].colIds],
                    features: [...leftData[0].features].concat([...rightData[0].features]),
                    headers: [...leftData[0].headers],
                    indicators: [...leftData[0].indicators].concat([...rightData[0].indicators]),
                    rowIds: [...leftData[0].rowIds].concat([...rightData[0].rowIds]),
                    rows: [...leftData[0].rows].concat([...rightData[0].rows])
                }
            ];
            this.applyChartActions(
                settings,
                mergedData //[...leftData, ...rightData] //this.chart.map((c) => c.data[0])
            );
            return this.chart.map((cnd) => cnd.chart);
        }
    };

    static getDefaults = () => {
        return {
            ...BarChartWidget.getDefaults(),
            seriesBindingStyle: 'features-as-series',
            plotMargins: '5px 25px 5px 25px',
            dataSplit: 'center', // Or 'alternate',
            barOrientation: 'horizontal',
            leftLabel: '',
            rightLabel: '',
            axisAlignment: 'outside', // or 'central'
            axisCentreWidth: '40px',
            xAxisLabelFormat: '#NAME',
            legendPosition: 'bottom',
            legendWeight: 5
        };
    };
}
