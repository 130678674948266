import React, { useEffect, useRef, useState, FunctionComponent } from 'react';
import { Rnd } from 'react-rnd';
import '../assets/styles/components/ModalDialog.scss';
import { isNullOrUndefined } from '../utils/object';

import '@esri/calcite-components/dist/components/calcite-button';
import '@esri/calcite-components/dist/components/calcite-modal';
import { CalciteButton, CalciteModal } from '@esri/calcite-components-react';

export type ModalDialogProps = {
    onClose?: (e: any) => void;
    show: boolean;
    scroll?: boolean;
    title?: any;
    buttons?: any[] | any;
    message?: any;
    large?: boolean;
    className?: string;
    bodyClass?: string;
    resizable?: boolean;
    draggable?: boolean;
    blocking?: boolean;
    fade?: boolean;
    autoFill?: boolean;
    helpLink?: any;
    helpIcon?: any;
    helpTarget?: string;
    kind?: 'brand' | 'danger' | 'info' | 'success' | 'warning';
    [x: string]: any;
};

const ModalDialog: FunctionComponent<ModalDialogProps> = ({
    large,
    scroll = false,
    buttons,
    show,
    onClose,
    bodyClass = 'pad-10',
    title,
    children,
    resizable,
    draggable = true,
    className,
    overflow,
    blocking = false,
    fade = true,
    autoFill = false,
    helpLink,
    helpIcon = <i aria-hidden="true" className="far fa-fw fa-question-circle"></i>,
    helpTarget = 'rbHelpWindow',
    kind,
    ...others
}: ModalDialogProps) => {
    const [bounds, setBounds] = useState({
        x: 0,
        y: 0,
        w: 300,
        h: 300
    });

    const rnd = useRef<Rnd>(null),
        container = useRef<HTMLCalciteModalElement>(null);

    useEffect(() => {
        if (rnd !== undefined && rnd.current !== undefined && rnd.current !== null)
            rnd.current.updateSize({ width: `${bounds.w}px`, height: `${bounds.h}px` });
    }, [bounds]);

    useEffect(() => {
        const handleKeyboard = (e: any) => {
                if (e.keyCode === 27 && onClose !== undefined) onClose(e);
                if (e.key === 'Tab' || e.keyCode === 9) {
                    const modal = container.current,
                        focusableElements =
                            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
                    if (modal !== undefined && modal !== null) {
                        const focusableContent =
                            modal.querySelectorAll<HTMLElement>(focusableElements);
                        if (e.shiftKey && document.activeElement === focusableContent[0]) {
                            focusableContent[focusableContent.length - 1].focus(); // add focus for the last focusable element
                            e.preventDefault();
                        } else if (
                            document.activeElement === focusableContent[focusableContent.length - 1]
                        ) {
                            focusableContent[0].focus(); // add focus for the first focusable element
                            e.preventDefault();
                        }
                    }
                }
            },
            ww = window.innerWidth,
            wh = window.innerHeight,
            containerWidth =
                autoFill && container.current !== undefined && container.current !== null
                    ? container.current.offsetWidth
                    : -1,
            dw =
                autoFill && containerWidth > 0
                    ? Math.round(containerWidth)
                    : Math.round(ww * (large ? 0.5 : 0.33)),
            dh = Math.round(wh * (large ? 0.8 : 0.5)),
            dx = Math.round((dw - ww) / 2.0),
            dy = Math.round((wh - dh) / 2.0);
        if (resizable) {
            setBounds({
                x: dx,
                y: dy,
                w: dw,
                h: dh
            });
        }
        document.addEventListener('keydown', handleKeyboard);
        if (show) {
            if (container.current !== undefined && container.current !== null) {
                const pb: any = container.current.querySelector('.modal-footer .btn-primary');
                if (!isNullOrUndefined(pb) && !isNullOrUndefined(pb.focus)) pb.focus();
                else {
                    const b: any = container.current.querySelectorAll('.modal-footer .btn')[0];
                    if (!isNullOrUndefined(b) && !isNullOrUndefined(b.focus)) b.focus();
                }
            }
        }
        return () => document.removeEventListener('keydown', handleKeyboard);
    }, [autoFill, large, resizable, show, onClose]);

    const inOut = (show ? `show ${fade ? 'in' : ''}` : '').trim(),
        modalExtraClass = !isNullOrUndefined(className) ? className : '',
        isLarge = large ? 'modal-lg' : '',
        isScroll = scroll ? 'modal-body-scrollable' : '',
        btns =
            buttons !== undefined ? (
                buttons
            ) : (
                <CalciteButton slot="primary" width="full" appearance="solid" kind="neutral">
                    Close
                </CalciteButton>
            ),
        btnSet = Array.isArray(btns)
            ? btns
                  .map((b: any, index: number) => {
                      if (isNullOrUndefined(b)) return null;
                      if (b.type !== undefined && b.type === 'button') {
                          return !isNullOrUndefined(b.props) &&
                              !isNullOrUndefined(b.props['data-dismiss']) &&
                              b.props['data-dismiss'] !== 'modal' ? (
                              <span style={{ marginLeft: '0.2em' }} key={index}>
                                  {b}
                              </span>
                          ) : (
                              <span style={{ marginLeft: '0.2em' }} onClick={onClose} key={index}>
                                  {b}
                              </span>
                          );
                      } else {
                          return b.type !== undefined && (b.type === 'span' || b.type === 'div') ? (
                              <React.Fragment key={index}>{b}</React.Fragment>
                          ) : (
                              <span style={{ marginLeft: '0.2em' }} key={index}>
                                  {b}
                              </span>
                          );
                      }
                  })
                  .filter((b: any) => b !== null)
            : btns,
        contentBlock = (
            <>
                {title !== undefined && title !== null ? (
                    <div slot="header" className="header-container">
                        <span>{title}</span>
                        {!isNullOrUndefined(helpLink) && helpLink !== '' && (
                            <CalciteButton
                                kind="neutral"
                                appearance="transparent"
                                href={helpLink}
                                target={helpTarget}
                                rel="no-follow"
                                className="nodef help-link"
                                iconStart="question"
                            ></CalciteButton>
                        )}
                    </div>
                ) : null}
                <div
                    slot="content"
                    className={`modal-body ${isScroll} ${bodyClass}`.trim()}
                    style={{
                        overflow: overflow
                    }}
                >
                    {children}
                </div>
                {btnSet}
            </>
        );
    return !show ? null : (
        <>
            <CalciteModal
                ref={container}
                className={`modal ${fade ? 'fade' : ''} ${modalExtraClass} ${inOut}`}
                tabIndex={-1}
                role="dialog"
                open={show ? true : undefined}
                width={others.width !== undefined ? others.width : isLarge ? 'm' : 's'}
                onCalciteModalClose={onClose}
                kind={kind}
            >
                {resizable && false ? (
                    <Rnd
                        ref={rnd}
                        default={{
                            x: bounds.x,
                            y: bounds.y,
                            width: `${bounds.w}px`,
                            height: `${bounds.h}px`
                        }}
                        dragHandleClassName="modal-header"
                        {...others}
                    >
                        {contentBlock}
                    </Rnd>
                ) : draggable && false ? (
                    <Rnd
                        ref={rnd}
                        enableResizing={{
                            top: false,
                            right: false,
                            bottom: false,
                            left: false,
                            topRight: false,
                            bottomRight: false,
                            bottomLeft: false,
                            topLeft: false
                        }}
                        className="drag-only"
                        dragHandleClassName="modal-header"
                        {...others}
                    >
                        {contentBlock}
                    </Rnd>
                ) : (
                    contentBlock
                )}
            </CalciteModal>
        </>
    );
};

export default ModalDialog;
