import AbstractWidget, { lengthWithUnit } from './AbstractWidget';
import { TextWidget } from './TextWidget';
import { isNullOrEmpty, isNullOrWhitespace } from '../utils/object';

export default class AbstractBoxedWidget extends AbstractWidget {
    renderBox = (
        data,
        options = {
            element: 'div',
            showTitle: true,
            titleStyle: '',
            messagePadType: 'margin',
            allowBackgroundFill: true,
            settings: {}
        }
    ) => {
        if (this.container !== undefined && this.container !== null) {
            let doc = this.container.ownerDocument,
                txt = doc.createTextNode(
                    `⚠️ Override this to render content for the widget [${this.design.scriptClass}] ⚠️`
                ),
                span = doc.createElement('span'),
                settings = {
                    ...AbstractBoxedWidget.getDefaults(),
                    ...this.design,
                    ...(options.settings !== undefined ? options.settings : {}) // Allow override if sub-classes have different defaults
                },
                {
                    width,
                    height,
                    borderWidth,
                    borderRadius,
                    borderStyle,
                    borderColor,
                    controlBackground,
                    titleText,
                    boxTitleCssClass,
                    titleColor,
                    titleBackground,
                    titleIsBold,
                    titleAlignment,
                    titlePadding,
                    titleFontSize,
                    messageBackground,
                    messagePadding,
                    numberFormat,
                    locale,
                    noDataText,
                    description
                } = settings,
                bordered =
                    !isNullOrWhitespace(borderStyle) &&
                    borderStyle.toLowerCase().replace(/[^a-zA-Z]/g, '') !== 'notset';
            span.setAttribute('class', 'ia-generated');
            span.appendChild(txt);
            let box = doc.createElement('div'),
                style = '',
                div,
                label;
            box.setAttribute(
                'class',
                `${options.boxClass !== undefined ? options.boxClass : 'ia-text-box'} ia-generated`.trim()
            );
            if (!isNullOrWhitespace(width)) style += 'left: 0; right: 0;'; //`width: ${settings.width};`;
            if (!isNullOrWhitespace(height)) style += 'top: 0; bottom: 0;'; //`height: ${settings.height};`;
            if (bordered) {
                if (!isNullOrWhitespace(borderWidth)) style += `border-width: ${lengthWithUnit(borderWidth)};`;
                if (!isNullOrWhitespace(borderRadius)) style += `border-radius: ${lengthWithUnit(borderRadius)};`;
                style += `border-style: ${borderStyle.toLowerCase()};`;
            }
            // Force a background that matches border to avoid CSS oddness
            if (bordered && !options.allowBackgroundFill) style += `border-color: ${borderColor};`;
            else if (bordered) style += `border-color: ${borderColor}; background-color: ${borderColor};`;
            //if (!isNullOrWhitespace(messageBackground)) style += `background-color: ${borderColor};`;
            if (!isNullOrWhitespace(controlBackground)) style += `background: ${controlBackground};`;
            if (style.length > 0) box.setAttribute('style', style);
            if (!isNullOrWhitespace(titleText) && options.showTitle) {
                div = doc.createElement('div');
                div.setAttribute('class', `ia-text-box-title ${boxTitleCssClass}`.trim());
                style = '';
                if (!isNullOrWhitespace(titleColor)) style += `color: ${titleColor};`;
                if (!isNullOrWhitespace(titleBackground)) style += `background: ${titleBackground};`;
                if (titleIsBold) style += 'font-weight: bold;';
                if (!isNullOrWhitespace(titleFontSize)) style += `font-size: ${lengthWithUnit(titleFontSize)};`;
                if (titleAlignment !== 'auto' && titleAlignment !== '')
                    style += `text-align: ${titleAlignment.toLowerCase()};`;
                if (!isNullOrWhitespace(borderRadius))
                    style += `border-top-left-radius: ${lengthWithUnit(
                        borderRadius
                    )}; border-top-right-radius: ${lengthWithUnit(borderRadius)};`;
                if (options.titleStyle !== undefined) style += options.titleStyle; // Hard override
                if (style.length > 0) div.setAttribute('style', style);
                label = doc.createElement('div');
                label.setAttribute('id', `w${settings.id}_titleContent`);
                style = '';
                if (!isNullOrWhitespace(titlePadding))
                    style += `padding: ${lengthWithUnit(titlePadding, 'px', true, 4, true)};`;
                if (style.length > 0) label.setAttribute('style', style);
                let htmlTitleFragment = TextWidget.insertValuesIntoText(
                    titleText,
                    data,
                    numberFormat,
                    locale,
                    noDataText
                );
                if (htmlTitleFragment !== null && htmlTitleFragment.trim().substring(0, 1) === '<')
                    label.innerHTML = htmlTitleFragment.replace('<>', '').replace('</>', '');
                // Allow empty enclosers, React-style
                else label.appendChild(doc.createTextNode(htmlTitleFragment !== null ? htmlTitleFragment : ''));
                div.appendChild(label);
                box.appendChild(div);
            }
            div = doc.createElement(options.element || 'div');
            div.setAttribute('class', `ia-icon-container ia-generated`);
            style = '';
            if (messagePadding !== undefined && messagePadding !== null)
                style += `${options.messagePadType || 'margin'}: ${lengthWithUnit(messagePadding, 'px', true, 4)};`; // Margin - reverse padding - makes layout of children easier
            if (!isNullOrWhitespace(messageBackground)) style += `background-color: ${messageBackground};`;
            if (style.length > 0) div.setAttribute('style', style);
            box.appendChild(div);
            if (description !== undefined && description !== null && !isNullOrEmpty(description)) {
                const dd = doc.createElement('div');
                dd.setAttribute('class', `ia-widget-description ia-generated`);
                const htmlFragment = TextWidget.insertValuesIntoText(
                    description,
                    data,
                    numberFormat,
                    locale,
                    noDataText
                );
                if (htmlFragment !== null && htmlFragment.substring(0, 1) === '<')
                    dd.innerHTML = htmlFragment.replace('<>', '').replace('</>', '');
                // Allow empty enclosers, React-style
                else dd.appendChild(doc.createTextNode(htmlFragment !== null ? htmlFragment : ''));
                if (!isNullOrWhitespace(messageBackground)) dd.style.backgroundColor = messageBackground;
                box.appendChild(dd);
            }
            this.container.appendChild(box);
            return div;
        }
    };

    static getDefaults = () => {
        return {
            ...AbstractWidget.getDefaults(),
            borderRadius: '2px',
            borderColor: '',
            borderStyle: 'none',
            borderWidth: '2px',
            messageBackground: 'transparent',
            messagePadding: '10px',
            titleAlignment: 'auto',
            titleIsBold: true,
            titlePadding: '5px',
            titleText: '',
            titleBackground: '',
            titleColor: ''
        };
    };
}
