export const lowerKeys = (o: any): { [id: string]: any } => {
    const low = { ...o };
    Object.keys(o).reduce((c, k) => {
        c[k.toLowerCase()] = o[k];
        return c;
    }, low);
    return low;
};

export const lowerKeyParams = (o: URLSearchParams): { [id: string]: any } => {
    const low = {};
    for (const key of Array.from(o.keys())) {
        low[key.toLowerCase()] = o.get(key);
    }
    return low;
};

export const isNullOrEmpty = (text: string | undefined | null): boolean => {
    return text === undefined || text === null || text === '';
};

export const isNullOrWhitespace = (text: string | undefined | null): boolean => {
    return text === undefined || text === null || text.trim() === '';
};

export const isNullOrUndefined = (obj: any): boolean => {
    return obj === undefined || obj === null;
};

export const isEmpty = (obj: object): boolean => {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
};

export const hyphenate = (
    camelCaseText: string | undefined | null,
    upperCaseRegex = /([A-Z])/,
    lowerCase = true,
    hyphen = '-'
): string | undefined => {
    if (camelCaseText !== undefined && camelCaseText !== null) {
        const tokens = camelCaseText.split(upperCaseRegex);
        for (let i = 0; i < tokens.length; i++) {
            if (upperCaseRegex.test(tokens[i])) tokens[i] = `${hyphen}${tokens[i]}`;
        }
        if (lowerCase) {
            const lowered = tokens.join('').toLowerCase();
            return lowered.substring(0, 1) === hyphen && camelCaseText.substring(0, 1) !== hyphen
                ? lowered.substring(1)
                : lowered;
        } else return tokens.join('');
    } else return undefined;
};

export const parseQueryToObject = (query: string): { [key: string]: string } => {
    const qs = new URLSearchParams(query),
        pairs: { [key: string]: string } = {};
    qs.forEach((v: string, k: string) => {
        pairs[k] = v;
    });
    return pairs;
};
