import AbstractWidget from './AbstractWidget';
import { TextWidget } from './TextWidget';

export default class ImageWidget extends AbstractWidget {
    constructor(containerHtmlElement, design) {
        // Some old-to-new mappings...
        super(containerHtmlElement, {
            ...design,
            url: design.url || design.text
        });
    }

    // Render here is slightly special, because it can _detect_ if the calling (React) class has laid out some of the HTML before calling this method.
    render = (data, options = {}) => {
        if (
            this.container !== undefined &&
            this.container !== null &&
            this.design !== undefined &&
            this.design !== null
        ) {
            const doc = this.container.ownerDocument,
                topWidget = this.container.closest('.ia-report-widget'),
                { locale, numberFormat, noDataText, lockAspectRatio = false, alternateText } = this.design,
                { portalUrl, token } = options;
            let img = this.container.querySelector('img'),
                link = this.container.querySelector('a'),
                srcUrl = this.design.url || this.design.text, // Text is legacy, will be replaced as time goes on!
                linkUrl = this.design.hyperlinkUrl,
                altText;
            if (
                srcUrl !== undefined &&
                srcUrl !== null &&
                data !== undefined &&
                data !== null &&
                Array.isArray(data) &&
                data.length > 0
            ) {
                srcUrl = TextWidget.insertValuesIntoText(srcUrl, data, numberFormat, locale, noDataText, false); //.text;
            }
            if (
                srcUrl !== undefined &&
                srcUrl !== null &&
                portalUrl !== undefined &&
                portalUrl !== null &&
                token !== undefined &&
                token !== null &&
                (srcUrl.indexOf(portalUrl) >= 0 || srcUrl.indexOf('.arcgis.com/') >= 0)
            ) {
                srcUrl = `${srcUrl}${srcUrl.indexOf('?') > 0 ? '&' : '?'}&token=${token}`;
            }
            if (linkUrl !== undefined) {
                linkUrl = TextWidget.insertValuesIntoText(linkUrl, data, numberFormat, locale, noDataText, false);
            }
            if (alternateText !== undefined) {
                altText = TextWidget.insertValuesIntoText(alternateText, data, numberFormat, locale, noDataText, false);
            }
            if (link !== undefined && link !== null) link.setAttribute('href', linkUrl);
            if (img !== undefined && img !== null) {
                img.setAttribute('src', srcUrl);
                img.setAttribute('class', `ia-generated aspect-ratio-${lockAspectRatio ? 'locked' : 'dynamic'}`);
            } else {
                img = doc.createElement('img');
                img.setAttribute('class', `ia-generated aspect-ratio-${lockAspectRatio ? 'locked' : 'dynamic'}`);
                img.setAttribute('src', srcUrl);
                if (linkUrl !== undefined) {
                    link = doc.createElement('a');
                    link.setAttribute('class', 'ia-generated');
                    link.setAttribute('href', linkUrl);
                    if (this.design.target !== undefined && this.design.target !== null)
                        link.setAttribute('target', this.design.target);
                    link.appendChild(img);
                    this.container.appendChild(link);
                } else this.container.appendChild(img);
            }
            if (altText !== undefined) {
                img.setAttribute('alt', altText.replace(/["]/g, '').trim());
            }
            if (topWidget !== null)
                topWidget.setAttribute('class', topWidget.getAttribute('class').replace(/\s(placeholder)/g, ''));
        }
    };
}
