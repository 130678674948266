import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isNullOrUndefined, parseQueryToObject } from '../utils/object';
import { getMessage, getNumberFormatter } from '../utils/localization';

import '@esri/calcite-components/dist/components/calcite-link';
import { CalciteLink } from '@esri/calcite-components-react';

const isMatchForNameOrCode = (ii, key) => {
    const match =
        (!isNullOrUndefined(ii.id) && ii.id === key) ||
        (!isNullOrUndefined(ii.name) && ii.name === key) ||
        (!isNullOrUndefined(ii.fullName) && ii.fullName === key) ||
        (!isNullOrUndefined(ii.shortName) && ii.shortName === key) ||
        key === '*'; // Allow a wildcard
    return match;
};

export const adjustForLimits = (chartDataItems = [], { upperAndLowerLimitKeys = '' }, nameLookupStyle = 'full') => {
    const filteredChartDataItems = [],
        limitIndices = [],
        limitSets = parseQueryToObject(upperAndLowerLimitKeys);
    let isrc, iup, ilo, pair, calc, isPattern;
    for (let key in limitSets) {
        isrc = chartDataItems.filter((ii) => isMatchForNameOrCode(ii, key));
        isPattern = limitSets[key].indexOf('pattern:') === 0;
        if (isrc !== undefined && !isNullOrUndefined(limitSets[key])) {
            for (let idi of isrc) {
                pair = (isPattern ? limitSets[key].substring('pattern:'.length) : limitSets[key]).split(',');
                if (pair.length > 0) {
                    // Special - one value means "confidence" which is presumed to be a % of the actual value...
                    if (pair.length === 1) {
                        // Allow for more arguments
                        pair = pair[0].split(':');
                        // P = percentage, A = absolute value, F = formula or calculation (built in), R = range (i.e. upper and lower are half this away from value)
                        calc = pair.length > 1 ? pair[0].toUpperCase() : 'P';
                        const ikey = isPattern
                            ? (pair.length > 1 ? pair[1] : pair[0])
                                  .replace(
                                      /#INAME/gi,
                                      nameLookupStyle === 'full' ? idi.fullName || idi.name : idi.shortName
                                  )
                                  .replace(/#ICODE/gi, idi.id)
                            : pair.length > 1
                            ? pair[1]
                            : pair[0];
                        ilo = chartDataItems.findIndex((ii) => isMatchForNameOrCode(ii, ikey));
                        if (ilo >= 0 && !isNullOrUndefined(idi.data.rows)) {
                            let v, vc;
                            for (let i = 0; i < idi.data.rows.length; i++) {
                                for (let j = 1; j < idi.data.rows[i].length; j++) {
                                    v = idi.data.rows[i][j];
                                    vc = chartDataItems[ilo].data.rows[i][j];
                                    if (isNumeric(v) && isNumeric(vc)) {
                                        vc = calc === 'R' ? vc * 0.5 : calc === 'A' ? vc : (vc / 100.0) * v;
                                        idi.data.rows[i][j] = {
                                            y: v,
                                            yMax: v + vc,
                                            yMin: v - vc
                                        };
                                    } else if (isNumeric(v)) {
                                        idi.data.rows[i][j] = {
                                            y: v
                                        };
                                    }
                                }
                            }
                            limitIndices.push(ilo);
                            //chartDataItems.splice(ilo, 1);
                        }
                    } else {
                        if (isPattern) {
                            pair[0] = pair[0]
                                .replace(
                                    /#INAME/gi,
                                    nameLookupStyle === 'full' ? idi.fullName || idi.name : idi.shortName
                                )
                                .replace(/#ICODE/gi, idi.id);
                            pair[1] = pair[1]
                                .replace(
                                    /#INAME/gi,
                                    nameLookupStyle === 'full' ? idi.fullName || idi.name : idi.shortName
                                )
                                .replace(/#ICODE/gi, idi.id);
                        }
                        const [lo, hi] = pair;
                        ilo = chartDataItems.findIndex((ii) => isMatchForNameOrCode(ii, lo));
                        iup = chartDataItems.findIndex((ii) => isMatchForNameOrCode(ii, hi));
                        if (ilo >= 0 && iup >= 0) {
                            let v, vlo, vup;
                            for (let i = 0; i < idi.data.rows.length; i++) {
                                for (let j = 1; j < idi.data.rows[i].length; j++) {
                                    v = idi.data.rows[i][j];
                                    vlo = chartDataItems[ilo].data.rows[i][j];
                                    vup = chartDataItems[iup].data.rows[i][j];
                                    if (isNumeric(v) && isNumeric(vlo)) {
                                        idi.data.rows[i][j] = {
                                            y: v,
                                            yMin: vlo,
                                            yMax: vup
                                        };
                                    } else if (isNumeric(v)) {
                                        idi.data.rows[i][j] = {
                                            y: v
                                        };
                                    }
                                }
                            }
                            //chartDataItems.splice(ilo, 1);
                            //iup = chartDataItems.findIndex(ii => isMatchForNameOrCode(ii, pair[1]));
                            //for (let i = 0; i < idi.data.rows.length; i++)
                            //{
                            //    for (let j = 1; j < idi.data.rows[i].length; j++)
                            //    {
                            //        v = idi.data.rows[i][j];
                            //        vlo = chartDataItems[iup].data.rows[i][j];
                            //        if (isNumeric(v) && isNumeric(vlo)) idi.data.rows[i][j].yMax = vlo;
                            //    }
                            //}
                            //chartDataItems.splice(iup, 1);
                            limitIndices.push(ilo);
                            limitIndices.push(iup);
                        }
                    }
                }
            }
        }
    }
    for (let i = 0; i < chartDataItems.length; i++) {
        if (limitIndices.indexOf(i) < 0) {
            filteredChartDataItems.push(chartDataItems[i]);
        }
    }
    return filteredChartDataItems;
};

const isNumeric = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
};

export const createIndicatorRowTable = (tables) => {
    // To avoid the ugly presumption that all have same time series...
    // Make some key-value pairs... and a lookup for them
    const compositeHeaders = [],
        compositeCols = [];
    for (let t of tables) {
        t.data.pairedRows = [];
        for (let j = 0; j < t.data.rows.length; j++) {
            const fid = t.data.rowIds[j],
                feature = t.data.features.find((f) => f.id === fid),
                lookup = {
                    __featureId: fid,
                    __isComp: feature.comparison
                };
            for (let h = 0; h < t.data.headers.length; h++) {
                const hkey = t.data.headers[h].trim();
                lookup[hkey] = t.data.rows[j][h];
                if (compositeHeaders.indexOf(hkey) < 0) {
                    compositeHeaders.push(hkey);
                    t.data.colIds[h].headerKey = hkey;
                    t.data.colIds[h].tempIndex = compositeCols.length;
                    compositeCols.push(t.data.colIds[h]);
                }
            }
            t.data.pairedRows.push(lookup);
        }
        t.data.pairedRows.sort((a, b) => {
            if (a.__isComp && !b.__isComp) return 1;
            if (!a.__isComp && b.__isComp) return -1;
            return 0;
        });
    }
    // Sort these, or we end up with a mess...
    compositeCols.sort((a, b) => {
        if (a.iid === 'name') return -1; // Always 1st...
        if (b.iid === 'name') return 1; // Always 1st...
        if (a.date !== undefined && b.date !== undefined) return a.date - b.date; // By date if there...
        if (a.index !== undefined && b.index !== undefined) return a.index - b.index; // By index or order if not...
        if (a.tempIndex !== undefined && b.tempIndex !== undefined) return a.tempIndex - b.tempIndex; // By the original ordering? Could be messy...
        return a.label.localeCompare(b.label);
    });
    tables[0].data.headers = compositeCols.map((cc) => cc.headerKey);
    tables[0].data.colIds = compositeCols;
    tables[0].data.rows = [];
    tables[0].data.rowIds = [];
    for (let comp of [false, true]) {
        for (let t of tables) {
            for (let pr of t.data.pairedRows.filter((r) => r.__isComp === comp)) {
                const r = [];
                for (let h of compositeCols) {
                    r.push(pr[h.headerKey]);
                }
                tables[0].data.rows.push(r);
                tables[0].data.rowIds.push(pr.__featureId);
            }
        }
    }
    return tables.slice(0, 1);
};

export const createIndicatorColumnTable = (tables) => {
    for (let i = 1; i < tables.length; i++) {
        for (let j = 0; j < tables[0].data.rows.length; j++) {
            tables[0].data.rows[j] = tables[0].data.rows[j].concat(tables[i].data.rows[j].slice(1));
        }
        tables[0].data.headers = tables[0].data.headers.concat(tables[i].data.headers.slice(1));
        tables[0].data.colIds = tables[0].data.colIds.concat(tables[i].data.colIds.slice(1));
    }
    return tables.slice(0, 1);
};

export const createIndicatorDatesRowTables = (table) => {
    //if (table.rows.length > 1) throw new Error('Cannot split table by dates if has more than one row - split the table first');
    const dates = [],
        dateTables = [];
    let at = 0;
    for (let c of table.colIds) {
        // exclude numerator/denominator
        let lbl = c.label || c.date;
        if (at > 0 && c.type === 'value' && lbl !== undefined && dates.indexOf(lbl) < 0) dates.push(lbl);
        c.index = at;
        at++;
    }
    // Common (date) labels, now split the tables
    for (let d of dates) {
        const colSet = table.colIds.filter((c) => c.index === 0 || (c.type === 'value' && c.label === d)),
            activeTable = {
                colIds: [...colSet],
                headers: table.headers.filter((c, i) => i === 0 || c === d),
                indicators: [...table.indicators.filter((ii) => colSet.findIndex((ci) => ci.iid === ii.id) >= 0)],
                features: [...table.features],
                rows: [],
                rowIds: [],
                label: d
            };
        for (let i = 1; i < activeTable.colIds.length; i++) {
            const ai = findIndicator(activeTable.indicators, activeTable.colIds[i].iid);
            if (ai !== undefined) activeTable.headers[i] = ai.name;
        }
        for (let i = 0; i < table.rows.length; i++) {
            activeTable.rows.push([]);
            activeTable.rowIds.push(table.rowIds[i]);
            for (let c of colSet) {
                activeTable.rows[i].push(table.rows[i][c.index]);
            }
            activeTable.rows[i][0] = {
                name: activeTable.rows[i][0],
                date: d
            };
        }
        dateTables.push(activeTable);
    }
    return dateTables;
};

/**
 * Split a table into a set of single indicator tables, where dates are the columns.
 * This presumes that all indicators have the same (or at least a lot in common) dates or results will be ugly.
 * @param {*} table
 */
export const createIndicatorDatesColumnTables = (table, fillEmptyValuesWith = 0, sortDates = 'no') => {
    //if (table.rows.length > 1) throw new Error('Cannot split table by dates if has more than one row - split the table first');
    const dates = [],
        indicatorTables = [];
    let at = 0;
    for (let c of table.colIds) {
        // exclude numerator/denominator
        let lbl = c.label || c.date;
        if (at > 0 && c.type === 'value' && lbl !== undefined && dates.indexOf(lbl) < 0) dates.push(lbl);
        c.index = at;
        at++;
    }
    if (sortDates.toLowerCase() === 'ascending') dates.sort();
    else if (sortDates.toLowerCase() === 'descending') dates.sort().reverse();
    // Common (date) labels, now split the tables
    for (let ai of table.indicators) {
        const colSet = table.colIds.filter((c) => c.index === 0);
        // Now push dates...
        for (let d of dates) {
            let mc = table.colIds.find((c) => c.type === 'value' && c.iid === ai.id && (c.label || c.date) === d);
            if (mc === undefined) {
                mc = table.colIds.find((c) => c.type === 'value' && (c.label || c.date) === d);
                if (mc === undefined) throw new Error(`Cannot find table column matching date '${d}`);
                mc = {
                    ...mc,
                    iid: ai.id,
                    index: -1 // Will make a wonky column, but that's OK
                };
            }
            colSet.push(mc);
        }
        const activeTable = {
            colIds: [...colSet],
            headers: [table.headers[0], ...dates],
            indicators: [ai],
            features: [...table.features],
            rows: [],
            rowIds: [],
            label: ai.name
        };
        //for (let i = 1; i < activeTable.colIds.length; i++)
        //{
        //const ai = findIndicator(activeTable.indicators, activeTable.colIds[i].iid);
        //if (ai !== undefined) activeTable.headers[i] = ai.name;
        //}
        for (let i = 0; i < table.rows.length; i++) {
            activeTable.rows.push([]);
            activeTable.rowIds.push(table.rowIds[i]);
            for (let c of colSet) {
                const v = table.rows[i][c.index];
                activeTable.rows[i].push(v !== undefined ? v : fillEmptyValuesWith);
            }
            activeTable.rows[i][0] = {
                feature: activeTable.rows[i][0],
                name: ai.name
            };
        }
        indicatorTables.push(activeTable);
    }
    return indicatorTables;
};

/**
 * Split a table into a set of single indicator/date tables, with one value (date) column each.
 * @param {*} singleIndicatorTable
 */
export const splitTableByIndicatorDate = (singleIndicatorTable, fillEmptyValuesWith = 0, sortDates = 'no') => {
    if (singleIndicatorTable.indicators.length > 1)
        throw new Error('Cannot split table by dates if has more than one indicator - split the table first');
    const dates = [],
        indicatorTables = [],
        ai = singleIndicatorTable.indicators[0];
    let at = 0;
    for (let c of singleIndicatorTable.colIds) {
        // exclude numerator/denominator
        let lbl = c.label || c.date;
        if (at > 0 && c.type === 'value' && lbl !== undefined && dates.indexOf(lbl) < 0) dates.push(lbl);
        c.index = at;
        at++;
    }
    if (dates.length < 2) return [singleIndicatorTable];
    if (sortDates.toLowerCase() === 'ascending') dates.sort();
    else if (sortDates.toLowerCase() === 'descending') dates.sort().reverse();
    // Common (date) labels, now split the tables
    for (let d of dates) {
        const colSet = singleIndicatorTable.colIds.filter((c) => c.index === 0);
        // Now push dates...
        let mc = singleIndicatorTable.colIds.find(
            (c) => c.type === 'value' && c.iid === ai.id && (c.label || c.date) === d
        );
        if (mc === undefined) {
            mc = singleIndicatorTable.colIds.find((c) => c.type === 'value' && (c.label || c.date) === d);
            if (mc === undefined) throw new Error(`Cannot find table column matching date '${d}`);
            mc = {
                ...mc,
                iid: ai.id,
                index: -1 // Will make a wonky column, but that's OK
            };
        }
        colSet.push(mc);
        const activeTable = {
            colIds: [...colSet],
            headers: [singleIndicatorTable.headers[0], ...dates],
            indicators: [ai],
            features: [...singleIndicatorTable.features],
            rows: [],
            rowIds: [],
            label: `${ai.name} ${d}`,
            date: d
        };
        //for (let i = 1; i < activeTable.colIds.length; i++)
        //{
        //const ai = findIndicator(activeTable.indicators, activeTable.colIds[i].iid);
        //if (ai !== undefined) activeTable.headers[i] = ai.name;
        //}
        for (let i = 0; i < singleIndicatorTable.rows.length; i++) {
            activeTable.rows.push([]);
            activeTable.rowIds.push(singleIndicatorTable.rowIds[i]);
            for (let c of colSet) {
                const v = singleIndicatorTable.rows[i][c.index];
                activeTable.rows[i].push(v !== undefined ? v : fillEmptyValuesWith);
            }
            activeTable.rows[i][0] = singleIndicatorTable.rows[i][0];
        }
        indicatorTables.push(activeTable);
    }
    return indicatorTables;
};

export const findFeature = (featureList, id) => {
    return featureList.find((f) => f.id === id);
};

export const findIndicator = (indicatorList, id) => {
    return indicatorList.find((f) => f.id === id);
};

export const splitTableByFeatureType = (table) => {
    // Assumes rows are features
    const ff = table.features.filter((f) => !f.comparison),
        cf = table.features.filter((f) => f.comparison === true),
        split = [],
        ffids = ff.map((f) => f.id),
        cfids = cf.map((f) => f.id);
    if (cf.length < 1) return [table, {}];
    let rowIds, rows;
    rows = [];
    rowIds = [];
    for (let i = 0; i < table.rows.length; i++) {
        if (ffids.indexOf(table.rowIds[i]) >= 0) {
            rows.push([...table.rows[i]]);
            rowIds.push(table.rowIds[i]);
        }
    }
    split[0] = {
        headers: [...table.headers],
        colIds: [...table.colIds],
        indicators: [...table.indicators],
        features: ff,
        rows: [...rows],
        rowIds: [...rowIds]
    };
    rows = [];
    rowIds = [];
    for (let i = 0; i < table.rows.length; i++) {
        if (cfids.indexOf(table.rowIds[i]) >= 0) {
            rows.push([...table.rows[i]]);
            rowIds.push(table.rowIds[i]);
        }
    }
    split[1] = {
        headers: [...table.headers],
        colIds: [...table.colIds],
        indicators: [...table.indicators],
        features: cf,
        rows: [...rows],
        rowIds: [...rowIds],
        comparisonsOnly: true
    };
    return split;
};

export const filterTableRows = (tables = [], approvedComparisonIds = []) => {
    const filtered = [],
        cleanCompIds = approvedComparisonIds.filter((i) => i !== ''),
        relsOnly =
            cleanCompIds.filter((i) => i.indexOf('relationship:') === 0).length === cleanCompIds.length &&
            cleanCompIds.length > 0;
    if (relsOnly) return tables;
    for (let table of tables) {
        // Assumes rows are features
        if (table.features !== undefined) {
            const ff = table.features.filter((f) => !f.comparison),
                cf = table.features.filter((f) => f.comparison === true),
                ffids = ff.map((f) => f.id),
                cfids = cf.map((f) => f.id);
            if (cf.length < 1) filtered.push(table);
            else {
                let rowIds, rows;
                rows = [];
                rowIds = [];
                for (let i = 0; i < table.rows.length; i++) {
                    if (ffids.indexOf(table.rowIds[i]) >= 0) {
                        rows.push([...table.rows[i]]);
                        rowIds.push(table.rowIds[i]);
                    }
                }
                for (let i = 0; i < table.rows.length; i++) {
                    if (
                        cfids.indexOf(table.rowIds[i]) >= 0 &&
                        (approvedComparisonIds.indexOf(table.rowIds[i]) >= 0 ||
                            approvedComparisonIds.indexOf(`#${table.rowIds[i]}`) >= 0)
                    ) {
                        rows.push([...table.rows[i]]);
                        rowIds.push(table.rowIds[i]);
                        ff.push(cf.find((f) => f.id === table.rowIds[i]));
                    }
                }
                filtered.push({
                    headers: [...table.headers],
                    colIds: [...table.colIds],
                    indicators: [...table.indicators],
                    features: ff,
                    rows: [...rows],
                    rowIds: [...rowIds],
                    comparisonsOnly: false
                });
            }
        }
    }
    return filtered;
};

export const buildHtmlFromData = async (
    data = [],
    numberFormat = 'G',
    locale = 'en',
    flattenHeaders = true,
    includeHyperlinks = true,
    discardExtraFeatures = false,
    buttonSlot = 'back',
    csvHeaderFormat = 'name' // 'full-name' | 'name'
) => {
    try {
        const dataApi = await import('data-catalog-js-api');
        const valueFormatter = getNumberFormatter(locale),
            lang = locale.toLowerCase().split('-')[0],
            excelLikelySemi = lang === 'de' || lang === 'es' || lang === 'fr' || lang === 'it',
            valueDelimiter = excelLikelySemi ? ';' : ',',
            mergedTable = dataApi.DataManipulator.mergeTables(data, discardExtraFeatures), // Discard non-common features? unless they are comparisons
            nf = getNumberFormatter(locale, numberFormat),
            tableHeaders = mergedTable.colIds.map((c, i) => {
                const h = mergedTable.headers[i],
                    ind = c.iid !== undefined ? mergedTable.indicators.find((ii) => ii.id === c.iid) : null;
                return !isNullOrUndefined(ind) ? (
                    <th key={i}>
                        <span className="rb-indicator-label" data-indicator-name={ind.name}>
                            {flattenHeaders && ind.name !== h ? `${ind.name} | ` : ''}
                            {h}{' '}
                            {includeHyperlinks && (
                                <a href={`?#metadata:${ind.id}`}>
                                    <i aria-hidden="true" className="fas fa-fw fa-info-circle"></i>
                                    <span className="sr-only">Metadata link for {ind.name}</span>
                                </a>
                            )}
                        </span>
                    </th>
                ) : (
                    <th key={i}>{h}</th>
                );
            }),
            hardRowHeader =
                mergedTable.rows.length === 1 &&
                !isNullOrUndefined(mergedTable.features) &&
                mergedTable.features.length === 1
                    ? mergedTable.features[0].name
                    : null,
            tableRows = mergedTable.rows.map((r, i) => {
                const ind =
                    mergedTable.rowIds[i] !== undefined && mergedTable.rowIds[i].iid !== undefined
                        ? mergedTable.indicators.find((ii) => ii.id === mergedTable.rowIds[i].iid)
                        : null;
                const cells = r.map((c, j) => {
                    const cv =
                        j === 0 && !isNullOrUndefined(c)
                            ? c.toString()
                            : !isNullOrUndefined(c) && c.yMin !== undefined && c.yMax !== undefined
                            ? `${nf.format(c.y)} [${nf.format(c.yMin)}↔${nf.format(c.yMax)}]`
                            : !isNullOrUndefined(c) && (c.y !== undefined || c.x !== undefined)
                            ? `${nf.format(c.y)}`
                            : !isNullOrUndefined(c)
                            ? nf.format(c, { strict: true })
                            : '';
                    if (typeof cv !== 'string') console.log(cv); // DEBUG
                    return !isNullOrUndefined(ind) && j === 0 ? (
                        <th key={j}>
                            <span
                                // className="pure-tip pure-tip-bottom"
                                data-full-indicator={!isNullOrUndefined(ind.date) ? ind.date : ind.name}
                            >
                                {ind.name}{' '}
                                {includeHyperlinks && (
                                    <a href={`?#metadata:${ind.id}`}>
                                        <i aria-hidden="true" className="fas fa-fw fa-info-circle"></i>
                                        <span className="sr-only">Metadata link for {ind.name}</span>
                                    </a>
                                )}
                            </span>
                        </th>
                    ) : j === 0 && hardRowHeader !== null ? (
                        <th key={j}>{hardRowHeader}</th>
                    ) : (
                        <td key={j}>{cv}</td>
                    );
                });
                return (
                    <tr key={i} data-row-id={ind !== null ? ind.id : mergedTable.rowIds[i]}>
                        {cells}
                    </tr>
                );
            }),
            table = (
                <table className="table table-striped indicator-table">
                    <thead>
                        <tr>{tableHeaders}</tr>
                    </thead>
                    <tbody>{tableRows}</tbody>
                </table>
            ),
            csvHeaders = [
                '"ID"',
                ...mergedTable.colIds.map((c, i) => {
                    const h = mergedTable.headers[i],
                        ind = c.iid !== undefined ? mergedTable.indicators.find((ii) => ii.id === c.iid) : null;
                    return !isNullOrUndefined(ind) && csvHeaderFormat === 'full-name' && ind.fullName !== undefined
                        ? `"${ind.fullName} | ${h}"`
                        : !isNullOrUndefined(ind) && ind.name !== h
                        ? `"${ind.name} | ${h}"`
                        : `"${h}"`;
                })
            ].join(valueDelimiter),
            csvRows = mergedTable.rows
                .map((r, i) => {
                    const cells = r.map((c, j) => {
                        const cs = !isNullOrUndefined(c)
                            ? typeof c === 'number'
                                ? valueFormatter.format(c)
                                : c.toString()
                            : c;
                        return !isNullOrUndefined(cs) && (cs.indexOf(valueDelimiter) >= 0 || j === 0)
                            ? `"${cs}"`
                            : (!isNullOrUndefined(c)
                                  ? c.yMin !== undefined && c.yMax !== undefined
                                      ? `"${valueFormatter.format(c.y)} [${valueFormatter.format(
                                            c.yMin
                                        )},${valueFormatter.format(c.yMax)}]"`
                                      : !isNullOrUndefined(c) && c.y !== undefined
                                      ? valueFormatter.format(c.y)
                                      : cs
                                  : ''
                              ).toString();
                    });
                    return [
                        mergedTable.rowIds[i] !== undefined && mergedTable.rowIds[i].iid !== undefined
                            ? mergedTable.rowIds[i].iid
                            : mergedTable.rowIds[i],
                        ...cells
                    ].join(valueDelimiter);
                })
                .join('\n'),
            BOM = '\uFEFF',
            csvBlob = new Blob([`${BOM}${csvHeaders}\n${csvRows}`], { type: 'text/plain;charset=utf-8' }),
            csvBlobUrl = URL.createObjectURL(csvBlob),
            csvBlobFeatureName = mergedTable.rows
                .map((r, i) =>
                    r[0] !== undefined && r[0] !== null ? r[0].toLowerCase().replace(/[^0-9a-z]/g, '-') : ''
                )
                .join('-'),
            csvBlobIndName =
                mergedTable.indicators !== undefined
                    ? mergedTable.indicators.map((i) => i.name.toLowerCase().replace(/[^0-9a-z]/g, '-')).join('-')
                    : '',
            html = {
                body: table,
                button: (
                    <CalciteLink
                        href={csvBlobUrl}
                        download={`chart-data-${csvBlobFeatureName.substring(
                            0,
                            Math.min(csvBlobFeatureName.length, 30)
                        )}-${csvBlobIndName.substring(0, Math.min(csvBlobIndName.length, 30))}.csv`.replace(/--/g, '-')}
                        className="btn btn-default btn-secondary ia-chart-export-link"
                        slot={buttonSlot}
                        iconStart="download"
                    >
                        <FormattedMessage id="view.dataDialog.button.download" defaultMessage="Download" />
                    </CalciteLink>
                )
            };
        return html;
    } catch (dataErr) {
        throw dataErr;
    }
};

export const appendAlternateViewButtons = (container, links, settings = {}, data = []) => {
    if (container !== null) {
        const doc = container.ownerDocument;
        for (let el of links) {
            el.remove();
        }
        if (data.length > 0) {
            if (settings.allowTableView) {
                const dataAsText = JSON.stringify(data.map((d) => (d.data !== undefined ? d.data : d))),
                    dataBlob = new Blob([dataAsText], {
                        type: 'application/json'
                    }),
                    dataBlobUrl = URL.createObjectURL(dataBlob),
                    da = doc.createElement('a'),
                    di = doc.createElement('i'),
                    ds = doc.createElement('span'),
                    labelText =
                        settings.downloadLabel ||
                        getMessage('widget.table.button.download', settings.locale || 'en', 'Download data as CSV');
                da.setAttribute('href', `?#${dataBlobUrl}`);
                da.setAttribute('class', 'ia-chart-data-table-link pure-tip pure-tip-top-left pure-tip-chart-download');
                //da.setAttribute('style', 'position: absolute; z-index: 101; bottom: 5px; right: calc(15px + 1.4em);');
                da.setAttribute('download', 'chart-data.json');
                da.setAttribute('data-number-format', settings.numberFormat);
                da.setAttribute('data-number-locale', settings.locale);
                da.setAttribute('data-tooltip', labelText);
                di.setAttribute('class', 'fas fa-fw fa-table');
                di.setAttribute('aria-hidden', 'true');
                da.appendChild(di);
                ds.appendChild(doc.createTextNode(labelText));
                ds.setAttribute('class', 'sr-only');
                da.appendChild(ds);
                container.appendChild(da);
            }
        }
    }
};

export const nullifyEmptyValues = (chartData, asEmptyObject = false) => {
    for (let d of chartData) {
        const dataRows = d.data.rows;
        for (let r of dataRows) {
            for (let i = 0; i < r.length; i++) {
                if (r[i] === undefined || r[i] === null) r[i] = asEmptyObject ? {} : null;
            }
        }
    }
};

export const zeroEmptyValues = (chartData, asZeroYObject = false) => {
    for (let d of chartData) {
        const dataRows = d.data.rows;
        for (let r of dataRows) {
            for (let i = 0; i < r.length; i++) {
                if (r[i] === undefined || r[i] === null) r[i] = asZeroYObject ? { y: 0 } : 0;
            }
        }
    }
};

/**
 * Test if a field's type is numeric or not.
 * @param fld The field whose type needs to be tested.
 * @returns True if the field's type is numeric (for a query' arguments), false otherwise.
 */
export const isNumericField = (fld) => {
    return (
        [
            'esriFieldTypeInteger',
            'esriFieldTypeBigInteger',
            'esriFieldTypeSmallInteger',
            'esriFieldTypeDouble',
            'esriFieldTypeSingle',
            'esriFieldTypeOID'
        ].indexOf(fld.type) >= 0
    );
    /* esriFieldTypeString, esriFieldTypeDate, esriFieldTypeGeometry, esriFieldTypeBlob, esriFieldTypeGlobalID, esriFieldTypeRaster, esriFieldTypeGUID, esriFieldTypeXML */
};
