import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector, RootState } from '../redux/builderStoreHooks';
import * as reactiveUtils from '@arcgis/core/core/reactiveUtils.js';

import '@esri/calcite-components/dist/components/calcite-icon';
import '@esri/calcite-components/dist/components/calcite-notice';
import { CalciteIcon, CalciteNotice } from '@esri/calcite-components-react';

import { lengthWithUnit } from '../widgets/AbstractWidget';
import { buildDisplayText } from '../widgets/widgetHelpers';
import { isNullOrUndefined } from '../utils/object';
import '../assets/styles/components/ArcWebMap.scss';
import { FormattedMessage, useIntl } from 'react-intl';

import { checkLayerLists, rebuildMap, takeScreenshot } from './support/mapUtils';

// This is a fairly un-React-y approach to this, all interaction with the map node/DOM has to stay outside the render() cycle,
// otherwise it ends up in a mess that is difficult to debug. So, for ease of use, it's easiest to treat them as entirely separate
// and just use events and internal state to keep them synchronized...
const ArcWebMap = React.memo(
    (props: { [key: string]: any }) => {
        const mapNode = useRef<HTMLDivElement>(null),
            legendNode = useRef<HTMLDivElement>(null),
            mapView = useRef<any>(null),
            legendView = useRef<any>(null),
            rebuildPauseId = useRef<number>(-1),
            screenshotPauseId = useRef<number>(-1),
            recoveryAttempts = useRef<number>(0),
            mapScreenshot = useRef<HTMLImageElement>(null), // UseRef to avoid re-render which doesn't play nicely with the map - otherwise need many custom components
            mapScreenshotButton = useRef<HTMLAnchorElement>(null),
            //[isMounted, setIsMounted] = useState(false),
            token: string = useAppSelector((state: RootState) => state.appSettings.token),
            portalUrl: string = useAppSelector((state: RootState) => state.appSettings.portalUrl),
            //portalHome: string = useAppSelector((state: RootState) => state.appSettings.portalHome),
            portalType: string = useAppSelector((state: RootState) => state.appSettings.portalType),
            //appAuthId: string = useAppSelector((state: RootState) => state.appSettings.appAuthId),
            //appHome: string = useAppSelector((state: RootState) => state.appSettings.appHome),
            user: any = useAppSelector((state: RootState) => state.appSettings.user),
            intl = useIntl(),
            {
                aggressiveMapDestroy = false,
                attemptMapRecovery = false,
                id,
                mapBackground = '#fff',
                mapPadding = '0',
                showMapToolTips = false,
                borderStyle = 'none',
                borderRadius = '0px',
                borderWidth = '0px',
                borderColor = null,
                legend = false, // Legend may influence the layout if it is requested
                legendAnchor = 'top-right',
                titleText = '',
                titleAlignment = 'auto',
                titleBackground = null,
                titleColor = null,
                titleIsBold = null, // boolean
                titlePadding = null,
                showExportLink = false,
                thematicApplyToLayer = false,
                allowTableView = true,
                activeFeature,
                inViewport = true,
                mapId,
                visibleLayers,
                visibleImageLayers,
                timestamp = '',
                scheduler,
                globals = {}
            } = props,
            [loading, setLoading] = useState<boolean>(true),
            [status, setStatus] = useState<'map' | 'errored' | 'image'>('map'),
            [mapError, setMapError] = useState<string | null>(null);

        useEffect(() => {
            const mn = mapNode.current,
                ln = legendNode.current,
                drawManagedByScheduler =
                    scheduler !== undefined &&
                    scheduler !== null &&
                    typeof scheduler.enqueue !== 'undefined';
            window.clearTimeout(rebuildPauseId.current);
            if (mn !== undefined && mn !== null && (inViewport || drawManagedByScheduler)) {
                const drawMapFnc = async (): Promise<any> => {
                    setLoading(true);
                    //console.log(`processing map #${id}`); // DEBUG
                    // Cleanup?
                    if (mapView.current !== undefined && mapView.current !== null)
                        mapView.current.destroy();
                    if (legendView.current !== undefined && legendView.current !== null)
                        legendView.current.destroy();
                    const {
                        view,
                        legend,
                        error = null
                    } = await rebuildMap(mn, ln, {
                        ...props,
                        disableMapAnimation: aggressiveMapDestroy, // animation and taking a screenshot are not good partners
                        forceWaitForLayersLoad:
                            props.forceWaitForLayersLoad ||
                            drawManagedByScheduler ||
                            aggressiveMapDestroy,
                        portalType,
                        portalUrl,
                        token,
                        user
                    });
                    mapView.current = view;
                    legendView.current = legend;
                    if (mapView.current !== undefined && mapView.current !== null) {
                        const screenshotAction = () => {
                                window.clearTimeout(screenshotPauseId.current);
                                screenshotPauseId.current = window.setTimeout(
                                    () => {
                                        takeScreenshot(
                                            mapView.current,
                                            mapScreenshot.current,
                                            mapScreenshotButton.current,
                                            id
                                        );
                                    },
                                    drawManagedByScheduler &&
                                        scheduler.getProperty('screenshotDelay') !== undefined &&
                                        typeof scheduler.getProperty('screenshotDelay') === 'number'
                                        ? scheduler.getProperty('screenshotDelay')
                                        : 1500
                                ); // Relatively long delay because only important if switching to print or similar
                            },
                            attachScreenshotter = () => {
                                const stopHandle = reactiveUtils.when(
                                        () => mapView.current?.stationary === true,
                                        async () => {
                                            for (
                                                let i = 0;
                                                i < mapView.current.allLayerViews.length;
                                                i++
                                            ) {
                                                await reactiveUtils.whenOnce(
                                                    () =>
                                                        mapView.current.allLayerViews.at(i) ===
                                                            undefined ||
                                                        mapView.current.allLayerViews.at(i)
                                                            .updating === false
                                                );
                                            }
                                            //console.debug(`Map #${id} stationary => screenshot`);
                                            screenshotAction();
                                        },
                                        {
                                            initial: true
                                        }
                                    ),
                                    createHandle = mapView.current.on(
                                        'layerview-create',
                                        (lyrEvent) => {
                                            console.debug(
                                                `Map #${id} layerview-create - ${lyrEvent.layer.title} is ${lyrEvent.layer.loadStatus}`
                                            );
                                            screenshotAction();
                                        }
                                    );
                                mapView.current.addHandles([stopHandle, createHandle]);
                            };
                        if (drawManagedByScheduler) {
                            await mapView.current.when();
                            attachScreenshotter();
                        } else mapView.current.when(attachScreenshotter);
                        //takeScreenshot(mapView.current, mapScreenshot.current, mapScreenshotButton.current);

                        if (aggressiveMapDestroy) {
                            await reactiveUtils.whenOnce(() => mapView.current.stationary === true);
                            const shot: any = await takeScreenshot(
                                mapView.current,
                                mapScreenshot.current,
                                mapScreenshotButton.current,
                                id
                            );
                            // If screenshot failed then leave the map as is, better than a blank screen
                            if (shot !== null) {
                                const mapContainer = mapView.current.container.parentNode;
                                if (
                                    legendView.current !== undefined &&
                                    legendView.current !== null &&
                                    props.legendAnchor !== undefined &&
                                    props.legendAnchor.indexOf('sidebar') === 0
                                ) {
                                    const legendContainer = legendView.current.container.parentNode;
                                    legendContainer.appendChild(
                                        legendView.current.container.cloneNode(true)
                                    );
                                    legendView.current.destroy(); // render once, then rely on screenshot
                                }
                                mapView.current.destroy(); // render once, then rely on screenshot
                                const lurking = mapContainer.querySelector('.ia-arc-map'),
                                    img = mapContainer.querySelector(
                                        '.ia-arc-map-screenshot > img:not(.transparent)'
                                    );
                                if (img !== undefined && img !== null) {
                                    img.parentNode.style.display = 'block';
                                    if (lurking !== undefined && lurking !== null)
                                        lurking.style.display = 'none';
                                    setStatus('image');
                                }
                            }
                        }
                        const errHandle = mapView.current.watch('fatalError', (error) => {
                            if (error) {
                                console.warn(
                                    `Map view #${id} has lost its WebGL context. Attempting to use substitute map image. Detail: ${error}`
                                );
                                if (mapView.current !== undefined && mapView.current !== null) {
                                    const mapContainer = mapView.current.container.parentNode,
                                        lurking = mapContainer.querySelector('.ia-arc-map'),
                                        mapAttribution =
                                            mapContainer.querySelector('.esri-attribution');
                                    if (attemptMapRecovery && recoveryAttempts.current < 5) {
                                        recoveryAttempts.current = recoveryAttempts.current + 1;
                                        console.debug(
                                            `Attempting recovery for view #${id}. Attempt ${recoveryAttempts.current} of 5... `
                                        );
                                        mapView.current.tryFatalErrorRecovery();
                                    } else {
                                        const img = mapContainer.querySelector(
                                            '.ia-arc-map-screenshot > img:not(.transparent)'
                                        );
                                        if (
                                            legendView.current !== undefined &&
                                            legendView.current !== null
                                        ) {
                                            const legendContainer =
                                                    legendView.current.container.parentNode,
                                                legendSnapshot =
                                                    legendView.current.container.cloneNode(true);
                                            if (
                                                props.legendAnchor !== undefined &&
                                                props.legendAnchor.indexOf('sidebar') < 0
                                            ) {
                                                if (img !== undefined && img !== null) {
                                                    const corner =
                                                        img.ownerDocument.createElement('div');
                                                    corner.setAttribute(
                                                        'class',
                                                        `esri-ui-clone ${legendContainer.getAttribute(
                                                            'class'
                                                        )}`
                                                    );
                                                    corner.appendChild(legendSnapshot);
                                                    img.parentNode.appendChild(corner);
                                                }
                                            } else legendContainer.appendChild(legendSnapshot);
                                            legendView.current.destroy(); // render once, then rely on screenshot
                                        }
                                        if (aggressiveMapDestroy) {
                                            mapView.current.destroy();
                                            if (lurking !== undefined && lurking !== null)
                                                lurking.style.display = 'none';
                                        } else {
                                            if (img !== undefined && img !== null) {
                                                img.parentNode.style.display = 'block';
                                                if (lurking !== undefined && lurking !== null)
                                                    lurking.style.display = 'none';
                                                if (
                                                    mapAttribution !== undefined &&
                                                    mapAttribution !== null
                                                )
                                                    img.parentNode.appendChild(
                                                        mapAttribution.cloneNode(true)
                                                    );
                                                setStatus('image');
                                            } else setStatus('errored');
                                        }
                                    }
                                }
                            }
                        });
                        mapView.current.addHandles(errHandle);
                        setStatus('map');
                        //console.log(`Map setup complete: ${id} ${new Date().toLocaleTimeString()}`); // DEBUG
                    } else setStatus('errored');
                    setLoading(false);
                    setMapError(error);
                    return mapView.current;
                };
                if (drawManagedByScheduler) {
                    //console.log(`queueing map #${id}`); // DEBUG
                    scheduler.dequeue(id);
                    scheduler.enqueue({
                        id,
                        func: drawMapFnc
                    });
                } else {
                    //console.log(`setTimeout map #${id}`); // DEBUG
                    rebuildPauseId.current = window.setTimeout(drawMapFnc, 50);
                }
            }
            //setIsMounted(true);
            const activeMap: any = mapView.current,
                activeLegend: any = legendView.current;
            return () => {
                if (activeMap !== undefined && activeMap !== null) activeMap.destroy();
                if (activeLegend !== undefined && activeLegend !== null) activeLegend.destroy();
            };
        }, [
            props,
            portalUrl,
            portalType,
            aggressiveMapDestroy,
            attemptMapRecovery,
            id,
            inViewport,
            token,
            user,
            mapId,
            visibleLayers,
            visibleImageLayers,
            timestamp,
            scheduler
        ]);

        /*shouldComponentUpdate(nextProps, nextState, nextContext) {
        const notMatchMap =
            this.props.map !== nextProps.map ||
            this.props.mapId !== nextProps.mapId ||
            !checkLayerLists(this.props.visibleLayers, nextProps.visibleLayers) ||
            this.props.timestamp !== nextProps.timestamp ||
            this.props.inViewport !== nextProps.inViewport;
        //console.log('map? ' + (this.props.map !== nextProps.map)); // DEBUG
        //console.log('mapId? ' + (this.props.mapId !== nextProps.mapId)); // DEBUG
        //console.log('layers? ' + (this.props.visibleLayers !== nextProps.visibleLayers)); // DEBUG
        //console.log(this.props.visibleLayers); // DEBUG
        //console.log(nextProps.visibleLayers); // DEBUG
        return notMatchMap; // || super.shouldComponentUpdate(nextProps, nextState, nextContext);
    }*/

        /*const clearMap = () => {
        if (mapView !== null) {
            for (let id of mapView.map.graphicsLayerIds.slice()) {
                mapView.map.removeLayer(mapView.map.getLayer(id));
            }
        }
    };

    const setMapExtent = (extentAsJson) => {
        if (mapView !== null) {
            loadModules(['esri/geometry/Extent']).then(([Extent]) => {
                mapView.map.goTo(new Extent(extentAsJson), {
                    animate: !prefersReducedMotion
                });
            });
        }
    };*/

        const takeMapScreenshot = () => {
            takeScreenshot(mapView.current, mapScreenshot.current, mapScreenshotButton.current, id);
        };

        const boxStyles: any = {
                borderStyle,
                borderWidth: lengthWithUnit(borderWidth),
                borderRadius: lengthWithUnit(borderRadius),
                borderColor
            },
            boxTitleStyles: any = {};
        if (!isNullOrUndefined(titlePadding))
            boxTitleStyles.padding = lengthWithUnit(titlePadding, 'px', true, 4);
        if (!isNullOrUndefined(titleBackground)) boxTitleStyles.backgroundColor = titleBackground;
        if (!isNullOrUndefined(titleColor)) boxTitleStyles.color = titleColor;
        if (
            !isNullOrUndefined(titleAlignment) &&
            titleAlignment.toLowerCase() !== '' &&
            titleAlignment.toLowerCase() !== 'auto'
        )
            boxTitleStyles.textAlign = titleAlignment.toLowerCase();
        if (!isNullOrUndefined(titleIsBold) && titleIsBold.toString().toLowerCase() === 'true')
            boxTitleStyles.fontWeight = 'bold';
        let tt = buildDisplayText(titleText, activeFeature, globals).text;
        return (
            <div
                className={`ia-text-box ia-map-box ${loading ? 'placeholder' : ''} ${
                    status !== 'map' ? status : ''
                }`.trim()}
                style={boxStyles}
            >
                {titleText !== undefined && titleText !== null && titleText !== '' ? (
                    <div
                        className="ia-text-box-title"
                        style={boxTitleStyles}
                        dangerouslySetInnerHTML={{
                            __html: tt
                        }}
                    ></div>
                ) : null}
                <div
                    className={`ia-text-box-content ia-arc-map-container ${
                        inViewport ? 'ia-showing' : ''
                    }`.trim()}
                >
                    <div className="ia-arc-map-screenshot">
                        <img
                            alt="map screenshot"
                            ref={mapScreenshot}
                            className="transparent"
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAQAAAAnOwc2AAAAD0lEQVR42mNkwAIYh7IgAAVVAAuInjI5AAAAAElFTkSuQmCC"
                        />
                    </div>
                    <div
                        ref={mapNode}
                        className="arcMap ia-arc-map"
                        style={{
                            backgroundColor: mapBackground,
                            padding: lengthWithUnit(mapPadding, 'px', true, 4)
                        }}
                    ></div>
                    {mapError !== null && (
                        <div
                            style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                pointerEvents: 'none',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-end',
                                padding: '20px'
                            }}
                        >
                            <CalciteNotice
                                open={true}
                                kind="warning"
                                icon="exclamation-mark-triangle"
                                scale="s"
                            >
                                <div slot="title">
                                    <FormattedMessage
                                        id="map.errorMessage.title"
                                        defaultMessage="Mapping error"
                                    />
                                </div>
                                <div slot="message">{mapError}</div>
                            </CalciteNotice>
                        </div>
                    )}
                    {legend && legendAnchor.indexOf('sidebar') === 0 ? (
                        <div
                            className={`ia-arc-legend ia-legend-sidebar ia-legend-${legendAnchor}`}
                        >
                            <div
                                ref={legendNode}
                                data-updated={new Date().getTime().toFixed(0)}
                            ></div>
                        </div>
                    ) : null}
                    {showMapToolTips ? (
                        <div className="ia-map-tooltip">
                            <div className="tooltip-text"></div>
                        </div>
                    ) : null}
                    {showExportLink && (
                        <a
                            ref={mapScreenshotButton}
                            href={`#${id}`}
                            className={`ia-chart-export-link ia-map-export-link ${
                                thematicApplyToLayer && allowTableView ? 'ia-chart-offset-link' : ''
                            } pure-tip pure-tip-top-left pure-tip-snapshot`}
                            download={`map${
                                activeFeature !== undefined &&
                                activeFeature !== null &&
                                Array.isArray(activeFeature) &&
                                activeFeature.length > 0 &&
                                activeFeature[0].name !== undefined &&
                                activeFeature[0].name !== null
                                    ? `-${activeFeature
                                          .map((a) => a.name)
                                          .join('-')
                                          .replace(/[^0-9a-zA-Z]/g, '-')}`
                                    : ''
                            }.png`}
                            onMouseEnter={takeMapScreenshot}
                            onFocus={takeMapScreenshot}
                            data-tooltip={intl.formatMessage({
                                id: 'widget.map.label.export',
                                defaultMessage: 'Export this map as an image'
                            })}
                        >
                            <span className="sr-only">
                                <FormattedMessage
                                    id="widget.map.label.export"
                                    defaultMessage="Export this map as an image"
                                />
                            </span>
                            <CalciteIcon icon="camera" scale="s"></CalciteIcon>
                        </a>
                    )}
                </div>
            </div>
        );
    },
    (prevProps: any, nextProps: any) => {
        const notMatchMap =
            prevProps.map !== nextProps.map ||
            prevProps.mapId !== nextProps.mapId ||
            !checkLayerLists(prevProps.visibleLayers, nextProps.visibleLayers) ||
            prevProps.timestamp !== nextProps.timestamp ||
            prevProps.inViewport !== nextProps.inViewport;
        return !notMatchMap;
    }
);

export const defaultMapSettings = {
    mapBackground: '#fff',
    webMapId: '',
    activeLayerIndex: -1,
    legend: false,
    legendAnchor: 'bottom-right',
    legendStyle: '',
    legendSize: 'small', // small|standard|large
    northArrow: 'none',
    northArrowAnchor: 'top-left',
    use3d: false,
    backgroundStars3d: false,
    atmosphere3d: false,
    //viewingMode3d: 'global',
    thematicApplyToLayer: false,
    showMapPopups: 'none',
    scalebar: 'none', // "ruler"|"line"
    scalebarAnchor: 'bottom-left',
    scalebarUnits: 'metric', // "non-metric"|"metric"|"dual"
    showZoomSlider: true,
    showPrintButton: false,
    showExportLink: false,
    zoomToActiveFeature: false,
    zoomToActiveFeatureMargin: '0',
    zoomToActivePointExtent: '1000',
    zoomToScale: -1,
    highlightSelectedFeature: false,
    highlightColor: '#ffffd7',
    //highlightOutlineWidth: '4',
    //highlightPointMarker: 'Auto',
    //highlightPointMarkerSize: '10',
    highlightType: 'Outline',
    clipVisibleLayers: false,
    clipVisibleLayersBuffer: 0,
    clipVisibleLayersStyle: 'clip', // 'clip' or 'fade'
    clipVisibleLayersType: 'contains', // spatial relationship - contains, intersects etc.
    drawClipBoundary: false,
    drawClipBoundaryColor: '#4A3875',
    includeAllAreas: true,
    indicatorAliases: null,
    locale: 'en',
    navigateOnFeatureClick: false,
    navigationLinkFormat: './#ID',
    numberFormat: '0.#',
    showMapToolTips: true,
    mapToolTipFormat: '#FNAME',
    text: null,
    titleText: null,
    thematicClassification: 'natural-breaks',
    thematicScheme: '',
    thematicSchemeType: 'high-to-low',
    thematicSchemeFlip: false,
    thematicLegendLabel: '#INAME{1} | #IDATE{1}',
    thematicNumberOfClasses: '4',
    thematicRenderType: 'color'
};

export default ArcWebMap;
