import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
//import 'pages/Manager.scss';
import ModalDialog from './ModalDialog';
import ProgressMessage from './ProgressMessage';
import { hyphenate, isNullOrUndefined } from '../utils/object';
import '@esri/calcite-components/dist/components/calcite-button';
import '@esri/calcite-components/dist/components/calcite-icon';
import '@esri/calcite-components/dist/components/calcite-link';
import { CalciteButton, CalciteIcon, CalciteLink } from '@esri/calcite-components-react';

type SimpleNamedItem = {
    id: string;
    name: string;
    url?: string;
};

const MetadataDialog = ({
    item,
    catalog,
    token,
    service,
    onSave,
    onClose,
    show,
    editable = false,
    inline = false,
    asReport = false
}: {
    item: any;
    catalog?: any;
    token?: string;
    service?: any;
    onSave?: any;
    onClose?: any;
    show?: boolean;
    editable?: boolean;
    inline?: boolean;
    asReport?: boolean;
}) => {
    const [values, setValues] = useState<any[]>([]),
        [indicator, setIndicator] = useState<SimpleNamedItem>(),
        [instance, setInstance] = useState<SimpleNamedItem>(),
        [geo, setGeo] = useState<SimpleNamedItem>(),
        [mode, setMode] = useState<'view' | 'edit'>('view'),
        [changes, setChanges] = useState<any[]>([]),
        [loading, setLoading] = useState<boolean>(false),
        [active, setActive] = useState<string | undefined>();

    const onModeChange = (evt: any, mode: 'view' | 'edit') => {
        evt.preventDefault();
        evt.stopPropagation();
        setMode(mode);
    };

    const onValueChange = (evt: any, rowId: string) => {
        evt.preventDefault();
        evt.stopPropagation();
        const active = evt.currentTarget,
            updatedChanges = [...changes];
        let i = -1;
        if (
            indicator !== undefined &&
            indicator !== null &&
            (i = changes.findIndex((c) => c.key === rowId && c.action === 'change')) < 0
        ) {
            updatedChanges.push({
                action: 'change',
                key: rowId,
                value: active.value,
                id: indicator.id,
                geo: geo !== undefined ? geo.id : null,
                instance: instance !== undefined ? instance.id : null
            });
        } else if (i >= 0) updatedChanges[i].value = active.value;
        setChanges(updatedChanges);
    };

    const onSaveClick = () => {
        if (onSave !== undefined) onSave(changes, item);
    };

    useEffect(() => {
        const metadataPromise = !isNullOrUndefined(catalog)
            ? catalog.getMetadata([item.indicator.id], true)
            : buildMetadataRequest(service, item.indicator.id, undefined, token);
        metadataPromise.then((metadataSet) => {
            const hasMetadata =
                    metadataSet !== undefined &&
                    metadataSet.features !== undefined &&
                    metadataSet.fields !== undefined,
                isInstance = !isNullOrUndefined(item.instance) && !isNullOrUndefined(item.geo),
                metaPromise = isInstance
                    ? !isNullOrUndefined(catalog)
                        ? catalog.getInstanceMetadata(
                              item.indicator.id,
                              item.instance.id,
                              item.geo.id,
                              true
                          )
                        : buildMetadataRequest(service, item.indicator.id, item.instance.id, token)
                    : Promise.resolve(null),
                bgChanges: any[] = [],
                defs = {
                    Title: isInstance ? item.instance.name : item.indicator.name,
                    IndicatorID: item.indicator.id
                };
            // Update the title if we can...
            if (
                isInstance &&
                hasMetadata &&
                !isNullOrUndefined(item.instance.name) &&
                metadataSet.features[0].attributes['Title'] !== item.instance.name
            ) {
                metadataSet.features[0].attributes['Title'] = item.instance.name;
                bgChanges.push({
                    action: 'change',
                    key: 'Title',
                    value: item.instance.name,
                    id: item.indicator.id,
                    geo: item.geo.id,
                    instance: item.instance.id
                });
            }
            metaPromise.then((instanceMetadataSet) => {
                const metadataItems: any[] = [],
                    exclusions = [
                        'objectid',
                        'themenametrail',
                        'themeidtrail',
                        'geoid',
                        'instanceid'
                    ];
                if (hasMetadata) {
                    const aliasLookup = {},
                        fieldSizes = {};
                    for (let f of metadataSet.fields) {
                        aliasLookup[f.name.toLowerCase()] = f;
                        // Dates are special...
                        if (!isNullOrUndefined(f.type) && f.type === 'esriFieldTypeDate')
                            fieldSizes[f.name.toLowerCase()] = '0000-00-00'.length;
                        else if (!isNullOrUndefined(f.length))
                            fieldSizes[f.name.toLowerCase()] = f.length;
                        else fieldSizes[f.name.toLowerCase()] = 255;
                    }
                    for (let i in metadataSet.features[0].attributes) {
                        if (exclusions.indexOf(i.toLowerCase()) < 0) {
                            let f = aliasLookup[i.toLowerCase()],
                                sz = fieldSizes[i.toLowerCase()];
                            metadataItems.push({
                                id: i,
                                label:
                                    f.alias !== undefined
                                        ? f.alias
                                        : hyphenate(i, undefined, false, ' '),
                                value:
                                    f.type === 'esriFieldTypeDate'
                                        ? new Date(metadataSet.features[0].attributes[i])
                                              .toISOString()
                                              .substring(0, 16)
                                              .replace('T', ' ')
                                              .replace('1970-01-01 00:00', '')
                                        : metadataSet.features[0].attributes[i],
                                max: sz
                            });
                        }
                    }
                    if (
                        !isNullOrUndefined(instanceMetadataSet) &&
                        !isNullOrUndefined(instanceMetadataSet.features) &&
                        instanceMetadataSet.features.length > 0
                    ) {
                        // Hard-wire the name...
                        instanceMetadataSet.features[0].attributes['Title'] = item.instance.name;
                        const removeReplaceOrAdd = (item, itemList) => {
                            if (!isNullOrUndefined(item.value)) {
                                const i = itemList.findIndex((itm) => itm.id === item.id);
                                if (i >= 0) {
                                    if (itemList[i].value !== item.value)
                                        itemList.splice(i, 1, item); // Only replace if an explicit overwrite...
                                } else itemList.push(item);
                            }
                        };
                        for (let i in instanceMetadataSet.features[0].attributes) {
                            if (exclusions.indexOf(i.toLowerCase()) < 0) {
                                let f = aliasLookup[i.toLowerCase()],
                                    sz = fieldSizes[i.toLowerCase()];
                                removeReplaceOrAdd(
                                    {
                                        id: i,
                                        label: f.alias !== undefined ? f.alias : i,
                                        value:
                                            f.type === 'esriFieldTypeDate'
                                                ? new Date(
                                                      instanceMetadataSet.features[0].attributes[i]
                                                  )
                                                      .toISOString()
                                                      .substring(0, 16)
                                                      .replace('T', ' ')
                                                      .replace('1970-01-01 00:00', '')
                                                : instanceMetadataSet.features[0].attributes[i],
                                        instance: true,
                                        max: sz
                                    },
                                    metadataItems
                                );
                            }
                        }
                    }
                } else if (catalog !== null) {
                    for (var f of catalog.meta.info.fields) {
                        if (exclusions.indexOf(f.name.toLowerCase()) < 0) {
                            metadataItems.push({
                                id: f.name,
                                label: f.alias,
                                value: defs[f.name] || ''
                            });
                        }
                    }
                    bgChanges.push({
                        action: 'change',
                        key: 'Title',
                        value: defs['Title'],
                        id: item.indicator.id
                    });
                }
                setLoading(false);
                setIndicator(item.indicator);
                setInstance(item.instance);
                setGeo(item.geo);
                setValues(metadataItems);
                setChanges(bgChanges);
            });
        });
    }, [catalog, service, item, token]);

    const rows = values
            .filter(
                (mi) =>
                    mode !== 'view' ||
                    (mi.value !== undefined && mi.value !== null && mi.value !== '')
            )
            .map((mi, index) => {
                const ac = mi.id === active ? 'active' : '',
                    mc =
                        changes.find((c) => c.id === mi.id && c.action === 'delete') !== undefined
                            ? 'marked-for-delete'
                            : '',
                    ic =
                        !isNullOrUndefined(mi.instance) && mi.instance === true
                            ? 'instance-level'
                            : '',
                    viewCell =
                        mode === 'view' &&
                        !isNullOrUndefined(mi.value) &&
                        /^(?:http|https):\/\/[\w.-]+(?:\.[\w-]+)+[\w\-.,@?^=%&:;/~\\+#]+$/.test(
                            mi.value
                        ) ? (
                            <td>
                                <CalciteLink
                                    href={mi.value}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    iconEnd="launch"
                                >
                                    {mi.value}
                                </CalciteLink>
                            </td>
                        ) : null;
                return (
                    <tr key={index} className={`${ac} ${mc} ${ic}`.trim()} data-item-id={mi['ID']}>
                        <th scope="row" data-metadata-key={mi.id}>
                            {mi.label}
                        </th>
                        {mode === 'view' ? (
                            viewCell !== null ? (
                                viewCell
                            ) : (
                                <td
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            mi.value !== null
                                                ? mi.value.replace(/,([^\s])/g, ', $1')
                                                : mi.value
                                    }}
                                ></td>
                            )
                        ) : (
                            <td>
                                {['Title', 'IndicatorID'].indexOf(mi.id) >= 0 ? (
                                    <input
                                        type="text"
                                        id={`editBox_${mi.id}`}
                                        defaultValue={mi.value}
                                        className="form-control"
                                        readOnly={true}
                                        disabled={true}
                                    />
                                ) : mi.max > 500 ? (
                                    <textarea
                                        rows={5}
                                        cols={100}
                                        id={`editBox_${mi.id}`}
                                        defaultValue={mi.value}
                                        className="form-control"
                                        onKeyUp={(e) => onValueChange(e, mi.id)}
                                        maxLength={mi.max}
                                    ></textarea>
                                ) : (
                                    <input
                                        type="text"
                                        id={`editBox_${mi.id}`}
                                        defaultValue={mi.value}
                                        className="form-control"
                                        onKeyUp={(e) => onValueChange(e, mi.id)}
                                        maxLength={mi.max}
                                    />
                                )}
                            </td>
                        )}
                    </tr>
                );
            }),
        atInstanceLevel = instance !== undefined && !isNullOrUndefined(instance.id);
    if (
        indicator !== undefined &&
        isNullOrUndefined(indicator.name) &&
        values.find((mi) => mi.id === 'Title') !== undefined
    )
        indicator.name = values.find((mi) => mi.id === 'Title').value;
    return !show ? null : inline ? (
        <table
            className={`table table-striped ia-metadata-table ${
                atInstanceLevel ? 'instance-level-metadata-table' : ''
            }`.trim()}
        >
            <thead>
                <tr>
                    <th scope="col" style={{ width: '33%' }}>
                        <CalciteLink
                            href="https://help.instantatlas.com/instantatlas-data-catalog/data-catalog-metadata/"
                            target="rbHelpWindow"
                            iconEnd="launch"
                        >
                            Key
                        </CalciteLink>
                    </th>
                    <th scope="col">Value</th>
                </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
    ) : (
        <ModalDialog
            title={
                <>
                    <CalciteIcon icon="information" scale="s"></CalciteIcon>&nbsp;
                    {atInstanceLevel ? (
                        <FormattedMessage
                            id="manager.metadataDialog.instance.title"
                            defaultMessage="{indicator} | {date} | {geo} | Metadata"
                            values={{
                                indicator: indicator !== undefined ? indicator.name : '?',
                                geo: geo !== undefined ? geo.name : '?',
                                date: instance.name
                            }}
                        />
                    ) : (
                        <FormattedMessage
                            id="manager.metadataDialog.indicator.title"
                            defaultMessage="{indicator} | Metadata"
                            values={{
                                indicator: indicator !== undefined ? indicator.name : '?'
                            }}
                        />
                    )}
                </>
            }
            buttons={
                editable ? (
                    [
                        <div className="pull-left">
                            <button
                                type="button"
                                className="btn btn-default btn-secondary"
                                data-dismiss="modal"
                                onClick={(e) => onModeChange(e, 'edit')}
                            >
                                <FormattedMessage
                                    id="manager.metadataDialog.button.edit"
                                    defaultMessage="{icon} Edit"
                                    values={{
                                        icon: (
                                            <i aria-hidden="true" className="fas fa-pencil-alt"></i>
                                        )
                                    }}
                                />
                            </button>
                        </div>,
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => onSaveClick()}
                            disabled={mode !== 'edit'}
                        >
                            <FormattedMessage
                                id="manager.metadataDialog.button.save"
                                defaultMessage="{icon} Save changes"
                                values={{
                                    icon: <i aria-hidden="true" className="fas fa-save"></i>
                                }}
                            />
                        </button>,
                        <button
                            type="button"
                            className="btn btn-default btn-secondary"
                            data-dismiss="modal"
                        >
                            <FormattedMessage
                                id="manager.metadataDialog.button.cancel"
                                defaultMessage="{icon} Cancel"
                                values={{
                                    icon: <i aria-hidden="true" className="fas fa-times"></i>
                                }}
                            />
                        </button>
                    ]
                ) : (
                    <CalciteButton
                        slot="primary"
                        onClick={onClose}
                        width="full"
                        appearance="outline"
                        iconStart="x"
                    >
                        <FormattedMessage id="metadataDialog.button.close" defaultMessage="Close" />
                    </CalciteButton>
                )
            }
            show={show}
            large={true}
            scroll={true}
            onClose={onClose}
        >
            <div>
                {loading ? (
                    <ProgressMessage message="" />
                ) : (
                    <div className={`${asReport ? 'ia-report' : ''} metadata-panel`}>
                        <div
                            className={`${asReport ? 'ia-report-section' : ''} metadata-inner`}
                            style={{ margin: '10px' }}
                        >
                            <div className="ia-table-widget i-after">
                                <table
                                    className={`table table-striped ia-table ia-metadata-table ${
                                        atInstanceLevel ? 'instance-level-metadata-table' : ''
                                    }`.trim()}
                                >
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: '33%' }}>
                                                <CalciteLink
                                                    href="https://help.instantatlas.com/instantatlas-data-catalog/data-catalog-metadata/"
                                                    target="rbHelpWindow"
                                                    iconEnd="launch"
                                                >
                                                    Key
                                                </CalciteLink>
                                            </th>
                                            <th scope="col">Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>{rows}</tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </ModalDialog>
    );
};

export default MetadataDialog;

const buildMetadataRequest = (
    url: string,
    indicatorId: string,
    instanceId?: string,
    token?: string
) => {
    const q = `Item_Type='Metadata'`,
        tkn = token !== undefined && token !== null ? `&token=${token}` : '';
    return fetch(
        `${url}/query?f=json&where=${encodeURIComponent(q)}&outFields=Service_URL${tkn}`
    ).then((sRsp) => {
        return sRsp.json().then((sf) => {
            const murl =
                    sf.features !== undefined &&
                    sf.features.length > 0 &&
                    sf.features[0].attributes !== undefined &&
                    sf.features[0].attributes['Service_URL'] !== undefined
                        ? sf.features[0].attributes['Service_URL']
                        : 'https://hub.instantatlas.com/data-catalog-metadata-service',
                mq =
                    instanceId !== undefined && instanceId !== null
                        ? `IndicatorID='${indicatorId}' AND InstanceID='${instanceId}'`
                        : `IndicatorID='${indicatorId}' AND InstanceID IS NULL`,
                mqs = `?f=json&where=${encodeURIComponent(mq)}&outFields=*${tkn}`;
            return fetch(`${murl}/query${mqs}`).then((mdRsp) => {
                return mdRsp.json();
            });
        });
    });
};
