import { createStore, combineReducers, Store } from 'redux';
import { ReactElement } from 'react';

const SET_RB_APP_SETTINGS = 'SET_RB_APP_SETTINGS';
const SET_APPLICATION_STATE = 'SET_APPLICATION_STATE';
const SET_PAGE_STATE = 'SET_PAGE_STATE';
const SET_TREE_STATE = 'SET_TREE_STATE';
const SET_USER_OPTIONS = 'SET_USER_OPTIONS';
const SET_PAGE_REPORT = 'SET_PAGE_REPORT';
const SET_EDITOR_STATE = 'SET_EDITOR_STATE';
const CLEAR_PAGE_STATE = 'CLEAR_PAGE_STATE';

export type IAppState = {
    appId: string;
    appAuthId: string;
    appHome: string;
    appHelp?: string;
    portalUrl?: string;
    portalType?: 'online' | 'portal';
    portalHome?: string;
    staticUrl?: string;
    token?: string;
    tokenExpiry?: number;
    user?: any;
    productHome?: string;
    featureSwitches?: { [id: string]: string };
};

const reportBuilderAppSettings = (
    state: IAppState = {
        appId: '',
        appAuthId: '',
        appHome: '',
        appHelp: 'https://help.reports.esriuk.com/report-builder/',
        portalUrl: '',
        portalType: 'online',
        portalHome: '',
        staticUrl: '',
        token: '',
        tokenExpiry: -1,
        user: {},
        productHome: 'www.reports.esriuk.com',
        featureSwitches: {}
    },
    action
) => {
    switch (action.type) {
        case SET_RB_APP_SETTINGS:
            return {
                ...state,
                ...action
                // appId: action.appId,
                // appHome: action.appHome,
                // appHelp: action.appHelp,
                // appAuthId: action.appAuthId,
                // portalUrl: action.portalUrl,
                // portalType: action.portalType,
                // portalHome: action.portalHome,
                // staticUrl: action.staticUrl,
                // token: action.token,
                // tokenExpiry: action.tokenExpiry,
                // user: action.user,
                // productHome: action.productHome,
                // featureSwitches: action.featureSwitches
            };
        default:
            return state;
    }
};

export const setAppSettings = (
    appId,
    appAuthId,
    appHome,
    appHelpUrl,
    portalUrl,
    portalType,
    portalHome,
    staticUrl,
    token,
    tokenExpiry,
    user,
    featureSwitches
) => ({
    type: SET_RB_APP_SETTINGS,
    appId: appId,
    appAuthId: appAuthId,
    appHome: appHome,
    appHelp: appHelpUrl,
    portalUrl: portalUrl,
    portalType: portalType,
    portalHome: portalHome,
    staticUrl: staticUrl,
    token: token,
    tokenExpiry: tokenExpiry,
    user: user,
    featureSwitches: featureSwitches
});

export type IPageState = {
    title: any;
    group?: any;
    item?: any;
    custom?: any;
};

const applicationState = (
    state: { [id: string]: any } = {
        tokenManager: null
    },
    action
) => {
    let currentState = { ...state };
    switch (action.type) {
        case SET_APPLICATION_STATE:
            // Shallow copy - works fine for this because we are not addressing nested fields...
            if (action.tokenManager !== undefined) currentState.tokenManager = action.tokenManager;
            return currentState;
        default:
            return currentState;
    }
};

export const setApplicationState = (tokenManager: any) => ({
    type: SET_APPLICATION_STATE,
    tokenManager: tokenManager
});

const pageState = (
    state: IPageState = {
        title: {
            text: '',
            icon: ''
        }
    },
    action
) => {
    let currentState = { ...state };
    switch (action.type) {
        case SET_PAGE_STATE:
            // Shallow copy - works fine for this because we are not addressing nested fields...
            if (action.title !== undefined) currentState.title = action.title;
            if (action.group !== undefined) currentState.group = action.group;
            if (action.item !== undefined) currentState.item = action.item;
            if (action.custom !== undefined) currentState.custom = action.custom;
            return currentState;
        default:
            return currentState;
    }
};

export const setPageState = (
    title: string,
    titleIcon?: string | Element | ReactElement<any, any>,
    groupName?: string | null,
    itemName?: string | null,
    pageCustomState?: any
) => ({
    type: SET_PAGE_STATE,
    title: {
        text: title,
        icon: titleIcon
    },
    group: {
        text: groupName,
        icon: ''
    },
    item:
        itemName !== undefined && itemName !== null && typeof itemName == 'object'
            ? itemName
            : {
                  text: itemName,
                  icon: ''
              },
    custom: pageCustomState
});

export type IEditorStatus = {
    [id: string]: string;
};

const editorState = (state: IEditorStatus = {}, action) => {
    switch (action.type) {
        case SET_EDITOR_STATE:
            return {
                ...state,
                ...action
            };
        default:
            return state;
    }
};

export const setEditorState = (editorPageStateObject: IEditorStatus) => ({
    type: SET_EDITOR_STATE,
    ...editorPageStateObject
});

const pageReport = (state: IPageReport = {}, action) => {
    switch (action.type) {
        case SET_PAGE_REPORT:
            return {
                ...action.report
            };
        default:
            return state;
    }
};

export type IPageReport = {
    info?: any;
    data?: any;
    report?: any;
    webmap?: any;
    downloaded?: string;
};

export const setPageReport = (pageReportObject: IPageReport) => ({
    type: SET_PAGE_REPORT,
    report: pageReportObject
});

export type ITreeState = {
    expanded: any[];
};

const treeState = (
    state: ITreeState = {
        expanded: []
    },
    action
) => {
    switch (action.type) {
        case SET_TREE_STATE:
            return {
                expanded: action.expanded
            };
        default:
            return state;
    }
};

export const setTreeState = (expanded: any[] = []) => ({
    type: SET_TREE_STATE,
    expanded: expanded
});

// Deliberately vague - each page or the app can choose what it does - idea would be an array of options like { id: <page-key>, options: <options-as-json-or-object> }
const userOptions = (state: any[] = [], action) => {
    switch (action.type) {
        case SET_USER_OPTIONS:
            return [...action.options];
        default:
            return state;
    }
};

export const setUserOptions = (options: any[] = []) => ({
    type: SET_USER_OPTIONS,
    options: options
});

export const fetchSessionState = (name: string) => {
    try {
        const str = sessionStorage.getItem(name);
        if (str === null) return undefined;
        return JSON.parse(str);
    } catch (err) {
        return undefined;
    }
};

const saveSessionState = (name: string, json: any) => {
    try {
        sessionStorage.setItem(name, JSON.stringify(json));
    } catch (err) {
        console.log(err);
    }
};

export const fetchLocalState = (name: string) => {
    try {
        const str = localStorage.getItem(name);
        if (str === null) return undefined;
        return JSON.parse(str);
    } catch (err) {
        return undefined;
    }
};

const saveLocalState = (name: string, json: any) => {
    try {
        localStorage.setItem(name, JSON.stringify(json));
    } catch (err) {
        console.log(err);
    }
};

const debounce = (func: Function, wait?: number, immediate?: boolean) => {
    let timeout;
    return (...args) => {
        const me: any = this;
        const later = function () {
            timeout = null;
            if (!immediate) func.apply(me, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait || 250);
        if (callNow) func.apply(me, args);
    };
};

// Fetch any state stored in session/local storage and use this when creating the store.
const persistedState = fetchSessionState('reportBuilderState'),
    //persistedExplorerState = fetchLocalState('dataCatalogHubExplorerState'),
    persistedOptions = fetchLocalState('reportBuilderOptions');

const reportBuilderStore = createStore(
    combineReducers({
        appSettings: reportBuilderAppSettings,
        pageState: pageState,
        report: pageReport,
        treeState: treeState,
        userOptions: userOptions,
        editorState: editorState,
        applicationState: applicationState
    }),
    { ...persistedState, ...persistedOptions }
);

// Subscribe to changes to the store so we can update local storage.
reportBuilderStore.subscribe(
    debounce(() => {
        saveSessionState('reportBuilderState', {
            // appSettings: reportBuilderStore.getState().appSettings,
            treeState: reportBuilderStore.getState().treeState,
            editorState: reportBuilderStore.getState().editorState
        });
        saveLocalState('reportBuilderOptions', {
            userOptions: reportBuilderStore.getState().userOptions
        });
    }, 1000)
);

const createUuid = (format = 'N') => {
    const df = s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    return (format === 'D' ? df : df.replace(/[^0-9a-zA-Z]/g, '')).toLowerCase();
};

const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
};

export const createReportBuilderStore = (): ReturnType<typeof createStore> => {
    const uuid = createUuid(),
        uniqueStateId = `reportBuilderState${uuid}`;
    // Fetch any state stored in session/local storage and use this when creating the store.
    const uniquePersistedState = fetchSessionState(uniqueStateId);

    const appReducer = combineReducers({
            appSettings: reportBuilderAppSettings,
            pageState: pageState,
            report: pageReport,
            treeState: treeState,
            userOptions: userOptions,
            applicationState: applicationState,
            editorState: editorState
        }),
        clearReducer = (state, action) => {
            if (action.type === CLEAR_PAGE_STATE) {
                sessionStorage.removeItem(uniqueStateId);
                return appReducer(undefined, action);
            }
            return appReducer(state, action);
        },
        uniqueReportBuilderStore = createStore(clearReducer, { ...uniquePersistedState, ...persistedOptions });

    // Subscribe to changes to the store so we can update local storage.
    uniqueReportBuilderStore.subscribe(
        debounce(() => {
            saveSessionState(`reportBuilderState${uuid}`, {
                // appSettings: reportBuilderStore.getState().appSettings,
                treeState: reportBuilderStore.getState().treeState,
                editorState: reportBuilderStore.getState().editorState
            });
            saveLocalState('reportBuilderOptions', {
                userOptions: reportBuilderStore.getState().userOptions
            });
        }, 1000)
    );
    return uniqueReportBuilderStore;
};

export default reportBuilderStore;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof reportBuilderStore.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof reportBuilderStore.dispatch;
