import React from 'react';
import { createRoot } from 'react-dom/client';
import ModalDialog from './ModalDialog';
import { FormattedMessage, IntlProvider } from 'react-intl';
import '@esri/calcite-components/dist/components/calcite-button';
import '@esri/calcite-components/dist/components/calcite-icon';
import { CalciteButton, CalciteIcon } from '@esri/calcite-components-react';

const ConfirmNavigationDialog = (message: string, callback: any) => {
    const container = document.createElement('div');
    container.setAttribute('custom-confirmation-navigation', '');
    document.body.appendChild(container);
    const root = createRoot(container!),
        closeModal = (callbackState) => {
            root.unmount();
            document.body.removeChild(container);
            callback(callbackState);
        };
    root.render(
        <IntlProvider locale="en">
            <ModalDialog
                show={true}
                kind="warning"
                buttons={
                    <>
                        <CalciteButton
                            kind="brand"
                            appearance="outline"
                            slot="primary"
                            onClick={(e) => {
                                closeModal(true);
                            }}
                            iconStart="check"
                        >
                            <FormattedMessage
                                id="navigation.confirmDialog.button.yes"
                                defaultMessage="Yes"
                            ></FormattedMessage>
                        </CalciteButton>
                        <CalciteButton
                            kind="brand"
                            appearance="solid"
                            slot="secondary"
                            onClick={(e) => {
                                closeModal(false);
                            }}
                            iconStart="x"
                        >
                            <FormattedMessage
                                id="navigation.confirmDialog.button.no"
                                defaultMessage="No"
                            ></FormattedMessage>
                        </CalciteButton>
                    </>
                }
                title={
                    <FormattedMessage
                        id="navigation.confirmDialog.title"
                        defaultMessage="Are you sure you want to leave?"
                    ></FormattedMessage>
                }
            >
                <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                    <div>
                        <p>
                            <CalciteIcon icon="exclamation-mark-triangle" scale="l"></CalciteIcon>
                        </p>
                    </div>
                    <div className="error-body">
                        <p>{message}</p>
                    </div>
                </div>
            </ModalDialog>
        </IntlProvider>
    );
};
export default ConfirmNavigationDialog;
