import AbstractWidget from './AbstractWidget';
import BarChartWidget from './BarChartWidget';
import { FilledIconWidget, ScaledBoxWidget, ScaledIconWidget } from './ProportionalIconWidgets';
import IconRepeaterWidget, { IconBarChartWidget } from './IconRepeaterWidget';
import ImageWidget from './ImageWidget';
import LayerAttachmentsWidget from './LayerAttachmentsWidget';
import LineChartWidget, { RadarChartWidget } from './LineChartWidget';
import NavigatorWidget from './NavigatorWidget';
import { PieChartWidget, PolarChartWidget } from './PieChartWidget';
import PyramidChartWidget from './PyramidChartWidget';
import RelatedRecordsTableWidget from './RelatedRecordsTableWidget';
import RemoteContentWidget from './RemoteContentWidget';
import ScatterplotChartWidget from './ScatterplotChartWidget';
import ScriptWidget from './ScriptWidget';
import TableWidget from './TableWidget';
import { TextWidget, TextBoxWidget } from './TextWidget';
import TextBreakWidget from './TextBreakWidget';
import TimeSeriesTableWidget from './TimeSeriesTableWidget';
import ToggleContentWidget from './ToggleContentWidget';
import { generateGuid } from '../utils/report-migrate-plus';
import { formatDateCustom } from '../utils/localization';

export const createWidgetInstance = (containerElement, designOrScriptClassName, failWithNull = true) => {
    let isVanilla = typeof designOrScriptClassName === 'string',
        design = isVanilla
            ? {
                  scriptClass: designOrScriptClassName,
                  id: generateGuid()
              }
            : designOrScriptClassName,
        widget = new AbstractWidget(containerElement, design),
        isText =
            design.scriptClass === 'TextBoxWidget' ||
            design.scriptClass === 'RuleAwareTextWidget' ||
            design.scriptClass === 'TextWidget';
    // TODO - nitty gritty here is to decide on some content based on the type of widget
    if (design.scriptClass === 'EmbeddedWebScriptWidget')
        widget = new ScriptWidget(containerElement, isVanilla ? { ...ScriptWidget.getDefaults(), ...design } : design);
    else if (design.scriptClass === 'TimeSeriesTableWidget')
        widget = new TimeSeriesTableWidget(
            containerElement,
            isVanilla ? { ...TimeSeriesTableWidget.getDefaults(), ...design } : design
        );
    else if (design.scriptClass === 'TableWidget')
        widget = new TableWidget(containerElement, isVanilla ? { ...TableWidget.getDefaults(), ...design } : design);
    else if (design.scriptClass === 'ImageWidget')
        widget = new ImageWidget(containerElement, isVanilla ? { ...ImageWidget.getDefaults(), ...design } : design);
    else if (design.scriptClass === 'IconRepeaterWidget')
        widget = new IconRepeaterWidget(
            containerElement,
            isVanilla ? { ...IconRepeaterWidget.getDefaults(), ...design } : design
        );
    else if (design.scriptClass === 'IconBarChartWidget')
        widget = new IconBarChartWidget(
            containerElement,
            isVanilla ? { ...IconBarChartWidget.getDefaults(), ...design } : design
        );
    else if (design.scriptClass === 'FilledIconWidget')
        widget = new FilledIconWidget(
            containerElement,
            isVanilla ? { ...FilledIconWidget.getDefaults(), ...design } : design
        );
    else if (design.scriptClass === 'ScaledIconWidget')
        widget = new ScaledIconWidget(
            containerElement,
            isVanilla ? { ...ScaledIconWidget.getDefaults(), ...design } : design
        );
    else if (design.scriptClass === 'ScaledBoxWidget')
        widget = new ScaledBoxWidget(
            containerElement,
            isVanilla ? { ...ScaledBoxWidget.getDefaults(), ...design } : design
        );
    else if (design.scriptClass === 'NavigatorWidget')
        widget = new NavigatorWidget(
            containerElement,
            isVanilla ? { ...NavigatorWidget.getDefaults(), ...design } : design
        );
    else if (design.scriptClass === 'LayerAttachmentsWidget')
        widget = new LayerAttachmentsWidget(
            containerElement,
            isVanilla ? { ...LayerAttachmentsWidget.getDefaults(), ...design } : design
        );
    else if (design.scriptClass === 'RelatedRecordsTableWidget' || design.scriptClass === 'RelatedItemsTableWidget') {
        widget = new RelatedRecordsTableWidget(
            containerElement,
            isVanilla ? { ...RelatedRecordsTableWidget.getDefaults(), ...design } : design
        );
        design.scriptClass = 'RelatedRecordsTableWidget';
    } else if (design.scriptClass === 'BarChartWidget')
        widget = new BarChartWidget(
            containerElement,
            isVanilla ? { ...BarChartWidget.getDefaults(), ...design } : design
        );
    else if (design.scriptClass === 'LineChartWidget')
        widget = new LineChartWidget(
            containerElement,
            isVanilla ? { ...LineChartWidget.getDefaults(), ...design } : design
        );
    else if (design.scriptClass === 'RadarChartWidget')
        widget = new RadarChartWidget(
            containerElement,
            isVanilla ? { ...RadarChartWidget.getDefaults(), ...design } : design
        );
    else if (design.scriptClass === 'PieChartWidget')
        widget = new PieChartWidget(
            containerElement,
            isVanilla ? { ...PieChartWidget.getDefaults(), ...design } : design
        );
    else if (design.scriptClass === 'PolarChartWidget')
        widget = new PolarChartWidget(
            containerElement,
            isVanilla ? { ...PolarChartWidget.getDefaults(), ...design } : design
        );
    else if (design.scriptClass === 'PyramidChartWidget')
        widget = new PyramidChartWidget(
            containerElement,
            isVanilla ? { ...PyramidChartWidget.getDefaults(), ...design } : design
        );
    else if (design.scriptClass === 'ScatterplotChartWidget')
        widget = new ScatterplotChartWidget(
            containerElement,
            isVanilla ? { ...ScatterplotChartWidget.getDefaults(), ...design } : design
        );
    else if (design.scriptClass === 'TextBoxWidget')
        widget = new TextBoxWidget(
            containerElement,
            isVanilla ? { ...TextBoxWidget.getDefaults(), ...design } : design
        );
    else if (design.scriptClass === 'XsltWidget' || design.scriptClass === 'RemoteContentWidget')
        widget = new RemoteContentWidget(
            containerElement,
            isVanilla ? { ...RemoteContentWidget.getDefaults(), ...design } : design
        );
    else if (design.scriptClass === 'TextBreakWidget')
        widget = new TextBreakWidget(
            containerElement,
            isVanilla ? { ...TextBreakWidget.getDefaults(), ...design } : design
        );
    else if (design.scriptClass === 'ToggleContentWidget')
        widget = new ToggleContentWidget(
            containerElement,
            isVanilla ? { ...ToggleContentWidget.getDefaults(), ...design } : design
        );
    else if (isText)
        widget = new TextWidget(containerElement, isVanilla ? { ...TextWidget.getDefaults(), ...design } : design);
    else if (!failWithNull)
        throw new Error(`Cannot create widget - class key '${design.scriptClass}' is not registered`);
    return widget;
};

export const designIsTextWidget = (design) => {
    return (
        design !== undefined &&
        design !== null &&
        design.scriptClass !== undefined &&
        design.scriptClass !== null &&
        (design.scriptClass === 'RuleAwareTextWidget' ||
            design.scriptClass === 'TextWidget' ||
            design.scriptClass === 'TextBoxWidget' ||
            design.scriptClass === 'ToggleContentWidget') // not strictly text but can be edited as text so...
    );
};

export const buildDisplayText = (rawText, feature, wellKnownValues = {}, locale = 'en', placeholder = '⬚⬚⬚') => {
    const subsRegex =
        /([^\\]|^)(#FNA|#CFNA|#INA|#ILD|#ILV|#INA|#IDA|#IVA|#IIV|#TODAY|#NOW|#DOWNLOAD|#IMETADATALINK)/gim;
    let txt = null;
    let regMatch = false;
    if (rawText !== undefined && rawText !== null) {
        txt = rawText.replace(/^\[|([^\\])\[/g, '$1<').replace(/([^\\])\]/g, '$1>'); // TODO - clean regex...
        while (/(?:#TODAY|#NOW){([yMdHhms-]*)}/.test(txt)) {
            txt = txt.replace(
                /(?:#TODAY|#NOW){([yMdHhms-]*)}/,
                formatDateCustom(new Date(), /(?:#TODAY|#NOW){([yMdHhms-]*)}/.exec(txt)[1])
            );
        }
        txt = txt
            .replace(
                /#TODAY/g,
                new Date().toLocaleDateString(locale !== '' ? locale : 'en', {
                    dateStyle: 'full'
                })
            )
            .replace(
                /#NOW/g,
                new Date().toLocaleString(locale !== '' ? locale : 'en', {
                    dateStyle: 'full'
                })
            );
        if (wellKnownValues['downloaded'] !== undefined) {
            txt = txt.replace(
                /#DOWNLOAD/g,
                new Date(wellKnownValues['downloaded']).toLocaleDateString(locale !== '' ? locale : 'en', {
                    dateStyle: 'full'
                })
            );
        }
        regMatch = true;
        if (feature !== undefined && feature !== null) {
            const fArray = Array.isArray(feature) ? feature : [feature];
            if (fArray.length > 0) {
                txt = txt
                    .replace(
                        new RegExp(`[$]feature[.]${wellKnownValues.idField ?? '_______unlikely'}`, 'gi'),
                        fArray.map((f) => f.id).join(', ')
                    )
                    .replace(
                        new RegExp(`[$]feature[.]${wellKnownValues.nameField ?? '_______unlikely'}`, 'gi'),
                        fArray.map((f) => f.name).join(', ')
                    )
                    .replace(/#FNAMES\{([^}{]+)\}/gi, fArray.map((f) => f.name).join('$1'))
                    .replace(
                        /#FNAME(S?)\{([0-9]+|active)\}/gi,
                        fArray[!isNaN(parseInt('$2')) ? parseInt('$2') - 1 : 0].name
                    )
                    .replace(/#FNAME/gi, fArray[0].name)
                    .replace(/#FIDS\{([^}{]+)\}/gi, fArray.map((f) => f.id).join('$1'))
                    .replace(
                        /#FID(S?)\{([0-9]+|active)\}/gi,
                        fArray[!isNaN(parseInt('$2')) ? parseInt('$2') - 1 : 0].id
                    )
                    .replace(/#FID/gi, fArray[0].id)
                    .replace(/[$]feature[.]([a-zA-Z0-9_]{2,30})/gim, '#IVALUE{$1,active}');
            }
        }
        regMatch = subsRegex.test(txt);
        if (!regMatch) txt = txt.replace(/\\#/g, '#');
        // Escapes - strip back...
        else
            txt = txt.replace(
                /([^\\])?(#FNAME(S?)|#CFNAME(S?)|#INAME|#ILDATE|#ILVALUE|#IDATE|#IVALUE|#IIVALUE)(\{[0-9a-zA-Z:,\s]+\})?/gi,
                `$1${placeholder}`
            );
    }
    return {
        src: rawText,
        text: txt,
        placeholder: regMatch && txt !== rawText
    };
};

// Gives a common color format of rgb(r, g, b) or rgba(r, g, b, a) from hex etc.
export const parseColor = (colorHexOrRgb, overrideOpacity = '', outputAsObject = false) => {
    if (colorHexOrRgb.substring(0, 1) === '#') {
        let r,
            g,
            b,
            a = 1.0;
        if (colorHexOrRgb.length === 4)
            return `rgb(${parseInt(colorHexOrRgb[1] + colorHexOrRgb[1], 16)},${parseInt(
                colorHexOrRgb[2] + colorHexOrRgb[2],
                16
            )},${parseInt(colorHexOrRgb[3] + colorHexOrRgb[3], 16)})`;
        else if (colorHexOrRgb.length === 9) {
            a = parseInt(colorHexOrRgb.substring(1, 3), 16) / 256.0;
            r = parseInt(colorHexOrRgb.substring(3, 5), 16);
            g = parseInt(colorHexOrRgb.substring(5, 7), 16);
            b = parseInt(colorHexOrRgb.substring(7, 9), 16);
        } else if (colorHexOrRgb.length === 7) {
            r = parseInt(colorHexOrRgb.substring(1, 3), 16);
            g = parseInt(colorHexOrRgb.substring(3, 5), 16);
            b = parseInt(colorHexOrRgb.substring(5, 7), 16);
        }
        if (overrideOpacity !== '') {
            if (overrideOpacity.indexOf('%') > 0 || parseFloat(overrideOpacity) > 1)
                a = parseFloat(overrideOpacity.replace('%')) / 100.0;
            else a = parseFloat(overrideOpacity);
        }
        return outputAsObject ? { r, g, b, a } : `rgba(${r},${g},${b},${a.toFixed(2)})`;
    }
    // Trust them?
    else if (colorHexOrRgb.substring(0, 4) === 'rgb(' || colorHexOrRgb.substring(0, 5) === 'rgba(') {
        const tokens = colorHexOrRgb.replace('rgb(', '').replace('rgba(', '').replace(')', '').split(','),
            r = parseInt(tokens[0]),
            g = parseInt(tokens[1]),
            b = parseInt(tokens[2]);
        let a = tokens.length > 3 ? parseFloat(tokens[3]) : 1.0;
        if (overrideOpacity !== '') {
            if (overrideOpacity.indexOf('%') > 0 || parseFloat(overrideOpacity) > 1)
                a = parseFloat(overrideOpacity.replace('%')) / 100.0;
            else a = parseFloat(overrideOpacity);
        }
        return outputAsObject ? { r, g, b, a } : `rgba(${r},${g},${b},${a.toFixed(2)})`;
    } else if (colorHexOrRgb.toLowerCase() === 'transparent')
        return outputAsObject ? { r: 255, g: 255, b: 255, a: 0 } : 'rgba(255, 255, 255, 0)';
    else throw new Error(`Cannot parse '${colorHexOrRgb}' as a color - use #RRGGBB, #AARRGGBB or similar`);
};

export const colorContrast = (foreColor = '#fff', backColor = '#000') => {
    const fc = parseColor(foreColor, undefined, true),
        bc = parseColor(backColor, undefined, true),
        fcLu = colorLuminance(fc),
        bcLu = colorLuminance(bc),
        // (L1 + 0.05) / (L2 + 0.05) but remember relative contrast so need to make sure max / min, so...
        contrast = (Math.max(fcLu, bcLu) + 0.05) / (Math.min(fcLu, bcLu) + 0.05);
    return contrast;
};

export const colorLuminance = (color = { r: 255, g: 255, b: 255 }) => {
    // https://www.w3.org/TR/WCAG20/#relativeluminancedef
    const r = color.r / 255.0,
        g = color.g / 255.0,
        b = color.b / 255.0,
        lum =
            0.2126 * (r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4)) +
            0.7152 * (g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4)) +
            0.0722 * (b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4));
    return lum;
};

// Legacy - only used as last resort...
export const STANDARD_PALETTES_KEYS = [
    'ia1',
    'ia2',
    'ia3',
    'ia4',
    'ia5',
    'ia6',
    'ia7',
    'ia8',
    'ia9',
    '<spacer>',
    'colorbrewerblind1seq',
    'colorbrewerblind2seq',
    'colorbrewerblind3seq',
    'colorbrewerblind4seq',
    'colorbrewerblind5seq',
    'colorbrewerblind6seq',
    '<spacer>',
    'colorbrewerblind1div',
    'colorbrewerblind2div',
    'colorbrewerblind3div',
    'colorbrewerblind4div',
    'colorbrewerblind5div',
    'colorbrewerblind6div',
    '<spacer>',
    'BrightPastel',
    'Bright',
    'Grayscale',
    'Excel',
    'Light',
    'Pastel',
    'EarthTones',
    'Berry',
    'Chocolate',
    'Fire',
    'SeaGreen'
];

export const STANDARD_PALETTES = [
    ['#3AAACF', '#FFBB73', '#5FD2B5', '#FE7276', '#7373DF', '#FF7140', '#1c71a9', '#BF8430'],
    ['#8dd3c7', '#ffffbc', '#bebada', '#fb8072', '#80b1d3', '#fdb462', '#b3de69', '#fccdef'],
    ['#fbb3ad', '#b2cce2', '#ccebc5', '#decbe4', '#fed9a5', '#ffffcc', '#e4d7bc', '#fddaec'],
    ['#e31a1c', '#377db8', '#4daf4a', '#984ea3', '#ff7f00', '#ffff33', '#a65628', '#f781bf'],
    ['#b3e2cd', '#fdcdac', '#cbd5a8', '#f4cae4', '#e6f5c9', '#fff2ae', '#f1e2cc', '#cccccc'],
    ['#66c2a5', '#fc8d62', '#8da0cb', '#e78ac3', '#a6d854', '#ffd92f', '#e5c494', '#b3b3b3'],
    ['#1b9e77', '#d95f02', '#7570b3', '#e7298a', '#66a61e', '#e6ab02', '#a6761d', '#666666'],
    ['#a6cee3', '#1f77b4', '#b2df8a', '#33a02c', '#fb9a99', '#e31a1c', '#fdbf6f', '#ff7f00'],
    ['#7fc97f', '#beaed4', '#fdc086', '#ffff99', '#386cb0', '#f0027f', '#bf5b17', '#666666'],
    [],
    ['#f7fbff', '#deebf7', '#c6dbef', '#9ecae1', '#6baed6', '#4292c6', '#2171b5', '#08519c', '#08306b'],
    ['#f7fcf5', '#e5f5e0', '#c7e9c0', '#a1d99b', '#74c476', '#41ab5d', '#238b45', '#006d2c', '#00441b'],
    ['#ffffff', '#f0f0f0', '#d9d9d9', '#bdbdbd', '#969696', '#737373', '#525252', '#252525', '#000000'],
    ['#fff5eb', '#fee6ce', '#fdd0a2', '#fdae6b', '#fd8d3c', '#f16913', '#d94801', '#a63603', '#7f2704'],
    ['#fcfbfd', '#efedf5', '#dadaeb', '#bcbddc', '#9e9ac8', '#807dba', '#6a51a3', '#54278f', '#3f007d'],
    ['#fff5f0', '#fee0d2', '#fcbba1', '#fc9272', '#fb6a4a', '#ef3b2c', '#cb181d', '#a50f15', '#67000d'],
    [],
    ['#543005', '#8c510a', '#bf812d', '#dfc27d', '#f6e8c3', '#c7eae5', '#80cdc1', '#35978f', '#01665e', '#003c30'],
    ['#8e0152', '#c51b7d', '#de77ae', '#f1b6da', '#fde0ef', '#e6f5d0', '#b8e186', '#7fbc41', '#4d9221', '#276419'],
    ['#40004b', '#762a83', '#9970ab', '#c2a5cf', '#e7d4e8', '#d9f0d3', '#a6dba0', '#5aae61', '#1b7837', '#00441b'],
    ['#7f3b08', '#b35806', '#e08214', '#fdb863', '#fee0b6', '#d8daeb', '#b2abd2', '#8073ac', '#542788', '#2d004b'],
    ['#67001f', '#b2182b', '#d6604d', '#f4a582', '#fddbc7', '#d1e5f0', '#92c5de', '#4393c3', '#2166ac', '#053061'],
    ['#a50026', '#d73027', '#f46d43', '#fdae61', '#fee090', '#e0f3f8', '#abd9e9', '#74add1', '#4575b4', '#313695'],
    [],
    [
        'rgb(65,140,240)',
        'rgb(252,180,65)',
        'rgb(224,64,10)',
        'rgb(5,100,146)',
        'rgb(191,191,191)',
        'rgb(26,59,105)',
        'rgb(255,227,130)',
        'rgb(18,156,221)',
        'rgb(202,107,75)',
        'rgb(0,92,219)',
        'rgb(243,210,136)',
        'rgb(80,99,129)',
        'rgb(241,185,168)',
        'rgb(224,131,10)',
        'rgb(120,147,190)'
    ], // .NET, BrightPastel
    [
        'rgb(0,128,0)',
        'rgb(0,0,255)',
        'rgb(128,0,128)',
        'rgb(0,255,0)',
        'rgb(255,0,255)',
        'rgb(0,128,128)',
        'rgb(255,255,0)',
        'rgb(128,128,128)',
        'rgb(0,255,255)',
        'rgb(0,0,128)',
        'rgb(128,0,0)',
        'rgb(255,0,0)',
        'rgb(128,128,0)',
        'rgb(192,192,192)',
        'rgb(255,99,71)',
        'rgb(255,228,181)'
    ], // .NET, Bright,
    [
        'rgb(200,200,200)',
        'rgb(189,189,189)',
        'rgb(178,178,178)',
        'rgb(167,167,167)',
        'rgb(156,156,156)',
        'rgb(145,145,145)',
        'rgb(134,134,134)',
        'rgb(123,123,123)',
        'rgb(112,112,112)',
        'rgb(101,101,101)',
        'rgb(90,90,90)',
        'rgb(79,79,79)',
        'rgb(68,68,68)',
        'rgb(57,57,57)',
        'rgb(46,46,46)',
        'rgb(35,35,35)'
    ], // .NET, Grayscale
    [
        'rgb(153,153,255)',
        'rgb(153,51,102)',
        'rgb(255,255,204)',
        'rgb(204,255,255)',
        'rgb(102,0,102)',
        'rgb(255,128,128)',
        'rgb(0,102,204)',
        'rgb(204,204,255)',
        'rgb(0,0,128)',
        'rgb(255,0,255)',
        'rgb(255,255,0)',
        'rgb(0,255,255)',
        'rgb(128,0,128)',
        'rgb(128,0,0)',
        'rgb(0,128,128)',
        'rgb(0,0,255)'
    ], // .NET, Excel
    [
        'rgb(230,230,250)',
        'rgb(255,240,245)',
        'rgb(255,218,185)',
        'rgb(255,250,205)',
        'rgb(255,228,225)',
        'rgb(240,255,240)',
        'rgb(240,248,255)',
        'rgb(245,245,245)',
        'rgb(250,235,215)',
        'rgb(224,255,255)'
    ], // .NET, Light
    [
        'rgb(135,206,235)',
        'rgb(50,205,50)',
        'rgb(186,85,211)',
        'rgb(240,128,128)',
        'rgb(70,130,180)',
        'rgb(154,205,50)',
        'rgb(64,224,208)',
        'rgb(255,105,180)',
        'rgb(240,230,140)',
        'rgb(210,180,140)',
        'rgb(143,188,139)',
        'rgb(100,149,237)',
        'rgb(221,160,221)',
        'rgb(95,158,160)',
        'rgb(255,218,185)',
        'rgb(255,160,122)'
    ], // .NET, Pastel
    [
        'rgb(255,128,0)',
        'rgb(184,134,11)',
        'rgb(192,64,0)',
        'rgb(107,142,35)',
        'rgb(205,133,63)',
        'rgb(192,192,0)',
        'rgb(34,139,34)',
        'rgb(210,105,30)',
        'rgb(128,128,0)',
        'rgb(32,178,170)',
        'rgb(244,164,96)',
        'rgb(0,192,0)',
        'rgb(143,188,139)',
        'rgb(178,34,34)',
        'rgb(139,69,19)',
        'rgb(192,0,0)'
    ], // .NET, EarthTones
    [
        'rgb(138,43,226)',
        'rgb(186,85,211)',
        'rgb(65,105,225)',
        'rgb(199,21,133)',
        'rgb(0,0,255)',
        'rgb(218,112,214)',
        'rgb(123,104,238)',
        'rgb(192,0,192)',
        'rgb(0,0,205)',
        'rgb(128,0,128)'
    ], // .NET, Berry
    [
        'rgb(160,82,45)',
        'rgb(210,105,30)',
        'rgb(139,0,0)',
        'rgb(205,133,63)',
        'rgb(165,42,42)',
        'rgb(244,164,96)',
        'rgb(139,69,19)',
        'rgb(192,64,0)',
        'rgb(178,34,34)',
        'rgb(182,92,58)'
    ], // .NET, Chocolate
    [
        'rgb(255,215,0)',
        'rgb(255,0,0)',
        'rgb(255,20,147)',
        'rgb(220,20,60)',
        'rgb(255,140,0)',
        'rgb(255,0,255)',
        'rgb(255,255,0)',
        'rgb(255,69,0)',
        'rgb(199,21,133)',
        'rgb(221,226,33)'
    ], // .NET, Fire
    [
        'rgb(46,139,87)',
        'rgb(102,205,170)',
        'rgb(70,130,180)',
        'rgb(0,139,139)',
        'rgb(95,158,160)',
        'rgb(60,179,113)',
        'rgb(72,209,204)',
        'rgb(176,196,222)',
        'rgb(143,188,139)',
        'rgb(135,206,235)'
    ], // .NET, SeaGreen
    [
        'rgba(255,0,0,0.5882353)',
        'rgba(0,255,0,0.5882353)',
        'rgba(0,0,255,0.5882353)',
        'rgba(255,255,0,0.5882353)',
        'rgba(0,255,255,0.5882353)',
        'rgba(255,0,255,0.5882353)',
        'rgba(170,120,20,0.5882353)',
        'rgba(255,0,0,0.3137255)',
        'rgba(0,255,0,0.3137255)',
        'rgba(0,0,255,0.3137255)',
        'rgba(255,255,0,0.3137255)',
        'rgba(0,255,255,0.3137255)',
        'rgba(255,0,255,0.3137255)',
        'rgba(170,120,20,0.3137255)',
        'rgba(100,120,50,0.5882353)',
        'rgba(40,90,150,0.5882353)'
    ] // .NET, SemiTransparent
];

/* Re-export to make life simpler! */
export {
    AbstractWidget,
    BarChartWidget,
    FilledIconWidget,
    ScaledBoxWidget,
    ScaledIconWidget,
    IconRepeaterWidget,
    IconBarChartWidget,
    ImageWidget,
    LayerAttachmentsWidget,
    LineChartWidget,
    NavigatorWidget,
    PieChartWidget,
    PolarChartWidget,
    PyramidChartWidget,
    RadarChartWidget,
    RelatedRecordsTableWidget,
    ScatterplotChartWidget,
    ScriptWidget,
    TableWidget,
    TextWidget,
    TextBoxWidget,
    TimeSeriesTableWidget
};
