import React, { useState, useEffect } from "react";
import { isNullOrUndefined } from '../utils/object';

const ListToggleButton = (props) =>
{
    const [open, setOpen] = useState(false),
        { element, elementClassName, className, text, tooltip, children, autoClose, closeAfter, intl, ...others } = props,
        containerElementName = (!isNullOrUndefined(element) ? element : 'div'),
        btnClassName = (className !== undefined ? className : 'btn btn-default'),
        auto = isNullOrUndefined(autoClose) || (autoClose === true),
        button = React.useRef(null),
        htmlElement = React.useRef(null);
    
    useEffect(() =>
    {
        if (button.current !== undefined) button.current.blur(); // UI update outside React...
        if (!isNullOrUndefined(closeAfter) && open)
        {
            setTimeout(() =>
            {
                setOpen(false);
            }, closeAfter);
        }
    });

        
    return React.createElement(containerElementName, {
        className: `${(open ? 'open' : '')} ${(elementClassName !== undefined ? elementClassName : '')}`.trim(),
        onMouseUp: (open && auto ? (e) =>
        {
            if (e.target.closest('.toggle-btn-def') === null) setOpen(false);
        }: undefined), 
        ref: htmlElement
    },
        <React.Fragment>
            <button ref={button} className={`${btnClassName} toggle-btn-def`} type="button" onClick={() => setOpen(!open)} data-tooltip={tooltip} {...others}>
                {text}
            </button>
            <>
                {children}
            </>
        </React.Fragment>);
}

export default ListToggleButton;
