import AbstractWidget from './AbstractWidget';

export default class ScriptWidget extends AbstractWidget
{
    static getDefaults = () =>
    {
        return {
            ...AbstractWidget.getDefaults(),
            htmlBody: '<p class="temporary">Initial HTML - you can use the script to change this.</p>',
            scriptBody: '(function(container, data) {\n//Code here..\n})(container, data);', 
            libraries: 'none'
        };
    }

    render = (data) =>
    {
        if ((this.container !== undefined) && (this.container !== null) && (this.design !== undefined) && (this.design !== null))
        {
            const topWidget = this.container.closest('.ia-report-widget'),
                widgetUuid = (topWidget != null ? topWidget.getAttribute('data-uuid') : 'XXX'),
                scriptTagUuid = `wscript${widgetUuid}`,
                { htmlBody = '', scriptBody: scriptOrLegacyScript, libraries = 'none' } = this.design;
            if ((scriptOrLegacyScript !== undefined) && (topWidget != null))
            {
                this.container.setAttribute('id', scriptTagUuid);
                const updatedScript = scriptOrLegacyScript.replace(/\.iaProfileWidget/g, '.ia-report-widget');
                let adjustedData = ((data !== undefined) && (data !== null) ? data : []);
                if ((adjustedData.length > 0) && (adjustedData[0].features !== undefined))
                {
                    // Legacy of earlier RB versions - may be dropped in future
                    adjustedData = {
                        features: [...adjustedData[0].features],
                        data: [...adjustedData]
                    };
                }
                const dataJson = JSON.stringify(adjustedData),
                    wrappedScript = (libraries === 'jquery' ?
                        `(function(container, data, $) { try { setTimeout(function() { ${updatedScript} }, 200); } catch (ex) { console.log('⚠️ Script error in widget ${widgetUuid} ⚠️'); console.log(ex); } })((window.jQuery || window.$ ? (window.jQuery || window.$)('#${scriptTagUuid}') : document.getElementById('${scriptTagUuid}')),${dataJson},window.jQuery || window.$);` :
                        `(function(container, data) { try { setTimeout(function() { ${updatedScript} }, 200); } catch (ex) { console.log('⚠️ Script error in widget ${widgetUuid} ⚠️'); console.log(ex); } })(document.getElementById('${scriptTagUuid}'),${dataJson});`),
                    scriptNode = this.container.ownerDocument.createTextNode(wrappedScript),
                    scriptElement = this.container.ownerDocument.createElement('script'),
                    htmlElement = this.container.ownerDocument.createElement('div');
                this.container.querySelectorAll('.ia-generated').forEach(e => e.remove());
                scriptElement.setAttribute('class', 'ia-generated');
                htmlElement.setAttribute('class', 'ia-generated');
                if (wrappedScript.indexOf('esri/map') > 0)
                {
                    throw new Error('⚠️ Script contains ArcGIS JavaScript API references at version 3.x - script is disabled. Update to version 4.x');
                }
                else if ((htmlBody !== undefined) && (htmlBody !== null) &&
                    ((htmlBody.indexOf('<script') >= 0) || (htmlBody.indexOf('<style') >= 0) || (htmlBody.indexOf('<link') >= 0)))
                {
                    throw new Error('⚠️ HTML body cannot contain extra script or style references - load via your script if necessary.');
                }
                else
                {
                    if ((htmlBody !== undefined) && (htmlBody !== null))
                    {
                        this.container.appendChild(htmlElement);
                        htmlElement.innerHTML = htmlBody; //Raw injection - dangerous but...
                    }
                    scriptElement.setAttribute('defer', 'true');
                    scriptElement.appendChild(scriptNode);
                    this.container.appendChild(scriptElement);
                    if (topWidget !== null) topWidget.setAttribute('class', topWidget.getAttribute('class').replace(/\s(placeholder)/g, ''));
                }
            }
        }
    }
}