import AbstractBoxedWidget from './AbstractBoxedWidget';
import { isNullOrWhitespace } from '../utils/object';

export default class AbstractIconWidget extends AbstractBoxedWidget {
    getScaleValues = (data) => {
        // Get some stuff ready...
        let scaleMax = Number.MIN_SAFE_INTEGER,
            scaleMin = Number.MAX_SAFE_INTEGER,
            tv;
        const settings = {
                ...AbstractIconWidget.getDefaults(),
                ...this.design
            },
            { iconScaleBetween, showComparisons } = settings,
            dataAvailable =
                data !== undefined &&
                data !== null &&
                data.colIds !== undefined &&
                data.colIds.length > 0 &&
                data.rows !== undefined &&
                data.rows !== null,
            dataMultipleFeatures = dataAvailable && data.rows.length > 1,
            dataMultipleInds = dataAvailable && data.colIds.length > 1;
        if (
            (dataMultipleFeatures || dataMultipleInds) &&
            (isNullOrWhitespace(iconScaleBetween) || iconScaleBetween.toLowerCase() === 'auto')
        ) {
            // Comparisons?
            let maxOffset =
                data.features.length > 0
                    ? showComparisons
                        ? data.features.length
                        : data.features.filter((f) => !f.comparison).length
                    : showComparisons
                    ? data.rowIds.length
                    : data.rowIds.filter((f) => f.substring(0, 1) !== '#').length;
            // All indicators... should only select 1 for those that need only 1!
            let v;
            for (let i = 1; i < data.colIds.length; i++) {
                for (let j = 0; j < maxOffset; j++) {
                    v = typeof data.rows[j][i] === 'number' ? data.rows[j][i] : parseFloat(data.rows[j][i]);
                    if (!isNaN(v)) {
                        if (v < scaleMin) scaleMin = v;
                        if (v > scaleMax) scaleMax = v;
                    }
                }
            }
        } else if (!isNullOrWhitespace(iconScaleBetween) && iconScaleBetween.split(',').length > 1) {
            const bounds = iconScaleBetween.split(',');
            if (!isNaN(parseFloat(bounds[0])) && !isNaN(parseFloat(bounds[1]))) {
                scaleMin = parseFloat(bounds[0]);
                scaleMax = parseFloat(bounds[1]);
            }
        }
        return [scaleMin, scaleMax];
    };

    static getDefaults = () => {
        return {
            ...AbstractBoxedWidget.getDefaults(),
            messageBackground: '#ffffff',
            animateIconLabels: true,
            animationDelay: '0.1',
            animationDuration: '0.7',
            fixedIconWidth: false,
            highlightColor: '#ffffd7',
            highlightSelectedFeature: false,
            icon: 'square',
            iconAlignment: 'Left',
            iconBorderColor: '#FFFFFF',
            iconBorderWidth: '0',
            iconBorders: false,
            iconClassPrefix: 'fas',
            iconColor: '#333333',
            iconMargin: '0 0 0 0',
            iconPadding: '0 0 0 0',
            iconScaleBetween: '',
            iconSize: '80',
            iconSizeDivisor: '100',
            iconSortByValue: 'Unsorted',
            iconType: 'FontCharacter', // Or Image
            tooltipFormat: '#COLOR #FNAME, #INAME: #IVALUE'
        };
    };
}
