import { lengthWithUnit } from './AbstractWidget';
import AbstractIconWidget from './AbstractIconWidget';
import { addClass, removeClass, hasClass } from '../utils/dom';
import { isNullOrWhitespace, hyphenate } from '../utils/object';
import { getNumberFormatter } from '../utils/localization';
import { DataManipulator } from 'data-catalog-js-api';

class ProportionalIconWidget extends AbstractIconWidget {
    // Render here is slightly special, because it can _detect_ if the calling (React) class laid out some of the HTML before calling this method.
    render = (data, options = {}) => {
        if (
            this.container !== undefined &&
            this.container !== null &&
            this.design !== undefined &&
            this.design !== null
        ) {
            // Basics - this will give us the box or the icon content area... using super class
            const settings = {
                    ...ProportionalIconWidget.getDefaults(),
                    ...this.design
                },
                iconContentElement = this.renderBox(data, {
                    showTitle: true, // Always true, because inside chart doesn't work here (settings.titlePosition !== 'InsideChart')
                    element: 'figure',
                    boxClass: 'ia-chart-box',
                    settings: {
                        ...settings,
                        messageBackground: settings.backgroundColor // Transfer chart background to message as well
                    }
                }),
                doc = this.container.ownerDocument,
                topWidget = this.container.closest('.ia-report-widget'),
                { activeFeature, comparisonFeatures } = options,
                activeFeatureIds = Array.isArray(activeFeature)
                    ? activeFeature.map((f) => f.id.toString())
                    : [activeFeature.id.toString()],
                {
                    mode = 'filled',
                    iconType,
                    highlightSelectedFeature,
                    highlightColor,
                    iconSize,
                    iconAlignment = 'auto',
                    iconAlignmentVertical = 'bottom',
                    iconLayout = 'row-wrapped',
                    includeAllAreas = false
                } = settings, // Most commonly used, expand to clean up code...
                dataTable = DataManipulator.mergeTables(data, !includeAllAreas), // Discard non-common features? unless they are comparisons
                dataAvailable =
                    dataTable !== undefined &&
                    dataTable !== null &&
                    dataTable.colIds !== undefined &&
                    dataTable.colIds.length > 0 &&
                    dataTable.rows !== undefined &&
                    dataTable.rows !== null,
                scaleBetween = this.getScaleValues(dataTable),
                styleOverride =
                    highlightSelectedFeature && !isNullOrWhitespace(highlightColor) ? `color: ${highlightColor}` : '',
                iAliases = this.indicatorAliases,
                aliasesProvided = iAliases !== undefined && iAliases !== null && iAliases.length > 0,
                findAliasById = (indId) => {
                    return iAliases.find((ia) => ia.id === indId).label;
                },
                idStem = `w${settings.id.replace(/[^0-9a-zA-Z]/g, '')}`,
                nfmt = getNumberFormatter(settings.locale, settings.numberFormat),
                maxFill =
                    settings.iconsFillTo !== undefined &&
                    settings.iconsFillTo !== null &&
                    settings.iconsFillTo !== '' &&
                    settings.iconsFillTo !== '[auto]'
                        ? parseFloat(settings.iconsFillTo)
                        : scaleBetween[1],
                availableBoxColors = iconType === 'Box' ? settings.palette.split(/,| /) : [];
            let diff = scaleBetween[1] !== Number.MIN_SAFE_INTEGER ? scaleBetween[1] - scaleBetween[0] : 0,
                iconSizeNum = parseFloat(iconSize.replace(/[^0-9.]/g, '')),
                valuePerSquare =
                    iconType === 'Box'
                        ? Math.abs(Math.max(1, scaleBetween[0]) / parseFloat(settings.boxMinimumSize))
                        : Math.abs(scaleBetween[1] / (iconSizeNum * iconSizeNum)); // Presuming squares for calculations;
            // Basic setup...
            iconContentElement.setAttribute('id', `${idStem}icons`);
            let style = 'z-index: 10;'; // overflow: hidden;???
            //style += `text-align: ${(iconAlignment.toLowerCase() !== 'auto' ? iconAlignment.toLowerCase() : 'center')};`;
            style += `display: flex; flex-direction: ${iconLayout.split('-')[0]}; justify-content: ${
                iconAlignment.toLowerCase() !== 'left'
                    ? 'flex-start'
                    : iconAlignment.toLowerCase() !== 'right'
                    ? 'flex-end'
                    : 'space-evenly'
            }; text-align: center;`;
            if (iconLayout.indexOf('-wrap') > 0) style += 'flex-wrap: wrap; ';
            if (!isNullOrWhitespace(iconAlignmentVertical))
                style += `align-items: ${
                    iconAlignmentVertical === 'top'
                        ? 'flex-start'
                        : iconAlignmentVertical === 'bottom'
                        ? 'flex-end'
                        : iconAlignmentVertical
                };`;
            else style += 'align-items: flex-start;';
            if (!isNullOrWhitespace(settings.messagePadding))
                style += `padding: ${lengthWithUnit(settings.messagePadding)};`;
            if (!isNullOrWhitespace(settings.messageBackground))
                style += `background-color: ${settings.messageBackground};`;
            if (style.length > 0) iconContentElement.setAttribute('style', style);
            // Only one column is tested if the diff is zero...
            if (diff === 0 && dataAvailable) {
                diff = 0;
                for (let r of dataTable.rows) {
                    if (typeof r[1] === 'number') diff = Math.max(r[1], diff);
                }
            }
            let maxDisplayValue = Math.max(diff, scaleBetween[1]),
                elmnt;
            // Default style for icons - could maybe be factored out into <head> or <style>
            let iconStyle = '',
                iconStartStyle = '',
                iconFillStyle = '',
                iconBoxStyle = '',
                //iconKeyFrames = '',
                iconAnimDelay = 0,
                iconAnimTime = 0;
            //iconStyle += `color: ${settings.iconColor};`;
            if (mode === 'filled') {
                if (settings.iconBorders)
                    iconStyle += `border: ${lengthWithUnit(settings.iconBorderWidth)} solid ${
                        settings.iconBorderColor
                    };`;
                if (!isNullOrWhitespace(settings.iconPadding))
                    iconFillStyle += `padding: ${lengthWithUnit(settings.iconPadding, 'px', true, 4)};`;
                //if (!isNullOrWhitespace(settings.iconMargin)) iconFillStyle += `margin: ${lengthWithUnit(settings.iconMargin)};`;
            }
            if (!isNullOrWhitespace(settings.iconPadding))
                iconStyle += `padding: ${lengthWithUnit(settings.iconPadding, 'px', true, 4)};`;
            if (!isNullOrWhitespace(settings.iconMargin))
                iconBoxStyle += `margin: ${lengthWithUnit(settings.iconMargin, 'px', true, 4)};`;
            // Animation?
            if (
                settings.animationDuration !== undefined &&
                settings.animationDuration !== null &&
                !isNaN((iconAnimTime = parseFloat(settings.animationDuration))) &&
                iconAnimTime > 0
            ) {
                //iconKeyFrames = `@keyframes animIcon${idStem} { 0% { clip-path: inset(0 100% 0 0); } 99% { clip-path: inset(0 0 0 0); } 100% { clip-path: none; } }`;
                iconAnimDelay = parseFloat(settings.animationDelay);
                if (mode === 'filled') {
                    switch (settings.iconDisplay) {
                        case 'FillBottomUp':
                            iconStartStyle += `clip-path: inset(100% 0 0) !important;`;
                            break;
                        case 'FillTopDown':
                            iconStartStyle += `clip-path: inset(0 0 100% 0) !important;`;
                            break;
                        case 'FillLeftRight':
                            iconStartStyle += `clip-path: inset(0 100% 0 0) !important;`; //  animation-duration: ${iconAnimTime}s; animation-name: animIcon${idStem}; animation-fill-mode: forwards;
                            break;
                        case 'FillRightLeft':
                            iconStartStyle += `clip-path: inset(0 0 0 100%) !important;`;
                            break;
                        default:
                            iconStartStyle = '';
                    }
                    iconFillStyle += `transition: clip-path ${settings.animationDuration}s ease ${iconAnimDelay}s;`;
                } else if (mode === 'scaled') {
                    iconStartStyle +=
                        iconType === 'Image' || iconType === 'Box'
                            ? 'width: 0px !important; height: 0px !important;'
                            : 'font-size: 0px !important;';
                    iconStyle +=
                        iconType === 'Image' || iconType === 'Box'
                            ? `transition: width ${settings.animationDuration}s ease ${iconAnimDelay}s, height ${settings.animationDuration}s ease ${iconAnimDelay}s;`
                            : `transition: font-size ${settings.animationDuration}s ease ${iconAnimDelay}s;`;
                }
                addClass(topWidget, 'ia-animated');
            }
            elmnt = doc.createElement('style');
            elmnt.setAttribute('class', 'ia-generated');
            let generatedCss = '';
            if (mode === 'filled')
                generatedCss =
                    generatedCss +
                    `#${idStem}icons .ia-icon-set > .ia-icon { ${iconStyle} line-height: 1; } #${idStem}icons .ia-icon-set-filler { ${iconFillStyle} } .ia-report-widget:not(.ia-showing) #${idStem}icons .ia-icon-set-filler { ${iconStartStyle} } #${idStem}icons .ia-icon-set { ${iconBoxStyle} }`;
            else
                generatedCss =
                    generatedCss +
                    `#${idStem}icons .ia-icon-set > .ia-icon { ${iconStyle} } .ia-report-widget:not(.ia-showing) #${idStem}icons .ia-icon { ${iconStartStyle} } #${idStem}icons .ia-icon-set {  padding: 0 ${lengthWithUnit(
                        settings.iconGap || '2px'
                    )} 0 ${lengthWithUnit(settings.iconGap || '2px')}; }`;
            // Labels
            const {
                showIconLabels = 'none',
                iconLabelFormat = '',
                iconLabelFontSize,
                iconLabelColor,
                iconLabelBold = false,
                iconLabelRotation = 0
            } = settings;
            generatedCss = generatedCss + `#${idStem}icons .icon-label { `;
            if (iconLabelFontSize !== undefined) generatedCss += `font-size: ${lengthWithUnit(iconLabelFontSize)};`;
            if (iconLabelColor !== undefined) generatedCss += `color: ${iconLabelColor};`;
            if (iconLabelBold === true) generatedCss += `font-weight: bold;`;
            if (showIconLabels.toString().toLowerCase() === 'center')
                generatedCss += 'position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);';
            if (iconLabelRotation !== 0) generatedCss += `transform: rotate(${iconLabelRotation}deg);`;
            generatedCss += ' }';
            elmnt.appendChild(doc.createTextNode(generatedCss));
            iconContentElement.parentNode.insertBefore(elmnt, iconContentElement);
            // Got data?
            if (data !== undefined && data !== null && scaleBetween[1] !== Number.MIN_SAFE_INTEGER) {
                let sortable = [],
                    val,
                    iconClass,
                    iat = 0,
                    iconColorAt,
                    iconBoxContainer,
                    iconBox,
                    iconBoxDisplay = 'inline-block',
                    icon,
                    iconLabel,
                    f,
                    ind,
                    txt;
                const isBoxyLayout = iconType === 'Box' || iconLayout === 'fill-space';
                // Indicators, columns...
                for (let i = 1; i < dataTable.colIds.length; i++) {
                    if (
                        i === 0 ||
                        dataTable.colIds[i].type === undefined ||
                        (dataTable.colIds[i].type !== 'numerator' && dataTable.colIds[i].type !== 'denominator')
                    ) {
                        sortable = [];
                        ind = findIndicator(dataTable.indicators, dataTable.colIds[i].iid);
                        for (let j = 0; j < dataTable.rowIds.length; j++) {
                            val = dataTable.rows[j][i];
                            if (
                                !isNaN(val) &&
                                (((f = findFeature(dataTable.features, dataTable.rowIds[j])) !== undefined &&
                                    (!f.comparison || settings.showComparisons)) ||
                                    (dataTable.features.length < 1 &&
                                        (dataTable.rowIds[j].substring(0, 1) !== '#' || settings.showComparisons)))
                            ) {
                                sortable.push({
                                    id: dataTable.rowIds[j].toString(),
                                    value: val,
                                    label: dataTable.rows[j][0],
                                    raw: dataTable.rows[j][i],
                                    comparison:
                                        f !== undefined ? f.comparison : dataTable.rowIds[j].substring(0, 1) === '#'
                                });
                            }
                        }
                        // Basics - choosing and styling the icon...
                        iconClass = `${settings.fixedIconWidth ? 'fa-fw' : ''} ${
                            settings.icon.indexOf(' ') > 0
                                ? settings.icon
                                : `${settings.iconClassPrefix} fa-${settings.icon}`
                        }`;
                        if (isBoxyLayout) sortable.sort(sortIconsByValue).reverse();
                        else if (settings.iconSortByValue.toLowerCase() === 'ascending')
                            sortable.sort(sortIconsByValue);
                        else if (settings.iconSortByValue.toLowerCase() === 'descending')
                            sortable.sort(sortIconsByValue).reverse();
                        // All of them?
                        if (!settings.includeAllAreas)
                            sortable = sortable.filter(
                                (vp) => vp.comparison || activeFeatureIds.indexOf(vp.id.toString()) >= 0
                            );
                        // Still got something to show?
                        if (sortable.length > 0) {
                            const boxes = [],
                                bm = iconType === 'Box' ? parseFloat(settings.boxMargin) : 0;
                            for (let vp of sortable) {
                                if (settings.includeAllAreas || settings.showComparisons) {
                                    if (
                                        activeFeature !== undefined &&
                                        activeFeature !== null &&
                                        activeFeatureIds.indexOf(vp.id.toString()) >= 0 &&
                                        highlightSelectedFeature
                                    )
                                        iconColorAt = highlightColor;
                                    else if (iconType === 'Box')
                                        iconColorAt =
                                            availableBoxColors[
                                                iat < availableBoxColors.length ? iat : iat % availableBoxColors.length
                                            ];
                                    else iconColorAt = mode === 'filled' ? settings.iconFillColor : settings.iconColor;
                                } else if (iconType === 'Box')
                                    iconColorAt =
                                        availableBoxColors[
                                            iat < availableBoxColors.length ? iat : iat % availableBoxColors.length
                                        ];
                                else iconColorAt = mode === 'filled' ? settings.iconFillColor : settings.iconColor;
                                iconBoxContainer = null;
                                iconBox = doc.createElement('div');
                                iconBox.setAttribute(
                                    'class',
                                    `ia-icon-set ${settings.showBoxLabels ? '' : 'pure-tip pure-tip-top'}`
                                );
                                if (settings.tooltipFormat !== undefined && settings.tooltipFormat !== null) {
                                    iconBox.setAttribute(
                                        'data-tooltip',
                                        settings.tooltipFormat
                                            .replace(/#COLOR/g, '') // TODO
                                            .replace(/#FNAME/g, vp.label)
                                            .replace(/#FID/g, vp.id)
                                            .replace(
                                                /#INAME/g,
                                                aliasesProvided
                                                    ? findAliasById(ind.id)
                                                    : settings.labelStyle === 'ShortName'
                                                    ? ind.shortName || ind.name
                                                    : ind.name
                                            )
                                            .replace(/#IDATE/g, dataTable.colIds[i].label)
                                            .replace(/(#IVALUE|#VALUE)/g, nfmt.format(vp.raw, settings.numberFormat))
                                    );
                                }
                                //iconBox.setAttribute('data-aria-label', vp.label);
                                iconBox.setAttribute('data-value', vp.raw);
                                if (iconType === 'Image') {
                                    icon = doc.createElement('img');
                                    icon.setAttribute('alt', 'icon');
                                    icon.setAttribute('class', `ia-icon ia-icon-image`);
                                    if (
                                        settings.iconBackgroundImage !== undefined &&
                                        settings.iconBackgroundImage !== null &&
                                        settings.iconBackgroundImage !== ''
                                    ) {
                                        icon.setAttribute(
                                            'src',
                                            settings.iconBackgroundImage
                                                .replace(/(#NAME|#FNAME)/g, vp.label)
                                                .replace(/#FID/g, vp.id)
                                        );
                                    } else
                                        icon.setAttribute(
                                            'src',
                                            settings.iconImage
                                                .replace(/(#NAME|#FNAME)/g, vp.label)
                                                .replace(/#FID/g, vp.id)
                                        );
                                    icon.setAttribute('style', 'width: 100%; height: 100%;');
                                    iconBox.appendChild(icon);
                                } else if (iconType === 'Box') {
                                    icon = doc.createElement('div');
                                    icon.setAttribute('class', `ia-icon ia-icon-box`);
                                    iconBox.appendChild(icon);
                                    // Labels
                                    if (settings.showBoxLabels) {
                                        txt = settings.boxLabelFormat
                                            .replace(/#COLOR/g, '') // TODO
                                            .replace(/#FNAME/g, vp.label)
                                            .replace(/#FID/g, vp.id)
                                            .replace(
                                                /#INAME/g,
                                                aliasesProvided
                                                    ? findAliasById(ind.id)
                                                    : settings.labelStyle === 'ShortName'
                                                    ? ind.shortName || ind.name
                                                    : ind.name
                                            )
                                            .replace(/#IDATE/g, dataTable.colIds[i].label)
                                            .replace(/(#IVALUE|#VALUE)/g, nfmt.format(vp.raw, settings.numberFormat));
                                        iconLabel = doc.createElement('span');
                                        iconLabel.setAttribute('class', 'icon-display-value');
                                        if (txt.indexOf('</') > 0) iconLabel.innerHTML = txt;
                                        else iconLabel.appendChild(doc.createTextNode(txt));
                                        icon.appendChild(iconLabel);
                                    }
                                } else {
                                    icon = doc.createElement('i');
                                    icon.setAttribute('class', `ia-icon ${iconClass}`);
                                    iconBox.appendChild(icon);
                                    // Labels
                                    if (
                                        showIconLabels.toString().toLowerCase() !== 'none' &&
                                        showIconLabels.toString().toLowerCase() !== 'false'
                                    ) {
                                        txt = iconLabelFormat
                                            .replace(/#COLOR/g, '') // TODO
                                            .replace(/#FNAME/g, vp.label)
                                            .replace(/#FID/g, vp.id)
                                            .replace(
                                                /#INAME/g,
                                                aliasesProvided
                                                    ? findAliasById(ind.id)
                                                    : settings.labelStyle === 'ShortName'
                                                    ? ind.shortName || ind.name
                                                    : ind.name
                                            )
                                            .replace(/#IDATE/g, dataTable.colIds[i].label)
                                            .replace(/(#IVALUE|#VALUE)/g, nfmt.format(vp.raw, settings.numberFormat));
                                        iconLabel = doc.createElement('div');
                                        iconLabel.setAttribute('class', 'icon-label');
                                        style = '';
                                        if (style !== '') iconLabel.setAttribute('style', style);
                                        if (txt.indexOf('</') > 0) iconLabel.innerHTML = txt;
                                        else iconLabel.appendChild(doc.createTextNode(txt));
                                        if (showIconLabels.toString().toLowerCase() === 'top')
                                            iconBox.insertBefore(iconLabel, iconBox.firstChild);
                                        else iconBox.appendChild(iconLabel);
                                    }
                                }
                                if (mode === 'filled') {
                                    iconStyle =
                                        iconType === 'Image'
                                            ? `width: ${lengthWithUnit(settings.iconSize)}; height: ${lengthWithUnit(
                                                  settings.iconSize
                                              )}; display: ${iconBoxDisplay}; flex: 1 1 auto;`
                                            : `font-size: ${lengthWithUnit(
                                                  settings.iconSize
                                              )}; display: ${iconBoxDisplay}; color: ${
                                                  settings.iconColor
                                              }; flex: 1 1 auto;`;
                                    iconBox.setAttribute('style', iconStyle);
                                    const currentIcon = iconBox.querySelector('.ia-icon');
                                    elmnt = doc.createElement('div');
                                    iconBox.insertBefore(elmnt, currentIcon);
                                    elmnt.appendChild(currentIcon);
                                    val = ((vp.value - scaleBetween[0]) / diff) * 100.0;
                                    elmnt = doc.createElement('div');
                                    elmnt.setAttribute('class', 'ia-icon-set-filler');
                                    iconStyle = `position: absolute; left: 0; top: 0; width: 100%; height: 100%; font-size: ${lengthWithUnit(
                                        settings.iconSize
                                    )};`;
                                    if (settings.iconFillStyle.toLowerCase() === 'background')
                                        iconStyle += `background-color: ${iconColorAt}; color: ${settings.iconInvertedFillColor};`;
                                    else iconStyle += `color: ${iconColorAt};`;
                                    switch (settings.iconDisplay) {
                                        case 'FillBottomUp':
                                            iconStyle += `clip-path: inset(${100 - val}% 0 0);`;
                                            break;
                                        case 'FillTopDown':
                                            iconStyle += `clip-path: inset(0 0 ${100 - val}% 0);`;
                                            break;
                                        case 'FillLeftRight':
                                            iconStyle += `clip-path: inset(0 ${100 - val}% 0 0);`; //  animation-duration: ${iconAnimTime}s; animation-name: animIcon${idStem}; animation-fill-mode: forwards;
                                            break;
                                        case 'FillRightLeft':
                                            iconStyle += `clip-path: inset(0 0 0 ${100 - val}%);`;
                                            break;
                                        default:
                                            break;
                                    }
                                    elmnt.setAttribute('style', iconStyle);
                                    if (iconType === 'Image') {
                                        icon = doc.createElement('img');
                                        icon.setAttribute('alt', 'icon');
                                        icon.setAttribute('class', `ia-icon ia-icon-image`);
                                        if (
                                            activeFeature !== undefined &&
                                            activeFeature !== null &&
                                            activeFeatureIds.indexOf(vp.id.toString()) >= 0 &&
                                            highlightSelectedFeature &&
                                            settings.highlightIconImage !== undefined &&
                                            settings.highlightIconImage !== null &&
                                            settings.highlightIconImage !== ''
                                        ) {
                                            icon.setAttribute(
                                                'src',
                                                settings.highlightIconImage
                                                    .replace(/(#NAME|#FNAME)/g, vp.label)
                                                    .replace(/#FID/g, vp.id)
                                            );
                                        } else
                                            icon.setAttribute(
                                                'src',
                                                settings.iconImage
                                                    .replace(/(#NAME|#FNAME)/g, vp.label)
                                                    .replace(/#FID/g, vp.id)
                                            );
                                        icon.setAttribute('style', 'width: 100%; height: 100%;');
                                        elmnt.appendChild(icon);
                                    } else {
                                        icon = doc.createElement('i');
                                        icon.setAttribute('class', `ia-icon ${iconClass}`);
                                        elmnt.appendChild(icon);
                                    }
                                    currentIcon.parentNode.appendChild(elmnt);
                                    currentIcon.parentNode.setAttribute('class', `ia-icon`);
                                } else if (mode === 'scaled') {
                                    iconStyle = isBoxyLayout // iconType === 'Box'
                                        ? `display: block; margin: ${lengthWithUnit(
                                              settings.boxMargin,
                                              'px',
                                              true,
                                              4
                                          )};`
                                        : '';
                                    iconBox.setAttribute('style', iconStyle);
                                    val = Math.sqrt(vp.value / valuePerSquare);
                                    //rounded = (int)Math.Round(Math.Sqrt(tv));
                                    iconStyle =
                                        iconType === 'Image' || iconType === 'Box'
                                            ? `width: ${val.toFixed(1)}px; height: ${val.toFixed(1)}px;`
                                            : `font-size: ${val.toFixed(1)}px;`;
                                    if (isBoxyLayout) {
                                        boxes.push({
                                            top: 0,
                                            left: 0,
                                            width: val + 2 * bm,
                                            height: val + 2 * bm,
                                            node: iconBox,
                                            cssWidth: `${val.toFixed(1)}px`,
                                            cssHeight: `${val.toFixed(1)}px`
                                        });
                                    }
                                    if (iconType === 'Box') {
                                        iconStyle += `background-color: ${iconColorAt};`;
                                    } else {
                                        iconStyle += `display: ${iconBoxDisplay}; color: ${iconColorAt}; flex: 1 1 auto;`;
                                    }
                                    (iconType === 'Box' ? icon : iconBox).setAttribute('style', iconStyle);
                                }
                                if (!isBoxyLayout) iconContentElement.appendChild(iconBox);
                                // TODO - labels
                                iat++;
                            }
                            // Boxes - we've made the elements, now do something with them...
                            if (isBoxyLayout) {
                                // iconType === 'Box' || iconLayout === 'fill-space') {
                                const rows = [
                                        {
                                            boxes: [],
                                            maxHeight: 0
                                        }
                                    ],
                                    containerWidth =
                                        Math.max(parseFloat(settings.width), topWidget.offsetWidth) -
                                        parseFloat(settings.messagePadding) * 2 -
                                        10, // -10 just to give a bit of leeway for borders etc.
                                    animOffset =
                                        (settings.animationDuration !== undefined
                                            ? settings.animationDuration * 1000.0
                                            : 0) / boxes.length,
                                    animDelay =
                                        settings.animationDelay !== undefined ? settings.animationDelay * 1000.0 : 0;
                                for (let i = 0; i < boxes.length; i++) {
                                    let inserted = false,
                                        j = 0,
                                        boxDelay = animDelay + i * animOffset;
                                    boxes[i].animOffset = boxDelay;
                                    while (j < rows.length && !inserted) {
                                        inserted = fitsInRow(rows[j], boxes[i], containerWidth);
                                        j++;
                                    }
                                    if (!inserted) {
                                        rows.push({
                                            boxes: [],
                                            maxHeight: 0
                                        });
                                        inserted = fitsInRow(rows[rows.length - 1], boxes[i], containerWidth);
                                    }
                                }
                                let voff = 0;
                                for (let i = 0; i < rows.length; i++) {
                                    voff += rows[i].maxHeight;
                                }
                                iconBoxContainer = doc.createElement('div');
                                iconBoxContainer.setAttribute(
                                    'style',
                                    `position: relative; width: ${containerWidth}px; min-height: ${voff}px; height: 100%;`
                                );
                                voff = 0;
                                let bm = settings.boxMargin !== undefined ? parseFloat(settings.boxMargin) : 0,
                                    boxOff = 0;
                                const xProp =
                                        isNullOrWhitespace(settings.layoutAnchor) ||
                                        hyphenate(settings.layoutAnchor) === 'top-left' ||
                                        hyphenate(settings.layoutAnchor) === 'bottom-left'
                                            ? 'left'
                                            : 'right',
                                    yProp =
                                        isNullOrWhitespace(settings.layoutAnchor) ||
                                        hyphenate(settings.layoutAnchor) === 'top-left' ||
                                        hyphenate(settings.layoutAnchor) === 'top-right'
                                            ? 'top'
                                            : 'bottom';
                                for (let i = 0; i < rows.length; i++) {
                                    for (let box of rows[i].boxes) {
                                        const be = box.node,
                                            bc = iconBoxContainer,
                                            boxIns = () => {
                                                bc.appendChild(be);
                                            },
                                            boxDelay = box.animOffset;
                                        be.style.position = 'absolute';
                                        be.style.display = 'block';
                                        be.style.width = box.cssWidth;
                                        be.style.height = box.cssHeight;
                                        be.style[xProp] = `${box.left + bm}px`;
                                        be.style[yProp] = `${box.top + voff + bm}px`;
                                        if (boxDelay > 0) setTimeout(boxIns, boxDelay);
                                        else boxIns();
                                        boxOff++;
                                    }
                                    voff += rows[i].maxHeight;
                                }
                                iconContentElement.appendChild(iconBoxContainer);
                            }
                        }
                    }
                }
                this.appendAlternateViewActions(settings, data);
            }
            if (topWidget !== null) {
                // Peg back the partial icons only when the widget becomes visible
                if (
                    (hasClass(topWidget, 'ia-animated') ||
                        topWidget.querySelectorAll('div.ia-partial-icon').length > 0) &&
                    typeof IntersectionObserver !== 'undefined'
                ) {
                    const iconObserver = new IntersectionObserver(
                        (entries, observer) => {
                            entries.forEach((entry) => {
                                const isAnimated = hasClass(entry.target, 'ia-animated');
                                if (isAnimated) {
                                    if (entry.intersectionRatio > 0) addClass(entry.target, 'ia-showing');
                                    else if (!hasClass(entry.target, 'ia-fixed-view'))
                                        removeClass(entry.target, 'ia-showing');
                                }
                            });
                        },
                        {
                            root: document.documentElement,
                            threshold: 0.75
                        }
                    );
                    iconObserver.observe(topWidget);
                }
                topWidget.setAttribute('class', topWidget.getAttribute('class').replace(/\s(placeholder)/g, ''));
            }
        }
    };

    static getDefaults = () => {
        return {
            ...AbstractIconWidget.getDefaults(),
            iconsFillTo: '1',
            iconFillColor: '#8CC63F',
            iconInvertedFillColor: '#ffffff',
            iconFillStyle: 'Icon', // Or Background
            iconDisplay: 'FillBottomUp', // Or FillTopDown, FillLeftRight, FillRightLeft
            iconLabelFormat: '#INAME',
            iconLabelColor: '#333333',
            iconLabelFontSize: '13px',
            showIconLabels: 'none', // Or Top, Bottom, Middle
            iconMargin: '', // '' = 'auto'
            iconBackgroundImage: ''
        };
    };
}

const findFeature = (featureList, id) => {
    return featureList.find((f) => f.id === id);
};

const findIndicator = (indicatorList, id) => {
    return indicatorList.find((f) => f.id === id);
};

const sortIconsByValue = (a, b) => {
    return a.value - b.value;
};

const fitsInRow = (row, box, maxWidth) => {
    if (row.boxes.length < 1) {
        row.boxes.push(box);
        row.maxHeight = box.height;
        return true;
    }
    let xoff = 0;
    for (let i = 0; i < row.boxes.length; i++) {
        xoff = Math.max(row.boxes[i].width + row.boxes[i].left, xoff);
    }
    // Fit horizontally?
    let willFitX = xoff + box.width <= maxWidth,
        willFitY = false;
    for (let i = 0; i < row.boxes.length; i++) {
        // Fit vertically?
        let y = row.boxes[i].top + row.boxes[i].height;
        willFitY = y + box.height <= row.maxHeight;
        if (willFitY) {
            let x = row.boxes[i].left,
                xm = row.boxes[i].left + row.boxes[i].width;
            // Anything else in this placement?
            for (let j = 0; j < row.boxes.length; j++) {
                if (
                    j !== i &&
                    y === row.boxes[j].top &&
                    row.boxes[j].left >= row.boxes[i].left &&
                    row.boxes[j].left < xm
                )
                    x += row.boxes[j].width;
            }
            if (x + box.width <= xm) {
                box.left = x;
                box.top = y;
                row.boxes.push(box);
                return true;
            }
        }
    }
    if (willFitX) {
        box.left = xoff;
        box.top = 0;
        row.boxes.push(box);
        return true;
    }
    return false;
};

export class FilledIconWidget extends ProportionalIconWidget {
    constructor(container, design) {
        super(container, {
            iconGap: '2px',
            ...design,
            mode: 'filled'
        });
    }
}

export class ScaledIconWidget extends ProportionalIconWidget {
    constructor(container, design) {
        super(container, {
            iconGap: '0',
            ...design,
            mode: 'scaled'
        });
    }
}

export class ScaledBoxWidget extends ProportionalIconWidget {
    constructor(container, design) {
        super(container, {
            iconGap: '0',
            palette: '#fbb3ad,#b2cce2,#ccebc5,#decbe4,#fed9a5,#ffffcc,#e4d7bc,#fddaec',
            boxMinimumSize: 9,
            boxMargin: '5px 5px 5px 5px',
            layoutAnchor: 'top-left',
            ...design,
            mode: 'scaled',
            iconType: 'Box'
        });
    }
}
