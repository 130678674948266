import AbstractWidget, { lengthWithUnit, convertLengthToPixels } from './AbstractWidget';
import AbstractChartWidget, {
    createDashedLine,
    getBestTextForWidth,
    getMaxTextWidth,
    getPointStyle
} from './AbstractChartWidget';
import { createLineImage } from './LineChartWidget';
import { TextWidget } from './TextWidget';
import {
    adjustForLimits,
    createIndicatorColumnTable,
    createIndicatorRowTable,
    createIndicatorDatesColumnTables,
    nullifyEmptyValues,
    splitTableByFeatureType,
    splitTableByIndicatorDate
} from './widgetDataUtils';
import { parseColor, STANDARD_PALETTES } from './widgetHelpers';
import { isNullOrEmpty, isNullOrWhitespace, hyphenate } from '../utils/object';
import { getNumberFormatter } from '../utils/localization';
import { DataManipulator } from 'data-catalog-js-api';
import ChartJS from '../lib/ChartJS';
import { Chart } from 'chart.js';

export default class BarChartWidget extends AbstractChartWidget {
    // Render here is slightly special, because it can _detect_ if the calling (React) class has laid out some of the HTML before calling this method.
    render = (data, options = {}) => {
        let chartData;
        if (
            this.container !== undefined &&
            this.container !== null &&
            this.design !== undefined &&
            this.design !== null
        ) {
            // Basics - this will give us the box or the chart content area... using super class
            const settings = {
                    ...BarChartWidget.getDefaults(),
                    ...this.design
                },
                chartContentElement =
                    options.canvas !== undefined
                        ? null
                        : this.renderBox(data, {
                              element: 'figure',
                              boxClass: 'ia-chart-box',
                              showTitle: hyphenate(settings.titlePosition) !== 'inside-chart',
                              messagePadType: 'padding',
                              allowBackgroundFill: false,
                              settings: {
                                  ...settings,
                                  messageBackground: settings.backgroundColor // Transfer chart background to message as well
                              }
                          }),
                doc = this.container.ownerDocument,
                topWidget = this.container.closest('.ia-report-widget'),
                { activeFeature, comparisonFeatures } = options,
                iAliases = this.indicatorAliases,
                aliasesProvided = iAliases !== undefined && iAliases !== null && iAliases.length > 0,
                palette =
                    settings.palette !== undefined && settings.palette !== null && settings.palette !== ''
                        ? AbstractChartWidget.getPaletteColors(settings.palette)
                        : [],
                compsPalette =
                    settings.comparisonsPalette !== undefined &&
                    settings.comparisonsPalette !== null &&
                    settings.comparisonsPalette !== ''
                        ? AbstractChartWidget.getPaletteColors(settings.comparisonsPalette)
                        : [],
                nfmt = getNumberFormatter(
                    settings.locale,
                    settings.yAxisLabelFormat !== undefined
                        ? settings.yAxisLabelFormat.replace(/^\[blank\]$/, '')
                        : settings.numberFormat
                ),
                canvas =
                    options.canvas !== undefined
                        ? options.canvas
                        : this.createCanvas(settings, data, chartContentElement),
                {
                    seriesBindingStyle,
                    labelStyle = 'full-name',
                    comparisonLineInterpolation = 'linear',
                    seriesLabelFormat = '#NAME',
                    dataIndexOffset = 0,
                    showComparisons = false,
                    showComparisonsAsLines = false,
                    comparsionLineLabelFormat = '#NAME',
                    hideMainFeature = false,
                    useCountsPerDistinctValue = false
                } = settings;
            let dataTable = DataManipulator.mergeTables(
                    data.filter((d) => d.indicators !== undefined),
                    !settings.includeAllAreas
                ), // Discard non-common features  unless they are comparisons
                dataAvailable =
                    dataTable !== undefined &&
                    dataTable !== null &&
                    dataTable.colIds !== undefined &&
                    dataTable.colIds.length > 0 &&
                    dataTable.rows !== undefined &&
                    dataTable.rows !== null;
            const dataUnlikelyToBeChartable =
                    dataAvailable &&
                    (dataTable.colIds.length < 2 ||
                        dataTable.indicators === undefined ||
                        dataTable.indicators.length < 1),
                indicatorSeriesExplicitly = hyphenate(seriesBindingStyle) === 'indicators-as-series';
            if (dataUnlikelyToBeChartable) {
                //console.debug(`Data error from: ${JSON.stringify(data)}`); // DEBUG
                throw new Error(
                    `Unsupported data structure for bar chart: columns = [${
                        dataTable.headers !== undefined ? dataTable.headers.join(', ') : ''
                    }], indicators = [${
                        dataTable.indicators === undefined ? dataTable.indicators.map((i) => i.id).join(', ') : '?'
                    }], requested = ${settings.indicators !== undefined ? JSON.stringify(settings.indicators) : '[?]'}`
                );
            }
            //console.log(settings); // DEBUG
            if (dataAvailable) {
                if (settings.sortFeaturesByName && settings.includeAllAreas) DataManipulator.sortTable(dataTable); // TODO - need to deal with comaprisons here...
                // While still feature rows... (maybe redundant? See line below)
                if (hideMainFeature === true && options.activeFeature !== undefined) {
                    const fids = Array.isArray(options.activeFeature)
                        ? options.activeFeature.map((f) => f.id)
                        : [options.activeFeature.id];
                    for (let i = dataTable.rows.length - 1; i >= 0; i--) {
                        const rid = dataTable.rowIds[i];
                        if (fids.indexOf(rid) >= 0) {
                            dataTable.rowIds.splice(i, 1);
                            dataTable.rows.splice(i, 1);
                            dataTable.features.splice(
                                dataTable.features.findIndex((f) => f.id === rid),
                                1
                            );
                        }
                    }
                }
                AbstractWidget.filterTable(
                    dataTable,
                    hideMainFeature === true ? [] : null,
                    showComparisons === false
                        ? []
                        : !isNullOrWhitespace(settings.comparisonFeatureIds)
                        ? settings.comparisonFeatureIds.split(',').map((f) => f.split('|')[1])
                        : null
                );
                if (settings.aggregatedAreasAlias !== undefined && settings.aggregatedAreasAlias !== null)
                    AbstractWidget.cleanAggregateNames(dataTable, settings.aggregatedAreasAlias);
                if (!showComparisons) {
                    for (let i = dataTable.rows.length - 1; i >= 0; i--) {
                        const rid = dataTable.rowIds[i],
                            rf = dataTable.features.find((f) => f.id === rid);
                        if (rf === undefined || rf.comparison) {
                            dataTable.rowIds.splice(i, 1);
                            dataTable.rows.splice(i, 1);
                            dataTable.features.splice(
                                dataTable.features.findIndex((f) => f.id === rid),
                                1
                            );
                        }
                    }
                }
                let singleTimeSlice = [...new Set(dataTable.headers.slice(1))].length === 1,
                    //(hyphenate(seriesBindingStyle) !== 'indicators-as-series') &&
                    groupByDate =
                        ((hyphenate(seriesBindingStyle) === 'features-dates-as-series' &&
                            dataTable.rowIds.length === 1) ||
                            (settings.groupSeriesByIndicatorDate === true && dataTable.rowIds.length === 1)) &&
                        !singleTimeSlice,
                    indicatorSeriesProbably =
                        indicatorSeriesExplicitly ||
                        (hyphenate(seriesBindingStyle) === 'features-as-series' && dataTable.rowIds.length === 1) ||
                        groupByDate,
                    chartConfigKey = 'timeseries',
                    chartTitleText = TextWidget.insertValuesIntoText(
                        settings.titleText,
                        data,
                        settings.numberFormat,
                        settings.locale,
                        '',
                        false
                    ),
                    labels;
                indicatorSeriesProbably = false; // Testing, I'm not sure I want to support incorrect settings going forward...
                //if (groupByDate) dataTable = DataManipulator.transposeTable(dataTable);
                // Set the value indices on the columns, to pick data up later...
                for (let i = 0; i < dataTable.colIds.length; i++) dataTable.colIds[i].index = i;
                // But if required, re-sort
                if (aliasesProvided)
                    AbstractWidget.applySortAndRename(dataTable.colIds, iAliases, dataTable.indicators, dataTable.rows);
                // One time slice? Or different binding? Then the headers need adjustment...
                if (hyphenate(seriesBindingStyle) !== 'indicators-as-series') {
                    // singleTimeSlice &&
                    let aind;
                    const startWidth = dataTable.colIds.length;
                    for (let i = dataTable.colIds.length - 1; i >= 1; i--) {
                        if (dataTable.colIds[i].type === 'value') {
                            aind =
                                dataTable.indicators.find((ii) => ii.id === dataTable.colIds[i].iid) ||
                                dataTable.indicators[i - 1];
                            aind =
                                aliasesProvided &&
                                iAliases.find((ia) => ia.id === dataTable.colIds[i].iid) !== undefined
                                    ? iAliases.find((ia) => ia.id === dataTable.colIds[i].iid).label
                                    : aind !== undefined
                                    ? hyphenate(labelStyle) === 'short-name'
                                        ? aind.shortName
                                        : aind.name
                                    : settings.noDataText;
                            dataTable.headers[i] = settings.xAxisLabelFormat
                                .replace(/^\[blank\]|\[tick\]$/, '#NAME') // [blank] is special, but is handled elsewhere
                                .replace(/#IDATE/g, dataTable.headers[i])
                                .replace(/#DATE/g, dataTable.headers[i] || aind)
                                .replace(/#NAME/g, aind)
                                .replace(/#FNAME/g, dataTable.rows[0][0])
                                .replace(/#INAME/g, aind);
                        } else dataTable.colIds.splice(i, 1);
                    }
                    if (startWidth > dataTable.colIds.length) {
                        const cutAt = dataTable.colIds.length;
                        for (let i = 0; i < dataTable.rows.length; i++) {
                            dataTable.rows[i].splice(cutAt);
                        }
                    }
                }
                // Split data up - by indicator to start with...or not, maybe, if single time slice
                let comparisonFeatures = dataTable.features.filter((f) => f.comparison === true),
                    splitCompTables = null,
                    multiValue = false;
                // Lines? If so, need to split comparisons out...
                if (
                    showComparisons &&
                    showComparisonsAsLines &&
                    !hideMainFeature &&
                    !useCountsPerDistinctValue &&
                    comparisonFeatures.length > 0 &&
                    indicatorSeriesExplicitly
                ) {
                    splitCompTables = splitTableByFeatureType(dataTable);
                }
                const limitsRequested =
                    settings.showUpperAndLowerLimits === true &&
                    settings.upperAndLowerLimitKeys !== undefined &&
                    settings.upperAndLowerLimitKeys !== null;
                let labelsPopulated = false;
                chartData =
                    splitCompTables !== null
                        ? splitCompTables
                        : indicatorSeriesExplicitly || indicatorSeriesProbably || limitsRequested
                        ? groupByDate && !singleTimeSlice
                            ? createIndicatorDatesColumnTables(dataTable, undefined, settings.xAxisSortByDate)
                            : DataManipulator.splitTable(dataTable)
                        : [dataTable];
                chartData = chartData.map((d, di) => {
                    const dsName =
                            aliasesProvided && iAliases.find((ia) => ia.id === d.indicators[0].id) !== undefined
                                ? iAliases.find((ia) => ia.id === d.indicators[0].id).label
                                : hyphenate(labelStyle) === 'short-name'
                                ? d.indicators[0].shortName
                                : d.indicators[0].name,
                        chartDataSet = {
                            id: d.indicators[0].id,
                            name: seriesLabelFormat
                                .replace(/#NAME/, dsName)
                                .replace(/#INAME/, dsName)
                                .replace(/#DATE/, d.headers.slice(1).join(', '))
                                .replace(
                                    /#INDEXALPHA|#INDEXA|#IDXA|#DATASETALPHA|#DATASETA/g,
                                    'abcdefghijklmnopqrstuvwxyz0123456789'.charAt(di + dataIndexOffset)
                                )
                                .replace(/#INDEX|#IDX|#DATASET/g, (di + 1 + dataIndexOffset).toFixed(0)),
                            fullName: d.indicators[0].fullName || d.indicators[0].name,
                            data: d,
                            colors: di < palette.length ? palette.slice(di) : palette.slice(di % palette.length)
                        };
                    if (compsPalette !== null && compsPalette.length > 0 && chartDataSet.data.features !== undefined) {
                        let offset = 0;
                        for (let i = 0; i < chartDataSet.data.features.length; i++) {
                            if (chartDataSet.data.features[i].comparison && offset < compsPalette.length) {
                                chartDataSet.colors[i] = compsPalette[offset];
                                offset++;
                            }
                        }
                    }
                    //if (settings.showComparisonsAsLines && (d.comparisonsOnly === true))
                    //{
                    //    chartDataSet.type = 'line';
                    //    chartDataSet.borderColor = [...chartDataSet.colors];
                    //    chartDataSet.lineTension = (settings.comparisonLineTension !== undefined ? settings.comparisonLineTension : 1.0);
                    //    chartDataSet.borderWidth = (settings.comparisonLineWidth !== undefined ? parseInt(settings.comparisonLineWidth.replace('px', '')) : 3);
                    //}
                    return chartDataSet;
                });
                let chartSubType = '',
                    datasetOptions = {
                        barPercentage: parseFloat(settings.barWidth),
                        cubicInterpolationMode: comparisonLineInterpolation === 'smooth-strict' ? 'monotone' : 'default'
                    };
                //console.log(chartData); // DEBUG
                // Specialist sub-type of chart - take the values and make them into a set of counts
                if (useCountsPerDistinctValue && indicatorSeriesExplicitly) {
                    let valueSet = [];
                    if (
                        settings.useRestrictedDistinctValues !== undefined &&
                        settings.useRestrictedDistinctValues !== null &&
                        settings.useRestrictedDistinctValues !== ''
                    )
                        valueSet = settings.useRestrictedDistinctValues.split(',');
                    // TODO - deal with looking up the distinct values
                    // Re-sort and re-count the data and adjust in-place to provide correct input to chart
                    for (let cd of chartData) {
                        const keyedCounts = new Map(
                            valueSet.map((k) => {
                                return [k, 0];
                            })
                        );
                        for (let r of cd.data.rows) {
                            const v = r[1] !== undefined && r[1] !== null ? r[1].toString() : '';
                            if (keyedCounts.has(v)) keyedCounts.set(v, keyedCounts.get(v) + 1);
                        }
                        cd.data.rowIds = Array.from(keyedCounts.keys());
                        cd.data.rows = Array.from(keyedCounts);
                        cd.colors = palette;
                    }
                }
                if (limitsRequested) {
                    // Adjust the series/data according to the keys in the settings...but check a change has actually been made!
                    const originalNumberOfDatasets = chartData.length;
                    chartData = adjustForLimits(chartData, settings);
                    const adjustedNumberOfDatasets = chartData.length;
                    const { limitsLineColor = '#2c2c2c', limitsLineWidth = '1px', limitsEndLineWidth = 0.4 } = settings;
                    datasetOptions = {
                        ...datasetOptions,
                        errorBarColor: limitsLineColor,
                        errorBarWhiskerColor: limitsLineColor,
                        errorBarLineWidth: convertLengthToPixels(limitsLineWidth, false, true),
                        errorBarWhiskerRatio:
                            typeof limitsEndLineWidth === 'number' ? limitsEndLineWidth : parseFloat(limitsEndLineWidth)
                    };
                    //errorBarWhiskerLineWidth
                    if (originalNumberOfDatasets !== adjustedNumberOfDatasets) chartSubType = 'WithErrorBars';
                    // What format was asked for? Might need to recombine the tables?
                    if (
                        hyphenate(seriesBindingStyle) !== 'indicators-as-series' &&
                        !indicatorSeriesProbably &&
                        chartData.length > 0
                    ) {
                        const allData = DataManipulator.mergeTables(chartData.map((cd) => cd.data));
                        chartData = chartData.slice(0, 1);
                        chartData[0].data = allData;
                    }
                }
                if (indicatorSeriesExplicitly && chartData[0].data.rowIds.length > 1) {
                    chartConfigKey = 'rankedfeatures';
                    labelsPopulated = true;
                } else if (indicatorSeriesExplicitly || indicatorSeriesProbably) {
                    // && !singleTimeSlice))
                    if (seriesLabelFormat !== undefined) {
                        let aind,
                            offset = 0;
                        for (let cd of chartData) {
                            cd.name =
                                cd.data.rows[0][0].name !== undefined ? cd.data.rows[0][0].name : cd.data.rows[0][0];
                            if (groupByDate && cd.data.rows[0][0].date !== undefined) {
                                cd.data.rows[0][0] = seriesLabelFormat
                                    .replace(/#NAME/g, cd.data.rows[0][0].date)
                                    .replace(/#DATE/g, cd.data.rows[0][0].date)
                                    .replace(/#FNAME/g, cd.data.rows[0][0].name)
                                    .replace(/#INAME/g, cd.data.rows[0][0].name)
                                    .replace(
                                        /#INDEXSYM|#IDXSYM|#DATASETSYM/g,
                                        '①②③④⑤⑥⑦⑧⑨⑩⑪⑫⑬⑭⑮⑯⑰⑱⑲⑳⓪'.charAt(offset + dataIndexOffset)
                                    )
                                    .replace(
                                        /#INDEXSUP|#IDXSUP|#DATASETSUP/g,
                                        '¹²³⁴⁵⁶⁷⁸⁹⁰'.charAt(offset + dataIndexOffset)
                                    )
                                    .replace(
                                        /#INDEXALPHA|#INDEXA|#IDXA|#DATASETALPHA|#DATASETA/g,
                                        'abcdefghijklmnopqrstuvwxyz0123456789'.charAt(offset + dataIndexOffset)
                                    )
                                    .replace(/#INDEX|#IDX|#DATASET/g, (offset + 1 + dataIndexOffset).toFixed(0));
                            } else {
                                aind =
                                    aliasesProvided &&
                                    iAliases.find((ia) => ia.id === cd.data.indicators[0].id) !== undefined
                                        ? iAliases.find((ia) => ia.id === cd.data.indicators[0].id).label
                                        : labelStyle.toLowerCase() === 'shortname' ||
                                          labelStyle.toLowerCase() === 'short-name'
                                        ? cd.data.indicators[0].shortName
                                        : cd.data.indicators[0].name;
                                cd.data.rows[0][0] = seriesLabelFormat
                                    .replace(
                                        /#NAME/g,
                                        (indicatorSeriesExplicitly || indicatorSeriesProbably) && chartData.length > 1
                                            ? aind
                                            : cd.name
                                    )
                                    .replace(/#FNAME/g, cd.name)
                                    .replace(/#INAME/g, aind)
                                    .replace(
                                        /#INDEXSYM|#IDXSYM|#DATASETSYM/g,
                                        '①②③④⑤⑥⑦⑧⑨⑩⑪⑫⑬⑭⑮⑯⑰⑱⑲⑳⓪'.charAt(offset + dataIndexOffset)
                                    )
                                    .replace(
                                        /#INDEXSUP|#IDXSUP|#DATASETSUP/g,
                                        '¹²³⁴⁵⁶⁷⁸⁹⁰'.charAt(offset + dataIndexOffset)
                                    )
                                    .replace(
                                        /#INDEXALPHA|#INDEXA|#IDXA|#DATASETALPHA|#DATASETA/g,
                                        'abcdefghijklmnopqrstuvwxyz0123456789'.charAt(offset + dataIndexOffset)
                                    )
                                    .replace(/#INDEX|#IDX|#DATASET/g, (offset + 1 + dataIndexOffset).toFixed(0));
                            }
                            offset++;
                        }
                        labelsPopulated = true;
                    }
                    //chartData = (groupByDate ? createIndicatorColumnTable(chartData) : createIndicatorRowTable(chartData)); // Bodge for now - needs to be part of DataManipulator
                    if (!groupByDate || singleTimeSlice) chartData = createIndicatorRowTable(chartData);
                    else {
                        chartConfigKey = 'timeseries'; // Remove from all config re-jigging
                        labels = chartData[0].data.headers.slice(1);
                        for (let d of chartData) d.data.label = d.data.features[0].name;
                    }
                    if (
                        settings.groupSeriesByIndicatorDate !== true &&
                        settings.groupSeriesByNames !== undefined &&
                        settings.groupSeriesByNames !== null &&
                        settings.groupSeriesByNames !== ''
                    ) {
                        const original = chartData[0].data,
                            seriesGroups = settings.groupSeriesByNames.split('&').filter((s) => s !== ''),
                            splitData = [];
                        labels = [];
                        for (let sg of seriesGroups) {
                            const [name, indicesString] = sg.split('='),
                                indices = indicesString.split(',').map((i) => parseInt(i) - 1);
                            splitData.push({
                                name: name,
                                data: {
                                    colIds: [...original.colIds],
                                    headers: [original.headers[0], name],
                                    rows: [...original.rows.filter((r, ri) => indices.indexOf(ri) >= 0)],
                                    rowIds: [...original.rowIds.filter((r, ri) => indices.indexOf(ri) >= 0)]
                                },
                                colors: chartData[0].colors
                            });
                            labels.push(name);
                        }
                        chartData = createIndicatorColumnTable(splitData.filter((sd) => sd.data.rows.length > 0)); // Bodge
                    }
                    if (!singleTimeSlice && settings.xAxisUseDate === true) {
                        // Adjust the lines from simple Y values to x, y...
                        for (let d of chartData) {
                            for (let i = 1; i < d.data.colIds.length; i++) {
                                const c = d.data.colIds[i];
                                if (c.date !== undefined && typeof c.date === 'number') {
                                    for (let r of d.data.rows) {
                                        r[i] = multiValue
                                            ? {
                                                  ...r[i],
                                                  x: new Date(c.date)
                                              }
                                            : {
                                                  x: new Date(c.date),
                                                  y: r[i]
                                              };
                                    }
                                }
                            }
                        }
                    }
                }
                if (settings.animated)
                    topWidget.setAttribute('class', `${topWidget.getAttribute('class')} ia-animated`);
                // chart.js 3 needs empty values not undefined - in-place
                nullifyEmptyValues(
                    chartData,
                    limitsRequested && chartSubType.toLowerCase().indexOf('witherrorbars') >= 0
                );
                // Final test for dates/indicators
                if (
                    chartConfigKey !== 'timeseries' &&
                    !settings.groupSeriesByIndicatorDate &&
                    (indicatorSeriesExplicitly || indicatorSeriesProbably)
                ) {
                    const dateSplitData = [];
                    let cat = 0;
                    for (let ds of chartData) {
                        if (ds.data.indicators.length === 1 && ds.data.colIds.length > 2) {
                            const dateTables = splitTableByIndicatorDate(ds.data);
                            for (let dt of dateTables) {
                                dateSplitData.push({
                                    ...ds,
                                    id: `${ds.id}_${dt.date.replace(/[^0-9a-zA-Z]/g, '_')}`,
                                    name: seriesLabelFormat
                                        .replace(/#NAME/, ds.data.indicators[0].name)
                                        .replace(/#INAME/, ds.data.indicators[0].name)
                                        .replace(/#DATE/, dt.date)
                                        .replace(
                                            /#INDEXALPHA|#INDEXA|#IDXA|#DATASETALPHA|#DATASETA/g,
                                            'abcdefghijklmnopqrstuvwxyz0123456789'.charAt(cat + dataIndexOffset)
                                        )
                                        .replace(/#INDEX|#IDX|#DATASET/g, (cat + 1 + dataIndexOffset).toFixed(0)),
                                    colors: ds.colors.slice(cat),
                                    data: dt
                                });
                                cat++;
                            }
                        } else {
                            dateSplitData.push({
                                ...ds,
                                colors: ds.colors.slice(cat)
                            });
                        }
                        cat++;
                    }
                    chartData = [...dateSplitData];
                }
                const chartEngine = new ChartJS(canvas.getContext('2d')),
                    chartOpts = {
                        ...this.getChartOptions(settings)
                    },
                    hzOrVert = settings.barOrientation.toLowerCase(),
                    flipAxes = hzOrVert === 'horizontal',
                    highlightLabel =
                        settings.includeAllAreas &&
                        settings.highlightSelectedFeature &&
                        (settings.highlightStyle === 'fill-and-callout' ||
                            settings.highlightStyle === 'FillAndCallout' ||
                            settings.highlightStyle === 'fill-and-text' ||
                            settings.highlightStyle === 'FillAndText'),
                    dataPointLabels =
                        settings.labelDataPointsWithValue !== undefined &&
                        settings.labelDataPointsWithValue !== null &&
                        settings.labelDataPointsWithValue.toLowerCase() !== 'none',
                    xTitle = TextWidget.insertValuesIntoText(
                        flipAxes ? settings.yAxisTitle : settings.xAxisTitle,
                        data,
                        settings.numberFormat,
                        settings.locale,
                        false
                    ),
                    yTitle = TextWidget.insertValuesIntoText(
                        flipAxes ? settings.xAxisTitle : settings.yAxisTitle,
                        data,
                        settings.numberFormat,
                        settings.locale,
                        false
                    ),
                    chartPlugins = {
                        datalabels: {
                            enabled: highlightLabel || dataPointLabels,
                            font: {
                                family: settings.axisFontFamily,
                                size: settings.axisFontSize
                            },
                            formatter: function (value, context) {
                                //console.log(value); // DEBUG
                                return typeof value === 'number' ? nfmt.format(value) : '' + value;
                            }
                        }
                    };
                if (settings.alwaysStackTo100 === true) {
                    if (chartOpts.plugins === undefined) chartOpts.plugins = {};
                    chartOpts.plugins.stacked100 = { enable: true };
                }
                let animBound = false;
                if (settings.paletteIsFixed === true) {
                    for (let d of chartData) {
                        let c = d.colors;
                        if (c.length > 0) {
                            while (c.length < d.data.rows.length) {
                                c = c.concat([...c]);
                            }
                            d.colors = [...c];
                        }
                    }
                }
                // Re-use annotations functionality for some more complex lines
                let extraLines = {};
                if (
                    showComparisons &&
                    showComparisonsAsLines &&
                    !hideMainFeature &&
                    !useCountsPerDistinctValue &&
                    comparisonFeatures.length > 0 &&
                    indicatorSeriesExplicitly &&
                    chartData.find((cd) => cd.data.comparisonsOnly) !== undefined
                ) {
                    const compData = chartData.find((cd) => cd.data.comparisonsOnly === true),
                        compColors = AbstractChartWidget.getPaletteColors(
                            settings.comparisonsPalette,
                            STANDARD_PALETTES[0]
                        );
                    for (let r = 0; r < compData.data.rows.length; r++) {
                        for (let c = 1; c < compData.data.rows[r].length; c++) {
                            const rk = `${(r + 1).toFixed(0)}_${(c + 1).toFixed(0)}`;
                            extraLines[`showTargetLine${rk}`] = 'y-axis';
                            extraLines[`targetLineLabel${rk}`] = comparsionLineLabelFormat
                                .replace(/#NAME|#FNAME/gi, compData.data.rows[r][0])
                                .replace(/#VALUE|#VAL/gi, nfmt.format(compData.data.rows[r][c]))
                                .replace(/#DATE|#IDATE/gi, compData.data.headers[c]);
                            //.replace(/#INAME|#INDICATOR/gi, compData.data.indicators[c].name);
                            extraLines[`targetLineValue${rk}`] = compData.data.rows[r][c]; // TODO - allow more columns?
                            extraLines[`targetLineColor${rk}`] =
                                compColors !== null && compColors.length > 0
                                    ? compColors[r % compColors.length]
                                    : '#ff9999';
                            extraLines[`targetLineWidth${rk}`] = settings.comparisonLineWidth;
                            //extraLines[`targetLineStroke${rk}`] = settings.comparisonLineWidth;
                        }
                    }
                    chartData = chartData.filter((cd) => cd.data.comparisonsOnly !== true);
                }
                const hc = parseColor(settings.axisFontColor || settings.xAxisColor || '#333'),
                    vc = parseColor(settings.axisFontColor || settings.yAxisColor || '#333'),
                    avWidth = Math.max(convertLengthToPixels(settings.width, true, true), canvas.offsetWidth);
                this.chart = chartEngine.render({
                    name: chartConfigKey,
                    datasets: chartData,
                    labels,
                    config: {
                        type: settings.useStackedBars ? 'stackedBar' : 'bar',
                        orientation: hzOrVert,
                        beginAtZero: true,
                        fontFamily: settings.axisFontFamily,
                        numberFormatter: nfmt,
                        displayLegend: settings.showLegend,
                        legend: chartOpts.legend,
                        titles:
                            hyphenate(settings.titlePosition) === 'inside-chart' &&
                            chartTitleText !== null &&
                            chartTitleText !== ''
                                ? getBestTextForWidth(
                                      canvas.getContext('2d'),
                                      chartTitleText,
                                      avWidth - 40,
                                      settings.axisFontFamily,
                                      settings.titleFontSize
                                  ).lines
                                : [],
                        titleFontSize: parseInt(settings.titleFontSize),
                        titleFontWeight: settings.titleIsBold === true ? 'bold' : 'normal',
                        titleFontColor:
                            !isNullOrWhitespace(settings.titleColor) && hyphenate(settings.titleColor) !== 'not-set'
                                ? settings.titleColor
                                : undefined,
                        xAxisLabel: xTitle,
                        yAxisLabel: yTitle,
                        displayXAxes:
                            true ||
                            (settings.forceOneAxisLabelPerBar && hzOrVert === 'vertical'
                                ? settings.xAxisLabelFormat.replace(/^\[blank\]$/, '') !== '' || xTitle !== ''
                                : undefined),
                        displayYAxes:
                            true ||
                            (settings.forceOneAxisLabelPerBar && hzOrVert === 'horizontal'
                                ? settings.yAxisLabelFormat.replace(/^\[blank\]$/, '') !== '' || yTitle !== ''
                                : settings.yAxisLabelFormat.replace(/^\[blank\]$/, '') !== ''
                                ? undefined
                                : false),
                        xAxisFontSize: parseInt(settings.axisFontSize),
                        yAxisFontSize: parseInt(settings.axisFontSize),
                        xAxisFontColor: hzOrVert === 'vertical' ? hc : vc,
                        yAxisFontColor: hzOrVert === 'vertical' ? vc : hc,
                        maintainAspectRatio: false,
                        animationResizeDuration: 300,
                        sort: settings.barsSortByValue.toLowerCase(),
                        sortComparisons:
                            settings.comparisonBarsSortByValue ||
                            (useCountsPerDistinctValue && indicatorSeriesExplicitly), // If they are counts, then they are part of the "main" chart, regardless
                        options: chartOpts,
                        datasetOptions: {
                            ...datasetOptions
                        },
                        plugins: chartPlugins,
                        borderWidth:
                            settings.barBorderStyle === undefined ||
                            settings.barBorderStyle === null ||
                            settings.barBorderStyle !== 'solid'
                                ? 0
                                : settings.barBorderWidth !== undefined &&
                                  !isNaN(parseInt(settings.barBorderWidth.toString()))
                                ? parseInt(settings.barBorderWidth.toString())
                                : convertLengthToPixels(settings.barBorderWidth || '0px', true, true, 0),
                        borderColor: settings.barBorderColor,
                        preserveAllLabels: settings.preserveAllLabels
                    },
                    preRender: (chartConfig) => {
                        // Data is now normalized, so look and see if there are any settings that depend on that
                        extraLines = this.applyStatisticsLines(settings, chartConfig, extraLines);
                        // Now apply any anotations
                        this.applyLineAnnotations(
                            {
                                ...settings,
                                ...extraLines
                            },
                            chartConfig,
                            hzOrVert === 'horizontal'
                        );
                        if (seriesLabelFormat !== undefined) {
                            // Apply a series label (will probably already have been done for most but for features-as-series)
                            for (let di = 0; di < chartConfig.data.datasets.length; di++) {
                                chartConfig.data.datasets[di].label = (
                                    labelsPopulated ? chartConfig.data.datasets[di].label : seriesLabelFormat
                                )
                                    .replace(/#NAME/g, chartConfig.data.datasets[di].label)
                                    .replace(
                                        /#FNAME/g,
                                        chartConfig.data.datasets[di].feature !== undefined
                                            ? Array.isArray(chartConfig.data.datasets[di].feature)
                                                ? chartConfig.data.datasets[di].feature.map((f) => f.name).join(', ')
                                                : chartConfig.data.datasets[di].feature.name
                                            : ''
                                    )
                                    .replace(
                                        /#INDEXSYM|#IDXSYM|#DATASETSYM/g,
                                        '①②③④⑤⑥⑦⑧⑨⑩⑪⑫⑬⑭⑮⑯⑰⑱⑲⑳⓪'.charAt(di + dataIndexOffset)
                                    )
                                    .replace(
                                        /#INDEXSUP|#IDXSUP|#DATASETSUP/g,
                                        '¹²³⁴⁵⁶⁷⁸⁹⁰'.charAt(di + dataIndexOffset)
                                    )
                                    .replace(
                                        /#INDEXALPHA|#INDEXA|#IDXA|#DATASETALPHA|#DATASETA/g,
                                        'abcdefghijklmnopqrstuvwxyz0123456789'.charAt(di + dataIndexOffset)
                                    )
                                    .replace(/#INDEX|#IDX|#DATASET/g, (di + 1 + dataIndexOffset).toFixed(0))
                                    .replace(
                                        /#INAME/g,
                                        chartConfig.data.datasets[di].label ||
                                            chartConfig.data.datasets[di].indicator ||
                                            ''
                                    ) // Should be pre-populated with correct label already
                                    .replace(/#IDATE|#DATE/g, chartConfig.data.datasets[di].date || '');
                            }
                        }
                        if (hzOrVert === 'horizontal') {
                            chartConfig.options.scales.y.afterSetDimensions = (axis) => {
                                const requiredWidth = getMaxTextWidth(
                                    axis.ctx,
                                    axis.options.labels,
                                    settings.axisFontFamily,
                                    settings.axisFontSize
                                );
                                axis.maxWidth = Math.max(axis.maxWidth, requiredWidth + 10); // 10 = fuzziness
                            };
                            if (chartSubType.toLowerCase().indexOf('errorbars') > 0) {
                                for (let ds of chartConfig.data.datasets) {
                                    ds.data = ds.data.map((dv) => {
                                        return {
                                            x: dv.y,
                                            xMax: dv.yMax,
                                            xMin: dv.yMin
                                        };
                                    });
                                }
                            }
                        }
                        chartConfig.type = `${chartConfig.type}${chartSubType}`;
                        // Adjust the colors
                        if (palette.length > 0) {
                            //&& (seriesBindingStyle === 'FeaturesAsSeries') && indicatorSeriesReally)
                            let i = 0;
                            while (i < palette.length && i < chartConfig.data.datasets.length) {
                                chartConfig.data.datasets[i].backgroundColor = palette[i];
                                i++;
                            }
                        }
                        if (compsPalette.length > 0) {
                            // && (seriesBindingStyle === 'FeaturesAsSeries') && indicatorSeriesReally)
                            const comparisonSets = chartConfig.data.datasets.filter(
                                (d) => d.feature !== undefined && d.feature.comparison === true
                            );
                            let i = 0;
                            while (i < compsPalette.length && i < comparisonSets.length) {
                                comparisonSets[i].backgroundColor = compsPalette[i];
                                i++;
                            }
                        }
                        // Adjust the labels now the chart engine has had a 1st cut at layout
                        this.adjustAxisLabels(
                            settings,
                            chartConfig,
                            canvas.getContext('2d'),
                            settings.barOrientation.toLowerCase() === 'horizontal'
                                ? convertLengthToPixels(
                                      (settings.plotMargins || '5px 5px 5px 5px').split(' ')[3],
                                      true,
                                      true
                                  )
                                : -1
                        );
                        if (
                            !isNullOrEmpty(settings.comparisonsPalette) &&
                            chartConfig.data.keys !== undefined &&
                            chartConfig.data.keys !== null
                        ) {
                            const compColors = AbstractChartWidget.getPaletteColors(settings.comparisonsPalette),
                                compActiveIds = dataTable.features.filter((f) => f.comparison).map((f) => f.id),
                                compActiveIndices = chartConfig.data.keys
                                    .map((r, i) => (compActiveIds.indexOf(r) >= 0 ? i : -1))
                                    .filter((i) => i >= 0);
                            if (compActiveIndices.length >= 0) {
                                for (let ds of chartConfig.data.datasets) {
                                    const ccolors = new Array(ds.data.length);
                                    for (let i = 0; i < ccolors.length; i++) {
                                        ccolors[i] = ds.backgroundColor;
                                    }
                                    for (let i of compActiveIndices) {
                                        ccolors[i] = compColors.shift();
                                    }
                                    ds.backgroundColor = ccolors;
                                }
                            }
                        }
                        if (
                            settings.includeAllAreas &&
                            settings.highlightSelectedFeature &&
                            settings.highlightColor !== undefined &&
                            settings.highlightColor !== null &&
                            activeFeature !== undefined &&
                            activeFeature !== null &&
                            chartData.length > 0 &&
                            chartConfig.data.keys !== undefined &&
                            chartConfig.data.keys !== null
                        ) {
                            const activeIds = Array.isArray(activeFeature)
                                    ? activeFeature.map((f) => f.id)
                                    : [activeFeature.id],
                                activeIndices = chartConfig.data.keys
                                    .map((r, i) => (activeIds.indexOf(r) >= 0 ? i : -1))
                                    .filter((i) => i >= 0);
                            //chartConfig.data.labelKeys = activeData.rowIds.slice(0);
                            if (activeIndices.length >= 0) {
                                for (let ds of chartConfig.data.datasets) {
                                    const colors = new Array(ds.data.length),
                                        widths = new Array(ds.data.length),
                                        arrayAlready = Array.isArray(ds.backgroundColor);
                                    for (let i = 0; i < colors.length; i++) {
                                        colors[i] = arrayAlready ? ds.backgroundColor[i] : ds.backgroundColor;
                                        widths[i] = 0;
                                    }
                                    for (let i of activeIndices) {
                                        colors[i] = settings.highlightColor;
                                        widths[i] = 1;
                                    }
                                    if (settings.highlightStyle.toLowerCase() === 'border') {
                                        ds.borderColor = colors;
                                        ds.borderWidth = widths;
                                    } else ds.backgroundColor = colors;
                                }
                                if (highlightLabel) {
                                    chartConfig.options.plugins = chartConfig.options.plugins || {};
                                    const bgColor =
                                        settings.highlightStyle === 'fill-and-callout' ||
                                        settings.highlightStyle === 'FillAndCallout'
                                            ? settings.highlightColor
                                            : 'rgba(255,255,255,0)';
                                    chartConfig.options.plugins.datalabels = {
                                        enabled: true,
                                        display: (context) => {
                                            return activeIndices.indexOf(context.dataIndex) >= 0;
                                        },
                                        backgroundColor: (context) => {
                                            return activeIndices.indexOf(context.dataIndex) >= 0 ? bgColor : null;
                                        },
                                        formatter: (value, context) => {
                                            const lbl = chartConfig.data.labels[context.dataIndex];
                                            return activeIndices.indexOf(context.dataIndex) >= 0
                                                ? `${Array.isArray(lbl) ? lbl.join(' ') : lbl}: ${nfmt.format(value)}`
                                                : null;
                                        },
                                        padding: 4,
                                        offset: 8,
                                        anchor: settings.yAxisReversed ? 'start' : 'end',
                                        align: settings.yAxisReversed ? 'start' : 'end',
                                        clamp: true,
                                        font: {
                                            family: settings.axisFontFamily,
                                            size: settings.axisFontSize
                                        }
                                    };
                                }
                            }
                        }
                        // Specialist sub-type of chart - take the counts and map to colors
                        const colorSet = AbstractChartWidget.getKeyedPaletteColors(settings.palette);
                        if (useCountsPerDistinctValue && indicatorSeriesExplicitly && colorSet !== null) {
                            const labels = chartConfig.data.labels,
                                colorPalette = new Array(labels.length);
                            for (let i = 0; i < labels.length; i++) {
                                colorPalette[i] = colorSet.has(labels[i])
                                    ? colorSet.get(labels[i])
                                    : colorSet.has(i.toString())
                                    ? colorSet.get(i.toString())
                                    : palette[i];
                                //labels[i] = settings.xAxisLabelFormat.replace(/#NAME/g, labels[i]);
                            }
                            const specifiedColors = colorPalette.filter((c) => c !== undefined && c !== null);
                            for (let ds of chartConfig.data.datasets) {
                                ds.backgroundColor = specifiedColors.length > 1 ? specifiedColors : specifiedColors[0];
                            }
                        } else if (indicatorSeriesExplicitly && colorSet !== null) {
                            for (let ds of chartConfig.data.datasets) {
                                if (ds.label !== undefined && colorSet.has(ds.label))
                                    ds.backgroundColor = colorSet.get(ds.label);
                            }
                        }
                        if (dataPointLabels) {
                            this.applyDataPointLabels(
                                {
                                    ...settings,
                                    labelDataPointsAnchor: (context) => {
                                        const localType =
                                            context.dataset.type !== undefined ? context.dataset.type : 'bar';
                                        return localType.substring(0, 4) === 'line'
                                            ? 'end'
                                            : settings.labelDataPointsAnchor;
                                    }
                                },
                                chartConfig,
                                (context) => {
                                    const localType = context.dataset.type !== undefined ? context.dataset.type : 'bar',
                                        vis =
                                            (settings.labelDataPointsWithValue.toLowerCase() === 'bars' &&
                                                localType.substring(0, 3) === 'bar') ||
                                            (settings.labelDataPointsWithValue.toLowerCase() === 'lines' &&
                                                localType.substring(0, 4) === 'line') ||
                                            settings.labelDataPointsWithValue.toLowerCase() === 'both';
                                    return vis;
                                }
                            );
                        }

                        if (
                            //settings.barOrientation.toLowerCase() !== 'horizontal' && // This shold be relaxed when we move to chartjs 3.x
                            showComparisons &&
                            showComparisonsAsLines === true &&
                            !hideMainFeature &&
                            !useCountsPerDistinctValue &&
                            chartData.length === 1 &&
                            chartData[0].data.features !== undefined &&
                            hyphenate(seriesBindingStyle) === 'features-as-series' &&
                            chartData[0].data.features.length === chartConfig.data.datasets.length
                        ) {
                            const nFeatures = chartData[0].data.features.length,
                                {
                                    comparisonLineMarkerSize,
                                    comparisonLineMarkers = 'auto',
                                    comparisonLineStroke = 'solid',
                                    comparisonLineWidth = '2px'
                                } = settings,
                                pointSize =
                                    comparisonLineMarkerSize !== undefined ? parseInt(comparisonLineMarkerSize) : 6;
                            let coff = 0;
                            for (let i = 0; i < nFeatures; i++) {
                                if (chartData[0].data.features[i].comparison === true) {
                                    chartConfig.data.datasets[i].type = 'line';
                                    chartConfig.data.datasets[i].fill = false;
                                    chartConfig.data.datasets[i].showLine = comparisonLineWidth !== '0';
                                    chartConfig.data.datasets[i].borderWidth = comparisonLineWidth;
                                    chartConfig.data.datasets[i].borderColor =
                                        chartConfig.data.datasets[i].backgroundColor;
                                    chartConfig.data.datasets[i].lineTension =
                                        comparisonLineInterpolation === 'linear' ? 0.0 : 0.4;
                                    if (comparisonLineInterpolation === 'stepped')
                                        chartConfig.data.datasets[i].stepped = 'before';
                                    chartConfig.data.datasets[i].borderWidth =
                                        comparisonLineWidth !== undefined
                                            ? parseInt(comparisonLineWidth.toString().replace('px', ''))
                                            : 3;
                                    chartConfig.data.datasets[i].borderDash = createDashedLine(
                                        comparisonLineStroke,
                                        chartConfig.data.datasets[i].borderWidth
                                    );
                                    chartConfig.data.datasets[i].pointRadius = pointSize / 2.0;
                                    chartConfig.data.datasets[i].pointStyle = getPointStyle(
                                        comparisonLineMarkers !== undefined ? comparisonLineMarkers : 'circle',
                                        coff
                                    );
                                }
                                chartConfig.data.datasets[i].order = nFeatures - i;
                                chartConfig.data.datasets[i].id = chartData[0].data.features[i].id;
                                coff++;
                            }
                            if (chartConfig.options.plugins.legend !== undefined) {
                                if (chartConfig.options.plugins.legend.labels === undefined)
                                    chartConfig.options.plugins.legend.labels = {};
                                chartConfig.options.plugins.legend.labels.generateLabels = (chart) => {
                                    const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart),
                                        defDash = createDashedLine(
                                            'solid',
                                            settings.comparisonLineWidth !== undefined
                                                ? parseInt(settings.comparisonLineWidth)
                                                : 3
                                        );
                                    for (let key in labels) {
                                        const lds = chart.data.datasets[labels[key].datasetIndex];
                                        // BUG #12238 borderColor is most likely an array of colors for individual bars.
                                        // but in this case we just want a single color value for rendering
                                        // a line to canvas.
                                        const lineColor =
                                            lds.borderColor instanceof Array === true && lds.borderColor.length > 0
                                                ? lds.borderColor[0]
                                                : lds.borderColor;
                                        if (lds.type === 'line') {
                                            /*labels[key].pointStyle = createLineImage(
                                                topWidget.ownerDocument,
                                                lds.borderDash !== undefined ? lds.borderDash : defDash,
                                                lds.borderWidth !== undefined
                                                    ? lds.borderWidth
                                                    : settings.comparisonLineWidth !== undefined
                                                    ? parseInt(settings.comparisonLineWidth)
                                                    : 3,
                                                lineColor !== undefined ? lineColor : labels[key].strokeStyle,
                                                { width: 40, height: 14 },
                                                settings.legendLineStyle || 'line'
                                            );*/
                                        } else labels[key].pointStyle = 'rect';
                                    }
                                    // Restore the "natural" order
                                    labels.sort((a, b) => {
                                        return a.datasetIndex - b.datasetIndex;
                                    });
                                    return labels;
                                };
                                chartConfig.options.plugins.legend.labels.usePointStyle = true;
                            }
                        }
                        this.applyAxesSettings(
                            {
                                ...settings,
                                xAxisLabelFormat:
                                    hyphenate(seriesBindingStyle) !== 'indicators-as-series' &&
                                    settings.xAxisLabelFormat !== '[blank]' &&
                                    settings.xAxisLabelFormat !== '[tick]'
                                        ? undefined
                                        : settings.xAxisLabelFormat,
                                xAxisUseDate: settings.xAxisUseDate && !singleTimeSlice
                            },
                            chartConfig,
                            settings.barOrientation.toLowerCase() === 'horizontal'
                        );
                        // reset some grid lines stuff
                        const xAxes = [chartConfig.options.scales.x];
                        const yAxes = [
                            chartConfig.options.scales.y,
                            chartConfig.options.scales.left,
                            chartConfig.options.scales.right
                        ];
                        if (settings.barOrientation.toLowerCase() === 'horizontal') {
                            xAxes.forEach((x) => (x.position = settings.xAxisReversed ? 'top' : 'bottom'));
                            yAxes.forEach((y) => {
                                y.position = settings.yAxisReversed ? 'right' : 'left';
                                //if (!settings.xAxisReversed && (y.grid !== undefined)) delete(y.grid.zeroLineColor);
                                y.reverse = !y.reverse;
                                y.grid.offset = false;
                            });
                        } else xAxes.forEach((x) => (x.grid.offset = false));
                        this.applyEvents(settings, chartConfig, options);
                    },
                    onComplete: (ce) => {
                        //this.forceAxesRender(ce.chart, settings);
                        //if (!animBound && settings.animated)
                        //{
                        //    console.log(ce); // DEBUG
                        //    const widgetId = topWidget.getAttribute('id');
                        //    window[`widgetChart${widgetId}`] = chartEngine.chart;
                        //    AbstractChartWidget.bindAnimationUpdate(topWidget);
                        //}
                        animBound = true;
                    }
                });
                this.applyChartActions(settings, chartData);
            }
            topWidget.setAttribute('class', topWidget.getAttribute('class').replace(/\s(placeholder)/g, ''));
        }
        return options.returnData ? { chart: this.chart, data: chartData } : this.chart;
    };

    static getDefaults = () => {
        return {
            ...AbstractChartWidget.getDefaults(),
            ...AbstractChartWidget.plotAreaDefaults,
            ...AbstractChartWidget.statisticsLineDefaults,
            ...AbstractChartWidget.annotationLineDefaults,
            barBorderColor: '#999999',
            barBorderStyle: 'NotSet',
            barBorderWidth: '0',
            barDrawingStyle: 'Default',
            barOrientation: 'Vertical',
            barShadowColor: '#666666',
            barShadowOffset: '0',
            barWidth: '0.9',
            barsSortByValue: 'no',
            comparisonBarsSortByValue: false,
            useStackedBars: false,
            groupSeriesByIndicatorDate: false,
            seriesLabelFormat: '#NAME',
            highlightStyle: 'fill-and-callout',
            labelDataPointsWithValue: 'none',
            labelDataPointsFormat: '#VALY',
            labelDataPointsAnchor: 'end', // start | middle | end
            labelDataPointsColor: '',
            labelDataPointsRotation: 0,
            showComparisonsAsLines: false,
            comparisonLineWidth: '3px',
            comparisonLineTension: 0.0,
            comparisonLineMarkerSize: '6px',
            comparisonLineMarkers: 'auto',
            comparisonLineStroke: 'solid',
            comparsionLineLabelFormat: '#NAME',
            hideMainFeature: false,
            xAxisLabelFormat: '#INAME #IDATE', // because with features as series this makes more sense than date
            legendWeight: 25
        };
    };
}

const sortIconsByValue = (a, b) => {
    return a.value - b.value;
};
