// import AlgorithmicColorRamp from '@arcgis/core/rest/support/AlgorithmicColorRamp.js';
// import ClassBreaksRenderer from '@arcgis/core/renderers/ClassBreaksRenderer.js';
import * as colorRendererCreator from '@arcgis/core/smartMapping/renderers/color.js';
import * as colorSchemes from '@arcgis/core/smartMapping/symbology/color.js';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer.js';
// import histogram from '@arcgis/core/smartMapping/statistics/histogram';
import esriId from '@arcgis/core/identity/IdentityManager';
import Legend from '@arcgis/core/widgets/Legend.js';
import MapView from '@arcgis/core/views/MapView.js';
import SceneView from '@arcgis/core/views/SceneView.js';
import View from '@arcgis/core/views/View.js';
// import MultipartColorRamp from '@arcgis/core/rest/support/MultipartColorRamp.js';
// import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol.js';
// import SimpleLineSymbol from '@arcgis/core/symbols/SimpleLineSymbol.js';
import * as sizeRendererCreator from '@arcgis/core/smartMapping/renderers/size.js';
import { getNumberFormatter } from './localization';
import OAuthInfo from '@arcgis/core/identity/OAuthInfo';
import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';
import { generateGuid } from './report-migrate-plus';

const getAttributeValueByName = (attributes: any, key: string, fallbackValue?: any): any => {
    if (attributes === undefined || attributes === null || key === undefined || key === null) return fallbackValue;
    const names = Object.keys(attributes),
        keyIndex = names.findIndex((n) => n.toLowerCase() === key.toLowerCase());
    return keyIndex >= 0 ? attributes[names[keyIndex]] : fallbackValue;
};

export const getVanillaWebMap = (options: any): any => {
    let webmap: any = {};
    webmap.item = {
        title: options && options.title ? options.title : 'Vanilla Map',
        extent:
            options && options.extent
                ? options.extent
                : [
                      [-180, -90],
                      [180, 90]
                  ]
    };
    webmap.itemData = {
        baseMap: {
            baseMapLayers: [
                {
                    id: 'VectorTile_4717',
                    type: 'VectorTileLayer',
                    layerType: 'VectorTileLayer',
                    title: 'Dark Gray Canvas Base',
                    styleUrl:
                        'https://www.arcgis.com/sharing/rest/content/items/5e9b3685f4c24d8781073dd928ebda50/resources/styles/root.json',
                    itemId: '5e9b3685f4c24d8781073dd928ebda50',
                    visibility: true,
                    opacity: 1
                },
                {
                    id: 'VectorTile_9547',
                    type: 'VectorTileLayer',
                    layerType: 'VectorTileLayer',
                    title: 'Dark Gray Canvas Reference',
                    styleUrl:
                        'https://www.arcgis.com/sharing/rest/content/items/747cb7a5329c478cbe6981076cc879c5/resources/styles/root.json',
                    itemId: '747cb7a5329c478cbe6981076cc879c5',
                    isReference: true,
                    visibility: true,
                    opacity: 1
                }
            ],
            title: 'Dark Gray Canvas'
        }
    };
    if (options && options.basemaps && options.basemaps.length > 0) {
        webmap.itemData.baseMap.baseMapLayers = [];
        let i = 0;
        for (let m of options.basemaps) {
            webmap.itemData.baseMap.baseMapLayers.push({
                id: 'reflyr' + i.toFixed(0),
                layerType: 'ArcGISTiledMapServiceLayer',
                url: m.split(';')[0],
                visibility: true,
                opacity: 1,
                title: m.split(';').length > 1 ? m.split(';')[1] : m,
                isReference: i > 0
            });
            i++;
        }
    }
    webmap.itemData.operationalLayers = [];
    return webmap;
};

export const bindMapClickNavigation = (
    mapView: MapView | SceneView,
    clickableLayer,
    idField = 'CODE',
    nameField = 'NAME',
    pattern = './#ID',
    routerHistory
) => {
    const navPattern = pattern.trim() === '' || pattern.trim().toLowerCase() === 'auto' ? './#ID' : pattern;
    clickableLayer.outFields = [
        ...(clickableLayer.outFields !== null ? clickableLayer.outFields : []),
        idField,
        nameField
    ];
    const clickHandle = mapView.on('click', (e) => {
        mapView
            .hitTest(e)
            .then((hitResults) => {
                const target = hitResults.results.find((r) => r.graphic.layer === clickableLayer);
                if (target !== undefined) {
                    const fid = getAttributeValueByName(target.graphic.attributes, idField),
                        fname = getAttributeValueByName(target.graphic.attribute, nameField);
                    if (fid !== undefined && fid !== null) {
                        if (e.type === 'click') {
                            if (
                                routerHistory !== undefined &&
                                routerHistory !== null &&
                                !/^(http|https|file)[:].*/.test(navPattern)
                            ) {
                                routerHistory.push(
                                    navPattern.replace(/(#ID|#FID)/g, fid).replace(/(#NAME|#FNAME)/g, fname)
                                );
                            } else {
                                window.location.href = navPattern
                                    .replace(/(#ID|#FID)/g, fid)
                                    .replace(/(#NAME|#FNAME)/g, fname);
                            }
                        }
                    }
                }
            })
            .catch((hitTestErr) => {
                console.debug(hitTestErr);
            });
    });
    mapView.addHandles(clickHandle);
};

export const bindMapFeatureTooltips = (
    mapView: MapView | SceneView,
    tipLayer,
    labelFieldOrKeyOrFormat = 'NAME',
    numberFormat = '0.#',
    locale = 'en',
    labelFunction = (a: any): string | undefined => {
        return undefined;
    },
    changeCursor = 'no'
) => {
    const labels = labelFieldOrKeyOrFormat.split('$feature.').filter((lbl) => lbl !== undefined && lbl !== ''),
        arcadeLike = labelFieldOrKeyOrFormat.indexOf('$feature.') >= 0 || labels.length > 1;
    let usefulLabel = labelFunction !== null && labelFunction(labelFieldOrKeyOrFormat) !== undefined;
    if (arcadeLike && !usefulLabel) {
        // Deal with Arcade expressions - TODO? Can I farm this out???
        tipLayer.outFields = [
            ...(tipLayer.outFields !== null ? tipLayer.outFields : []),
            ...labels.map((lbl) => lbl.split(/[^0-9a-zA-Z_]/)[0])
        ];
        //tipLayer.labelingInfo = [{
        //    labelExpressionInfo: {
        //        expression: labelField
        //    },
        //    labelPlacement: 'always-horizontal'
        //}];
        usefulLabel = true;
    } else if (labelFieldOrKeyOrFormat !== '' && !usefulLabel) {
        tipLayer.outFields = [...(tipLayer.outFields !== null ? tipLayer.outFields : []), labelFieldOrKeyOrFormat];
        usefulLabel = true;
    }
    if (usefulLabel) {
        const nf = getNumberFormatter(locale, numberFormat),
            moveHandle = mapView?.on('pointer-move', (e) => {
                mapView
                    .hitTest(e, {
                        include: tipLayer
                    })
                    .then((hitResults) => {
                        const target = hitResults.results.find((r) => r.graphic.layer === tipLayer),
                            container = mapView.container?.parentNode,
                            tt: HTMLElement | null =
                                container !== null ? container.querySelector('.ia-map-tooltip') : null;
                        if (tt !== undefined && tt !== null) {
                            const label = tt.querySelector('.tooltip-text');
                            if (target !== undefined) {
                                let fname: string | null | undefined = null;
                                if (labelFunction !== null && labelFunction(target.graphic.attributes) !== undefined)
                                    fname = labelFunction(target.graphic.attributes);
                                else if (arcadeLike) {
                                    fname = '';
                                    const keys = labels.map((lbl) => lbl.split(/[^0-9a-zA-Z_]/)[0]);
                                    for (let i = 0; i < labels.length; i++) {
                                        let dv = getAttributeValueByName(target.graphic.attributes, keys[i], keys[i]);
                                        if (typeof dv === 'number') dv = nf.format(dv);
                                        fname += labels[i].replace(keys[i], dv);
                                    }
                                } else
                                    fname = getAttributeValueByName(
                                        target.graphic.attributes,
                                        labelFieldOrKeyOrFormat,
                                        labelFieldOrKeyOrFormat
                                    );
                                if (fname !== undefined && fname !== null && label !== null) {
                                    if (e.type === 'pointer-move') {
                                        label.innerHTML = fname;
                                        // rely on margin in CSS to offset
                                        tt.style.left = `${e.x}px`;
                                        tt.style.top = `${e.y}px`;
                                        tt.style.display = 'block';
                                        if (changeCursor !== 'no') mapView.container.style.cursor = changeCursor;
                                    }
                                }
                            } else {
                                tt.style.display = 'none';
                                if (changeCursor !== 'no') mapView.container.style.cursor = '';
                            }
                        }
                    });
            }),
            mouseLeaveListener = () => {
                const container = mapView?.container?.parentNode,
                    tt = container?.querySelector('.ia-map-tooltip') as HTMLElement;
                if (tt !== undefined && tt !== null) {
                    setTimeout(() => {
                        tt.style.display = 'none';
                    }, 200);
                }
            },
            mouseLeaveHandle = {
                remove: () => {
                    mapView?.container?.parentNode?.removeEventListener('mouseleave', mouseLeaveListener);
                }
            };
        mapView?.container?.parentNode?.addEventListener('mouseleave', mouseLeaveListener);
        mapView.addHandles([moveHandle, mouseLeaveHandle]);
    }
};

export const bindMapFeatureEvents = (
    eventType: 'click' | 'pointer-move',
    mapView: MapView | SceneView,
    activeLayer,
    idField = 'CODE',
    nameField = 'NAME'
) => {
    const hitListener = (e) => {
            mapView
                .hitTest(e, {
                    include: activeLayer
                })
                .then((hitResults) => {
                    const target = hitResults.results.find((r) => r.graphic.layer === activeLayer),
                        container = mapView.container?.parentNode as HTMLElement;
                    if (target !== undefined && container !== undefined && container !== null) {
                        if (container.hasAttribute('data-event-timeout-id'))
                            window.clearTimeout(parseInt(container.getAttribute('data-event-timeout-id') || '0'));
                        const fire = () => {
                            const event = new CustomEvent(`rb.${eventType.replace('pointer-move', 'mousemove')}`, {
                                bubbles: true,
                                detail: {
                                    feature: {
                                        id: getAttributeValueByName(target.graphic.attributes, idField, ''),
                                        name: getAttributeValueByName(target.graphic.attributes, nameField, '')
                                    },
                                    src: {
                                        id: container.getAttribute('id'),
                                        type: 'Map',
                                        event: e
                                    }
                                }
                            });
                            return container.dispatchEvent(event);
                        };
                        container.setAttribute('data-event-timeout-id', window.setTimeout(fire, 20).toFixed(0));
                    }
                })
                .catch((hitTestErr) => {
                    console.debug(hitTestErr);
                });
        },
        eventHandle =
            eventType === 'click' ? mapView.on('click', hitListener) : mapView.on('pointer-move', hitListener), // Ridiculous Typescript binding madness
        leaveEventListener = () => {
            const container = mapView?.container?.parentNode;
            if (container !== undefined && container !== null) {
                setTimeout(() => {
                    const event = new CustomEvent(`rb.mouseleave`, {
                        bubbles: true,
                        detail: {
                            feature: null
                        }
                    });
                    return container.dispatchEvent(event);
                }, 200);
            }
        },
        leaveHandle = {
            remove: () => {
                mapView.container?.parentNode?.removeEventListener('mouseleave', leaveEventListener);
            }
        };
    mapView.container?.parentNode?.addEventListener('mouseleave', leaveEventListener);
    mapView.addHandles([eventHandle, leaveHandle]);
};

/** Version 4 of the API ONLY.
 * */
export const addLayerWithRenderer = async (
    view,
    layer: any = {
        url: null,
        name: null,
        classification: {
            field: {
                name: null,
                label: null
            },
            method: 'equal-interval',
            classes: 5,
            theme: 'high-to-low',
            flip: false,
            scheme: null,
            type: 'color'
        }
    },
    legend,
    clearVisibleLayers = true
) => {
    const map = view.map,
        flayer = new FeatureLayer({
            title: layer.title,
            url: layer.url,
            visible: true,
            outFields: layer.outFields || ['*']
        }),
        classificationParams: any = {
            layer: flayer,
            view: view,
            basemap: map.basemap,
            classificationMethod: layer.classification.method,
            numClasses: layer.classification.classes,
            legendOptions: {
                title: layer.classification.field.label
            }
        };
    // Data? Thrown at us or just a field?
    if (layer.classification.data !== undefined) {
        // Arcade - sigh - Decode(code, 1, 'Residential', 2, 'Commercial', 3, 'Mixed', 'Other');
        const decodable: string[] = [];
        let iv;
        for (let i = 0; i < layer.classification.data.rowIds.length; i++) {
            iv = parseFloat(layer.classification.data.rows[i][1]);
            decodable.push(`'${layer.classification.data.rowIds[i]}', ${!isNaN(iv) ? iv : `'${iv}'`}`);
        }
        classificationParams.valueExpression = `Decode($feature.${layer.idField || 'CODE'}, ${decodable.join(
            ', '
        )}, '')`;
    } else classificationParams.field = layer.classification.field.name;
    if (clearVisibleLayers) {
        for (let lyr of map.layers.items) {
            if (lyr.type === 'feature') lyr.visible = false;
        }
    }
    map.add(flayer);
    if (layer.classification.theme !== undefined) {
        const gt = flayer.geometryType || 'polygon';
        let s: __esri.ColorScheme | null | undefined =
            layer.classification.scheme !== undefined && layer.classification.scheme !== ''
                ? colorSchemes.getSchemeByName({
                      geometryType: gt,
                      theme: layer.classification.theme,
                      name: layer.classification.scheme,
                      basemap: map.basemap
                  })
                : null;
        let geoSchemes: __esri.ColorSchemes | null = colorSchemes.getSchemes({
            geometryType: gt,
            theme: layer.classification.theme,
            basemap: map.basemap
        });
        if (s === undefined || s === null) {
            s = geoSchemes.primaryScheme;
            if (
                layer.classification.scheme !== undefined &&
                layer.classification.scheme !== null &&
                geoSchemes !== undefined &&
                geoSchemes !== null &&
                geoSchemes.secondarySchemes !== undefined
            ) {
                const wildcards = layer.classification.scheme.split(' ').join('(.*)'),
                    sregex = new RegExp(`^(${layer.classification.theme})?\\s*${wildcards}.*$`, 'i');
                if (geoSchemes.secondarySchemes.find((ss) => sregex.test(ss.name)) !== undefined)
                    s = geoSchemes.secondarySchemes.find((ss) => sregex.test(ss.name));
            }
        }
        //if (s !== undefined && s !== null) s = s.primaryScheme !== undefined ? s.primaryScheme : s;
        if (s !== undefined && s !== null && s.name !== undefined) {
            if (layer.classification.flip) s = colorSchemes.flipColors(s);
            classificationParams.colorScheme = s;
        }
    }
    let legendWidget: Legend | null = null;
    if (legend !== undefined && legend !== null && legend !== false && legend.show !== false) {
        const { anchor = 'bottom-left', maxHeight = '', size = 'standard', style = '', container = null } = legend;
        legendWidget = new Legend({
            view: view,
            container: container
        });
        if (container === null || legend.container === '') {
            view.ui.add(legendWidget, anchor);
            if (maxHeight !== '' && legendWidget.container !== null)
                (legendWidget.container as HTMLElement).style.maxHeight = maxHeight;
        }
        if (size !== null && legendWidget.container !== null)
            (legendWidget.container as HTMLElement).setAttribute(
                'class',
                `${(legendWidget.container as HTMLElement).getAttribute('class')} ia-arc-legend ia-legend-${size}`
            );
        if (style !== '' && legendWidget.container !== null)
            (legendWidget.container as HTMLElement).setAttribute('style', style);
    }
    const renderObj =
            layer.classification.type !== undefined && layer.classification.type === 'size'
                ? sizeRendererCreator
                : colorRendererCreator,
        renderFunc =
            layer.classification.theme !== undefined &&
            (layer.classification.theme === 'extremes' || layer.classification.method === 'continuous')
                ? renderObj.createContinuousRenderer(classificationParams)
                : renderObj.createClassBreaksRenderer(classificationParams),
        rendererResponse = await renderFunc;
    flayer.renderer = rendererResponse.renderer;
    const flayerView = await view.whenLayerView(flayer);
    return {
        layer: flayer, //map.layers.items.find(lyr => `${lyr.url}/${lyr.layerId}`.toLowerCase() === flayer.url.toLowerCase());
        layerView: flayerView,
        legend: legendWidget
    };
};

export const applyRenderer = (options) => {
    const webmap = options.webmap,
        lyr = options.layer,
        field = options.field !== undefined ? options.field : options.dataField,
        idf = options.idField !== undefined ? options.idField : options.featureIdField,
        mtd = options.classificationMethod,
        classBreaks = options.classes && typeof options.classes.splice !== 'undefined' ? options.classes : null,
        nclasses = classBreaks !== null ? classBreaks.length - 1 : options.classes || 5,
        colors = options.colors,
        useSmart = typeof options.smart === 'undefined' || options.smart,
        map = options.map,
        addLegend = options.legend && options.legend.show,
        legendAnchor = options.legend && options.legend.anchor ? options.legend.anchor : 'top-left',
        legendlayerLabel = options.legend && options.legend.label ? options.legend.label : null,
        afterRenderFnc = options && options.done ? options.done : null,
        rawValues = options && options.values ? options.values : null,
        rawFeatures = options && options.ids ? options.ids : null;
    let rendererOverride: any = null;
    if (classBreaks !== null) {
        rendererOverride = (r: any) => {
            let locals = r.infos.slice(),
                classBreaksLabelFormat =
                    options.classLabel || (r.isMaxInclusive ? '> MINVAL to MAXVAL' : 'MINVAL to MAXVAL');
            r.clearBreaks();
            for (let c = 0; c < classBreaks.length - 1; c++) {
                if (locals[c]) {
                    locals[c].minValue = parseFloat(classBreaks[c]);
                    locals[c].maxValue = parseFloat(classBreaks[c + 1]);
                    locals[c].label = classBreaksLabelFormat
                        .replace(/MINVAL/g, locals[c].minValue.toString())
                        .replace(/MAXVAL/g, locals[c].maxValue.toString());
                    r.addBreak(locals[c]);
                }
            }
        };
    }
    if (useSmart || (typeof field === 'function' && rawValues && rawFeatures)) {
        const smartArgs: any = {
            layer: lyr,
            field: field,
            basemap: map.getBasemap() || 'gray-vector', // default to gray if it's not obvious...
            classificationMethod: mtd,
            theme: 'high-to-low', // 'above-and-below', 'centered-on', 'extremes',
            numClasses: nclasses
        };
        // If we have passed in the values AND it is not a "connectable" source for that data...
        if (typeof field == 'function' && rawValues && rawFeatures) {
            // Arcade - sigh - Decode(code, 1, 'Residential', 2, 'Commercial', 3, 'Mixed', 'Other');
            let decodable: string[] = [],
                iv;
            for (let i in rawFeatures) {
                iv = parseFloat(rawValues[i]);
                decodable.push("'" + rawFeatures[i] + "', " + (!isNaN(iv) ? iv : "'" + iv + "'"));
            }
            smartArgs.valueExpression = 'Decode($feature.' + idf + ', ' + decodable.join(', ') + ", '')";
            smartArgs.field = null;
        }
        colorRendererCreator.createClassBreaksRenderer(smartArgs).then(
            (response) => {
                if (rendererOverride !== null) rendererOverride(response.renderer);
                lyr.setRenderer(response.renderer);
                lyr.redraw();
                if (addLegend) createLegend(map, lyr, field, legendAnchor, legendlayerLabel);
                if (afterRenderFnc !== undefined) {
                    afterRenderFnc({
                        target: lyr,
                        map: map
                    });
                }
            },
            (err) => {
                console.log(err); // DEBUG
            }
        );
    }
};

const legendStore = {
    _legendRefs: {}
};

const createLegend = async (
    map: any,
    lyr: any,
    field: string,
    anchor: string,
    lyrLabel: string,
    legendContainerId?: string,
    startupDelayMs = 500
) => {
    //const [
    //    ,  //Map,
    //    ,  //esriId,
    //    ,  //Extent,
    //    ,  //arcgisUtils,
    //    ,  //FeatureLayer,
    //    ,  //webMercatorUtils,
    //    ,  //Color,
    //    Legend,
    //    ,  //LayerList,
    //    ,  //BasemapGallery
    //] = this._arcModules;
    // Only operational layers...
    const legendArgs: any = {
        map: map
    };
    if (lyr !== undefined && lyr !== null) {
        legendArgs.layerInfos = [];
        legendArgs.layerInfos.push({
            layer: lyr,
            title: lyrLabel || lyr.title || lyr.name
        });
    }
    //var legendContainerId = $(map.container).prop('id').replace(/\-/g, '') + '_iaolegend';
    if (legendContainerId !== undefined && legendStore._legendRefs[legendContainerId] !== undefined) {
        legendStore._legendRefs[legendContainerId].refresh(legendArgs.layerInfos);
        //document.getElementById(legendContainerId).getElementsByClassName('esriLegendMsg').remove();
    }
    if (legendContainerId !== undefined) {
        // Pass ourself into the promise, because async nature of React and ArcGIS JS means we can get in a mess... *sigh*
        return new Promise((resolve) => {
            setTimeout(() => {
                let legend: Legend | null = null;
                if (legendStore._legendRefs[legendContainerId] !== undefined) {
                    legend = legendStore._legendRefs[legendContainerId];
                    if (legend !== undefined && legend !== null) legend.renderNow(); //.refresh(legendArgs.layerInfos);
                    //document.getElementById(legendContainerId).getElementsByClassName('esriLegendMsg').remove();
                } else {
                    legend = new Legend({
                        ...legendArgs,
                        container: legendContainerId
                    });
                    // legend.startup();
                }
                resolve(legend);
            }, startupDelayMs); // This is likely to be called in an async process, so wait a while...
        }).then((activeLegend) => {
            legendStore._legendRefs[legendContainerId] = activeLegend;
            return activeLegend;
        });
    }
    return null;
};

export const ARC_COLOR_RAMPS = [
    {
        name: 'Red',
        colors: ['#fee5d9', '#fcae91', '#fb6a4a', '#de2d26', '#a50f15']
    },
    {
        name: 'Purple',
        colors: ['#feebe2', '#fbb4b9', '#f768a1', '#c51b8a', '#7a0177']
    },
    {
        name: 'Blue',
        colors: ['#eff3ff', '#bdd7e7', '#6baed6', '#3182bd', '#08519c']
    },
    {
        name: 'Cyan',
        colors: ['#e4f4f8', '#c0e7f0', '#6bc1d7', '#2198b5', '#07576b']
    },
    {
        name: 'Green',
        colors: ['#edf8e9', '#bae4b3', '#74c476', '#31a354', '#006d2c']
    },
    {
        name: 'Grey',
        colors: ['#f0f0f0', '#bdbdbd', '#111111']
    },
    {
        name: 'Red Yellow',
        colors: ['#ffffcc', '#feb24c', '#b10026']
    },
    {
        name: 'Blue Yellow',
        colors: ['#ffffd9', '#7fcddb', '#0c2c83']
    },
    {
        name: 'Green Yellow',
        colors: ['#ffffe5', '#addd8e', '#005a32']
    }
];

type redirectArgs = {
    portalUrl: string;
    appAuthId: string;
    authExpiry?: number;
    redirectUri?: string;
};

export const redirectToSignIn = (
    props: redirectArgs = { portalUrl: 'https://www.arcgis.com/sharing/rest', appAuthId: '', redirectUri: '' },
    forceRefresh?: boolean
) => {
    const { portalUrl, appAuthId, authExpiry } = props,
        portalHomeUrl =
            portalUrl.indexOf('/sharing/rest') > 0
                ? portalUrl.substring(0, portalUrl.indexOf('/sharing/rest'))
                : portalUrl,
        oa: any = esriId.findOAuthInfo(portalHomeUrl),
        hasOa = oa !== undefined && oa !== null;
    if (!hasOa) {
        const toa = new OAuthInfo({
            appId: appAuthId,
            portalUrl: portalHomeUrl
        });
        esriId.registerOAuthInfos([toa]);
    } else if (hasOa && appAuthId !== undefined && appAuthId !== '' && oa.appId !== appAuthId) {
        const activeCodeHash = Base64.stringify(sha256(`${appAuthId}/${portalUrl}`)),
            activeState = JSON.stringify({ portalUrl: portalUrl, uid: generateGuid() });
        // Currently using response_type=token because even tho' it is less secure otherwise we end up in a tangle with two different OAuthInfos for same domain
        // See bug #40241
        window.location.href = `${portalUrl.replace(
            /^(.*)\/$/,
            '$1'
        )}/oauth2/authorize?client_id=${appAuthId}&response_type=token&redirect_uri=${encodeURIComponent(
            window.location.href.split('#')[0]
        )}&expiration=${(authExpiry ? authExpiry : 2 * 24 * 60).toFixed()}`;
        // &code_challenge=${activeCodeHash}&code_challenge_method=S256&state=${encodeURIComponent(
        //     activeState
        // )}`;
        return;
    }
    if (forceRefresh !== undefined && forceRefresh === true) {
        esriId.destroyCredentials();
        //console.log(esriId.toJSON()); // DEBUG
    }
    esriId.getCredential(portalUrl);
};

export const signOut = async (token, appAuthId, portalUrl = 'https://www.arcgis.com/sharing/rest') => {
    // const r = await fetch(`${portalUrl.replace(/^(.*)\/$/, '$1')}/oauth2/revokeToken`, {
    //     method: 'post',
    //     headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
    //     body: `auth_token=${encodeURIComponent(token)}&client_id=${encodeURIComponent(appAuthId)}&f=json`
    // }).then((response) => response.json());
    // return r;
    esriId.destroyCredentials();
};

export const getFlatLayersList = (operationalLayers: any[] = []): any[] => {
    const flattened = [];
    for (let i = 0; i < operationalLayers.length; i++) {
        descendAndFillList(operationalLayers[i], flattened);
    }
    return flattened;
};

const descendAndFillList = (startingLayer: any, flatList: any[] = []) => {
    // Operational layers - defined in a webmap
    if (
        startingLayer.layerType !== undefined &&
        startingLayer.layerType !== null &&
        startingLayer.layerType.toLowerCase() === 'grouplayer' &&
        startingLayer.layers !== undefined
    ) {
        for (let i = 0; i < startingLayer.layers.length; i++) {
            descendAndFillList(startingLayer.layers[i], flatList);
        }
    }
    // Layers that have already been added to a map widget
    else if (
        startingLayer.type !== undefined &&
        startingLayer.type !== null &&
        startingLayer.type.toLowerCase() === 'group' &&
        startingLayer.layers !== undefined &&
        startingLayer.layers.items !== undefined
    ) {
        for (let i = 0; i < startingLayer.layers.items.length; i++) {
            descendAndFillList(startingLayer.layers.items[i], flatList);
        }
    } else flatList.push(startingLayer);
};

const getTokenUrl = (url: string): string => {
    const serviceOrPortalUrl =
        url.toLowerCase().indexOf('/rest/services') > 0
            ? url.substring(0, url.toLowerCase().indexOf('/rest/services') + '/rest/services'.length)
            : url.toLowerCase().indexOf('/sharing/rest') > 0
            ? url.substring(0, url.toLowerCase().indexOf('/sharing/rest') + '/sharing/rest'.length)
            : url;
    return serviceOrPortalUrl;
};

export const getTokenFor = (url: string, tokenManager: any, fallback: string | null = null) => {
    if (
        url !== null &&
        tokenManager !== undefined &&
        tokenManager !== null &&
        tokenManager.findCredential !== undefined
    ) {
        const serviceOrPortalUrl = getTokenUrl(url),
            tc = tokenManager.findCredential(serviceOrPortalUrl),
            t = tc !== undefined && tc !== null && tc.token !== undefined ? tc.token : null;
        if (t !== null) return t;
    }
    return fallback;
};
