import React, { useEffect, useRef } from 'react';
import '../assets/styles/components/ProductLogo.scss';
import { ReactComponent as Logo } from '../assets/images//logo_hi_white.svg';

const ProductLogo = ({
    size = '1.4em',
    color = 'var(--esriuk-app-hi-color, #4a3875)',
    className = '',
    ...others
}) => {
    const sceneRoot = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (sceneRoot.current !== null) {
            sceneRoot.current.style.setProperty('--logo-base-unit', size);
            sceneRoot.current.style.setProperty('--logo-base-color', color);
        }
    });

    return (
        <div className={`logo ${className}`.trim()} ref={sceneRoot} {...others}>
            <Logo />
        </div>
    );
};

export default ProductLogo;
