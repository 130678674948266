import { isNullOrUndefined } from './object';
import html2canvas from 'html2canvas';

const textNodesUnder = (node: Node): Node[] => {
    const a: Node[] = [];
    const walker = document.createTreeWalker(node, NodeFilter.SHOW_TEXT, null);
    while (walker.nextNode()) a.push(walker.currentNode);
    return a;
};

export const extractText = (strHtml: string): string | null => {
    if (!strHtml) return null;
    let burner = document.createElement('div');
    burner.innerHTML = strHtml;
    let tnodes = textNodesUnder(burner);
    if (!tnodes.length) return null;
    return tnodes.map((tn) => tn.textContent).join(' ');
};

export const appendStyle = (css: string): void => {
    const head = document.head || document.getElementsByTagName('head')[0];
    const style: HTMLStyleElement = document.createElement('style');
    style.setAttribute('type', 'text/css');
    //if (style.styleSheet) style.styleSheet.cssText = css;
    style.appendChild(document.createTextNode(css));
    head.appendChild(style);
};

export const highlight = (strHtml: string, regex: RegExp, highlightClass = 'highlight'): string => {
    let match;
    let indices: number[] = [];
    let searchStrs: string[] = [];

    while ((match = regex.exec(strHtml)) != null) {
        searchStrs.push(match[0]);
        indices.push(match.index);
    }

    if (indices.length > 0) {
        const reversedIndices = indices.reverse();
        const reversedStr = searchStrs.reverse();
        const plainText = strHtml.indexOf('>') < 0;
        for (let i of Array.from(reversedIndices.keys())) {
            const index = reversedIndices[i];
            const searchStr = reversedStr[i];
            const inTag =
                plainText ||
                (strHtml.indexOf('<', index) > index && strHtml.indexOf('<', index) < strHtml.indexOf('"', index));
            if (inTag)
                strHtml =
                    strHtml.substring(0, index) +
                    '<span class="' +
                    highlightClass +
                    '">' +
                    strHtml.substring(index, index + searchStr.length) +
                    '</span>' +
                    strHtml.substring(index + searchStr.length);
        }
    }
    return strHtml;
};

export const debounce = (func: any, wait?: number, immediate?: boolean) => {
    let timeout;
    return (...args) => {
        const me = this;
        const later = function () {
            timeout = null;
            if (!immediate) func.apply(me, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait || 250);
        if (callNow) func.apply(me, args);
    };
};

export const addClass = (
    elementOrElementArray: HTMLElement | Array<HTMLElement | null> | null,
    className: string
): void => {
    if (elementOrElementArray !== null) {
        const elementSet = (
            Array.isArray(elementOrElementArray) ? elementOrElementArray : [elementOrElementArray]
        ).filter((e) => e !== null);
        for (let elmnt of elementSet) {
            if (elmnt !== null) {
                elmnt.className =
                    elmnt.className.indexOf(' ' + className) < 0
                        ? (elmnt.className += ' ' + className)
                        : elmnt.className;
            }
        }
    }
};

export const removeClass = (
    elementOrElementArray: HTMLElement | Array<HTMLElement | null> | null,
    className: string
): void => {
    if (elementOrElementArray !== null) {
        const elementSet = Array.isArray(elementOrElementArray) ? elementOrElementArray : [elementOrElementArray],
            classRipper = new RegExp('(?:^|\\s)' + className + '(?:\\s|$)', 'g');
        for (let elmnt of elementSet) {
            if (elmnt !== null) {
                elmnt.className = elmnt.className.replace(classRipper, ' ').trim();
            }
        }
    }
};

export const hasClass = (elt: HTMLElement | null, className: string): boolean => {
    return elt !== null && (' ' + elt.className + ' ').replace(/[\n\t]/g, ' ').indexOf(' ' + className + ' ') > -1;
};

export const parseStylesheet = (cssRules = ''): any[] => {
    const tokens = cssRules.split('}'),
        rules: any[] = [];
    let rule, selectors, css, skey;
    for (let t of tokens) {
        selectors = t.split('{')[0].split(',');
        css = parseCssStyles(t.split('{')[1]);
        for (let s of selectors) {
            skey = s.trim();
            // Clean up keys - issues with newlines and @imports and all sorts
            if (skey.indexOf(';') > 0) skey = skey.substring(skey.lastIndexOf(';') + 1).trim();
            if (skey.indexOf('*/') > 0) skey = skey.substring(skey.lastIndexOf('*/') + 2).trim();
            rule = findRule(rules, skey);
            if (rule !== undefined) {
                rule.style = {
                    ...rule.style,
                    ...css
                };
            } else {
                rule = {
                    selector: skey,
                    style: {
                        ...css
                    }
                };
                rules.push(rule);
            }
        }
    }
    return rules;
};

const findRule = (rules: any, selector: string): any => {
    return rules.find((r) => r.selector === selector);
};

export const parseCssStyles = (cssText: string): any => {
    const styles = {};
    if (!isNullOrUndefined(cssText)) {
        let pair, name;
        for (let token of cssText.split(';')) {
            pair = token.split(':');
            if (!isNullOrUndefined(pair[1])) {
                name = pair[0].trim().split('-');
                for (let i = 1; i < name.length; i++) {
                    name[i] = name[i].substring(0, 1).toUpperCase() + name[i].substring(1).toLowerCase();
                }
                styles[name.join('')] = pair.slice(1).join(':').trim().replace('http://', 'https://');
            }
        }
    }
    return styles;
};

/**
 * Utility method to test the status of a drag point w.r.t. _relative_ elements in a page
 * @param {any} element
 * @param {any} x
 * @param {any} y
 */
export const isOver = (element: HTMLElement, x: number, y: number): 'unknown' | 'over' | 'before' | 'after' => {
    const rect = element.getBoundingClientRect() || {
            left: element.offsetLeft,
            top: element.offsetTop,
            width: element.offsetWidth,
            height: element.offsetHeight
        },
        { left, top, width, height } = rect;
    let rv: 'unknown' | 'over' | 'before' | 'after' = 'unknown';
    if (x >= left && x <= left + width && y >= top && y <= top + height) rv = 'over';
    else if ((x < left + width && y <= top) || (x <= left && y < top + height)) rv = 'before';
    else if ((x > left + width && y >= top) || (x >= left && y > top + height)) rv = 'after';
    // if (rv === 'over') console.log(`${x},${y} in? [${left},${top} ${left + width},${top + height}]`); // DEBUG
    return rv;
};

export const isElementInViewport = (el: HTMLElement): boolean => {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */ &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
};

export const findAppVersion = (fallback = '2.0'): string => {
    const appNameTag = document.querySelector('head > meta[name="application-name"]');
    if (appNameTag !== undefined && appNameTag !== null) {
        const v = appNameTag.getAttribute('content');
        if (v !== undefined && v !== null && v.indexOf(';') > 0) {
            const b = v.split(';');
            if (b.length > 1) {
                const n = b[1].split('=')[1];
                if (n !== undefined && n !== null && n !== '') return n;
            }
        }
    }
    return fallback;
};

export const findIntersectingWidget = (container: HTMLElement, x: number, y: number, xyRelativeToContainer = false) => {
    const containerRect = container.getBoundingClientRect() || {
            left: container.offsetLeft,
            top: container.offsetTop,
            width: container.offsetWidth,
            height: container.offsetHeight
        },
        { left: cx, top: cy } = containerRect,
        ix = xyRelativeToContainer ? x + cx : x,
        iy = xyRelativeToContainer ? y + cy : y,
        overContainer = isOver(container, ix, iy);
    //console.log(`${container?.scrollTop}, overContainer? ${overContainer} ${ix},${iy}`); // DEBUG
    if (!isNullOrUndefined(container) && (overContainer === 'over' || overContainer === 'before')) {
        // before when a widget box has its top left corner outside but its centre point inside
        const widgets = Array.from(container.querySelectorAll<HTMLElement>('.ia-report-widget')).filter(
            (w) => (w.getAttribute('class') || '').indexOf('preview') < 0
        );
        let overWidget: HTMLElement | null = null,
            beforeWidget: HTMLElement | null = null,
            afterWidget: HTMLElement | null = null,
            overValue = '?';
        // Before any of them? Over any of them?
        for (let i = 0; i < widgets.length; i++) {
            overValue = isOver(widgets[i], ix, iy);
            // console.log(`${ix},${iy} ${overValue}: ${widgets[i].getAttribute('data-src-class')}`); // DEBUG
            if (overValue === 'over') {
                overWidget = widgets[i];
                break;
            } else if (beforeWidget === null && overValue === 'before') {
                beforeWidget = widgets[i];
            } else if (overValue === 'after') {
                afterWidget = widgets[i];
            }
        }
        if (overWidget !== null) {
            return {
                target: overWidget,
                position: 'over'
            };
        } else if (beforeWidget !== null) {
            return {
                target: beforeWidget,
                position: 'before'
            };
        } else if (afterWidget !== null) {
            return {
                target: afterWidget,
                position: 'after'
            };
        }
    }
    return null;
};

export const getThumbnail = (): Promise<Blob | null> => {
    return new Promise((resolve, reject) => {
        const element = document.querySelector<HTMLElement>(
            '.edit-surface .ia-report > .ia-report-page > .ia-report-section'
        );
        if (element !== null) {
            const elementBox = element.getBoundingClientRect() || {
                    left: element.offsetLeft,
                    top: element.offsetTop,
                    width: element.offsetWidth,
                    height: element.offsetHeight
                },
                opts = {};
            // Note - see https://github.com/niklasvh/html2canvas/pull/1948 for discussion/fix for Font Awesome issue - hopefully will be part of core before release of RB
            html2canvas(element, opts)
                .then((renderCanvas) => {
                    const ctx = renderCanvas.getContext('2d'),
                        logoSvg = document.querySelector<SVGElement>('.navbar .logo > svg'),
                        logoImg = document.querySelector<HTMLImageElement>('.navbar .navbar-brand .about-link > img'),
                        scale = renderCanvas.width / 600.0;
                    if (ctx !== null) {
                        const current = ctx.getImageData(0, 0, renderCanvas.width, scale * 400.0);
                        renderCanvas.style.height = '0'; //null;
                        renderCanvas.height = scale * 400.0;
                        ctx.resetTransform();
                        ctx.putImageData(current, 0, 0);
                        ctx.scale(scale, scale);
                        ctx.globalAlpha = 1;
                        ctx.fillStyle = '#4A3875';
                        ctx.fillRect(0, 395, 600, 5);
                        ctx.fillStyle = '#ffffff';
                        //ctx.fillRect(558, 360, 36, 36);
                        const finalizer = () => {
                            renderCanvas.toBlob((imgBytes) => {
                                resolve(imgBytes);
                            });
                        };
                        if (logoSvg !== undefined && logoSvg !== null) {
                            const svg = new Blob([logoSvg.outerHTML], { type: 'image/svg+xml;charset=utf-8' }),
                                url = URL.createObjectURL(svg),
                                img = new Image(32, 32);
                            img.onload = () => {
                                ctx.drawImage(img, 560, 362, 32, 32);
                                URL.revokeObjectURL(url);
                                finalizer();
                            };
                            img.src = url;
                        } else if (logoImg !== undefined && logoImg !== null) {
                            ctx.drawImage(logoImg, 560, 362, 32, 32);
                            finalizer();
                        } else finalizer();
                    } else resolve(null);
                })
                .catch((err) => {
                    console.log(`⚠️ Warning - error when saving thumbnail - non-critical: ${err}`);
                    resolve(null);
                });
        } else resolve(null);
    });
};
