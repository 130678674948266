import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as EsriFooterLogo } from '../assets/images//blogo.svg';
import { findAppVersion } from '../utils/dom';
import '../assets/styles/components/AppFooter.scss';

declare const __VERSION__: string;

const AppFooter = ({
    homeUrl = '',
    helpUrl = 'https://help.reports.esriuk.com/report-builder/',
    appStatus,
    appSource,
    omitTerms = false,
    content = 'minimal'
}: {
    homeUrl?: string;
    helpUrl?: string;
    appStatus?: string;
    appSource?: string;
    omitTerms?: boolean;
    content?: 'minimal' | 'full';
}) => {
    const appInDev =
        (appStatus !== undefined && appStatus === 'development') ||
        (typeof process !== 'undefined' &&
            typeof process.env !== 'undefined' &&
            process.env.NODE_ENV !== null &&
            process.env.NODE_ENV === 'development');
    let appVersion =
        typeof process !== 'undefined' &&
        typeof process.env !== 'undefined' &&
        process.env.REACT_APP_BUILD_NUMBER !== undefined
            ? process.env.REACT_APP_BUILD_NUMBER
            : findAppVersion('2.0');
    try {
        appVersion = __VERSION__;
    } catch (cannotFindHardWiredVersionEx) {
        // Null catch
    }

    return (
        <>
            <footer
                aria-label="Site Footer"
                className="rb-hidden sm:rb-block rb-bg-[var(--calcite-ui-foreground-1)] rb-border-t rb-border-solid rb-border-[var(--calcite-ui-foreground-3)] text-[var(--calcite-ui-text-1)]"
            >
                {content === 'full' ? (
                    <div className="rb-max-w-screen-xl rb-px-4 rb-py-6 rb-mx-auto rb-space-y-8 sm:rb-px-6 lg:rb-space-y-16 lg:rb-px-8">
                        <div className="rb-grid rb-grid-cols-3 rb-gap-8">
                            <div>
                                <div className="rb-mb-4">
                                    <a
                                        href="https://www.esriuk.com/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        tabIndex={-1}
                                        className="rb-text-inherit"
                                        aria-label="Esri UK homepage"
                                    >
                                        <EsriFooterLogo
                                            className="rb-h-8 text-[var(--calcite-ui-text-1)] rb-fill-current"
                                            title="Esri UK logo"
                                        />
                                    </a>
                                </div>
                                <p className="text-xs">
                                    Copyright © Esri UK Ltd {new Date().getFullYear().toFixed(0)}
                                    <br />
                                    All rights reserved
                                </p>
                            </div>

                            <div className="rb-grid rb-grid-cols-1 rb-gap-8 sm:rb-grid-cols-3 rb-col-span-2">
                                <div>
                                    <p className="rb-font-medium">
                                        <FormattedMessage
                                            id="footer.column.documentation.title"
                                            defaultMessage="Documentation"
                                        />
                                    </p>

                                    <nav
                                        aria-label="Footer Navigation - Company"
                                        className="rb-mt-3"
                                    >
                                        <ul className="rb-list-none rb-p-0 rb-space-y-2 rb-text-sm">
                                            <li>
                                                <a
                                                    className="rb-text-inherit rb-transition hover:rb-opacity-75"
                                                    href={`${helpUrl.replace(/^(.*)\/$/, '$1')}/`}
                                                >
                                                    <FormattedMessage
                                                        id="footer.item.welcome"
                                                        defaultMessage="Welcome"
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className="rb-text-inherit rb-transition hover:rb-opacity-75"
                                                    href={`${helpUrl.replace(
                                                        /^(.*)\/$/,
                                                        '$1'
                                                    )}/new-report-from-web-map/`}
                                                >
                                                    <FormattedMessage
                                                        id="footer.item.starter"
                                                        defaultMessage="Getting started"
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className="rb-text-inherit rb-transition hover:rb-opacity-75"
                                                    href={`${helpUrl.replace(
                                                        /^(.*)\/$/,
                                                        '$1'
                                                    )}/report-builder-faq/`}
                                                >
                                                    <FormattedMessage
                                                        id="footer.item.faq"
                                                        defaultMessage="FAQ"
                                                    />
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>

                                <div>
                                    <p className="rb-font-medium ">
                                        <FormattedMessage
                                            id="footer.column.resources.title"
                                            defaultMessage="Resources"
                                        />
                                    </p>

                                    <nav
                                        aria-label="Footer Navigation - Company"
                                        className="rb-mt-3"
                                    >
                                        <ul className="rb-list-none rb-p-0 rb-space-y-2 rb-text-sm">
                                            <li>
                                                <a
                                                    className="rb-text-inherit rb-transition hover:rb-opacity-75"
                                                    href="https://www.esriuk.com/en-gb/arcgis/products/instantatlas/products/report-builder"
                                                >
                                                    <FormattedMessage
                                                        id="footer.item.overview"
                                                        defaultMessage="Overview"
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className="rb-text-inherit rb-transition hover:rb-opacity-75"
                                                    href={`${helpUrl.replace(
                                                        /^(.*)\/$/,
                                                        '$1'
                                                    )}/blog/`}
                                                >
                                                    <FormattedMessage
                                                        id="footer.item.blog"
                                                        defaultMessage="Blog"
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className="rb-text-inherit rb-transition hover:rb-opacity-75"
                                                    href={`${helpUrl.replace(
                                                        /^(.*)\/$/,
                                                        '$1'
                                                    )}/gallery/`}
                                                >
                                                    <FormattedMessage
                                                        id="footer.item.gallery"
                                                        defaultMessage="Gallery"
                                                    />
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>

                                <div>
                                    <p className="rb-font-medium ">
                                        <FormattedMessage
                                            id="footer.column.company.title"
                                            defaultMessage="Company"
                                        />
                                    </p>

                                    <nav aria-label="Footer Navigation - Legal" className="rb-mt-3">
                                        <ul className="rb-list-none rb-p-0 rb-space-y-2 rb-text-sm">
                                            <li>
                                                <a
                                                    className="rb-text-inherit rb-transition hover:rb-opacity-75"
                                                    href="https://www.esriuk.com/en-gb/about/overview"
                                                >
                                                    <FormattedMessage
                                                        id="footer.item.about"
                                                        defaultMessage="About"
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className="rb-text-inherit rb-transition hover:rb-opacity-75"
                                                    href="https://www.esriuk.com/en-gb/legal/notices/privacy"
                                                >
                                                    <FormattedMessage
                                                        id="footer.item.privacy"
                                                        defaultMessage="Privacy"
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className="rb-text-inherit rb-transition hover:rb-opacity-75"
                                                    href="https://www.esriuk.com/en-gb/legal/terms-and-conditions"
                                                >
                                                    <FormattedMessage
                                                        id="footer.item.terms"
                                                        defaultMessage="Terms"
                                                    />
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="rb-max-w-screen-xl rb-px-4 rb-py-6 rb-mx-auto rb-space-y-8 sm:rb-px-6 lg:rb-space-y-16 lg:rb-px-8">
                        <div className="rb-flex rb-justify-between rb-items-center">
                            <div>
                                <a
                                    href="https://www.esriuk.com/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    tabIndex={-1}
                                    className="rb-text-inherit"
                                    aria-label="Esri UK homepage"
                                >
                                    <EsriFooterLogo
                                        className="rb-h-4 text-[var(--calcite-ui-text-1)] rb-fill-current"
                                        title="Esri UK logo"
                                    />
                                </a>
                            </div>
                            <div className="rb-text-xs">
                                <a
                                    href={`${homeUrl.replace(/(.*)\/$/, '$1')}/`}
                                    tabIndex={-1}
                                    className="rb-text-inherit"
                                >
                                    <FormattedMessage
                                        id="app.label.hub"
                                        defaultMessage="Powered by Report Builder for ArcGIS"
                                    />
                                    &nbsp;
                                    <FormattedMessage
                                        id="app.label.version"
                                        defaultMessage="{version}"
                                        values={{
                                            version: appInDev
                                                ? appVersion
                                                : appVersion.split('.').slice(0, 2).join('.'),
                                            fullVersion: appVersion
                                        }}
                                    />
                                    &nbsp;
                                    {appInDev ? <GitBranchComponent branch={appSource} /> : null}
                                </a>
                            </div>
                        </div>
                    </div>
                )}
            </footer>
        </>
    );
};

const GitBranchComponent = ({ branch }) => {
    const [dynamicBranch, setDynamicBranch] = useState('?');

    useEffect(() => {
        if (branch === undefined || branch === null || branch === '') {
            fetch(
                `${
                    typeof process !== 'undefined' && typeof process.env !== 'undefined'
                        ? process.env.PUBLIC_URL
                        : ''
                }/git-branch.txt`
            ).then((gbRsp) => {
                gbRsp.text().then((text) => {
                    setDynamicBranch(text);
                });
            });
        }
    }, [branch]);

    return (
        <em
            className="git-branch-name pure-tip pure-tip-top"
            style={{
                whiteSpace: 'nowrap'
            }}
            data-tooltip="in-dev@git-branch"
        >
            {' '}
            <i aria-hidden="true" className="fas fa-code-branch"></i>{' '}
            {branch !== undefined && branch !== null && branch !== '' ? branch : dynamicBranch}
        </em>
    );
};

export default AppFooter;
