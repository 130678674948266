import AbstractWidget from './AbstractWidget';
import { TextWidget } from './TextWidget';

export default class RemoteContentWidget extends AbstractWidget
{
    constructor(containerHtmlElement, design)
    {
        // Some old-to-new mappings...
        super(containerHtmlElement, {
            ...design, 
            url: design.url || design.text
        });
    }

    static getDefaults = () =>
    {
        return {
            ...AbstractWidget.getDefaults(),
            url: '',
            mode: 'embed', // | frame , 
            stylesheetUrl: '',
            overflow: 'scroll' // | hidden | grow
        };
    }

    // 
    render = (data, options = {}) =>
    {
        if ((this.container !== undefined) && (this.container !== null) && (this.design !== undefined) && (this.design !== null))
        {
            const doc = this.container.ownerDocument,
                topWidget = this.container.closest('.ia-report-widget'),
                { locale, numberFormat, noDataText, mode = 'embed', overflow = 'scroll' } = this.design,
                { portalUrl, token } = options;
            let srcUrl = this.design.url || this.design.text, // Text is legacy, will be replaced as time goes on!
                xsltUrl = this.design.stylesheetUrl;
            if ((srcUrl !== undefined) && (srcUrl !== null) && (srcUrl.indexOf('#') >= 0) && 
                ((data !== undefined) && (data !== null) && Array.isArray(data) && (data.length > 0)))
            {
                srcUrl = TextWidget.insertValuesIntoText(srcUrl, data, numberFormat, locale, noDataText, false);
            }
            if ((srcUrl !== undefined) && (srcUrl !== null) && 
                (portalUrl !== undefined) && (portalUrl !== null) &&
                (token !== undefined) && (token !== null) &&
                (srcUrl.indexOf(portalUrl) >= 0 || srcUrl.indexOf('.arcgis.com/') >= 0)) 
            {
                srcUrl = `${srcUrl}${(srcUrl.indexOf('?') > 0 ? '&' : '?')}&token=${token}`;
            }
            if ((srcUrl === undefined) || (srcUrl === null) || (srcUrl === ''))
            {
                const warn = doc.createElement('p');
                warn.setAttribute('class', 'ia-generated ia-remote ia-warning');
                warn.setAttribute('style', 'padding: 10px;');
                warn.appendChild(doc.createTextNode('⚠️ Set the URL property to see content here.'));
                this.container.appendChild(warn);
            }
            // <iframe> - really very simple but has issues...
            else if ((mode === 'frame') || (mode === 'iframe'))
            {
                const frame = doc.createElement('iframe');
                frame.setAttribute('class', 'ia-generated ia-remote');
                frame.setAttribute('style', `width: 100%; height: 100%; overflow: ${(overflow === 'grow' ? 'auto' : overflow)};`);
                frame.setAttribute('src', srcUrl);
                this.container.appendChild(frame);
            }
            // Download remote content - potentially more sophisticated but...
            else if (mode === 'embed')
            {
                fetch(srcUrl)
                    .then(rsp => 
                    {
                        return rsp.text();
                    })
                    .then(textContent => 
                    {
                        // What is it? Have to presume HTML...
                        const bodyIndex = textContent.indexOf('<body');
                        // XSLT? - more complex...
                        if ((xsltUrl !== undefined) && (xsltUrl !== null) && (xsltUrl !== ''))
                        {
                            fetch(xsltUrl)
                                .then(xrsp => 
                                {
                                    return xrsp.text();
                                })
                                .then(xsltText => 
                                {
                                    const xsltProcessor = new XSLTProcessor();
                                    xsltProcessor.importStylesheet(xsltText);
                                    const parser = new DOMParser(), 
                                        inputDoc = parser.parseFromString(textContent, "text/xml"),
                                        outputFragment = xsltProcessor.transformToFragment(inputDoc, topWidget.ownerDocument),
                                        frame = doc.createElement('div');
                                    frame.setAttribute('class', 'ia-generated ia-remote ia-transformed');
                                    frame.setAttribute('style', `width: 100%; height: ${(overflow === 'grow' ? 'auto' : '100%')};`);
                                    // Apply the scroll etc. to the container, because it's irrelevant for the inner content
                                    if (overflow === 'grow') topWidget.style.height = 'auto';
                                    else if (overflow === 'scroll') frame.style.overflow = 'scroll';
                                    else if (overflow === 'hidden') frame.style.overflow = 'hidden';
                                    frame.appendChild(outputFragment);
                                    this.container.appendChild(frame);
                                });
                        }
                        else if (bodyIndex >= 0)
                        {
                            let txt = textContent.substring(bodyIndex);
                            txt = txt.substring(txt.indexOf('>') + 1);
                            txt = txt.substring(0, txt.indexOf('</body>'));
                            // No <script>...
                            while (txt.indexOf('<script') >= 0)
                            {
                                const s = txt.indexOf('<script'),
                                    e = txt.indexOf('</script>', s);
                                txt = txt.substring(0, s) + txt.substring(e + '</script>'.length);
                            }
                            const frame = doc.createElement('div');
                            frame.setAttribute('class', 'ia-generated ia-remote');
                            frame.setAttribute('style', `width: 100%; height: ${(overflow === 'grow' ? 'auto' : '100%')};`);
                            this.container.appendChild(frame);
                            // Apply the scroll etc. to the container, because it's irrelevant for the inner content
                            if (overflow === 'grow') topWidget.style.height = 'auto';
                            else if (overflow === 'scroll') frame.style.overflow = 'scroll';
                            else if (overflow === 'hidden') frame.style.overflow = 'hidden';
                            frame.innerHTML = txt;
                        }
                    });
            }
            if (topWidget !== null) topWidget.setAttribute('class', topWidget.getAttribute('class').replace(/\s(placeholder)/g, ''));
        }
    }
}