import AbstractWidget, { convertLengthToPixels } from './AbstractWidget';
import AbstractChartWidget, { getBestTextForWidth, getPointStyle } from './AbstractChartWidget';
import { TextWidget } from './TextWidget';
import { parseColor } from './widgetHelpers';
import { isNullOrWhitespace, hyphenate } from '../utils/object';
import { getNumberFormatter } from '../utils/localization';
import { DataManipulator } from 'data-catalog-js-api';
import { nullifyEmptyValues } from './widgetDataUtils';
import ChartJS from '../lib/ChartJS';

export default class ScatterplotChartWidget extends AbstractChartWidget {
    // Render here is slightly special, because it can _detect_ if the calling (React) class has laid out some of the HTML before calling this method.
    render = (data, options = {}) => {
        if (
            this.container !== undefined &&
            this.container !== null &&
            this.design !== undefined &&
            this.design !== null
        ) {
            // Basics - this will give us the box or the chart content area... using super class
            const settings = {
                    ...ScatterplotChartWidget.getDefaults(),
                    ...this.design
                },
                dataTable = DataManipulator.mergeTables(
                    data.filter((d) => d.indicators !== undefined && d.indicators.length > 0),
                    !settings.includeAllAreas
                ), // Discard non-common features  unless they are comparisons
                chartContentElement = this.renderBox([dataTable], {
                    element: 'figure',
                    boxClass: 'ia-chart-box',
                    showTitle: hyphenate(settings.titlePosition) !== 'inside-chart',
                    messagePadType: 'padding',
                    allowBackgroundFill: false,
                    settings: {
                        ...settings,
                        messageBackground: settings.backgroundColor // Transfer chart background to message as well
                    }
                }),
                topWidget = this.container.closest('.ia-report-widget'),
                { activeFeature } = options,
                iAliases = this.indicatorAliases,
                aliasesProvided = iAliases !== undefined && iAliases !== null && iAliases.length > 0,
                palette =
                    settings.palette !== undefined && settings.palette !== null && settings.palette !== ''
                        ? AbstractChartWidget.getPaletteColors(settings.palette)
                        : [],
                nfmt = getNumberFormatter(settings.locale, settings.numberFormat),
                xNfmt = getNumberFormatter(settings.locale, settings.xAxisLabelFormat),
                yNfmt = getNumberFormatter(settings.locale, settings.yAxisLabelFormat),
                canvas = this.createCanvas(settings, data, chartContentElement);
            // Set the value indices on the columns, to pick data up later...
            for (let i = 0; i < dataTable.colIds.length; i++) dataTable.colIds[i].index = i;
            // But if required, re-sort
            if (aliasesProvided)
                AbstractWidget.applySortAndRename(dataTable.colIds, iAliases, dataTable.indicators, dataTable.rows);
            // Split data up - by indicator to start with...
            let chartData = DataManipulator.splitTable(dataTable).map((d, di) => {
                return {
                    name:
                        aliasesProvided && iAliases.find((ia) => ia.id === d.indicators[0].id) !== undefined
                            ? iAliases.find((ia) => ia.id === d.indicators[0].id).label
                            : settings.labelStyle.toLowerCase() === 'shortname' ||
                              settings.labelStyle.toLowerCase() === 'short-name'
                            ? d.indicators[0].shortName
                            : d.indicators[0].name,
                    data: d,
                    colors: palette.slice(di)
                };
            });
            const [xd, yd, zd] = chartData;
            //let aind;
            xd.data.labels = [];
            for (let i = 0; i < xd.data.rows.length; i++) {
                //aind =
                //    aliasesProvided && iAliases.find((ia) => ia.id === xd.data.rows[i][0].id) !== undefined
                //        ? iAliases.find((ia) => ia.id === xd.data.rows[i][0].id).label
                //        : xd.data.rows[i][0].name;
                //xd.data.rows[i][0] = settings.seriesLabelFormat
                //    .replace(/#NAME/g, xd.data.rows[i][0])
                //    .replace(/#FNAME/g, xd.data.rows[i][0])
                //    .replace(/#INAME/g, aind);
                for (let j = 1; j < xd.data.rows[i].length; j++) {
                    xd.data.rows[i][j] = {
                        x: xd.data.rows[i][j],
                        y: yd.data.rows[i][j],
                        v: zd !== undefined ? zd.data.rows[i][j] : undefined
                    };
                }
                xd.data.labels.push(xd.data.rows[i][0]);
            }
            chartData = chartData.slice(0, 1);
            if (settings.animated) topWidget.setAttribute('class', `${topWidget.getAttribute('class')} ia-animated`);
            // chart.js 3 needs empty values not undefined - in-place
            nullifyEmptyValues(chartData, true);
            const chartEngine = new ChartJS(canvas.getContext('2d')),
                chartTitleText = TextWidget.insertValuesIntoText(
                    settings.titleText,
                    dataTable,
                    settings.numberFormat,
                    settings.locale,
                    '',
                    false
                ),
                highlightLabel =
                    settings.includeAllAreas &&
                    settings.highlightSelectedFeature &&
                    (settings.highlightStyle === 'fill-and-callout' ||
                        settings.highlightStyle === 'FillAndCallout' ||
                        settings.highlightStyle === 'fill-and-text' ||
                        settings.highlightStyle === 'FillAndText'),
                dataPointLabels =
                    settings.labelDataPointsWithValue !== undefined &&
                    settings.labelDataPointsWithValue !== null &&
                    settings.labelDataPointsWithValue === true,
                chartPlugins = {
                    datalabels: {
                        enabled: dataPointLabels,
                        font: {
                            family: settings.axisFontFamily,
                            size: settings.axisFontSize
                        }
                    }
                },
                chartOpts = {
                    ...this.getChartOptions(settings)
                };
            if (settings.paletteIsFixed === true) {
                for (let d of chartData) {
                    let c = d.colors;
                    if (c.length > 0) {
                        while (c.length < d.data.rows.length) {
                            c = c.concat([...c]);
                        }
                        d.colors = [...c];
                    }
                }
            }
            this.chart = chartEngine.render({
                name: 'scatter',
                datasets: chartData,
                config: {
                    beginAtZero: true,
                    fontFamily: settings.axisFontFamily,
                    numberFormatter: nfmt,
                    displayLegend: settings.showLegend,
                    legendPosition: settings.legendPosition.toLowerCase(),
                    legendFontSize: parseInt(settings.legendFontSize),
                    titles:
                        hyphenate(settings.titlePosition) === 'inside-chart' &&
                        chartTitleText !== null &&
                        chartTitleText !== ''
                            ? getBestTextForWidth(
                                  canvas.getContext('2d'),
                                  chartTitleText,
                                  convertLengthToPixels(settings.width, true, true) - 40,
                                  settings.axisFontFamily,
                                  settings.titleFontSize
                              ).lines
                            : [],
                    titleFontSize: parseInt(settings.titleFontSize),
                    titleFontWeight: settings.titleIsBold === true ? 'bold' : 'normal',
                    titleFontColor:
                        !isNullOrWhitespace(settings.titleColor) && hyphenate(settings.titleColor) !== 'not-set'
                            ? settings.titleColor
                            : undefined,
                    xAxisLabel: TextWidget.insertValuesIntoText(
                        settings.xAxisTitle,
                        dataTable,
                        settings.numberFormat,
                        settings.locale,
                        '',
                        false
                    ),
                    yAxisLabel: TextWidget.insertValuesIntoText(
                        settings.yAxisTitle,
                        dataTable,
                        settings.numberFormat,
                        settings.locale,
                        '',
                        false
                    ),
                    xAxisFontSize: parseInt(settings.axisFontSize),
                    yAxisFontSize: parseInt(settings.axisFontSize),
                    xAxisFontColor: parseColor(settings.axisFontColor || settings.xAxisColor || '#333'),
                    yAxisFontColor: parseColor(settings.axisFontColor || settings.yAxisColor || '#333'),
                    maintainAspectRatio: false,
                    animationResizeDuration: 300,
                    type: zd !== undefined ? 'bubble' : 'scatter',
                    plugins: chartPlugins,
                    splitRows: true,
                    options: chartOpts
                },
                preRender: (chartConfig) => {
                    // Standard axes settings
                    this.applyAxesSettings(settings, chartConfig);
                    // Then scatterplot specific
                    chartConfig.options.scales.x.ticks.callback = (value, index, values) => {
                        return xNfmt.format(value);
                    };
                    chartConfig.options.scales.y.ticks.callback = (value, index, values) => {
                        return yNfmt.format(value);
                    };
                    this.applyEvents(settings, chartConfig, options);
                    if (dataPointLabels) this.applyDataPointLabels(settings, chartConfig);
                    // Scatter chart specific...
                    const {
                        maxPointRadius = 20,
                        minPointRadius = 3,
                        scalePointsByChartSize = true,
                        featureLineMarkers
                    } = settings;
                    let dMin = Number.MAX_VALUE,
                        dMax = -1 * Number.MAX_VALUE;
                    for (let cd of chartConfig.data.datasets) {
                        for (let dataPoint of cd.data) {
                            if (dataPoint.v !== undefined) {
                                if (dataPoint.v < dMin) dMin = dataPoint.v;
                                if (dataPoint.v > dMax) dMax = dataPoint.v;
                            }
                        }
                    }
                    const dataMin = dMin,
                        dataMax = dMax,
                        pointSize =
                            minPointRadius !== undefined
                                ? convertLengthToPixels(minPointRadius, false, true, 1) * 2
                                : 6,
                        pointRange =
                            convertLengthToPixels(maxPointRadius, false, true, 20) -
                            convertLengthToPixels(minPointRadius, false, true, 1),
                        targetChartSize = convertLengthToPixels(settings.width, false, true, -1);
                    chartConfig.options.elements.point = {
                        ...chartConfig.options.elements.point,
                        radius: (context) => {
                            const pointValue = context.dataset.data[context.dataIndex],
                                chartSize = context.chart.width,
                                scaleFactor =
                                    scalePointsByChartSize && targetChartSize > 0
                                        ? (chartSize * 1.0) / targetChartSize
                                        : 1;
                            if (pointValue.v !== undefined && dataMin !== Number.MAX_VALUE) {
                                const scaledValue = (pointValue.v - dataMin) / (dataMax - dataMin),
                                    scaledSize = scaledValue * pointRange + pointSize * 0.5;
                                console.log(
                                    pointValue.v +
                                        ' ' +
                                        scaledSize +
                                        '?' +
                                        (pointValue.v - dataMin) +
                                        '>' +
                                        (dataMax - dataMin)
                                ); // DEBUG
                                return scaledSize * scaleFactor;
                            } else return pointSize / 2.0;
                        },
                        pointStyle: featureLineMarkers !== undefined ? getPointStyle(featureLineMarkers) : 'circle'
                    };
                    if (
                        settings.includeAllAreas &&
                        settings.highlightSelectedFeature &&
                        settings.highlightColor !== undefined &&
                        settings.highlightColor !== null &&
                        activeFeature !== undefined &&
                        activeFeature !== null &&
                        chartData.length > 0
                    ) {
                        const activeIds = Array.isArray(activeFeature)
                                ? activeFeature.map((f) => f.id)
                                : [activeFeature.id],
                            activeIndices = chartConfig.data.datasets
                                .map((d, i) => (activeIds.indexOf(d.id) >= 0 ? i : -1))
                                .filter((i) => i >= 0);
                        //chartConfig.data.labelKeys = activeData.rowIds.slice(0);
                        if (activeIndices.length >= 0) {
                            for (let i of activeIndices) {
                                chartConfig.data.datasets[i].backgroundColor = settings.highlightColor;
                            }
                            if (highlightLabel) {
                                chartConfig.options.plugins = chartConfig.options.plugins || {};
                                const bgColor =
                                    settings.highlightStyle === 'fill-and-callout' ||
                                    settings.highlightStyle === 'FillAndCallout'
                                        ? settings.highlightColor
                                        : 'rgba(255,255,255,0)';
                                chartConfig.options.plugins.datalabels = {
                                    enabled: true,
                                    display: (context) => {
                                        return activeIndices.indexOf(context.dataIndex) >= 0;
                                    },
                                    backgroundColor: (context) => {
                                        return activeIndices.indexOf(context.dataIndex) >= 0 ? bgColor : null;
                                    },
                                    formatter: (value, context) => {
                                        const lbl = chartConfig.data.labels[context.dataIndex];
                                        return activeIndices.indexOf(context.dataIndex) >= 0
                                            ? `${Array.isArray(lbl) ? lbl.join(' ') : lbl}: ${nfmt.format(value)}`
                                            : null;
                                    },
                                    offset: 8,
                                    anchor: settings.yAxisReversed ? 'start' : 'end',
                                    align: settings.yAxisReversed ? 'start' : 'end',
                                    clamp: true,
                                    font: {
                                        family: settings.axisFontFamily,
                                        size: settings.axisFontSize
                                    }
                                };
                            }
                        }
                    }
                    if (chartConfig.options.plugins.legend !== undefined) {
                        if (chartConfig.options.plugins.legend.labels === undefined)
                            chartConfig.options.plugins.legend.labels = {};
                        chartConfig.options.plugins.legend.labels.usePointStyle = true;
                        chartConfig.options.plugins.legend.labels.boxWidth = Math.max(6, pointSize);
                    }
                    if (dataPointLabels) {
                        this.applyDataPointLabels(
                            {
                                labelDataPointsOffset: '0px',
                                labelDataPointsAnchor: 'center',
                                ...settings
                            },
                            chartConfig
                        );
                    }
                },
                onComplete: (ce) => {
                    //if (!animBound && settings.animated)
                    //{
                    //    const widgetId = topWidget.getAttribute('id');
                    //    window[`widgetChart${widgetId}`] = chartEngine.chart;
                    //    AbstractChartWidget.bindAnimationUpdate(topWidget);
                    //}
                }
            });
            this.applyChartActions(settings, chartData);
            topWidget.setAttribute('class', topWidget.getAttribute('class').replace(/\s(placeholder)/g, ''));
        }
    };

    static getDefaults = () => {
        return {
            ...AbstractChartWidget.getDefaults(),
            ...AbstractChartWidget.plotAreaDefaults,
            spanGaps: false, // New in RB 2.0, default is therefore "off"
            seriesLabelFormat: '#NAME',
            includeAllAreas: true,
            showLegend: false,
            xAxisTitle: '#INAME{1}',
            xAxisLabelFormat: '#,##0.#',
            yAxisTitle: '#INAME{2}',
            yAxisLabelFormat: '#,##0.#',
            minPointRadius: 3,
            maxPointRadius: 20,
            xAxisColor: '#000000',
            xAxisShowGrid: true,
            xAxisGridColor: '#cccccc',
            yAxisColor: '#000000',
            yAxisShowGrid: true,
            yAxisGridColor: '#cccccc',
            labelDataPointsWithValue: false,
            labelDataPointsRotation: 0,
            labelDataPointsAlign: 'center',
            labelDataPointsFormat: '#VALZ'
        };
    };
}
