import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import '@esri/calcite-components/dist/components/calcite-label';
import '@esri/calcite-components/dist/components/calcite-icon';
import '@esri/calcite-components/dist/components/calcite-switch';
import { CalciteLabel, CalciteIcon, CalciteSwitch } from '@esri/calcite-components-react';

const DARK_THEME = 'calcite-mode-dark',
    LIGHT_THEME = 'calcite-mode-light',
    THEME_KEY = 'ia.uiTheme';

const DarkModeSwitch = ({
    darkIcon = (
        <>
            <span>
                <FormattedMessage id="app.theme.dark.label" defaultMessage="Dark" />
            </span>{' '}
            <i className="fas fa-fw fa-moon"></i>
        </>
    ),
    lightIcon = (
        <>
            <span>
                <FormattedMessage id="app.theme.light.label" defaultMessage="Light" />
            </span>{' '}
            <i className="fas fa-fw fa-sun"></i>
        </>
    ),
    showInterface = true
}) => {
    const [theme, setTheme] = useState<string>(
        localStorage !== undefined && localStorage.getItem(THEME_KEY) !== null
            ? localStorage.getItem(THEME_KEY) || LIGHT_THEME
            : LIGHT_THEME
    );

    useEffect(() => {
        document.documentElement.classList.replace(DARK_THEME, theme);
        document.documentElement.classList.replace(LIGHT_THEME, theme);
        if (!document.documentElement.classList.contains(theme))
            document.documentElement.classList.add(theme);
        localStorage.setItem(THEME_KEY, theme);
    }, [theme]);

    return showInterface ? (
        <ul className="inline-list">
            <li>
                <CalciteLabel layout="inline" className="label-wrapper">
                    <CalciteIcon icon="brightness" scale="s" class="switch-icon"></CalciteIcon>
                    <CalciteSwitch
                        checked={theme === DARK_THEME ? true : undefined}
                        onCalciteSwitchChange={() => {
                            setTheme((t) => {
                                return t === DARK_THEME ? LIGHT_THEME : DARK_THEME;
                            });
                        }}
                    ></CalciteSwitch>
                    <CalciteIcon icon="moon" scale="s" class="switch-icon"></CalciteIcon>
                </CalciteLabel>
            </li>
        </ul>
    ) : (
        <></>
    );
};

export default DarkModeSwitch;
