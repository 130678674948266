import { isNullOrUndefined, hyphenate } from './object';
import { parseStylesheet, parseCssStyles } from './dom';
import { standardColorSchemes } from './appUtilsAndConstants';
import { STANDARD_PALETTES, STANDARD_PALETTES_KEYS } from '../widgets/widgetHelpers';
import { parseQueryToObject } from './object';
import { TextWidget } from '../widgets/TextWidget';
import { convertLengthToPixels, lengthWithUnit } from '../widgets/AbstractWidget';

export interface IReport {
    id: string;
    version: string;
    design: IReportDesign;
    modified: Date;
}

export interface IReportDesign {
    sections: IReportSectionDesign[];
}

export interface IReportSectionDesign {
    id: string;
    widgets: any[];
}

/**
 * Get a simple vanilla or placeholder report that can be customized by a user.
 * Is very similar to the report definition from Report Builder 1.0 but with some simplifications and updates
 * that make it easier to deal with in (a) script and (b) modern browsers.
 * */
export const VANILLA_REPORT = {
    id: null,
    version: '2.0',
    properties: {
        title: 'Report Builder for ArcGIS - Report',
        description: '',
        startup: '',
        export: ''
    },
    design: {
        sections: [
            {
                widgets: []
            }
        ],
        font: {
            family: 'Lato, sans-serif',
            size: '16px',
            extras: []
        },
        style: {
            lineHeight: 'normal'
        },
        pageStyles: {
            layout: 'relative',
            size: {
                width: '1000px',
                height: 'auto'
            },
            margin: '10px 10px 10px 10px',
            display: 'single',
            border: 'light',
            navigation: 'bar-and-arrows',
            load: 'when-in-view', // or 'always'
            responsive: {
                enabled: 'both',
                width: '500px',
                height: null
            }
        }
    },
    data: {
        sources: []
    },
    modified: new Date(1999, 0, 1, 0, 0, 0, 0) // Clearly not modified then - basically "vanilla marker"
};

export const getEmptyDesign = () => {
    return JSON.parse(JSON.stringify(VANILLA_REPORT)); // Or object.assign, or any other manner of doing it!
};

// Utility function to tidy up some naming and class/property conventions from older reports (built with RB 1.x)
export const migrateDesign = (legacyReportDesign: any, forceCleanUpdate = false) => {
    const changesLog = [];
    // Version stamp - this will be part of our new design template/model, so if it is there...
    if (
        isNullOrUndefined(legacyReportDesign) ||
        (!forceCleanUpdate &&
            !isNullOrUndefined(legacyReportDesign.version) &&
            parseFloat(legacyReportDesign.version) > 0)
    ) {
        legacyReportDesign.modified = legacyReportDesign.modified === undefined ? false : legacyReportDesign.modified;
        // Interim - this code may disappear - work-in-progress - earlier migrations did not have this so...
        if (legacyReportDesign.design.pageLayoutStyles !== undefined) {
            const migratedPageLayout = parseStylesheet(legacyReportDesign.design.pageLayoutStyles);
            migratePageLayoutStyles(migratedPageLayout, legacyReportDesign.design, changesLog);
            delete legacyReportDesign.design.pageLayoutStyles;
        }
        legacyReportDesign.design.colors = migrateCustomColors(legacyReportDesign.design);
        return {
            report: legacyReportDesign,
            changes: changesLog
        };
    }
    // Slimmed down and cleaned up version of the same information in a more JS rather than C# style...
    const startupQs = parseQueryToObject(
            legacyReportDesign.reportStartupParameters !== undefined ? legacyReportDesign.reportStartupParameters : ''
        ),
        migratedDesign = migrateInnerDesign(
            legacyReportDesign.report !== undefined ? legacyReportDesign.report : legacyReportDesign.design,
            changesLog
        ),
        migratedPageLayout =
            migratedDesign.legacyStyles !== undefined ? parseStylesheet(migratedDesign.legacyStyles) : [],
        colorSet = migrateCustomColors(migratedDesign);
    // Clean up some styling...
    delete migratedDesign.legacyStyles;
    migratePageLayoutStyles(migratedPageLayout, migratedDesign, changesLog);
    if (startupQs.pageBorder !== undefined && startupQs.pageBorder !== null)
        migratedDesign.pageStyles.border = startupQs.pageBorder.toString().toLowerCase();
    if (startupQs.pageFlow !== undefined && startupQs.pageFlow !== null)
        migratedDesign.pageStyles.display = startupQs.pageFlow.toString().toLowerCase().replace('paged', 'single');
    if (startupQs.pageLoad !== undefined && startupQs.pageLoad !== null)
        migratedDesign.pageStyles.render = startupQs.pageLoad.toString().toLowerCase();
    const reportDef = {
        id: legacyReportDesign.id,
        version: '2.0',
        properties: {
            title: legacyReportDesign.title,
            description: legacyReportDesign.description,
            startup: {},
            export: legacyReportDesign.allowExportToFormats
        },
        design: {
            ...migratedDesign,
            colors: {
                ...colorSet
            },
            index: {
                description: startupQs.indexPageUseDescription,
                webMapId: startupQs.indexMapId,
                layout: startupQs.indexLayout,
                linkStyle: startupQs.indexLinkStyle,
                mapBackground: startupQs.indexMapBackground,
                tooltipFormat: startupQs.tooltipFormat,
                legend: startupQs.useLegend
            },
            popup: {
                size: startupQs.popupSize
            }
        },
        data: {
            sources:
                legacyReportDesign.data !== undefined
                    ? legacyReportDesign.data.sources
                    : migrateDataSources(legacyReportDesign.dataSources)
        },
        migrated: 'auto',
        modified: legacyReportDesign.modified === undefined ? false : legacyReportDesign.modified
    };
    return {
        report: reportDef,
        changes: changesLog
    };
};

export const updateDesignFromCommand = (report: any, updateCommand: string, commandArgs: URLSearchParams): any => {
    if (updateCommand === 'migrate-dates') {
        return migrateDesignDates(
            report,
            commandArgs.get('mode') ?? 'replace',
            commandArgs.get('output-format') ?? undefined,
            commandArgs.get('indicators') ?? undefined
        );
    }
    return {
        report,
        changes: []
    };
};

const migrateDesignDates = (
    dateBoundReportDesign: any,
    replaceOrAppend: string = 'replace',
    outputFormat: string = 'IDiiiDyyyyMMdd000000',
    indicatorList?: string
) => {
    const formatter = new Intl.DateTimeFormat(dateBoundReportDesign.locale ?? 'en', {
        year: 'numeric',
        month: 'short',
        day: '2-digit'
    });
    const indFilter = indicatorList !== undefined ? indicatorList.split(',') : [];
    let changesLog: any[] = [];
    for (const s of dateBoundReportDesign.design.sections) {
        for (const w of s.widgets) {
            if (w.indicators) {
                for (const i of w.indicators) {
                    if ((indFilter.length === 0 || indFilter.includes(i.id)) && i.dates) {
                        const ndates = i.dates.length;
                        let changed = false;
                        for (let j = 0; j < ndates; j++) {
                            const matcher =
                                /^(?<year>[0-9]{4})-(?<month>[0-9]{2})-?(?<day>[0-9]{2})?$|^ID(?<ind>[0-9]+)D(?<yyyy>[0-9]{4})(?<mm>[0-9]{2})(?<dd>[0-9]{2})000000$/gim.exec(
                                    i.dates[j].trim()
                                );
                            if (matcher !== null && matcher.groups) {
                                const { year, month, day, yyyy, mm, dd } = matcher.groups;
                                const d = new Date(
                                    parseInt(year ?? yyyy),
                                    parseInt(month ?? mm) - 1,
                                    parseInt(day ?? dd ?? '1')
                                );
                                const parts = formatter.formatToParts(d);
                                const amended = outputFormat
                                    .replace('iiii', i.id)
                                    .replace('iii', i.id.replace(/[^0-9]/g, ''))
                                    .replace('yyyy', parts.find((p) => p.type === 'year')?.value ?? '0000')
                                    .replace('MMM', parts.find((p) => p.type === 'month')?.value ?? 'Abc')
                                    .replace('MM', month ?? mm)
                                    .replace('dd', parts.find((p) => p.type === 'day')?.value ?? '01');
                                if (replaceOrAppend === 'append') {
                                    i.dates.push(amended);
                                } else {
                                    i.dates.splice(j, 1, amended);
                                }
                                changed = true;
                            }
                        }
                        if (changed) {
                            changesLog.push({
                                type: 'info',
                                message: `Dates: selection for ${i.id} migrated to [${i.dates.join(', ')}]`
                            });
                        }
                    }
                }
            }
        }
    }
    return {
        report: dateBoundReportDesign,
        changes: changesLog
    };
};

const migratePageLayoutStyles = (legacyStyleRules: any[] = [], targetDesign: any, changesLog: any[] = []) => {
    let rule = legacyStyleRules.find((r) => r.selector === '.profileReport');
    if (rule !== undefined && rule.style !== undefined && rule.style.fontFamily !== undefined) {
        if (targetDesign.font === undefined) targetDesign.font = {};
        targetDesign.font.family = rule.style.fontFamily;
        if (rule.style.fontSize !== undefined) targetDesign.font.size = rule.style.fontSize;
        if (rule.style.fontStyle !== undefined) targetDesign.font.style = rule.style.fontStyle;
        if (rule.style.fontWeight !== undefined) targetDesign.font.weight = rule.style.fontWeight;
        changesLog.push({
            type: 'info',
            message: `Fonts: style rule ${rule.selector} migrated to JSON design rule ${JSON.stringify(
                targetDesign.font
            )}`
        });
    }
    rule = legacyStyleRules.find((r) => r.selector === '.profileReport .iaProfileSection');
    if (rule !== undefined) {
        if (targetDesign.pageStyles === undefined) targetDesign.pageStyles = {};
        if (targetDesign.pageStyles.size === undefined) targetDesign.pageStyles.size = {};
        if (rule.style.width !== undefined) targetDesign.pageStyles.size.width = rule.style.width;
        if (rule.style.height !== undefined) targetDesign.pageStyles.size.height = rule.style.height;
        if (targetDesign.pageStyles.responsive === undefined) {
            targetDesign.pageStyles.responsive = {
                enabled: 'both',
                width: `${convertLengthToPixels(rule.style.width, true, true).toFixed(0)}px`,
                height: null
            };
        }
        changesLog.push({
            type: 'info',
            message: `Layout: style rule ${rule.selector} migrated to JSON design rule ${JSON.stringify(
                targetDesign.pageStyles
            )}`
        });
    }
    rule = legacyStyleRules.find((r) => r.selector === '.profileReport .iaProfileSectionContentWrapper');
    if (rule !== undefined) {
        if (targetDesign.pageStyles === undefined) targetDesign.pageStyles = {};
        targetDesign.pageStyles.margin = `${rule.style.marginTop !== undefined ? rule.style.marginTop : 'inherit'} ${
            rule.style.marginRight !== undefined ? rule.style.marginRight : 'inherit'
        } ${rule.style.marginBottom !== undefined ? rule.style.marginBottom : 'inherit'} ${
            rule.style.marginLeft !== undefined ? rule.style.marginLeft : 'inherit'
        }`;
        changesLog.push({
            type: 'info',
            message: `Margins: style rule ${rule.selector} migrated into JSON design rule ${JSON.stringify(
                targetDesign.pageStyles
            )}`
        });
    }
};

const migrateCustomColors = (reportDesign) => {
    let colorSet: any = {
            standard: '#111',
            heading: '#111',
            light: '#777',
            border: '#999',
            boxBackground: '#ccc',
            boxForeground: '#111',
            boxContentBackground: '#ffffff'
        },
        overrides = reportDesign.colors !== undefined ? reportDesign.colors : {};
    const schemesRegex = /~\/styles\/schemes\//gi,
        getSchemeById = (sid) => {
            return standardColorSchemes.find((c) => c.id === sid);
        };
    if (reportDesign.cssPaths !== undefined && reportDesign.cssPaths !== null) {
        let cp;
        for (let i = reportDesign.cssPaths.length - 1; i >= 0; i--) {
            cp = reportDesign.cssPaths[i];
            const schemeKey = cp.replace(schemesRegex, '').split('.')[0].toLowerCase(); // Pull out the 'olive' from ~/styles/schemes/olive.min.css
            // If we have a new definition of the color scheme, use that...
            if (!isNullOrUndefined(getSchemeById(schemeKey))) {
                colorSet = getSchemeById(schemeKey);
                reportDesign.cssPaths.splice(i, 1);
            }
            // Custom? Deal with this differently now... CSS variables in Custom.css, but this is still legacy - see below for different approach...
            if (cp.indexOf('custom.css') > 0 && cp.split('?').length > 1) {
                const qs = parseQueryToObject(cp.split('?')[1]);
                if (qs['base'] !== undefined) colorSet.standard = qs['base'];
                if (qs['border'] !== undefined) colorSet.border = qs['border'];
                if (qs['heading'] !== undefined) colorSet.heading = qs['heading'];
                if (qs['background'] !== undefined) colorSet.boxBackground = qs['background'];
                if (qs['foreground'] !== undefined) colorSet.boxForeground = qs['foreground'];
                reportDesign.cssPaths.splice(i, 1);
            }
        }
    }
    return {
        ...colorSet,
        ...overrides
    };
};

const migrateDataSources = (legacySources: any[] = []): any[] => {
    /* connectionType: "ArcGISOnline"
id: "data-source-000"
parameters: "&src=https%3A%2F%2Fservices1.arcgis.com%2FHumUw0sDQHwJuboT%2Farcgis%2Frest%2Fservices%2FCumbria_BaseMaps%2FFeatureServer%2F1&layer=&idField=LTLACode&nameField=NAME&master=False&name=LTLA&transient=False"
url: "https://www.arcgis.com/sharing/rest" */
    const migrated: any[] = [];
    let p,
        nds,
        offset = 0;
    for (let ds of legacySources) {
        if (!isNullOrUndefined(ds.connectionType) && ds.connectionType === 'ArcGISOnline') {
            p = parseQueryToObject(ds.parameters);
            nds = {
                id: ds.id,
                type: 'FeatureService',
                url: p['src'].replace('http://', 'https://'),
                name: p['name'],
                params: {
                    ...p
                },
                transient: p['transient'] !== undefined ? p['transient'] : false,
                primary: offset === 0
            };
            delete nds.params.src;
            delete nds.params.name;
            delete nds.params.transient;
            // Only feature services can be the primary source...
            offset++;
        } else {
            p = parseQueryToObject(ds.parameters);
            nds = {
                id: ds.id,
                type: ds.connectionType.replace('ArcGISOnlineDataStore', 'DataCatalog'),
                url: ds.url.replace('http://', 'https://'),
                name: p['name'],
                params: {
                    ...p
                },
                transient: false
            };
            delete nds.params.name;
            delete nds.params.transient;
            // CSV columns, special case...
            if (nds.params.columns !== undefined) {
                nds.params.columns = JSON.parse(nds.params.columns);
                // Legacy - .NET casing in RB1
                let cc = nds.params.columns.Items.find((c) => c.IsFeatureIdColumn === true);
                if (cc !== undefined) nds.params.featureIdField = cc.Name;
                cc = nds.params.columns.Items.find((c) => c.IsFeatureNameColumn === true);
                if (cc !== undefined) nds.params.featureNameField = cc.Name;
            }
        }
        // Add an extra check here to exclude duplicates...
        if (findByUrl(migrated, nds.url) === undefined) migrated.push(nds);
        else
            console.warn(
                `Duplicate data source: ${ds.id} has been rejected because there is already another source with the same URL ${nds.url}`
            );
    }
    return migrated;
};

const findByUrl = (migrated, url) => {
    return migrated.find((d) => d.url === url);
};

const migrateInnerDesign = (legacyDesign: any, changesLog: any[] = []): any => {
    const migrated = {
            font: {
                family: 'Lato',
                size: '15px'
            },
            ...legacyDesign
        },
        dataVoidWidgets = ['TextBreakWidget'];
    let sat = 0;
    for (let s of migrated.sections) {
        const clonedWidgets: any[] = [];
        let clone, cloneChild;
        sat++;
        changesLog.push({ type: 'groupStart', message: `Section #${sat}: migrating widgets...` });
        for (let w of s.widgets) {
            // Clone the widget, because we are pulling "up" some of the properties to simplify it...
            clone = {
                ...w.settings,
                ...w
            };
            // But deal with some special cases...
            if (w.settings.description !== undefined) clone.description = w.settings.description;
            delete clone.settings;
            if (clone.className !== undefined) {
                clone.scriptClass = clone.className
                    .replace(/GeoWise\.IAS\.ProfileBuilderCloud/g, '')
                    .replace(/GeoWise\.InstantAtlas\.Cloud\.ReportBuilder/g, '')
                    .replace(/GeoWise\.IAS\.Web\.Widgets/g, '')
                    .replace(/\./g, '')
                    .replace(/,/g, '')
                    .replace(/^Html(.+)$/, '$1')
                    .replace('RepeatingIconWidget', 'IconRepeaterWidget')
                    .replace('ArcGisOnlineRelatedItemsTableWidget', 'RelatedRecordsTableWidget')
                    .replace('ArcGisOnlineRelatedRecordsTableWidget', 'RelatedRecordsTableWidget')
                    .replace('ArcGisOnlineLayerAttachmentsWidget', 'LayerAttachmentsWidget')
                    .replace('ScatterPlotWidget', 'ScatterplotChartWidget')
                    .trim();
                // Special case - widgets that have changed type...
                if (
                    clone.scriptClass === 'IconRepeaterWidget' &&
                    clone['indicatorIconsLayout'] !== undefined &&
                    clone['indicatorIconsLayout'] !== null &&
                    clone['indicatorIconsLayout'].toLowerCase().indexOf('barchart') === 0
                ) {
                    clone.scriptClass = 'IconBarChartWidget';
                }
                changesLog.push({
                    type: 'info',
                    message: `Widget: ${clone.scriptClass} migrated from ${clone.className}#${
                        clone.id || clone.uniqueCode
                    }`
                });
                delete clone.className;
                // Special case(s) - migration from old >> new may need more properties where model has changed...
                if (clone.scriptClass === 'EmbeddedWebScriptWidget') clone.libraries = 'jquery';
            }
            // 1 level of nesting - can we relax this? Not now...
            if (!isNullOrUndefined(w.childWidgets)) {
                clone.childWidgets = [];
                for (let cw of w.childWidgets) {
                    cloneChild = {
                        ...cw.settings,
                        ...cw
                    };
                    // But deal with some special cases...
                    if (cw.settings.description !== undefined) cloneChild.description = cw.settings.description;
                    delete cloneChild.settings;
                    if (cloneChild.className !== undefined) {
                        cloneChild.scriptClass = cloneChild.className
                            .replace(/GeoWise\.InstantAtlas\.Cloud\.ReportBuilder/g, '')
                            .replace(/GeoWise\.IAS\.Web\.Widgets/g, '')
                            .replace(/\./g, '')
                            .replace(/,/g, '')
                            .replace(/^Html(.+)$/, '$1')
                            .replace('RepeatingIconWidget', 'IconRepeaterWidget')
                            .replace('ArcGisOnlineRelatedItemsTableWidget', 'RelatedRecordsTableWidget')
                            .replace('ArcGisOnlineRelatedRecordsTableWidget', 'RelatedRecordsTableWidget')
                            .replace('ArcGisOnlineLayerAttachmentsWidget', 'LayerAttachmentsWidget')
                            .replace('ScatterPlotWidget', 'ScatterplotChartWidget')
                            .trim();
                        changesLog.push({
                            type: 'info',
                            message: `Widget: ${cloneChild.scriptClass} migrated from ${cloneChild.className}#${
                                cloneChild.id || cloneChild.uniqueCode
                            }`
                        });
                        delete cloneChild.className;
                        // Special case(s) - migration from old >> new may need more properties where model has changed...
                        if (cloneChild.scriptClass === 'EmbeddedWebScriptWidget') cloneChild.libraries = 'jquery';
                    }
                    if (cloneChild.requiredIndicators !== undefined) {
                        cloneChild.indicators =
                            dataVoidWidgets.indexOf(cloneChild.scriptClass) >= 0
                                ? []
                                : migrateIndicatorRefs(cloneChild.requiredIndicators, changesLog);
                        cloneChild.__oldIrefs = [...cloneChild.requiredIndicators]; // DEBUG and back-door for migration?
                        delete cloneChild.requiredIndicators;
                    }
                    //else if (cloneChild.scriptClass.indexOf('Text') >= 0) cloneChild.__oldIrefs = []; // Back-door for migration
                    cloneChild = migrateWidgetSettings(cloneChild, changesLog);
                    clone.childWidgets.push(cloneChild);
                }
            }
            if (clone.requiredIndicators !== undefined) {
                clone.indicators =
                    dataVoidWidgets.indexOf(clone.scriptClass) >= 0
                        ? []
                        : migrateIndicatorRefs(clone.requiredIndicators, changesLog);
                clone.__oldIrefs = [...clone.requiredIndicators]; // DEBUG and back-door for migration?
                delete clone.requiredIndicators;
            }
            //else if (clone.scriptClass.indexOf('Text') >= 0) clone.__oldIrefs = []; // Back-door for migration
            clone = migrateWidgetSettings(clone, changesLog);
            clone.id = clone.id.replace(/[^0-9a-zA-Z]/g, '').toLowerCase(); // Too much legacy of matching against - and Aa
            clonedWidgets.push(clone);
        }
        s.widgets = clonedWidgets; // Flattening out...
        changesLog.push({ type: 'groupEnd', message: '' });
    }
    let globalStyles = '',
        userStyles = '',
        start = -1,
        end = -1;
    if (
        migrated.styles !== undefined &&
        migrated.styles !== null &&
        (start = migrated.styles.indexOf('/* start user css overrides */')) >= 0
    ) {
        end = migrated.styles.indexOf('/* end user css overrides */', start);
        let builtInStyles = migrated.styles.substring(0, start),
            customStyles = migrated.styles.substring(start + '/* start user css overrides */'.length, end);
        globalStyles += builtInStyles;
        userStyles += customStyles;
    } else if (migrated.styles !== undefined && migrated.styles !== null) globalStyles += migrated.styles;
    if (
        migrated.pageLayoutStyles !== undefined &&
        migrated.pageLayoutStyles !== null &&
        (start = migrated.pageLayoutStyles.indexOf('/* start user css overrides */')) >= 0
    ) {
        end = migrated.pageLayoutStyles.indexOf('/* end user css overrides */', start);
        let builtInStyles = migrated.pageLayoutStyles.substring(0, start),
            customStyles = migrated.pageLayoutStyles.substring(start + '/* start user css overrides */'.length, end);
        globalStyles += builtInStyles;
        userStyles += customStyles;
    } else if (migrated.pageLayoutStyles !== undefined && migrated.pageLayoutStyles !== null)
        globalStyles += migrated.pageLayoutStyles;
    // Reset
    delete migrated.styles;
    delete migrated.pageLayoutStyles;
    if (globalStyles !== '') migrated.legacyStyles = globalStyles;
    if (userStyles !== '') migrated.styles = userStyles;
    // Other special cases...
    if (migrated.customSettings !== undefined) {
        migrated.customSettings = parseQueryToObject(migrated.customSettings.replace(/[;]/g, '&'));
        if (!isNullOrUndefined(migrated.customSettings['DataPolicy'])) {
            // Illogical name(s) from RB1.x but still important functionality
            migrated.customSettings['dataCachingPolicy'] = migrated.customSettings['DataPolicy'];
            delete migrated.customSettings['DataPolicy'];
        }
        for (let k of Object.keys(migrated.customSettings)) {
            if (/^[A-Z].*$/.test(k)) {
                migrated.customSettings[`${k.substring(0, 1).toLowerCase()}${k.substring(1)}`] =
                    migrated.customSettings[k];
                delete migrated.customSettings[k];
            }
        }
    }
    return migrated;
};

const migrateWidgetSettings = (settings: any, logMessages: any[] = []): any => {
    // Remove and clean some older options...or some from C# that are no longer relevant
    const discardMap = {
            rules: [
                '<?xml version="1.0" encoding="utf-16"?><ArrayOfDataRule xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns="http://online.instantatlas.com/profile" />',
                '<?xml version="1.0" encoding="utf-16"?><ArrayOfDataRule xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://online.instantatlas.com/profile" />',
                '<?xml version="1.0" encoding="utf-16"?><ArrayOfDataRule xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns="http://online.instantatlas.com/profile" />'
            ],
            yAxisRange: 'Constrained' // Redundant value, may be replaced lower down
        },
        updateMap = {
            valueColumnsForComparatorsHeaderFormat: 'valueColumnHeaderNonPrimary',
            appRelativeTemplateSourceDirectory: '',
            attributes: '',
            antiAliasing: '',
            backColor: '',
            borderColor: '',
            clientIDMode: '',
            controlStyle: '',
            controlStyleCreated: '',
            controls: '',
            enableTheming: '',
            enableViewState: '',
            foreColor: '',
            isPublic: '',
            pos: '',
            serverId: '',
            style: '',
            supportsDisabledAttribute: '',
            tagVersion: '',
            templateSourceDirectory: '',
            title: '',
            validateRequestMode: '',
            viewStateMode: '',
            numberFormatAssociates: '',
            encodeTextAsHtml: '',
            convertLineBreaks: '',
            allowScriptTags: '',
            boxBorderColor: 'borderColor',
            boxBorderWidth: 'borderWidth',
            boxBorderStyle: (bs) => {
                return [['borderStyle', bs !== undefined && bs !== null ? hyphenate(bs) : 'not-set']];
            },
            uniqueCode: 'id',
            numberFormat: (nf) => {
                const cleanNf =
                    nf !== undefined && nf !== null && !/^(\w*)([0#,.]+)(\w*)$/.test(nf)
                        ? nf.replace(/[^0#,.]/g, '')
                        : nf;
                return [['numberFormat', cleanNf]]; // Number formats in RB1 were ugly/corrupt - this forces them to be clean but may impact on other display formats...
            },
            '^(Image)Widget$.text': 'url',
            '^ArcGisOnlineWebMapWidget$.text': 'titleText',
            '^(Bar|Line|Radar|Pie)ChartWidget$.text': 'titleText',
            '^(Bar|Line|Radar|Pie)ChartWidget$.titleFontStyle': (tfs) => {
                return [['titleIsBold', tfs === undefined || tfs === null || tfs.toLowerCase() === 'bold']];
            },
            ySecondaryAxis: '',
            ySecondaryAxisIntervalMode: '',
            ySecondaryAxisLabelFormat: '',
            ySecondaryAxisRange: '',
            ySecondaryAxisReversed: '',
            ySecondaryAxisTitle: '',
            '^(Bar|Line|Pie|Scatterplot|Radar)ChartWidget$.plotMargins': (pm, others) => {
                const auto = pm === undefined || pm === null || pm === '' || pm.toLowerCase() === 'auto',
                    //hz = (others['barOrientation'] !== undefined) && (others['barOrientation'] !== null) && (others['barOrientation'].toLowerCase() === 'horizontal'),
                    tokens = (auto ? '10,10,10,10' : pm).split(/[,\s]/),
                    apm = tokens.map((t) => (t === '40' ? '20px' : '5px'));
                //if (hz) apm[3] = `${Math.max(100, parseInt(apm[3].replace('px', '')))}px`;
                return [['plotMargins', apm.join(' ')]];
            },
            'mode=Lookup': 'mode=relationship',
            'northArrow=WideArrowPlusLabel': 'northArrow=arrow',
            'featureLineStroke=Dash': 'featureLineStroke=dashed',
            'featureLineStroke=Dot': 'featureLineStroke=dotted',
            'comparisonLineStroke=Dash': 'comparisonLineStroke=dashed',
            'comparisonLineStroke=Dot': 'comparisonLineStroke=dotted',
            backgroundColor1: 'backgroundColor',
            backgroundGradientStyle: '',
            widgetBorderStyle: '',
            '^(Bar|Line|Radar)ChartWidget$.toolTip': (ttf) => {
                // RB1 didn't have a tooltip title, so strip that text from the tooltip item label
                return [
                    [
                        'tooltipFormat',
                        (ttf || '').replace('#COLOR', '').replace('#SER', '').replace('#LEGENDTEXT', '').trim()
                    ]
                ];
            },
            '^(Pie)ChartWidget$.toolTip': (ttf) => {
                // RB1 didn't have a tooltip title, but pie charts can have nested features, so don't strip that text from the tooltip item label
                return [['tooltipFormat', (ttf || '').replace('#COLOR', '').trim()]];
            },
            //"^(Bar|Line|Radar|Pie)ChartWidget$.dataPointLabelFormat": "tooltipFormat",
            //"^(Bar|Line|Radar)ChartWidget$.titlePosition": "", // This is harsh, but the "titleBar" option is a better default...
            '^(Bar|Line|Radar|Pie)ChartWidget$.palette': (pv, others) => {
                const paletteIndex =
                        pv !== undefined && pv !== null && pv !== '' ? STANDARD_PALETTES_KEYS.indexOf(pv) : -1,
                    rv = [['paletteIsFixed', true]]; // Moving from RB1.x to 2.0, breaking change in behaviour...
                if (paletteIndex >= 0) rv.push(['palette', STANDARD_PALETTES[paletteIndex].join(' ')]);
                return rv;
            },
            '^(Bar|Line|Radar|Pie)ChartWidget$.seriesBindingStyle': (sbs, others) => {
                const indReally =
                        sbs === 'FeaturesAsSeries' &&
                        others['seriesLabelFormat'] !== undefined &&
                        others['seriesLabelFormat'] === '#INAME',
                    rv = indReally ? 'indicators-as-series' : sbs;
                //console.log(`${others.scriptClass}.serieslabelformat=${sbs}=>${rv} (${others['seriesLabelFormat']})`); // DEBUG
                return [['seriesBindingStyle', hyphenate(rv)]]; // Moving from RB1.x to 2.0, breaking change in behaviour...
            },
            '^(Line|Radar)ChartWidget.labelDataPointsWithValue': (ldp) => {
                return [
                    [
                        'labelDataPointsWithValue',
                        ldp !== undefined &&
                            ldp !== null &&
                            (ldp.toString().toLowerCase() === 'lines' || ldp.toString().toLowerCase() === 'true')
                    ]
                ];
            },
            '^(Bar)ChartWidget$.labelDataPointsWithValue': (ldp, others) => {
                return [
                    [
                        'labelDataPointsWithValue',
                        (ldp === undefined ||
                        ldp === null ||
                        ldp === false ||
                        ldp.toString().toLowerCase() === 'false' ||
                        ldp === 'NoLabels'
                            ? 'none'
                            : ldp
                        ).toLowerCase()
                    ],
                    ['labelDataPointsAnchor', 'end']
                ]; // Moving from RB1.x to 2.0, breaking change in behaviour... default is now 'middle'
            },
            '^(Bar)ChartWidget$.labelDataPointsFormat': (ldp, others) => {
                const validFormat = ldp !== undefined && ldp !== null && ldp !== '' && ldp.indexOf('#PERCENT') >= 0;
                return [
                    [
                        'labelDataPointsFormat',
                        ldp !== undefined && ldp !== null && ldp !== '' && !validFormat ? `#VALY{${ldp}}` : ldp
                    ]
                ]; // Moving from RB1.x to 2.0, breaking change in behaviour... default is now '#VALY'
            },
            '^(Bar)ChartWidget$.useStackedBars': (usb, others) => {
                const isStacked =
                        usb !== undefined && usb !== null && (usb === true || usb.toString().toLowerCase() === 'true'),
                    isVLeg =
                        others['showLegend'] === true &&
                        others['legendPosition'] !== undefined &&
                        (others['legendPosition'].toLowerCase() === 'left' ||
                            others['legendPosition'].toLowerCase() === 'right');
                return [
                    ['useStackedBars', usb],
                    ['legendReverseOrder', isStacked && isVLeg]
                ]; // Moving from RB1.x to 2.0, breaking change in behaviour... ordering of legend items seems different
            },
            '^(Bar|Line)ChartWidget$.showLegend': (sl, others) => {
                const show = sl !== undefined && sl !== null && (sl === true || sl.toString().toLowerCase() === 'true'),
                    isVLeg =
                        others['legendPosition'] !== undefined &&
                        (others['legendPosition'].toLowerCase() === 'left' ||
                            others['legendPosition'].toLowerCase() === 'right');
                return [
                    ['showLegend', show],
                    ['legendWeight', isVLeg ? 50 : 20]
                ];
            },
            '^(Pie)ChartWidget$.showLegend': (sl, others) => {
                const show = sl !== undefined && sl !== null && (sl === true || sl.toString().toLowerCase() === 'true'),
                    isVLeg =
                        others['legendPosition'] !== undefined &&
                        (others['legendPosition'].toLowerCase() === 'left' ||
                            others['legendPosition'].toLowerCase() === 'right');
                return [
                    ['showLegend', show],
                    ['legendWeight', isVLeg ? 50 : 20],
                    ['legendInChart', show && isVLeg ? false : true]
                ];
            },
            'legendItemOrdering=ReversedSeriesOrder': 'legendReverseOrder=true',
            yAxisLabelFormat: (yalf) => {
                return [['yAxisLabelFormat', yalf.replace(/['"]/g, '').replace('\\%', '%')]]; // RB1 had some built in formats from C# that are no longer relevant so escapes are no longer required
            },
            xAxisColor: (xc) => {
                const isReal = xc !== undefined && xc !== null,
                    isLongHex = isReal ? /^#[0-9a-fA-F]{8}$/.test(xc) : false;
                return [['xAxisColor', isReal && isLongHex ? `#${xc.substring(3)}` : xc]];
            },
            yAxisColor: (xc) => {
                const isReal = xc !== undefined && xc !== null,
                    isLongHex = isReal ? /^#[0-9a-fA-F]{8}$/.test(xc) : false;
                return [['yAxisColor', isReal && isLongHex ? `#${xc.substring(3)}` : xc]];
            },
            '^(Bar|Line|Radar|Pie)ChartWidget$.xAxisGridColor': (xgc, others) => {
                const pbg = (others['plotBackgroundColor'] || '#ffffff').toLowerCase(),
                    isReal = xgc !== undefined && xgc !== null,
                    isLongHex = isReal ? /^#[0-9a-fA-F]{8}$/.test(xgc) : false,
                    isTrspt =
                        (isLongHex && xgc.indexOf('00') === 1) || // #AARRGGBB = 00 = transparent
                        (isReal && (xgc.toLowerCase() === 'transparent' || xgc === 'rgba(255,255,255,0.0)')),
                    isColor = isReal && !isTrspt && xgc.toLowerCase() !== pbg;
                return [
                    ['xAxisGridColor', isColor ? (isLongHex ? `#${xgc.substring(3)}` : xgc) : others['xAxisColor']],
                    ['xAxisShowGrid', isColor]
                ]; // Moving from RB1.x to 2.0, breaking change in behaviour... ordering of legend items seems different
            },
            '^(Bar|Line|Radar|Pie)ChartWidget$.yAxisGridColor': (ygc, others) => {
                const pbg = (others['plotBackgroundColor'] || '#ffffff').toLowerCase(),
                    isReal = ygc !== undefined && ygc !== null,
                    isLongHex = isReal ? /^#[0-9a-fA-F]{8}$/.test(ygc) : false,
                    isTrspt =
                        (isLongHex && ygc.indexOf('00') === 1) || // #AARRGGBB = 00 = transparent
                        (isReal && (ygc.toLowerCase() === 'transparent' || ygc === 'rgba(255,255,255,0.0)')),
                    isColor = isReal && !isTrspt && ygc.toLowerCase() !== pbg;
                return [
                    ['yAxisGridColor', isColor ? (isLongHex ? `#${ygc.substring(3)}` : ygc) : others['yAxisColor']],
                    ['yAxisShowGrid', isColor]
                ]; // Moving from RB1.x to 2.0, breaking change in behaviour... ordering of legend items seems different
            },
            '^(Bar|Line|Radar|Pie)ChartWidget$.customPalette': (cpv) => {
                return cpv !== undefined && cpv !== null && cpv !== '' ? [['palette', cpv]] : [];
            },
            '^(Bar|Line|Radar|Pie)ChartWidget$.customComparisonFeaturePalette': 'comparisonsPalette',
            'yAxisRange=Auto': 'yAxisRange=',
            '^(Bar|Line|Radar|Pie)ChartWidget$.yAxisRangeValues': (yaxr, others) => {
                const ylo = (yaxr !== undefined && yaxr !== null ? yaxr : '').toLowerCase().trim(),
                    applyRange = others['yAxisRange'] !== undefined && others['yAxisRange'].toLowerCase() !== 'auto';
                if (ylo === 'auto' || !applyRange) return [['yAxisRange', '']];
                if (/^[0-9.]+$/.test(ylo)) return [['yAxisRange', `0,${ylo}`]];
                else return [['yAxisRange', ylo]];
            },
            '^(Bar|Line|Radar|Pie)ChartWidget$.use3D': '',
            '^(Bar|Line|Radar|Pie)ChartWidget$.use3DRightAngledAxes': '',
            '^(Bar|Line|Radar|Pie)ChartWidget$.use3DInclination': '',
            '^(Bar|Line|Radar|Pie)ChartWidget$.use3DRotation': '',
            '^(Bar|Line|Radar)ChartWidget$.use3DBarDepth': '',
            '^(Bar|Line|Radar)ChartWidget$.use3DBarGap': '',
            '^(Bar|Line|Radar)ChartWidget$.use3DClusteredValues': '',
            '^(Bar|Line|Radar|Pie)ChartWidget$.chartWidthInPixels': '',
            '^(Bar|Line|Radar|Pie)ChartWidget$.chartHeightInPixels': '',
            '^(Bar|Line|Radar|Pie)ChartWidget$.includeNumeratorsAndDenominators': '',
            '^(RuleAwareText|Text|TextBox)Widget$.includeNumeratorsAndDenominators': '',
            '^(Table|TimeSeriesTable)Widget$.text': 'tableTitle',
            '^(Table|TimeSeriesTable)Widget$.description': 'tableSummary', // Nobody used table summary so...
            '^(Table|TimeSeriesTable)Widget$.includeNumeratorsAndDenominators': (ind, others) => {
                const really =
                    ind !== undefined &&
                    ind !== null &&
                    ind.toString().toLowerCase() === 'true' &&
                    others['cellDataFormat'] !== undefined &&
                    others['cellDataFormat'] !== null &&
                    (others['cellDataFormat'].indexOf('#NUM') >= 0 || others['cellDataFormat'].indexOf('#DEN') >= 0);
                return [['includeNumeratorsAndDenominators', really]];
            },
            '^(Bar|Line)ChartWidget$.yAxisColor': (yac, others) => {
                return [
                    [
                        'yAxisLabelFormat',
                        (yac !== undefined &&
                        yac !== null &&
                        (yac.toLowerCase() === 'transparent' ||
                            yac.replace(' ', '') === 'rgba(255,255,255,0)' ||
                            yac.toLowerCase() === '#ffffff')
                            ? ''
                            : others['yAxisLabelFormat']
                        )
                            .replace(/['"]/g, '')
                            .replace('\\%', '%')
                    ],
                    ['yAxisColor', yac]
                ]; // Moving from RB1.x to 2.0, breaking change in behaviour... default is now 'middle'
            },
            '^(Bar)ChartWidget$.axisLabelRotation': (alr, others) => {
                const rotated = alr !== undefined && alr !== null && alr.toLowerCase() !== 'none',
                    iaa =
                        others['includeAllAreas'] !== undefined &&
                        others['includeAllAreas'] !== null &&
                        others['includeAllAreas'].toString().toLowerCase() === 'true',
                    hz =
                        others['barOrientation'] !== undefined &&
                        others['barOrientation'] !== null &&
                        others['barOrientation'].toLowerCase() === 'horizontal',
                    previous =
                        others['axisLabelAllowWordWrap'] !== undefined &&
                        others['axisLabelAllowWordWrap'] !== null &&
                        others['axisLabelAllowWordWrap'].toString().toLowerCase() === 'true';
                return [
                    ['axisLabelAllowWordWrap', !iaa && !hz && (!rotated || previous)],
                    ['xAxisMinLabelRotation', !previous ? 0 : 0],
                    ['xAxisMaxLabelRotation', !previous || iaa ? 90 : 0]
                ]; // Moving from RB1.x to 2.0, breaking change in behaviour... default is now 'middle'
            },
            '^(Line)ChartWidget$.axisLabelRotation': (alr, others) => {
                const rotated = alr !== undefined && alr !== null && alr.toLowerCase() !== 'none',
                    previous =
                        others['axisLabelAllowWordWrap'] !== undefined &&
                        others['axisLabelAllowWordWrap'] !== null &&
                        others['axisLabelAllowWordWrap'].toString().toLowerCase() === 'true';
                return [
                    ['axisLabelAllowWordWrap', !rotated],
                    ['xAxisMinLabelRotation', !previous ? 0 : 0],
                    ['xAxisMaxLabelRotation', !previous ? 90 : 45]
                ]; // Moving from RB1.x to 2.0, breaking change in behaviour... default is now 'middle'
            },
            doughnutHoleSize: (sz) => {
                const hs = typeof sz === 'number' ? sz : parseFloat(sz),
                    flip = 100 - (!isNaN(hs) ? hs : 100);
                return [['doughnutHoleSize', flip]];
            },
            pieLabels: (pl) => {
                const npl = (pl !== undefined ? pl : 'none').toLowerCase().replace('disabled', 'none');
                return [['showSegmentLabels', npl]];
            },
            segmentLabelFontSize: 'axisFontSize', // Pie charts, but move to a common name
            segmentLabelFontFamily: 'axisFontFamily', // Pie charts, but move to a common name
            segmentLabelFormat: 'seriesLabelFormat',
            segmentXLabelFormat: 'seriesLabelFormat',
            segmentLabel: 'segmentLabelFormat',
            segmentStartOffset: (sso) => {
                const offDegrees =
                    sso !== undefined && sso !== null
                        ? Math.round(360.0 * ((typeof sso === 'number' ? sso : parseFloat(sso.toString())) / 100.0))
                        : 0;
                return [
                    ['segmentStartOffset', offDegrees],
                    ['thresholdPercent', 0] // Sneaking this is for migrated reports
                ];
            },
            pieEdges: '',
            'labelStyle=ShortName': 'labelStyle=short-name',
            'labelStyle=FullName': 'labelStyle=full-name',
            boxFillColors: 'palette',
            iconColors: 'palette',
            boxScaleBetween: 'iconScaleBetween',
            allowMouseNavigation: '',
            northArrowLabel: '',
            thematicLegend: (tl, others) => {
                return [
                    ['legend', (tl && others['thematicApplyToLayer']) || others['showLegend']],
                    ['legendSize', 'small']
                ];
            },
            thematicLegendAnchor: '', // Obsolete
            thematicColors: (tc = 'Red') => {
                let ts = tc;
                switch (tc.toLowerCase()) {
                    case 'red':
                    case 'orange':
                    case 'pink':
                    case 'green':
                        ts = `${tc} 2`;
                        break;
                    case 'green yellow':
                        ts = `Green 2`;
                        break;
                    case 'red yellow':
                        ts = `Red 2`;
                        break;
                    case 'blue yellow':
                        ts = `Blue 3`;
                        break;
                    case 'blue':
                        ts = `${tc} 3`;
                        break;
                    default:
                        break;
                }
                return [['thematicScheme', ts]];
            },
            activeLayerIndex: (ali) => {
                return [
                    [
                        'activeLayerIndex',
                        ali !== undefined && ali !== null && ali !== '0' && !isNaN(parseInt(ali.toString()))
                            ? parseInt(ali.toString())
                            : -1
                    ]
                ]; // RB1 seems to have over-used this so ditch it most of the time...
            },
            visibleLayers: (viz, others) => {
                const rv = [['visibleLayers', viz]],
                    list = viz !== undefined && viz !== null ? viz.split(',') : [];
                // One visible layer? Then it must be the active layer too, surely???
                if (list.length === 1 && !isNaN(parseInt(list[0]))) rv.push(['activeLayerIndex', parseInt(list[0])]);
                return rv;
            },
            comparisonFeatureIds: (cfids) => {
                const fids = (cfids !== undefined ? cfids : '').split(',').map((fid) => {
                    return fid.indexOf('|') > 0 ? fid.split('|')[1] : fid;
                });
                return [['comparisonFeatureIds', Array.from(new Set(fids)).join(',')]]; // RB1 --> RB2.0 breaking change, ditching the sensitivity about feature/dataset indices
            },
            '^(Bar|Line|Radar)ChartWidget$.dataPointLabelFormat': '',
            dataPointLabelFormat: '',
            '^RelatedRecordsTableWidget$.layout': 'orientation',
            '^(RuleAwareText)Widget.text': (txt) => {
                return [['text', txt.replace('href="#page', 'href="?#page').replace('href="#top"', 'href="?#page1"')]]; // New accessible link format
            }
        },
        htmlTextSet = ['text', 'titleText', 'boxTitleText', 'boxLabelFormat', 'iconLabelFormat'],
        parseable = ['rules'],
        updates = {
            ...settings
        };
    for (let key in discardMap) {
        const target = discardMap[key];
        if (settings[key] !== undefined) {
            if (Array.isArray(target)) {
                for (let v of target) {
                    if (settings[key].toString().trim().replace(/\n/g, '') === v.trim().replace(/\n/g, '')) {
                        delete updates[key];
                        logMessages.push({ type: 'debug', message: `${key} deleted from settings: "${v}"` }); // DEBUG
                        break;
                    }
                }
                //if (updates[key]) console.log(`${key} is still in settings as: "${updates[key]}"`); // DEBUG
            } else if (settings[key].toString().trim().replace(/\n/g, '') === target.trim().replace(/\n/g, '')) {
                //console.log(JSON.stringify(settings, null, 2)); // DEBUG
                delete updates[key];
                logMessages.push({ type: 'debug', message: `${key}=${target.trim()} deleted from settings` }); // DEBUG
            }
        }
    }
    const keySet = Object.keys(updateMap);
    for (let key of keySet) {
        const target = updateMap[key];
        if (key.indexOf('.') < 0 && typeof target === 'function') {
            if (settings[key] !== undefined) {
                const amendedSettingsSet = target(settings[key], settings, key), // Migrate or ditch...
                    incoming = `${key}=${updates[key]}`,
                    outgoing = `${amendedSettingsSet.map(([k, v]) => `${k}=${v}`).join('; ')}`;
                if (incoming !== outgoing) {
                    logMessages.push({
                        type: 'debug',
                        message: `${incoming} updated to ${outgoing}`
                    }); // DEBUG
                }
                delete updates[key];
                for (let [tkey, tvalue] of amendedSettingsSet) {
                    if (tkey !== undefined && tvalue !== undefined) updates[tkey] = tvalue;
                }
            }
        } else if (key.indexOf('=') > 0 && target.indexOf('=') > 0) {
            const [srcKey, srcValue] = key.split('=');
            //if (settings[srcKey] !== undefined) console.log(`${srcKey}=${srcValue} ? ${settings[srcKey]} >?> ${target}`); // DEBUG
            if (settings[srcKey] !== undefined && settings[srcKey] === srcValue) {
                logMessages.push({ type: 'debug', message: `${srcKey}=${updates[srcKey]} updated to ${target}` }); // DEBUG
                delete updates[srcKey];
                // Allow multiple mappings from src->target
                for (let tv of target.split(';')) {
                    const [tgtKey, tgtValue] = tv.split('=');
                    updates[tgtKey] = tgtValue; // Migrate...
                }
            }
        } else if (key.indexOf('.') > 0) {
            const pair = key.split('.'),
                classRegex = new RegExp(pair[0], 'i'),
                subKey = pair[1];
            if (classRegex.test(settings.scriptClass)) {
                if (settings[subKey] !== undefined) {
                    if (typeof target === 'function') {
                        const amendedSettingsSet = target(settings[subKey], settings, subKey); // Migrate or ditch...
                        logMessages.push({
                            type: 'debug',
                            message: `${settings.scriptClass}.${subKey}=${
                                updates[subKey]
                            } updated to ${amendedSettingsSet.map(([k, v]) => `${k}=${v}`).join('; ')}`
                        }); // DEBUG
                        delete updates[subKey];
                        for (let [tkey, tvalue] of amendedSettingsSet) {
                            if (tkey !== undefined && tvalue !== undefined) updates[tkey] = tvalue;
                        }
                    } else {
                        if (settings[subKey] !== '') {
                            updates[target] = updates[subKey]; // Migrate or ditch...
                            if (subKey !== target) {
                                logMessages.push({
                                    type: 'debug',
                                    message:
                                        target === ''
                                            ? `${settings.scriptClass}.${subKey}=${updates[subKey]} removed (obsolete)`
                                            : `${settings.scriptClass}.${subKey}=${updates[subKey]} updated to ${target}=${updates[target]}`
                                }); // DEBUG
                            }
                        }
                        delete updates[subKey];
                    }
                }
            }
        } else {
            if (settings[key] !== undefined) {
                if (settings[key] !== '') {
                    updates[target] = settings[key]; // Migrate or ditch...
                    if (key !== target) {
                        logMessages.push({
                            type: 'debug',
                            message:
                                target === ''
                                    ? `${settings.scriptClass}.${key}=${updates[key]} removed (obsolete)`
                                    : `${settings.scriptClass}.${key}=${updates[key]} updated to ${target}=${updates[target]}`
                        }); // DEBUG
                    }
                }
                delete updates[key];
            }
        }
    }
    // HTML escapes - .NET wouldn't allow them to post back to server so used subs/escapes, now need to reverse this...
    // Older designs may have used older FontAwesome as well, should we do anything about that??
    for (let key of htmlTextSet) {
        if (settings[key] !== undefined) {
            updates[key] = settings[key].replace(/^\[|([^\\])\[/g, '$1<').replace(/([^\\])\]/g, '$1>');
            if (updates[key].indexOf('<') !== 0 && (updates[key].indexOf('</') > 0 || updates[key].indexOf('/>') > 0))
                updates[key] = `<span>${updates[key]}</span>`;
        }
    }
    // parseable - .NET wouldn't allow implement this very well (JSON), now need to reverse this...
    for (let key of parseable) {
        // Only let them through if they have not already been discarded...
        if (settings[key] !== undefined && updates[key] !== undefined) {
            try {
                updates[key] = JSON.parse(settings[key]);
                // Special case - rules
                if (key === 'rules') {
                    let migrated = [...updates[key]];
                    migrated = migrated.map((r) => {
                        /* ComparisonCulture: "", DataIdentifier: "#IVALUE{1}", OutputValue: "...", Test: 1, TestValue: "E07000030", TestValueMultiplier: 1 */
                        return {
                            test: testEnum[r.Test],
                            key: r.DataIdentifier,
                            value: r.TestValue,
                            valueMultiplier: r.TestValueMultiplier,
                            text: r.OutputValue.replace(/^\[|([^\\])\[/g, '$1<').replace(/([^\\])\]/g, '$1>'),
                            locale: r.ComparisonCulture
                        };
                    });
                    updates[key] = migrated;
                }
            } catch (jsonEx) {
                logMessages.push({
                    type: 'warning',
                    message: `⚠️ Cannot migrate widget property '${key}=${settings[key]}', parse as JSON failed: ${jsonEx}`
                });
            }
        }
    }
    // CSS - special case to simplify margins...
    if (settings.cssStyle !== undefined && settings.cssStyle !== null) {
        const cssArgs = parseCssStyles(settings.cssStyle);
        if (cssArgs.margin !== undefined && cssArgs.margin !== null) {
            updates.margin = cssArgs.margin.toString();
            cssArgs.margin = undefined;
            updates.cssStyle = ''; // TODO - deal with anything NOT margin
        }
        const marginDefs = ['0px', '0px', '0px', '0px'];
        if (cssArgs.marginTop !== undefined && cssArgs.marginTop !== null) marginDefs[0] = cssArgs.marginTop;
        if (cssArgs.marginRight !== undefined && cssArgs.marginRight !== null) marginDefs[1] = cssArgs.marginRight;
        if (cssArgs.marginBottom !== undefined && cssArgs.marginBottom !== null) marginDefs[2] = cssArgs.marginBottom;
        if (cssArgs.marginLeft !== undefined && cssArgs.marginLeft !== null) marginDefs[3] = cssArgs.marginLeft;
        if (marginDefs.join(' ') !== '0px 0px 0px 0px') {
            updates.margin = marginDefs.join(' ');
            updates.cssStyle = ''; // TODO - deal with anything NOT margin
        }
    }
    // Special case for bar charts - legacy of restrictions in previous versions...
    if (
        updates.showComparisonsAsLines !== undefined &&
        updates.showComparisonsAsLines === true &&
        updates.barOrientation !== undefined &&
        updates.barOrientation.toLowerCase() === 'horizontal'
    ) {
        updates.showComparisonsAsLines = false;
    }
    // Special case for script widgets - if older then add jQuery/$ by default
    if (
        updates.scriptClass.indexOf('ScriptWidget') >= 0 &&
        (updates.libraries === undefined || updates.libraries.indexOf('jquery') < 0)
    ) {
        updates.libraries = 'jquery';
    }
    return updates;
};

const testEnum = {
    1: 'equals',
    2: 'doesNotEqual',
    4: 'isGreaterThan',
    8: 'isLessThan',
    16: 'contains',
    32: 'doesNotContain',
    64: 'numericEquals',
    5: 'isGreaterThanOrEqualTo',
    68: 'isGreaterThanOrEqualTo', // RB1 oddness - IsGreaterThan | NumericEquals = 4 | 64 = 68
    9: 'isLessThanOrEqualTo',
    72: 'isLessThanOrEqualTo', // RB1 oddness - IsLessThan | NumericEquals = 8 | 64 = 72
    128: 'numericDoesNotEqual',
    256: 'nullComparator',
    512: 'isContainedBy',
    1024: 'isNotContainedBy',
    2048: 'isNull',
    4096: 'isNotNull'
};

export const migrateStyles = (legacyReportStyles = ''): string => {
    const replacementStyles = legacyReportStyles
        .replace(/\.iaReport/g, '.esriuk-app .ia-report')
        .replace(/\.profileReport/g, '.esriuk-app .ia-report')
        .replace(/\.iaProfileSectionContentWrapper/g, '.ia-report-section-wrapper')
        .replace(/\.iaProfileSection/g, '.ia-report-section')
        .replace(/\.iaProfileWidget/g, '.ia-report-widget')
        .replace(/\.ia-arcgis-online-/g, '.ia-')
        .replace(/\.ia-arc-gis-online-/g, '.ia-')
        .replace(/\.iao-page-/g, '.ia-page-')
        .replace(/\.iao-/g, '.ia-')
        .replace(/\.ia-related-items-/g, '.ia-related-records-')
        .replace('@import url(https://fonts.googleapis.com/css?family=FontAwesome);', '')
        .replace('@import url(//fonts.googleapis.com/css?family=FontAwesome);', '') // Legacy of an ancient bug...
        .replace(
            '.esriuk-app .ia-report div.ia-report-widget:not(.Html5MapWidget):not(.DashboardEmbedWidget):not(.ArcGisOnlineWebMapWidget) { height: auto !important; } }',
            ''
        ) // Legacy of changes in the way we handle size/style
        .replace(/\.fa-/g, '.disallowed-fa-'); // Don't let them override core componentry
    return replacementStyles;
};

export const migrateIndicatorRefs = (legacyIndicatorRefs: any[] = [], changesLog: any[] = []): any[] => {
    const replacementIndicators: any[] = [];
    let ni, ei;
    for (let i of legacyIndicatorRefs) {
        // Modern, possibly data catalog bonded...
        if (i.text !== undefined && i.text !== null) {
            const storeInd = i.text.split(':')[0] === 'datastore';
            if (storeInd) {
                ni = {
                    id: i.text.split(':')[1],
                    type: 'catalog'
                };
            } else {
                ni = {
                    id: i.text,
                    type: 'direct',
                    offset: i.indInstanceId
                };
                // Special cases for CSV etc.
                if (i.indicatorId !== undefined) {
                    const iidTxt = '_' + i.indicatorId.toString(),
                        idx = i.text.lastIndexOf(iidTxt);
                    if (idx === i.text.length - iidTxt.length) {
                        ni.id = i.text
                            .substring(0, idx)
                            .replace(/[^0-9a-zA-Z]/g, '') // To match data-catalog-js-api
                            .replace(/^data(.*)$/, 'data_$1'); // Legacy of old code
                        ni.id = `I${ni.id}G`; // Matching auto-generation of IDs
                        ni.forceByIndex = true;
                    }
                    ni.offset = ni.offset + 1; // Legacy of old ordering code
                }
            }
            ni.index = i.pos;
            if (i.indDate !== undefined && i.indDate !== null) {
                ni.latest = 0;
                ni.dates = [i.indDate];
            } else ni.latest = i.maxTimePeriods;
            const nid = ni.id;
            // Only add multi-dates to catalog-based indicators because we have simplified others in UI...
            if (
                storeInd &&
                ni.dates !== undefined &&
                (ei = replacementIndicators.find((ri) => ri.id === nid && ri.dates !== undefined)) !== undefined
            ) {
                ei.dates = ei.dates.concat(ni.dates);
                changesLog.push({
                    type: 'debug',
                    message: `Data: ${ni.type} indicator reference ${JSON.stringify(i)} added to: ${JSON.stringify(ei)}`
                }); // DEBUG
            } else {
                replacementIndicators.push(ni);
                changesLog.push({
                    type: 'debug',
                    message: `Data: ${ni.type} indicator reference ${JSON.stringify(i)} updated: ${JSON.stringify(ni)}`
                }); // DEBUG
            }
        }
        // Older - typically bonded to CSV or similar
        else if (i.indicatorId !== undefined) {
            // {"indicatorId":1156,"maxTimePeriods":0,"indInstanceId":1066,"indDate":"Yes","pos":0}
            ni = {
                id: `I${i.indicatorId}G`,
                index: i.indicatorId,
                latest: i.maxTimePeriods,
                offset: i.indInstanceId,
                type: 'direct',
                date: i.indDate
            };
            ni.forceByIndex = true; // ?? maybe?
            replacementIndicators.push(ni);
            changesLog.push({
                type: 'debug',
                message: `Data: direct(?) indicator reference ${JSON.stringify(i)} updated: ${JSON.stringify(ni)}`
            }); // DEBUG
        } else
            changesLog.push({ type: 'warning', message: `Data: indicator reference rejected: ${JSON.stringify(i)}` }); // DEBUG
    }
    return replacementIndicators;
};

export const injectStyles = (
    reportDesign: any = {},
    match,
    matchClip = 'edit/',
    rootElement = 'body',
    checkForExisting = false,
    skipResponsiveStyles = false,
    useGoogleFontsCdn = false,
    builtInFonts?: string[]
): void => {
    const styleRootElement: HTMLElement | null = document.querySelector(rootElement),
        hostingPagePath = !isNullOrUndefined(match) ? match.url : window.location.pathname,
        basePath = `${hostingPagePath.substring(0, hostingPagePath.indexOf(matchClip)).replace(/^\/(.*)\/$/, '$1')}`;
    // New design - need to strip some CSS back and then recreate it...
    let styleTag: HTMLElement | HTMLElement[] | null | undefined,
        tagExists: { [key: string]: HTMLElement[] | null } = {
            iaInjectedStyleCustom: null,
            iaInjectedStyleGlobalLegacy: null,
            'link.ia-injected-style-path': null,
            'style.ia-injected-font-face': null,
            'style.ia-injected-mobile-view': null
        };
    for (let key in tagExists) {
        styleTag = document.getElementById(key);
        if (styleTag !== undefined && styleTag !== null) tagExists[key] = [styleTag];
        else {
            styleTag = Array.from(document.head.querySelectorAll(key));
            if (!isNullOrUndefined(styleTag) && styleTag.length > 0) tagExists[key] = styleTag;
        }
    }
    if (!checkForExisting) {
        for (let key in tagExists) {
            styleTag = tagExists[key];
            if (styleTag !== undefined && styleTag !== null) {
                for (let i = styleTag.length - 1; i >= 0; i--) styleTag[i].remove();
            }
        }
        // Reset...
        tagExists = {
            iaInjectedStyleCustom: null,
            iaInjectedStyleGlobalLegacy: null,
            'link.ia-injected-style-path': null,
            'style.ia-injected-font-face': null,
            'style.ia-injected-mobile-view': null
        };
    }
    if (!isNullOrUndefined(reportDesign.cssPaths)) {
        const schemesRegex = /~\/styles\/schemes\//gi,
            getSchemeById = (sid: string): any => {
                return standardColorSchemes.find((c) => c.id === sid);
            };
        for (let cp of reportDesign.cssPaths) {
            // Legacy lurker from ancient bug...
            if (cp !== 'https://fonts.googleapis.com/css?family=FontAwesome') {
                const tagContent = cp
                        .replace(schemesRegex, `${basePath}/static/css/legacy/`)
                        .replace('.min.css', '.css')
                        .replace(/^\/\//, '/'),
                    schemeKey = cp.replace(schemesRegex, '').split('.')[0].toLowerCase(); // Pull out the 'olive' from ~/styles/schemes/olive.min.css
                // If we have a new definition of the color scheme, use that...
                if (
                    !isNullOrUndefined(getSchemeById(schemeKey)) &&
                    styleRootElement !== undefined &&
                    styleRootElement !== null
                ) {
                    const {
                        pageBackground = '#fff',
                        standard = '#111',
                        heading = '#111',
                        light = '#777',
                        border = '#999',
                        boxBackground = '#ccc',
                        boxForeground = '#111',
                        boxContentBackground = '#ffffff'
                    } = getSchemeById(schemeKey);
                    styleRootElement.style.setProperty('--rb-page-background-color', pageBackground);
                    styleRootElement.style.setProperty('--rb-standard-color', standard);
                    styleRootElement.style.setProperty('--rb-light-color', light);
                    styleRootElement.style.setProperty('--rb-heading-color', heading);
                    styleRootElement.style.setProperty('--rb-border-color', border);
                    styleRootElement.style.setProperty('--rb-box-background-color', boxBackground);
                    styleRootElement.style.setProperty('--rb-box-foreground-color', boxForeground);
                    styleRootElement.style.setProperty('--rb-box-content-background-color', boxContentBackground);
                } else {
                    styleTag = tagExists['link.ia-injected-style-path'];
                    if (styleTag !== undefined && styleTag !== null)
                        styleTag = Array.from(styleTag).find((st) => st.getAttribute('href') === tagContent);
                    if (isNullOrUndefined(styleTag)) {
                        styleTag = document.createElement('link');
                        styleTag.setAttribute('rel', 'stylesheet');
                        styleTag.setAttribute('href', tagContent);
                        styleTag.setAttribute('class', 'ia-injected-style-path');
                        //styleTag.setAttribute('data-report-id', report.id);
                        //styleTag.appendChild(document.createTextNode(migrateStyles(reportDesign.styles)));
                        document.head.appendChild(styleTag);
                        if (cp.indexOf('custom.css') > 0 && cp.split('?').length > 1)
                            styleTag.setAttribute('href', tagContent.split('?')[0]);
                    }
                }
                // Custom? Deal with this differently now... CSS variables in Custom.css, but this is still legacy - see below for different approach...
                if (
                    cp.indexOf('custom.css') > 0 &&
                    cp.split('?').length > 1 &&
                    styleRootElement !== undefined &&
                    styleRootElement !== null
                ) {
                    const qs = parseQueryToObject(cp.split('?')[1]);
                    if (qs['base'] !== undefined && qs['base'] !== null)
                        styleRootElement.style.setProperty('--rb-standard-color', qs['base'].toString());
                    if (qs['border'] !== undefined && qs['border'] !== null)
                        styleRootElement.style.setProperty('--rb-border-color', qs['border'].toString());
                    if (qs['heading'] !== undefined && qs['heading'] !== null)
                        styleRootElement.style.setProperty('--rb-heading-color', qs['heading'].toString());
                    if (qs['background'] !== undefined && qs['background'] !== null)
                        styleRootElement.style.setProperty('--rb-box-background-color', qs['background'].toString());
                    if (qs['foreground'] !== undefined && qs['foreground'] !== null)
                        styleRootElement.style.setProperty('--rb-box-foreground-color', qs['foreground'].toString());
                }
            }
        }
    }
    if (!isNullOrUndefined(reportDesign.colors) && styleRootElement !== undefined && styleRootElement !== null) {
        const {
            pageBackground = '#fff',
            standard = '#111',
            heading = '#111',
            light = '#777',
            border = '#999',
            boxBackground = '#ccc',
            boxForeground = '#111',
            boxContentBackground = '#ffffff'
        } = reportDesign.colors;
        styleRootElement.style.setProperty('--rb-page-background-color', pageBackground);
        styleRootElement.style.setProperty('--rb-standard-color', standard);
        styleRootElement.style.setProperty('--rb-light-color', light);
        styleRootElement.style.setProperty('--rb-heading-color', heading);
        styleRootElement.style.setProperty('--rb-border-color', border);
        styleRootElement.style.setProperty('--rb-box-background-color', boxBackground);
        styleRootElement.style.setProperty('--rb-box-foreground-color', boxForeground);
        styleRootElement.style.setProperty('--rb-box-content-background-color', boxContentBackground);
    }
    if (!isNullOrUndefined(reportDesign.pageStyles) && !isNullOrUndefined(reportDesign.pageStyles.size)) {
        const { size = {} } = reportDesign.pageStyles,
            { width = '1000px' } = size,
            pixelHalfWidth = Math.ceil(Math.ceil(convertLengthToPixels(width, false, true)) * 0.5),
            navCss =
                `.esriuk-app .ia-report-holder.paged.pages-paged .page-turner.forward, .esriuk-app .ia-report-holder.paged.pages-scroll-horizontal .page-turner.forward { transform: translate(${pixelHalfWidth.toFixed(
                    0
                )}px, 0) translate(-5%, -50%);} ` +
                `.esriuk-app .ia-report-holder.paged.pages-paged .page-turner.backward, .esriuk-app .ia-report-holder.paged.pages-scroll-horizontal .page-turner.backward { transform: translate(-${pixelHalfWidth.toFixed(
                    0
                )}px, 0) translate(-105%, -50%);} `;
        // Insert some navigation-specific stuff. On some pages withis will be ignored...
        if (tagExists['style.ia-injected-navigation-view'])
            tagExists['style.ia-injected-navigation-view'][0].innerText = navCss;
        else {
            styleTag = document.createElement('style');
            styleTag.setAttribute('class', 'ia-injected-style-path ia-navigation-switch');
            //styleTag.setAttribute('data-report-id', report.id);
            styleTag.appendChild(document.createTextNode(navCss));
            document.head.appendChild(styleTag);
        }
    }
    if (
        !isNullOrUndefined(reportDesign.pageStyles) &&
        !isNullOrUndefined(reportDesign.pageStyles.responsive) &&
        !isNullOrUndefined(reportDesign.pageStyles.responsive.enabled) &&
        reportDesign.pageStyles.responsive.enabled !== 'none' &&
        !skipResponsiveStyles
    ) {
        const { size = {}, responsive } = reportDesign.pageStyles,
            { width: fullWidth } = size,
            { width, height, orientation, enabled } = responsive,
            mq: string[] = [];
        if (!isNullOrUndefined(width) && width !== '') mq.push(`(max-width: ${lengthWithUnit(width)})`);
        if (!isNullOrUndefined(height) && height !== '') mq.push(`(max-height: ${lengthWithUnit(height)})`);
        if (!isNullOrUndefined(orientation) && orientation !== '') mq.push(`(orientation: ${orientation})`);
        if (mq.length > 0) {
            const responsiveCss =
                (enabled === 'both' || enabled === 'full'
                    ? getPageSizeConstraints(`(max-width: ${lengthWithUnit(fullWidth)})`)
                    : '') + (enabled === 'both' || enabled === 'mobile' ? getMobileConstraints(mq.join(' and ')) : '');
            if (tagExists['style.ia-injected-mobile-view'])
                tagExists['style.ia-injected-mobile-view'][0].innerText = responsiveCss;
            else {
                styleTag = document.createElement('style');
                styleTag.setAttribute('class', 'ia-injected-style-path ia-mobile-switch');
                //styleTag.setAttribute('data-report-id', report.id);
                styleTag.appendChild(document.createTextNode(responsiveCss));
                document.head.appendChild(styleTag);
            }
        }
    }
    if (!isNullOrUndefined(reportDesign.font)) {
        const { family = '', size = '16px', extras = [], temporary = false } = reportDesign.font,
            cleanFamilies = family.split(',').map((f) => {
                const [fi] = f.split(':');
                return (fi.indexOf(' ') > 0 ? `'${fi}'` : fi).trim();
            }),
            extraFamilies = findFontRefs(reportDesign, extras), // Other fonts used elsewhere (headers for example) - double check;
            fontList = Array.from(
                new Set<string>(
                    family
                        .split(',')
                        .map((f) => f.trim())
                        .concat(extraFamilies)
                )
            );
        // Standard font for report
        const fontsAvailableLocally = builtInFonts !== undefined && builtInFonts.length > 0;
        for (let ff of fontList.filter((f) => nonGoogleFonts.indexOf(f.toLowerCase()) < 0)) {
            const fontName = ff.replace(/[^0-9a-zA-Z\-\s]/g, '').trim(),
                [fontFace, fontWeight = '400'] = ff.split(':'),
                fontUrl = `https://fonts.googleapis.com/css?family=${encodeURIComponent(fontName)}&display=swap`;
            // Use Google fonts directly? GDPR issues - need to be solved elsewhere - see https://www.cookieyes.com/documentation/features/google-fonts-and-gdpr/
            if (
                useGoogleFontsCdn ||
                ff.indexOf('*') === ff.length - 1 ||
                (fontsAvailableLocally && builtInFonts.indexOf(ff) < 0)
            ) {
                styleTag = tagExists['link.ia-injected-style-path'];
                if (styleTag !== undefined && styleTag !== null) {
                    const temps = Array.from(styleTag).filter((st: HTMLElement, i: number) => {
                        return st !== null && (st.getAttribute('class') || '').indexOf('ia-preview-only') > 0;
                    });
                    for (let i = temps.length - 1; i >= 0; i--) temps[i].remove();
                }
                if (styleTag !== undefined && styleTag !== null)
                    styleTag = Array.from(styleTag).find((st) => st.getAttribute('data-font-name') === fontName);
                if (isNullOrUndefined(styleTag) || isNullOrUndefined(styleTag?.parentElement)) {
                    styleTag = document.createElement('link');
                    styleTag.setAttribute(
                        'class',
                        `ia-injected-style-path ia-google-font ${temporary ? 'ia-preview-only' : ''}`
                    );
                    styleTag.setAttribute('href', fontUrl);
                    styleTag.setAttribute('data-font-name', fontName);
                    styleTag.setAttribute('rel', 'stylesheet');
                    document.head.appendChild(styleTag);
                }
            }
            // Otherwise use Google fonts served directly from this service
            else {
                styleTag = tagExists['style.ia-injected-font-face'];
                if (styleTag !== undefined && styleTag !== null) {
                    const temps = Array.from(styleTag).filter((st: HTMLElement, i: number) => {
                        return st !== null && (st.getAttribute('class') || '').indexOf('ia-preview-only') > 0;
                    });
                    for (let i = temps.length - 1; i >= 0; i--) temps[i].remove();
                }
                if (styleTag !== undefined && styleTag !== null)
                    styleTag = Array.from(styleTag).find((st) => st.getAttribute('data-font-name') === fontName);
                if (isNullOrUndefined(styleTag) || isNullOrUndefined(styleTag?.parentElement)) {
                    styleTag = document.createElement('style');
                    styleTag.setAttribute(
                        'class',
                        `ia-injected-font-face ia-google-font ${temporary ? 'ia-preview-only' : ''}`
                    );
                    styleTag.setAttribute('data-font-name', fontName);
                    const faceDef = `@font-face {
                        font-family: '${fontFace}';
                        src: url('${basePath}/static/webfonts/fontsource/${fontFace
                        .toLowerCase()
                        .replace(/[^0-9a-zA-Z-]/g, '-')}-latin-${fontWeight}-normal.woff2') format('woff2'),
                            url('${basePath}/static/webfonts/fontsource/${fontFace
                        .toLowerCase()
                        .replace(/[^0-9a-zA-Z-]/g, '-')}-latin-${fontWeight}-normal.woff') format('woff');
                        font-weight: normal;
                        font-style: normal;
                        font-display: swap;
                        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                    }
                    @font-face {
                        font-family: '${fontFace}';
                        src: url('${basePath}/static/webfonts/fontsource/${fontFace
                        .toLowerCase()
                        .replace(/[^0-9a-zA-Z-]/g, '-')}-latin-ext-${fontWeight}-normal.woff2') format('woff2'),
                            url('${basePath}/static/webfonts/fontsource/${fontFace
                        .toLowerCase()
                        .replace(/[^0-9a-zA-Z-]/g, '-')}-latin-ext-${fontWeight}-normal.woff') format('woff');
                        font-weight: normal;
                        font-style: normal;
                        font-display: swap;
                        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
                    }`;
                    styleTag.appendChild(document.createTextNode(faceDef));
                    document.head.appendChild(styleTag);
                }
            }
        }
        styleTag = tagExists['style.ia-injected-style-path'];
        // if (styleTag !== undefined && styleTag !== null) {
        //     const temps = Array.from(styleTag).filter((st: HTMLElement) => {
        //         return (st.getAttribute('class') || '').indexOf('ia-preview-only') > 0;
        //     });
        //     for (let i = temps.length - 1; i >= 0; i--) temps[i].remove();
        // }
        const localFontTag: HTMLElement | undefined =
                styleTag !== undefined && styleTag !== null
                    ? Array.from(styleTag).find((st: HTMLElement) => {
                          return (st.getAttribute('class') || '').indexOf('ia-font-ref') > 0;
                      })
                    : undefined,
            tagContent = `.esriuk-app .ia-report > section, .esriuk-app .ia-report .ia-report-section { font-family: ${family.replace(
                /[*]/g,
                ''
            )}; font-size: ${size}; }`;
        if (localFontTag !== undefined && localFontTag !== null) {
            styleTag = document.createElement('style');
            styleTag.setAttribute('class', `ia-injected-style-path ia-font-ref ${temporary ? 'ia-preview-only' : ''}`);
            //styleTag.setAttribute('data-report-id', report.id);
            styleTag.appendChild(document.createTextNode(tagContent));
            document.head.appendChild(styleTag);
        } else if (localFontTag !== undefined && (localFontTag as HTMLElement).innerText !== tagContent)
            (localFontTag as HTMLElement).innerText = tagContent;
        //document.head.appendChild(styleTag);
        if (styleRootElement !== undefined && styleRootElement !== null) {
            styleRootElement.style.setProperty('--rb-standard-font', cleanFamilies.join(','));
            styleRootElement.style.setProperty('--rb-standard-font-size', size);
        }
    }
    if (!isNullOrUndefined(reportDesign.legacyStyles)) {
        styleTag = tagExists['iaInjectedStyleGlobalLegacy'];
        const tagContent = migrateStyles(reportDesign.legacyStyles);
        if (styleTag === undefined || styleTag === null || styleTag.length < 1) {
            styleTag = document.createElement('style');
            styleTag.setAttribute('id', 'iaInjectedStyleGlobalLegacy');
            //styleTag.setAttribute('data-report-id', report.id);
            styleTag.appendChild(document.createTextNode(tagContent));
            document.head.appendChild(styleTag);
        } else if (styleTag[0].innerText !== tagContent) styleTag[0].innerText = tagContent;
    } else if (tagExists['iaInjectedStyleGlobalLegacy'] !== null) tagExists['iaInjectedStyleGlobalLegacy'][0].remove();
    if (!isNullOrUndefined(reportDesign.styles)) {
        styleTag = tagExists['iaInjectedStyleCustom'];
        const tagContent = migrateStyles(reportDesign.styles);
        if (styleTag === undefined || styleTag === null || styleTag.length < 1) {
            styleTag = document.createElement('style');
            styleTag.setAttribute('id', 'iaInjectedStyleCustom');
            //styleTag.setAttribute('data-report-id', report.id);
            styleTag.appendChild(document.createTextNode(tagContent));
            document.head.appendChild(styleTag);
        } else if (styleTag[0].innerText !== tagContent) styleTag[0].innerText = tagContent;
    } else if (tagExists['iaInjectedStyleCustom'] !== null) tagExists['iaInjectedStyleCustom'][0].remove();
};

const findFontRefs = (design: any = {}, initialSet: string[] = []): string[] => {
    const fontFamilies = [...initialSet],
        ffRegex = /^.*FontFamily$/gi,
        attRegex = /font-family:\s*([a-zA-Z0-9-\s,']+);/g;
    let ff, ffm;
    for (let s of design.sections) {
        if (s.widgets !== undefined) {
            for (let w of s.widgets) {
                for (let k of Object.keys(w)) {
                    if (ffRegex.test(k)) {
                        ff = w[k].trim();
                        if (fontFamilies.indexOf(ff) < 0) fontFamilies.push(ff);
                    }
                }
                if (w.text !== undefined) {
                    while ((ffm = attRegex.exec(w.text)) !== null && ffm.length > 1) {
                        for (let f of ffm[1].split(',')) {
                            ff = f.replace(/[^0-9a-zA-Z\-\s]/g, '').trim();
                            if (fontFamilies.indexOf(ff) < 0) fontFamilies.push(ff);
                        }
                    }
                    attRegex.lastIndex = 0;
                }
                if (w.childWidgets !== undefined) {
                    for (let c of w.childWidgets) {
                        for (let k of Object.keys(c)) {
                            if (ffRegex.test(k)) {
                                ff = c[k].trim();
                                if (fontFamilies.indexOf(ff) < 0) fontFamilies.push(ff);
                            }
                        }
                    }
                }
            }
        }
    }
    return fontFamilies.filter((f) => f !== 'Font Awesome 5 Free'); // Special case - cannot be Google if is Font Awesome
};

// Obvious and well-known fonts that cannot come from Google
const nonGoogleFonts = [
    'Font Awesome 5 Free',
    'FontAwesome',
    'Avenir Next',
    'Avenir-light',
    'Arial',
    'Calibri',
    'Cambria',
    'Candara',
    'Consolas',
    'Gill Sans',
    'Helvetica',
    'Impact',
    'Lucida Grande',
    'Lucida Sans Unicode',
    'Lucida Sans',
    'Microsoft Sans Serif',
    'Optima',
    'Segoe',
    'Segoe UI',
    'Tahoma',
    'Trebuchet MS',
    'Verdana',
    'serif',
    'sans-serif',
    'monospace',
    'cursive',
    'fantasy',
    'system-ui',
    'emoji',
    'math',
    'fangsong',
    'inherit'
].map((f) => f.toLowerCase());

export const getNamedSize = (sizeKey = 'A4'): { width: string; height: string } => {
    let size = {
        width: '210mm',
        height: '297mm'
    };
    switch (sizeKey.toUpperCase()) {
        case 'A2':
            size = {
                width: '420mm',
                height: '594mm'
            };
            break;
        case 'A3':
            size = {
                width: '297mm',
                height: '420mm'
            };
            break;
        case 'A5':
            size = {
                width: '148mm',
                height: '210mm'
            };
            break;
        case 'LETTER':
            size = {
                width: '216mm',
                height: '279mm'
            };
            break;
        case 'LEGAL':
            size = {
                width: '216mm',
                height: '356mm'
            };
            break;
        default:
            size = {
                width: '210mm',
                height: '297mm'
            };
    }
    return size;
};

export const findIndicatorRefs = (design: any): any[] => {
    const iRefs: any[] = [];
    for (let s of design.sections || []) {
        for (let w of s.widgets || []) {
            findAndFillIndicatorsFromWidget(w, iRefs);
        }
    }
    return iRefs;
};

const findAndFillIndicatorsFromWidget = (widgetDef: any, indicatorList: any[]) => {
    if (widgetDef.indicators !== undefined && widgetDef.indicators !== null) {
        let iid;
        for (let iRef of widgetDef.indicators) {
            iid = iRef.id;
            if (indicatorList.indexOf(iid) < 0) indicatorList.push(iid);
        }
    }
    if (widgetDef.scriptClass.indexOf('TextWidget') > 0 || widgetDef.scriptClass === 'TextBoxWidget') {
        for (let iid of TextWidget.getIndicatorsFromText(widgetDef.text).indicators.map((iRef) => iRef.id)) {
            if (indicatorList.indexOf(iid) < 0) indicatorList.push(iid);
            if (widgetDef.rules !== undefined && widgetDef.rules !== null) {
                for (let r of widgetDef.rules) {
                    for (let iid of TextWidget.getIndicatorsFromText(r.text).indicators.map((iRef) => iRef.id)) {
                        if (indicatorList.indexOf(iid) < 0) indicatorList.push(iid);
                    }
                }
            }
        }
    }
    if (widgetDef.childWidgets !== undefined && widgetDef.childWidgets !== null) {
        for (let wRef of widgetDef.childWidgets) {
            findAndFillIndicatorsFromWidget(wRef, indicatorList);
        }
    }
};

export const getWidgetFromDesign = (widgetId: string, design: any = {}): any => {
    let match = undefined;
    if (design.sections !== undefined) {
        for (let s of design.sections) {
            for (let w of s.widgets) {
                if (w.id === widgetId) {
                    match = w;
                    break;
                } else if (w.childWidgets !== undefined) {
                    for (let cw of w.childWidgets) {
                        if (cw.id === widgetId) {
                            match = cw;
                            cw.parentId = w.id;
                            break;
                        }
                    }
                }
            }
        }
    }
    return match;
};

export const findWidgetIndex = (widgetId: string, design: any = {}): number[] => {
    let match = [-1, -1];
    if (design.sections !== undefined) {
        for (let i = 0; i < design.sections.length; i++) {
            for (let j = 0; j < design.sections[i].widgets.length; j++) {
                if (design.sections[i].widgets[j].id === widgetId) {
                    match = [i, j];
                    break;
                } else if (design.sections[i].widgets[j].childWidgets !== undefined) {
                    for (let k = 0; k < design.sections[i].widgets[j].childWidgets.length; k++) {
                        if (design.sections[i].widgets[j].childWidgets[k].id === widgetId) {
                            match = [i, j, k];
                            break;
                        }
                    }
                }
            }
        }
    }
    return match;
};

export const getFlatWidgetList = (section: any): any[] => {
    const flat: any[] = [];
    for (let w of section.widgets) {
        flat.push(w);
        if (w.childWidgets !== undefined) {
            for (let cw of w.childWidgets) {
                flat.push(cw);
            }
        }
    }
    return flat;
};

export const copyWidget = (widgetId: string, design: any = {}): any | undefined => {
    const src = getWidgetFromDesign(widgetId, design);
    if (src !== undefined) {
        const cloned = JSON.parse(JSON.stringify(src)); // Ugly but effective - everything loses any dangling references by converting in/out of JSON
        cloned.id = generateGuid();
        return cloned;
    } else return undefined;
};

export const copySection = (sectionIndex: number, design: any = {}): any => {
    const src = design.sections !== undefined ? design.sections[sectionIndex] : undefined;
    if (src !== undefined) {
        const clonedWidgets: any[] = [],
            cloned: any = {
                id: generateGuid(),
                widgets: clonedWidgets
            };
        for (let i = 0; i < src.widgets.length; i++) {
            cloned.widgets.push(JSON.parse(JSON.stringify(src.widgets[i]))); // Ugly but effective - everything loses any dangling references by converting in/out of JSON
            cloned.widgets[cloned.widgets.length - 1].id = generateGuid();
        }
        return cloned;
    } else return undefined;
};

export const generateGuid = (format = 'N'): string => {
    const df = s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    return (format === 'D' ? df : df.replace(/[^0-9a-zA-Z]/g, '')).toLowerCase();
};

const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
};

export const getMobileConstraints = (mediaQuery = '(max-width: 500px)'): string => {
    return (
        `@media screen and ${mediaQuery} {` +
        '.esriuk-app .ia-report.media-responsive { width: 100%; }' +
        '.esriuk-app .ia-report.media-responsive section, .esriuk-app .ia-report .ia-report-section { max-width: 100% !important; }' +
        '.ia-report.media-responsive .ia-report-widget { width: 100% !important; max-width: 100% !important; margin-left: 0 !important; margin-right: 0 !important; }' +
        '}'
    );
};

export const getPageSizeConstraints = (mediaQuery = '(max-width: 999px)'): string => {
    return (
        `@media screen and ${mediaQuery} {` +
        '.esriuk-app .ia-report.media-responsive { width: 100%; }' +
        '.esriuk-app .ia-report.media-responsive section, .esriuk-app .ia-report .ia-report-section { max-width: 100% !important; }' +
        '.ia-report.media-responsive .ia-report-widget { max-width: 100% !important; }' +
        // More sophisticated % widths - for direct descendants of a section only
        ['100', '90', '80', '70', '60', '50', '40', '30', '20', '10']
            .map(
                (w) =>
                    `.ia-report.media-responsive .ia-report-section-wrapper > .ia-report-widget.ia-max-width-p${w} { max-width: ${w}%; }`
            )
            .join(' ') +
        '}'
    );
};
export const logMessagesToConsole = (messages: any[] = [], groupLabel = ''): void => {
    if (messages.length > 0) {
        if (groupLabel !== '') console.groupCollapsed(groupLabel);
        for (let m of messages) {
            if (m.type === 'groupStart') console.groupCollapsed(m.message);
            const mm =
                m.type === 'error'
                    ? console.error
                    : m.type === 'warn' || m.type === 'warning'
                    ? console.warn
                    : m.type === 'debug'
                    ? console.debug
                    : console.log;
            mm((m.type === 'debug' ? '\t' : '') + m.message);
            if (m.type === 'groupEnd') console.groupEnd();
        }
        if (groupLabel !== '') console.groupEnd();
    }
};
