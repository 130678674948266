import React, { useState } from 'react';
import { useAppSelector, RootState } from '../redux/builderStoreHooks';
import { FormattedMessage, useIntl } from 'react-intl';
import DarkModeSwitch from './DarkModeSwitch';
import { isNullOrUndefined, isNullOrWhitespace } from '../utils/object';
import { getTokenFor } from '../utils/arcgis';

import '@esri/calcite-components/dist/components/calcite-avatar';
import '@esri/calcite-components/dist/components/calcite-dropdown-group';
import '@esri/calcite-components/dist/components/calcite-dropdown-item';
import '@esri/calcite-components/dist/components/calcite-popover';
import '@esri/calcite-components/dist/components/calcite-split-button';
import {
    CalciteAvatar,
    CalciteDropdownGroup,
    CalciteDropdownItem,
    CalcitePopover,
    CalciteSplitButton
} from '@esri/calcite-components-react';

const UserOptions = ({
    signOutAction,
    returnUrl,
    showOptions,
    showMode = true,
    location,
    tipLocation,
    alignment = 'left',
    children
}: {
    signOutAction?: any;
    returnUrl?: string;
    showOptions?: boolean;
    showMode?: boolean;
    location?: any;
    tipLocation?:
        | 'inline'
        | 'top'
        | 'top-left'
        | 'top-right'
        | 'bottom'
        | 'bottom-left'
        | 'bottom-right';
    alignment?: 'left' | 'right';
    children?: React.ReactNode;
}) => {
    const portalUrl: string = useAppSelector((state: RootState) => state.appSettings.portalUrl),
        user: any = useAppSelector((state: RootState) => state.appSettings.user),
        token: string = useAppSelector((state: RootState) => state.appSettings.token),
        tokenManager: any = useAppSelector(
            (state: RootState) => state.applicationState.tokenManager
        ),
        anon =
            user === undefined ||
            user === null ||
            user.username === undefined ||
            user.username === null ||
            user.username.length === 0,
        showOptionsLink = isNullOrUndefined(showOptions) || showOptions === true,
        intl = useIntl(),
        action = () => {
            if (signOutAction) signOutAction();
            else if (returnUrl) window.location.href = returnUrl;
            else
                window.location.href =
                    (typeof process !== 'undefined' &&
                    typeof process.env !== 'undefined' &&
                    !isNullOrWhitespace(process.env.PUBLIC_URL)
                        ? process.env.PUBLIC_URL
                        : '/') + '#sign-out';
        },
        aTitle = {
            id: 'app.label.user',
            defaultMessage: 'You are signed in @{portal} as {name} ({username})'
        },
        tipText = intl.formatMessage(aTitle, {
            name: anon ? '?' : user.fullName,
            username: anon ? '?' : user.username,
            portal:
                portalUrl !== undefined && portalUrl !== null
                    ? portalUrl.replace(/^(http:\/\/|https:\/\/)([0-9a-zA-Z_\-.]+)\/(.*)$/, '$2')
                    : ''
        }),
        [infoOpen, setInfoOpen] = useState<boolean>(false);

    return anon ? null : (
        <>
            <CalciteAvatar
                userId={user.id}
                username={user.username}
                fullName={user.fullName}
                thumbnail={
                    user.thumbnail !== undefined && user.thumbnail !== null
                        ? `${portalUrl.replace(/\/$/, '')}/community/users/${user.username}/info/${
                              user.thumbnail
                          }?token=${getTokenFor(portalUrl, tokenManager, token)}`
                        : undefined
                }
            ></CalciteAvatar>
            <CalciteSplitButton
                id="rbUserBtn"
                appearance="transparent"
                color="neutral"
                kind="neutral"
                primaryText={user.fullName}
                onCalciteSplitButtonPrimaryClick={() => {
                    setInfoOpen((o) => {
                        return !o;
                    });
                }}
            >
                {showMode && (
                    <CalciteDropdownGroup selectionMode="none">
                        <CalciteDropdownItem>
                            <DarkModeSwitch />
                        </CalciteDropdownItem>
                    </CalciteDropdownGroup>
                )}
                {children}
                <CalciteDropdownGroup selectionMode="none">
                    <CalciteDropdownItem
                        href={
                            action !== undefined
                                ? undefined
                                : `${
                                      typeof process !== 'undefined' &&
                                      typeof process.env !== 'undefined'
                                          ? process.env.PUBLIC_URL
                                          : '/'
                                  }#sign-out`
                        }
                        className="sign-out"
                        onClick={(e) => {
                            if (action !== undefined) action();
                        }}
                        iconStart="sign-out"
                    >
                        <FormattedMessage id="app.signout.button" defaultMessage="Sign Out" />
                    </CalciteDropdownItem>
                </CalciteDropdownGroup>
            </CalciteSplitButton>
            <CalcitePopover
                open={infoOpen ? true : undefined}
                referenceElement="rbUserBtn"
                label="User detail"
                placement="trailing-end"
                autoClose={true}
                triggerDisabled={true}
            >
                <div className="pad-10">
                    <div>{tipText}</div>
                </div>
            </CalcitePopover>
        </>
    );
};

export default UserOptions;
