import AbstractWidget, { applyRule } from './AbstractWidget';
import { formatDateCustom, getNumberFormatter } from '../utils/localization';

const VALUE_KEYS = [
    'FNAME',
    'FID',
    'FNAMES',
    'FIDS',
    'CFNAME',
    'CFID',
    'INAME',
    'IINAME',
    'IDATE',
    'IVALUE',
    'ILVALUE',
    'IIVALUE',
    'ILDATE',
    'FVALUE',
    'CVALUE',
    'IMETADATALINK',
    'IURL',
    'ISOURCE'
];

class TextWidget extends AbstractWidget {
    // Render here is slightly special, because it can _detect_ if the calling (React) class has laid out some of the HTML before calling this method.
    render = (data, options = {}) => {
        if (
            this.container !== undefined &&
            this.container !== null &&
            this.design !== undefined &&
            this.design !== null
        ) {
            const isBox = this.design.scriptClass === 'TextBoxWidget',
                topWidget = this.container.closest('.ia-report-widget'),
                textContent = topWidget.querySelector(
                    `${isBox ? '.ia-text-box-content' : '.ia-text-content'} > .text-body`
                ), // First one, should not be duplicates...
                titleContent = topWidget.querySelector(`${isBox ? '.ia-text-box-title' : '.ia-text-title'}`), // First one, should not be duplicates...
                { locale, numberFormat, noDataText } = this.design,
                dataAvailable = data !== undefined && data !== null && Array.isArray(data) && data.length > 0;
            let html = getHtmlFromText(replaceIndicatorKeys(this.design.text, options.requested)); //(textContent !== undefined ? textContent.innerHTML : getHtmlFromText(this.design.text));
            // Rules?
            if (this.design.rules && this.design.rules.length > 0 && dataAvailable) {
                let tv, ov;
                for (let rule of this.design.rules) {
                    const r = {
                        ...rule
                    };
                    // Test numbers as default language and format, display values are different...
                    r.value = TextWidget.insertValuesIntoText(
                        replaceIndicatorKeys(r.value, options.requested),
                        data,
                        'G',
                        'en',
                        '<NULL>',
                        false
                    );
                    tv = TextWidget.insertValuesIntoText(
                        replaceIndicatorKeys(r.key, options.requested),
                        data,
                        'G',
                        'en',
                        '<NULL>',
                        false
                    );
                    ov = applyRule(r, tv);
                    if (ov !== null) {
                        html = getHtmlFromText(ov);
                        break;
                    }
                }
            }
            if (html !== undefined && html !== null && dataAvailable) {
                if (data[0].features !== undefined && data[0].features !== null) {
                    const feature = data[0].features.find((f) => f.comparison !== true && f.active === true),
                        allFeatures = data[0].features.filter((f) => f.comparison !== true),
                        isLoopy = /(#LOOPFEATURES|<featureloop>)/i.test(html);
                    if (feature !== undefined && !isLoopy) {
                        html = html
                            .replace(/#FNAMES\{([^}{]+)\}|\{(active)\}/gi, allFeatures.map((f) => f.name).join('$1'))
                            .replace(
                                /#FNAME\{[0-9]+\}|\{active\}/gi,
                                allFeatures[!isNaN(parseInt('$1')) ? parseInt('$1') - 1 : 0].name
                            )
                            .replace(/#FNAME/gi, feature.name)
                            .replace(/#FIDS\{([^}{]+)\}|\{(active)\}/gi, allFeatures.map((f) => f.id).join('$1'))
                            .replace(
                                /#FID\{[0-9]+\}|\{active\}/gi,
                                allFeatures[!isNaN(parseInt('$1')) ? parseInt('$1') - 1 : 0].id
                            )
                            .replace(/#FID/gi, feature.id);
                    }
                }
                html = TextWidget.insertValuesIntoText(html, data, numberFormat, locale, noDataText);
                if (textContent !== undefined && textContent !== null)
                    textContent.innerHTML = html.replace('<>', '').replace('</>', ''); // Allow empty enclosers, React-style
                // Title?
                if (titleContent !== undefined && titleContent !== null) {
                    html = getHtmlFromText(replaceIndicatorKeys(this.design.titleText, options.requested));
                    html = TextWidget.insertValuesIntoText(html, data, numberFormat, locale, noDataText);
                    if (data[0].features !== undefined && data[0].features !== null) {
                        const feature = data[0].features.find((f) => f.comparison !== true && f.active === true),
                            allFeatures = data[0].features.filter((f) => f.comparison !== true);
                        if (feature !== undefined) {
                            html = html
                                .replace(
                                    /#FNAMES(\{1\}|\{, \}|\{active\})?/gi,
                                    allFeatures.map((f) => f.name).join(', ')
                                )
                                .replace(/#FIDS(\{1\}|\{, \}|\{active\})?/gi, allFeatures.map((f) => f.id).join(', '))
                                .replace(/#FNAME(\{1\}|\{, \}|\{active\})?/gi, feature.name)
                                .replace(/#FID(\{1\}|\{, \}|\{active\})?/gi, feature.id);
                        }
                    }
                    titleContent.innerHTML = html.replace('<>', '').replace('</>', ''); // Allow empty enclosers, React-style
                }
            }
            if (topWidget !== null)
                topWidget.setAttribute('class', topWidget.getAttribute('class').replace(/\s(placeholder)/g, ''));
        }
    };

    static getDefaults = () => {
        return {
            ...AbstractWidget.getDefaults(),
            sortFeaturesByName: false,
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            rules: []
        };
    };

    static findIndicatorInDataSource(d, key) {
        const i = /^[0-9]+$/.test(key) ? parseInt(key) : -1;
        return (
            (d.indicator !== undefined && d.indicator.id === key) ||
            (d.indicators !== undefined && d.indicators.findIndex((c) => c.id === key) >= 0) || // Normal ID match
            (d.indicators !== undefined &&
                i < 0 &&
                d.indicators.findIndex(
                    (c) =>
                        c.field !== undefined &&
                        (Array.isArray(c.field) ? c.field.indexOf(key.trim()) >= 0 : c.field.trim() === key.trim())
                ) >= 0) || // Normal ID match
            (d.indicators !== undefined &&
                d.indicators.findIndex(
                    (c) =>
                        c.offset !== undefined &&
                        (c.offset.toString() === key.toString() || (c.offset % 1000).toString() === key.toString())
                ) >= 0) ||
            (d.indicators !== undefined &&
                d.indicators.findIndex(
                    (c) =>
                        c.index !== undefined &&
                        (c.index.toString() === key.toString() || (c.index % 1000).toString() === key.toString())
                ) >= 0)
        );
    }

    static findFeatureInDataSource(d, key) {
        if (key === 'active') {
            return d.features !== undefined && d.features.find((f) => f.active) !== undefined;
        }
        const i = /^[0-9]+$/.test(key) ? parseInt(key) : Number.MAX_SAFE_INTEGER;
        return (
            (d.features !== undefined && d.features.find((f) => f.id === key) !== undefined) ||
            (d.features !== undefined && i <= d.features.length)
        );
    }

    static insertValuesIntoText = (html, data, numberFormat, locale, noDataText = 'N/A', useHtmlForNoData = true) => {
        const looper = /^([\s\S]*)(?:#FEATURELOOP|<featureloop>)([\s\S]*)(?:#END|<\/featureloop>)([\s\S]*)$/i.exec(
                html
            ),
            parsed = [],
            isLoopy = looper !== null && looper.length === 4,
            loopyHtml = (isLoopy ? looper[2] : html).replace(
                /[$]feature[.]([a-zA-Z0-9_]{2,30})/gim,
                '#IVALUE{$1,active}'
            ), // (Very) Basic support for Arcade-style syntax
            featureIndices =
                isLoopy &&
                Array.isArray(data) &&
                data.find((d) => d.primary) !== undefined &&
                data.find((d) => d.primary).features !== undefined
                    ? data
                          .find((d) => d.primary)
                          .features.filter((f) => !f.comparison)
                          .map((f, i) => i)
                    : [0];
        if (isLoopy) {
            parsed.push(
                TextWidget.insertValuesIntoText(looper[1], data, numberFormat, locale, noDataText, useHtmlForNoData)
            );
        }
        for (let fi = 0; fi < featureIndices.length; fi++) {
            const tokens = loopyHtml.split(/(?<!([\\]|#|NF:|#,|#.|0.))(#)/).filter((t) => t !== undefined && t !== '}'), //.split(/([^\\]|^)(#)/).filter((t) => t !== '}'), // In case of wonky text - see bug #7521
                datasets = data !== undefined && data !== null ? (Array.isArray(data) ? data : [data]) : [],
                nfmt = getNumberFormatter(locale, numberFormat, undefined, noDataText);
            let valueKey,
                valueInd,
                value,
                start,
                end,
                subData,
                fIdx,
                cIdx,
                preceded = false,
                localNumFmt;
            try {
                for (let i = 0; i < tokens.length; i++) {
                    if (preceded) {
                        // Escaped? If not , do something...
                        if (i === 0 || tokens[i - 1].charAt(tokens[i - 1].length - 1) !== '\\') {
                            start = tokens[i].indexOf('{');
                            if (start < 0) start = tokens[i].indexOf(' ');
                            valueKey = (start < 0 ? tokens[i] : tokens[i].substring(0, start))
                                .toUpperCase()
                                .replace(/[^0-9A-Z]/g, '');
                            // Only bother looking for things we know about...
                            if (VALUE_KEYS.indexOf(valueKey) >= 0) {
                                start = tokens[i].indexOf('{');
                                end = tokens[i].indexOf('}', start);
                                localNumFmt = nfmt; // Standard number format - can be overridden
                                if (end < 0) end = tokens[i].length; // In case of stripping by wonky text (see comment above)
                                if (start < 0) {
                                    start = tokens[i].indexOf(/[^0-9a-zA-Z]/);
                                    end = start < 0 ? tokens[i].length : start - 1;
                                    valueInd = ['1'];
                                } else {
                                    const isSemiDelimited = tokens[i].indexOf(';') > 0 && tokens[i].indexOf(';') < end;
                                    valueInd = tokens[i]
                                        .substring(start + 1, end)
                                        .split(isSemiDelimited ? ';' : ',')
                                        .map((t) => {
                                            return t.trim();
                                        });
                                }
                                if (
                                    valueInd[0].indexOf('catalog:') === 0 ||
                                    valueInd[0].indexOf('datastore:') === 0 ||
                                    valueInd[0].indexOf('datacatalog:') === 0
                                )
                                    valueInd[0] = valueInd[0].split(':')[1]; // Don't care now
                                //if ((valueKey.substring(0, 1) === 'F') && (valueInd[0] === 'active')) valueInd[0] = '1';
                                const searchId = valueInd[0].toString();
                                subData =
                                    valueKey.substring(0, 1) === 'F'
                                        ? datasets.find((d) => TextWidget.findFeatureInDataSource(d, searchId))
                                        : datasets.find((d) => TextWidget.findIndicatorInDataSource(d, searchId));
                                // Last chance - cannot find by ID - find the first one that has indicators (1st/primary one may not if it only has the feature IDs and names)
                                if (subData === undefined) subData = findFirstDataWithIndicators(datasets);
                                if (subData !== undefined) {
                                    if (
                                        valueInd.length > 1 &&
                                        valueInd[valueInd.length - 1].substring(0, 3).toUpperCase() === 'NF:'
                                    ) {
                                        const nf = valueInd.pop().substring(3);
                                        try {
                                            localNumFmt = getNumberFormatter(locale, nf, undefined, noDataText);
                                        } catch (nferr) {
                                            console.debug(nferr);
                                            localNumFmt = nfmt;
                                        }
                                    }
                                    // Special cases for index of the feature... mainly related to comparisons
                                    if (
                                        (valueInd.length > 1 && valueInd[1].match(/^C[0-9]+$/i)) ||
                                        valueKey === 'CFNAME' ||
                                        valueKey === 'CFID'
                                    ) {
                                        // Presume 'active' comparison is the 1st one (how else can you know?)
                                        const tgt =
                                            valueInd[0] === 'active'
                                                ? 1
                                                : parseInt(
                                                      valueKey === 'CFNAME' || valueKey === 'CFID'
                                                          ? valueInd[0]
                                                          : valueInd[1].substring(1)
                                                  );
                                        let cat = 1;
                                        for (let i = 0; i < subData.features.length; i++) {
                                            if (subData.features[i].comparison === true) {
                                                if (cat === tgt) fIdx = i;
                                                cat++;
                                            }
                                        }
                                    } else if (valueInd.length > 1 && valueInd[1].match(/^C:[0-9a-zA-Z_-]+$/i)) {
                                        const tgt = valueInd[1].substring(2);
                                        for (let i = 0; i < subData.features.length; i++) {
                                            if (subData.features[i].comparison === true) {
                                                if (subData.features[i].id === tgt) fIdx = i;
                                            }
                                        }
                                    } else {
                                        fIdx =
                                            valueInd.length > 1 &&
                                            (!isLoopy || valueInd[1] !== 'active') &&
                                            valueInd[1].substring(0, 3) !== 'NF:'
                                                ? valueInd[1] === 'active' || isNaN(parseInt(valueInd[1]))
                                                    ? subData.features.length > 1
                                                        ? subData.features.findIndex((c) => c.active)
                                                        : 0
                                                    : parseInt(valueInd[1]) - 1
                                                : subData.features !== undefined && !isLoopy
                                                ? Math.max(
                                                      subData.features.findIndex((f) => f.comparison !== true),
                                                      0
                                                  )
                                                : fi;
                                    }
                                    if (
                                        valueKey === 'FNAME' ||
                                        valueKey === 'FID' ||
                                        valueKey === 'CFNAME' ||
                                        valueKey === 'CFID' ||
                                        (valueInd.length > 1 && valueInd[1] === 'active' && valueKey === 'FNAMES')
                                    ) {
                                        // Special case for names
                                        // Comparisons already found, because they are more subtle - see above ^
                                        if (valueKey.substring(0, 1) !== 'C') {
                                            const tgtId = valueInd[0];
                                            fIdx =
                                                valueInd[0] === 'active' && !isLoopy
                                                    ? subData.features.findIndex((c) => c.active)
                                                    : /^[0-9]+$/.test(valueInd[0])
                                                    ? parseInt(valueInd[0]) - 1
                                                    : valueInd !== '' && (!isLoopy || valueInd[0] !== 'active')
                                                    ? subData.features.findIndex((c) => c.id === tgtId)
                                                    : fi;
                                        }
                                        tokens[i] = `${
                                            fIdx >= 0 && fIdx < subData.features.length
                                                ? valueKey.indexOf('FID') >= 0
                                                    ? subData.features[fIdx].id
                                                    : subData.features[fIdx].name
                                                : noDataText
                                        }${tokens[i].substring(end + 1)}`;
                                    } else if (valueKey === 'FNAMES' || valueKey === 'FIDS') {
                                        if (valueInd[0] === '1') valueInd = ', ';
                                        else valueInd = valueInd.join(',');
                                        tokens[i] = `${
                                            valueKey === 'FIDS'
                                                ? subData.features
                                                      .filter((f) => !f.comparison)
                                                      .map((f) => f.id)
                                                      .join(valueInd)
                                                : subData.features
                                                      .filter((f) => !f.comparison)
                                                      .map((f) => f.name)
                                                      .join(valueInd)
                                        } ${tokens[i].substring(end + 1)}`;
                                    }
                                    // INAME or IDATE - by <index>
                                    else if (
                                        (valueKey === 'INAME' || valueKey === 'IDATE' || valueKey === 'IINAME') &&
                                        valueInd.length === 1 &&
                                        !isNaN(parseInt(valueInd[0]))
                                    ) {
                                        const tgtIdx = parseInt(valueInd[0]);
                                        cIdx = subData.indicators.find(
                                            (h) =>
                                                (h.index !== undefined && h.index === tgtIdx) ||
                                                (h.offset !== undefined && h.offset % 1000 === tgtIdx)
                                        );
                                        if (cIdx === undefined) cIdx = subData.indicators[parseInt(valueInd[0]) - 1];
                                        value = null;
                                        if (cIdx !== undefined && (valueKey === 'INAME' || valueKey === 'IINAME')) {
                                            value = cIdx.name;
                                            tokens[i] = `${value}${tokens[i].substring(end + 1)}`;
                                        } else if (cIdx !== undefined) {
                                            const tgtId = cIdx.id;
                                            cIdx = subData.colIds.findIndex((h) => h.iid === tgtId);
                                            value = subData.colIds[cIdx].label;
                                            tokens[i] = `${value}${tokens[i].substring(end + 1)}`;
                                        }
                                        // Not found - default back out to the "no data" value...
                                        else {
                                            tokens[i] = useHtmlForNoData
                                                ? `<span class="missing-data" data-key="${valueKey}{${valueInd}}">${noDataText}</span>${tokens[
                                                      i
                                                  ].substring(end + 1)}`
                                                : `${noDataText}${tokens[i].substring(end + 1)}`;
                                            console.log('⚠️ No data! ' + valueKey + '{' + valueInd + '}'); // DEBUG
                                            //console.log(datasets); // DEBUG
                                        }
                                    } else if (valueKey === 'INAME') {
                                        const tgtId = valueInd[0];
                                        cIdx = subData.indicators.findIndex((c) => c.id === tgtId);
                                        tokens[i] = `${cIdx >= 0 ? subData.indicators[cIdx].name : noDataText}${tokens[
                                            i
                                        ].substring(end + 1)}`;
                                    } else if (valueKey === 'IURL' || valueKey === 'ISOURCE') {
                                        const tgtId = valueInd[0];
                                        cIdx = subData.indicators.findIndex((c) => c.id === tgtId);
                                        tokens[i] = `${
                                            cIdx >= 0 && subData.indicators[cIdx].src !== undefined
                                                ? subData.indicators[cIdx].src
                                                : noDataText
                                        }${tokens[i].substring(end + 1)}`;
                                    } else if (valueKey === 'ILDATE') {
                                        if (valueInd.length === 1 && !isNaN(parseInt(valueInd[0])))
                                            valueInd[0] = subData.colIds[parseInt(valueInd[0])].iid;
                                        const tgtId = valueInd[0];
                                        cIdx =
                                            subData.colIds.findIndex((c) => c.iid === tgtId) +
                                            subData.colIds.filter((c) => c.iid === tgtId).length -
                                            1;
                                        tokens[i] = `${cIdx >= 0 ? subData.headers[cIdx] : noDataText}${tokens[
                                            i
                                        ].substring(end + 1)}`;
                                    } else if (
                                        valueKey === 'ILVALUE' &&
                                        fIdx >= 0 &&
                                        fIdx < subData.rows.length &&
                                        subData.rows[fIdx] !== undefined
                                    ) {
                                        if (valueInd.length === 1 && !isNaN(parseInt(valueInd[0])))
                                            valueInd[0] = subData.colIds[parseInt(valueInd[0])].iid;
                                        const tgtId = valueInd[0];
                                        cIdx =
                                            subData.colIds.findIndex((c) => c.iid === tgtId) +
                                            subData.colIds.filter((c) => c.iid === tgtId).length -
                                            1;
                                        value = subData.rows[fIdx][cIdx];
                                        tokens[i] =
                                            value &&
                                            value.constructor !== undefined &&
                                            value.constructor.name === 'Date'
                                                ? value.toLocaleString(locale)
                                                : `${localNumFmt.format(value)}${tokens[i].substring(end + 1)}`;
                                    }
                                    // IVALUE or IIVALUE - by <ID>,<feature-index>,<date>
                                    else if (
                                        (valueKey === 'IVALUE' || valueKey === 'IIVALUE') &&
                                        valueInd.length === 3 &&
                                        fIdx >= 0 &&
                                        fIdx < subData.rows.length
                                    ) {
                                        const tgtId = valueInd[0],
                                            tgtHeader = valueInd[2].toLowerCase();
                                        cIdx = subData.colIds.findIndex(
                                            (h) => h.label.toLowerCase() === tgtHeader && h.iid === tgtId
                                        );
                                        // Easy - we found it in the set...
                                        if (cIdx >= 0) {
                                            value = subData.rows[fIdx][cIdx];
                                            tokens[i] =
                                                value.constructor !== undefined && value.constructor.name === 'Date'
                                                    ? value.toLocaleString(locale)
                                                    : `${localNumFmt.format(value)}${tokens[i].substring(end + 1)}`;
                                        }
                                        // Not quite so easy - data might be there, but in a different "table"
                                        else if (
                                            (subData = datasets.find(
                                                (d) =>
                                                    d.indicator !== undefined &&
                                                    d.indicator.id === tgtId &&
                                                    d.headers.findIndex((h) => h.toLowerCase() === tgtHeader) >= 0
                                            )) !== undefined
                                        ) {
                                            cIdx = subData.headers.findIndex((h) => h.toLowerCase() === tgtHeader);
                                            // Must be in the set to get inside this if...
                                            value = subData.rows[fIdx][cIdx];
                                            tokens[i] =
                                                value.constructor !== undefined && value.constructor.name === 'Date'
                                                    ? value.toLocaleString(locale)
                                                    : `${localNumFmt.format(value)}${tokens[i].substring(end + 1)}`;
                                        }
                                        // Not found - default back out to the "no data" value...
                                        else {
                                            tokens[i] = useHtmlForNoData
                                                ? `<span class="missing-data" data-key="${valueKey}{${valueInd}}">${noDataText}</span>${tokens[
                                                      i
                                                  ].substring(end + 1)}`
                                                : `${noDataText}${tokens[i].substring(end + 1)}`;
                                            console.log('⚠️ No data! ' + valueKey + '{' + valueInd + '}'); // DEBUG
                                            //console.log(datasets); // DEBUG
                                        }
                                    }
                                    // IVALUE or IIVALUE - by <index> or, for feature service sources, by <field-name>
                                    else if (
                                        (valueKey === 'IVALUE' || valueKey === 'IIVALUE' || valueKey === 'CVALUE') &&
                                        valueInd.length < 3
                                    ) {
                                        const tgtId = valueInd[0],
                                            tgtIdx = parseInt(valueInd[0]),
                                            isNum = !isNaN(tgtIdx);
                                        cIdx = isNum
                                            ? subData.indicators.find(
                                                  (h) =>
                                                      (h.index !== undefined &&
                                                          (h.index === tgtIdx || h.index % 1000 === tgtIdx)) ||
                                                      (h.offset !== undefined && h.offset % 1000 === tgtIdx)
                                              )
                                            : subData.indicators.find(
                                                  (h) =>
                                                      (h.field !== undefined &&
                                                          (Array.isArray(h.field)
                                                              ? h.field.indexOf(tgtId.trim()) >= 0
                                                              : h.field.trim() === tgtId.trim())) ||
                                                      (h.name !== undefined && h.name === tgtId) ||
                                                      (h.id !== undefined && h.id === tgtId)
                                              );
                                        // Not there OR not numeric, straight field match?
                                        if (
                                            !isNum &&
                                            (cIdx === undefined ||
                                                subData.colIds.findIndex(
                                                    (h) => h.field !== undefined && h.field === tgtId
                                                ) > 0)
                                        )
                                            cIdx = subData.colIds.findIndex(
                                                (h) => h.field !== undefined && h.field === tgtId
                                            );
                                        // Not there? OK, fallback to the pure index as fetched by the previous step... This is only relevant for indicators selected by a "required" e.g. in an icon widget title...
                                        if (isNum && cIdx === undefined)
                                            cIdx = subData.indicators[parseInt(valueInd[0]) - 1];
                                        if (cIdx !== undefined) {
                                            if (typeof cIdx !== 'number') {
                                                const ciid = cIdx.id;
                                                cIdx = subData.colIds.findIndex((h) => h.iid === ciid);
                                            }
                                        } else cIdx = -1;
                                        // Easy - we found it in the set...
                                        value =
                                            cIdx >= 0 && fIdx < subData.rows.length ? subData.rows[fIdx][cIdx] : null;
                                        if (value !== undefined && value !== null) {
                                            tokens[i] =
                                                value.constructor !== undefined && value.constructor.name === 'Date'
                                                    ? value.toLocaleString(locale)
                                                    : `${localNumFmt.format(value)}${tokens[i].substring(end + 1)}`;
                                        }
                                        // Not found - default back out to the "no data" value...
                                        else {
                                            tokens[i] = useHtmlForNoData
                                                ? `<span class="missing-data" data-key="${valueKey}{${valueInd}}">${noDataText}</span>${tokens[
                                                      i
                                                  ].substring(end + 1)}`
                                                : `${noDataText}${tokens[i].substring(end + 1)}`;
                                            console.log(`⚠️ No data! ${valueKey}{${valueInd}}, f=${fIdx}, c=${cIdx}`); // DEBUG
                                            //console.log(datasets); // DEBUG
                                        }
                                    } else if (valueKey === 'IMETADATALINK') {
                                        // Special and new for RB2.0 - clean up links
                                        const [tgtId, icon = 'info', txt = ''] = valueInd;
                                        cIdx = subData.indicators.findIndex((c) => c.id === tgtId);
                                        const inm = cIdx >= 0 ? subData.indicators[cIdx].name : noDataText,
                                            linkBody =
                                                icon === 'custom' || icon === 'text'
                                                    ? txt
                                                    : `<i class="fas fa-fw fa-${icon.replace(
                                                          /['"]/g,
                                                          ''
                                                      )}"></i><span class="sr-only"> View metadata for ${inm}</span>`;
                                        tokens[i] =
                                            cIdx >= 0
                                                ? `<a href="?#metadata:${tgtId}" style="text-decoration: none !important;" data-tooltip="${inm} | Metadata" class="pure-tip pure-tip-bottom">${linkBody}</a>${tokens[
                                                      i
                                                  ].substring(end + 1)}`
                                                : `${tokens[i].substring(end + 1)}`;
                                    } else console.log(`⚠️ No data! ${valueKey}{${valueInd}}, f=${fIdx}, c=${cIdx}`); // DEBUG
                                } else {
                                    console.log(`⚠️ No dataset! ${valueKey}{${valueInd}}, f=${fIdx}, c=${cIdx}`); // DEBUG
                                    tokens[i] = useHtmlForNoData
                                        ? `<span class="missing-data" data-key="${valueKey}{${valueInd}}">${noDataText}</span>${tokens[
                                              i
                                          ].substring(end + 1)}`
                                        : `${noDataText}${tokens[i].substring(end + 1)}`;
                                }
                            } else tokens[i] = `#${tokens[i]}`;
                        }
                    }
                    preceded = tokens[i] === '#';
                    // If it is a token (by itself), strip it...
                    if (preceded) tokens[i] = '';
                    // It is contains escapes, adjust it...
                    tokens[i] = tokens[i].replace(/([^\\])\\#/g, '$1#');
                }
                //return tokens.join(''); // Should be updated now...
                parsed.push(...tokens);
            } catch (err) {
                throw new TextDataError(err, html, data, 'Error parsing text or inserting data values');
            }
        }
        if (isLoopy)
            parsed.push(
                TextWidget.insertValuesIntoText(looper[3], data, numberFormat, locale, noDataText, useHtmlForNoData)
            );
        return parsed.join('');
    };

    static getIndicatorsFromWidget = (textWidgetDesign, dataProvider = null) => {
        let indicators = [
                ...(textWidgetDesign.indicators !== undefined && textWidgetDesign.indicators !== null
                    ? textWidgetDesign.indicators
                    : [])
            ],
            comps = [];
        if (textWidgetDesign.text !== undefined && textWidgetDesign.text !== null) {
            const txtArgs = TextWidget.getIndicatorsFromText(textWidgetDesign.text, dataProvider);
            indicators = indicators.concat(txtArgs.indicators);
            comps = comps.concat(txtArgs.comparisons);
        }
        if (textWidgetDesign.titleText !== undefined && textWidgetDesign.titleText !== null) {
            const titleArgs = TextWidget.getIndicatorsFromText(textWidgetDesign.titleText, dataProvider);
            indicators = indicators.concat(titleArgs.indicators);
            comps = comps.concat(titleArgs.comparisons);
        }
        if (textWidgetDesign.rules !== undefined && textWidgetDesign.rules !== null) {
            for (let r of textWidgetDesign.rules) {
                const ruleArgs = TextWidget.getIndicatorsFromText(r.text, dataProvider),
                    keyArgs = TextWidget.getIndicatorsFromText(r.key, dataProvider),
                    valueArgs = TextWidget.getIndicatorsFromText(r.value, dataProvider);
                indicators = indicators
                    .concat(ruleArgs.indicators)
                    .concat(keyArgs.indicators)
                    .concat(valueArgs.indicators);
                comps = comps.concat(ruleArgs.comparisons).concat(keyArgs.comparisons).concat(valueArgs.comparisons);
            }
        }
        // Make comparisons easy...
        indicators = indicators.map((i) => JSON.stringify(i));
        indicators = [...new Set(indicators)];
        indicators = indicators.map((i) => JSON.parse(i));
        comps = [...new Set(comps)];
        return {
            comparisons: comps,
            indicators
        };
    };

    static getIndicatorsFromText = (text, dataProvider = null) => {
        const tokens =
                text !== undefined && text !== null
                    ? getHtmlFromText(text)
                          .split(/[^\\]?([#])/g)
                          .filter((t) => t !== '}')
                    : [],
            indicators = [];
        let valueKey,
            valueInd,
            start,
            end,
            iRef,
            iAlready,
            preceded = false,
            comparisonsRef = [],
            isSemiDelimited;
        for (let i = 0; i < tokens.length; i++) {
            if (preceded) {
                // Escaped? If not , do something...
                if (i === 0 || tokens[i - 1].charAt(tokens[i - 1].length - 1) !== '\\') {
                    valueKey = tokens[i].substring(0, tokens[i].indexOf('{')).toUpperCase();
                    if (valueKey.substring(0, 1) === 'I') {
                        start = tokens[i].indexOf('{');
                        end = tokens[i].indexOf('}', start);
                        if (end < 0) end = tokens[i].length;
                        isSemiDelimited = tokens[i].indexOf(';') > 0 && tokens[i].indexOf(';') < end;
                        valueInd = tokens[i]
                            .substring(start + 1, end)
                            .split(isSemiDelimited ? ';' : ',')
                            .map((t) => {
                                return t.trim();
                            });
                        if (
                            valueInd[0].indexOf('catalog:') === 0 ||
                            valueInd[0].indexOf('datastore:') === 0 ||
                            valueInd[0].indexOf('datacatalog:') === 0
                        ) {
                            iRef = {
                                id: valueInd[0].split(':')[1],
                                type: 'catalog',
                                latest: valueInd.length > 2 ? 0 : 1,
                                dates: valueInd.length > 2 ? [valueInd[2]] : undefined
                            };
                        } else {
                            const numId = parseInt(valueInd[0]),
                                idParts = valueInd[0].split(':');
                            // #I(L)VALUE{<ind-index>,<geo-index>,<?>,<format>}
                            iRef = {
                                id: idParts.length > 1 ? idParts[1] : !isNaN(numId) ? `I${numId}G` : valueInd[0],
                                type: 'direct',
                                latest: valueKey === 'ILVALUE' ? 1 : 0,
                                dates: valueInd.length > 2 && isNaN(numId) ? [valueInd[2]] : undefined
                            };
                            // Numeric, with no other clues...
                            if (!isNaN(numId)) {
                                iRef.arrayOffset = numId;
                                iRef.offset = numId;
                                if (numId < 1000 && dataProvider !== null && dataProvider.available) {
                                    // Try and match by _simple_ offset...
                                    // This is a legacy of old code in RB2.0 - normally we shouldn't hit this...
                                    let count = 0,
                                        lastCount = 0,
                                        off = 0;
                                    for (let ds of dataProvider.sources) {
                                        if (ds.config.offset) off = ds.config.offset;
                                        if (ds.availableData !== undefined) {
                                            count += ds.availableData.filter(
                                                (f) =>
                                                    f.field === undefined ||
                                                    (f.field !== undefined &&
                                                        f.field.substring(0, 6).toLowerCase() !== 'shape_')
                                            ).length;
                                        }
                                        if (numId <= count) {
                                            iRef.offset = off + numId - lastCount + 1; // Visually, offset or index is 1-based, but not in code
                                            iRef.arrayOffset = numId - lastCount;
                                            if (ds.availableData[iRef.arrayOffset].field !== undefined) {
                                                iRef.field = ds.availableData[iRef.arrayOffset].field;
                                                iRef.swap = [
                                                    `#${valueKey}\\{${numId}\\}`,
                                                    `#${valueKey}{${iRef.field}}`
                                                ];
                                                delete iRef.offset;
                                            } else
                                                iRef.swap = [
                                                    `#${valueKey}\\{${numId}\\}`,
                                                    `#${valueKey}{${iRef.offset}}`
                                                ];
                                            break;
                                        }
                                        lastCount = count;
                                    }
                                }
                                //iRef.latest = 1;
                            } else iRef.field = valueInd;
                        }
                        const iid = iRef.id,
                            ilatest = iRef.latest;
                        if (
                            (iAlready = indicators.find((ii) => ii.id === iid && ii.latest === ilatest)) !== undefined
                        ) {
                            if (iAlready.dates !== undefined && iRef.dates !== undefined)
                                iAlready.dates = [...iAlready.dates, ...iRef.dates];
                            else if (iRef.dates !== undefined) iAlready.dates = [...iRef.dates];
                        } else if (!containsRef(indicators, iRef)) indicators.push(iRef);
                        if (valueInd.length > 1 && valueInd[1].substring(0, 2) === 'C:') {
                            const compId = valueInd[1].substring(2);
                            if (comparisonsRef.indexOf(compId) < 0) comparisonsRef.push(compId);
                        }
                    }
                }
            }
            preceded = tokens[i] === '#';
        }
        //console.log(indicators); // DEBUG
        return {
            comparisons: comparisonsRef,
            indicators
        };
    };
}

class TextDataError extends Error {
    constructor(rootCause, text = '', dataSources = [], ...params) {
        super(params);
        this.rootCause = rootCause;
        this.text = text;
        this.dataSources = [...dataSources];
        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, TextDataError);
        }
    }
}

class TextBoxWidget extends TextWidget {
    static getDefaults = () => {
        return {
            ...TextWidget.getDefaults(),
            borderStyle: 'none',
            borderRadius: '2px',
            borderWidth: '0px',
            borderColor: '#666666',
            messagePadding: '5px',
            messageBackground: '',
            titleText: '',
            titlePadding: '5px',
            titleBackground: ''
        };
    };
}

const containsRef = (list, item) => {
    const j = JSON.stringify(item);
    return list.find((i) => JSON.stringify(i) === j) !== undefined;
};

const getHtmlFromText = (rawText, locale = 'en') => {
    if (rawText !== undefined && rawText !== null) {
        const now = new Date();
        let subbedText = rawText.toString();
        while (/(?:#TODAY|#NOW){([yMdHhms-]*)}/.test(subbedText)) {
            subbedText = subbedText.replace(
                /(?:#TODAY|#NOW){([yMdHhms-]*)}/,
                formatDateCustom(now, /(?:#TODAY|#NOW){([yMdHhms-]*)}/.exec(subbedText)[1])
            );
        }
        return subbedText // TODO - clean regex...
            .replace(
                /#TODAY/g,
                now.toLocaleDateString(locale, {
                    dateStyle: 'full'
                })
            )
            .replace(
                /#NOW/g,
                now.toLocaleString(locale, {
                    dateStyle: 'full'
                })
            );
    } else return '';
};

const replaceIndicatorKeys = (text = '', requestedIndicators = []) => {
    let outText = text.toString();
    for (let i of requestedIndicators) {
        if (i.swap !== undefined) {
            const rgx = new RegExp(i.swap[0], 'g');
            outText = outText.replace(rgx, i.swap[1]);
        }
    }
    return outText;
};

const findFirstDataWithIndicators = (dataArray) => {
    return dataArray.find((d) => d.indicators !== undefined && d.indicators !== null && d.indicators.length > 0);
};

export { TextWidget, TextBoxWidget };
