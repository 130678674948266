import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { setPageState, setPageReport } from '../redux/builderStore';
import { useAppDispatch, useAppSelector, RootState } from '../redux/builderStoreHooks';
import { Link } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { hyphenate, isNullOrUndefined } from '../utils/object';
//import { findCatalogTable } from 'api/catalogUtils';
import { ArcGISPortal, ReportDataSource } from 'data-catalog-js-api';
//import WelcomeDialog from 'components/WelcomeDialog';
import { RB_APP_VIEW_ONLY_AUTH_ID } from '../utils/appUtilsAndConstants';
import { parseCssStyles, addClass, removeClass } from '../utils/dom';
import { redirectToSignIn } from '../utils/arcgis';
import { getReportDesign, isSameDomain } from '../utils/arcgis-network';
import {
    migrateDesign,
    findIndicatorRefs,
    injectStyles,
    getNamedSize
} from '../utils/report-migrate-plus';
import '../assets/styles/pages/View.scss';
import '../assets/styles/pages/ViewIndex.scss';
import ProgressMessage from '../components/ProgressMessage';
import ArcWebMap from '../components/ArcWebMap';
import { bindMapClickNavigation, bindMapFeatureTooltips } from '../utils/arcgis';

import '@esri/calcite-components/dist/components/calcite-icon';
import '@esri/calcite-components/dist/components/calcite-link';
import '@esri/calcite-components/dist/components/calcite-notice';
import '@esri/calcite-components/dist/components/calcite-pagination';
import {
    CalciteIcon,
    CalciteLink,
    CalciteNotice,
    CalcitePagination
} from '@esri/calcite-components-react';

const QUERY = '(prefers-reduced-motion: reduce)';
const prefersReducedMotion = window.matchMedia(QUERY).matches;

type NamedItem = {
    id: string;
    name: string | undefined;
};

type ReportViewIndexPageArgs = {
    dataMode?: string | undefined;
    staticUrl?: string | undefined;
    windowMode?: 'standalone' | 'embedded' | undefined;
};

const ReportViewIndexPage = ({
    dataMode = 'live',
    staticUrl = '',
    windowMode = 'standalone'
}: ReportViewIndexPageArgs) => {
    // Redux mapping for app level variables
    const token: string = useAppSelector((state: RootState) => state.appSettings.token),
        portalUrl: string = useAppSelector((state: RootState) => state.appSettings.portalUrl),
        //portalHome: string = useAppSelector((state: RootState) => state.appSettings.portalHome),
        portalType: string = useAppSelector((state: RootState) => state.appSettings.portalType),
        //appAuthId: string = useAppSelector((state: RootState) => state.appSettings.appAuthId),
        appHome: string = useAppSelector((state: RootState) => state.appSettings.appHome),
        user: any = useAppSelector((state: RootState) => state.appSettings.user),
        //title: any = useAppSelector((state: RootState) => state.pageTitle),
        reportItem: any = useAppSelector((state: RootState) => state.report); //,
    //tokenManager: any = useAppSelector((state: RootState) => state.applicationState.tokenManager);

    const windowHash: string | undefined = window.location.hash,
        hasPageArg: boolean = /^#page[0-9]+$/.test(windowHash),
        startPage = hasPageArg ? parseInt(windowHash.replace(/[^0-9]/g, '')) - 1 : 0,
        [loading, setLoading] = useState<boolean>(false),
        [features, setFeatures] = useState<NamedItem[]>([]),
        [featureServiceUrl, setFeatureServiceUrl] = useState<string>(''),
        [maxFeatureCount, setMaxFeatureCount] = useState<number>(0),
        [featureOffset, setFeatureOffset] = useState<number>(0),
        [error, setError] = useState<any>(null),
        [modal, setModal] = useState<any>({}),
        abortController = useRef<AbortController>(),
        { id: reportId } = useParams<any>(),
        history = useHistory(),
        location = useLocation(),
        dispatch = useAppDispatch(),
        webMap = useRef<any>(),
        intl = useIntl(),
        isInErrorState = !isNullOrUndefined(error),
        featureListSize: number = 250;

    useEffect(() => {
        const onPageStart = async (forceUpdate = false) => {
            const anon = isNullOrUndefined(token) || token.length < 10,
                rp = anon ? { f: 'json' } : { f: 'json', token: token };
            if (
                forceUpdate ||
                isNullOrUndefined(reportItem) ||
                isNullOrUndefined(reportItem.info) ||
                isNullOrUndefined(reportItem.info.id) ||
                reportItem.info.id !== reportId
            ) {
                if (dataMode === 'static') {
                    const designUrl = `${staticUrl.replace(/\/$/, '')}/${reportId}/__index.json`;
                    fetch(designUrl).then((rsp) => {
                        rsp.json().then((reportInfo) => {
                            document.title = intl.formatMessage(
                                {
                                    id: 'view.titleFormat',
                                    defaultMessage:
                                        '{reportTitle} | View | Report Builder for ArcGIS'
                                },
                                {
                                    reportTitle: reportInfo.title
                                }
                            );
                            dispatch(
                                setPageReport({
                                    info: reportInfo,
                                    data: {
                                        values: {
                                            webmap: reportInfo.webmap
                                        }
                                    },
                                    report: {
                                        ...reportInfo.report
                                    }
                                })
                            ); // Should then trigger another update...
                        });
                    });
                } else {
                    try {
                        const itemDesc = await ArcGISPortal.getItemDescription(
                                reportId,
                                rp,
                                portalUrl
                            ),
                            itemData = await ArcGISPortal.getItemData(reportId, rp, portalUrl);
                        document.title = `View | ${itemDesc.title} | Report Builder for ArcGIS`;
                        const reportDesign = await getReportDesign(
                            itemDesc,
                            itemData,
                            token,
                            portalUrl
                        );
                        //console.log(reportDesign); // DEBUG
                        reportDesign.id = itemDesc.id;
                        const updatedDesign = migrateDesign(reportDesign);
                        // Web map?
                        if (
                            !isNullOrUndefined(itemData.values) &&
                            !isNullOrUndefined(itemData.values.webmap)
                        ) {
                            ArcGISPortal.getItemData(itemData.values.webmap, rp, portalUrl)
                                .then((mapItemData) => {
                                    dispatch(
                                        setPageReport({
                                            info: itemDesc,
                                            data: itemData,
                                            report: updatedDesign.report,
                                            webmap: mapItemData
                                        })
                                    ); // Should then trigger another update...
                                })
                                .catch((mapDataError) => {
                                    //console.log(mapDataError); // DEBUG
                                    dispatch(
                                        setPageReport({
                                            info: itemDesc,
                                            data: itemData,
                                            report: updatedDesign.report,
                                            webmap: null
                                        })
                                    ); // Should then trigger another update...
                                });
                        } else {
                            dispatch(
                                setPageReport({
                                    info: itemDesc,
                                    data: itemData,
                                    report: updatedDesign.report
                                })
                            ); // Should then trigger another update...
                        }
                    } catch (itemErr: any) {
                        const hashQs = new URLSearchParams(
                            window.location.hash !== undefined && window.location.hash !== null
                                ? window.location.hash
                                : ''
                        );
                        const coreErr =
                            !isNullOrUndefined(itemErr) && !isNullOrUndefined(itemErr.error)
                                ? itemErr.error
                                : itemErr;
                        if (
                            !isNullOrUndefined(coreErr) &&
                            !isNullOrUndefined(coreErr.code) &&
                            (coreErr.code === 403 ||
                                coreErr.code === 498 ||
                                coreErr.code === 499) &&
                            (hashQs.get('error') || '') !== 'access_denied'
                        ) {
                            // Signed in already? Just echo the error...
                            if (
                                !isNullOrUndefined(user) &&
                                !isNullOrUndefined(token) &&
                                token.length > 0
                            ) {
                                console.log(coreErr); // DEBUG
                                setLoading(false);
                                setError(coreErr);
                            } else {
                                redirectToSignIn({
                                    appAuthId: RB_APP_VIEW_ONLY_AUTH_ID, // Hard-wired - view-only sign in...
                                    portalUrl
                                });
                            }
                        } else {
                            console.log(coreErr); // DEBUG
                            setLoading(false);
                            setError(itemErr);
                        }
                    }
                }
            } else dispatch(setPageReport(reportItem)); // Should then trigger another update...
        };
        setLoading(true);
        // Clear...and establish a controller that can kill all the pending requests
        abortController.current = new AbortController();
        setError(null);
        if (portalUrl !== undefined && portalUrl !== null && portalUrl !== '') {
            onPageStart(true);
        }
        setPageState(`Index | Item | Report Builder for ArcGIS`, undefined, null, null);
        if (windowMode === 'standalone') window.scrollTo(0, 0); // Reset on initial mount
        addClass(document.body, windowMode === 'embedded' ? 'rb-view-embedded' : 'rb-view-page');
        return () => {
            if (abortController.current !== undefined) abortController.current.abort();
            removeClass(document.body, 'rb-view-page');
            removeClass(document.body, 'rb-view-embedded');
            if (
                webMap.current !== undefined &&
                webMap.current !== null &&
                webMap.current.destroy !== undefined
            )
                webMap.current.destroy();
        };
    }, [
        reportId,
        windowMode,
        dataMode,
        dispatch,
        portalType,
        portalUrl,
        user,
        intl,
        token,
        staticUrl // Unlikely to change, but has an impact if does
    ]);

    const oldReportItem: any = usePrevious(reportItem),
        oldId: string =
            oldReportItem !== undefined && oldReportItem.info !== undefined
                ? oldReportItem.info.id
                : '',
        oldFeatureOffset: number | undefined = usePrevious(featureOffset),
        reportExists: boolean =
            reportItem !== undefined &&
            reportItem !== null &&
            reportItem.info !== undefined &&
            reportItem.info.id === reportId,
        reportHasChanged: boolean =
            reportExists && (oldReportItem === undefined || oldId !== reportItem.info.id),
        offsetChanged: boolean =
            oldFeatureOffset === undefined || oldFeatureOffset !== featureOffset;
    useEffect(() => {
        if (!isInErrorState && reportExists && (reportHasChanged || offsetChanged)) {
            // Change in the report?
            const { report, info, webmap } = reportItem,
                stamp = new Date().getTime().toFixed(0);
            // CSS - this is legacy model, might change?
            injectStyles(
                report.design,
                windowMode === 'embedded' ? { url: `${appHome}/view-report/` } : null,
                '/view-report/'
            );
            // Got the report, now see if there are data sources to populate the on-screen items...
            if (dataMode === 'static') {
                const coreFeatureUrl = `${staticUrl.replace(/\/$/, '')}/${info.id}/__features.json`;
                fetch(coreFeatureUrl)
                    .then((featureRsp) => {
                        featureRsp.json().then((featureSet) => {
                            setLoading(false);
                            setFeatures([...featureSet.features]);
                            setFeatureServiceUrl(featureSet.src);
                        });
                    })
                    .catch((err) => {
                        //console.log(err); // DEBUG
                        setLoading(false);
                        setError(err);
                    });
            } else if (!isNullOrUndefined(report.data) && !isNullOrUndefined(report.data.sources)) {
                const coreSrc = report.data.sources[0],
                    idf = report.design.idField,
                    nmf = report.design.nameField,
                    outf = [idf, nmf],
                    requiredIndicators = findIndicatorRefs(report.design),
                    fetchInitArgs =
                        abortController.current !== undefined
                            ? { signal: abortController.current.signal }
                            : undefined;
                // Spin up a data provider as a parallel task because this allows caching of requests by the browser and ArcGIS Online/Server
                setTimeout(() => {
                    new ReportDataSource(
                        report.data.sources,
                        idf,
                        nmf,
                        token,
                        portalUrl,
                        requiredIndicators,
                        undefined
                    )
                        .init()
                        .catch((dataErr) => {
                            //console.log(dataErr); // DEBUG
                            setLoading(false);
                            setError(dataErr);
                        });
                }, 1000);
                ArcGISPortal.queryFeatureCount(
                    coreSrc.url,
                    '1=1',
                    {
                        f: 'json',
                        token: token
                    },
                    fetchInitArgs
                )
                    .then((featureCountRsp) => {
                        ArcGISPortal.queryFeatures(
                            coreSrc.url,
                            '1=1',
                            featureListSize,
                            {
                                f: 'json',
                                token: isSameDomain(coreSrc.url, portalUrl) ? token : undefined,
                                outFields: outf,
                                orderByFields: nmf,
                                resultOffset: featureOffset
                            },
                            true,
                            fetchInitArgs
                        )
                            .then((featuresRsp) => {
                                const featureArray = featuresRsp.features,
                                    fidf = featuresRsp.fields.find(
                                        (ff) => ff.name.toLowerCase() === idf.toLowerCase()
                                    ).name,
                                    fnmf = featuresRsp.fields.find(
                                        (ff) => ff.name.toLowerCase() === nmf.toLowerCase()
                                    ).name,
                                    flist: NamedItem[] = [];
                                for (let f of featureArray) {
                                    flist.push({
                                        id: f.attributes[fidf].toString(),
                                        name: f.attributes[fnmf]
                                    });
                                }
                                setLoading(false);
                                setFeatures(flist);
                                setFeatureServiceUrl(coreSrc.url);
                                setMaxFeatureCount(featureCountRsp.count);
                            })
                            .catch((err) => {
                                //console.log(err); // DEBUG
                                setLoading(false);
                                setError(err);
                            });
                    })
                    .catch((countErr) => {
                        //console.log(countErr); // DEBUG
                        setLoading(false);
                        setError(countErr);
                    });
            } else {
                console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!'); // DEBUG
                console.log(report); // DEBUG
                throw new Error('Cannot access data sources');
            }
        } else {
            //const activePage = document.getElementById(`page${info.id}P${(page + 1)}`);
            //if (!isNullOrUndefined(activePage)) addClass(activePage, 'active');
            // TODO - some other global handlers to deal with intra-report page navigation when the report is "paged"
        }
    });

    const onMapStart = useCallback(
        (mapEvent, arcGisUtils) => {
            const { report } = reportItem;
            if (!isNullOrUndefined(mapEvent.view) && !isNullOrUndefined(featureServiceUrl)) {
                const matchUrl = featureServiceUrl.toLowerCase(),
                    clickableLayer = mapEvent.view.map.allLayers.find(
                        (fl) =>
                            fl.type === 'feature' &&
                            fl.url !== undefined &&
                            fl.url !== null &&
                            (fl.url.toLowerCase() === featureServiceUrl ||
                                `${fl.url}/${fl.layerId}`.toLowerCase() === matchUrl)
                    );
                if (!isNullOrUndefined(clickableLayer)) {
                    clickableLayer.popupEnabled = false;
                    clickableLayer.outFields = [report.design.idField, report.design.nameField];
                    const ttf =
                        !isNullOrUndefined(report.design.index) &&
                        !isNullOrUndefined(report.design.index.tooltipFormat) &&
                        report.design.index.tooltipFormat !== ''
                            ? report.design.index.tooltipFormat
                                  .replace(/\{NAME\}/g, `$feature.${report.design.nameField}`)
                                  .replace(/\{([0-9a-zA-Z_]+)\}/g, '$feature.$1')
                            : report.design.nameField;
                    bindMapClickNavigation(
                        mapEvent.view,
                        clickableLayer,
                        report.design.idField,
                        report.design.nameField,
                        `/view-${dataMode === 'static' ? 'publication' : 'report'}/${
                            report.id
                        }/#ID`,
                        history
                    );
                    bindMapFeatureTooltips(
                        mapEvent.view,
                        clickableLayer,
                        ttf,
                        undefined,
                        report.locale,
                        undefined,
                        'pointer'
                    );
                    mapEvent.view.whenLayerView(clickableLayer).then((lv) => {
                        mapEvent.view.goTo(clickableLayer.fullExtent, {
                            animate: !prefersReducedMotion
                        });
                    });
                }
                webMap.current = mapEvent.view.map;
            }
        },
        [featureServiceUrl, reportHasChanged, dataMode, history]
    );

    const anon = isNullOrUndefined(token) || token.length < 10,
        hosted = !isNullOrUndefined(portalType) && portalType.toLowerCase() !== 'online',
        qs = new URLSearchParams(window.location.search),
        hasActiveDesign =
            !isNullOrUndefined(reportItem) &&
            !isNullOrUndefined(reportItem.info) &&
            !isNullOrUndefined(reportItem.report) &&
            !isNullOrUndefined(reportItem.report.design),
        { info: itemInfo, report, data: itemData } = reportItem,
        item = hasActiveDesign ? reportItem : {},
        nFeatures = !isNullOrUndefined(features)
            ? features.filter((f) => f.id.substring(0, 1) !== '#').length
            : 0,
        nPerCol = Math.ceil((nFeatures * 1.0) / 3.0),
        vis: any[] = [],
        pageBorder =
            hasActiveDesign && !isNullOrUndefined(report.design.pageStyles.border)
                ? report.design.pageStyles.border.toLowerCase()
                : 'standard',
        mapOnly =
            hasActiveDesign &&
            !isNullOrUndefined(report.design.pageStyles.index) &&
            !isNullOrUndefined(report.design.pageStyles.index.layout) &&
            (hyphenate(report.design.pageStyles.index.layout) === 'map-only' ||
                hyphenate(report.design.pageStyles.index.layout) === 'map-full-screen'),
        linksOnly =
            hasActiveDesign &&
            !isNullOrUndefined(report.design.pageStyles.index) &&
            !isNullOrUndefined(report.design.pageStyles.index.layout) &&
            hyphenate(report.design.pageStyles.index.layout) === 'links-only',
        showDesc =
            hasActiveDesign &&
            !isNullOrUndefined(report.design.pageStyles.index) &&
            !isNullOrUndefined(report.design.pageStyles.index.description) &&
            hyphenate(report.design.pageStyles.index.description) !== 'no',
        containerClass = mapOnly
            ? hyphenate(report.design.pageStyles.index.layout) === 'map-full-screen'
                ? 'map-only map-full-screen'
                : 'map-only'
            : 'map-and-links',
        fullScreenResponsive =
            hasActiveDesign &&
            report.design.pageStyles.responsive !== undefined &&
            report.design.pageStyles.responsive.expandToFill === true
                ? 'expand-responsive'
                : '';
    if (!isNullOrUndefined(featureServiceUrl)) vis.push(featureServiceUrl);
    let featureList: any = null,
        styles = {},
        wrapperStyles = {};
    if (hasActiveDesign && nFeatures > 0) {
        if (!isNullOrUndefined(report.design.cssStyle))
            styles = parseCssStyles(report.design.cssStyle);
        if (!isNullOrUndefined(report.design.pageStyles.size)) {
            if (typeof report.design.pageStyles.size === 'string')
                styles = { ...styles, ...getNamedSize(report.design.pageStyles.size) };
            else styles = { ...styles, ...report.design.pageStyles.size };
        }
        if (!isNullOrUndefined(report.design.pageStyles.margin))
            wrapperStyles = { ...wrapperStyles, margin: report.design.pageStyles.margin };
        //else wrapperStyles = { ...wrapperStyles, margin: '10px' };
        if (!mapOnly) {
            let children: any[] = [];
            const coreFeatures = features.filter((f) => f.id.substring(0, 1) !== '#');
            for (let i = 0; i < 3; i++) {
                children.push(
                    coreFeatures.slice(i * nPerCol, i * nPerCol + nPerCol).map((f, j) => {
                        return (
                            <li key={j} data-feature-id={f.id}>
                                <Link
                                    to={`/view-${
                                        dataMode === 'static' ? 'publication' : 'report'
                                    }/${item.info.id}/${f.id}`}
                                    className={`geo-feature-link`}
                                >
                                    <CalciteIcon icon="pin" scale="s"></CalciteIcon>
                                    {f.name}
                                </Link>
                            </li>
                        );
                    })
                );
            }
            featureList = (
                <div className="row feature-links-list">
                    <div className="col-md-4">
                        <ul>{children[0]}</ul>
                    </div>
                    <div className="col-md-4">
                        <ul>{children[1]}</ul>
                    </div>
                    <div className="col-md-4">
                        <ul>{children[2]}</ul>
                    </div>
                </div>
            );
        }
    }
    // Edit, bail (using harsh non-React mechanism)
    if (
        qs.has('edit') &&
        qs.get('edit') !== undefined &&
        qs.get('edit') !== null &&
        (qs.get('edit') || '').toString().toLowerCase() === 'true'
    ) {
        setLoading(true);
        window.location.replace(window.location.pathname.replace('/view-report/', '/edit-report/'));
    }
    return loading ? (
        <div className="viewer">
            <div className="progress-big">
                <ProgressMessage message="" />
            </div>
        </div>
    ) : (
        <>
            <div className="viewer">
                {!isNullOrUndefined(error) ? (
                    !isNullOrUndefined(error.code) && error.code === 403 ? (
                        <div className="fatal-error">
                            <CalciteNotice
                                icon="circle-disallowed"
                                scale="l"
                                open={true}
                                kind="danger"
                                width="half"
                            >
                                <div slot="title">
                                    <FormattedMessage
                                        id="view.reportForbidden.title"
                                        defaultMessage="Permission denied"
                                    />
                                </div>
                                <div slot="message">
                                    <FormattedMessage
                                        id="view.reportForbidden.message"
                                        defaultMessage="You{user} do not have permission to view report {name}. If you think you should have access please try opening {arclink} on ArcGIS Online to check and/or {authlink}."
                                        values={{
                                            name: (
                                                <strong>
                                                    {!isNullOrUndefined(itemInfo)
                                                        ? itemInfo.title
                                                        : reportId}
                                                </strong>
                                            ),
                                            user: !isNullOrUndefined(user) ? (
                                                <span title={user.username}>
                                                    {' '}
                                                    ({user.fullName})
                                                </span>
                                            ) : (
                                                <></>
                                            ),
                                            arclink: (
                                                <CalciteLink
                                                    href={`https://www.arcgis.com/home/item.html?=${reportId}`}
                                                >
                                                    {!isNullOrUndefined(itemInfo)
                                                        ? itemInfo.title
                                                        : reportId}
                                                </CalciteLink>
                                            ),
                                            authlink: (
                                                <CalciteLink
                                                    href={`${(portalType.toLowerCase() !== 'online'
                                                        ? portalUrl
                                                        : 'https://www.arcgis.com/sharing/rest'
                                                    ).replace(
                                                        /^(.*)\/$/,
                                                        '$1'
                                                    )}/oauth2/authorize?client_id=${RB_APP_VIEW_ONLY_AUTH_ID}&response_type=token&redirect_uri=${encodeURIComponent(
                                                        window.location.href
                                                    )}`}
                                                >
                                                    <FormattedMessage
                                                        id="view.reportForbidden.signInLink"
                                                        defaultMessage="sign in as a different user"
                                                    />
                                                </CalciteLink>
                                            ),
                                            error: (
                                                <span>
                                                    <br />
                                                    <br />
                                                    <code>
                                                        {error.code} {error.message}
                                                    </code>
                                                </span>
                                            )
                                        }}
                                    />
                                </div>
                            </CalciteNotice>
                        </div>
                    ) : (
                        <div className="bg-warning ia-report-fail-message">
                            <p>
                                <FormattedMessage
                                    id="view.reportFail"
                                    defaultMessage="{icon} Report Builder for ArcGIS encountered an unexpected error when opening report {name}. The error details are shown below - please review and try again. If this problem is persistent please contact the owner{owner} of this report. {error}"
                                    values={{
                                        icon: (
                                            <i
                                                aria-hidden="true"
                                                className="fas fa-times-circle fa-3x fa-pull-left"
                                            ></i>
                                        ),
                                        name: (
                                            <strong>
                                                {!isNullOrUndefined(itemInfo)
                                                    ? itemInfo.title
                                                    : reportId}
                                            </strong>
                                        ),
                                        owner:
                                            !isNullOrUndefined(itemInfo) &&
                                            !isNullOrUndefined(itemInfo.owner) ? (
                                                <span>
                                                    &nbsp;
                                                    <a
                                                        href={`https://www.arcgis.com/home/user.html?user=${itemInfo.owner}`}
                                                        target="iaArcWindow"
                                                    >
                                                        ({itemInfo.owner}
                                                        <i
                                                            aria-hidden="true"
                                                            className="fas fa-external-link-alt"
                                                        ></i>
                                                        )
                                                    </a>
                                                </span>
                                            ) : (
                                                ''
                                            ),
                                        error: (
                                            <span>
                                                <br />
                                                <br />
                                                <code>
                                                    {error.code} {error.message}
                                                </code>
                                            </span>
                                        )
                                    }}
                                />
                            </p>
                        </div>
                    )
                ) : null}
                <div className={`ia-report-holder page-border-${pageBorder}`}>
                    {hasActiveDesign ? (
                        <article
                            id={`design_${report.id}`}
                            lang={report.design.locale}
                            className={`ia-report ia-report-index ${containerClass} ${fullScreenResponsive}`}
                        >
                            <div className="ia-report-section" style={styles}>
                                <div className="ia-report-section-wrapper" style={wrapperStyles}>
                                    <h1>{item.info.title}</h1>
                                    {vis.length > 0 && !linksOnly ? (
                                        <div className="map-container">
                                            <ArcWebMap
                                                mapId={item.data.values.webmap}
                                                visibleLayers={[...vis]}
                                                visibleImageLayers="*"
                                                activeLayer={featureServiceUrl}
                                                legend={
                                                    !isNullOrUndefined(report.design.index)
                                                        ? report.design.index.legend
                                                        : false
                                                }
                                                onMapLoad={onMapStart}
                                                showMapToolTips={true}
                                                showExportLink={false}
                                            />
                                        </div>
                                    ) : null}
                                    {!isNullOrUndefined(item.info.description) && showDesc ? (
                                        <div className="description-container">
                                            <div
                                                className="item-description"
                                                dangerouslySetInnerHTML={{
                                                    __html: item.info.description
                                                }}
                                            />
                                        </div>
                                    ) : null}
                                    {dataMode === 'live' &&
                                        features !== undefined &&
                                        features.length > 0 &&
                                        features.length < maxFeatureCount && (
                                            <nav
                                                aria-label="Page navigation"
                                                className="page-navigation"
                                            >
                                                <CalcitePagination
                                                    startItem={featureOffset + 1}
                                                    pageSize={featureListSize}
                                                    totalItems={maxFeatureCount}
                                                    onCalcitePaginationChange={(pd: any) => {
                                                        const start: number =
                                                            pd.target !== undefined &&
                                                            pd.target.startItem !== undefined
                                                                ? pd.target.startItem
                                                                : pd.startItem;
                                                        if (start !== undefined && !isNaN(start))
                                                            setFeatureOffset(start - 1);
                                                    }}
                                                ></CalcitePagination>
                                            </nav>
                                        )}
                                    <div className="feature-list-links">{featureList}</div>
                                </div>
                            </div>
                        </article>
                    ) : null}
                </div>
            </div>
        </>
    );
};

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export default ReportViewIndexPage;
