//import React, { PureComponent } from 'react';
import React, { useState, useEffect, useRef } from 'react';
import { isNullOrUndefined, isNullOrWhitespace, hyphenate } from '../utils/object';
import { parseCssStyles, addClass, hasClass, removeClass } from '../utils/dom';
import '../assets/styles/components/ReportWidget.scss';
import ArcWebMap from './ArcWebMap';
import { convertLengthToPixels, lengthWithUnit } from '../widgets/AbstractWidget';
import { TextWidget } from '../widgets/TextWidget';
import { buildDisplayText, createWidgetInstance } from '../widgets/widgetHelpers';
import { filterTableRows } from '../widgets/widgetDataUtils';
import { useAppSelector } from '../redux/builderStoreHooks';
import { DataManipulator } from 'data-catalog-js-api';
import { useHistory } from 'react-router-dom';

const ReportWidgetPanel = (props) => {
    const prevProps = usePrevious(props),
        contentElement = useRef(null),
        { tokenManager } = useAppSelector((state) => state.applicationState),
        history = useHistory();
    //const [contentElement, setContentElement] = useState(null);
    //const prevElement = usePrevious(contentElement);

    useEffect(() => {
        function onMountOrChange() {
            // TODO - make this more "Hook"y because there is some blunt change detction here that can be got rid of...
            const {
                    design,
                    feature,
                    dataAvailable = false,
                    dataProvider,
                    dataCollector,
                    locked,
                    token,
                    globals = {},
                    editable = false,
                    onClick,
                    eventTarget = '',
                    inViewport = false
                } = props,
                { locale = 'en', portalUrl, token: globalToken, idField, nameField } = globals,
                activeToken =
                    token !== undefined ? token : !isNullOrUndefined(dataProvider) ? dataProvider.token : globalToken,
                retryErrorCodes = [400, 503];
            if (dataAvailable && inViewport) {
                //if (!isNullOrUndefined(design) && ((design.locale === undefined) || (design.locale === null))) design.locale = locale;
                // Force update of locale from the report...
                if (!isNullOrUndefined(design)) design.locale = locale;
                // Overrides?
                applyGlobalOverrides(design, globals);
                let hasRef = !isNullOrUndefined(contentElement.current), // && !isNullOrUndefined(contentElement.current), // .current only applicable when createRef used in constructor
                    isStatic = hasRef && contentElement.current.getAttribute('data-static-content') === 'true',
                    renderAttempts =
                        hasRef && !isNullOrUndefined(contentElement.current.getAttribute('data-render-attempts'))
                            ? parseInt(contentElement.current.getAttribute('data-render-attempts'))
                            : 0,
                    isText =
                        design.scriptClass === 'TextBoxWidget' ||
                        design.scriptClass === 'RuleAwareTextWidget' ||
                        design.scriptClass === 'TextWidget',
                    isBoxed =
                        design.scriptClass === 'TextBoxWidget' || design.scriptClass === 'ArcGisOnlineWebMapWidget',
                    isMap = design.scriptClass === 'ArcGisOnlineWebMapWidget',
                    hasChildren = !isNullOrUndefined(design.childWidgets),
                    hasRules = isText && !isNullOrUndefined(design.rules) && design.rules.length > 0,
                    dataRefs = isText || isBoxed ? TextWidget.getIndicatorsFromWidget(design, dataProvider) : {},
                    requiredIndicators =
                        design.indicators !== undefined && design.indicators.length > 0 && !isText
                            ? [...design.indicators]
                            : (isText || isBoxed) && design.__oldIrefs !== undefined
                            ? [{ all: true }] // Old, auto-migrated, just get everything (ugly)
                            : dataRefs.indicators !== undefined
                            ? dataRefs.indicators
                            : [],
                    dataAggType =
                        design.requiredDataAggregation !== undefined &&
                        design.aggrequiredDataAggregationregation !== 'inherit'
                            ? design.requiredDataAggregation
                            : feature.length > 1
                            ? 'aggregated'
                            : undefined, // If more than 1 active feature, default behaviour is to get the aggregated value
                    dataOptions = {
                        activeFeature: feature,
                        comparisonFeatures:
                            design.showComparisons && !isNullOrWhitespace(design.comparisonFeatureIds)
                                ? design.comparisonFeatureIds
                                : dataRefs.comparisons !== undefined
                                ? dataRefs.comparisons.join(',')
                                : '',
                        token: activeToken,
                        aggregation: dataAggType,
                        aggregatedFeaturesAlias:
                            design.aggregatedFeaturesAlias || design.aggregatedAreasAlias || 'Selected Areas',
                        target: design.id,
                        sources:
                            !isNullOrUndefined(design.includeNumeratorsAndDenominators) &&
                            design.includeNumeratorsAndDenominators === true,
                        requested: [...requiredIndicators],
                        throwErrorsOut: true,
                        portalUrl,
                        tokenManager
                    };
                if (!isNullOrUndefined(idField)) {
                    dataOptions.idField = idField;
                    dataOptions.nameField = nameField;
                }
                if (renderAttempts > 0) dataOptions.cache = false; // Attempt a (soft) cache-buster via http options if we are reloading, in case the error is buried in JSON
                if (!editable && onClick !== undefined) dataOptions.onClick = onClick;
                const featureKey = design.includeAllAreas ? null : feature.map((ff) => ff.id),
                    validFeature =
                        (design.includeAllAreas && featureKey === null) ||
                        (featureKey !== null && featureKey.length > 0);
                // Data collector? Gives a simple way to take stuff offline, so throw stuff at it...
                if (!isNullOrUndefined(dataCollector) && typeof dataCollector === 'function')
                    dataCollector(design.id, featureKey, requiredIndicators, null);
                if (isText && !isStatic && !hasChildren) {
                    isStatic = !buildDisplayText(design.text, feature, globals, design.locale, '').placeholder; // Render can handle it, no need for this...
                    if (isBoxed)
                        isStatic =
                            isStatic &&
                            !buildDisplayText(design.titleText, feature, globals, design.locale, '').placeholder;
                }
                // Content is not completely handled by render()...or we are not looking at a real feature...
                if (validFeature && (!isStatic || hasChildren || hasRules) && hasRef && !locked) {
                    // ...so launch DOM updates via the widget's internal code
                    const widget = createWidgetInstance(contentElement.current, {
                        ...design,
                        includeAccessibleTable: !editable // Make some shortcuts/choices based on how we are viewing for speed/simplicity
                    });
                    //console.log(design.id + ' ' + widget.constructor.name + ' .getData()? ' + (!isNullOrUndefined(dataProvider) && dataProvider.available)); // DEBUG
                    if (!isStatic && !isMap && !hasClass(contentElement.current.parentElement, 'placeholder'))
                        addClass(contentElement.current.parentElement, 'placeholder'); //
                    (!isNullOrUndefined(dataProvider) && dataProvider.available
                        ? dataProvider.getData(featureKey, requiredIndicators, dataOptions)
                        : Promise.resolve([])
                    )
                        .then((data) => {
                            if (data.error !== undefined) throw data.error;
                            if (Array.isArray(data) && data.filter((d) => d.error !== undefined).length > 0)
                                throw new Error(data.filter((d) => d.error !== undefined).error);
                            data = filterTableRows(
                                data,
                                (design.showComparisons && !isNullOrWhitespace(design.comparisonFeatureIds)
                                    ? design.comparisonFeatureIds
                                    : dataRefs.comparisons !== undefined
                                    ? dataRefs.comparisons.join(',')
                                    : ''
                                ).split(',')
                            );
                            if (
                                design.sortFeaturesByName !== undefined &&
                                design.sortFeaturesByName === true &&
                                design.includeAllAreas
                            )
                                data.forEach((d) => (d.rowIds !== undefined ? DataManipulator.sortTable(d) : d));
                            // Data collector? Gives a simple way to take stuff offline, so throw stuff at it...
                            if (!isNullOrUndefined(dataCollector) && typeof dataCollector === 'function')
                                dataCollector(design.id, featureKey, requiredIndicators, data);
                            // Remove anything from previous run (convention required that all content is .ia-generated), but allow for render() timeout with locally loaded (fast) data sources...
                            const generated = contentElement.current.querySelectorAll('.ia-generated');
                            //console.log(`${design.scriptClass}, ${editable || (data.length > 0)}, ${contentElement}.ia-generated = ${generated.length}`); // DEBUG
                            if (editable || data.length > 0)
                                for (let i = generated.length - 1; i >= 0; i--) generated[i].remove();
                            // Only allow the render to a mounted component, or who knows what React will have done with the refs...
                            if (!isStatic || hasRules) {
                                //console.log(`-------------------------${design.scriptClass} ${design.id}? ${hasChildren} ${isStatic} ${this._isMounted}`); // DEBUG
                                if (eventTarget !== '')
                                    widget.eventSource =
                                        contentElement.current.ownerDocument.querySelector(eventTarget);
                                if (design.scriptClass !== 'ArcGisOnlineWebMapWidget' && data.length > 0) {
                                    widget.render(data, dataOptions, editable);
                                }
                            }
                            // And deal with any children - which should have been "locked" by the render code...
                            if (hasChildren) {
                                for (let cwDesign of design.childWidgets.filter(
                                    (cw) => cw.scriptClass !== 'ArcGisOnlineWebMapWidget'
                                )) {
                                    // data-uuid={design.id.replace(/[^0-9a-zA-Z]/g, '')}
                                    if (!isNullOrUndefined(cwDesign)) cwDesign.locale = locale;
                                    const cwElement = contentElement.current.querySelector(
                                            `.ia-report-widget[data-uuid="${cwDesign.id.replace(
                                                /[^0-9a-zA-Z]/g,
                                                ''
                                            )}"] > .ia-widget-inner`
                                        ),
                                        isChildText =
                                            cwDesign.scriptClass === 'TextBoxWidget' ||
                                            cwDesign.scriptClass === 'RuleAwareTextWidget' ||
                                            cwDesign.scriptClass === 'TextWidget',
                                        isChildBoxed =
                                            cwDesign.scriptClass === 'TextBoxWidget' ||
                                            cwDesign.scriptClass === 'ArcGisOnlineWebMapWidget',
                                        childDataRefs =
                                            isChildText || isChildBoxed
                                                ? TextWidget.getIndicatorsFromWidget(cwDesign, dataProvider)
                                                : {},
                                        childRequiredIndicators =
                                            cwDesign.indicators !== undefined &&
                                            cwDesign.indicators.length > 0 &&
                                            !isChildText
                                                ? [...cwDesign.indicators]
                                                : childDataRefs.indicators !== undefined
                                                ? childDataRefs.indicators
                                                : [];
                                    // Overrides?
                                    applyGlobalOverrides(cwDesign, globals);
                                    if (!isNullOrUndefined(cwElement)) {
                                        if (
                                            childRequiredIndicators.length > 0 &&
                                            cwElement.getAttribute('data-static-content') !== 'true'
                                        ) {
                                            const child = createWidgetInstance(cwElement, cwDesign),
                                                cwDataAggType =
                                                    cwDesign.requiredDataAggregation !== undefined &&
                                                    cwDesign.requiredDataAggregation !== 'inherit'
                                                        ? cwDesign.requiredDataAggregation
                                                        : feature.length > 1
                                                        ? 'aggregated'
                                                        : undefined, // If more than 1 active feature, default behaviour is to get the aggregated value
                                                childDataOptions = {
                                                    activeFeature: feature,
                                                    comparisonFeatures:
                                                        cwDesign.showComparisons &&
                                                        !isNullOrWhitespace(cwDesign.comparisonFeatureIds)
                                                            ? cwDesign.comparisonFeatureIds
                                                            : childDataRefs.comparisons !== undefined
                                                            ? childDataRefs.comparisons.join(',')
                                                            : '',
                                                    token: activeToken,
                                                    aggregation: cwDataAggType,
                                                    aggregatedFeaturesAlias:
                                                        cwDesign.aggregatedFeaturesAlias ||
                                                        cwDesign.aggregatedAreasAlias ||
                                                        'Selected Areas',
                                                    target: cwDesign.id
                                                };
                                            if (renderAttempts > 0) childDataOptions.cache = false; // Attempt a (soft) cache-buster via http options if we are reloading, in case the error is buried in JSON
                                            (!isNullOrUndefined(dataProvider) && dataProvider.available
                                                ? dataProvider.getData(
                                                      cwDesign.includeAllAreas ? null : feature.map((ff) => ff.id),
                                                      childRequiredIndicators,
                                                      childDataOptions
                                                  )
                                                : Promise.resolve([])
                                            )
                                                .then((childData) => {
                                                    if (childData.error !== undefined) throw childData.error;
                                                    if (
                                                        Array.isArray(childData) &&
                                                        childData.filter((d) => d.error !== undefined).length > 0
                                                    )
                                                        throw new Error(
                                                            childData.filter((d) => d.error !== undefined).error
                                                        );
                                                    childData = filterTableRows(
                                                        childData,
                                                        (cwDesign.showComparisons &&
                                                        !isNullOrWhitespace(cwDesign.comparisonFeatureIds)
                                                            ? cwDesign.comparisonFeatureIds
                                                            : childDataRefs.comparisons !== undefined
                                                            ? childDataRefs.comparisons.join(',')
                                                            : ''
                                                        ).split(',')
                                                    );
                                                    if (
                                                        cwDesign.sortFeaturesByName !== undefined &&
                                                        cwDesign.sortFeaturesByName === true &&
                                                        cwDesign.includeAllAreas
                                                    )
                                                        childData.forEach((d) =>
                                                            d.rowIds !== undefined ? DataManipulator.sortTable(d) : d
                                                        );
                                                    //console.log(`${design.id}: child data response, ${cwDesign.scriptClass}, child ID ${cwDesign.id}, data size = ${childData.length}`); // DEBUG
                                                    // Remove anything from previous run (convention required that all content is .ia-generated)
                                                    const childGenerated = cwElement.querySelectorAll('.ia-generated');
                                                    for (let i = childGenerated.length - 1; i >= 0; i--)
                                                        childGenerated[i].remove();
                                                    if (eventTarget !== '')
                                                        child.eventSource =
                                                            cwElement.ownerDocument.querySelector(eventTarget);
                                                    if (childData.length > 0) child.render(childData, childDataOptions);
                                                })
                                                .catch((childErr) => {
                                                    // Echo a message to the screen about the error, but mayeb allow another try
                                                    contentElement.current.setAttribute(
                                                        'data-render-attempts',
                                                        (renderAttempts + 1).toFixed(0)
                                                    );
                                                    if (
                                                        !editable &&
                                                        !isNullOrUndefined(childErr.error) &&
                                                        !isNullOrUndefined(childErr.error.code) &&
                                                        retryErrorCodes.indexOf(childErr.error.code) >= 0
                                                    ) {
                                                        if (renderAttempts < 3) {
                                                            const afterSec = 5 + renderAttempts * 2;
                                                            child.renderError(
                                                                {
                                                                    override: true,
                                                                    message: `⏳ Sorry - this widget had a problem fetching data. It will try again in ${afterSec}s...`
                                                                },
                                                                cwDesign.id,
                                                                cwDesign.scriptClass,
                                                                cwDesign.indicators
                                                            );
                                                            setTimeout(() => {
                                                                console.debug(
                                                                    'Render attempt #' + (renderAttempts + 1).toFixed(0)
                                                                );
                                                                onMountOrChange();
                                                            }, afterSec * 1000);
                                                        } else
                                                            appendRefreshMessage(
                                                                contentElement.current,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                () => {
                                                                    onMountOrChange();
                                                                }
                                                            );
                                                    } else
                                                        child.renderError(
                                                            childErr,
                                                            cwDesign.id,
                                                            cwDesign.scriptClass,
                                                            cwDesign.indicators
                                                        );
                                                });
                                        }
                                    } else console.log('⚠️ Cannot find child ' + cwDesign.id + '? ' + cwElement); // DEBUG
                                }
                            }
                        })
                        .catch((err) => {
                            // Echo a message to the screen about the error, but maybe allow another try
                            if (contentElement.current !== undefined && contentElement.current !== null) {
                                contentElement.current.setAttribute(
                                    'data-render-attempts',
                                    (renderAttempts + 1).toFixed(0)
                                );
                            }
                            if (
                                !editable &&
                                !isNullOrUndefined(err.error) &&
                                !isNullOrUndefined(err.error.code) &&
                                retryErrorCodes.indexOf(err.error.code) >= 0
                            ) {
                                if (renderAttempts < 3) {
                                    const afterSec = 5 + renderAttempts * 2;
                                    widget.renderError(
                                        {
                                            override: true,
                                            message: `⏳ Sorry - this widget had a problem fetching data. It will try again in ${afterSec}s...`
                                        },
                                        design.id,
                                        design.scriptClass,
                                        design.indicators
                                    );
                                    setTimeout(() => {
                                        console.debug('Render attempt #' + (renderAttempts + 1).toFixed(0));
                                        onMountOrChange();
                                    }, afterSec * 1000);
                                } else
                                    appendRefreshMessage(
                                        contentElement.current,
                                        undefined,
                                        undefined,
                                        undefined,
                                        () => {
                                            onMountOrChange();
                                        }
                                    );
                            } else widget.renderError(err, design.id, design.scriptClass, design.indicators);
                        });
                } else if (hasRef) {
                    const generated = contentElement.current.querySelectorAll('.ia-generated');
                    for (let i = generated.length - 1; i >= 0; i--) generated[i].remove();
                }
            } else if (inViewport) console.log(`No data available for ${design.scriptClass}#${design.id}?`); // DEBUG
        }
        // Don't like this, but for now, will rely on a timestamp or GUID to control render - faster than full on comparison... (prevProps.timestamp !== this.props.timestamp) ||
        const editChange =
                isNullOrUndefined(prevProps) ||
                prevProps.design.timestamp !== props.design.timestamp ||
                prevProps.design.id !== props.design.id ||
                prevProps.locale !== props.locale ||
                prevProps.design.locale !== props.design.locale,
            geoChange =
                isNullOrUndefined(prevProps) ||
                (isNullOrUndefined(prevProps.feature) && !isNullOrUndefined(props.feature)) ||
                prevProps.feature.length !== props.feature.length ||
                prevProps.feature.map((f) => f.id).join('|') !== props.feature.map((f) => f.id).join('|'),
            dataChange =
                isNullOrUndefined(prevProps) ||
                (!isNullOrUndefined(prevProps.dataAvailable) &&
                    !isNullOrUndefined(props.dataAvailable) &&
                    prevProps.dataAvailable !== props.dataAvailable),
            htmlChange = false,
            // (isNullOrUndefined(prevElement) && !isNullOrUndefined(contentElement)) ||
            // prevElement !== contentElement,
            viewportChange = isNullOrUndefined(prevProps) || prevProps.inViewport !== props.inViewport;
        //if (!isNullOrUndefined(this.props.design.childWidgets) && (this.props.design.childWidgets.length > 0)) console.log(`ReportWidgetPanel.${this.props.design.id}.componentDidUpdate geo-change=${geoChange} edit=${editChange} timestamps=${this.props.design.timestamp} ?> ${prevProps.design.timestamp}`); // DEBUG
        //if (props.design !== undefined) console.log(`ReportWidgetPanel.${props.design.scriptClass}.componentDidUpdate: edit=${editChange}, geo=${geoChange}, data=${dataChange}`); // DEBUG
        if (editChange || geoChange || dataChange || htmlChange || viewportChange) onMountOrChange();
        // else if (contentElement !== null && hasClass(contentElement.parentElement, 'placeholder'))
        //     removeClass(contentElement.parentElement, 'placeholder');
    });

    const {
            design,
            feature,
            dataProvider,
            dataAvailable,
            className = '',
            editable = false,
            onClick,
            globals = {},
            pageSize = null,
            inViewport = false,
            mode = 'normal',
            ...others
        } = props,
        { locale = 'en', portalUrl, token } = globals,
        widgetClassName = !isNullOrUndefined(design) && !isNullOrUndefined(design.cssClass) ? design.cssClass : '',
        isTextBased =
            design.scriptClass === 'RuleAwareTextWidget' ||
            design.scriptClass === 'TextWidget' ||
            design.scriptClass === 'TextBoxWidget',
        hasChildren = isTextBased && !isNullOrUndefined(design.childWidgets) && design.childWidgets.length > 0;

    // TODO - (well TOFINISH) nitty gritty here is to decide on some content based on the type of widget - maybe handle here, maybe have custom React components?
    const {
        scriptClass,
        backgroundColor,
        rules,
        messageBackground,
        messagePadding,
        messageAlignment,
        messageBackgroundImage,
        messageBackgroundImageSize,
        messageBackgroundImagePosition,
        messageBackgroundImageRepeat,
        cssStyle,
        margin,
        borderStyle,
        borderWidth,
        borderRadius,
        borderColor,
        titlePadding,
        titleBackground,
        titleText,
        titleColor,
        titleIsBold,
        titleAlignment,
        boxTitleCssClass = '',
        childWidgets
    } = design;
    let directChildren = editable ? null : getHtmlPreview(scriptClass),
        isPlaceholder = true,
        styleOvers = !isNullOrUndefined(cssStyle) ? parseCssStyles(cssStyle) : {};
    if (margin !== undefined) styleOvers.margin = lengthWithUnit(margin, 'px', true, 4);
    // Text widgets - we can inject their text directly, then worry about override of rendering later...
    if (isTextBased) {
        let displayText = buildDisplayText(design['text'], feature, globals, locale);
        const textContentPanel = <span className="text-body" dangerouslySetInnerHTML={{ __html: displayText.text }} />,
            buildWidget = (cw, cwi) => {
                return (
                    <ReportWidgetPanel
                        key={cwi}
                        dataAvailable={dataAvailable}
                        dataProvider={dataProvider}
                        feature={feature}
                        design={{ ...cw }}
                        globals={{ ...globals }}
                        locale={locale}
                        locked={true}
                        editable={editable}
                        onClick={onClick}
                        pageSize={{
                            width: design.width,
                            height: design.height // Page size is relative - if a child then your maxima are the parent, not the page
                        }}
                        inViewport={inViewport}
                        {...others}
                    />
                );
            };
        //isPlaceholder = ((!isNullOrUndefined(indicators) && (indicators.length > 0)) || displayText.placeholder || (!isNullOrUndefined(rules) && (rules.length > 0)));
        isPlaceholder = displayText.placeholder || (!isNullOrUndefined(rules) && rules.length > 0);
        //console.log(`Text '${design.text}' subbed to '${displayText.text}' - placeholder? ${displayText.placeholder} >> ${isPlaceholder}`); // DEBUG
        //console.log(indicators); // DEBUG
        if (backgroundColor !== undefined) {
            styleOvers = {
                backgroundColor: backgroundColor,
                ...styleOvers
            };
        }
        if (messageBackground !== undefined) {
            styleOvers = {
                backgroundColor: messageBackground,
                ...styleOvers
            };
        }
        if (scriptClass === 'TextBoxWidget') {
            styleOvers = !isNullOrUndefined(cssStyle) ? parseCssStyles(cssStyle) : {};
            if (margin !== undefined) styleOvers.margin = margin;
            const boxStyles = {
                borderRadius: `${!isNullOrUndefined(borderRadius) ? borderRadius : '0'}px`
            };
            if (borderStyle.toLowerCase() !== 'notset' && borderStyle.toLowerCase() !== 'not-set') {
                boxStyles.borderStyle = borderStyle;
                boxStyles.borderWidth = `${!isNullOrUndefined(borderWidth) ? borderWidth : '0'}px`;
                if (!isNullOrUndefined(borderColor)) {
                    boxStyles.borderColor = borderColor;
                    if (borderStyle !== 'none' && borderStyle !== 'dotted' && borderStyle !== 'dashed')
                        boxStyles.backgroundColor = borderColor; // Avoids CSS super-thin-border madness
                }
            }
            const msgStyles = {
                    padding: !isNullOrUndefined(messagePadding)
                        ? /^[0-9]+$/.test(messagePadding)
                            ? `${messagePadding}px`
                            : messagePadding
                        : null,
                    backgroundColor: messageBackground
                },
                titleStyleOvers = {
                    padding: !isNullOrUndefined(titlePadding)
                        ? /^[0-9]+$/.test(titlePadding)
                            ? `${titlePadding}px`
                            : titlePadding
                        : null,
                    backgroundColor: titleBackground,
                    color: titleColor,
                    fontWeight: titleIsBold === true ? 'bold' : 'normal',
                    textAlign: titleAlignment
                },
                tt =
                    !isNullOrUndefined(titleText) && titleText !== ''
                        ? buildDisplayText(titleText, feature, globals, locale)
                        : null,
                title =
                    tt !== null ? (
                        <div
                            className={`ia-text-box-title ${boxTitleCssClass}`.trim()}
                            style={titleStyleOvers}
                            dangerouslySetInnerHTML={{ __html: tt.text }}
                        ></div>
                    ) : null;
            if (!isNullOrUndefined(messageBackground)) msgStyles.backgroundColor = messageBackground;
            if (!isNullOrUndefined(messageAlignment)) msgStyles.textAlign = messageAlignment;
            if (!isNullOrUndefined(messageBackgroundImage))
                msgStyles.backgroundImage =
                    messageBackgroundImage.substring(0, 4) !== 'url('
                        ? `url(${messageBackgroundImage})`
                        : messageBackgroundImage;
            if (!isNullOrUndefined(messageBackgroundImagePosition))
                msgStyles.backgroundPosition = messageBackgroundImagePosition;
            if (!isNullOrUndefined(messageBackgroundImageRepeat))
                msgStyles.backgroundRepeat = messageBackgroundImageRepeat;
            if (!isNullOrUndefined(messageBackgroundImageSize)) msgStyles.backgroundSize = messageBackgroundImageSize;
            isPlaceholder = isPlaceholder || (tt !== null && tt.placeholder);
            directChildren = (
                <div className="ia-text-box" style={boxStyles}>
                    {title}
                    <div className="ia-text-box-content" style={msgStyles}>
                        {textContentPanel}
                    </div>
                </div>
            );
            if (!isNullOrUndefined(childWidgets) && childWidgets.length > 0) {
                const wcbBefore = childWidgets
                    .filter(
                        (cw) => cw.embeddedPlacement === undefined || hyphenate(cw.embeddedPlacement) === 'before-text'
                    )
                    .map((cw, cwi) => {
                        return buildWidget(cw, cwi);
                    });
                const wcbAfter = childWidgets
                    .filter(
                        (cw) => cw.embeddedPlacement !== undefined && hyphenate(cw.embeddedPlacement) === 'after-text'
                    )
                    .map((cw, cwi) => {
                        return buildWidget(cw, cwi);
                    });
                directChildren = (
                    <div className="ia-text-box" style={boxStyles}>
                        {title}
                        <div className="ia-text-box-content" style={msgStyles}>
                            {wcbBefore}
                            {textContentPanel}
                            {wcbAfter}
                        </div>
                    </div>
                );
            }
        } else if (hasChildren) {
            const wcbBefore = childWidgets
                .filter((cw) => cw.embeddedPlacement === undefined || hyphenate(cw.embeddedPlacement) === 'before-text')
                .map((cw, cwi) => {
                    return buildWidget(cw, cwi);
                });
            const wcbAfter = childWidgets
                .filter((cw) => cw.embeddedPlacement !== undefined && hyphenate(cw.embeddedPlacement) === 'after-text')
                .map((cw, cwi) => {
                    return buildWidget(cw, cwi);
                });
            directChildren = (
                <div className="ia-text-content" style={styleOvers}>
                    {wcbBefore}
                    {textContentPanel}
                    {wcbAfter}
                </div>
            );
        } else {
            directChildren = <div className="ia-text-content">{textContentPanel}</div>;
        }
    }
    // Text break - simple...
    else if (scriptClass === 'TextBreakWidget') {
        let breakStyle = {
                borderBottomColor: design.bottomBorderColor,
                borderBottomStyle: !isNullOrUndefined(design.bottomBorderStyle)
                    ? design.bottomBorderStyle.toLowerCase()
                    : null,
                borderBottomWidth: !isNullOrUndefined(design.bottomBorderWidth)
                    ? lengthWithUnit(design.bottomBorderWidth)
                    : null,
                minHeight: !isNullOrUndefined(design.bottomBorderWidth)
                    ? lengthWithUnit(design.bottomBorderWidth)
                    : null,
                clear: 'both'
            },
            { cssClass = undefined, cssStyle = undefined } = design;
        if (cssStyle !== undefined) breakStyle = { ...breakStyle, ...parseCssStyles(cssStyle) };
        directChildren =
            design['showHorizontalRule'] === true ||
            design['showHorizontalRule'].toString().toLowerCase() === 'true' ? (
                <hr style={breakStyle} className={cssClass} />
            ) : (
                <div style={breakStyle} className={cssClass}>
                    &nbsp;
                </div>
            );
        styleOvers.height = 'auto';
        isPlaceholder = false;
    }
    // Image - simple...? Might have subbable text tho'
    else if (scriptClass === 'ImageWidget') {
        const { hyperlinkUrl, text, url, tooltip, alternateText, target, lockAspectRatio = false } = design,
            srcUrl = buildDisplayText(url !== undefined ? url : text, feature, globals, locale, ''),
            alt = buildDisplayText(alternateText, feature, globals, locale),
            href = buildDisplayText(hyperlinkUrl, feature, globals, locale),
            title = buildDisplayText(tooltip, feature, globals, locale),
            srcQs =
                !isNullOrUndefined(portalUrl) &&
                !isNullOrUndefined(token) &&
                !isNullOrUndefined(srcUrl.text) &&
                (srcUrl.text.indexOf(portalUrl) >= 0 || srcUrl.text.indexOf('.arcgis.com/') >= 0)
                    ? `${srcUrl.text.indexOf('?') >= 0 ? '&' : '?'}token=${token}`
                    : '';
        directChildren =
            href.text !== undefined && href.text !== null ? (
                <a href={href.text} target={target || '_self'} title={title.text}>
                    <img
                        src={`${srcUrl.text}${srcQs}`}
                        alt={alt.text}
                        className={`aspect-ratio-${lockAspectRatio ? 'locked' : 'dynamic'}`}
                    />
                </a>
            ) : (
                <img
                    src={`${srcUrl.text}${srcQs}`}
                    alt={alt.text}
                    title={title.text}
                    className={`aspect-ratio-${lockAspectRatio ? 'locked' : 'dynamic'}`}
                />
            );
        isPlaceholder = srcUrl.placeholder || alt.placeholder || href.placeholder || title.placeholder;
    }
    // WebMap - already have an async React component for this...
    else if (scriptClass === 'ArcGisOnlineWebMapWidget') {
        const webMapId = !isNullOrUndefined(globals) ? globals.webMap : '';
        if (!isNullOrUndefined(dataProvider) && dataProvider.available && mode !== 'preview') {
            directChildren = (
                <ArcWebMap
                    mapId={webMapId}
                    activeFeature={feature}
                    activeLayer={
                        dataProvider.primarySource !== undefined ? dataProvider.primarySource.config.url : null
                    }
                    title={design.titleText}
                    dataProvider={dataProvider}
                    globals={{ ...globals }}
                    inViewport={inViewport}
                    scheduler={props.scheduler}
                    history={history}
                    {...design}
                />
            );
        }
        isPlaceholder = false;
    }
    // Dangerous...
    else if (editable && scriptClass.indexOf('ScriptWidget') >= 0) {
        directChildren = (
            <div className="ia-editing ia-warning-message ia-error-detail">
                ⚠️ Script widgets are not drawn in edit mode. Use the Report - Preview menu item to see this widget in
                action. ⚠️
            </div>
        );
    }

    let widgetRelativeSizeClass = '',
        widgetSpecificSize = '?';
    let pw = -1;
    if (
        !isNullOrUndefined(pageSize) &&
        !isNullOrUndefined(pageSize.width) &&
        !isNullOrUndefined(design.width) &&
        (pw = convertLengthToPixels(pageSize.width, true, true, -1)) > 0
    ) {
        const ww =
                design.width.indexOf('%') > 0
                    ? (parseFloat(design.width.replace('%')) / 100.0) * pw
                    : convertLengthToPixels(design.width, true, true),
            perc = Math.round((ww * 100.0) / pw),
            factor = Math.ceil((ww * 10.0) / pw) * 10;
        widgetRelativeSizeClass = `ia-max-width-p${factor.toFixed(0)}`;
        widgetSpecificSize = `${perc.toFixed(0)}%`;
    }

    const ww = !isNullOrUndefined(design.width) && design.width !== '' && design.width !== 'px' ? design.width : 'auto',
        wh =
            !isNullOrUndefined(design.height) && design.height !== '' && design.height !== 'px'
                ? design.height
                : 'auto',
        wwNum = ww !== 'auto' && ww !== '-1' && ww !== '-1px' ? convertLengthToPixels(ww, true, true, -1, pw) : pw,
        whNum = wh !== 'auto' ? convertLengthToPixels(wh, true, true, -1) : -1,
        verySmall = (wwNum >= 0 && wwNum < 11) || (whNum >= 0 && whNum < 5),
        wwClasses = [];
    wwClasses.push(className);
    wwClasses.push('ia-report-widget');
    wwClasses.push(`ia${design.scriptClass.replace(/([A-Z])/g, '-$1').toLowerCase()}`);
    if (hasChildren) wwClasses.push('ia-widget-container');
    // if (isPlaceholder) wwClasses.push('placeholder');
    else wwClasses.push('static');
    if (editable) wwClasses.push('ia-showing ia-fixed-view');
    if (verySmall) wwClasses.push('ia-warning-is-tiny');
    if (inViewport) wwClasses.push('ia-in-viewport');

    return (
        <div
            className={`${wwClasses.join(' ')} ${widgetRelativeSizeClass} ${widgetClassName}`.trim()}
            data-src-class={scriptClass}
            data-uuid={design.id.replace(/[^0-9a-zA-Z]/g, '')}
            data-preferred-width={widgetSpecificSize}
            style={{
                left:
                    !isNullOrUndefined(design.left) && design.left !== '' && design.left !== 'px'
                        ? design.left
                        : undefined,
                top:
                    !isNullOrUndefined(design.top) && design.top !== '' && design.top !== 'px' ? design.top : undefined,
                width: ww,
                height: wh,
                float: !isNullOrUndefined(design.float) ? design.float.toLowerCase() : 'left',
                ...styleOvers
            }}
        >
            <div
                className={`ia-widget-inner ${hasChildren ? 'ia-widget-container' : ''}`.trim()}
                data-static-content={!isPlaceholder}
                ref={contentElement}
            >
                {directChildren}
                {editable && !isNullOrUndefined(onClick) ? (
                    <>
                        <div
                            className="ia-widget-edit-screen"
                            onClick={(e) => onClick(e, design.id, design.scriptClass)}
                        ></div>
                    </>
                ) : null}
            </div>
        </div>
    );
};

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const appendRefreshMessage = (
    containerElement,
    message = '⚠️ Sorry - this widget had a problem fetching data. To try again click the button below.',
    buttonText = 'Refresh',
    buttonIcon = 'fas fa-fw fa-refresh',
    buttonClickFunc = () => {}
) => {
    const generated = containerElement.querySelectorAll('.ia-generated');
    for (let i = generated.length - 1; i >= 0; i--) generated[i].remove();
    const failDiv = containerElement.ownerDocument.createElement('div'),
        failMsg = containerElement.ownerDocument.createElement('p'),
        failBtn = containerElement.ownerDocument.createElement('button'),
        failBtnIcon = containerElement.ownerDocument.createElement('i');
    failDiv.setAttribute('class', 'ia-generated ia-data-fail-message');
    failBtn.setAttribute('class', 'btn btn-default btn-block');
    failBtnIcon.setAttribute('class', buttonIcon);
    failBtn.appendChild(failBtnIcon);
    failBtn.appendChild(containerElement.ownerDocument.createTextNode(buttonText));
    failMsg.appendChild(containerElement.ownerDocument.createTextNode(message));
    failMsg.appendChild(failBtn);
    failDiv.appendChild(failMsg);
    failBtn.addEventListener('click', (e) => {
        e.preventDefault();
        buttonClickFunc(e);
    });
    containerElement.appendChild(failDiv);
};

export const getHtmlPreview = (scriptClassName) => {
    let htmlSlug = null;
    switch (scriptClassName) {
        case 'ArcGisOnlineWebMapWidget':
        case 'WebMapWidget':
        case 'MapWidget':
            const bg = `${(typeof process !== 'undefined' && typeof process.env !== 'undefined'
                ? process.env.PUBLIC_URL
                : ''
            ).replace(/^(.*)\/$/, '$1')}/static/images/maps16stretchable.svg`;
            htmlSlug = (
                <div
                    className="ia-placeholder-fill-widget"
                    style={{
                        backgroundImage: `url(${bg})`
                    }}
                >
                    &nbsp;
                </div>
            );
            break;
        case 'TableWidget':
        case 'TimeSeriesTableWidget':
            htmlSlug = (
                <table className="ia-generated ia-table standard" style={{ width: '100%', height: 'auto' }}>
                    <thead>
                        <tr>
                            <th>
                                <span className="sr-only">Column 1</span>&nbsp;
                            </th>
                            <th>
                                <span className="sr-only">Column 2</span>&nbsp;
                            </th>
                            <th>
                                <span className="sr-only">Column 3</span>&nbsp;
                            </th>
                            <th>
                                <span className="sr-only">Column 4</span>&nbsp;
                            </th>
                            <th>
                                <span className="sr-only">Column 5</span>&nbsp;
                            </th>
                            <th>
                                <span className="sr-only">Column 6</span>&nbsp;
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="even">
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr className="odd">
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr className="even">
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody>
                </table>
            );
            break;
        default:
            htmlSlug = null;
            break;
    }
    return htmlSlug;
};

const applyGlobalOverrides = (design = {}, globals = {}) => {
    const isChart = /^(.*)ChartWidget$/.test(design.scriptClass);
    if (isChart) {
        if (
            typeof globals.chartPalette !== 'undefined' &&
            typeof design.palette !== 'undefined' &&
            design.palette.indexOf('=') < 0
        )
            design.palette = globals.chartPalette;
        if (
            typeof globals.chartComparisonsPalette !== 'undefined' &&
            typeof design.comparisonsPalette !== 'undefined' &&
            design.comparisonsPalette.indexOf('=') < 0
        )
            design.comparisonsPalette = globals.chartComparisonsPalette;
    }
    if (
        typeof globals.coreName !== 'undefined' &&
        typeof globals.coreRelationships !== 'undefined' &&
        typeof design.showComparisons !== 'undefined' &&
        design.showComparisons === true &&
        typeof design.comparisonFeatureIds !== 'undefined'
    ) {
        const keys = globals.coreRelationships.map((cr) => cr.name),
            rels = design.comparisonFeatureIds
                .split(',')
                .map((r, i) => {
                    if (/relationship:[0-9a-zA-Z]+_([0-9a-zA-Z]+)\*/.test(r)) {
                        const rn = r.replace(/relationship:[0-9a-zA-Z]+_([0-9a-zA-Z]+)\*/, `${globals.coreName}_$1`);
                        if (keys.indexOf(rn) >= 0) return `relationship:${rn}*`;
                        else if (keys.indexOf(r.replace('relationship:', '').replace('*', '')) < 0) return ''; // Empty - no suitable match
                    }
                    return r;
                })
                .filter((r) => r !== ''); // No keys = no relationships => strip them because they are unsuitable
        design.comparisonFeatureIds = rels.join(',');
        design.showComparisons = rels.length > 0;
    }
};

export default ReportWidgetPanel;
